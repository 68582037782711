import React                  from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

const MappingsHeader = () => {
  const popupIcon = <Icon color='blue' name='info circle' />;
  const popupText = 'When force is selected, the import will replace ' +
                    'existing destination values with the new origin values.';

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          Origin
        </Table.HeaderCell>
        <Table.HeaderCell>
          Destination
        </Table.HeaderCell>
        <Table.HeaderCell>
          Force &nbsp;
          <Popup  trigger={ popupIcon }
                  content={ popupText }
                  position='top center' />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default MappingsHeader;
