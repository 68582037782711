import React, { useState }        from 'react';
import PropTypes                  from 'prop-types';
import { Select, Label, Button }  from 'semantic-ui-react';

import useOrganizationResource    from 'hooks/useOrganizationResource';
import ReferralStatusResource     from 'caseWorthy/resources/ReferralStatusResource';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const SelectorLabel = ({ referral, onSave }) => {
  const { referral_status }       = referral;
  const [selection, setSelection] = useState(referral_status);
  const referralStatuses          = useOrganizationResource(
                                      ReferralStatusResource.listShape()
                                    );

  if(referral_status === null || referral_status === undefined) {
    return (
      <Label color='red'>Not Synced</Label>
    );
  }

  const showActions = referral_status !== selection;

  const handleChange = (evt, { value }) => {
    setSelection(value);
  };

  const handleSave = (evt) => {
    evt.preventDefault();
    onSave({
      ...referral,
      referral_status: selection
    });
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    setSelection(referral_status);
  };

  return (
    <div>
      <Select value={ selection }
              options={ referralStatuses }
              selectOnBlur={ false }
              onChange={ handleChange }
              search />

      {
        showActions &&
        <span style={{ marginLeft: '0.25em' }}>
          <Button icon='check' color='blue' onClick={ handleSave } basic />
          <Button icon='cancel' color='red' onClick={ handleCancel } basic />
        </span>
      }
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SelectorLabel.propTypes = {
  referral: PropTypes.object.isRequired,
  onSave:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SelectorLabel;
