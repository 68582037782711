import React, { useState, useEffect }     from 'react';
import PropTypes                          from 'prop-types';
import { Modal, Header, Button, Loader }  from 'semantic-ui-react';

import useApiFetch from 'hooks/useApiFetch';
import resourceUrl from 'utils/resourceUrl';

import ResultsList from './ResultsList';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const DEFAULT_PAGE    = 1;
const DEFAULT_RESULTS = [];

const buildQueryParams = (parametersMapping, answers, currentPage) => {
  const mappedParams  = Object
                        .entries(parametersMapping)
                        .map(([param, fieldId]) => (
                          [ param, answers[fieldId]?.value ]
                        ))
                        .filter(([, value]) => value !== undefined && value !== null);

  if(currentPage !== null && currentPage !== undefined) {
    mappedParams.push(['page_index', currentPage]);
  }
  return mappedParams;
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const SelectionModal = ({ opened,
                          title,
                          onClose,
                          onSubmit,
                          parametersMapping,
                          answers,
                          fieldId,
                          metadata: { api_endpoint } }) => {

  const [selection, setSelection]     = useState();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [allResults, setAllResults]   = useState(DEFAULT_RESULTS);
  
  const disabled                  = selection === null || selection === undefined;
  const initUrl                   = resourceUrl(api_endpoint);

  const [{ isLoading, isError, data }, url, setUrl] = useApiFetch(initUrl, []);
  const { metadata: { total_pages: totalPages }={}, data: results } = data;
  
  useEffect(() => {
    if(results !== null && results !== undefined) {
      setAllResults(ar => [...ar, ...results]);
    }
  }, [results]);
  
  useEffect(() => {
    if(opened) {
      const params    = buildQueryParams(parametersMapping, answers, currentPage);
      const nextUrl   = new URL(url);
      nextUrl.search  = new URLSearchParams(params);
      
      if (url?.href !== nextUrl?.href) {
        setUrl(nextUrl);
      }
    } else {
      setSelection();
      setAllResults(DEFAULT_RESULTS);
      setCurrentPage(DEFAULT_PAGE);
    }

  }, [fieldId, answers, parametersMapping, setUrl, url, currentPage, opened]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onSubmit(selection);
  };

  const handleSelect = (selection) => {
    setSelection(selection);
  };

  const handlePageNext = () => {
    if(currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  return (
    <Modal open={opened} onClose={handleClose} dimmer='inverted' closeIcon>
      <Header icon='linkify' content={title} />
      <Modal.Content>
        { 
          isError
          ? <div>An error occurred: {data.errors}</div>
          : allResults?.length > 0
            ? <ResultsList  results={ allResults } 
                            onSelect={ handleSelect } 
                            onPageNext={ handlePageNext } />
            : isLoading
              ? <Loader active />
              : <div>No results found</div>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} negative basic>Cancel</Button>
        <Button type='submit'
          onClick={handleSubmit}
          disabled={disabled}
          positive
          basic>Select</Button>
      </Modal.Actions>
    </Modal>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

SelectionModal.defaultProps  = {
  opened: false
};

SelectionModal.propTypes     = {
  opened:             PropTypes.bool,
  title:              PropTypes.string.isRequired,
  onClose:            PropTypes.func.isRequired,
  onSubmit:           PropTypes.func.isRequired,
  answers:            PropTypes.object,
  parametersMapping:  PropTypes.object
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default SelectionModal;