import React from 'react';
import { Button } from 'semantic-ui-react';

// --------------------------------------------------------
// Component Def
// --------------------------------------------------------

class SidebarToggle extends React.Component {

  render() {
    return (
      <Button className={`toggle-sidebar ${this.props.className}`}
              icon={`angle double ${this.props.direction}`}
              color='black'
              size='small'
              basic
              compact
              floated={ this.props.target }
              onClick={ this.props.onClick } />
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default SidebarToggle;
