import _ from 'lodash';

function toFormData(data, keys='', formData=new FormData()) {
  if(_.isPlainObject(data)) {
    _.each(data, (val, key) => {
      let nextKeys = keys ? `${keys}[${key}]` : key;
      toFormData(val, nextKeys, formData);
    });
  } else if(_.isArray(data)) {
    _.each(data, (val) => {
      toFormData(val, `${keys}[]`, formData);
    });
  } else {
    if(data !== null && data !== undefined) {
      formData.append(keys, data);
    }
  }

  return formData;
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export function formDataRequestMapper(payload) {
  const formData = toFormData(payload);
  return formData;
}
