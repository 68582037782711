import React      from 'react';
import PropTypes  from 'prop-types';

import { Header as SemHeader, Image } from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Header = ({ name, description, logo }) => {
  return (
    <div>
      { logo &&
        <Image src={ logo } size="medium" centered />
      }
      <SemHeader as='h2'>{ name }</SemHeader>
      <p>{ description }</p>
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Header.propTypes = {
  name:         PropTypes.string,
  description:  PropTypes.string
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Header;
