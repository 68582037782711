import React, { useState }        from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header, Menu }  from 'semantic-ui-react';

import Definitions                from './Definitions';
import Messages                   from './Messages';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const Tab = {
  messages: {
    name: 'Messages',
    value:  0
  },

  configuration: {
    name: 'Configuration',
    value:  1
  }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Notifications = ({ form, resourceId, resourceName }) => {
  const [ selectedTab, setSelectedTab ] = useState(Tab.messages.value);

  return (
    <Segment>
      <Header as='h3'>Notifications</Header>
      <Menu pointing secondary size='huge'>
        <Menu.Item
          name={ Tab.messages.name }
          active={ selectedTab === Tab.messages.value }
          onClick={ () => setSelectedTab(Tab.messages.value) }
        />
        <Menu.Item
          name={ Tab.configuration.name }
          active={ selectedTab === Tab.configuration.value }
          onClick={ () => setSelectedTab(Tab.configuration.value) }
        />
      </Menu>

      {
        selectedTab === Tab.messages.value
        && <Messages  resourceId={ resourceId }
                      resourceName={ resourceName } />
      }

      {
        selectedTab === Tab.configuration.value
        && <Definitions form={ form }
                        resourceId={ resourceId }
                        resourceName={ resourceName } />
      }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Notifications.propTypes = {
  form:         PropTypes.object.isRequired,
  resourceId:   PropTypes.number.isRequired,
  resourceName: PropTypes.string.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Notifications;
