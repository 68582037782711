import ApiResource  from 'resources/ApiResource';
import AuthToken    from 'utils/AuthToken';

// -----------------------------------------------------
// Constants
// -----------------------------------------------------

const authHeaderKey = 'Authorization';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

// Manages auth headers for API calls, as well as rotating the UI's authToken
// on each request to the API.
class AuthenticatedResource extends ApiResource {

  // Adds Auth header to API requests
  static fetchOptionsPlugin(options) {
    const { headers } = options;
    const authToken   = AuthToken.read();

    const headersWithAuth = {
      ...headers,
      [authHeaderKey]: `Bearer ${authToken}`
    };

    return {
      ...options,
      headers: headersWithAuth
    };
  }

  // Override to capture and store the next authToken from the response header.
  static fetchResponse(method, url, body) {
    return  super
            .fetchResponse(method, url, body)
            .then(response => {
              const { headers } = response;
              const authToken   = headers.get(authHeaderKey);

              AuthToken.write(authToken);

              return response;
            });
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AuthenticatedResource;
