import React, { Component } from 'react';
import { List }             from 'semantic-ui-react';

import MasterDetail             from 'components/MasterDetail';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import { users }                from 'resources/adminResources';
import connectResource          from 'utils/connectResource';
import { getPageAllById }       from 'utils/connectors';
import { sortByAttributes }     from 'utils/sortComparators';
import { textSubstringFilter }  from 'utils/recordFilters';

import DetailView from './DetailView';
import FormView   from './FormView';

// -----------------------------------------------------
// List Item View
// -----------------------------------------------------

const ListItem = ({record}) => (
  <List.Header>{ record.first_name } { record.last_name }</List.Header>
);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class App extends Component {
  constructor(props) {
    super(props);
    this.handleDelete  = this.handleDelete.bind(this);
    this.handleUpdate  = this.handleUpdate.bind(this);
    this.handleCreate  = this.handleCreate.bind(this);
  }

  render() {
    const { data }  = this.props;
    const users     = data.users || [];

    return (
      <MasterDetail masterItemComponent={ ListItem }
                    detailComponent={ DetailView }
                    formComponent={ FormView }
                    records={ users }
                    onDelete={ this.handleDelete }
                    onUpdate={ this.handleUpdate }
                    onCreate={ this.handleCreate }
                    listSort={ sortByAttributes('first_name', 'last_name') }
                    filterRecord={ textSubstringFilter('first_name', 'last_name') }
                    { ...this.props } />
    );
  }

  handleDelete(id) {
    const { destroy } = this.props;
    return destroy(null, { id });
  }

  handleUpdate(record) {
    const { update }  = this.props;
    return update(record, { id: record.id });
  }

  handleCreate(record) {
    const { create }  = this.props;
    return create(record);
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    users: getPageAllById(users)
  },
  mapDispatchToProps: { ...users.actions }
});

export default withSearch(withPagination(connectedComponent));
