import React            from 'react';
import { useSelector }  from 'react-redux';
import { Redirect }     from 'react-router-dom';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const AuthenticatedRoute = ({ children }) => {
  const { authToken } = useSelector(state => state.auth);

  if(authToken !== null && authToken !== undefined) {
    return children;
  } else {
    return <Redirect to='/login' />;
  }
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default AuthenticatedRoute;
