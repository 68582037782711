import React, { Component }                           from 'react';
import PropTypes                                      from 'prop-types';
import { Modal, Header, Form, Button, Message, Icon } from 'semantic-ui-react';

import { organizations }                from 'resources/adminResources';
import { getAllById }                   from 'utils/connectors';
import connectResource                  from 'utils/connectResource';
import uniqueId                         from 'utils/uniqueId';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function getExistingAssignments(form) {
  if(!form) {
    return [];
  }

  return form.clones.map(({ organization }) => organization.id);
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class OrganizationSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizationIds: new Set(),
      loading: false
    };

    this.formId = uniqueId();

    this.handleSubmit  = this.handleSubmit.bind(this);
    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { opened, form, onClose, data: { organizations } } = this.props;
    const { loading } = this.state;

    // this is a list of IDs of orgs that have already been assigned this form.
    const assigned = getExistingAssignments(form);

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon='copy outline' content='Copy to Organizations' />

        <Modal.Content>
          <h4>Select the organizations below that will receive this form.</h4>

          {
            assigned.length > 0
            ? <Message icon info>
                <Icon name='info circle' />
                If this form has already been assigned to one or more of the
                following organizations, the corresponding checkboxes will be disabled.
              </Message>
            : ''
          }

          <Form onSubmit={ this.handleSubmit } id={ this.formId }>
            {
              organizations.map(({ name, id }) => (
                <Form.Field key={ id }>
                  <Form.Checkbox  label={ name }
                                  value={ id }
                                  disabled={ assigned.includes(id) }
                                  onChange={ this.handleChange } />
                </Form.Field>
              ))
            }
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={ onClose } negative basic>Cancel</Button>
          <Button type='submit'
                  loading={ loading }
                  form={ this.formId }
                  positive
                  basic>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  async handleSubmit(evt) {
    evt.preventDefault();

    const { onSave }            = this.props;
    const { organizationIds }   = this.state;
    const organizationIdsArray  = [ ...organizationIds ];

    this.setState({ loading: true });
    const didSucceed = await onSave(organizationIdsArray);

    if(didSucceed) {
      this.setState({ loading: false, organizationIds: new Set() });
    }
  }

  handleChange(evt, { value, checked }) {
    const { organizationIds } = this.state;

    if(checked) {
      organizationIds.add(value);
    } else {
      organizationIds.delete(value);
    }

    this.setState({ organizationIds });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

OrganizationSelector.defaultProps = {
  opened: false,
  form:   {}
};

OrganizationSelector.propTypes = {
  opened:   PropTypes.bool,
  form:     PropTypes.object,
  onClose:  PropTypes.func.isRequired,
  onSave:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(OrganizationSelector, {
  connectors: {
    organizations: getAllById(organizations)
  }
});
