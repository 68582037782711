import React, { Fragment, Component }   from 'react';
import { Button, Popup }                from 'semantic-ui-react';
import FormModal                        from './FormModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Edit publish settings.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class EditButton extends Component {
  state = {
    opened: false
  };

  render() {
    const { opened } = this.state;

    const Btn = <Button icon='pencil'
                        basic
                        color='blue'
                        onClick={ () => this.setState({ opened: true }) } />;

    return (
      <Fragment>
        <FormModal  title={ title }
                    opened={ opened }
                    onClose={ () => this.setState({ opened: false }) }
                    { ...this.props } />

        <Popup  trigger={ Btn }
                content={ title }
                position='top center' />
      </Fragment>
    );
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditButton;
