import React, { Component } from 'react';
import { Form, Segment, Checkbox } from 'semantic-ui-react';
import { Redirect }         from 'react-router-dom';

import connectForm          from 'utils/connectForm';
import connectResource      from 'utils/connectResource';
import { getById }          from 'utils/connectors';
import { clients }          from 'resources/organizationResources';
import DateSelect           from 'components/DateSelect';
import Fixed                from 'components/Fixed';
import { contentNamespace } from 'utils/content';

import 'style/components/FormView.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const initialState = {
  id:         null,
  first_name: '',
  last_name:  '',
  dob:        '',
  dod:        '',
  ssn_suffix: '',
  active:     true
};

function getStyle(hideSegment) {
  return hideSegment ? { 'boxShadow': 'none', 'border': 0} : {};
}

const clientContent = contentNamespace('organization.clients');
const genderValues  = clientContent('gender');

const genderOptions = Object.keys(genderValues).map((value) => {
  return { value: value, text: genderValues[value] };
});

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormView extends Component {

  render() {
    const { selected,
            loading,
            api_errors,
            onSave,
            onUpdateValue,
            saving,
            hideSubmitButton=false }  = this.props;

    const { first_name,
            middle_name,
            gender,
            last_name,
            dob,
            dod,
            active,
            ssn_suffix } = selected;

    const style = getStyle(hideSubmitButton);

    return (
      <Segment loading={ loading } style={ style }>
        { api_errors && <Redirect to='./'/> }

        <Form onSubmit={ onSave } id='client-form' >
          <Form.Input label='First Name'
                      name='first_name'
                      value={ first_name || '' }
                      placeholder='James'
                      onChange={ onUpdateValue }
                      required />

          <Form.Input label='Middle Name'
                      name='middle_name'
                      value={ middle_name || '' }
                      onChange={ onUpdateValue } />

          <Form.Input label='Last Name'
                      name='last_name'
                      value={ last_name || '' }
                      placeholder='Brown'
                      onChange={ onUpdateValue }
                      required />

          <Form.Field label='Date of Birth'
                      name='dob'
                      control={ DateSelect }
                      onChange={ this.handleDobChange }
                      value={ dob }
                      maxDate={ new window.Date() }
                      required />

          { !dod &&
            <div className={ 'active-toggle' }>
              <label>Deceased</label>
              <Checkbox toggle checked={ false } onChange={ this.handleDeceased } />
            </div>
          }

          { dod &&
          <Form.Field label='Date Deceased'
                      name='dod'
                      control={ DateSelect }
                      onChange={ this.handleDodChange }
                      value={ dod }
                      maxDate={ new window.Date() }
                       />
          }

          <Form.Select  label='Gender'
                        name='gender'
                        placeholder='Gender'
                        value={ gender }
                        options={ genderOptions }
                        onChange={ onUpdateValue }
                        selectOnBlur={ false }
                        clearable
                        search
                        required />

          <Form.Input label='Last 4 of SSN'
                      name='ssn_suffix'
                      value={ ssn_suffix || '' }
                      placeholder='1234'
                      type='number'
                      onChange={ onUpdateValue }
                      required />
          { !hideSubmitButton &&
          <Fixed className={ 'fixed-with-toggle' }>
            <Form.Button  loading={ saving }
                          type='submit'
                          primary>Save</Form.Button>

            <div className={ 'active-toggle' }>
              Inactive
              <Checkbox toggle checked={ active } onChange={ this.handleActive } />
              Active
            </div>
          </Fixed>
          }
        </Form>
      </Segment>
    );
  }

  handleDobChange = (value) => {
    this.handleDateChange ('dob', value);
  }

  handleDodChange = (value) => {
    this.handleDateChange ('dod', value);
  }

  handleDeceased = () => {
    this.handleDodChange(window.Date());
  }

  handleDateChange = (name, value=null) => {
    const { onUpdateValue } = this.props;

    onUpdateValue(null, { name, value });
  }

  handleActive = (evt, { checked }) => {
    const { onUpdateValue } = this.props;

    onUpdateValue(null, { name: 'active', value: checked });
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(connectForm(FormView, {initialState}), {
  connectors: {
    selected: getById(clients, (props) => props.match.params.id)
  },
  mapDispatchToProps: { ...clients.actions }
});
