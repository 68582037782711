import clone from 'clone';

export default (state=[], action) => {
  let newState = clone(state);

  switch(action.type) {
    case 'PASSWORDRESET:UPDATE_RESPONSE':
      return action.payload.authentication_scopes;

    default:
      return newState;
  }
};
