import createResource  from '../utils/resource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

const fieldTypes = createResource({
  name:         'fieldTypes',
  selector:     (state) => state.fieldTypes,
  basepath:     '/field_types/',
  initialState: null,

  actions: {
    get: {
      method:   'GET',
      path:     '/',
      response: (state, action) => action.payload
    }
  }
});

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default fieldTypes;
