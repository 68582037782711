import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';

import connectResource                from 'utils/connectResource';
import { getAllById, getById }        from 'utils/connectors';
import { imports,
         organizations,
         organization_forms }         from 'resources/adminResources';

import BasicDetail  from 'components/admin/imports/Detail/Basic';
import BasicForm    from 'components/admin/imports/Form/Basic';
import Advanced     from 'components/admin/imports/Form/Advanced';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class FormContainer extends Component {

  // construction
  constructor(props) {
    super(props);

    this.state = {
      organizationForms: [],
      saving: false
    };

    this.handleChangeToOrg = this.handleChangeToOrg.bind(this);
    this.handleCreate      = this.handleCreate.bind(this);
    this.handleUpdate      = this.handleUpdate.bind(this);
  }

  // handlers
  handleChangeToOrg(record) {
    const { formsIndex }      = this.props;
    const { organization_id } = { ...record };
    this.setState({ organizationForms: [] }, () => {
      formsIndex(null, { organization_id }).then(
        (organizationForms) => {
          this.setState({ organizationForms });
        }
      );
    });
  }
  handleCreate(record) {
    const { onCreate } = this.props;
    this.setState({ saving: true }, async () => {
      await onCreate(record);
      this.setState({ saving: false });
    });
  }
  handleUpdate(record) {
    const { onUpdate } = this.props;
    this.setState({ saving: true }, async () => {
      await onUpdate(record);
      this.setState({ saving: false });
    });
  }

  // helpers
  getOrganizationFormsArray() {
    const { organizationForms } = this.state;
    return organizationForms || [];
  }
  getOrganizationsArray() {
    const { data }          = this.props;
    const { organizations } = { ...data };
    return organizations || [];
  }
  getSelectedObject() {
    const { data }     = this.props;
    const { selected } = { ...data };
    return { ...selected };
  }

  // rendering
  render() {
    const { loading }       = this.props;
    const { saving }        = this.state;
    const record            = this.getSelectedObject();
    const organizationforms = this.getOrganizationFormsArray();
    const organizations     = this.getOrganizationsArray();

    return (
      <Fragment>
        {
          !record.id &&
          <BasicForm  loading={ loading }
                      saving={ saving }
                      forms={ organizationforms }
                      organizations={ organizations }
                      onChange={ this.handleChangeToOrg }
                      onSubmit={ this.handleCreate } />
        }
        {
          record.id &&
          <Fragment>
            <BasicDetail loading={ loading }
                         record={ record } />

            <Advanced loading={ loading }
                      saving={ saving }
                      record={ record }
                      onSubmit={ this.handleUpdate } />
          </Fragment>
        }
      </Fragment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

FormContainer.propTypes = {
  loading:    PropTypes.bool.isRequired,
  data:       PropTypes.object.isRequired,
  match:      PropTypes.object.isRequired,
  onCreate:   PropTypes.func,
  onUpdate:   PropTypes.func,
  formsIndex: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default connectResource(FormContainer, {
  connectors: {
    selected:       getById(imports, ({ match }) => match.params.id),
    organizations:  getAllById(organizations)
  },
  mapDispatchToProps: {
    formsIndex: organization_forms.actions.index
  }
});
