import { useState, useEffect }  from 'react';
import { useSelector }          from 'react-redux';

import useApiFetch  from 'hooks/useApiFetch';
import resourceUrl  from 'utils/resourceUrl';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const urlRoot = '/organization/:organization/case_worthy/clients';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function useClientSearch(initParams={}) {
  const [params, setParams] = useState(initParams);

  // Get the current organization slug from the Redux state
  const organization      = useSelector(state => state.orgdomain);
  const initUrl           = resourceUrl(urlRoot, '', { organization });

  if(Object.keys(params).length) {
    initUrl.search = new URLSearchParams(initParams);
  }

  const [state, url, setUrl]  = useApiFetch(initUrl, []);

  useEffect(() => {
    const nextUrl   = new URL(url);
    nextUrl.search  = new URLSearchParams(params);

    if(url?.href !== nextUrl?.href) {
      setUrl(nextUrl);
    }
  }, [url, setUrl, params]);

  return [state, params, setParams];
}
