import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ClientFormGroupResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/clients/:client_id/client_form_groups/';

  id                   = undefined;
  count                = null;
  last_administered_at = null;
  last_administered_by = null;

  pk() {
    return this.id && this.id.toString();
  }
}
