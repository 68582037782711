import React, { Component }   from 'react';
import { Table, Icon, Label } from 'semantic-ui-react';
import formattedDate          from 'utils/formattedDate';

const importTypes  = {
  bulk_update: 'Import Responses',
  delete_client: 'Delete Client Responses',
  delete_public: 'Delete Public Responses',
  internal: 'Import Responses'
};

function statusLabel(state){
  return (
    <Label size='mini' color={ state === 'succeeded' ? 'green' : 'red' }>
      { state }
    </Label>
  );
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class LogItem extends Component {

  render() {
    const { created_at,
            administrator,
            records,
            state,
            type,
            error_url,
            input_url  } = this.props;

    return (
            <Table.Row>
                <Table.Cell>
                  { formattedDate(created_at) }
                </Table.Cell>
                <Table.Cell>
                  { importTypes[type] }
                </Table.Cell>
                <Table.Cell>
                  { statusLabel(state) }
                </Table.Cell>
                <Table.Cell>
                  { records }
                </Table.Cell>
                <Table.Cell>
                  { administrator }
                </Table.Cell>
                <Table.Cell>
                  <a href={ input_url } target="_blank" rel="noopener noreferrer">
                    <Icon name="download" />
                  </a>
                </Table.Cell>
                <Table.Cell>
                  { state === 'failed' &&
                  <a href={ error_url } target="_blank" rel="noopener noreferrer">
                    <Icon name="download" />
                  </a>
                  }
                </Table.Cell>
            </Table.Row>
      );
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default LogItem;
