import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { List, Checkbox }   from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Checkboxes extends Component {
  constructor(props) {
    super(props);
    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { options, value: selected, disabled } = this.props;

    return (
      <List horizontal>
        {
          options.map(({ text, value }) => (
            <List.Item key={ value }>
              <Checkbox value={ value }
                        disabled={ disabled }
                        label={ text }
                        checked={ selected.includes(value) }
                        onChange={ this.handleChange } />
            </List.Item>
          ))
        }
      </List>
    );
  }

  handleChange(evt, { checked, value }) {
    const { value: selected, onChange } = this.props;
    const selectionSet = new Set(selected);

    if(checked) {
      selectionSet.add(value);
    } else {
      selectionSet.delete(value);
    }

    onChange(evt, { value: [ ...selectionSet ] });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Checkboxes.defaultProps = {
  value:    [],
  options:  [],
  disabled: false
};

Checkboxes.propTypes = {
  disabled: PropTypes.bool,
  value:    PropTypes.array,
  options:  PropTypes.arrayOf(PropTypes.shape({
    text:   PropTypes.string,
    value:  PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })),
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Checkboxes;
