import React, { Fragment }      from 'react';
import { List }                 from 'semantic-ui-react';

import MasterDetail             from 'components/MasterDetail';
import ActiveStateBadge         from 'components/ActiveStateBadge';
import withSearch               from 'hocs/withSearch';
import withPagination           from 'hocs/withPagination';
import connectResource          from 'utils/connectResource';
import { getPageAllById }       from 'utils/connectors';
import { sortByAttributes }     from 'utils/sortComparators';
import { textSubstringFilter }  from 'utils/recordFilters';
import { publicFormAnswers }    from 'resources/organizationResources';
import DetailView               from './DetailView';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const ListItemView = ({ record: { name, is_public } }) => {
  return (
    <Fragment>
      <List.Content floated='right'>
        <ActiveStateBadge isActive={ is_public } />
      </List.Content>
      <List.Header>
        { name }
      </List.Header>
    </Fragment>
  );
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const App = (props) => {
  const { data: { forms } } = props;

  return (
    <MasterDetail masterItemComponent={ ListItemView }
                  detailComponent={ DetailView }
                  records={ forms || [] }
                  resource='forms'
                  listSort={ sortByAttributes('name') }
                  filterRecord={textSubstringFilter('name')}
                  displayOnly
                  { ...props } />
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    forms: getPageAllById(publicFormAnswers)
  },
  mapDispatchToProps: { ...publicFormAnswers.actions }
});

export default withSearch(withPagination(connectedComponent));
