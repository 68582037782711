import React, { Component }                         from 'react';
import PropTypes                                    from 'prop-types';
import { Modal, Button, Header, Form, Card, List, Message }  from 'semantic-ui-react';
import uniqueId                                     from 'utils/uniqueId';

import formattedDate        from 'utils/formattedDate';
import { contentNamespace } from 'utils/content';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const genders = contentNamespace('organization.clients.gender');

function name(client) {
  const { first_name, last_name } = client;
  return `${first_name} ${last_name}`;
}

function ClientCard({ currentClientId, client, masterId, onChange }) {
  const { dob, gender, ssn_suffix, id } = client;

  return (
    <Card>
      <Card.Content header={ name(client) } />
      <Card.Content>
        <List relaxed>
          <List.Item>
            <List.Header>Client ID</List.Header>
            <List.Description>
              { id }
              { currentClientId && <em> (currently viewing)</em> }
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>DOB</List.Header>
            <List.Description>{ formattedDate(dob, 'date') }</List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Gender</List.Header>
            <List.Description>{ genders(gender) }</List.Description>
          </List.Item>
          <List.Item>
            <List.Header>Last 4 of SSN</List.Header>
            <List.Description>{ ssn_suffix }</List.Description>
          </List.Item>
        </List>
      </Card.Content>
      <Card.Content>
        <Button size='small'
                primary
                basic={ masterId !== id }
                value={ id }
                onClick={ onChange }>

          { masterId !== id ? 'Set master' : 'This is master' }
        </Button>
      </Card.Content>
    </Card>
  );
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class MergeModal extends Component {
  constructor(props) {
    super(props);

    const { client, duplicates }  = this.props;
    const { id: masterId }        = client;
    const mergedIds               = duplicates.map(({ id }) => id);

    this.state = { masterId, mergedIds };

    this.formId       = uniqueId();
    this.handleChange = this.handleChange.bind(this);
    this.handleNotDuplicate = this.handleNotDuplicate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { masterId }                                    = this.state;
    const { opened, onClose, title, client, duplicates }  = this.props;

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon='pencil' content={ title } />
        <Modal.Content>
          <Form onSubmit={ this.handleSubmit } id={ this.formId }>
            <Card.Group itemsPerRow={ 2 }>
              <ClientCard client={ client }
                          masterId={ masterId }
                          currentClientId={ true }
                          onChange={ this.handleChange } />

              {
                duplicates.map((duplicate) => (
                  <ClientCard key={ duplicate.id }
                              client={ duplicate }
                              masterId={ masterId }
                              onChange={ this.handleChange } />
                ))
              }
            </Card.Group>
          </Form>

          <Message icon>
            <Message.Content>
                These records are not the same? Dismiss this duplicate warning.
                <Button size='small'
                  floated='right'
                  basic
                  onClick={ this.handleNotDuplicate }>Dismiss</Button>
            </Message.Content>
          </Message>

        </Modal.Content>

        <Modal.Actions>
          <Button onClick={ onClose } negative basic>Cancel</Button>
          <Button type='submit' form={ this.formId } positive basic>Merge</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleChange(evt, { value }) {
    evt.preventDefault();

    const { masterId, mergedIds } = this.state;

    if(mergedIds.includes(value)) {
      const nextMergedIds = [
        ...mergedIds.filter(id => id !== value),
        masterId
      ];

      this.setState({ masterId: value, mergedIds: nextMergedIds });
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const data        = { ...this.state };
    const { onClose } = this.props;

    this.props.onSubmit(data, onClose);
  }

  handleNotDuplicate(evt) {
    evt.preventDefault();
    
    const data        = { ...this.state, is_duplicate: false };
    const { onClose } = this.props;
    
    this.props.onSubmit(data, onClose);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

MergeModal.defaultProps = {
  opened:       false
};

MergeModal.propTypes = {
  id:           PropTypes.number,
  opened:       PropTypes.bool,
  client:       PropTypes.object.isRequired,
  duplicates:   PropTypes.arrayOf(PropTypes.object).isRequired,
  title:        PropTypes.string.isRequired,
  onSubmit:     PropTypes.func.isRequired,
  onClose:      PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default MergeModal;
