import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Button, Popup }    from 'semantic-ui-react';

import { windowFunctionType, aggFunctionType } from 'constants/reports/FunctionTypes';

import 'style/selection-buttons.css';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FunctionSelector extends Component {
  render() {
    const { functionType, onChange }  = this.props;

    const WindowFunctionBtn =
      <Button icon='columns'
              basic
              color='blue'
              value={ windowFunctionType }
              active={ functionType === windowFunctionType }
              onClick={ onChange } />;

    const AggFunctionBtn =
      <Button icon='filter'
              basic
              color='blue'
              value={ aggFunctionType }
              active={ functionType === aggFunctionType }
              onClick={ onChange } />;

    const ClearBtn =
      <Button icon='remove'
              basic
              color='blue'
              onClick={ onChange } />;

    return (
      <Button.Group size='mini' className='selection-buttons'>
        <Popup  trigger={ WindowFunctionBtn }
                content='Apply window function'
                position='top center' />

        <Popup  trigger={ AggFunctionBtn }
                content='Apply aggregation function'
                position='top center' />

        <Popup  trigger={ ClearBtn }
                content='Remove all function'
                position='top center' />
      </Button.Group>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FunctionSelector.propTypes = {
  functionType: PropTypes.string,
  onChange:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FunctionSelector;
