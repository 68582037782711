import React, { Component }      from 'react';
import PropTypes  from 'prop-types';

import ComparisonExpression from './ComparisonExpression';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function isLogical(expression) {
  return !!(expression['and'] || expression['or']);
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class LogicalExpression extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const { expression, index, onChange, ...forwardProps } = this.props; // eslint-disable-line no-unused-vars
    const [op, expressions] = Object.entries(expression).flat();

    if(!expressions) {
      return null;
    }

    return (
      <div className='conditional-logical-expression' index={ index }>
        {
          expressions
          .map((expression, key) => (
            isLogical(expression)
            ? <LogicalExpression  key={ `logical-expr-${key}` }
                                  index={ key }
                                  expression={ expression }
                                  onChange={ this.handleChange }
                                  { ...forwardProps } />
            : <ComparisonExpression key={ `comparison-expr-${key}` }
                                    index={ key }
                                    expression={ expression }
                                    onChange={ this.handleChange }
                                    { ...forwardProps } />
          ))
          .reduce((prev, curr, key) => (
            [
              prev,
              <div  className='conditional-logical-operator'
                    key={ `logical-op-${key}` }>{ op }</div>,
              curr
            ]
          ), null)
        }
      </div>
    );
  }

   handleChange({ index: childIndex, expression: newComparisonExpression }) {
    const { expression, index=0, onChange }    = this.props;
    const [op, expressions] = Object.entries(expression).flat();

    const newExpressions = [ ...expressions ];
    newExpressions[childIndex]  = newComparisonExpression;

    onChange({ index, expression: { [op]: newExpressions } });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

LogicalExpression.propTypes = {
  expression: PropTypes.oneOfType([
    PropTypes.shape({ and: PropTypes.array }),
    PropTypes.shape({ or: PropTypes.array })
  ]).isRequired,

  editable: PropTypes.bool,

  fieldsLookup: PropTypes.objectOf(PropTypes.shape({
    label:        PropTypes.string,
    formatValue:  PropTypes.func
  }))
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default LogicalExpression;
