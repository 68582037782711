import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class FormPrePopulateResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/forms/:form_id/pre_populate';

  id              = undefined;
  answers         = {};

  pk() {
    return this.id && this.id.toString();
  }
}

