import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table }            from 'semantic-ui-react';

import BodyRow              from './BodyRow';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Body extends Component {
  render() {

    const { actions, entities, privileges, disabled, onChange } = this.props;
    
    return (
      <Table.Body>
        {
          entities.map((entity) => {
            return (
              <BodyRow  key={ entity }
                entity={ entity }
                actions={ actions }
                privileges={ privileges }
                disabled={ disabled }
                onChange={ onChange } />
            );
          })
        }
      </Table.Body>
    );
  }
}

// -----------------------------------------------------
// Prop Types
// -----------------------------------------------------

Body.propTypes = {
  actions:    PropTypes.arrayOf(PropTypes.string).isRequired,
  entities:   PropTypes.arrayOf(PropTypes.string).isRequired,
  privileges: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired,
  disabled:   PropTypes.bool,
  onChange:   PropTypes.func
};

Body.defaultProps = {
  disabled: true,
  onChange: () => {}
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Body;
