import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';
import MessageItem          from './Item';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const MessageList = (props) => {
  const { messages } = props;

  return (
    <Fragment>
      {
        messages.map((msg) => (
          <MessageItem  key={ msg.id }
                        body={ msg.body }
                        created_at={ msg.created_at }
                        notifiable={ msg.notifiable } />
        ))
      }
    </Fragment>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

MessageList.propTypes = {
  messages: PropTypes.array.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default MessageList;
