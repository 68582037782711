import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';

import { sortOrders }           from 'constants/Sort';
import SortableTable            from 'components/SortableTable';

import { refPrefix }                from 'utils/reportDefinitions';
import ClientFormReportCellRenderer from 'components/organization/reports/ClientFormReportCellRenderer';
import PublicFormReportCellRenderer from 'components/organization/reports/PublicFormReportCellRenderer';
import InternalFormReportCellRenderer from 'components/organization/reports/InternalFormReportCellRenderer';

const cellRendererType = {
  'client_forms':   ClientFormReportCellRenderer,
  'public_forms':   PublicFormReportCellRenderer,
  'internal_forms': InternalFormReportCellRenderer
};

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
function cellRenderer(reportDefinition, headers) {
  const { report_type } = reportDefinition;

  const Renderer  = cellRendererType[report_type] || PublicFormReportCellRenderer;

  const CellRenderer = (record, value, columnIndex) => (
    <Renderer reportDefinition={ reportDefinition }
              headers={ headers }
              record={ record }
              value={ value }
              columnIndex={ columnIndex } />
  );

  return CellRenderer;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class TableView extends Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
  }

  render() {
    const { data, onPageNext, scrollResets } = this.props;

    const { headers=[], records=[] }         = data.reportViewResults || {};
    const reportDefinition                   = data.reportDefinition || {};

    const cloaker = (index) => (
      `${headers[index]}`.startsWith(refPrefix)
    );

    return (
      <SortableTable  { ...scrollResets }
                      tableId={`report_${reportDefinition.id}`}
                      headerLabels={ headers }
                      records={ records }
                      cellRenderer={ cellRenderer(reportDefinition, headers) }
                      onPageNext={ onPageNext }
                      onSort={ this.handleSort }
                      columnCloaker={ cloaker }
                      remoteSort={ true }
                      className='report-table' />

    );
  }

  handleSort({ sort=[] }) {
    const { onSort,
            onPageReset,
            data: { reportDefinition, reportViewResults } }  = this.props;

    const { fields=[] }   = reportDefinition || {};
    const { headers=[] }  = reportViewResults || {};

    const offset = headers.length - fields.length;

    const reportSort = sort.map(({ index, direction }) => {
      const sortField = fields[index - offset] || {};
      return {
        ...sortField,
        order: sortOrders[direction]
      };
    });

    onPageReset();
    onSort({ sort: reportSort });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

TableView.propTypes = {
  data: PropTypes.shape({
    reportViewResults: PropTypes.shape({
      headers:  PropTypes.array,
      records:  PropTypes.arrayOf(PropTypes.array),
      fields:   PropTypes.arrayOf(PropTypes.object)
    }),
    reportDefinition: PropTypes.object
  }).isRequired,
  onPageNext:   PropTypes.func.isRequired,
  onPageReset:  PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default TableView;
