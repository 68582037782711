import React from 'react';
import PropTypes from 'prop-types';

const initialStyle = {
  'display':  'block',
  'position': 'absolute',
  'top':      '0px',
  'bottom':   '0px',
  'right':    '0px',
  'overflow': 'hidden'
};

const paddedStyle = {
  'padding': '1em 1.5em'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Main = ({ offsetLeft,
                offsetRight,
                offsetTop,
                offsetBottom,
                overflow,
                className,
                padded,
                children }) => {

  let style = {
    ...initialStyle,
    overflow,
    left:   offsetLeft,
    right:  offsetRight,
    top:    offsetTop,
    bottom: offsetBottom
  };

  if(padded) {
    style = { ...style, ...paddedStyle };
  }

  return (
    <section  style={ style }
              className={ `main-component ${className}` }>
      { children }
    </section>
  );
};

Main.defaultProps = {
  className:    '',
  offsetLeft:   '0px',
  offsetRight:  '0px',
  offsetTop:    '0px',
  offsetBottom: '0px',
  overflow:     'hidden',
  padded:       false
};

Main.propTypes = {
  offsetLeft:   PropTypes.string,
  offsetRight:  PropTypes.string,
  offsetTop:    PropTypes.string,
  offsetBottom: PropTypes.string,
  overflow:     PropTypes.string,
  className:    PropTypes.string,
  padded:       PropTypes.bool
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default Main;
