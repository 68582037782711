import React, { Component }       from 'react';
import Record                from './record';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ShowRecord extends Component {
  render() {

    return (
      <Record download={ false } { ...this.props } />
    );
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ShowRecord;