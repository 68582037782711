import React from 'react';
import Select from 'components/DateSelect';
import FieldSelectSwitcher from './FieldSelectSwitcher';

class DateSelect extends FieldSelectSwitcher {
  getInitialInputState(value) {
    return typeof value === 'string';
  }

  renderInput(transformedValue) {
    return (
      <Select value={transformedValue} onChange={this.handleChange} />
    );
  }
}

export default DateSelect;
