import React                      from 'react';
import { Redirect  }              from 'react-router-dom';
import FieldInfo                  from './FieldInfo';
import UsageInfo                  from './UsageInfo';
import OrgUsageInfo               from './OrgUsageInfo';

class DetailView extends React.Component {
  render() {
    const { loading, data, api_errors, scope } = this.props;

    const { selected }      = data;
    const record            = selected || {};
    const { forms, reports, clones } = record;

    return (
      <div>
        { api_errors && <Redirect to='./'/> }

        <FieldInfo loading={ loading } field={ record } />
        <UsageInfo loading={ loading } forms={ forms }  reports={ reports } scope={ scope } />

        {
          scope === 'admin' &&
          <OrgUsageInfo loading={ loading } fields={ clones } />
        }

      </div>
    );
  }
}

export default DetailView;
