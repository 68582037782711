import { DISMISS_ERRORS } from 'constants/ActionTypes';

export default function errorReducer(state=null, action) {
  if(action.type.match(/_ERROR$/)) {
    return action.payload.errors || null;
  } else if(action.type === DISMISS_ERRORS) {
    return null;
  } else {
    return state;
  }
}
