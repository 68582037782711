import React              from 'react';
import { Route, Switch }  from 'react-router-dom';

import NewFormView        from './NewFormView';
import EditFormView       from './EditFormView';


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FormView = (props) => {

  return (
    <Switch>
      <Route  path='/organization/affiliations/new'
              render={ () => <NewFormView { ...props } /> } />

      <Route  path='/organization/affiliations/:id/edit'
              render={ () => <EditFormView { ...props } /> } />
    </Switch>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormView;
