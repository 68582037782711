import React, { Component }     from 'react';
import PropTypes                from 'prop-types';

import Layout                   from 'components/MasterDetail';
import Item                     from 'components/admin/imports/Item';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import { imports }              from 'resources/adminResources';
import connectResource          from 'utils/connectResource';
import { getPageAllById }       from 'utils/connectors';
import { sortByAttributes }     from 'utils/sortComparators';
import { textSubstringFilter }  from 'utils/recordFilters';

import Detail                   from './Detail';
import Form                     from './Form';
import SideCard                 from './SideCard';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class ImportsContainer extends Component {

  // construction
  constructor(props) {
    super(props);

    this.handleCreate        = this.handleCreate.bind(this);
    this.handleUpdate        = this.handleUpdate.bind(this);
    this.handleDelete        = this.handleDelete.bind(this);
    this.handleDisableEdit   = this.handleDisableEdit.bind(this);
    this.handleDisableDelete = this.handleDisableDelete.bind(this);
  }

  // handlers
  handleCreate(record) {
    const { create } = this.props;
    return create(record);                      // MasterDetail needs promise
  }
  handleUpdate(record) {
    const { update } = this.props;
    return update(record, { id: record.id });   // MasterDetail needs promise
  }
  handleDelete(id) {
    const { destroy } = this.props;
    destroy(null, { id });
  }
  handleDisableEdit(id) {
    const state = this.getStateForId(id);
    return (state !== 'unmapped' && state !== 'mapped');
  }
  handleDisableDelete(id) {
    const state = this.getStateForId(id);
    return (state === 'enqueued');
  }

  // helpers
  getRecordsArray() {
    const { data }    = this.props;
    const { imports } = { ...data };
    return imports || [];
  }
  getRecordsObject() {
    const records = this.getRecordsArray();
    const object  = records.reduce((map, item) => {
      map[item.id] = item;
      return map;
    }, {});
    return object;
  }
  getStateForId(id) {
    const records   = this.getRecordsObject();
    const record    = records[id];
    const { state } = { ...record };
    return state;
  }

  // rendering
  render() {
    const records     = this.getRecordsArray();
    const filterFn    = textSubstringFilter('organization_name', 'name');
    const sortFn      = sortByAttributes('organization_name', 'name');

    return (
      <Layout masterItemComponent={ Item }
              detailComponent={ Detail }
              formComponent={ Form }
              sideCardComponent={ SideCard }
              records={ records }
              filterRecord={ filterFn }
              listSort={ sortFn }
              onCreate={ this.handleCreate }
              onUpdate={ this.handleUpdate }
              onDelete={ this.handleDelete }
              disableEdit={ this.handleDisableEdit }
              disableDelete={ this.handleDisableDelete }
              { ...this.props } />
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

ImportsContainer.propTypes = {
  data:       PropTypes.object.isRequired,
  create:     PropTypes.func.isRequired,
  update:     PropTypes.func.isRequired,
  destroy:    PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

const connectedComponent = connectResource(ImportsContainer, {
  key: ({ page, query }) => [page, query],
  connectors: {
    imports: getPageAllById(imports)
  },
  mapDispatchToProps : { ...imports.actions }
});

export default withSearch(withPagination(connectedComponent));
