import React                    from 'react';
import PropTypes                from 'prop-types';

import MasterDetail                                     from 'components/MasterDetail';
import { reportDefinitions, cloneRecords, categoriesByType }  from 'resources/organizationResources';
import withCategory                                     from 'hocs/withCategory';
import withPagination                                   from 'hocs/withPagination';
import withSearch                                       from 'hocs/withSearch';
import connectResource                                  from 'utils/connectResource';
import { textSubstringFilter }                          from 'utils/recordFilters';
import { sortByAttributes }                             from 'utils/sortComparators';
import { getPageAllById, getAllById }                   from 'utils/connectors';

import SideCardView                                     from '../SideCardView';
import DetailView                                       from './DetailView';
import FormView                                         from './FormView';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const App = ({ data, destroy, update, create, clone, ...forwardProps }) => {
  const reportDefinitions = data.reportDefinitions || [];

  return (
    <MasterDetail detailComponent={ DetailView }
                  formComponent={ FormView }
                  sideCardComponent={ SideCardView }
                  records={ reportDefinitions }
                  onDelete={ id => destroy(null, { id }) }
                  onUpdate={ record => update(record, { id: record.id }) }
                  onCreate={ create }
                  onClone={ id => clone(null, { cloneable_type: 'report_definitions', cloneable_id: id }) }
                  resource='report_definitions'
                  listSort={ sortByAttributes('name') }
                  filterRecord={ textSubstringFilter('name') }
                  categorizable={ true }
                  categoriesByType={ categoriesByType }
                  data={ data }
                  manageOnNested={ true }
                  versioned={ true }
                  { ...forwardProps } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

App.propTypes = {
  data: PropTypes.shape({
    reportDefinitions: PropTypes.array
  }).isRequired,

  destroy: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query, category }) => [page, query, category],
  connectors: {
    reportDefinitions: getPageAllById(reportDefinitions),
    categoriesByType: getAllById(categoriesByType, () => ({ type: 'reportDefinition' }), true)
  },
  mapDispatchToProps: {
    ...reportDefinitions.actions,
    ...cloneRecords.actions
  }
});

export default withSearch(withCategory(withPagination(connectedComponent)));
