import React, { Component, Fragment }       from 'react';
import PropTypes                            from 'prop-types';
import RichTextEditor                       from 'react-rte';
import { Button, Header, Icon, Segment }    from 'semantic-ui-react';

import _                                    from 'lodash';

import DefinitionItem                       from './Item';
import DeleteButton                         from './DeleteButton';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function getDefault(props) {
  const { eventMetadata } = props;
  const events            = Object.keys(eventMetadata);
  const bvalue = RichTextEditor.createValueFromString('', 'markdown');
  const svalue = RichTextEditor.createValueFromString('', 'markdown');
  return {
    name:             events[0],
    subject_template: svalue,
    body_template:    bvalue,
    conditions:       {},
    recipients:       []
  };
}

function prepareForApi(definition) {
  const tmp = prepareForCompare(definition);
  return {
    ...tmp,
    conditions: JSON.stringify(definition.conditions)
  };
}

function prepareForCompare(definition) {
  const btext = definition.body_template.toString('markdown');
  const stext = definition.subject_template.toString('markdown');
  return {
    ...definition,
    body_template:    btext.replace(/\\_/g, '_'),
    subject_template: stext.replace(/\\_/g, '_')
  };
}

function prepareForUi(definition) {
  const btext = definition.body_template    || '';
  const stext = definition.subject_template || '';
  return {
    ...definition,
    body_template:    RichTextEditor.createValueFromString(btext, 'markdown'),
    subject_template: RichTextEditor.createValueFromString(stext, 'markdown')
  };
}

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class DefinitionList extends Component {
  // We're using this feature of React to ensure the initial
  // state loads correctly. Probably there's a better way to
  // do this overall, but this is the least invasive way to
  // ensure we pick up the initial value from the many,
  // many prop changes we get when the form loads the
  // associated record's data.
  //
  // To limit the long-term expense of this function, we only
  // modify state when the converted value differs from our
  // internal state. This should make our intercessions useful
  // for picking up the initial data load and a noop for any
  // user actions.
  static getDerivedStateFromProps(props, state) {
    const pDefinitions = props.definitions;
    const sDefinitions = state.prevDefinitions;

    if (!_.isEqual(pDefinitions, sDefinitions)) {
      return {
        definitions: _.mapValues(pDefinitions || {}, prepareForUi),
        prevDefinitions: pDefinitions
      };
    }
    return null;
  }

  // construction
  constructor(props){
    super(props);

    this.state = {
      addingDefinition: {},
      adding:           false,
      definitions:      {},
      prevDefinitions:  {}
    };

    this.handleDefinitionCreate = this.handleDefinitionCreate.bind(this);
    this.handleDefinitionDelete = this.handleDefinitionDelete.bind(this);
    this.handleDefinitionUpdate = this.handleDefinitionUpdate.bind(this);
    this.handleExistingChange   = this.handleExistingChange.bind(this);
    this.handleNewChange        = this.handleNewChange.bind(this);
    this.handleNewClick         = this.handleNewClick.bind(this);
    this.handleNewRemove        = this.handleNewRemove.bind(this);
  }

  // handlers
  handleDefinitionCreate() {
    const { onCreate }          = this.props;
    const { addingDefinition }  = this.state;
    const definition            = prepareForApi(addingDefinition);
    const callbackFn            = () => { this.setState({ adding: false }); };
    onCreate(definition, callbackFn);
  }
  handleDefinitionDelete(id) {
    const { onDelete } = this.props;
    onDelete(id);
  }
  handleDefinitionUpdate(id) {
    const { onUpdate }      = this.props;
    const { definitions }   = this.state;
    const definition        = prepareForApi(definitions[id]);
    onUpdate(definition);
  }
  handleExistingChange(definition) {
    const { definitions } = this.state;
    const { id }          = definition;
    this.setState({
      definitions: {
        ...definitions,
        [id]: definition
      }
    });
  }
  handleNewChange(addingDefinition) {
    this.setState({ addingDefinition });
  }
  handleNewClick(evt) {
    evt.preventDefault();
    this.setState({
      adding:           true,
      addingDefinition: getDefault(this.props)
    });
  }
  handleNewRemove(evt) {
    evt.preventDefault();
    this.setState({
      adding:           false,
      addingDefinition: {}
    });
  }

  // rendering
  render() {
    const { eventMetadata,
            recipientFields,
            formFields,
            users }           = this.props;
    const { adding,
            addingDefinition,
            definitions,
            prevDefinitions } = this.state;
    const sortedDefinitions   = Object.values(definitions).reverse();

    return (
      <Fragment>
        { !adding &&
            <Button icon
                    labelPosition='right'
                    onClick={ this.handleNewClick } >
              Add Notification
              <Icon name='add' />
            </Button>
        }
        { adding &&
            <Segment secondary>
              <Header as='h3'>
                New Notification
              </Header>
              <DefinitionItem definition={ addingDefinition }
                              eventMetadata={ eventMetadata }
                              recipientFields={ recipientFields }
                              formFields={ formFields }
                              users={ users }
                              onChange={ this.handleNewChange } />
              <br/>
              <Button.Group>
                <Button positive onClick={ this.handleDefinitionCreate }>save</Button>
                <Button.Or />
                <Button onClick={ this.handleNewRemove }>cancel</Button>
              </Button.Group>
            </Segment>
        }
        { sortedDefinitions.map(definition => {
            const { id }          = definition;
            const currDefinition  = prepareForCompare(definition);
            const prevDefinition  = prevDefinitions[definition.id];
            const hasChanged      = !_.isEqual(currDefinition, prevDefinition);

            return definition && (
              <Segment key={ id }>
                <DefinitionItem definition={ definition }
                                eventMetadata={ eventMetadata }
                                recipientFields={ recipientFields }
                                formFields={ formFields }
                                users={ users }
                                onChange={ this.handleExistingChange } />
                <br/>
                <Button.Group>
                  <Button positive
                          disabled={ !hasChanged }
                          // loading={ hasChanged && saving }
                          onClick={ () => this.handleDefinitionUpdate(id) }
                    >save</Button>
                  <Button.Or />
                  <DeleteButton onConfirm={ () => this.handleDefinitionDelete(id) } />
                </Button.Group>
              </Segment>
          );
        })}
      </Fragment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

DefinitionList.propTypes = {
  definitions:      PropTypes.object.isRequired,
  eventMetadata:    PropTypes.object.isRequired,
  recipientFields:  PropTypes.array.isRequired,
  users:            PropTypes.array.isRequired,
  onCreate:         PropTypes.func.isRequired,
  onUpdate:         PropTypes.func.isRequired,
  onDelete:         PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default DefinitionList;
