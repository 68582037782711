// AUTHENTICATION
export const AUTH_FAILED      = 'AUTH_FAILED';
export const DEAUTH_REQUESTED = 'DEAUTH_REQUESTED';
export const AUTH_REFRESH     = 'AUTH_REFRESH';

// ORGDOMAIN
export const SET_ORGDOMAIN    = 'SET_ORGDOMAIN';

//ERRORS
export const DISMISS_ERRORS   = 'DISMISS_ERRORS';

// GENERIC VALIDATION ERROR
export const VALIDATION_ERROR = 'VALIDATION_ERROR';

// RESET THE ORG STATE (useful for impersonation)
export const RESET_ORG        = 'RESET_ORG';
