import React, { useState, useRef, useEffect } from 'react';
import PropTypes                              from 'prop-types';
import isFunction                             from 'lodash/isFunction';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const initialStyle = {
  overflow: 'auto'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------
const ScrollPane = ({ scrollTop,
                      scrollLeft,
                      styleOverrides,
                      onScrollX,
                      onScrollY,
                      children,
                      ...forwardProps }) => {

  // Prevents React from complaining about unknown event handers.
  delete forwardProps.onScrollX;
  delete forwardProps.onScrollY;
  delete forwardProps.scrollTop;
  delete forwardProps.scrollLeft;

  const [top, setTop]   = useState(0);
  const [left, setLeft] = useState(0);
  const scrollPaneEl    = useRef(null);
  const style           = { ...initialStyle, ...styleOverrides };

  useEffect(() => {
    if(scrollTop !== undefined && scrollTop !== null) {
      scrollPaneEl.current.scrollTop = +scrollTop;
    }
  }, [scrollTop]);

  useEffect(() => {
    if(scrollLeft !== undefined && scrollLeft !== null) {
      scrollPaneEl.current.scrollLeft = +scrollLeft;
    }
  }, [scrollLeft]);

  const handleScroll = (evt) => {
    if(isFunction(onScrollX)) {
      const { scrollLeft }  = scrollPaneEl.current;

      if(left !== scrollLeft) {
        onScrollX(evt);
        setLeft(scrollLeft);
      }
    }

    if(isFunction(onScrollY)) {
      const { scrollTop }   = scrollPaneEl.current;

      if(top !== scrollTop) {
        onScrollY(evt);
        setTop(scrollTop);
      }
    }
  };

  return (
    <div  { ...forwardProps }
          ref={ scrollPaneEl }
          onScroll={ handleScroll }
          style={ style }>

      { children }
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ScrollPane.defaultProps = {
  styleOverrides: {}
};

ScrollPane.propTypes = {
  onScrollY:      PropTypes.func,
  onScrollX:      PropTypes.func,
  scrollTop:      PropTypes.number,
  scrollLeft:     PropTypes.number,
  styleOverrides: PropTypes.object
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ScrollPane;
