import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ClientFormResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/clients/:client_id/client_forms';

  id                = undefined;
  form_id           = undefined;
  client_id         = undefined;
  administrator_id  = undefined;
  completed_at      = null;
  created_at        = null;
  updated_at        = null;
  answers           = null;

  pk() {
    return this.id && this.id.toString();
  }
}
