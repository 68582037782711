import React      from 'react';
import PropTypes  from 'prop-types';

import DataList from 'components/DataList';

function diffValue(value) {
  if(value === 'null') {
    return null;
  } else if(value === true) {
    return 'True';
  } else if(value === false) {
    return 'False';
  } else if(typeof value === 'object') {
    return JSON.stringify(value);
  } else {
    return value;
  }
}

function toDatalist(attributeEntries, action) {
  return attributeEntries.map(([key, { old: o, new: n }]) => (
    {
      label: key,
      value: (
        <>
          { action == 'update' &&
            <span style={{ backgroundColor: '#ffd7d5', textDecoration: 'line-through' }}>
              { diffValue(o) }
            </span>
          }
          <span style={{ backgroundColor: '#abf2bc' }}>
            { diffValue(n) }
          </span>
        </>
      )
    }
  ));
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const Changes = ({ attributes, action }) => {
  const attributeEntries = Object.entries(attributes || {});

  return (
    <pre>
      <DataList items={ toDatalist(attributeEntries, action) } />
    </pre>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

Changes.defaultProps  = {
  attributes: {},
  action:     'update'
};

Changes.propTypes     = {
  attributes: PropTypes.object,
  action:     PropTypes.string
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default Changes;
