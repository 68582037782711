import React        from 'react';
import PropTypes    from 'prop-types';
import { Divider }  from 'semantic-ui-react';

import UserResource             from 'resources/organization/UserResource';
import PermissionGroupResource  from 'resources/organization/PermissionGroupResource';
import useOrganizationResource  from 'hooks/useOrganizationResource';

import ConditionalEditor, {
       ConditionGroup,
       Control,
       Item,
       Registry,
       ItemProvider,
       GroupProvider }  from '@coactionnet/conditional-editor';

import ConditionDateSelect  from 'components/ConditionSelect/DateSelect';
import ConditionTimeSelect  from 'components/ConditionSelect/TimeSelect';

import SmartGroupButton from './SmartGroupButton';

// -----------------------------------------------------
// Helper / Config Functions
// -----------------------------------------------------

// This should not be used directly.  Instead, use the getFields function.
const fields = [
  {
    label:        'Created By',
    value:        'whodunnit',
    fieldType:    'selectOne',
    dataType:     'id',
    fieldOptions: []
  }, {
    label:        'Created At',
    value:        'created_at',
    fieldType:    'date',
    dataType:     'datetime'
  }, {
    label:        'Record Type',
    value:        'item_type',
    fieldType:    'selectOne',
    dataType:     'string',
    fieldOptions: [
      { label: 'People',        value: 'Client' },
      { label: 'Form Response', value: 'ClientForm' }
    ]
  }
];

const creatorFieldIndex = 0;

function getFields(users, permissionGroups) {
  const userOptions = users.map(({ id, name }) => (
    {
      value: JSON.stringify({ id, type: 'User' }),
      label: name
    }
  ));

  const permissionGroupOptions = permissionGroups.map(({ id, name }) => (
    {
      value: JSON.stringify({ id, type: 'PermissionGroup' }),
      label: name
    }
  ));

  fields[creatorFieldIndex].fieldOptions = [
    ...userOptions,
    ...permissionGroupOptions
  ];

  return fields;
}

function getSortedCollection(collection) {
  return Object
          .values({ ...collection })
          .sort(({ name: a }, { name: b }) => a > b ? 1 : -1);
}

function getRegistry() {
  const itemProvider  = new ItemProvider(Item, 'Smart Condition');
  const groupProvider = new GroupProvider(ConditionGroup, 'Smart Condition Group');
  const registry      = new Registry();

  registry.add(itemProvider.getAdapter());
  registry.add(groupProvider.getAdapter());

  return registry;
}

function wrapOnChange(onChange, name) {
  return (conditions) => {
    onChange(null, { name, value: conditions });
  };
}

function ValueSelect(props) {
  return (
    <Control.SelectInput { ...props } includeBlankOption={true} />
  );
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const SmartGroupConditions = ({ name, conditions, onChange }) => {
  const registry                = getRegistry();
  const users                   = useOrganizationResource(UserResource.listShape(), {});
  const permissionGroups        = useOrganizationResource(PermissionGroupResource.listShape(), {});
  const sortedUsers             = getSortedCollection(users);
  const sortedPermissionGroups  = getSortedCollection(permissionGroups);
  const fields                  = getFields(sortedUsers, sortedPermissionGroups);
  const handleChange            = wrapOnChange(onChange, name);

  const conditionsEmpty   = Object.keys(conditions).length === 0;
  console.log({ permissionGroups });
  if(conditionsEmpty) {
    return (
      <div>
        <Divider />
        <SmartGroupButton onClick={ handleChange } />
        <span>&nbsp;Enable SmartGroup</span>
        <Divider />
      </div>
    );
  } else {
    return (
      <div className='conditions-editor'>
        <ConditionalEditor  conditions={ conditions }
                            registry={ registry }
                            fields={ fields }
                            valuesDateControl={ ConditionDateSelect }
                            valuesTimeControl={ ConditionTimeSelect }
                            valuesSelectOneControl={ ValueSelect }
                            onChange={ handleChange } />
      </div>
    );
  }
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SmartGroupConditions.defaultProps = {
  conditions: { and: [] }
};

SmartGroupConditions.propTypes = {
  conditions: PropTypes.object,
  name:       PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SmartGroupConditions;
