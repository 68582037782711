import React from 'react';
import { Button, Dropdown, Popup }  from 'semantic-ui-react';

function CategoryButton(onCategory, showCategories, handleShowCategories) {
  if (onCategory === null || onCategory === undefined) { return null; }

  const Btn = <Button
    active={showCategories}
    floated='right'
    icon='clone'
    size='small'
    compact
    onClick={handleShowCategories}
    basic />;

    return <Popup trigger={Btn}
      content={'Show Categories'}
      position='right center' />;
}

function CategorySelect(availableCategories, handleCategory, selectedCategory) {
  if (availableCategories.length === 0) {
    return null;
  }
  const sortedOptions = availableCategories.sort((a, b) => a.name.localeCompare(b.name));
  const options = Object.keys(sortedOptions).map( key => 
      { 
        return { 
          value: availableCategories[key].id, 
          text: availableCategories[key].name
        };
      }
    );

  return <div className="record-list-category">
    <Dropdown placeholder='Filter Category'
              labeled
              clearable
              fluid
              icon='folder'
              className='icon'
              selection
              options={ options }
              onChange={ (evt, { value }) => handleCategory(value) }
              selectOnBlur={ false }
              value={ selectedCategory } />
  </div>;
}

export { CategoryButton, CategorySelect };
