import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Header }                     from 'semantic-ui-react';

import connectResource                            from 'utils/connectResource';
import { getById }                                from 'utils/connectors';
import { publicFormAnswers, publicFormResponses } from 'resources/organizationResources';
import PublicFormInstance                         from './PublicFormInstance';
import { orderBy }                                from 'lodash';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class DetailView extends Component {

  constructor(props) {
    super(props);

    this.handleDelete  = this.handleDelete.bind(this);
  }
  render() {
    const { data: { selected } } = this.props;

    const { name,
            public_forms=[],
            fields=[] }  = selected || {};

    const filteredFields = fields.filter((field) => field.field_type !== 'presentation');
    const orderedPublicForms = orderBy(public_forms, 'published_at', 'desc');

    return (
      <Fragment>
        <Header as='h2'>
          { name }
        </Header>

        {
          orderedPublicForms.map(({
            id,
            access_key,
            published_at,
            expired_at,
            dow,
            time_start,
            time_end,
            is_active
          }) => (
            <PublicFormInstance key={ id }
                                publicFormId={ id }
                                accessKey={ access_key }
                                publishedAt={ published_at }
                                expiredAt={ expired_at }
                                dow={ dow }
                                timeStart={ time_start }
                                timeEnd={ time_end }
                                isActive={ is_active }
                                fields={ filteredFields }
                                onDelete={ this.handleDelete } />
          ))
        }
      </Fragment>
    );
  }

  async handleDelete(public_form_id, id) {
    const { destroy } = this.props;
    await destroy(null, { public_form_id, id });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DetailView.propTypes = {
  data:     PropTypes.object,
  loading:  PropTypes.bool
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(DetailView, {
  connectors: {
    selected: getById(publicFormAnswers, ({ match: { params: { id } } }) => id)
  },
  mapDispatchToProps: {
    destroy:  publicFormResponses.actions.destroy
  }
});
