import React              from 'react';
import { Button, Table }  from 'semantic-ui-react';
import formattedDate      from 'utils/formattedDate';

// --------------------------------------------------------
// Helper
// --------------------------------------------------------

const MAX_LENGTH = 80;

function trimContent(text) {
   return text.length > MAX_LENGTH ?
            text.substring(0, MAX_LENGTH) + '...' :
            text;
}

function expiredLabel( {expired_at, expired} ) {
    const date = formattedDate(expired_at);
    if (expired) {
        return ( <span style={{color: 'red'}}> { date } </span> );
    } else {
        return date;
    }
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const Item = ({ pinnedMessage, onDelete, onEdit })  => {
    return (
        <Table.Row key={`pinnedMessage-${pinnedMessage.id}`}>
            <Table.Cell>{ pinnedMessage.subject }</Table.Cell>
            <Table.Cell>{ trimContent(pinnedMessage.body) }</Table.Cell>
            <Table.Cell>{ formattedDate(pinnedMessage.updated_at) }</Table.Cell>
            <Table.Cell>{ expiredLabel(pinnedMessage) }</Table.Cell>
            <Table.Cell>
              <Button
                value={pinnedMessage.id}
                onClick={ onEdit }
                color='black'
                icon='write'
                size='small'
                circular
                compact
                basic />
              <Button
                value={pinnedMessage.id}
                onClick={ onDelete }
                color='black'
                icon='trash'
                size='small'
                circular
                compact
                basic />
            </Table.Cell>
        </Table.Row>
      );
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default Item;
