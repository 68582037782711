import React          from 'react';
import PropTypes      from 'prop-types';
import classnames     from 'classnames';

// import Address              from './Address';
import LegacyAddress  from './LegacyAddress';
import Attachment     from './Attachment';
import Checkbox       from './Checkbox';
import Date           from './Date';
import Time           from './Time';
import Email          from './Email';
import FreeformLong   from './FreeformLong';
import FreeformShort  from './FreeformShort';
import AutoPopulate   from './AutoPopulate';
import MarkdownEditor from './MarkdownEditor';
import Number         from './Number';
import Presentation   from './Presentation';
import SelectMany     from './SelectMany';
import SelectOne      from './SelectOne';
import Signature      from './Signature';
import Search         from './Search';
import RefPicker      from './RefPicker';

import ParameterWarning from 'components/forms/ParameterWarning';
import cast             from 'utils/cast';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const typeMap = {
  'address':        LegacyAddress,
  'autopopulate':   AutoPopulate,
  'attachment':     Attachment,
  'checkbox':       Checkbox,
  'date':           Date,
  'email':          Email,
  'freeform-long':  FreeformLong,
  'freeform-short': FreeformShort,
  'hyperlink':      FreeformShort,
  'markdown':       MarkdownEditor,
  'number':         Number,
  'presentation':   Presentation,
  'select-many':    SelectMany,
  'select-one':     SelectOne,
  'signature':      Signature,
  'time':           Time,
  'search':         Search,
  'ref-picker':     RefPicker
};

const warningMessage = 'This field is not properly configured.  Please contact an administrator for assistance.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Field = (props) => {
  const { fieldType,
          dataType,
          label,
          description,
          hasError,
          isRequired,
          isDisabled,
          onChange,
          metadata,
          parametersMapping } = props;

  const FieldImplementation = typeMap[fieldType];
  const classes             = classnames('field', {
                                required: isRequired,
                                error:    hasError
                              });

  const showLabel = fieldType !== 'presentation';

  const fieldWarningInfo = {
    metadata,
    parameters_mapping: parametersMapping
  };

  const handleChange = (evt, { name, value, error }) => {
    const castValue = cast(value, dataType);

    onChange(evt, { name, value: castValue, error });
  };

  return (
    <fieldset className={ classes }
              disabled={ isDisabled } >

      { showLabel &&
        <label>
          { label }
          <ParameterWarning formField={ fieldWarningInfo }
                            title={ warningMessage } />
        </label>
      }
      <FieldImplementation  { ...props }
                            onChange={ handleChange } />

      {
        description &&
        <div>
          <small>{ description }</small>
        </div>
      }
    </fieldset>
  );
};

Field.defaultProps = {
  label:              '',
  description:        null,
  hasError:           false,
  isRequired:         false,
  isDisabled:         false,
  dataType:           'string',
  parametersMapping:  {},
  answers:            {},
  metadata:           {}
};

Field.propTypes = {
  label:                  PropTypes.string,
  name:                   PropTypes.string,
  value:                  PropTypes.any,
  parametersMapping:      PropTypes.object,
  answers:                PropTypes.object,
  description:            PropTypes.string,
  hasError:               PropTypes.bool,
  isRequired:             PropTypes.bool,
  isDisabled:             PropTypes.bool,
  dataType:               PropTypes.string,
  metadata:               PropTypes.object,
  fieldType:              PropTypes.oneOf(Object.keys(typeMap)).isRequired,
  fieldId:                PropTypes.number.isRequired,
  onChange:               PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Field;
