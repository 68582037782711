import React, { Component } from 'react';
import { Route, Switch }    from 'react-router-dom';
import urljoin              from 'url-join';

import ApiCallBoundary  from 'components/api/ApiCallBoundary';
import DetailView       from './DetailView';
import EditFormView     from './EditFormView';
import NewFormView      from './NewFormView';
import FormsGrouped     from './FormsGrouped';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Forms extends Component {
  render() {
    const { match: { path } } = this.props;

    return (
      <ApiCallBoundary>
        <Switch>
          <Route  path={ urljoin(path, '/client_forms/sequence/:sequence_id/new/:form_id') }
                  component={ NewFormView } />

          <Route  path={ urljoin(path, '/client_forms/new/:form_id') }
                  component={ NewFormView } />

          <Route  path={ urljoin(path, '/client_forms/:client_form_id/edit') }
                  component={ EditFormView } />

          <Route  path={ urljoin(path, '/client_forms/:client_form_id') }
                  component={ DetailView } />

          <Route  path={ path }
                  component={ FormsGrouped } />
        </Switch>
      </ApiCallBoundary>
    );
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Forms;
