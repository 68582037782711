import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ArchiveResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/:archivable_type/:archivable_id/archive';

  id    = undefined;
  type  = '';

  pk() {
    return this.id && this.id.toString();
  }
}
