import React                    from 'react';
import { Button, Label, Icon }  from 'semantic-ui-react';
import isString                 from 'lodash/isString';

import formattedDate            from 'utils/formattedDate';
import formattedTime            from 'utils/formattedTime';

import './styles.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const fieldTypeRenderers = {
  'address':      AddressDisplay,
  'email':        EmailDisplay,
  'attachment':   AttachmentDisplay,
  'select-many':  SelectManyDisplay,
  'date':         DateDisplay,
  'datetime':     DateTimeDisplay,
  'time':         TimeDisplay,
  'signature':    SignatureDisplay,
  'hyperlink':    HyperlinkDisplay,
  'search':       SearchTermDisplay,
  'ref-picker':   SearchTermDisplay
};

// -----------------------------------------------------
// Address Value Renderer
// -----------------------------------------------------

function AddressDisplay({ answer={}, onDetail }) {
  let addr = answer.value;

  if(isString(addr)) {
    try {
      addr = JSON.parse(addr);
    } catch(e) {
      addr = { text: addr };
    }
  }

  const { location, text } = addr || {};

  return (
    <div className='addrRenderWrapper'>
      <div className='addrRenderText' title={ text }>{ text }</div>
      {
        location && !!location.length && onDetail &&
        <Button basic
                onClick={ onDetail }
                size='mini'>Show Map</Button>
      }

      {(!location || !location.length) &&
        <Label size='tiny'>Unverified Location</Label>
      }
    </div>
  );
}

// -----------------------------------------------------
// Email Value Renderer
// -----------------------------------------------------

function EmailDisplay({ answer={} }) {
  const { value, display_value } = answer;

  return (
    <a href={ `mailto:${value}` } title={ display_value }>
      { display_value }
    </a>
  );
}

// -----------------------------------------------------
// Attachment Value Renderer
// -----------------------------------------------------

function AttachmentDisplay({ answer: { value={} }={} }) {
  const { filename, url } = value;

  if (url) {
    return (
      <a  href={ url }
          onClick={ (evt) => evt.stopPropagation() }
          target='_blank'
          rel="noopener noreferrer">

        <Icon name='file alternate outline' />
        { filename }
      </a>
    );
  }
  return null;
}

// -----------------------------------------------------
// Select-Many Renderer
// -----------------------------------------------------

function SelectManyDisplay({ answer:{ display_value='' }={} }) {
  return (
    <span title={ display_value }>{ display_value }</span>
  );
}

// -----------------------------------------------------
// Date Renderer
// -----------------------------------------------------

function DateDisplay({ answer: { value }={} }) {
  return (
    <span>
      { formattedDate(value, 'date', '') }
    </span>
  );
}

// -----------------------------------------------------
// DateTime Renderer
// -----------------------------------------------------

function DateTimeDisplay({ answer: { value }={} }) {
  return(
    <span>
      { formattedDate(value, 'datetime_long', '') }
    </span>
  );
}

// -----------------------------------------------------
// Time Renderer
// -----------------------------------------------------

function TimeDisplay({ answer: { value, metadata: { format = '24-hour' }={} }={} }) {
  return(
    <span>
      { formattedTime(value, format, '') }
    </span>
  );
}

// -----------------------------------------------------
// Signature Renderer
// -----------------------------------------------------

function SignatureDisplay({ answer:{ value, showSignature = false }={} }) {
  const isEmpty = !value || !value.length;

  if(!isEmpty) {
    return (
      <span>
        { showSignature && <img src={ value } alt='Signature' /> }
        { !showSignature && <i className="check icon"></i> }
      </span>
    );
  }

  return (
    <span><i className="x icon"></i></span>
  );
}

// -----------------------------------------------------
// Hyperlink Renderer
// -----------------------------------------------------

function HyperlinkDisplay({ answer: { value='' } ={} }) {
  const HTTP_PATTERN = /^https?:\/\//;
  const DOMAIN_PATTERN = /^(?:https?:)?(?:\/\/)?([^/?]+)/;

  if(value) {
    const url = HTTP_PATTERN.test(value) ? value : 'https://' + value;
    const matches = url.match(DOMAIN_PATTERN);
    const domain = matches ? matches[1] : value;

    return (
      <a  href={ url }
          onClick={ (evt) => evt.stopPropagation() }
          target='_blank'
          rel='noopener noreferrer'>

        { domain }
        <Icon name='external alternate' />
      </a>
    );
  }
  return null;
}

// -----------------------------------------------------
// Search Term Renderer
// -----------------------------------------------------

function SearchTermDisplay({ answer: { value }={} }) {
  let title = value;

  try {
    const searchResult = JSON.parse(value);
    title = searchResult?.title;
  } catch(e) {
    console.warn('could not deserialize search result:', value);
    console.warn(e);
  }

  return (
    <span>{ title }</span>
  );
}

// -----------------------------------------------------
// Default Renderer
// -----------------------------------------------------

function DefaultDisplay({ answer={} }) {
  const { display_value, value } = answer;
  const display = display_value || value;

  return (
    <span title={ display }>
      { display }
    </span>
  );
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------


export default function AnswerRenderer({ answer }) {
  const { field_type }  = answer;
  const Renderer        = fieldTypeRenderers[field_type] || DefaultDisplay;

  return <Renderer answer={ answer } />;
}
