import React      from 'react';
import PropTypes  from 'prop-types';
import { List }   from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ListItemView = ({ record }) => {
  const { name, path=[] } = record;
  const prefix            = `${path.map(() => '⊢').join('')} `;

  return (
    <List.Header>
      <span>{prefix}</span>{ name }
    </List.Header>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ListItemView.propTypes = {
  record: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ListItemView;
