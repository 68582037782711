import React, { Component } from 'react';

import FormGroupDisplay from './FormGroupDisplay';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormGroup extends Component {
  constructor () {
    super();

    this.state = { expanded: false };

    this.handleToggleExpanded  = this.handleToggleExpanded.bind(this);
  }

  render () {
    const { match, formGroup, forms } = this.props;
    const { expanded }                = this.state;
    const form                        = forms[formGroup.id] || {};
    const { name }                    = form;
    const { last_administered_by }    = formGroup;
    const { first_name='', 
            last_name='' }            = last_administered_by || {};

    const lastAdministeredBy = `${first_name} ${last_name}`;

    return (
      <FormGroupDisplay match={ match }
                        formGroup={ formGroup }
                        formName={ name }
                        lastAdministeredBy={ lastAdministeredBy }
                        expanded={ expanded }
                        toggleExpanded={ this.handleToggleExpanded } />
    );
  }

  handleToggleExpanded(evt) {
    evt.preventDefault();
    const { expanded } = this.state;

    this.setState({ expanded: !expanded });
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormGroup;
