import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Form, Select, TextArea }     from 'semantic-ui-react';

import VariablesInfo  from './VariablesInfo';
import nextVariable   from 'utils/calculations/nextVariable';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const VALID_KEYS = /[0-9+\-*/%.()\s]|ArrowLeft|ArrowRight|Backspace/i;
const field_type = 'Field';
const calculation_type = 'Calculation';

function fieldOptions(currentId, fields, calculations=[]) {
  const numeric_fields = fields
                         .filter(({ data_type }) => (data_type === 'numeric'))
                         .map(({ name, id }) => ({ text: name, id: id, type: field_type }));

  const options = [
                    { text: field_type.toUpperCase(), disabled: true },
                    ...numeric_fields,
                  ];

  const numeric_calculations = calculations
                               .filter(({ id }) => (id !== currentId))
                               .map(({ name, id }) => ({ text: name, id: id, type: calculation_type }));

  if (numeric_calculations.length > 0){
    options.push({ text: calculation_type.toUpperCase(), disabled: true });
    numeric_calculations.map((calculation) => options.push(calculation));
  }

  return options.map((option, index) => ({ value: index, key: index, ...option  }));
}

function findOrAssignVariable(variables, id, type) {
  const variable = variables.find(({ record_type, record_id }) => ( id === record_id && record_type === type));

  if(variable) {
    return variable.name;
  }

  const nextVariableName = nextVariable(variables);
  variables.push({ name: nextVariableName, record_id: id, record_type: type });

  return nextVariableName;
}

// This allows us to remove variables from our collection, when they no longer
// exist in the equation.
function updateVariablesWithEquation(variables, equation) {
  const variablesInUse = new Set(equation.split(VALID_KEYS));
  return variables.filter(({ name }) => variablesInUse.has(name));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class NumericEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: {
        start:  0,
        end:    0
      }
    };

    this.textarea = React.createRef();

    this.handleNameChange         = this.handleNameChange.bind(this);
    this.handleFieldSelectChange  = this.handleFieldSelectChange.bind(this);
    this.handleCalculationChange  = this.handleCalculationChange.bind(this);
    this.handleKeyDown            = this.handleKeyDown.bind(this);

    this.handleEquationFieldSelectionChange =
      this.handleEquationFieldSelectionChange.bind(this);
  }

  render() {
    const { fields, calculations, calculation }   = this.props;
    const { id, name='', equation='', variables } = calculation;

    return (
      <Fragment>
        <Form.Field>
          <label>Name</label>
          <Form.Input name='name'
                      value={ name }
                      onChange={ this.handleNameChange } />
        </Form.Field>

        <Form.Field>
          <label>Select a field to insert</label>
          <Select options={ fieldOptions(id, fields, calculations) }
                  selectOnBlur={ false }
                  onChange={ this.handleFieldSelectChange }/>
        </Form.Field>

        <Form.Field>
          <label>Equation</label>

          <TextArea name='equation'
                    value={ equation }
                    ref={ this.textarea }
                    onChange={ this.handleCalculationChange }
                    onKeyDown={ this.handleKeyDown }
                    onKeyUp={ this.handleEquationFieldSelectionChange }
                    onClick={ this.handleEquationFieldSelectionChange } />

          <VariablesInfo variables={ variables } fields={ fields } calculations={ calculations } />

        </Form.Field>
      </Fragment>
    );
  }

  handleNameChange(evt, { value }) {
    const { calculation, onChange } = this.props;
    const nextCalculation           = { ...calculation, name: value };
    onChange({ calculation: nextCalculation });
  }

  handleFieldSelectChange(evt, { value: index, options }) {
    const selectedOption = options[index];
    const { id, type }   = selectedOption;

    const { calculation, onChange }         = this.props;
    const { selection: { start, end } }     = this.state;
    const { variables=[], equation='' }     = calculation;

    const front           = equation.slice(0, start);
    const back            = equation.slice(end);
    const nextVariables   = [ ...variables ];
    const variable        = findOrAssignVariable(nextVariables, id, type);
    const nextEquation    = `${front} ${ variable } ${back}`;

    const nextCalculation = {
      ...calculation,
      equation:   nextEquation,
      variables:  nextVariables
    };

    onChange({ calculation: nextCalculation });

    this.textarea.current.focus();
  }

  handleCalculationChange({ target }, { value }) {
    const { calculation, onChange }                     = this.props;
    const { selectionStart: start, selectionEnd: end }  = target;
    const { variables=[] }                              = calculation;

    const nextVariables   = updateVariablesWithEquation(variables, value);
    const nextCalculation = {
      ...calculation,
      variables: nextVariables,
      equation: value
    };

    this.setState({
      selection: { start, end }
    });

    onChange({ calculation: nextCalculation });
  }

  handleKeyDown(evt) {
    const { key } = evt;
    if(key && !VALID_KEYS.test(key)) {
      evt.preventDefault();
    }
  }

  handleEquationFieldSelectionChange({ target }) {
    const { selectionStart: start, selectionEnd: end } = target;

    this.setState({
      selection: { start, end }
    });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

NumericEditor.defaultProps = {
  loading:    false,
  equation:   '',
  variables:  []
};

NumericEditor.propTypes = {
  loading:    PropTypes.bool,
  calculation:  PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    equation:   PropTypes.string,
    variables:  PropTypes.array
  }),

  fields:     PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    data_type:  PropTypes.string
  })).isRequired,

  onChange:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default NumericEditor;
