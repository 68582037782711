import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class HouseholdMemberResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/clients/:client_id/household_members';

  id                = undefined;
  client_id         = undefined;
  name              = '';
  dob               = null;
  gender            = null;
  relationship      = null;

  pk() {
    return this.id && this.id.toString();
  }
}
