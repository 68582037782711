import React              from 'react';
import PropTypes          from 'prop-types';
import { Route, Switch }  from 'react-router-dom';
import urljoin            from 'url-join';

import ReportView         from './ReportView';
import FormView           from '../ReportViews/FormView/';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = ({ match: { path } }) => {
  return (
    <Switch>
    	<Route  path={ urljoin(path, '/views/new') }
              component={ FormView } />
      <Route  path={ urljoin(path, '/views/:viewId/edit') }
              component={ FormView } />
      <Route  path={ urljoin(path, '/views/:viewId') }
              component={ ReportView } />
      <Route  component={ ReportView }/>
    </Switch>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DetailView.propTypes = {
  match:  PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DetailView;