import moment               from 'moment';
import { contentNamespace } from './content';

const dateFormats = contentNamespace('date_formats');

const formattedDate = (date, formatName='datetime_long', fallback='Never') => {
  const format = dateFormats(formatName);
  return date ? moment(date).format(format) : fallback;
};

export default formattedDate;
