import React      from 'react';
import PropTypes  from 'prop-types';

import { Table, Button, Icon }  from 'semantic-ui-react';

import cast         from 'utils/cast';
import EditButton   from './EditButton';
import DeleteButton from './DeleteButton';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const draggingStyle = {
  userSelect: 'none',
  background: 'lightyellow'
};

const baseStyle = {
  transition: 'background 0.75s'
};

function getDraggableStyle(isDragging, draggableStyle) {
  const style = { ...draggableStyle, ...baseStyle };

  if(isDragging) {
    return { ...style, ...draggingStyle,  };
  }

  return style;
}

function findField(fields, fieldId) {
  const strFieldId = cast(fieldId, 'string');

  return  fields.find(({ value }) => (cast(value, 'string') === strFieldId))
          || {};
}

function conditionsSize(condition) {
  const { and, or }   = condition;
  const logicGroup    = and || or || [];

  const conditionLabels = logicGroup.map((group) => {
    const { conditions, scope } = group;

    if(conditions && scope) {
      return 1;
    } else {
      return conditionsSize(group);
    }
  });

  return conditionLabels.reduce((a, b)=> (a + b), 0);
}

function conditionSummary(condition, fields) {
  const { and, or }   = condition;
  const logicGroup    = and || or || [];
  const logicOpLabel  = (and && 'All') || (or && 'Any') || 'Unknown';

  const conditionLabels = logicGroup.map((group) => {
    const { conditions, scope } = group;

    if(conditions && scope) {
      const [lhs, rhs]    = conditions.and;
      const fieldId       = lhs.value;
      const field         = findField(fields, fieldId);
      const fieldLabel    = field.label;
      const { op, value } = rhs;

      return `${fieldLabel} ${op} ${value}`;
    } else {
      return conditionSummary(group, fields);
    }
  });

  return `${logicOpLabel}: (${conditionLabels.join('; ')})`;
}

function actionsSummary(actions, fields) {
  const actionLabels = actions.map(({ type, target: { id } }) => {
    const field       = findField(fields, id);
    const fieldLabel  = field.label;
    return `${type}: ${fieldLabel}`;
  });

  return actionLabels.join('; ');
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Record = ({ index, name, condition, fields, actions, summaryMode, onSave, onDelete, isDraggable, draggableProvided, draggableSnapshot }) => {
  const { dragHandleProps, draggableProps } = draggableProvided;

  const style = getDraggableStyle(
    draggableSnapshot.isDragging,
    draggableProps.style
  );

  const showCount = !!name && summaryMode;

  return (
    <Table.Row  { ...draggableProps } style={ style } >
      <Table.Cell>{ name }</Table.Cell>
      <Table.Cell>{ showCount ? conditionsSize(condition) : conditionSummary(condition, fields) }</Table.Cell>
      <Table.Cell>{ showCount ? actions.length : actionsSummary(actions, fields) }</Table.Cell>
      <Table.Cell textAlign='right'>
        { isDraggable
          ? <Icon name='bars'
                size='large'
                { ...dragHandleProps }
                tabIndex='-1' />

          : <Button.Group size='mini' { ...dragHandleProps }>
              <EditButton index={ index }
                          name={ name }
                          condition={ condition }
                          fields={ fields }
                          actions={ actions }
                          onSubmit={ onSave } />

              <DeleteButton index={ index }
                            onDelete={ onDelete } />

            </Button.Group>
        }
      </Table.Cell>
    </Table.Row>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Record.propTypes = {
  index:      PropTypes.number.isRequired,
  condition:  PropTypes.object.isRequired,
  fields:     PropTypes.array.isRequired,
  actions:    PropTypes.array.isRequired,
  onSave:     PropTypes.func.isRequired,
  onDelete:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Record;
