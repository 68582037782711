import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Header, Grid }     from 'semantic-ui-react';

import SelectionPanel from 'components/SelectionPanel';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const options = [
  { label: 'Client Forms Report', value: 'client_forms' },
  { label: 'Public Forms Report', value: 'public_forms' },
  { label: 'Fields Report',       value: 'fields' },
  { label: 'System Report',       value: 'system' },
  { label: 'Resources Report',    value: 'internal_forms' },
  { label: 'Household Report',    value: 'household' },
  { label: 'Aggregate Report',    value: 'query' },
];

const selectionPanelStyle = {
  marginTop:  '2rem'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ReportTypeSelection extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { reportDefinition }  = this.props;
    const { report_type }       = reportDefinition;

    return (
      <div>
        <Header as='h2'>Select a Report Type</Header>
        <Grid centered columns={ 2 }>
          <Grid.Column>
            <SelectionPanel name="report_type"
                            value={ report_type }
                            options={ options }
                            onChange={ this.handleChange }
                            style={ selectionPanelStyle } />
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  handleChange({ value, name }) {
    const { onChange } = this.props;
    onChange({ [name]: value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ReportTypeSelection.defaultProps = {
  reportDefinition: {},
};

ReportTypeSelection.propTypes = {
  reportDefinition: PropTypes.object,
  onChange:         PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ReportTypeSelection;
