import React            from 'react';
import {  Grid,
          Segment,
          Header,
          Divider,
          Form }        from 'semantic-ui-react';
import connectResource  from '../utils/connectResource';
import invitations      from '../resources/invitations';


const style = {
  grid: {
    'height': '100%'
  }
};

class InviteContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name:             '',
      last_name:              '',
      password:               '',
      password_confirmation:  ''
    };

    this.handleChange  = this.handleChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
  }

  render() {
    const { data: { invitation }={} } = this.props;
    const email                       = invitation ? invitation.email : '';

    const { first_name, last_name, password, password_confirmation } = this.state;

    return (
      <Grid verticalAlign='middle' centered columns='3' style={ style.grid }>
        <Grid.Row>
          <Grid.Column>
            <Header as='h2' textAlign='center'>Welcome to CoactionNet</Header>
            <Segment>
                <Header>Setup Your New Account</Header>
                <Divider />
                <Form onSubmit={ this.handleSubmit }>
                  <Form.Input id='login-email'
                              label='Email'
                              value={ email }
                              onChange={ this.handleChange }
                              disabled
                              />

                  <Form.Input label='First Name'
                              name='first_name'
                              value={ first_name }
                              onChange={ this.handleChange } />

                  <Form.Input label='Last Name'
                              name='last_name'
                              value={ last_name }
                              onChange={ this.handleChange } />

                  <Form.Input type='password'
                              label='Password'
                              name='password'
                              value={ password }
                              onChange={ this.handleChange } />

                  <Form.Input type='password'
                              label='Password Confirmation'
                              name='password_confirmation'
                              value={ password_confirmation }
                              onChange={ this.handleChange } />

                  <Form.Button type='submit' primary>Join</Form.Button>
                </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  async handleSubmit(evt) {
    evt.preventDefault();

    const { accept, match: { params: { invite_id }={} }={} } = this.props;
    const response = await accept(this.state, { invite_id });

    if(!response.errors) {
      this.props.history.push('/');
    }
  }

  handleChange(evt, { name, value }) {
    this.setState({ [name]: value });
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default connectResource(InviteContainer, {
  connectors: {
    invitation: {
      fetchData: (state, { match: { params: { invite_id }={} }={} }) => {
        return invitations.actions.get(null, { invite_id });
      },
      selectData: (state) => {
        return state.invitations;
      }
    }
  },
  mapDispatchToProps: { ...invitations.actions }
});
