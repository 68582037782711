import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';
import { Form }             from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const RecipientField = (props) => {
  const { checked, label, value, onChange } = props;

  return (
    <Fragment>
      <Form.Checkbox checked={ checked }
                     label={ label }
                     value={ value }
                     onChange={ onChange } />
    </Fragment>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

RecipientField.propTypes = {
  checked:  PropTypes.bool.isRequired,
  label:    PropTypes.string.isRequired,
  value:    PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default RecipientField;
