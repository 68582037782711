import React        from 'react';
import { Redirect } from 'react-router-dom';

import { Form, Segment, Checkbox } from 'semantic-ui-react';

import Fixed              from 'components/Fixed';
import connectForm        from 'utils/connectForm';
import FieldList          from './FieldList';
import AffiliatesSelector from './AffiliatesSelector';

import 'style/components/FormView.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const CLIENT_TYPE   = 'client';
const INTERNAL_TYPE = 'internal';

const fieldTypeOptions = [
  { text: 'Administer to clients (Publishable)',    value: CLIENT_TYPE },
  { text: 'Use for internal data (Not Publishable)', value: INTERNAL_TYPE }
];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormView extends React.Component {
  constructor(props){
    super(props);
    this.handleChange  = this.handleChange.bind(this);
    this.handleActive  = this.handleActive.bind(this);
  }

  render() {
    const { loading,
            saving,
            onSave,
            selected,
            data } = this.props;

    const fields        = Object.values(data.fields || {});
    const affiliations  = data.affiliations || {};

    const { id,
            form_fields=[],
            affiliation_ids=[],
            name='',
            description='',
            active=true,
            redirect_url= '',
            form_type } = selected || {};

    return (
      <Segment loading={ loading }>
        <Form onSubmit={ onSave } warning={! form_fields.length }>
          {
            this.props.api_errors && <Redirect to='./' />
          }

          <Form.Input label='Name'
                      name='name'
                      value={ name }
                      autoFocus
                      onChange={ this.handleChange } />

          <Form.Input label='Description'
                      name='description'
                      value={ description }
                      onChange={ this.handleChange } />

          <Form.Select  label='Form Use'
                        name='form_type'
                        value={ form_type }
                        disabled={ !!id }
                        options={ fieldTypeOptions }
                        clearable
                        selectOnBlur={ false }
                        onChange={ this.handleChange } />

          {
            affiliations &&
            <AffiliatesSelector affiliations={ affiliations }
                                affiliationIds={ affiliation_ids }
                                onChange={ this.handleChange } />
          }

          { form_type === CLIENT_TYPE &&
            <Form.Input label='Custom Redirect Url'
                        name='redirect_url'
                        value={ redirect_url }
                        onChange={ this.handleChange } />
          }

          <FieldList  fields={ fields }
                      name='form_fields'
                      formFields={ form_fields }
                      onChange={ this.handleChange } />

          <Fixed className={ 'fixed-with-toggle' }>
            <Form.Button  loading={ saving }
                          primary>Save</Form.Button>

            <div className={ 'active-toggle' }>
              Inactive
              <Checkbox toggle checked={ active } onChange={ this.handleActive } />
              Active
            </div>
          </Fixed>
        </Form>
      </Segment>
    );
  }

  handleChange(evt, { name, value }) {
    const { onUpdateState } = this.props;
    onUpdateState({ [name]: value });
  }

  handleActive(evt, { checked }) {
    const { onUpdateState } = this.props;
    onUpdateState({ active: checked });
  }
}

const initialState = {
  id:           null,
  name:         '',
  description:  '',
  active:       true,
  redirect_url: '',
  form_fields:  []
};

export default connectForm(FormView, {
  getDefault: ({ data: { selected={} } }) => (
    { ...initialState, ...(selected) }
  ),
  mapOnSave: (selected) => {
    return {
      ...selected,
      form_fields: selected.form_fields.filter(o=>!o.new)
    };
  }
});
