import React      from 'react';
import PropTypes  from 'prop-types';
import groupBy    from 'lodash/groupBy';

import indexOnAttribute from 'utils/indexOnAttribute';

// -----------------------------------------------------
// Helper Functions
// -----------------------------------------------------

const defaultOptionGroup = 'Client Fields';

function groupOptionsByForm(fieldOptions, forms) {
  const indexedForms    = indexOnAttribute(forms, 'id');
  const groupedOptions  = groupBy(fieldOptions, ({ form }) => form);

  return  Object
          .entries(groupedOptions)
          .map(([formId, options]) => {
            const form            = indexedForms[formId];
            const { name: label } = form || { name: defaultOptionGroup };
            return { label, options };
          });
}

// -----------------------------------------------------
// HOC Definition
// -----------------------------------------------------

export default function(forms) {

  // -----------------------------------------------------
  // Component Definition
  // -----------------------------------------------------

  const FieldSelect = ({  name, value, onChange, options }) => {
    const groupedOptions = groupOptionsByForm(options, forms);

    return (
      <select name={ name }
              value={ value }
              onChange={ onChange }>

        <option />

        {
          groupedOptions.map(({ label, options }) => (
            <optgroup label={ label } key={ label }>
              {
                (options || []).map(({ value, label }) => (
                  <option key={ value } value={ value }>
                    { label }
                  </option>
                ))
              }
            </optgroup>
          ))
        }
      </select>
    );
  };

  // -----------------------------------------------------
  // PropTypes
  // -----------------------------------------------------

  FieldSelect.propTypes = {
    value:    PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.array
              ]),

    name:     PropTypes.string.isRequired,
    options:  PropTypes.arrayOf(
                PropTypes.shape({
                  value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool
                  ]),
                  label: PropTypes.string
                })
              ).isRequired,

    onChange: PropTypes.func.isRequired
  };

  return FieldSelect;
}
