// -----------------------------------------------------
// Constants
// -----------------------------------------------------

const daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// -----------------------------------------------------
// External Functions
// -----------------------------------------------------
export default function formattedDow(dow) {
  if (dow.length === 0 || !dow) {
    return 'Every day';
  }

  return dow.sort((a, b) => a - b)
            .map( (index) => daysShort[index] )
            .join(', ');
}
