import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Header, Segment, Table } from 'semantic-ui-react';

import ColumnId                   from './ColumnId';
import Mappings                   from './Mappings';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class AdvancedDetail extends Component {

  // rendering
  render() {
    const { loading, record }   = this.props;
    const { destinations={},
            mappings=[],
            origins={},
            client_id_column,
            response_id_column} = record;
    const needsResponseId       = record.type !== 'client';

    return (
      <Segment loading={ loading }>
        <Header as='h3'>
          Mappings
        </Header>

        <Table basic='very' celled collapsing>
          <Table.Body>
            <ColumnId origins={ origins }
                      label='Client ID Column'
                      columnId={ client_id_column } />
            {
              needsResponseId &&
              <ColumnId origins={ origins }
                        label='Response ID Column'
                        columnId={ response_id_column } />
            }
          </Table.Body>
        </Table>

        <Mappings destinations={ destinations }
                  origins={ origins }
                  mappings={ mappings } />
      </Segment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

AdvancedDetail.propTypes = {
  loading:  PropTypes.bool.isRequired,
  record:   PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default AdvancedDetail;
