import React                from 'react';
import PropTypes            from 'prop-types';
import { Button }           from 'semantic-ui-react';
import DatePicker           from 'react-datepicker';
import moment               from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const defaultFormat       = 'MM/dd/yyyy';
const validDateOutputExpr = /^\d{4}-\d{2}-\d{2}$/;

const style = {
  button: {
    verticalAlign:  'bottom',
    marginBottom:   '0.3rem',
    marginLeft:     '-2.5rem',
    display:        'inline-block',
    boxShadow:      'none'
  }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DateSelect = ({ name, value, format, dateInterval, onChange, ...forwardProps }) => {

  const parsedDate    = Date.parse(value);
  const selectedDate  = isNaN(parsedDate)
                        ? undefined
                        : moment(value).toDate();

  const handleChange = (date) => {
    // toISOString returns a date/time string.  We only want the date part, so
    // we take the first 10 characters of the ISO 8601 string as our date.
    const value = date && moment(date).toISOString().substring(0,10);

    if(value && value.match(validDateOutputExpr)) {
      onChange(value, name);
    }
  };

  const handleClick = (evt) => {
    evt.preventDefault();
    onChange(undefined, name);
  };

  return (
    <>
      <DatePicker { ...forwardProps }
                  { ...dateInterval }
                  selected={ selectedDate }
                  dropdownMode='select'
                  placeholderText={ format }
                  dateFormat={ format }
                  onChange={ handleChange }
                  showMonthDropdown
                  showYearDropdown />

      {
        selectedDate !== undefined &&
        <Button style={ style.button }
                icon='remove'
                size='small'
                type='button'
                basic
                compact
                onClick={ handleClick } />
      }
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DateSelect.defaultProps = {
  format: defaultFormat
};

DateSelect.propTypes = {
  format:   PropTypes.string,
  value:    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DateSelect;
