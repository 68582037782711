import React      from 'react';
import PropTypes  from 'prop-types';

import { Table, Form }  from 'semantic-ui-react';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function getOptions(records) {
  return records.map((record) => ({
            key: record.id,
            value: record.id,
            text: record.name
          }));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const AddRecord = ({ forms, onAdd }) => {

  const [ selectedFormId,  setSelectedFormId ] = React.useState(null);

  const formOptions = getOptions(forms);
  const selectedForm = selectedFormId ? forms.find((form) => form.id === selectedFormId) : null;
  const fieldOptions = selectedFormId ? getOptions(selectedForm.ffs) : [];

  const handleAdd = (_, {value}) => {
    onAdd({ formId: selectedFormId, formFieldId: value });
  };


  return (
    <Table.Row>
      <Table.Cell>
        <Form.Select  selectOnBlur={ false }
                      options={ formOptions }
                      value={ selectedFormId }
                      onChange={ (_, { value }) => setSelectedFormId(value) }
                      clearable
                      search />
      </Table.Cell>
      <Table.Cell>
        <Form.Select  selectOnBlur={ false }
                      options={ fieldOptions }
                      value={ null }
                      onChange={ handleAdd }
                      clearable
                      search />
      </Table.Cell>
      <Table.Cell></Table.Cell>
    </Table.Row>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

AddRecord.propTypes = {
  forms:  PropTypes.array.isRequired,
  onAdd:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AddRecord;
