const withReturn  = expr => expr.trim().startsWith('return')
                            ? expr
                            : `return ${expr}`;

function expressionParser(expression, context={}) {
  const names     = Object.keys(context);
  const values    = Object.values(context);

  try {
    const evaluator = new Function(...names, withReturn(expression));
    return evaluator(...values);
  } catch(e) {
    console.error(`Invalid expression: "${expression}"`);
    return null;
  }
}

export default expressionParser;
