import React      from 'react';
import PropTypes  from 'prop-types';

import { Table, Button, Icon }  from 'semantic-ui-react';

import EditButton     from './EditButton';
import DeleteButton   from './DeleteButton';
import VariablesInfo  from  './VariablesInfo';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const draggingStyle = {
  userSelect: 'none',
  background: 'lightyellow'
};

const baseStyle = {
  transition: 'background 0.75s'
};

function getDraggableStyle(isDragging, draggableStyle) {
  const style = { ...draggableStyle, ...baseStyle };

  if(isDragging) {
    return { ...style, ...draggingStyle,  };
  }

  return style;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Record = ({ calculation, fields, calculations, onSave, onDelete, isDraggable, draggableProvided, draggableSnapshot }) => {
  const { name, equation, variables } = calculation;

  const { dragHandleProps, draggableProps } = draggableProvided;

  const style = getDraggableStyle(
    draggableSnapshot.isDragging,
    draggableProps.style
  );

  return (
    <Table.Row  { ...draggableProps } style={ style } >
      <Table.Cell>{ name }</Table.Cell>
      <Table.Cell>
        <code>{ equation }</code>
      </Table.Cell>
      <Table.Cell>
        <VariablesInfo  variables={ variables }
                        fields={ fields }
                        calculations={ calculations }
                        basic={ true } />
      </Table.Cell>
      <Table.Cell textAlign='right'>
        { isDraggable
          ? <Icon name='bars'
                size='large'
                { ...dragHandleProps }
                tabIndex='-1' />

          : <Button.Group size='mini' { ...dragHandleProps }>
              <EditButton calculation={ calculation }
                          fields={ fields }
                          calculations={ calculations }
                          onSubmit={ onSave } />

              <DeleteButton calculation={ calculation } onDelete={ onDelete } />

            </Button.Group>
        }
      </Table.Cell>
    </Table.Row>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Record.propTypes = {
  calculation:  PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    variables:  PropTypes.array,
    equation:   PropTypes.string
  }).isRequired,

  fields:     PropTypes.array.isRequired,
  onSave:     PropTypes.func.isRequired,
  onDelete:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Record;
