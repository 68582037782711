import React, { Component }                     from 'react';
import { Segment, Header, Message, List, Icon } from 'semantic-ui-react';
import { Redirect, Link }                       from 'react-router-dom';

import connectResource                    from 'utils/connectResource';
import { getAll, getById, getSingleton }  from 'utils/connectors';
import {users,
        permission_groups,
        permissions_schema } from 'resources/organizationResources';

import PermissionTable from 'components/organization/permissions/PermissionTable';

class FormView extends Component {
  render() {
    const { data, loading, api_errors }                   = this.props;
    const { selected, schema }                            = data;
    const { users, name, default:isDefault, privileges }  = selected || {};
    const { permission_actions, entities }                = schema || {};

    return (
      <div>
        <Segment loading={loading}>
          <Header as='h3'>{name}</Header>
          {isDefault &&
            <Message>
              <Message.Header>
                Default Group
              </Message.Header>
              This group will be applied to all new users.
            </Message>
          }
          {api_errors && <Redirect to='./'/>}

          { schema && selected &&
            <PermissionTable
              disabled
              entities={ entities }
              actions={ permission_actions }
              privileges={ privileges } />
          }
        </Segment>

        <Segment loading={loading}>
          <Header as='h3'>Users Belonging to This Group</Header>

          { users && users.length ?
            <List divided relaxed='very'>
              {
                selected.users.map(({id, name}) => {
                  const url = `/organization/users/${id}`;

                  return (
                    <List.Item key={id}>
                      <List.Icon  name='user outline'
                        size='small'
                        verticalAlign='middle' />
                      <List.Content>
                        <List.Header>
                          <Link to={ url }>{ name }</Link>
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  );
                })
              }
            </List>
            :
            <p className='ui message warning'>
              <Icon name='warning circle' size='large'/>
              No users have been assigned to this permission group
            </p>
          }
        </Segment>
      </div>
    );
  }
}

export default connectResource(FormView, {
  connectors: {
    schema:   getSingleton(permissions_schema),
    users:    getAll(users),
    selected: getById(permission_groups, ({ match: { params: { id } } }) => id)
  },
  mapDispatchToProps: { ...users.actions }
});
