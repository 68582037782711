import React                                              from 'react';
import PropTypes                                          from 'prop-types';
import { Segment, Header, Message, List, Icon, Divider }  from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const OrgUsageInfo = ({ loading, forms }) => {
  return (
    <Segment loading={ loading }>
      <Header as='h3'>Organization Usage Information</Header>
      {
        forms && forms.length
        ? <div>
            <Message icon info>
              <Icon name='info circle' />
                This form is used in&nbsp;
              <strong>{forms.length}</strong>&nbsp;organizations.
            </Message>

            <Divider horizontal>Organizations</Divider>

            <List divided relaxed='very'>
              {
                forms.map(({ id, organization }) => {

                  return (
                    <List.Item key={ id }>
                      <List.Icon  name='building outline' />
                      <List.Content>{ organization.name }</List.Content>
                    </List.Item>
                  );
                })
              }
            </List>
          </div>
        : <Message warning>
            <Icon name='warning circle' />
            This form is not used by any organizations.
          </Message>

      }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

OrgUsageInfo.defaultProps = {
  loading:  false,
  forms:    []
};

OrgUsageInfo.propTypes = {
  loading:  PropTypes.bool,
  forms:    PropTypes.array
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default OrgUsageInfo;
