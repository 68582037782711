import AuthenticatedResource from 'resources/AuthenticatedResource';

export default class NoteResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/:notable_type/:notable_id/notes';

  id            = undefined;
  user_id       = undefined;
  notable_type  = undefined;
  notable_id    = undefined;
  body          = '';
  created_at    = undefined;
  updated_at    = undefined;

  pk() {
    return this.id && this.id.toString();
  }
}