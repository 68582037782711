import React              from 'react';
import PropTypes          from 'prop-types';
import { Route, Switch }  from 'react-router-dom';
import urljoin            from 'url-join';

import Start  from './Start';
import Stop   from './Stop';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const App = ({ match: { path } }) => {
  return (
    <Switch>
      <Route path={ urljoin(path, '/create/:userId') } component={ Start } />
      <Route path={ urljoin(path, '/destroy') } component={ Stop } />
    </Switch>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

App.propTypes = {
  match: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default App;
