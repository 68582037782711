import React, { useState, useEffect }                 from 'react';
import PropTypes                                      from 'prop-types';
import { Modal, Header, Form, Button, Message, Icon } from 'semantic-ui-react';

import exportData from 'utils/exportData';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const FORM_ID = 'api-credentials-form';

const credentialsCsv = ({ name, api_key }) => {
  const headers = ['Name', 'API Key'].join(',');
  const data    = [name, api_key].join(',');

  return [headers, data].join('\n');
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const FormModal = ({ title, credentials, opened, onClose, onSubmit }) => {
  const [name, setName]     = useState();
  const credentialsPresent  = credentials !== null && credentials !== undefined;

  useEffect(() => {
    if(credentialsPresent) {
      const csv = credentialsCsv(credentials);
      exportData(csv, 'text/csv', 'credentials.csv');
    }
  }, [credentialsPresent, credentials]);

  const handleClose = (evt) => {
    setName(null);
    onClose(evt);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const body = { name };

    onSubmit(body);
  };

  const handleNameChange = (evt, { value }) => {
    setName(value);
  };

  return (
    <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
      <Header icon='pencil' content={title} />

      {
        credentialsPresent
          ? (
            <>
              <Modal.Content>
                <Message icon info>
                  <Icon name='info circle' />
                  <div>
                    <p>
                      Your Api Key has been created and is downloading to your computer.  It is&nbsp;
                      <strong>critical</strong> that this Api Key is kept safe and shared with
                      as few people as possible.
                    </p>
                    <p>
                      Every 3 months the Api Key will expire. You will need to rotate it to get a new one.
                    </p>
                  </div>
                </Message>
              </Modal.Content>

              <Modal.Actions>
                <Button onClick={handleClose} positive basic>Close</Button>
              </Modal.Actions>
            </>
          )
        : (
            <>
              <Modal.Content>
                <Form onSubmit={handleSubmit} id={FORM_ID}>
                  <Form.Input label="name" onChange={handleNameChange} />
                </Form>
              </Modal.Content>

              <Modal.Actions>
                <Button onClick={handleClose} negative basic>Cancel</Button>
                <Button type='submit' positive basic form={FORM_ID}>Save</Button>
              </Modal.Actions>
            </>
          )
      }
    </Modal>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

FormModal.propTypes = {
  title:        PropTypes.string,
  credentials:  PropTypes.object,
  opened:       PropTypes.bool,
  onClose:      PropTypes.func.isRequired,
  onSubmit:     PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default FormModal;
