import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header, List }  from 'semantic-ui-react';
import { connect }                from 'react-redux';
import { formAssignments }        from 'resources/adminResources';
import OrganizationSelector       from './OrganizationSelector';

// -----------------------------------------------------
// Helpers / Constants
// -----------------------------------------------------

const copyToOrgModal = '#copy-to-org-modal';

const modalStateKey = 'currentModal';

function isModalOpen(state) {
  return state[modalStateKey] === copyToOrgModal;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ActionsSideCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [modalStateKey]: null
    };

    this.handleItemClick        = this.handleItemClick.bind(this);
    this.handleItemClose        = this.handleItemClose.bind(this);
    this.handleSaveCopyToOrgs   = this.handleSaveCopyToOrgs.bind(this);
  }

  render() {
    const isOrgSelectorOpened = isModalOpen(this.state, copyToOrgModal);
    const { data: { form } }  = this.props;

    return (
      <Segment>
        <Header as='h3'>Actions</Header>
        <List>
          <List.Item>
            <List.Icon name='copy outline' />
            <List.Content>
              <a href={ copyToOrgModal } onClick={ this.handleItemClick }>
                Copy to Organizations
              </a>
              <OrganizationSelector opened={ isOrgSelectorOpened }
                                    form={ form }
                                    onClose={ this.handleItemClose }
                                    onSave={ this.handleSaveCopyToOrgs } />
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    );
  }

  handleItemClick(evt) {
    evt.preventDefault();

    const { target } = evt;
    const href       = target.getAttribute('href');

    this.setState({ [ modalStateKey ]: href });
  }

  handleItemClose() {
    this.setState({ [ modalStateKey ]: null });
  }

  async handleSaveCopyToOrgs(organizationIds) {
    const { data: { form }, dispatch }  = this.props;
    const { create }                    = formAssignments.actions;

    const request = create({
      form_id: form.id,
      organization_ids: organizationIds
    });

    const response    = await dispatch(request);
    const didSucceed  = !response.errors;

    if(didSucceed) {
      this.handleItemClose();
    }

    return didSucceed;
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ActionsSideCard.propTypes = {
  data: PropTypes.object
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connect()(ActionsSideCard);
