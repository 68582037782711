import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import Select               from 'components/TimeSelect';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

// Wraps the TimeSelect component to provide a compatible interface for the
// ConditionalEditor
class TimeSelect extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { value } = this.props;

    return(
      <Select value={ value }
              onChange={ this.handleChange } />

    );
  }

  handleChange({ value }) {
    const { name, onChange }  = this.props;
    onChange({ target: { name, value } });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

TimeSelect.propTypes = {
  value:    PropTypes.string,
  name:     PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default TimeSelect;
