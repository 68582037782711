import React             from 'react';
import PropTypes          from 'prop-types';
import { Modal, Button, Header, Form, Checkbox }    from 'semantic-ui-react';
import { reject }         from 'lodash';

import ClientList          from './ClientList';
import 'style/containers/organization/clients/client_forms/clone-modal.css';

const labels = {
  singleTitle: 'Duplicate response for this client',
  singleDescription: 'Creates a single copy of this response and assigns it to this client',
  multipleTitle: 'Duplicate response for other clients',
  multipleDescription: 'Creates copies of this response and assigns them to clients selected on the list below'
};

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function CheckboxLabel({ title, description }) {
  return (
    <span>
      <h3 style={{ marginBottom: '0' }} >{title}</h3>
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </span>
  );
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class CloneModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      cloneMultiple: 0,
      clientsList: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleRemoveClient = this.handleRemoveClient.bind(this);
    this.handleResetClients = this.handleResetClients.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { cloneMultiple, clientsList }     = this.state;
    const { opened,
            onClose }         = this.props;

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon={ 'copy' } content={ 'Duplicate form response' } />
        <Modal.Content>
          <Form>
            <Form.Field>
              <Checkbox style={{ marginBottom: '20px' }}
                radio
                name='cloneMultiple'
                label={{ children: <CheckboxLabel title={ labels.singleTitle }
                                                  description={ labels.singleDescription } /> }}
                value='0'
                checked={cloneMultiple === 0}
                onChange={this.handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Checkbox
                radio
                label={{ children: <CheckboxLabel title={ labels.multipleTitle }
                                                  description={ labels.multipleDescription  } /> }}
                name='cloneMultiple'
                value='1'
                checked={cloneMultiple === 1}
                onChange={this.handleChange}
              />
            </Form.Field>

            <ClientList disabled={ !cloneMultiple }
                        selectedClients={ clientsList }
                        onAddClient={ this.handleAddClient}
                        onRemoveClient={ this.handleRemoveClient }
                        onResetClients={ this.handleResetClients } />

          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ onClose } negative basic>Cancel</Button>
          <Button onClick={ this.handleSubmit } positive basic>Duplicate</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleChange = (e, { value }) => this.setState({ cloneMultiple: parseInt(value) })
  
  handleAddClient( client ){
    const { clientsList } = this.state;
    clientsList.push(client);

    this.setState({ clientsList });
  } 

  handleRemoveClient( clientId ){
    const { clientsList } = this.state;
    const newClientsList = reject(clientsList, ['id', clientId]);

    this.setState({ 
      clientsList: newClientsList 
    });
  } 

  handleResetClients(){
    this.setState({ clientsList: [] });
  } 

  handleSubmit(evt) {
    evt.preventDefault();
    const { clientsList } = this.state;
    const clientsIdsList = clientsList.map(({id}) => id);

    this.props.onSubmit(clientsIdsList);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CloneModal.defaultProps = {
  opened:       false,
  loading:      false
};

CloneModal.propTypes = {
  opened:       PropTypes.bool,
  loading:      PropTypes.bool,
  onSubmit:     PropTypes.func.isRequired,
  onClose:      PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CloneModal;