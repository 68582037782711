import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import RichTextEditor       from 'react-rte';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class MarkdownEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value === ''
              ? RichTextEditor.createEmptyValue()
              : RichTextEditor.createValueFromString(this.props.value, 'markdown')
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // handlers
  handleChange(value) {
    const { name, onChange }  = this.props;
    this.setState({value}, () => {
      onChange(null, { name, value: value.toString('markdown') });
    });
  }

  render() {
    const { value } = this.state;

    const placeholder     = 'Content...';
    const rootStyle       = { background: '#f9f9f9',
                              fontFamily: 'sans-serif' };
    const toolbarConfig   = { display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
                              INLINE_STYLE_BUTTONS: [
                                {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                                {label: 'Italic', style: 'ITALIC'},
                              ],
                              BLOCK_TYPE_BUTTONS: [
                                {label: 'UL', style: 'unordered-list-item'},
                                {label: 'OL', style: 'ordered-list-item'}
                              ]
                            };
    const toolbarStyle    = { background: '#fff',
                              margin: 0,
                              padding: '10px 10px 5px',
                              height: '52px' };

    return (
      <RichTextEditor ref={ (ref) => this.rte = ref }
                      placeholder={ placeholder }
                      rootStyle={ rootStyle }
                      toolbarStyle={ toolbarStyle }
                      toolbarConfig={ toolbarConfig }
                      value={ value }
                      onChange={ this.handleChange } />
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

MarkdownEditor.defaultProps = {
  value:    '',
  metadata: {}
};

MarkdownEditor.propTypes = {
  value:    PropTypes.string,
  metadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default MarkdownEditor;
