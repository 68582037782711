import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';
import { Table, Checkbox, Button }  from 'semantic-ui-react';

import Privileges             from 'constants/Privileges';
import PermissionableSelector from './PermissionableSelector';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const privilegesFieldName     = 'privileges';
const permissionableFieldName = 'permissionable';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class PermissionFields extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
    this.handleRemove  = this.handleRemove.bind(this);
  }

  render() {
    const { recordPermission } = this.props;
    const { permissionable_type,
            permissionable_id,
            privileges=[] } = recordPermission;

    return (
      <Table.Row>
        <Table.Cell>
          <PermissionableSelector name={ permissionableFieldName }
                                  permissionableType={ permissionable_type }
                                  permissionableId={ permissionable_id }
                                  onChange={ this.handleChange }/>
        </Table.Cell>

        {
          Privileges.validPrivileges.map((privilege) => (
            <Table.Cell key={ privilege }>
              <Checkbox value={ privilege }
                        name={ privilegesFieldName }
                        checked={ privileges.includes(privilege) }
                        onChange={ this.handleChange } />
            </Table.Cell>
          ))
        }

        <Table.Cell textAlign='right'>
          <Button icon='remove'
                  size='mini'
                  basic
                  color='red'
                  onClick={ this.handleRemove } />
        </Table.Cell>
      </Table.Row>
    );
  }

  handleChange(evt, { value, checked, name }) {
    const { id, recordPermission, onChange } = this.props;

    // Handle 'privilege' checkboxes
    if(name === privilegesFieldName) {
      const { privileges }  = recordPermission;
      const privilegesSet   = new Set(privileges);

      if(checked) {
        privilegesSet.add(value);
      } else {
        privilegesSet.delete(value);
      }

      onChange(evt, {
        value: {
          [id]: {
            ...recordPermission,
            privileges: Array.from(privilegesSet)
          }
        }
      });
    }

    // handle permissionable selection
    if(name === permissionableFieldName) {
      onChange(evt, {
        value: {
          [id]: {
            ...recordPermission,
            ...value
          }
        }
      });
    }
  }

  handleRemove(evt) {
    evt.preventDefault();

    const { id, onRemove } = this.props;
    onRemove(evt, { id });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

PermissionFields.propTypes = {
  recordPermission: PropTypes.shape({
    id:                   PropTypes.number,
    permissionable_type:  PropTypes.string,
    permissionable_id:    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    privileges:           PropTypes.array
  }).isRequired,

  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,

  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default PermissionFields;
