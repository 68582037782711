import React                      from 'react';
import PropTypes                  from 'prop-types';
import { Dropdown, Icon, Label }  from 'semantic-ui-react';
import { Link }                   from 'react-router-dom';

import Privileges                 from 'constants/Privileges';
import WithPermission             from 'containers/WithPermission';
import { preventDefault }         from 'utils/eventHandlers';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function fullName(user) {
  const { first_name, last_name } = user || {};
  return [first_name, last_name].join(' ').trim();
}

function trigger(name) {
  return (
    <span>
      <Icon name='user' /> { name }
    </span>
  );
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const UserMenu = ({ user, impersonatedUser, onStopImpersonation, onLogout }) => {
    const userText = fullName(user);

    return(
      <span>
        {
          impersonatedUser &&
          <Label  basic color='blue'
                  style={{ marginRight: '1rem' }}>

            Impersonating: { fullName(impersonatedUser) }

            <a  href='#stop-impersonation'
                onClick={ preventDefault(onStopImpersonation) }>
                
              <Icon name='remove' style={{ margin: '0 0 0 .75rem' }} />
            </a>
          </Label>
        }

        <Dropdown inline trigger={ trigger(userText) }>
          <Dropdown.Menu>
            <Dropdown.Item  as={ Link }
                            to='/myprofile'>My Profile</Dropdown.Item>
            
            <WithPermission resource='organization_accounts' actions={Privileges.write}>
              <Dropdown.Item  as={ Link }
                              to='/myorg'>My Organization</Dropdown.Item>
            </WithPermission>

            <Dropdown.Item onClick={ onLogout }>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </span>
    );
  };

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

UserMenu.propTypes = {
  impersonatedUser:     PropTypes.object,
  user:                 PropTypes.object.isRequired,
  onStopImpersonation:  PropTypes.func.isRequired,
  onLogout:             PropTypes.func.isRequired
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default UserMenu;
