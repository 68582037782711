import _ from 'lodash';

// Returns the state, unchanged.
export function identityReducer(state) {
  return state;
}

// Overrides the current state with the action's payload.
export function overrideReducer(state, action) {
  return action.payload;
}

// Indexes an array of records (i.e. action.payload) and merges the indexed
// records into the current state.
export function mergeNormalizeReducer(state, action) {
  const { payload } = action;
  const records     = [].concat(payload);
  const normalized  = _.keyBy(records, 'id');
  const merged      = _.mapValues(normalized, (record, id) => (
    Object.assign({}, state[id], record)
  ));
  return { ...state, ...merged };
}

// Removes a record--identified by the action's scope.id--from the current
// state.
export function destroyReducer(state, action) {
  const { scope } = action;
  return _.omit(state, scope.id);
}

export function reduceWithSubgroup(...groupByAttributes) {
  return function(state, action) {
    const { payload, scope }  = action;
    const subgroups           = groupByAttributes.map(attr => scope[attr]);
    const norm                = _.keyBy(payload, 'id');
    const subgroupState       = subgroups
                                .reduce((state, subgroup) => (
                                  { ...state[subgroup] }
                                ), state);

    const nextSubgroupState   = { ...subgroupState, ...norm };
    const nextState           = { ...state };

    return  _.setWith(nextState, subgroups, nextSubgroupState, Object);
  };
}

export function generateReducers(transitions, initialState={}) {
  return (state=initialState, action) => {
    const transition = transitions[action.type] || identityReducer;
    return transition(state, action);
  };
}
