import React              from 'react';
import PropTypes          from 'prop-types';
import { Checkbox, Form } from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Field = ({ name, label, onChange }) => {
  return (
    <Form.Field>
      <Checkbox name={ name }
                label={ label }
                onChange={ onChange } />
    </Form.Field>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Field.propTypes = {
  name:     PropTypes.string.isRequired,
  label:    PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Field;
