import React, { Component}              from 'react';
import PropTypes                        from 'prop-types';
import urljoin                          from 'url-join';
import { Grid, Input, Header, Segment } from 'semantic-ui-react';

import connectResource                        from 'utils/connectResource';
import { getAllById, getById }                from 'utils/connectors';
import { internalFormRecords, internalForms } from 'resources/organizationResources';
import { store }                              from 'store/configuredStore';
import Privileges                             from 'constants/Privileges';
import WithPermission                         from 'containers/WithPermission';

import CreateButton     from './CreateButton';
import RefreshButton    from './RefreshButton';
import InstanceControls from './InstanceControls';
import SortableTable    from 'components/SortableTable';
import MarkdownRenderer  from 'components/renderers/MarkdownRenderer';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
const refId = 'ref_id';

const onDelete = (recordId, formId) => {
  const { destroy } = internalFormRecords.actions;
  const request     = destroy(null, { id: recordId, form_id: formId });
  store.dispatch(request);
};

function createControlCellRenderer(formId, hasAttachments) {
  const CellRenderer = (answers) => {
    const recordId = answers[0];
    if (!formId || !recordId ) { return null; }

    return (
      <div className='pull-left'>
        <InstanceControls formId={ formId }
                          recordId={ parseInt(recordId) }
                          hasAttachments={ hasAttachments }
                          onDelete={ (id) => onDelete(id, formId) } />
      </div>
    );
  };

  return CellRenderer;
}


function valueFilter(filterValue) {
  return function(values) {
    if(filterValue === null || filterValue === undefined || filterValue === '') {
      return true;
    }

    return values.some(( value ) => {
      const v1 = `${value}`.toLowerCase();
      const v2 = `${filterValue}`.toLowerCase();
      return v1.includes(v2);
    });
  };
}

function cellRenderer(record, value, columnIndex) {
  return (
    <MarkdownRenderer  record={ record }
                        value={ value }
                        columnIndex={ columnIndex } />
  );
}

function prepareRecords(records, recordsFetched) {
  return recordsFetched ? records[0] : [];
}

function prepareHeaders(formFields, calculations) {
  return [refId].concat(formFields.map( ({name}) => name )).concat(calculations.map( ({name}) => name ));
}

function refreshHandler(refresh, refetch) {
  return async ({ id }) => {
    const refreshResponse = await refresh(null, { id });
    const { errors }      = refreshResponse;

    if(!errors) {
      await refetch(null, { form_id:  id });
    }
    return refreshResponse;
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class RecordList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: ''
    };

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { match: { url }, data, loading,
            refresh, refetch              } = this.props;
    const { filterValue }                   = this.state;
    const form                              = data.form     || {};
    const records                           = data.records  || [];
    const { id, name, active,
            form_fields=[],
            calculations=[] }               = form;
    const hasAttachments                    = form_fields.some( ({field_type}) => field_type === 'attachment' );
    const recordsFetched                    = records.length !== 0 && Array.isArray(records[0]); // this shouldn't be needed. for some unexpected reason data.records holds an object
                                                                                                 // instead of an array when the Component is rendered the first time.
    const headers             = prepareHeaders(form_fields, calculations);
    const flattenedRecords    = prepareRecords(records, recordsFetched);
    const answers             = flattenedRecords.filter(valueFilter(filterValue));

    const controlCellRenderer = createControlCellRenderer(id, hasAttachments);
    const handleRefresh       = refreshHandler(refresh, refetch );

    const cloaker = (index) => (
      `${headers[index]}`.startsWith(refId)
    );

    return (
      <Segment loading={ loading || !id || !recordsFetched}>
        <Header as='h2'>{ name }</Header>

        <Grid style={{ marginTop: '2rem' }}>
          <Grid.Row>
            <Grid.Column width={ 8 }>
              <Input  placeholder='Filter'
                      onChange={ this.handleChange } />
            </Grid.Column>
            <Grid.Column width={ 8 }>
              <div className='pull-right'>
                <RefreshButton  refresh={ handleRefresh }
                                formId={ id } />
              </div>
              { active && 
              <div className='pull-right'>
                <WithPermission resource='internal_forms' actions={ Privileges.write }>
                  <CreateButton to={ urljoin(url, '/records/new') } />
                </WithPermission>
              </div>
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 16 }>
              <SortableTable tableId={`internal_form_${id}`}
                        headerLabels={ headers }
                        records={ answers }
                        cellRenderer={ cellRenderer }
                        controlCellRenderer={ controlCellRenderer }
                        controlsMinWidth={ hasAttachments ? '133' : false }
                        columnCloaker={ cloaker } />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

    );
  }

  handleChange(evt, { value }) {
    this.setState({ filterValue: value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

RecordList.defaultProps = {
  loading: false
};

RecordList.propTypes = {
  loading:  PropTypes.bool,

  data:     PropTypes.shape({
    form:     PropTypes.object,
    records:  PropTypes.array
  }).isRequired,

  match:  PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(RecordList, {
  key: ({ match: { params: { id } } }) => id,
  connectors: [{
    form:     getById(internalForms, ({ match: { params: { id } } }) => (id)),
    records:  getAllById(internalFormRecords,
                        ({ match: { params: { id } } }) => ({ form_id: id }))
  }],
  mapDispatchToProps: {
    refresh: internalForms.actions.update,
    refetch: internalFormRecords.actions.index
  }
});
