import React from 'react';
import FieldSelectSwitcher from './FieldSelectSwitcher';

class NumberSelect extends FieldSelectSwitcher {
  getInitialInputState(value) {
    return typeof value === 'number';
  }

  renderInput(transformedValue) {
    return (
      <input
        type="number"
        value={transformedValue}
        onChange={(e) => this.handleChange(e.target.value)}
      />
    );
  }
}

export default NumberSelect;
