import React      from 'react';
import { List, Label }   from 'semantic-ui-react';

import MasterDetail                               from 'components/MasterDetail';
import scopedToRole                               from 'hocs/scopedToRole';
import withPagination                             from 'hocs/withPagination';
import withSearch                                 from 'hocs/withSearch';
import { sequences, forms, cloneRecords }                from 'resources/organizationResources';
import connectResource                            from 'utils/connectResource';
import { getPageAllById, getById, getAll }  from 'utils/connectors';
import { sortByAttributes }                       from 'utils/sortComparators';
import { textSubstringFilter }                    from 'utils/recordFilters';

import SideCardView   from '../SideCardView';
import DetailView     from './DetailView';
import FormView       from './FormView';

// -----------------------------------------------------
// Connected Form View Definition
// -----------------------------------------------------

const ConnectedFormView = connectResource(FormView, {
  connectors: {
    forms:    getAll(forms),
    selected: getById(sequences, (props)=>props.match.params.id)
  },
  mapDispatchToProps : { ...sequences.actions }
});

// -----------------------------------------------------
// Connected Detail View Definition
// -----------------------------------------------------

const ScopedDetailView = scopedToRole(DetailView, { role: 'organization' });

const ConnectedDetailView = connectResource(ScopedDetailView, {
    selected: getById(sequences, (props)=>props.match.params.id)
});

// -----------------------------------------------------
// List Item Definition
// -----------------------------------------------------

const ListItem = ({record}) => (
  <>
    <List.Content floated='right'>
      <Label size='mini' color='grey'>
      { record.forms.length }
      </Label>
    </List.Content>
    <List.Header>{ record.name }</List.Header>
  </>
);

// -----------------------------------------------------
// App Component Definition
// -----------------------------------------------------

class App extends React.Component {
  render() {

    const { data, destroy, update, create, clone } = this.props;


    return (
      <MasterDetail masterItemComponent={ListItem}
        detailComponent={ConnectedDetailView}
        formComponent={ConnectedFormView}
        sideCardComponent={SideCardView}
        records={ data.sequences || [] }
        onDelete={ id => destroy(null, {id}) }
        onUpdate={ record => update(record, {id: record.id}) }
        onCreate={ record => create(record) }
        onClone={ id => clone(null, {cloneable_type: 'sequences', cloneable_id: id}) }
        resource='forms'
        listSort={ sortByAttributes('name') }
        filterRecord={textSubstringFilter('name')}
        {...this.props}/>
    );
    }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    sequences: getPageAllById(sequences)
  },
  mapDispatchToProps: {
    ...sequences.actions,
    ...cloneRecords.actions
  }
});

export default withSearch(withPagination(connectedComponent));

