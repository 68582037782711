import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { Segment, Header }      from 'semantic-ui-react';

import connectResource          from 'utils/connectResource';
import { getSingleton }         from 'utils/connectors';
import { publicFormClientLink } from 'resources/organizationResources';

import LinkedView   from './LinkedView';
import UnlinkedView from './UnlinkedView';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ClientLinkView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.handleLink   = this.handleLink.bind(this);
    this.handleUnlink = this.handleUnlink.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { loading: prevLoading }  = prevProps;
    const { loading }               = this.props;

    if(prevLoading !== loading) {
      this.setState({ loading: loading });
    }
  }

  render() {
    const { loading } = this.state;
    const { data, answers, changes }    = this.props;
    const clientLink  = data.clientLink || {};

    return (
      <Segment loading={ loading }>
        <Header as='h3'>Client Link</Header>
        {
          clientLink.client
          ? <LinkedView clientLink={ clientLink }
                        onUnlink={ this.handleUnlink } />
          : <UnlinkedView clientFieldMapping={ clientLink.client_field_mapping }
                          answers={ answers }
                          changes={ changes }
                          onLink={ this.handleLink } />
        }
      </Segment>
    );
  }

  async handleLink(client) {
    const { dispatch, publicFormInstanceId, changes } = this.props;
    const { create }                                  = publicFormClientLink.actions;

    const params  = { client_id: client.id, answers: changes };
    const scope   = { public_form_instance_id:  publicFormInstanceId };

    const request = create(params, scope);

    this.setState({ loading: true });
    await dispatch(request);
    this.setState({ loading: false });
  }

  async handleUnlink() {
    const { dispatch, publicFormInstanceId }  = this.props;
    const { destroy }                         = publicFormClientLink.actions;

    const request = destroy(
      null, { public_form_instance_id:  publicFormInstanceId }
    );

    this.setState({ loading: true });
    await dispatch(request);
    this.setState({ loading: false });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ClientLinkView.defaultProps = {
  loading: false
};

ClientLinkView.propTypes = {
  answers:              PropTypes.object,
  changes:              PropTypes.array,
  loading:              PropTypes.bool,
  publicFormInstanceId: PropTypes.string.isRequired,
  data:                 PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(ClientLinkView, {
  key: ({ publicFormInstanceId }) => publicFormInstanceId,

  connectors: {
    clientLink: getSingleton(
      publicFormClientLink,
      ({ publicFormInstanceId }) => ({
        public_form_instance_id: publicFormInstanceId
      }))
  }
});
