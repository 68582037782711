import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Form, Segment, Select }  from 'semantic-ui-react';

import Fixed      from 'components/Fixed';
import FileField  from 'components/forms/Field/Attachment';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class BasicForm extends Component {

  // construction
  constructor(props) {
    super(props);

    this.state = {
      record: {}
    };

    this.handleChangeToForm  = this.handleChangeToForm.bind(this);
    this.handleChangeToInput = this.handleChangeToInput.bind(this);
    this.handleChangeToOrg   = this.handleChangeToOrg.bind(this);
    this.handleChangeToType  = this.handleChangeToType.bind(this);
    this.handleSubmit        = this.handleSubmit.bind(this);
  }

  // handlers
  handleChangeToForm(evt, { value: form_id }) {
    evt.preventDefault();
    const { record } = this.state;
    this.setState({
      record: { ...record, form_id }
    });
  }
  handleChangeToInput(evt, { value: input }) {
    const { record } = this.state;
    this.setState({
      record: { ...record, input }
    });
  }
  handleChangeToOrg(evt, { value: organization_id }) {
    evt.preventDefault();
    const { onChange } = this.props;
    const { record }   = this.state;
    const form_id      = null;
    const payload      = { ...record, organization_id, form_id };
    this.setState({ record: payload }, () => {
      onChange(payload);
    });
  }
  handleChangeToType(evt, { value: type }) {
    evt.preventDefault();
    const { record } = this.state;
    const form_id    = null;
    this.setState({
      record: { ...record, type, form_id }
    });
  }
  handleSubmit(evt) {
    evt.preventDefault();
    const { onSubmit } = this.props;
    const { record }   = this.state;
    onSubmit(record);
  }

  // helpers
  getFormOptions() {
    const { forms } = this.props;
    return forms.map((item) => (
      { text: item.name, value: item.id }
    ));
  }
  getOrgOptions() {
    const { organizations } = this.props;
    return organizations.map((item) => (
      { text: item.name, value: item.id }
    ));
  }
  getTypeOptions() {
    return [
      { text: 'Client Data', value: 'client' },
      { text: 'Form Data',   value: 'form' }
    ];
  }

  // rendering
  render() {
    const { loading, saving } = this.props;
    const { record }          = this.state;
    const formOptions         = this.getFormOptions();
    const orgOptions          = this.getOrgOptions();
    const typeOptions         = this.getTypeOptions();

    return (
      <Segment loading={ loading }>
        <Form onSubmit={ this.handleSubmit }>
          <Form.Field label='Organization'
                      placeholder='Select an organization'
                      control={ Select }
                      options={ orgOptions }
                      value={ record.organization_id }
                      onChange={ this.handleChangeToOrg } />

          <Form.Field label='Import Type'
                      placeholder='Select an import type'
                      control={ Select }
                      options={ typeOptions }
                      value={ record.type }
                      onChange={ this.handleChangeToType } />

          { record.type === 'form' &&
            <Form.Field label='Form'
                        placeholder='Select a form'
                        control={ Select }
                        options={ formOptions }
                        value={ record.form_id }
                        onChange={ this.handleChangeToForm } /> }

          <FileField  ownLabel='Input Data'
                      value={ record.input }
                      onChange={ this.handleChangeToInput } />

          <Fixed>
            <Form.Button  primary
                          loading={ saving }
                          type='submit'>Save</Form.Button>
          </Fixed>
        </Form>
      </Segment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

BasicForm.propTypes = {
  loading:        PropTypes.bool.isRequired,
  saving:         PropTypes.bool.isRequired,
  forms:          PropTypes.array.isRequired,
  organizations:  PropTypes.array.isRequired,
  onChange:       PropTypes.func.isRequired,
  onSubmit:       PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default BasicForm;
