import AuthenticatedResource  from 'resources/AuthenticatedResource';
import resourceUrl            from 'utils/resourceUrl';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class DtdReferralResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/case_worthy/referrals';

  // Override listUrl to append search params.
  static listUrl(searchParams) {
    const url = resourceUrl(this.urlRoot, '', searchParams);
    url.search = new URLSearchParams(searchParams);
    return url;
  }

  provider_referral_id  = null;
  referral_date         = null;
  referral_status       = null;
  service_type          = null;
  to_organization       = null;
  from_organization     = null;
  referral_reason       = null;
  notes                 = null;
  client_id             = null;
  client_form_id        = null;

  pk() {
    return  this.provider_referral_id
            && this.provider_referral_id.toString();
  }
}