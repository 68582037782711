import React              from 'react';
import PropTypes          from 'prop-types';
import { Segment, Table } from 'semantic-ui-react';

import formattedDate from 'utils/formattedDate';

import CreateButton from './CreateButton';
import DeleteButton from './DeleteButton';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const List = ({ apiCredentials, onCreate, onDestroy }) => {
  return (
    <Segment>
      <Table basic='very' style={{ marginTop: '2rem' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>
              <CreateButton compact onCreate={ onCreate } />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            apiCredentials.map(({ id, name, created_at }) => (
              <Table.Row key={ id }>
                <Table.Cell>{ name }</Table.Cell>
                <Table.Cell>{ formattedDate(created_at) }</Table.Cell>
                <Table.Cell textAlign='right'>
                  <DeleteButton id={ id } onDestroy={ onDestroy } />
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </Segment>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

List.propTypes = {
  apiCredentials: PropTypes.array.isRequired,
  onCreate:       PropTypes.func.isRequired,
  onDestroy:      PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default List;
