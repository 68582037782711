import React             from 'react';
import { List }          from 'semantic-ui-react';

import ManageViews               from './ReportViews/Manage';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ReportViews = (props) => {
  return (
        <React.Fragment>
        <List.Item>
          <List.Icon name='chart bar' />
          <List.Content>
              <ManageViews {...props} />
          </List.Content>
        </List.Item>
        </React.Fragment>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ReportViews;
