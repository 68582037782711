import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import RichTextEditor       from 'react-rte';

import FieldInserter        from './FieldInserter';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class BodyEditor extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // handlers
  handleChange(evt, { value }) {
    const { onChange } = this.props;
    onChange(value);
  }

  // rendering
  render() {
    const { fields, value, onChange } = this.props;

    const Inserter = <FieldInserter key='bodyInserter'
                                    fields={ fields }
                                    scope={ this } />;

    const customControls  = [Inserter];
    const placeholder     = 'Notification message...';
    const rootStyle       = { background: '#f9f9f9',
                              fontFamily: 'sans-serif' };
    const toolbarConfig   = { display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
                              INLINE_STYLE_BUTTONS: [
                                {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                                {label: 'Italic', style: 'ITALIC'},
                              ],
                              BLOCK_TYPE_BUTTONS: [
                                {label: 'UL', style: 'unordered-list-item'},
                                {label: 'OL', style: 'ordered-list-item'}
                              ]
                            };
    const toolbarStyle    = { background: '#fff',
                              margin: 0,
                              padding: '10px 10px 5px',
                              height: '52px' };

    return (
      <RichTextEditor ref={ (ref) => this.rte = ref }
                      placeholder={ placeholder }
                      rootStyle={ rootStyle }
                      toolbarStyle={ toolbarStyle }
                      toolbarConfig={ toolbarConfig }
                      customControls={ customControls }
                      value={ value }
                      onChange={ onChange } />
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

BodyEditor.propTypes = {
  fields:     PropTypes.array.isRequired,
  value:      PropTypes.object.isRequired,
  onChange:   PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default BodyEditor;
