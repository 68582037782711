import React                    from 'react';

import MasterDetail             from 'components/MasterDetail';
import connectResource          from 'utils/connectResource';
import withCategory             from 'hocs/withCategory';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import withFilters              from 'hocs/withFilters';
import { getPageAllById, getAllById }       from 'utils/connectors';
import { sortByAttributes,
         reverseSortByAttributes } from 'utils/sortComparators';
import formattedDate            from 'utils/formattedDate';

import { humanNamesFilter,
         numericFilter,
         textSubstringFilter,
         dateFilter,
         combineFilters }       from 'utils/recordFilters';

import { clients, categoriesByType }              from 'resources/organizationResources';

import SideCardView             from '../SideCardView';
import DetailView               from './DetailView';
import FormView                 from './FormView';
import ListItemView             from './ListItemView';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class App extends React.Component {
  render() {

    const dateParser = (date) => { formattedDate(date, 'date'); };

    const nameFilter  = humanNamesFilter('first_name', 'middle_name', 'last_name');
    const idFilter    = numericFilter('id');
    const suffixFilter = textSubstringFilter('ssn_suffix');
    const dobFilter    = dateFilter(dateParser, 'dob');
    const filters      = combineFilters(nameFilter, idFilter, suffixFilter, dobFilter);

    return (
      <MasterDetail masterItemComponent={ListItemView}
                    detailComponent={DetailView}
                    sideCardComponent={SideCardView}
                    formComponent={FormView}
                    printable={true}
                    groupable={true}
                    categorizable={ true }
                    categoriesByType={ categoriesByType }
                    versioned={true}
                    records={this.props.data.clients || []}
                    onDelete={id => this.props.destroy(null, {id})}
                    onUpdate={record => this.props.update(record, {id: record.id})}
                    onCreate={record => this.props.create(record)}
                    resource='clients'
                    listSort={ sortByAttributes('last_name', 'first_name') }
                    secondaryListSort={ reverseSortByAttributes('active') }
                    filterRecord={ filters }
                    {...this.props} />
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query, filters, category }) => [page, query, filters, category],
  connectors: {
    clients: getPageAllById(clients),
    categoriesByType: getAllById(categoriesByType, () => ({ type: 'client' }), true),
  },
  mapDispatchToProps: { ...clients.actions }
});

export default withSearch(withCategory(withFilters(withPagination(connectedComponent))));
