import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Dropdown }         from 'semantic-ui-react';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

function convertToMustache(fieldName) {
  let scopes = fieldName.split('.'),
      name   = `{{ ${ scopes.pop() } }}`;

  return scopes.reverse().reduce((fullName, scope) => {
    return `{{#${scope}}}${fullName}{{/${scope}}}`;
  }, name);
}

function getOptions(fields) {
  return fields.map(f => ({ text: f.label, value: f.id }));
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class FieldInserter extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // handlers
  handleChange(evt, { value }) {
    const { scope } = this.props;
    const clippy    = new DataTransfer();
    const mtext     = convertToMustache(value);
    clippy.setData('text/plain', mtext);
    
    scope.rte.editor._onPaste({
      preventDefault : () => {},
      clipboardData  : clippy
    });
  }

  // rendering
  render() {
    const { fields }    = this.props;
    const classNames    = 'icon inserter';
    const icon          = 'add';
    const selectOnBlur  = false;
    const text          = 'Insert Field';
    const options       = getOptions(fields);

    return (
      <Dropdown button
                labeled
                scrolling
                className={ classNames }
                icon={ icon }
                selectOnBlur={ selectOnBlur }
                text={ text }
                options={ options }
                onChange={ this.handleChange } />
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

FieldInserter.propTypes = {
  fields: PropTypes.array.isRequired,
  scope:  PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default FieldInserter;
