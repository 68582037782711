import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form, Select }     from 'semantic-ui-react';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

function getOptions(fields) {
  return fields.map( ({id, name}) => ({ text: name, value: id }));
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class UpdatedFieldsSelector extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // handlers
  handleChange(evt, { value }) {
    const { onChange } = this.props;
    onChange(value);
  }

  // rendering
  render() {
    const { fields, value } = this.props;
    const placeholder       = 'Select an Field';
    const options           = getOptions(fields);

    return (
      <Form.Field control={ Select }
                  placeholder={ placeholder }
                  options={ options }
                  value={ value }
                  clearable
                  onChange={ this.handleChange } />
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

UpdatedFieldsSelector.propTypes = {
  fields:     PropTypes.array.isRequired,
  value:      PropTypes.string.isRequired,
  onChange:   PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default UpdatedFieldsSelector;
