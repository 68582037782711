import React             from 'react';
import PropTypes         from 'prop-types';
import { Header, Table } from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

const ColumnId = (props) => {
  const { origins,
          label,
          columnId } = props;
  const origin       = origins[columnId];
  const { name }     = { ...origin };
  const defaultName  = 'No column has been mapped.';

  return (
    <Table.Row>
      <Table.Cell>
        <Header as='h4'>
          { label }
        </Header>
      </Table.Cell>
      <Table.Cell>
        { name || defaultName }
      </Table.Cell>
    </Table.Row>
  );
};

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

ColumnId.propTypes = {
  origins:  PropTypes.object.isRequired,
  label:    PropTypes.string.isRequired,
  columnId: PropTypes.number
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ColumnId;
