import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Map, TileLayer, Marker, Popup} from 'react-leaflet';
import { Message } from 'semantic-ui-react';
import { uniq } from 'lodash';
import icon from 'leaflet/dist/images/marker-icon.png';

import 'leaflet/dist/leaflet.css';
import 'style/components/Map.css';

const markerIcon = {
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [25, 41],
};

const defaultZoom = 10;

function makeMarkers(groups) {
  return groups.map((member, index) => {

    return L.icon({
      ...markerIcon,
      className: `marker-group-${index}`,
    });
  });
}

function processMarkers(records, groups, locationIndex, labelIndex, groupIndex) {
  return records.map((point) => {
    if (point[locationIndex] === null || point[locationIndex] === '[]' || point[locationIndex] === '') {
      return null;
    }

    const location = JSON.parse(point[locationIndex]);
    const label    = point[labelIndex];
    const group    = groupIndex ?
                      groups.indexOf(point[groupIndex]) :
                      0;

   return [location, label, group];

  }).filter((marker) => marker !== null);
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const MapView = ({ data }) => {

  const { location_index, label_index, group_by_index, records, zoom } = data;
  if (!location || !records) {
    return null;
  }

  const groups = group_by_index ? uniq(records.map((point) => point[group_by_index])) : [null];
  const markers = processMarkers(records, groups, location_index, label_index, group_by_index);
  const markerIcons = makeMarkers(groups);


  return (

    <>
      { records.length !== markers.length &&
        <Message>
          <p>
            Some records are missing location information. Currently displaying <b>{markers.length} of {records.length}</b>.
          </p>
         </Message>
      }

      <Map center={markers[0][0]} style={{height : '90%'}} zoom={zoom || defaultZoom}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      />

      {
        markers.map((marker, index) => (
          <Marker key={index} position={marker[0]} icon={markerIcons[marker[2]]}>
            <Popup>
              { marker[1] }
            </Popup>
          </Marker>
        ))
      }
      </Map>
    </>

  );
};

MapView.propTypes = {
  data: PropTypes.object.isRequired
};


export default MapView;

