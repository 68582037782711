import React from 'react';
import PropTypes from 'prop-types';

import 'style/components/HeaderBar.css';

const initialStyle = {
  'display':  'block',
  'position': 'absolute',
  'top':      '0px',
  'left':     '0px',
  'right':    '0px',
  'height':   '50px'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const HeaderBar = ({ height, className, children }) => {
  const style = { ...initialStyle, height };

  return (
    <header style={style}
            className={`header-bar-component ${className}`}>

      { children }
    </header>
  );
};

HeaderBar.defaultProps = {
  className:    '',
  height:       '50px'
};

HeaderBar.propTypes = {
  height:     PropTypes.string,
  className:  PropTypes.string
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default HeaderBar;
