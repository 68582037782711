import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Search,
         Form } from 'semantic-ui-react';
import { debounce, isEmpty } from 'lodash';

import withPagination       from 'hocs/withPagination';
import withSearch           from 'hocs/withSearch';
import connectResource      from 'utils/connectResource';
import formattedDate        from 'utils/formattedDate';
import { getPageAllById }   from 'utils/connectors';
import { sortByAttributes } from 'utils/sortComparators';
import { humanNamesFilter } from 'utils/recordFilters';
import { getChangedAnswer } from 'utils/getChangedAnswer';
import { clients }          from 'resources/organizationResources';
import CreateClientModal    from './CreateClientModal';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const SEARCH_DEBOUNCE = 250;

const searchStyle = {
  margin: 0
};

const clientNamesFilter =
  humanNamesFilter('first_name', 'middle_name', 'last_name');

const clientSort = sortByAttributes('last_name', 'first_name');

function clientName({ first_name, middle_name, last_name }) {
  return [first_name, middle_name, last_name].filter(name => name).join(' ');
}

function clientNameFromFieldsMapping(clientFieldMapping, answers, changes) {
  const { first_name, middle_name, last_name } = clientFieldMapping || {};

  return [first_name, middle_name, last_name]
          .filter(field => field)
          .map(field => getChangedAnswer(field, changes, answers) )
          .join(' ');
}

const clientRenderer = ({ title, dob }) => (
  <div>
    <strong>{ title }</strong>
    <div className='pull-right'>
      <small className='text-muted text-uppercase'>
        { formattedDate(dob, 'date') }
      </small>
    </div>
  </div>
);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class UnlinkedView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value:          '',
      searchStarted:  false,
      selectedClient: null,
      searchResults:  [],
      modalOpened:    false
    };

    this.handleSave         = this.handleSave.bind(this);
    this.handleSelect       = this.handleSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleOpenModal    = this.handleOpenModal.bind(this);
    this.handleCloseModal   = this.handleCloseModal.bind(this);
    this.handleCreateClient = this.handleCreateClient.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { clientFieldMapping: prevMapping, answers: prevAnswers, changes: prevChanges } = prevProps;
    const { clientFieldMapping, answers, changes } = this.props;

    if (prevMapping !== clientFieldMapping || prevAnswers !== answers || prevChanges !== changes) {
      this.setState({
        value: clientNameFromFieldsMapping(clientFieldMapping, answers, changes),
        searchStarted: false,
        searchResults: []

      });
    }
  }

  render() {
    const { loading, answers, changes, clientFieldMapping }                             = this.props;
    const { searchStarted, selectedClient, searchResults, value, modalOpened } = this.state;
    const isInvalid = selectedClient === null;
    const hasResults = !isEmpty(value) && !isEmpty(searchResults);

    return (
      <React.Fragment>
      <Form onSubmit={ this.handleSave }>
        <p>
          Begin typing the name of the client to which
          this response should be linked.
        </p>

        <p>Click the <em>Create Link</em> button to create the link.</p>

        <Form.Field>
          <Search results={ searchResults }
                  value={ value }
                  onFocus={ this.handleSearchChange }
                  onResultSelect={ this.handleSelect }
                  onSearchChange={ this.handleSearchChange }
                  style={ searchStyle }
                  loading={ loading }
                  resultRenderer={ clientRenderer }
                  showNoResults={ false }
                  fluid />
        </Form.Field>

        { hasResults &&
        <Form.Button  basic
                      primary
                      disabled={ isInvalid }>Create Link</Form.Button>
        }
      </Form>

      { value && searchStarted &&
        <CreateClientModal  opened={ modalOpened }
                            value={ value }
                            answers={ answers }
                            changes={ changes }
                            clientFieldMapping={ clientFieldMapping }
                            hasResults={ hasResults }
                            handleCloseModal={ this.handleCloseModal }
                            handleOpenModal={ this.handleOpenModal }
                            handleCreateClient={ this.handleCreateClient } />
      }
      </React.Fragment>
    );
  }

  handleSave() {
    const { onLink }          = this.props;
    const { selectedClient }  = this.state;

    onLink(selectedClient);
  }

  handleSelect(evt, { result }) {
    const { data }        = this.props;
    const { clients }     = data;
    const { key, title }  = result;
    const client          = Object.values(clients)
                                  .find(({ id }) => id === key);

    this.setState({
      value: title,
      selectedClient: client
    });
  }

  handleSearchChange(evt, { value }) {
    const { onSearch } = this.props;
    onSearch({ query: value });

    this.setState({ value }, () => {   // we need to chain this as a callback to
      const { value } = this.state;    // ensure we are operating on the state
      this.updateSearchResults(value); // value we intend.
    });
  }

  updateSearchResults = debounce((value) => {
    const { data }      = this.props;
    const clients       = data.clients || {};

    const searchResults = Object
                          .values(clients)
                          .filter((client) => clientNamesFilter(value, client))
                          .sort(clientSort)
                          .map((client) => ({
                            key:    client.id,
                            title:  clientName(client),
                            dob:    client.dob
                          }));

    this.setState({ searchResults, searchStarted: true });
  }, SEARCH_DEBOUNCE);

  handleOpenModal() {
    this.setState({ modalOpened: true });
  }

  handleCloseModal() {
    this.setState({ modalOpened: false });
  }

  handleCreateClient(client) {
    this.setState({
      selectedClient: client
    });
    this.handleSave();
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

UnlinkedView.defaultProps = {
  loading: false
};

UnlinkedView.propTypes = {
  loading:  PropTypes.bool,
  clientFieldMapping: PropTypes.object.isRequired,
  answers:  PropTypes.object.isRequired,
  changes:  PropTypes.array.isRequired,
  data:     PropTypes.object.isRequired,
  onLink:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedComponent = connectResource(UnlinkedView, {
  key: ({ page, query }) => [page, query],
  connectors: {
    clients: getPageAllById(clients)
  }
});

export default withSearch(withPagination(connectedComponent));
