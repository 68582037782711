import React, {useState}  from 'react';
import PropTypes          from 'prop-types';
import { Button,
         Popup,
         Confirm }        from 'semantic-ui-react';
import { Link }           from 'react-router-dom';

import Privileges         from 'constants/Privileges';
import WithPermission     from 'containers/WithPermission';

import CloneModal              from './CloneModal';
import CloneResource              from 'resources/organization/CloneResource';
import ClientFormResource         from 'resources/organization/ClientFormResource';
import useOrganizationFetcher     from 'hooks/useOrganizationFetcher';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const title = {
  edit: 'Edit this form response.',
  clone: 'Duplicate and edit this form response.',
  delete: 'Delete this form rsponse.',
};

function editClientFormUrl(clientId, clientFormId){
  return `/organization/clients/${clientId}/client_forms/${clientFormId}/edit`;
}

async function cloneHandler({ clientId, clientFormId, clientsIdsList, history, clone }) {
    const params  = { cloneable_type: 'client_forms', cloneable_id: clientFormId };

    const response = await clone(params, { clients_ids: clientsIdsList});

    if(response.id && clientsIdsList.length === 0) {
      history.push(editClientFormUrl(clientId, response.id));
    }
}

function deleteHandler({ clientId, clientFormId, destroy }) {
  return async () => {
    const params = { client_id: clientId, id: clientFormId };
    destroy(params);
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ManageButtons = ({ clientId, clientFormId, history }) => {

  const [deleteModal, setDeleteModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);

  const clone         = useOrganizationFetcher(CloneResource.createShape());
  const destroy       = useOrganizationFetcher(ClientFormResource.deleteShape());
  const handleClone   = (clientsIdsList) => {
    setCloneModal(false);
    cloneHandler({ clientId, clientFormId, clientsIdsList, history, clone });
  };
  const handleDelete  = deleteHandler({ clientId, clientFormId, destroy});

  return (
    <React.Fragment>
       <WithPermission resource='client_forms'
                      recordId={ clientFormId }
                      actions={ Privileges.write }
                      anyGroup={ true }>

                      <Popup  trigger={
                                <Button icon='pencil'
                                        basic
                                        color='blue'
                                        as={ Link }
                                        to={ editClientFormUrl(clientId, clientFormId) } />
                              }
                              content={ title['edit'] }
                              position='top center' />

                      <Popup  trigger={
                              <Button  onClick={ () => setCloneModal(true) }
                                        icon='copy'
                                        basic
                                        color='blue' />
                              }
                              content={ title['clone'] }
                              position='top center' />

                      <CloneModal
                        opened={ cloneModal }
                        onClose={ () => setCloneModal(false) }
                        onSubmit={ handleClone }
                      />
      </WithPermission>

      <WithPermission resource={ 'client_forms' }
                      recordId={ clientFormId }
                      actions={ Privileges.remove }>

                      <Popup  trigger={
                                <Button onClick={ () => setDeleteModal(true) }
                                        icon='trash'
                                        basic
                                        color='red' />
                              }
                              content={ title['delete'] }
                              position='top center' />

                      <Confirm
                        as={Link}
                        to={'./'}
                        open={ deleteModal }
                        dimmer='inverted'
                        onCancel={ () => setDeleteModal(false) }
                        onConfirm={ () => handleDelete() } />
      </WithPermission>
    </React.Fragment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ManageButtons.propTypes = {
  clientId:     PropTypes.string.isRequired,
  clientFormId: PropTypes.string.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ManageButtons;
