const storage = localStorage;
const key     = 'authToken';

// -----------------------------------------------------
// Util Definition
// -----------------------------------------------------

const AuthToken = {
  read:   () => storage.getItem(key),
  write:  (authToken) => storage.setItem(key, authToken),
  remove: () => storage.removeItem(key)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AuthToken;
