import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ReferralResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/case_worthy/referrals';

  id                = undefined;
  referral_status   = undefined;
  status_updated_at = undefined;
  created_at        = undefined;
  updated_at        = undefined;
  client_form_id    = undefined;
  referral_id       = undefined;

  pk() {
    return this.id && this.id.toString();
  }
}
