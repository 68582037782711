import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';

import { logout }                 from 'resources/auth';
import { impersonation }          from 'resources/organizationResources';
import connectResource            from 'utils/connectResource';
import { selectPassively }        from 'utils/connectors';

import UserMenu                   from 'components/UserMenu';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class UserMenuContainer extends Component {

  constructor(props) {
    super(props);

    this.handleStopImpersonation  = this.handleStopImpersonation.bind(this);
    this.handleLogout             = this.handleLogout.bind(this);
  }

  render() {
    const { data, user }  = this.props;
    const impersonation   = data.impersonation || {};

    return(
      <UserMenu user={ user }
                impersonatedUser={ impersonation.user }
                onStopImpersonation={ this.handleStopImpersonation }
                onLogout={ this.handleLogout } />
    );
  }

  async handleStopImpersonation() {
    const { history }  = this.props;
    history.push('/organization/impersonation/destroy');
  }

  handleLogout() {
    const { logout, history } = this.props;
    logout();
    history.push('/');
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

UserMenuContainer.propTypes = {
  user: PropTypes.object.isRequired,

  data: PropTypes.shape({
    impersonation: PropTypes.object
  }).isRequired,

  history:  PropTypes.object.isRequired,
  logout:   PropTypes.func.isRequired
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default connectResource(UserMenuContainer, {
  connectors: {
    impersonation: selectPassively(impersonation)
  },

  mapDispatchToProps: {
    logout
  }
});
