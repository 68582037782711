import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form }             from 'semantic-ui-react';
import cast                 from 'utils/cast';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    const { value } = this.props;

    // Ensures the unchecked value is applied, if the box is never checked
    // (triggering a onChange)
    if(value === null) {
      this.handleChange(null, { checked: false });
    }
  }

  render() {
    const { value, metadata, dataType }  = this.props;
    const { checked_value=true }         = metadata;
    const checked                        = (value === cast(checked_value, dataType));

    return (
      <Form.Checkbox  checked={ checked }
                      onChange={ this.handleChange } />
    );
  }

  handleChange(evt, { checked }) {
    const { onChange, name, metadata } = this.props;
    const { checked_value=true, unchecked_value=false } = metadata;
    const value = checked ? checked_value : unchecked_value;

    onChange(evt, { name, value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Checkbox.defaultProps = {
  value:    null,
  metadata: {},
  loading:  false
};

Checkbox.propTypes = {
  value:    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  metadata: PropTypes.object,
  loading:  PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Checkbox;
