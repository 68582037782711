import React from 'react';
import PropTypes  from 'prop-types';
import { Table, Statistic }  from 'semantic-ui-react';

import ActiveStateBadge from 'components/ActiveStateBadge';
import formattedDate    from 'utils/formattedDate';
import formattedDow     from 'utils/recurrence';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const InstanceStatus = ({ isActive, publishedAt, expiredAt, dow, timeStart, timeEnd }) => {
  return (
    <Table basic='very'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>Status</Table.HeaderCell>
          <Table.HeaderCell width={2}>Published At</Table.HeaderCell>
          <Table.HeaderCell width={2}>Expires At</Table.HeaderCell>
          <Table.HeaderCell width={2}>Recurrence</Table.HeaderCell>
          <Table.HeaderCell width={2}>From - To</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell><ActiveStateBadge isActive={ isActive } size='small' /></Table.Cell>
          <Table.Cell>
            <Statistic size='mini' color='grey'>
              <Statistic.Value>
                { formattedDate(publishedAt, 'datetime_long', 'Not Published') }
              </Statistic.Value>
            </Statistic>
          </Table.Cell>
          <Table.Cell>
            <Statistic size='mini' color='grey'>
              <Statistic.Value>
                { formattedDate(expiredAt) }
              </Statistic.Value>
            </Statistic>
          </Table.Cell>
          <Table.Cell>
            { formattedDow(dow) }
          </Table.Cell>
          <Table.Cell>
            { !!timeStart &&
              <>
                {timeStart} - {timeEnd}
              </>
            }
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

InstanceStatus.propTypes = {
  isActive:     PropTypes.bool.isRequired,
  publishedAt:  PropTypes.string,
  expiredAt:    PropTypes.string
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default InstanceStatus;
