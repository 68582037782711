import React, { Fragment }  from 'react';

import 'style/components/Fixed.css';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Fixed = ({ children, ...forwardProps }) => {
  return (
    <Fragment>
      <div { ...forwardProps } className='fixed-component'>
        { children }
      </div>
    </Fragment>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Fixed;
