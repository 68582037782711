import React      from 'react';
import PropTypes  from 'prop-types';
import { Table }  from 'semantic-ui-react';

import ActionCell         from './ActionCell';
import PayloadActionCell  from './PayloadActionCell';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const payloadCarriers = new Set(['set']);

const defaultActionTargetType = 'field';

function actionTarget(value) {
  return {
    type: defaultActionTargetType,
    id:   value
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Row = ({ field, actions, actionTypeList, onChange }) => {
  const { label, value } = field;

  return (
    <Table.Row>
      <Table.Cell>{ label }</Table.Cell>
      {
        actionTypeList.map((actionType) => (
          payloadCarriers.has(actionType)
          ? <PayloadActionCell  key={ actionType }
                                type={ actionType }
                                target={ actionTarget(value) }
                                actions={ actions }
                                field={ field }
                                onChange={ onChange } />

          : <ActionCell key={ actionType }
                        type={ actionType }
                        target={ actionTarget(value) }
                        actions={ actions }
                        onChange={ onChange } />
        ))
      }
    </Table.Row>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Row.propTypes = {
  field:  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    dataType:   PropTypes.string,
    fieldType:  PropTypes.string,
    fieldOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool
        ])
      })
    )
  }),
  actions:        PropTypes.array.isRequired,
  actionTypeList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange:       PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Row;
