import Ajv from 'ajv';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

//
const matcher = (new Ajv()).compile({
  type:     'object',
  required: [ 'scope', 'conditions'],
  properties: {
    scope: {
      type:     'string',
      pattern:  '^(self|form|administrator|client|response|calculator)$'
    },
    conditions: {
      type: 'object',
      required: [ 'and' ],
      properties: {
        and: {
          type: 'array',
          items: {
            oneOf: [
              {
                type: 'object',
                required: ['field_name', 'op', 'value'],
                properties: {
                  field_name: {
                    type: 'string'
                  },
                  op: {
                    type: 'string',
                    enum: [
                      'EQ',
                      'NEQ',
                      'IN',
                      'NIN',
                      'LT',
                      'LTE',
                      'GT',
                      'GTE',
                      'BWITH',
                      'EWITH',
                      'CONTAINS',
                      'NCONTAIN',
                      'RANGE',
                      'NRANGE',
                      'DOW',
                      'NDOW'
                    ]
                  },
                  value: {
                    type: ['array', 'number', 'string', 'boolean', 'null']
                  }
                }
              }, {
                type: 'object',
                required: [ 'field_name', 'op'],
                properties: {
                  field_name: { type: 'string' },
                  op:         { type: 'string', enum: ['EMPTY', 'NEMPTY', 'TRUE', 'FALSE'] }
                }
              }
            ]
          }
        }
      }
    }
  }
});

function transformIn(data) {
  const { scope, conditions }    = data;
  const { and }                  = conditions;
  const { field_name, op, value} = and[0];
  const fieldId = (scope === 'self') ? field_name : `${ scope }.${ field_name }`;

  return { field_name: fieldId, op, value };
}

function transformOut(data) {
  const { field_name: fieldId, op, value } = data;
  const [attr, obj] = fieldId.split('.').reverse();
  const scope = obj || 'self';

  return {
    scope: `${ scope }`,
    conditions: {
      and: [
        { field_name: `${ attr }`, op, value }
      ]
    }
  };
}

function create() {
  return transformOut({ field_name: 'id', op: 'EQ', value: '' });
}

// -----------------------------------------------------
// Class Definition
// -----------------------------------------------------

class FieldProvider {
  constructor(node, name, providesControl=true) {
    this.adapter = {
      matcher,
      transformIn,
      transformOut,
      create,
      node,
      name,
      providesControl
    };
  }

  getAdapter() {
    return this.adapter;
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldProvider;
