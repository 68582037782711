import React, { Component }         from 'react';
import { Segment, Header, Button, Loader }  from 'semantic-ui-react';
import { reportSummary }            from 'resources/organizationResources';
import connectResource              from 'utils/connectResource';
import { getById }                  from 'utils/connectors';
import SummaryModal                 from './SummaryModal';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changeSettings: false,
    };

    this.handleUpdateSettings = this.handleUpdateSettings.bind(this);
    this.handleOpenModal      = this.handleOpenModal.bind(this);
    this.handleCloseModal     = this.handleCloseModal.bind(this);
  }

  render() {
    const { changeSettings }  = this.state;
    const { reportSummary }   = this.props.data || {};

    const { stats=[], settings, fields } = reportSummary || {};

    return (
      <Segment>
        <Header as='h3' dividing>
          Report Summary
          <div className='pull-right'>
            <Button basic compact
                    icon='cog'
                    onClick={ this.handleOpenModal } />
          </div>
        </Header>

        { !Array.isArray(stats) &&
          <div style={{ textAlign: 'center' }}>Summary not loaded. <br />Please check your summary settings</div>
        }
        { Array.isArray(stats) && !stats.length &&
          <div style={{ position: 'relative', minHeight: '200px' }}>
            <Loader active>Loading</Loader>
          </div>
        }
        { Array.isArray(stats) && !!stats.length && stats.map( ({label, value}, i) => {
                  if (value) {
                    return <div key={i}>{ label }: { value }</div>;
                  } else {
                    return null;
                  }
                 })
        }

        <SummaryModal  opened={ changeSettings }
                       settings = { settings || [] }
                       fields = { fields || [] }
                       onClose={ this.handleCloseModal }
                       onSubmit={ this.handleUpdateSettings } />
      </Segment>
    );
  }

  async handleUpdateSettings(settings) {
    const { reportId, refetch, update } = this.props;

    const updateResponse =
      await update(null, {
        id:         reportId,
        report_id:  reportId,
        settings:   JSON.stringify(settings)
      });

    if(updateResponse) {
      await refetch(null, {
        id:         reportId,
        report_id:  reportId,
      });
    }

    this.setState({ changeSettings: false });
  }

  handleOpenModal() {
    this.setState({ changeSettings: true });
  }

  handleCloseModal() {
    this.setState({ changeSettings: false });
  }

}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(Summary, {
  key: ({ reportId }) => reportId,
  connectors: {
    reportSummary: getById(
      reportSummary,  ({ reportId: id }) => ( id ), ({ reportId: id }) => ( {report_id: id} )
    ),
  },
  mapDispatchToProps: {
    refetch: reportSummary.actions.show,
    create: reportSummary.actions.create,
    update: reportSummary.actions.update,
  }
});
