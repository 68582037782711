// If these constants are ever changed, all form-logic data will need to be
// updated with these changes.  Changing these constants is not advised.
const prefix    = 'calc';
const delimiter = '.';

// wrapId is used to wrap the id of a calculation record for use in form-logic.
//
// Form-logic currently functions with reference to field_ids, but we also want
// to use calculations in form-logic, so to avoid id collision, we must scope the
// calculation id to set it apart from field ids.
export default function wrapId(id) {
  return [prefix, id].join(delimiter);
}
