export const windowFunctionType  = 'window';
export const aggFunctionType     = 'agg';

export const componentOptions = [
  { value: 'streetAddress', text: 'Address' },
  { value: 'city',          text: 'City' },
  { value: 'state',         text: 'State' },
  { value: 'postalCode',    text: 'Postal Code' },
  { value: 'location',      text: 'Geo Location' }
];

export const functionOptions = [
  { value: 'none',     text: 'None' },
  { value: 'agg',      text: 'Aggregate' },
  { value: 'window',   text: 'Window' }
];

export const aggregationOptions = [
  { value: 'avg',      text: 'Avg' },
  { value: 'count',    text: 'Count' },
  { value: 'distinct', text: 'Count Distinct' },
  { value: 'max',      text: 'Max' },
  { value: 'min',      text: 'Min' },
  { value: 'sum',      text: 'Sum' }
];

export const windowFunctionOptions = [
  { value: 'partition_field',       text: 'Group By' },
  { value: 'order_fields:earliest', text: 'Earliest' },
  { value: 'order_fields:latest',   text: 'Latest' },
  { value: 'order_fields:compare',  text: 'Earliest and Latest (Comparison)' }
];

export default { windowFunctionType, aggFunctionType };
