import React              from 'react';
import PropTypes          from 'prop-types';
import { Route, Switch }  from 'react-router-dom';
import urljoin            from 'url-join';

import ApiCallBoundary  from 'components/api/ApiCallBoundary';

import RecordList         from './RecordList';
import EditResponseForm   from './EditResponseForm';
import Preview            from './Preview';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = ({ match: { path } }) => {
  return (
    <ApiCallBoundary>
      <Switch>
        <Route  path={ urljoin(path, '/responses/:responseId/edit') }
                component={ EditResponseForm } />

        <Route  path={ urljoin(path, '/responses/:responseId') }
                component={ Preview } />

        <Route  path={ path }
                component={ RecordList } />
      </Switch>
    </ApiCallBoundary>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DetailView.propTypes = {
  match:  PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DetailView;
