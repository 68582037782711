import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ClientFormResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/:cloneable_type/:cloneable_id/clone';

  id    = undefined;
  type  = '';

  pk() {
    return this.id && this.id.toString();
  }
}
