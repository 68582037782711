import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form, Select }     from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class ColumnId extends Component {

  // construction
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  // handlers
  handleChange(evt, { value }) {
    evt.stopPropagation();
    const { onChange } = this.props;
    onChange(value);
  }

  // helpers
  getOriginOptions() {
    const { origins } = this.props;
    const values      = Object.values(origins);
    let   options     = values.map(o => (
                          { text: o.name, value: o.id }
                        ));
    return options;
  }

  // rendering
  render() {
    const { columnId, label } = this.props;
    const originOptions       = this.getOriginOptions();

    return (
      <Form.Field className='required'
                  label={ label }
                  placeholder='Select a column'
                  control={ Select }
                  options={ originOptions }
                  value={ columnId }
                  onChange={ this.handleChange } />
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

ColumnId.propTypes = {
  origins:  PropTypes.object.isRequired,
  label:    PropTypes.string.isRequired,
  columnId: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ColumnId;
