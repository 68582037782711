import ApiResource from './ApiResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class AuthenticationResource extends ApiResource {
  static urlRoot = '/authentication';

  auth_token = null

  pk() {
    return 'authorization';
  }
}
