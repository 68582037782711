import React      from 'react';
import PropTypes  from 'prop-types';

const initialStyle = {
  'position': 'absolute',
  'top':      '0px',
  'bottom':   '0px',
  'left':     '0px'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const BrandBox = ({ width, className, children }) => {
  const style = { ...initialStyle, width };

  return (
    <div  style={ style }
          className={ `brand-box-component ${className}` }>
      { children }
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

BrandBox.defaultProps = {
  className: ''
};

BrandBox.propTypes = {
  width:      PropTypes.string.isRequired,
  className:  PropTypes.string
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default BrandBox;
