import { useResource }        from 'rest-hooks';
import { useApiErrorContext } from 'components/api/ApiErrorContext';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function useApiResource(resourceShape, params={}) {
  const { setError }  = useApiErrorContext();

  if(resourceShape) {
    const { fetch } = resourceShape;

    resourceShape.fetch = (...args) => {
      return  fetch
              .apply(resourceShape, args)
              .catch((error) => {
                setError(error);
                throw error;
              });
    };
  }

  return useResource(resourceShape, params);
}
