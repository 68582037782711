const read            = 'read';
const write           = 'write';
const remove          = 'remove';
const use          = 'use';
const validPrivileges = [read, write, remove, use];

export default {
  read,
  write,
  remove,
  use,
  validPrivileges
};
