import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';

import MergeModal from './MergeModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Merge client records.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class MergeLink extends Component {
  constructor(props) {
    super(props);

    this.handleClick  = this.handleClick.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  render() {
    const { children, opened }  = this.props;

    //
    return (
      <Fragment>
        <MergeModal title={ title }
                    opened={ opened }
                    onClose={ this.handleClose }
                    { ...this.props } />


        <a  href='#merge-clients'
            onClick={ this.handleClick }>{ children }</a>
      </Fragment>
    );
  }

  handleClose() {
    this.props.setShowMergeModal(false);
  }

  handleClick(evt) {
    evt.preventDefault();
    this.props.setShowMergeModal(true);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

MergeLink.propTypes = {
  client:     PropTypes.object.isRequired,
  duplicates: PropTypes.arrayOf(PropTypes.object).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default MergeLink;
