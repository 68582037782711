import React                                              from 'react';
import PropTypes                                          from 'prop-types';
import { Segment, Header, Message, List, Icon, Divider }  from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const OrgUsageInfo = ({ loading, fields }) => {

  return (
    <Segment loading={ loading }>
      <Header as='h3'>Organization Usage Information</Header>
      {
        fields && fields.length
        ? <div>
            <Message icon info>
              <Icon name='info circle' />
                This field is used in&nbsp;
              <strong>{fields.length}</strong>&nbsp;organizations.
            </Message>

            <Divider horizontal>Organizations</Divider>

            <List divided relaxed='very'>
              {
                fields.map(({ id, forms, organization }) => {

                  return (
                    <List.Item key={ id }>
                      <List.Icon  name='building outline' />

                      <List.Content>
                        <List.Header>
                          { organization.name }
                        </List.Header>
                        <List.List>
                          {
                            forms.map((form) => (
                              <List.Item key={ form.id }>
                                <List.Icon  name='file outline' />

                                <List.Content>
                                  <List.Header>{ form.name }</List.Header>
                                </List.Content>
                              </List.Item>
                            ))
                          }
                        </List.List>
                      </List.Content>
                    </List.Item>
                  );
                })
              }
            </List>
          </div>
        : <Message info warning>
            <Icon name='warning circle' />
            This field is not used by any organizations.
          </Message>

      }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

OrgUsageInfo.defaultProps = {
  loading:  false,
  fields:   []
};

OrgUsageInfo.propTypes = {
  loading:  PropTypes.bool,
  fields:   PropTypes.array
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default OrgUsageInfo;
