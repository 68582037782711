import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import { Table }             from 'semantic-ui-react';

import MappingsHeader         from '../../MappingsHeader';
import MappingsRow            from './MappingsRow';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class Mappings extends Component {

  // helpers
  getMappingForDestination(destination) {
    const { mappings } = this.props;
    const { id }       = { ...destination } ;
    const mapping      = { ...mappings[id] };
    return {
      destination_id: destination.id,               // start with defaults
      origin_id:      null,                         // and then override
      force:          true,                         // from actual mapping
      ...mapping
    };
  }

  // rendering
  render() {
    const { destinations, origins, onChange } = this.props;

    return (
      <Table basic='very' className='with-top-margin'>
        <MappingsHeader />
        <Table.Body>
          {
            destinations.map((destination) => (
              <MappingsRow key={ destination.id }
                           destination={ destination }
                           mapping={ this.getMappingForDestination(destination) }
                           origins={ origins }
                           onChange={ onChange } />
            ))
          }
        </Table.Body>
      </Table>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

Mappings.propTypes = {
  destinations: PropTypes.array.isRequired,
  origins:      PropTypes.object.isRequired,
  mappings:     PropTypes.object.isRequired,
  onChange:     PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default Mappings;
