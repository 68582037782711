import React, { useReducer }  from 'react';
import PropTypes              from 'prop-types';
import { Divider, Message }   from 'semantic-ui-react';

import { generateReducers } from 'utils/reducers';
import { preventDefault }   from 'utils/eventHandlers';
import PermissionTable      from 'components/organization/permissions/PermissionTable';

// -----------------------------------------------------
// Internal State
// -----------------------------------------------------

const initialState = { showing: false };

const reducer = generateReducers({
  show: (state) => ({ ...state, showing: true }),
  hide: (state) => ({ ...state, showing: false })
});

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ExtendedPermissions = ({ entities, actions, privileges, onChange }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { showing }         = state;
  const count               = Object.entries({ ...privileges }).length;

  if(showing) {
    return (
      <>
        <a  href='#hide'
            onClick={ preventDefault(() => dispatch({ type: 'hide' })) }>

          Hide extended permissions.
        </a>

        <Divider horizontal>Extended Permissions</Divider>

        <Message  info
                  icon='info'
                  content='These controls allow us to extend privileges to members of this
                  group, on individual records, when records are created or updated.' />

        <PermissionTable  entities={ entities }
                          actions={ actions }
                          privileges={ privileges }
                          disabled={ false }
                          onChange={ onChange } />
      </>
    );
  } else {
    return (
      <a  href='#show'
          onClick={ preventDefault(() => dispatch({ type: 'show' })) }>

        Show extended permissions ({ count })
      </a>
    );
  }
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ExtendedPermissions.defaultProps = {
  entities:   [],
  actions:    [],
  privileges: {}
};

ExtendedPermissions.propTypes = {
  entities:   PropTypes.arrayOf(PropTypes.string),
  actions:    PropTypes.arrayOf(PropTypes.string),
  privileges: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ),
  onChange:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ExtendedPermissions;
