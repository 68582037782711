import React, { Component }           from 'react';
import PropTypes                      from 'prop-types';
import { Responsive, WidthProvider }  from 'react-grid-layout';

import indexOnAttribute from 'utils/indexOnAttribute';
import PinnedMessage    from './Widgets/PinnedMessage';
import Report           from './Widgets/Report';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'style/containers/organization/dashboard.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const gridMargins     = [14, 14];
const gridBreakpoints = { lg: 700,  sm: 0 };
const gridColumns     = { lg: 4,    sm: 1 };

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ShowView extends Component {
  constructor(props) {
    super(props);

    this.handleLayoutChange  = this.handleLayoutChange.bind(this);
  }

  render() {
    const { dashboardWidgets, onDelete }  = this.props;

    return (
      <ResponsiveReactGridLayout  className='layout'
                                  margin={ gridMargins }
                                  breakpoints={ gridBreakpoints }
                                  cols={ gridColumns }
                                  isResizable={ true }
                                  onLayoutChange={ this.handleLayoutChange }>
        {
          dashboardWidgets.map(({ id, record_type, record_id, view_id, layout }) => (
            <div key={ id } data-grid={ layout } className='widget-container'>
              { record_type === 'PinnedMessage'
                ? <PinnedMessage id={ id }
                      pinnedMessageId={ record_id } />
                : <Report id={ id }
                      reportDefinitionId={ record_id }
                      reportViewId={ view_id }
                      onDelete={ onDelete } />
              }
            </div>
          ))
        }
      </ResponsiveReactGridLayout>
    );
  }

  handleLayoutChange(layouts) {
    const { dashboardWidgets, onUpdate } = this.props;
    const indexedWidgets = indexOnAttribute(dashboardWidgets, 'id');

    const changes = layouts.reduce((changes, { i, x, y, w, h }) => {
      const widget                                = indexedWidgets[i];
      const { id, layout, record_id }  = widget;

      const hasChanges  = layout.x !== x
                          || layout.y !== y
                          || layout.w !== w
                          || layout.h !== h;

      if(hasChanges) {
        changes.push({ id, record_id, x, y, w, h });
      }

      return changes;
    }, []);

    onUpdate({ widgets: changes });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ShowView.propTypes = {
  dashboardWidgets: PropTypes.array.isRequired,
  onDelete:         PropTypes.func.isRequired,
  onUpdate:         PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ShowView;
