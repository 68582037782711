import React  from 'react';

import MasterDetail             from 'components/MasterDetail';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import { organizations }        from 'resources/adminResources';
import connectResource          from 'utils/connectResource';
import { getPageAllById }       from 'utils/connectors';
import { sortByAttributes }     from 'utils/sortComparators';
import { textSubstringFilter }  from 'utils/recordFilters';

import DetailView               from './DetailView';
import FormView                 from './FormView';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class App extends React.Component {
  render() {
    const { data, destroy, update, create } = this.props;
    const organizations                     = data.organizations || [];

    return (
      <MasterDetail detailComponent={ DetailView }
                    formComponent={ FormView }
                    records={ organizations }
                    onDelete={ (id) => destroy(null, { id }) }
                    onUpdate={ (record) => update(record, { id: record.id }) }
                    onCreate={ (record) => create(record) }
                    listSort={ sortByAttributes('name') }
                    filterRecord={ textSubstringFilter('name') }
                    { ...this.props } />
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    organizations: getPageAllById(organizations)
  },
  mapDispatchToProps: { ...organizations.actions }
});

export default withSearch(withPagination(connectedComponent));
