import React                      from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header, Table } from 'semantic-ui-react';

import ClientFormResource         from 'resources/organization/ClientFormResource';
import FormResource               from 'resources/organization/FormResource';
import useOrganizationResource    from 'hooks/useOrganizationResource';

import InstanceControls           from './InstanceControls';
import FieldRecord                from './FieldRecord';
import CalculationRecord          from './CalculationRecord';
import ReferralStatus             from 'caseWorthy/components/ReferralStatus';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = ({ match, history }) => {
  const { params: { id, client_form_id } } = match;

  const clientForm  = useOrganizationResource(
                        ClientFormResource.detailShape(),
                        { client_id: id, id: client_form_id }
                      );

  const form        = useOrganizationResource(
                        FormResource.detailShape(),
                        { id: clientForm.form_id }
                      );

  const formFields  = form.form_fields;

  const readOnlyFields =
    formFields.filter(({ field_type }) => (field_type !== 'presentation'));

  const { answers: clientFormFieldAnswers=[],
          calculation_results=[],
          completed_at=null } = clientForm;

  const completedAtRecord = {
      field: { name: 'Time Recorded' },
      answer: { value: completed_at, field_type: 'datetime' }
    };

  const answers = clientFormFieldAnswers.reduce((index, answer) => (
    (index[answer.field_id] = answer) && index
  ), {});

  return (
    <>
      <ReferralStatus clientForm={ clientForm } />
      <Segment>
        <Header as='h3'>{ form.name }</Header>
        <Table basic='very' style={{ marginTop: '2rem' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={ 7 }>Field</Table.HeaderCell>
              <Table.HeaderCell>Answer</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>
                  <InstanceControls clientId={ id }
                              clientFormId={ client_form_id }
                              history={ history } />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              completed_at &&
              <FieldRecord  field={ completedAtRecord.field }
                            answer={ completedAtRecord.answer } />
            }
            {
              readOnlyFields.map((field) => {
                const { id }  = field;
                const answer  = answers[id] || {};

                return (
                  <FieldRecord key={ id } field={ field } answer={ answer } />
                );
              })
            }

            {
              calculation_results.map((result) => (
                <CalculationRecord  key={ result.id } result={ result } />
              ))
            }
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DetailView.defaultProps = {};

DetailView.propTypes = {
  match: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DetailView;
