import React      from 'react';
import PropTypes  from 'prop-types';
import { Form }   from 'semantic-ui-react';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const OWNER_TYPE      = 'owner';
const APPROVED_STATE  = 'approved';

function approvedOwnerAffiliationOptions(affiliations) {
  return  Object
          .values(affiliations)
          .filter(({ type, state }) => (
            type === OWNER_TYPE && state === APPROVED_STATE
          ))
          .map(({ name, id }) => ({
            text: name, value: id
          }));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const AffiliatesSelector = ({ affiliations, affiliationIds, disabled, onChange }) => {
  const options = approvedOwnerAffiliationOptions(affiliations);

  return (
    <Form.Select  options={ options }
                  label='Share this form with affiliates'
                  name='affiliation_ids'
                  value={ affiliationIds }
                  onChange={ onChange }
                  disabled={ disabled }
                  selectOnBlur={ false }
                  clearable
                  multiple />

  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

AffiliatesSelector.defaultProps = {
  disabled: false
};

AffiliatesSelector.propTypes = {
  disabled:       PropTypes.bool,
  affiliationIds: PropTypes.array.isRequired,
  affiliations:   PropTypes.object.isRequired,
  onChange:       PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AffiliatesSelector;
