import React, { Component }       from 'react';
import { Link }                   from 'react-router-dom';
import { Form, Button, Table }    from 'semantic-ui-react';
import { chartTypes }             from 'constants/reports/Charts.js';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function viewTypeName(view_type) {
  if (view_type === 'table') return 'Table';

  return chartTypes[view_type].description;
}

function editPath(view) {
  return `/organization/reports/${view.report_definition_id}/views/${view.id}/edit`;
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class ReportViewItem extends Component {

  render() {
    const { view,
            isSelected,
            isEditable,
            isDeletable,
            onChange,
            onDelete,
            onClose } = this.props;

    return (
        <Table.Row key={`view-${view.id}`}>
            <Table.Cell>{ view.name }</Table.Cell>
            <Table.Cell>{ viewTypeName(view.view_type) }</Table.Cell>
            <Table.Cell>
              <Form.Checkbox
                name='is_default'
                value={view.id}
                checked={ isSelected }
                onChange={ onChange } />
            </Table.Cell>
            <Table.Cell>
            { isEditable &&
              <Button as={ Link }
                to={ editPath(view) }
                color='black'
                icon='write'
                size='small'
                circular
                compact
                basic
                onClick={ onClose } />
            }
            { isDeletable &&
              <Button
                value={view.id}
                onClick={ onDelete }
                color='black'
                icon='trash'
                size='small'
                circular
                compact
                basic />
            }</Table.Cell>
        </Table.Row>
      );
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ReportViewItem;