import React, { Component }      from 'react';
import PropTypes                 from 'prop-types';
import { Modal, Button,
         Header, Form }          from 'semantic-ui-react';
import { deriveFilters }        from 'utils/reportDefinitions';
import FilterSelector            from '../EditModal/FilterSelector';

import 'style/containers/organization/reports/wizard.css';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FiltersModal extends Component {
  constructor(props) {
    super(props);

    const { form,
            reportDefinition } = props;

    this.state = {
      filters: deriveFilters(form, false, null, reportDefinition.window)
    };

    this.handleFiltersChange         = this.handleFiltersChange.bind(this);
    this.handleSave                  = this.handleSave.bind(this);
  }

  render() {
    const { opened,
            onClose,
            reportDefinition,
            ...forwardProps } = this.props;

    const { filters } = this.state;

    return (
        <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
          <Header icon="cog" content="Edit Window Filters" />
          <Modal.Content>
            <Form>
                <FilterSelector filters={ filters }
                                windowComparison={ reportDefinition.window.comparison }
                                reportDefinition={ reportDefinition }
                                { ...forwardProps }
                                onChange={this.handleFiltersChange} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ onClose } negative basic>Cancel</Button>
            <Button onClick={ this.handleSave } primary >Save</Button>
          </Modal.Actions>
        </Modal>
      );
  }

  handleFiltersChange({ filters }) {
    this.setState({ filters });
  }

  handleSave() {
    const { form,
            reportDefinition,
            onChange,
            onClose }  = this.props;

    const { filters } = this.state;

    const nextWindow  = {
        ...reportDefinition.window,
    };

    const nextFilters = nextWindow.filters || {};
    nextFilters[form] = filters;
    nextWindow.filters = nextFilters;

    onChange({
      windowFunction: nextWindow
    });

    onClose();
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FiltersModal.propTypes = {
  opened:           PropTypes.bool.isRequired,
  index:            PropTypes.number.isRequired,
  functionType:     PropTypes.string,
  selectedFields:   PropTypes.array,
  reportDefinition: PropTypes.object,
  fields:           PropTypes.array,
  formCalculations: PropTypes.object,
  forms:            PropTypes.array,
  users:            PropTypes.array,
  onChange:         PropTypes.func.isRequired,
  onClose:          PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FiltersModal;
