import React from 'react';
import { Header, Segment, Table, Grid }  from 'semantic-ui-react';
import useOrganizationResource    from 'hooks/useOrganizationResource';
import ClientFieldAnswerResource         from 'resources/organization/ClientFieldAnswerResource';
import { Link }   from 'react-router-dom';

import SettingsButton from './SettingsButton';
import AnswerRenderer from 'components/fields/AnswerRenderer';

// -----------------------------------------------------
// Sub Components
// -----------------------------------------------------

const Answer = ({ answer, clientId, clientFormId, formId}) => {
  const url = clientFormId ?
                `/organization/clients/${clientId}/client_forms/${clientFormId}` :
                `/organization/clients/${clientId}/client_forms/new/${formId}`;

  return (
    <Link to={ url }>
      { clientFormId ?
        ( answer ?
          <AnswerRenderer answer={ answer } /> :
          '(no answer)'):
        '(no form available)' }
    </Link>
  );
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ClientDashboard = ({ client }) => {

  const clientFieldsAnswers = useOrganizationResource(ClientFieldAnswerResource.detailShape(), { id: client.id, client_id: client.id });
  const { client_fields } = clientFieldsAnswers;

  return (
    <Segment loading={ !client }>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Header as='h3' content='Dashboard Fields' />
          </Grid.Column>

          <Grid.Column width={2} textAlign="right">
            <SettingsButton />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      { client_fields.length > 0 &&
      <Table basic='very' style={{ marginTop: '1rem' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Form</Table.HeaderCell>
            <Table.HeaderCell>Field</Table.HeaderCell>
            <Table.HeaderCell>Answer</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

      { client_fields.map( field =>
          <Table.Row key={ field.formFieldId }>
            <Table.Cell>
              { field.formName }
            </Table.Cell>
            <Table.Cell>
              { field.fieldName }
            </Table.Cell>
            <Table.Cell>
              <Answer clientId={ client.id }
                              clientFormId={ field.clientFormId }
                              formId={ field.formId }
                              answer={ field.answer }
                              />
            </Table.Cell>
          </Table.Row>
        )
      }
      </Table>
      }


    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ClientDashboard.propTypes = {
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ClientDashboard;
