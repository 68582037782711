import React, { Component } from 'react';
import { Switch, Route }    from 'react-router';
import { Message, Loader }  from 'semantic-ui-react';

import ResponseForm                   from 'containers/ResponseForm';
import { Page, Confirmation, Header } from 'components/organization/PublicForm';
import connectResource                from 'utils/connectResource';
import { getById }                    from 'utils/connectors';

import {
  public_forms,
  public_form_instances }   from 'resources/organizationResources';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class PublicFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { data: { form }, loading } = this.props;
    const { name, description, logo, translate } = form || {};

    const header = <Header logo={ logo } name={ name } description={ description } />;
    
    return (
      <Switch>
        <Route exact path='/public_forms/:access_key/success'>
          <Page>
            <Confirmation />
          </Page>
        </Route>

        <Route>
          <Page header={header} translate={translate}>
            { form
              ? <ResponseForm form={ form }
                              isPublic={ true }
                              onSave={ this.handleSubmit } />

              : loading
                ? <Loader active>Loading</Loader>
                : <Message  warning
                            icon='warning circle'
                            header='This form is not currently available.'/>
            }
          </Page>
        </Route>
      </Switch>
    );
  }

  async handleSubmit(answers, done) {
    const { data: { form: { id: access_key, redirect_url } } }  = this.props;
    const { dispatch, history, match }            = this.props;

    const params  = { answers };
    const scope   = { access_key };
    const request = public_form_instances.actions.create(params, scope);

    const response = await dispatch(request);

    if(!response.errors) {
      if (redirect_url)
        window.location.href = redirect_url;
      else {
        history.push(`${match.url}/success`);
      }
    }

    done();
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(PublicFormContainer, {
  form: getById(public_forms, ({ match }) => match.params.access_key)
});
