import AuthenticatedResource from 'resources/AuthenticatedResource';
import resourceUrl           from 'utils/resourceUrl';

// --------------------------------------------------------
// Resource Definition
// --------------------------------------------------------

export default class VersionSearchResultResource extends AuthenticatedResource {
  static urlRoot = '/admin/versions';

  // Override listUrl to append search params.
  static listUrl(searchParams) {
    const url = resourceUrl(this.urlRoot, '', searchParams);
    url.search = new URLSearchParams(searchParams);
    return url;
  }

  id                = undefined;
  item_type         = undefined;
  item_id           = undefined;
  organization_name = undefined;
  user_name         = undefined;
  created_at        = undefined;
  transaction_id    = undefined;
  related_items     = [];

  pk() {
    return this.id && this.id.toString();
  }
}