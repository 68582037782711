import React    from 'react';
import { List } from 'semantic-ui-react';

import MasterDetail                               from 'components/MasterDetail';
import scopedToRole                               from 'hocs/scopedToRole';
import withPagination                             from 'hocs/withPagination';
import withSearch                                 from 'hocs/withSearch';
import { fields, cloneRecords }                   from 'resources/adminResources';
import fieldTypes                                 from 'resources/fieldTypes';
import connectResource                            from 'utils/connectResource';
import { getPageAllById, getById, getSingleton }  from 'utils/connectors';
import { sortByAttributes }                       from 'utils/sortComparators';
import { textSubstringFilter }                    from 'utils/recordFilters';

import DetailView from 'components/fields/DetailView';
import FormView   from 'components/fields/FormView';

// -----------------------------------------------------
// FormView
// -----------------------------------------------------

const ConnectedFormView = connectResource(FormView, {
  connectors : {
    fieldTypes: getSingleton(fieldTypes),
    selected:   getById(fields, (props)=>props.match.params.id)
  },
  mapDispatchToProps : {...fields.actions}
});

// -----------------------------------------------------
// DetailView
// -----------------------------------------------------

const ScopedDetailView  = scopedToRole(DetailView, { role: 'admin' });

const ConnectedDetailView = connectResource(ScopedDetailView, {
  fieldTypes: getSingleton(fieldTypes),
  selected:   getById(fields, (props)=>props.match.params.id)
});

// -----------------------------------------------------
// ListItem
// -----------------------------------------------------

const ListItem = ({record}) => (
  <List.Header>{ record.name }</List.Header>
);

// -----------------------------------------------------
// App Component
// -----------------------------------------------------

class App extends React.Component {
  render() {
    const { data, destroy, update, create, clone } = this.props;

    return (
      <MasterDetail masterItemComponent={ListItem}
        detailComponent={ConnectedDetailView}
        formComponent={ConnectedFormView}
        records={data.fields||[]}
        onDelete={id => destroy(null, {id})}
        onUpdate={record => update(record, {id: record.id})}
        onCreate={record => create(record)}
        onClone={id => clone(null, { cloneable_type: 'fields', cloneable_id: id })}
        listSort={ sortByAttributes('name') }
        filterRecord={ textSubstringFilter('name') }
        {...this.props}/>
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors : {
    fields : getPageAllById(fields)
  },
  mapDispatchToProps: {
    ...fields.actions,
    ...cloneRecords.actions
  }
});

export default withSearch(withPagination(connectedComponent));
