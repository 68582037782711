import React, { useState, useEffect }     from 'react';
import { Form, Segment, Select, Message } from 'semantic-ui-react';
import _                                  from 'lodash';
import { Redirect }                       from 'react-router-dom';

import Fixed                    from 'components/Fixed';
import connectForm              from 'utils/connectForm';
import connectResource          from 'utils/connectResource';
import { getAllById, getById }  from 'utils/connectors';

import OrganizationConfigForm from 'caseWorthy/components/OrganizationConfigForm';

import useApiResource   from 'hooks/useApiResource';
import TimeZoneResource from 'resources/TimeZoneResource';

import { organizations as OrganizationsResource,
         pricing_tiers as PricingTiersResource,
         org_users     as OrganizationUsersResource,
         users         as UsersResource,
         forms         as FormsResource } from 'resources/adminResources';

const uniqueUserOptions = (userOptions) => _.uniqBy(userOptions, 'value');

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const FormView = ({ selected,
                    data,
                    loading,
                    saving,
                    api_errors,
                    onUpdateState,
                    onUpdateValue,
                    onSave,
                    dispatch,
                    match }) => {

  const [userOptions, setUserOptions]       = useState([]);
  const [isFetching, setIsFetching]         = useState(false);

  const timeZones       = useApiResource(TimeZoneResource.listShape());
  const timeZoneOptions = timeZones.map(({ name }) => ({ text: name, value: name }));

  const orgOwner                      = data.orgOwner;
  const orgUsers                      = data.orgUsers || [];
  const pricingTiers                  = data.pricingTiers || [];
  const forms                         = data.forms || [];
  const isNew                         = match.params.id === undefined;

  const UserOptions = orgUsers.map(({ email }) => ({
    text:   email,
    value:  email
  }));

  const PricingTierOptions = pricingTiers.map(({ name, id }) => ({
    text:   name,
    value:  id
  }));

  useEffect(() => {
    if(orgOwner && !selected.owner_email) {
      onUpdateState({ owner_email: orgOwner.email });
    }

    setUserOptions([{
      text:   orgOwner?.email,
      value:  orgOwner?.email
    }]);
  }, [orgOwner, selected, onUpdateState]);

  const doSearch = _.throttle((value) => {
    if(value) {
      dispatch(
        OrganizationUsersResource.actions.searchByEmail(null, { email: value })
      ).then(() => {
        setIsFetching(false);
      });
    }
  }, 1000);

  const handleSearchChange = (_evt, value) => {
    if(value) {
      setIsFetching(true);
    }

    doSearch(value);
  };

  const handleEnableApiAccessChange = (evt, { checked, name }) => {
    onUpdateValue(evt, { name, value: !!checked });
  };

  const handleAddUserOption = (evt, { value }) => {
    const nextOpts = uniqueUserOptions([...userOptions, { text: value, value }]);

    setUserOptions(nextOpts);
  };

  return (
    <Segment loading={ loading }>
      {api_errors && <Redirect to='./'/>}
      <Form onSubmit={ onSave } warning={ selected.owner_id === null }>
        <Form.Input id='organization-name'
                    label='Name'
                    name='name'
                    value={ selected.name || '' }
                    placeholder='Acme Inc.'
                    onChange={ onUpdateValue } />

        <Form.Input id='organization-slug'
                    label='Subdomain'
                    name='slug'
                    value={ selected.slug }
                    placeholder='acme-inc'
                    onChange={ onUpdateValue } />

        <Form.Field control={ Select }
                    label='Pricing Tier'
                    options={ PricingTierOptions }
                    placeholder='Select Pricing Tier'
                    name='pricing_tier_id'
                    value={ selected.pricing_tier_id }
                    selectOnBlur={ false }
                    onChange={ onUpdateValue } />

        <Form.Field control={ Select }
                    label='Owner Email'
                    loading={ isFetching }
                    search
                    allowAdditions
                    options={ uniqueUserOptions([...UserOptions, ...userOptions ]) }
                    placeholder='Owner Email'
                    noResultsMessage=''
                    name='owner_email'
                    value={ selected.owner_email }
                    onSearchChange={ handleSearchChange }
                    onChange={ onUpdateValue }
                    onAddItem={ handleAddUserOption } />

        <Form.Select  label='Time Zone'
                      options={ timeZoneOptions }
                      name='time_zone'
                      value={ selected.time_zone }
                      onChange={ onUpdateValue } />

        <Form.Checkbox  label='Enable Developer API Access'
                        name='api_access_enabled'
                        onChange={ handleEnableApiAccessChange }
                        checked={ selected.api_access_enabled } />

        <OrganizationConfigForm organization={ selected }
                                forms={ forms }
                                onChange={ onUpdateValue } />

        {
          !isNew && selected.owner_id === null &&
          <Message warning>
            Owner has not yet accepted invitation.
          </Message>
        }


        <Fixed>
          <Form.Button  loading={ saving }
                        type='submit'
                        primary>Save</Form.Button>
        </Fixed>
      </Form>
    </Segment>
  );
};

const initialState = {
  id:                       null,
  name:                     '',
  slug:                     '',
  pricing_tier_id:          '',
  owner_email:              '',
  case_worthy_affiliation:  null
};

const ConnectedForm = connectForm(FormView, {
  getDefault: ({ data }) => ({ ...initialState, ...(data.selected || {}) })
});

export default connectResource(ConnectedForm, [
  {
    selected:     getById(OrganizationsResource, (props)=>props.match.params.id),
    pricingTiers: getAllById(PricingTiersResource),
    forms:        getAllById(FormsResource),
    orgUsers: {
      selectData: (state) => _.values(OrganizationUsersResource.selector(state)),
      fetchData:  () => {}
    }
  }, {
    orgOwner: getById(UsersResource, ({ data: { selected } }) => selected.owner_id)
  }
], { ...OrganizationsResource.actions });
