import React, { Component }            from 'react';
import PropTypes    from 'prop-types';
import { Grid, Header, Form, Checkbox, Loader } from 'semantic-ui-react';
import { formFieldObjectsMapping }  from 'utils/reportDefinitions';
import { chartTypes, chartSettingLabels } from 'constants/reports/Charts';

import { find, compact } from 'lodash';
import ChartConfiguration from './ChartConfiguration';
import MapConfiguration   from './MapConfiguration';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

function fieldOptionsMapping(forms, fields, formCalculations, reportDefinition={}) {
  if (reportDefinition === null) { return []; }
  const { fields: reportFields } = reportDefinition;

  const mapping =
      formFieldObjectsMapping(forms, fields, formCalculations, reportDefinition);

  return reportFields.map(( {form, field, type, agg, label}, index) => {
      const formId = parseInt(form);

      for (const formMap of mapping) {
        const formMapId = parseInt(formMap[0]);

        if ( formMapId === formId || ( Number.isNaN(formId) && Number.isNaN(formMapId) ) ) {
          const fieldMap = find(formMap[1], ['id', field]);
          if (fieldMap === undefined) return {};

          const fieldLabel = label || compact([agg, fieldMap['name']]).join(' ');

          return {
              value: index,
              key: index,
              text: fieldLabel,
              numeric: isFieldNumeric(agg, type, fieldMap['data_type'])
            };
        }
      }

      return {};
  });
}

function isFieldNumeric(agg, field_type, data_type) {
  return !!agg
          || field_type === 'calculation'
          || data_type === 'numeric';
}



const style = {
  marginBottom: '2rem'
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class Configuration extends Component {
  constructor(props) {
    super(props);

    this.handleChange      = this.handleChange.bind(this);
    this.handleSeriesCheck = this.handleSeriesCheck.bind(this);
    this.handleSettingsCheck = this.handleSettingsCheck.bind(this);
  }

  render() {
    const { forms, fields, formCalculations, reportDefinition, currentView, isNew } = this.props;

    const axisOptions = fieldOptionsMapping(forms, fields, formCalculations, reportDefinition);
    const seriesOptions = axisOptions.filter(({value, numeric}) => ( currentView.xaxis !== value && numeric ));
    const chartSettings = (currentView.view_type) ? chartTypes[currentView.view_type].settings : [];

    const currentChartType = (currentView.view_type) ? chartTypes[currentView.view_type].description : '';
    return (
      <React.Fragment>
      {
        axisOptions.length === 0
        ?    <Header  placeholder textAlign='center'>
              <Loader active inline content="Loading configurations." />
            </Header>
        :
      <Form>
      <Header as='h2'>Chart Configuration</Header>

      <Grid.Row style={style}>
        <Grid.Column>
          <Form.Input label="Chart Title"
                      value={ currentView.name || '' }
                      name='name'
                      onChange={ this.handleChange } />
        </Grid.Column>
      </Grid.Row>

      { !isNew &&
        <Grid.Row style={style}>
          <Grid.Column>
            <Form.Input label="Chart type"
                        value={ currentChartType }
                        name='view_type'
                        disabled />
          </Grid.Column>
        </Grid.Row>
      }

      { currentView.view_type === 'map' ?
          <MapConfiguration
            currentView={currentView}
            axisOptions={axisOptions}
            onChange={ this.handleChange } />
        :
          <ChartConfiguration
            currentView={currentView}
            axisOptions={axisOptions}
            seriesOptions={seriesOptions}
            onChange={ this.handleChange } />
      }

      { chartSettings.length > 0 &&
        <Grid.Row style={style}>
          <Grid.Column>
            <Header as='h3'>Settings</Header>
            {
              chartSettings.map((setting) =>
                <Form.Field key={setting}>
                  <Checkbox slider
                            label={chartSettingLabels[setting]}
                            value={setting}
                            checked={ currentView.settings.includes(setting) }
                            onChange={ this.handleSettingsCheck } />
                </Form.Field>
                )
            }
          </Grid.Column>
        </Grid.Row>
      }
      </Form>
    }
    </React.Fragment>
      );
  }


  handleChange(evt, { name, value }) {
    const { onChange } = this.props;

    onChange(evt, { name, value });
  }

  handleSeriesCheck(evt, { value, checked }) {
    const { onChange, currentView } = this.props;
    const nextSeries = currentView.series || [];

    if(checked) {
      nextSeries.push(value);
    } else {
      const index = nextSeries.indexOf(value);
      if (index > -1) {
        nextSeries.splice(index, 1);
      }
    }

    onChange(evt, { name: 'series', value: nextSeries });
  }

  handleSettingsCheck(evt, { value, checked }) {
    const { onChange, currentView } = this.props;
    const nextSettings = currentView.settings || [];

    if(checked) {
      nextSettings.push(value);
    } else {
      const index = nextSettings.indexOf(value);
      if (index > -1) {
        nextSettings.splice(index, 1);
      }
    }

    onChange(evt, { name: 'settings', value: nextSettings });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Configuration.propTypes = {
  reportDefinition: PropTypes.shape({
    name:         PropTypes.string,
    description:  PropTypes.string,
    fields:       PropTypes.array,
    conditions:   PropTypes.object
  }).isRequired,

  forms:              PropTypes.array.isRequired,
  fields:             PropTypes.array.isRequired,
  formCalculations:   PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default Configuration;
