import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import { Segment, Header, Grid, Form }  from 'semantic-ui-react';

import Fixed                      from 'components/Fixed';
import { peerOrganizations }      from 'resources/organizationResources';
import { getAll }                 from 'utils/connectors';
import connectResource            from 'utils/connectResource';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class NewFormView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        affiliate_organization_id: null
      }
    };

    this.handleSave   = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const { loading, data }   = this.props;
    const peerOrganizations   = data.peerOrganizations || {};
    const organizationOptions = Object
                                .values(peerOrganizations)
                                .map(({ name, id }) => (
                                  { text: name, value: id }
                                ));

    return (
      <Segment loading={ loading }>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as='h2'>Create an Affiliation</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form onSubmit={ this.handleSave }>
                <Form.Select options={ organizationOptions }
                              label='Affiliate Organization'
                              name='affiliate_organization_id'
                              placeholder='Select an affiliate'
                              onChange={ this.handleChange }
                              selectOnBlur={ false }
                              clearable
                              search />

                <Fixed>
                  <Form.Button  type='submit'
                                loading={ loading }
                                primary>Save</Form.Button>
                </Fixed>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  handleSave(evt) {
    evt.preventDefault();
    const { onCreate }  = this.props;
    const { form }      = this.state;

    return onCreate(form);
  }

  handleChange(evt, { name, value }) {
    const { form }  = this.state;
    const nextForm  = { ...form, [name]: value };

    this.setState({ form: nextForm });
  }

}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

NewFormView.defaultProps = {
  loading: false
};

NewFormView.propTypes = {
  loading:  PropTypes.bool,

  data: PropTypes.shape({
    peerOrganizations: PropTypes.object
  }).isRequired,

  onCreate: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(NewFormView, {
  connectors: {
    peerOrganizations: getAll(peerOrganizations)
  }
});
