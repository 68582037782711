/* CRUD resource actions */
import _ from 'lodash';
import {
  mergeNormalizeReducer,
  destroyReducer
} from '../utils/reducers';



export const index = {
  method : 'GET',
  path : '/',
  response : (state, action) => {
    return {
      ...mergeNormalizeReducer(state, action),
      _indexed: (new Date()).getTime()
    };
  }
};

export const paginatedIndex = {
  method : 'GET',
  path : '/page/:page',
  response: (state, action) => {
    const { scope: { page=1 }={} } = action;
    const nextState = page === 1
                      ? {}
                      : state;
    return {
      ...mergeNormalizeReducer(nextState, action),
      _indexed: (new Date()).getTime()
    };
  }
};

export const hardIndex = {
  method : 'GET',
  path : '/',
  response : (state, action) => {
    return {
      ...mergeNormalizeReducer({}, action),
      _indexed : (new Date()).getTime()
    };
  }
};

export const show = {
  method : 'GET',
  path : '/:id',
  mapResponse : payload => ({...payload, _indexed : true}),
  response : mergeNormalizeReducer
};

export const create = {
  method:   'POST',
  response: mergeNormalizeReducer
};

export const update = {
  method:     'PUT',
  path:       '/:id',
  mapRequest: payload => JSON.stringify(_.omit(payload, '_indexed')),
  response:   mergeNormalizeReducer
};

export const destroy = {
  method:   'DELETE',
  path:     '/:id',
  response: destroyReducer
};
