import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import debounce             from 'debounce-promise';
import { Search, Form }     from 'semantic-ui-react';

import addressAutocomplete, { getAddressDetails }  from 'utils/addressAutocomplete';

import AddressComponent     from './AddressComponent';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const searchDebounce = 700;

const defaultValue = {
  text:       '',
  components: {},
  location:   [],
  ignoring:   []
};

const search = debounce(async (value) => {
  const { error, addresses=[] } = await addressAutocomplete(value);

  if(error) {
    console.warn(error); // eslint-disable-line no-console
  }

  return addresses.map(({ text, placeId }) => ({ placeid: placeId, title: text }));
}, searchDebounce);

function componentsToText({
    streetAddress='', unitNumber='', city='', state='', postalCode=''}) {

  return `${streetAddress} ${unitNumber}, ${city}, ${state} ${postalCode}`;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Address = ({ name, value, onChange }) => {
  const [results, setResults]     = useState([]);
  const [searching, setSearching] = useState(false);
  const { components }            = { ...defaultValue, ...value };
  const { streetAddress, unitNumber, city, state, postalCode } = components;

  const handleSearchChange = async (evt, { value }) => {
    setSearching(true);
    const results = await search(value);
    
    setResults(results);
    setSearching(false);
  };

  const handleResultSelect = async (evt, { result }) => {
    const { placeid }         = result;
    const { error, address }  = await getAddressDetails(placeid);

    if (error) {
      console.warn(error); // eslint-disable-line no-console
    }
    
    onChange(evt, { value: address });
  };

  const handleComponentChange = (evt, component) => {
    const nextComponents  = { ...components, ...component };
    const text            = componentsToText(nextComponents);
    const nextValue       = { text, components: nextComponents };

    onChange(evt, { name, value: nextValue });
  };

  return (
    <>
      <Form.Group>
        <Search loading={ searching }
                results={ results }
                placeholder='Search'
                onResultSelect={ handleResultSelect }
                onSearchChange={ handleSearchChange }
                style={{ width: '100%', padding: '0 0.5rem' }} />
      </Form.Group>

      <Form.Group>
        <AddressComponent value={ streetAddress }
                          name='streetAddress'
                          onChange={ handleComponentChange }
                          width={ 12 }
                          placeholder='Address'
                          label='Address'
                          fluid />

        <AddressComponent value={ unitNumber }
                          name='unitNumber'
                          onChange={ handleComponentChange }
                          width={ 4 }
                          placeholder='Apartment #'
                          label='Apartment #' />
      </Form.Group>

      <Form.Group widths='equal'>
        <AddressComponent value={ city }
                          name='city'
                          onChange={ handleComponentChange }
                          placeholder='City'
                          label='City' />

        <AddressComponent value={ state }
                          name='state'
                          onChange={ handleComponentChange }
                          placeholder='State'
                          label='State' />

        <AddressComponent value={ postalCode }
                          name='postalCode'
                          onChange={ handleComponentChange }
                          placeholder='Zip'
                          label='Zip' />
      </Form.Group>
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Address.defaultProps = {
  value:    defaultValue,
  metadata: {},
  loading:  false
};

Address.propTypes = {
  value:    PropTypes.object,
  metadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Address;
