import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';
import { Button, Header, Segment }  from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class ActionsCard extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  // handlers
  handleClick() {
    const { record, onUpdate } = this.props;
    const state_transition     = 'enqueue';
    onUpdate({ ...record, state_transition });
  }

  // helpers
  isDisabled() {
    const href = window.location.href;
    const last = href.split('/').reverse()[0];
    return (last === 'edit');
  }

  // rendering
  render() {
    const { loading } = this.props;
    const disabled    = this.isDisabled();

    return (
      <Segment loading={ loading }>
        <Header as='h3'>
          Actions
        </Header>
        <Button basic
                primary
                size='small'
                disabled={ disabled }
                onClick={ this.handleClick }>
          Start Import
        </Button>
      </Segment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

ActionsCard.propTypes = {
  loading:  PropTypes.bool.isRequired,
  record:   PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ActionsCard;
