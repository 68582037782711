import React, { useState }        from 'react';
import PropTypes                  from 'prop-types';
import { Button, Popup, Confirm } from 'semantic-ui-react';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const title = 'Delete these credentials';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const DeleteButton = ({ id, onDestroy }) => {
  const [opened, setOpened] = useState(false);

  const handleClick = () => {
    setOpened(true);
  };

  const handleCancel = () => {
    setOpened(false);
  };

  const handleConfirm = () => {
    onDestroy(id);
    setOpened(false);
  };

  const Btn = <Button icon='remove'
                      basic
                      color='red'
                      size='mini'
                      onClick={ handleClick } />;

  return (
    <>
      <Confirm  open={ opened }
                header={ title }
                onCancel={ handleCancel }
                onConfirm={ handleConfirm } />

      <Popup  trigger={ Btn }
              content={ title }
              position='top center' />
    </>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

DeleteButton.propTypes = {
  id:         PropTypes.number.isRequired,
  onDestroy:  PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default DeleteButton;