import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Input, Icon }      from 'semantic-ui-react';

//---------------------------------------------------------
// Component Definition
//---------------------------------------------------------

const arrows = [38, 40]; // up and down arrow key codes

class Number extends Component {

  // We're using this feature of React to ensure the initial
  // state loads correctly. Probably there's a better way to
  // do this overall, but this is the least invasive way to
  // ensure we pick up the initial value from the many,
  // many prop changes we get when the form loads the
  // associated record's data.
  //
  // To limit the long-term expense of this function, we only
  // modify state when the converted value differs from our
  // internal state. This should make our intercessions useful
  // for picking up the initial data load and a noop for any
  // user actions.
  //
  // To allow the parent to differentiate between blank values
  // and zeroes, we need to convert the parent's idea of a
  // blank value (i.e., null) into something that will render
  // in a number field (i.e., empty string). /jdugan
  static getDerivedStateFromProps(props, state) {
    const value = (props.value === null || props.value === undefined)
                    ? ''
                    : props.value;

    if (value !== state.value) {
      return { value };
    }
    return null;
  }

  // initialisation
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // event handlers
  handleChange(evt) {
    const { name, onChange }              = this.props;
    const { target: { value: rawValue } } = evt;

    // To allow the parent to differentiate between blank
    // values and zeros, we need to convert our representation
    // of a blank value (i.e., empty string) to the parent's
    // idea of an blank value (i.e., null).  /jdugan
    const value = (rawValue === undefined || rawValue === '' || rawValue === ' ')
                    ? null
                    : rawValue;

    this.setState({ value }, () => {
      onChange(evt, { name, value });
    });
  }

  // rendering
  render() {
    const { metadata }          = this.props;
    const { format, max, min }  = metadata;
    const { value }             = this.state;

    const isCurrency  = (format === 'usd');

    const numberInput = (
      <input type='number'
             value={ value }
             min={ min }
             max={ max }
             onKeyDown={(e) => { if (arrows.includes(e.which)) { e.preventDefault(); } }}
             onWheel={(e) => { e.target.blur(); }}
             onChange={ this.handleChange } />
    );

    if (isCurrency) {
      return (
        <Input iconPosition='left'>
          <Icon name='dollar' />
          { numberInput }
        </Input>
      );
    } else {
      return numberInput;
    }
  }
}

//---------------------------------------------------------
// PropTypes
//---------------------------------------------------------

Number.defaultProps = {
  value:    '',
  metadata: { min: undefined, max: undefined, format: 'integer' }
};

Number.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),

  metadata: PropTypes.shape({
    min:  PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max:  PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    format: PropTypes.oneOf(['integer', 'decimal', 'usd'])
  }),

  onChange: PropTypes.func.isRequired
};

//---------------------------------------------------------
// Exports
//---------------------------------------------------------

export default Number;
