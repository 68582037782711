import React      from 'react';
import PropTypes  from 'prop-types';
import { Link }   from 'react-router-dom';

import DefaultRenderer        from 'components/renderers/DefaultRenderer';
import { hasAggs, recordRef } from 'utils/reportDefinitions';
import AnswerRenderer         from 'components/fields/AnswerRenderer';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const ResponseIdRenderer = ({ value, internalFormId, internalFormInstanceId }) => {
  if (typeof value === 'string' && value.startsWith('http')) {
    return <AnswerRenderer answer={{value, field_type: 'hyperlink'}} />;
  } else {
    const url = `/organization/internal_form_responses/${internalFormId}/records/${internalFormInstanceId}`;
    return ( <Link to={ url } target='_blank' title={ value }>
                { value }
             </Link> );
  }
};


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const InternalFormReportCellRenderer = ({ reportDefinition, headers, record, value, columnIndex }) => {

  if(hasAggs(reportDefinition)) {
    return (
      <DefaultRenderer  record={ record }
                        value={ value }
                        columnIndex={ columnIndex } />
    );
  } else {
    const refJSON = recordRef(reportDefinition, false, headers, record, columnIndex);

    try {
      const { form_id, response_id } = refJSON && JSON.parse(refJSON);
      return (
        <ResponseIdRenderer value={ value }
                            internalFormId={ form_id }
                            internalFormInstanceId={ response_id } />
      );
    } catch(e) {
      return (
        <DefaultRenderer  record={ record }
                          value={ value }
                          columnIndex={ columnIndex } />
      );
    }
  }

};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

InternalFormReportCellRenderer.propTypes = {
  value: PropTypes.any
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default InternalFormReportCellRenderer;
