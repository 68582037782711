import React                      from 'react';
import { Form, Segment, Select }  from 'semantic-ui-react';
import { Redirect }               from 'react-router-dom';

import Fixed              from 'components/Fixed';
import connectForm        from 'utils/connectForm';
import connectResource    from 'utils/connectResource';
import { getById }        from 'utils/connectors';
import { pricing_tiers }  from 'resources/adminResources';

const billingOptions = [
  {text : 'Monthly', value : 'Monthly'},
  {text : 'Quarterly', value : 'Quarterly'},
  {text : 'Yearly', value : 'Yearly'}
];

class FormView extends React.Component {
  render() {
    return (
      <Segment loading={this.props.loading}>
        {this.props.api_errors && <Redirect to='./'/>}
        <Form onSubmit={this.props.onSave}>
          <Form.Input id='tier-name'
            label='Name'
            name='name'
            value={this.props.selected.name}
            placeholder='Gold'
            onChange={this.props.onUpdateValue} />

          <Form.Input id='tier-rate'
            label='Rate'
            name='rate_cents'
            value={this.props.selected.rate_cents}
            placeholder='0'
            onChange={this.props.onUpdateValue} />

          <Form.Field
            control={Select}
            label='Period'
            options={billingOptions}
            placeholder='Select Billing Period'
            name='billing_period'
            value={this.props.selected.billing_period}
            onChange={this.props.onUpdateValue}
          />

          <Form.Input id='tier-features'
            label='Features'
            name='features'
            value={this.props.selected.features||''}
            placeholder="A description of this pricing tier's features..."
            onChange={this.props.onUpdateValue} />

          <Fixed>
            <Form.Button  loading={this.props.saving}
                          type='submit'
                          primary>Save</Form.Button>
          </Fixed>
        </Form>
      </Segment>
    );
  }
}

const initialState = {
  id : null,
  name : '',
  rate_cents : '',
  billing_period : '',
  features : ''
};

export default connectResource(connectForm(FormView, {getDefault : (props) => ({...initialState, ...(props.data.selected||{})})}), {
  connectors : {
    selected : getById(pricing_tiers, (props)=>props.match.params.id)
  },
  mapDispatchToProps : {...pricing_tiers.actions}
});
