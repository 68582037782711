import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { List }             from 'semantic-ui-react';

import formattedDate from 'utils/formattedDate';

import './ClientSearchResults.css';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ClientSearchResults = ({ clients, onSelect }) => {
  const [selected, setSelected] = useState();

  const handleSelect = (evt, client) => {
    evt.preventDefault();
    setSelected(client);
    onSelect(client);
  };

  return (
    <List divided className='search-results'>
      {
        clients.map(c => (
          <List.Item key={c.entity_id}
                    as='a'
                    onClick={ (e) => handleSelect(e, c) }
                    className='result'
                    active={ selected == c }>
            <List.Content>
              <List.Header>
                { c.last_name }, { c.first_name } { c.middle_name }
              </List.Header>
              <List.Description>
                <strong>Birth Date:</strong>
                <span className='ml-2'>
                  { formattedDate(c.birth_date, 'date') }
                </span>
              </List.Description>
            </List.Content>
          </List.Item>
        ))
      }
    </List>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ClientSearchResults.defaultProps = {
  clients: []
};

ClientSearchResults.propTypes = {
  clients:  PropTypes.array,
  onSelect: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ClientSearchResults;
