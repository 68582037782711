import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class FormValidatorResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/clients/:client_id/client_forms_validator';

  pk() {
    return 'formValidator';
  }
}
