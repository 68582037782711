import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Form, Select }               from 'semantic-ui-react';

import { intervals }          from 'constants/Calculations';
import indexVariablesByName   from 'utils/calculations/indexVariablesByName';
import {fieldVariableSetter } from 'utils/calculations/variableSetters';
import fieldOptions           from 'utils/fields/dateFieldOptions';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const fieldVariableNameMap  = {
  'start_record_id': 'a',
  'end_record_id':   'b'
};

const equation = 'b - a';

const variableSetters = {
  a: fieldVariableSetter,
  b: fieldVariableSetter
};

function variableFor(variables, formFieldName) {
  const variablesIndex  = indexVariablesByName(variables);
  const variableName    = fieldVariableNameMap[formFieldName];

  return variablesIndex[variableName] || { name: variableName };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class TimeBetweenPanel extends Component {
  constructor(props) {
    super(props);

    this.handleChange             = this.handleChange.bind(this);
    this.handleDisplayUnitChange  = this.handleDisplayUnitChange.bind(this);
  }

  render() {
    const { fields, calculation, calculationType } = this.props;
    const { variables, display_unit } = calculation || {};

    const variableA = variableFor(variables, 'start_record_id');
    const variableB = variableFor(variables, 'end_record_id');

    const startRecorddId  = variableA['record_id'];
    const endRecorddId    = variableB['record_id'];

    return (
      <Fragment>
        <p>
          Calculates the amount of time that has passed between the&nbsp;
          <strong>Start { calculationType }</strong> and the <strong>End { calculationType }</strong>.
          <br />
          The result of the calculation will be displayed using the
          selected <strong>Display Units</strong>.
        </p>

        <Form.Field>
          <label>Start { calculationType }</label>
          <Select options={ fieldOptions(calculationType, fields) }
                  name='start_record_id'
                  value={ startRecorddId }
                  selectOnBlur={ false }
                  onChange={ this.handleChange }/>
        </Form.Field>

        <Form.Field>
          <label>End { calculationType }</label>
          <Select options={ fieldOptions(calculationType, fields) }
                  name='end_record_id'
                  value={ endRecorddId }
                  selectOnBlur={ false }
                  onChange={ this.handleChange }/>
        </Form.Field>

        <Form.Field>
          <label>Display As</label>
          <Select options={ intervals[calculationType] }
                  name='display_unit'
                  value={ display_unit }
                  selectOnBlur={ false }
                  onChange={ this.handleDisplayUnitChange }/>
        </Form.Field>
      </Fragment>
    );
  }

  handleChange(evt, { name, value }) {
    const { calculation, onChange } = this.props;
    const { variables }             = calculation || {};
    const variable                  = variableFor(variables, name);
    const variableName              = fieldVariableNameMap[name];
    const variableSetter            = variableSetters[variableName];
    const nextVariable              = variableSetter(variable, 'record_id', value);

    const nextVariables = Object.values({
      ...indexVariablesByName(variables),
      [variableName]: nextVariable
    });

    const nextCalculation = {
      ...calculation,
      equation,
      variables: nextVariables
    };

    onChange({ calculation: nextCalculation });
  }

  handleDisplayUnitChange(evt, { name, value }) {
    const { calculation={}, onChange } = this.props;

    const nextCalculation = {
      ...calculation,
      equation,
      [name]: value
    };

    onChange({ calculation: nextCalculation });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

TimeBetweenPanel.defaultProps = {
  loading:      false,
  calculation:  {}
};

TimeBetweenPanel.propTypes = {
  loading:    PropTypes.bool,
  calculation:  PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    equation:   PropTypes.string,
    variables:  PropTypes.array
  }),

  fields:     PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    data_type:  PropTypes.string
  })).isRequired,
  onChange:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default TimeBetweenPanel;
