import React, { useState }  from 'react';
import PropTypes            from 'prop-types';

import SelectionModal from './SelectionModal';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const DEFAULT_PLACEHOLDER = 'Press ENTER to search';

const getInitialTerm = (value) => {
  try {
    const data = JSON.parse(value);
    return data.title;
  } catch (e) {
    return '';
  }
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const RefPicker = ({ label, name, value, answers, parametersMapping, metadata, fieldId, onChange }) => {
  const initialDisplayValue               = getInitialTerm(value);
  const [ displayValue, setDisplayValue ] = useState(initialDisplayValue); 
  const [ placeholder, setPlaceholder ]   = useState();
  const [ modalShowing, setModalShowing ] = useState(false);

  const handleClick = () => {
    setModalShowing(true);
  };

  const handleFocus = () => {
    setPlaceholder(DEFAULT_PLACEHOLDER);
  };

  const handleBlur = () => {
    setPlaceholder();
  };

  const handleKeyPress = (evt) => {
    evt.preventDefault();
    if(evt.key === 'Enter') {
      setModalShowing(true);
    }
  };

  const handleModalClose = () => {
    setModalShowing(false);
  };

  const handleSelection = (selection) => {
    const value = JSON.stringify(selection);
    setDisplayValue(selection.title);
    setModalShowing(false);
    onChange(null, { name, value });
  };

  return (
    <>
      <div className='ui search'>
        <div className='ui icon input'>
          <input  autoComplete='off'
                  type='text' 
                  value={ displayValue }
                  readOnly={ true } 
                  className='prompt'
                  placeholder={ placeholder }
                  onClick={ handleClick }
                  onFocus={ handleFocus }
                  onBlur={ handleBlur }
                  onKeyPress={ handleKeyPress } />
          <i aria-hidden='true' className='search icon'></i>
        </div>
      </div>

      <SelectionModal opened={ modalShowing }
                      title={ label } 
                      onClose={ handleModalClose }
                      onSubmit={ handleSelection }
                      answers={ answers }
                      parametersMapping={ parametersMapping }
                      fieldId={ fieldId }
                      metadata={ metadata } />
    </>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

RefPicker.defaultProps  = {};
RefPicker.propTypes     = {
  value:              PropTypes.string,
  name:               PropTypes.string,
  label:              PropTypes.string,
  answers:            PropTypes.object,
  parametersMapping:  PropTypes.object,
  fieldId:            PropTypes.number.isRequired,
  metadata:           PropTypes.object,
  onChange:           PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default RefPicker;