import ApiResource from 'resources/ApiResource';

export default class TimeZoneResource extends ApiResource {
  static urlRoot = '/time_zones';

  name = null;

  pk() {
    return this.name;
  }
}