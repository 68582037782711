import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import MaskedInput          from 'react-maskedinput';
import moment               from 'moment';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const defaultFormat = '24-hour';

const timeFormats = {
  '24-hour': {
    'pattern':      'HH:mm',
    'mask':         '11:11',
    'placeholder':  '--:--'
  },
  '12-hour': {
    'pattern':      'hh:mm A',
    'mask':         '11:11 AA',
    'placeholder':  '--:-- --'
  }
};

// Given a time string and a format string (12-hour|24-hour), checks the time
// string against the pattern for the format.  Returns true if valid;
// false if invalid.
function isValidTime(time, format) {
  if(time === null || time === undefined) {
    return true;
  }

  const { [format]: { pattern } } = timeFormats;
  return moment(time, pattern, true).isValid();
}

// Converts `time` from `fromFormat` to `toFormat`
function formatTime(time, { fromFormat, toFormat }) {
  if(time === null || time === undefined || fromFormat === toFormat) {
    return time;
  }

  const { [fromFormat]: { pattern: fromPattern },
          [toFormat]:   { pattern: toPattern } } = timeFormats;

  return moment(time, fromPattern).format(toPattern);
}

// Given a time string and a format string (12-hour|24-hour), converts the time
// string to the default format, and returns the converted time.  If time is
// empty, or is already in the default format, returns time as-is.
function normalizeTime(time, format) {
  return formatTime(time, { fromFormat: format, toFormat: defaultFormat });
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class TimeSelect extends Component {
  constructor(props) {
    super(props);

    this.handleBlur   = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    const { value, format, prepopulate } = this.props;

    // Handles prepopulation.
    if((value === null || value === undefined) && prepopulate) {
      const { [format]: { pattern } } = timeFormats;
      const defaultValue              = moment().format(pattern);

      this.update(defaultValue);
    }
  }

  render() {
    const { format, value }                   = this.props;
    const { [format]: { mask, placeholder } } = timeFormats;

    // in-bound values should always be 24-hour format.  Ensure the value
    // is rendered in the user-specified format.
    const formattedValue  = formatTime(value, {
                              fromFormat: defaultFormat,
                              toFormat:   format
                            });

    return (<MaskedInput  mask={ mask }
                          placeholder={ placeholder }
                          value={ formattedValue }
                          onBlur={ this.handleBlur }
                          onChange={ this.handleChange } />);
  }

  handleBlur(evt) {
    const { value } = evt.target;
    this.update(value, { showValidationErrors: true });
  }

  handleChange(evt) {
    const { format }  = this.props;
    const { value }   = evt.target;

    if(isValidTime(value, format)) {
      this.update(value);
    }
  }

  update(value, { showValidationErrors=false }={}) {
    const { onChange, format } = this.props;

    if(showValidationErrors) {
      if(isValidTime(value, format)) {
        onChange({ value: normalizeTime(value, format) });
      } else {
        onChange({ value: null, error: true });
      }
    } else {
      onChange({ value: normalizeTime(value, format) });
    }
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

TimeSelect.defaultProps = {
  format:       defaultFormat,
  value:        null,
  prepopulate:  false
};

TimeSelect.propTypes = {
  format:       PropTypes.oneOf(['12-hour', '24-hour']),
  value:        PropTypes.string,
  prepopulate:  PropTypes.bool,
  onChange:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default TimeSelect;
