// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const defaultOptions = {
  simplify: false
};

const falseValues = [
  false,
  0,
  '0',
  'f',
  'F',
  'false',
  'FALSE',
  'no',
  'NO'
];

const simple = {
  'string_array':   'string',
  'numeric_array':  'numeric',
  'boolean_array':  'boolean'
};

const casters = {
  'string':         (value) => (
                      (value !== null && value !== undefined)
                      ? value.toString()
                      : ''
                    ),
  'numeric':        (value) => (+value),
  'boolean':        (value) => (!falseValues.includes(value)),
  'string_array':   (value) => ([].concat(value).map((v) => (casters.string(v)))),
  'numeric_array':  (value) => ([].concat(value).map((v) => (casters.numeric(v)))),
  'boolean_array':  (value) => ([].concat(value).map((v) => (casters.boolean(v))))
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

// NOTE: the simplify option casts inbound array values to their single-value
// types. E.g. [string] -> string.
export default function cast(value, type, options=defaultOptions) {
  const { simplify, castMissingValue }  = options;

  // if 'castMissingValue' is not truthy, and the value is null or undefined,
  // return the value as-is.
  if(!castMissingValue && (value === null || value === undefined)) {
    return value;
  }

  let castFn;

  if(simplify) {
    const simpleType  = simple[type] || type;
    castFn            = casters[simpleType];
  } else {
    castFn            = casters[type];
  }

  if(castFn) {
    return castFn(value);
  } else {
    return value;
  }
}
