import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table, Checkbox }  from 'semantic-ui-react';

import { contentNamespace } from '../../../../utils/content';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class BodyHeaderCell extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  content = contentNamespace('organization.permissions.entities')

  render() {
    const { entity, disabled } = this.props;
    return (
      <Table.Cell className='header-cell'>
        <label>
          <Checkbox disabled={ disabled }
                    checked={ this.isChecked() }
                    onChange={ this.handleChange }
                    label={ this.content(entity) } />

        </label>
      </Table.Cell>
    );
  }

  isChecked() {
    const { privileges, entity, actions } = this.props;
    const privilegeActions                = privileges[entity] || [];

    return privilegeActions.length === actions.length;
  }

  handleChange(_, attrs) {
    const { disabled, entity,  actions, onChange }  = this.props;
    const { checked }                               = attrs;
    const entities                                  = [entity];

    onChange({ entities, actions, checked, disabled });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

BodyHeaderCell.propTypes = {
  entity:     PropTypes.string.isRequired,
  actions:    PropTypes.arrayOf(PropTypes.string).isRequired,
  privileges: PropTypes.objectOf(
                PropTypes.arrayOf(PropTypes.string)
              ).isRequired,
  disabled:   PropTypes.bool,
  onChange:   PropTypes.func
};

BodyHeaderCell.defaultProps = {
  disabled: true,
  onChange: () => {}
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default BodyHeaderCell;
