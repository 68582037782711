import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Form, Header, Segment }  from 'semantic-ui-react';

import _                          from 'lodash';

import ColumnId                   from './ColumnId';
import Mappings                   from './Mappings';

import Fixed  from 'components/Fixed';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class AdvancedForm extends Component {

  // derived state
  static getDerivedStateFromProps(props, state) {
    const pValue = props.record;
    const sValue = state.prevRecord;

    if (!_.isEqual(pValue, sValue)) {
      return {
        record: pValue,
        prevRecord: pValue
      };
    }
    return null;
  }

  // construction
  constructor(props) {
    super(props);

    this.state = {
      record: {},
      prevRecord: null
    };

    this.handleChangeToClientId   = this.handleChangeToClientId.bind(this);
    this.handleChangeToMappings   = this.handleChangeToMappings.bind(this);
    this.handleChangeToResponseId = this.handleChangeToResponseId.bind(this);
    this.handleSubmit             = this.handleSubmit.bind(this);
  }

  // handlers
  handleChangeToClientId(client_id_column) {
    const { record } = this.state;
    this.setState({
      record: { ...record, client_id_column }
    });
  }
  handleChangeToMappings(mapping) {
    const { record }             = this.state;
    const { destination_id: id } = mapping;
    const mappingsObject         = this.getMappingsObject();
    const newMappingsObject      = { ...mappingsObject, [id]: mapping };
    const mappings               = Object.values(newMappingsObject);
    this.setState({
      record: { ...record, mappings }
    });
  }
  handleChangeToResponseId(response_id_column) {
    const { record } = this.state;
    this.setState({
      record: { ...record, response_id_column }
    });
  }
  handleSubmit(evt) {
    evt.preventDefault();
    const { onSubmit } = this.props;
    const { record }   = this.state;
    onSubmit(record);
  }

  // helpers
  getDestinationsArray() {
    const { record }       = this.state;
    const { destinations } = record;
    const values = Object.values({ ...destinations });
    const sorted = _.sortBy(values, v => v.position);
    return sorted;
  }
  getMappingsObject() {
    const { record }      = this.state;
    const { mappings=[] } = record;
    return mappings.reduce((object, item) => {
      const { destination_id: key } = item;         // translate array to
      object[key] = item;                           // object keyed by
      return object;                                // destination_id
    }, {});
  }
  getOriginsObject() {
    const { record }  = this.state;
    const { origins } = record;
    return { ...origins };
  }
  getClientIdColumn() {
    const { record } = this.state;
    return record.client_id_column;
  }
  getResponseIdColumn() {
    const { record } = this.state;
    return record.response_id_column;
  }
  getResponseIdNeeded() {
    const { record } = this.state;
    return record.type !== 'client';
  }

  // rendering
  render() {
    const { loading, saving } = this.props;
    const destinations        = this.getDestinationsArray();
    const mappings            = this.getMappingsObject();
    const origins             = this.getOriginsObject();
    const clientIdColumn      = this.getClientIdColumn();
    const responseIdColumn    = this.getResponseIdColumn();
    const needsResponseId     = this.getResponseIdNeeded();

    return (
      <Segment loading={ loading }>
        <Form onSubmit={ this.handleSubmit }>
          <Header as='h3'>
            Mappings
          </Header>

          <ColumnId origins={ origins }
                    label='Client ID Column'
                    columnId={ clientIdColumn }
                    onChange={ this.handleChangeToClientId } />

          {
            needsResponseId &&
            <ColumnId origins={ origins }
                      label='Response ID Column'
                      columnId={ responseIdColumn }
                      onChange={ this.handleChangeToResponseId } />
          }

          <Mappings destinations={ destinations }
                    origins={ origins }
                    mappings={ mappings }
                    onChange={ this.handleChangeToMappings } />

          <Fixed>
            <Form.Button  primary
                          loading={ saving }
                          type='submit'>Save</Form.Button>
          </Fixed>

        </Form>
      </Segment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

AdvancedForm.propTypes = {
  loading:        PropTypes.bool.isRequired,
  saving:         PropTypes.bool.isRequired,
  record:         PropTypes.object.isRequired,
  onSubmit:       PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default AdvancedForm;
