import React, { useState }              from 'react';
import PropTypes                        from 'prop-types';
import { Redirect }                     from 'react-router-dom';
import { Segment, Header, Grid, List }  from 'semantic-ui-react';

import Privileges                 from 'constants/Privileges';
import PrintLink                  from 'components/PrintLink';
import ResponseForm               from 'containers/ResponseForm';
import WithPermission             from 'containers/WithPermission';
import { formDataRequestMapper }  from 'utils/requestMappers';

import useOrganizationResource    from 'hooks/useOrganizationResource';
import useOrganizationFetcher     from 'hooks/useOrganizationFetcher';
import PublicFormResponseResource from 'resources/organization/PublicFormResponseResource';
import FormResource               from 'resources/organization/FormResource';

import ClientLinkView from '../../ClientLinkView';
import DeleteLink     from './DeleteLink';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function saveHandler({ responseId, publicFormId, setRedirectPath, update }) {
  return async (answers, done) => {

    const params    = { public_form_id: publicFormId, id: responseId };
    const body      = formDataRequestMapper({ answers });
    const response  = await update(params, body);

    if(response) {
      setRedirectPath(`/organization/public_responses/${publicFormId}/responses/${responseId}`);
    }
    done();
  };
}

function deleteHandler({ responseId, publicFormId, form, setRedirectPath, destroy }) {
  return async () => {
    const params = { public_form_id: publicFormId, id: responseId };
    destroy(params);

    setRedirectPath(`/organization/public_responses/${form.id}`);
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CoreEditResponseForm = ({ match, setRedirectPath }) => {
  const [changes, setChanges] = useState();
  const { params: { id: publicFormId, responseId } } = match;

  const publicFormResponse  = useOrganizationResource(
                                PublicFormResponseResource.detailShape(),
                                { public_form_id: publicFormId, id: responseId }
                              );

  const form        = useOrganizationResource(
                        FormResource.detailShape(),
                        { id: publicFormResponse.form_id }
                      );

  const update        = useOrganizationFetcher(PublicFormResponseResource.updateShape());
  const destroy       = useOrganizationFetcher(PublicFormResponseResource.deleteShape());
  const handleSave    = saveHandler({
                          responseId,
                          publicFormId,
                          setRedirectPath,
                          update
                        });

  const handleDelete  = deleteHandler({
                          responseId,
                          publicFormId,
                          form,
                          setRedirectPath,
                          destroy
                        });

  if(form === null || form === undefined) {
    return <div/>;
  }

  const { answers } = publicFormResponse;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={ 10 } className='printable-full'>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header as='h2'>{ form.name }</Header>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header as='h3' className='printable-hidden'>Edit Response</Header>
                  <div style={{ marginTop: '2rem' }}>

                    <ResponseForm answers={ answers }
                                  form={ form }
                                  onSave={ handleSave }
                                  onChange={ setChanges } />

                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={ 6 } className="printable-hidden">
          <ClientLinkView publicFormInstanceId={ responseId }
                          answers={ answers }
                          changes={ changes } />
          <Segment>
            <List>
              <List.Item>
                <List.Icon name='print' />
                <List.Content>
                  <PrintLink />
                </List.Content>
              </List.Item>
              <WithPermission resource='public_forms'
                              actions={ Privileges.remove }
                              recordId={ form.id }>
                <List.Item>
                  <List.Icon name='delete' />
                  <List.Content>
                    <DeleteLink onDelete={ handleDelete } />
                  </List.Content>
                </List.Item>
              </WithPermission>
            </List>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const EditResponseForm = (props) => {
  const [redirectPath, setRedirectPath] = useState();

  return  redirectPath
          ? <Redirect to={ redirectPath } />
          : <CoreEditResponseForm {...props} setRedirectPath={ setRedirectPath } />;
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditResponseForm.propTypes = {
  match:    PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditResponseForm;
