import React, { useState, useEffect, useCallback } from 'react';

import { Form, Message }       from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import debounce       from 'lodash/debounce';

import DateSelect         from 'components/DateSelect';
import useApiResource     from 'hooks/useApiResource';
import useApiFetcher      from 'hooks/useApiFetcher';
import UserResource       from 'resources/admin/UserResource';
import ItemTypeResource   from 'resources/admin/ItemTypeResource';
import EventTypeResource  from 'resources/admin/EventTypeResource';

const defaultSearchQuery = { page: 1 };

const RequiredFieldIndicator = () => (
  <span style={{ 'color': 'red', 'marginLeft': '0.25rem' }}>*</span>
);

const ErrorMessage = (errors) => {
  console.log({errors});
  if(Object.keys(errors).length === 0) {
    return null;
  }

  const errorMessages = Object.entries(errors).map(([key, value]) => (
    <Message.Item key={ key }>{ value }</Message.Item>
  ));

  return (
    <Message negative>
      <Message.Header>There were some errors with your submission</Message.Header>
      <Message.List>
        { errorMessages }
      </Message.List>
    </Message>
  );
};

const validateSearchParams = (params) => {
  const errors = {};
  console.log({
    params:   params.toString(),
    itemType: params.get('itemType'),
    event:    params.get('event'),
    startDate: params.get('startDate'),
    endDate:   params.get('endDate'),
    userId:    params.get('userId')
  });

  if(!params.get('item_type')) {
    errors.itemType = 'Please select a record type';
  }

  if(!params.get('event')) {
    errors.event = 'Please select an event';
  }

  if(!params.get('start_date') && !params.get('end_date')) {
    errors.dates = 'Please select a start date and/or end date';
  }

  return errors;
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const FormView = (/*props*/) => {
  const history = useHistory();
  const [itemType, setItemType]       = useState('');
  const [event, setEvent]             = useState('');
  const [startDate, setStartDate]     = useState('');
  const [endDate, setEndDate]         = useState('');
  const [userId, setUserId]           = useState('');
  const [users, setUsers]             = useState([]);
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [errors, setErrors]           = useState({});
  
  const eventTypes      = useApiResource(EventTypeResource.listShape(), {});
  const itemTypes       = useApiResource(ItemTypeResource.listShape(), {});
  let getUsers          = useApiFetcher(UserResource.listShape(), true);

  // ignore the eslint warning here, we want to debounce the function.
  // There are no dependencies.
  // 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  getUsers = useCallback(debounce(getUsers, 500, { leading: true }), []);

  useEffect(() => {
    const retrieveUsers = async () => {
      const userRecords = await getUsers(searchQuery);
      setUsers(userRecords);
    };

    retrieveUsers();
  }, [searchQuery, getUsers]);
  
  const itemTypeOptions = itemTypes.map(({ label, value }) => (
                            { text: label, value }
                          ));

  const userOptions     = users?.map(({ first_name, last_name, id }) => (
                            { 
                              text:   `${first_name} ${last_name}`, 
                              value:  id 
                            }
                          ));
  const setters = {
    itemType:   setItemType,
    event:      setEvent,
    startDate:  setStartDate,
    endDate:    setEndDate,
    userId:     setUserId
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const params  = new URLSearchParams({
                      item_type:  itemType,
                      event:      event,
                      start_date: startDate,
                      end_date:   endDate,
                      user_id:    userId
                    });

    const errors = validateSearchParams(params);
    console.log(errors);
    setErrors(errors);

    if(Object.keys(errors).length > 0) {
      return;
    }

    history.push(`/admin/versions/results?${params}`);
  };

  const handleChange = (_, { name, value }) => {

    // So, when we press the clear button on the search field,
    // rather than Semantic UI invoking onSearchChange, it calls
    // on change.  As such, we have to manage the search query
    // state here and in onSearchChange.
    if(value === '') {
      setSearchQuery(defaultSearchQuery);
    }

    const setStateValue = setters[name];
    setStateValue?.(value);
  };

  const handleDateChange = (value, name) => {
    handleChange(null, { name, value });
  };

  const handleUserSearchChange  = (_evt, { searchQuery }) => {
    console.log({searchQuery});
    const nextSearch = { query: searchQuery, ...defaultSearchQuery };

    setSearchQuery(nextSearch);
  };

  return (
    <>
      <ErrorMessage { ...errors } />

      <Form onSubmit={ handleSubmit }>
        <Form.Select  name='itemType'
                      value={ itemType }
                      label='Record Type'
                      options={ itemTypeOptions }
                      onChange={ handleChange }
                      required
                      clearable />

        <Form.Group inline>
          <label>
            Action that occurred:
            <RequiredFieldIndicator />
          </label>
          {
            eventTypes.map(({ label, value }) => (
              <Form.Radio key={ value }
                          label={ label }
                          name='event' 
                          value={ value }
                          checked={ event === value }
                          onChange={ handleChange } />    
            ))
          }
        </Form.Group>

        <Form.Group inline>
          <label>
            Occurred between:
            <RequiredFieldIndicator />
          </label>
          <DateSelect name='startDate' 
                      value={ startDate }
                      onChange={ handleDateChange } />

          <label style={{ 'margin': '0 0.5rem'}}>and</label>
          <DateSelect name='endDate' 
                      value={ endDate }
                      onChange={ handleDateChange } />
        </Form.Group>

        <Form.Select  name='userId'
                      value={ userId }
                      label='Action performed by (optional)'
                      options={ userOptions }
                      onChange={ handleChange }
                      onSearchChange={ handleUserSearchChange }
                      selectOnBlur={ false }
                      clearable
                      search />

        <Form.Button primary>Search</Form.Button>
      </Form>
    </>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

FormView.defaultProps  = {};
FormView.propTypes     = {};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default FormView;