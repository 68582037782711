import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import { Grid, Form, Header }  from 'semantic-ui-react';
import FieldSelection             from './QueryFieldSelection';
import { getAllById }  from 'utils/connectors';
import connectResource          from 'utils/connectResource';
import { reportDefinitions } from 'resources/organizationResources';


// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class QueryFieldSelection extends Component {
  constructor(props) {
    super(props);

    this.handleReportChange = this.handleReportChange.bind(this);
  }

  render() {

    const { data,
            forms,
            fields,
            formCalculations,
            reportDefinition,
            users,
            functionType,
            onAddField,
            onChange,
            onChangeWindow,
            onFunctionTypeChange }  = this.props;

    const reportDefs              = data.reportDefs || {};
    const { report_target_id }    = reportDefinition || {};

    const report = report_target_id ?
                    reportDefs.find(({ id }) => id === report_target_id) :
                    null;

    const reportOptions     = reportDefs
                            .map(({ id, name }) => ({
                              value:  id,
                              text:   name
                            }))
                            .sort(({ text: a }, { text: b }) => a > b ? 1 : -1);


    return (
      <Form>
        <Header as='h2'>Select Report Fields</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              { report ?
                <Header>Selected Report: { report.name }</Header>
                :
                <Form.Select  label='Choose a report to select one or more of its fields'
                            selectOnBlur={ false }
                            options={ reportOptions }
                            onChange={ this.handleReportChange }
                            clearable
                            search />
              }
            </Grid.Column>
          </Grid.Row>
          { report &&
            <Grid.Row>
              <Grid.Column>
                <FieldSelection reportId={ report_target_id }
                                form={ null }
                                allFields={ fields }
                                formCalculations={ formCalculations }
                                functionType={ functionType }
                                reportDefinition={ reportDefinition }
                                onChange={ onChange }
                                onChangeWindow={ onChangeWindow }
                                onAddField= { onAddField }
                                onAddAllFields= { this.handleAddAllFields }
                                onFunctionTypeChange={ onFunctionTypeChange }
                                forms={ forms }
                                users={ users} />
              </Grid.Column>
            </Grid.Row>
          }

        </Grid>
      </Form>
    );
  }

  handleReportChange(evt, { value }) {
    const { onChangeReportTarget } = this.props;

    this.setState({ currentReport: value });
    onChangeReportTarget(value);
  }

  handleAddAllFields(formId) {
    const { onAddAllFields, forms }  = this.props;

    const currentForm = forms.find(({ id }) => id === formId);
    const newFields = currentForm.field_ids.map((fieldId) => ({
                            form: formId,
                            field: fieldId,
                            type: 'field'
                          }));

    onAddAllFields(newFields);
  }

  closeAlert() {
    this.setState({ showMultiformAlert: false });
  }

  setCurrentForm(formId) {
    const { forms }   = this.props;
    const currentForm = forms.find(({ id }) => id === formId);

    this.setState({ currentForm });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

QueryFieldSelection.defaultProps = {
  reportDefinition:   {},
  forms:              [],
  fields:             [],
  formCalculations:   {}
};

QueryFieldSelection.propTypes = {
  reportDefinition:   PropTypes.object,
  forms:              PropTypes.array,
  fields:             PropTypes.array,
  formCalculations:   PropTypes.object,
  onChange:           PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(QueryFieldSelection, {
  connectors: {
    reportDefs: getAllById(reportDefinitions, ()=>({}), true),
  }
});

