import AuthenticatedResource from 'resources/AuthenticatedResource';
import resourceUrl            from 'utils/resourceUrl';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class PublicFormResponseResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/public_forms/:public_form_id/public_form_instances';

  // Override listUrl to append search params.
  static listUrl(searchParams) {
    const url = resourceUrl(this.urlRoot, '', searchParams);
    url.search = new URLSearchParams(searchParams);
    return url;
  }

  id                = undefined;
  public_form_id    = undefined;
  client_form_id    = undefined;
  completed_at      = null;
  created_at        = null;
  updated_at        = null;
  answers           = null;

  pk() {
    return this.id && this.id.toString();
  }
}
