import React, { Fragment, useState }  from 'react';
import PropTypes            from 'prop-types';

import {
  Segment,
  Header,
  Message,
  Divider,
  Icon,
  Table,
  Button,
  Ref }  from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import CreateButton from './CreateButton';
import ViewModeButtons  from './ViewModeButtons';
import Record       from './Record';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const LogicSettings = ({ loading, logic, fields, onSave, onSaveOrder, onDelete, onDragEnd }) => {
  const [summaryMode, setSummaryMode]   = useState(true);
  const [orderingMode, setOrderingMode] = useState(false);

  return (
    <Segment loading={ loading }>
      <Header as='h3'>Logic Settings</Header>
      <div style={{ marginTop: '2rem' }}>
        {
          logic && logic.length
          ? <DragDropContext onDragEnd={ onDragEnd }>
              <Droppable droppableId="field-option-list">
                {
                  (provided) => (
                    <Ref innerRef={ provided.innerRef }>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Conditions</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>
                    <Button.Group size='mini'>
                      <ViewModeButtons summaryMode={ summaryMode }
                                       onClickSummary={ () => setSummaryMode(!summaryMode) }
                                       onClickOrdering={ () => setOrderingMode(!orderingMode) } />
                      { !orderingMode &&
                        <CreateButton onSubmit={ onSave } fields={ fields } />
                      }
                    </Button.Group>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  logic.map(({ name, condition, actions }, i) => {
                    return(
                      <Draggable  key={ i }
                                  draggableId={ i }
                                  index={ i }>
                        {
                          (provided, snapshot) => (
                            <Ref innerRef={ provided.innerRef }>
                    <Record key={ i }
                            index={ i }
                            fields={ fields }
                            name={ name }
                            condition={ condition }
                            actions={ actions }
                            summaryMode= { summaryMode }
                            onSave={ onSave }
                            onDelete={ onDelete }
                            isDraggable= { orderingMode }
                            draggableProvided={ provided }
                            draggableSnapshot={ snapshot } />
                           </Ref>
                          )
                        }
                      </Draggable>
                  ); } )
                }
              </Table.Body>
            </Table>
                    </Ref>
                  )
                }
              </Droppable>
            </DragDropContext>

          : <Fragment>
              <Message info icon>
                <Icon name='info circle' />
                This form currently has no logic operations defined.
              </Message>
              <Divider horizontal>Add Logic</Divider>
              <p>
                <CreateButton onSubmit={ onSave }
                              fields={ fields } /> Add logic to this form.
              </p>
            </Fragment>
        }

        { orderingMode &&
          <div className='ui right aligned container'>
            <Button style={ { marginTop: '1em' } }
                    primary
                    icon='refresh'
                    labelPosition='left'
                    content='Save order'
                    onClick={ () => { onSaveOrder().then( setOrderingMode(false) ); } } />
          </div>
        }
      </div>
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

LogicSettings.defaultProps = {
  loading:  false,
  logic:    []
};

LogicSettings.propTypes = {
  loading:  PropTypes.bool,
  logic:    PropTypes.arrayOf(
    PropTypes.shape({
      condition:  PropTypes.object,
      actions:    PropTypes.array
    })
  ),
  fields:   PropTypes.array.isRequired,
  onSave:   PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default LogicSettings;
