import React              from 'react';
import { Form, Segment }  from 'semantic-ui-react';
import { Redirect }       from 'react-router-dom';

import connectForm          from 'utils/connectForm';
import { contentNamespace } from 'utils/content';

import Fixed            from 'components/Fixed';
import MetadataFields   from '../MetadataFields';
import FieldOptionList  from './FieldOptionList';
import InternalOptions  from './InternalOptions';

// -----------------------------------------------------
// Helpers & Constants
// -----------------------------------------------------

const fieldContent = contentNamespace('fields');

function getFieldTypeOptions(fieldTypes) {
  const { labels } = fieldContent('fieldTypes');

  return fieldTypes.map((type) => ({
    text:   labels[type] || type,
    value:  type
  }));
}

function getDataTypeOptions(fieldDataTypes, fieldType) {
  const { labels }  = fieldContent('dataTypes');
  const dataTypes   = fieldDataTypes[fieldType] || [];

  return dataTypes.map((type) => ({
    text:   labels[type] || type,
    value:  type
  }));
}

function filterMetadata(field_type, data_type, metadata) {
  if(field_type !== 'checkbox') {
    return metadata;
  } else {
    return Object.keys(metadata)
            .filter((key) => {
                if ((data_type === 'boolean' && key !== 'required_true') ||
                    (data_type !== 'boolean' && key === 'required_true')) {
                  return false;
                }
              return true;
            })
            .reduce((filtered, key) => {
              filtered[key] = metadata[key];
              return filtered;
            }, {});
  }
}


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------


class FormView extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange         = this.handleChange.bind(this);
    this.handleMetadataChange = this.handleMetadataChange.bind(this);
  }

  render() {
    const { loading,
            api_errors,
            saving,
            onSave,
            selected,
            data:{ fieldTypes={} }={} } = this.props;

    const { field_options=[],
            internal_option={},
            name='',
            description='',
            metadata={},
            field_type,
            data_type } = selected || {};

    const { metadata: metadataConfig={},
            field_types=[],
            valid_field_data_types={} }  = fieldTypes;

    const fieldMetadata     = metadataConfig[field_type] || {};
    const filteredMetadata  = filterMetadata(field_type, data_type, fieldMetadata);
    const fieldTypeOptions  = getFieldTypeOptions(field_types) ;
    const dataTypeOptions   = getDataTypeOptions(valid_field_data_types, field_type);

    const { options_source='external' } = metadata;
    const internalOptionsSource = ((field_type === 'select-one' || field_type === 'select-many') && options_source === 'internal');
    const externalOptionsSource = ((field_type === 'select-one' || field_type === 'select-many') && options_source === 'external');

    return (
      <Segment loading={ loading }>
        <Form onSubmit={ onSave } warning={ !field_options.length }>
          { api_errors && <Redirect to='./'/> }

          <Form.Input label='Name'
                      name='name'
                      value={ name }
                      onChange={ this.handleChange } />

          <Form.Input label='Description'
                      name='description'
                      value={ description }
                      onChange={ this.handleChange } />

          <Form.Select  label='Field Type'
                        options={ fieldTypeOptions }
                        placeholder='Select Field Type'
                        name='field_type'
                        value={ field_type }
                        selectOnBlur={ false }
                        clearable
                        search
                        onChange={ this.handleChange } />

          <Form.Select  label='Data Type'
                        options={ dataTypeOptions }
                        placeholder='Select a Data Type'
                        name='data_type'
                        value={ data_type }
                        selectOnBlur={ false }
                        clearable
                        search
                        onChange={ this.handleChange } />

          <MetadataFields value={ metadata || {} }
                        metadata={ filteredMetadata }
                        onChange={ this.handleMetadataChange } />

          {
            externalOptionsSource &&
            <FieldOptionList  options={ field_options }
                              name='field_options'
                              onChange={ this.handleChange } />
          }

          { (internalOptionsSource || field_type === 'autopopulate') &&
            <InternalOptions options={ internal_option }
                             name='internal_option'
                             onChange={ this.handleChange } />
          }

          <Fixed>
            <Form.Button  loading={ saving }
                          primary>Save</Form.Button>
          </Fixed>

        </Form>
      </Segment>
    );
  }

  handleChange(evt, { name, value }) {
    const { onUpdateState } = this.props;
    onUpdateState({ [name]: value });
  }

  handleMetadataChange(evt, { value }) {
    const { selected, onUpdateValue } = this.props;
    const metadata = Object.assign({}, selected.metadata, value);

    onUpdateValue(evt, { name: 'metadata', value: metadata });
  }
}

const initialState = {
  id:             null,
  name:           '',
  description:    '',
  metadata:       {},
  field_type:     null,
  data_type:      null,
  field_options:  [],
  internal_option: {}
};

export default connectForm(FormView, {
  getDefault: ({ data }) => ({ ...initialState, ...(data.selected || {}) }),
  mapOnSave: (selected) => {
    return {
      ...selected,
      field_options : selected.field_options.filter(o => !o.new)
    };
  }
});
