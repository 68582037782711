import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { Prompt }           from 'react-router';
import { Form }             from 'semantic-ui-react';
import { isEmpty }          from 'lodash';

import Fixed                    from 'components/Fixed';
import indexOnAttribute         from 'utils/indexOnAttribute';
import calculateDisplayState    from 'utils/formLogic/calculateDisplayState';
import evaluateCalculations     from 'utils/calculations/evaluateCalculations';
import wrapCalculationId        from 'utils/calculations/wrapId';
import BaseField                from 'components/forms/Field';
import Field                    from './Field';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const promptTitle = 'You have unsaved changes, are you sure you want to leave?';

function renderableFields(formFields, form) {
  if(formFields === null || formFields === undefined || formFields.length === 0) {
    return [];
  }

  const orderedFormFields = form.form_fields;

  if(orderedFormFields === null || orderedFormFields === undefined) {
    return [];
  }

  const indexedFormFields = indexOnAttribute(formFields, 'id');

  // Ensures sort order, and decorates the formFields with is_required and
  // position data.
  return orderedFormFields.map(({ id, is_required, position }) => (
    {
      ...indexedFormFields[id],
      id,
      is_required,
      position
    }
  ));
}

function answersAsParams(answers) {
  return  Object
          .entries(answers)
          .map(([fieldId, answer]) => ({
            field_id: +fieldId,
            value:    answer.value
          }));
}

function submitHandler(answers, onSave, setAnswerChanges, setSubmitting) {
  return () => {
    setSubmitting(true);
    const submittedAnswers  = answersAsParams(answers);

    onSave(submittedAnswers, (success=false) => {
      if(success) { setAnswerChanges({}); }
      setSubmitting(false);
    });
  };
}

function changeHandler(answerChanges, setAnswerChanges, onChange) {
  return ({ field, answer }) => {
    const { id }            = field;
    const changes           = { ...answerChanges, [id]: answer };

    setAnswerChanges(changes);
    onChange && onChange(answersAsParams(changes));
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ResponseForm = ({ form, loading, answers, prePopulate={}, isPublic=false, onSave, onChange, submitButtonTitle='Save' }) => {
  const [ answerChanges, setAnswerChanges ] = useState(prePopulate);
  const [ submitting, setSubmitting ]       = useState(false);
  const { logic, calculations }             = form;

  const formFields    = form.form_fields;
  const fields        = renderableFields(formFields, form);
  const nextAnswers   = { ...answers, ...answerChanges };
  const calculationAnswers = evaluateCalculations(formFields, nextAnswers, calculations);

  const handleSubmit  = submitHandler(nextAnswers, onSave, setAnswerChanges, setSubmitting);
  const handleChange  = changeHandler(answerChanges, setAnswerChanges, onChange);

  const displayState =
    calculateDisplayState(logic, fields, nextAnswers, calculations);

  const hasUnsavedChanges = !isEmpty(answerChanges);

  return (
    <>
      <Prompt when={ hasUnsavedChanges && !submitting }
              message={ promptTitle } />

      <Form loading={ loading }>
        {
          fields.map((field) => (
            <Field  key={ field.id }
                    field={ field }
                    displayState={ displayState[field.id] }
                    error={ false }
                    answer={ nextAnswers[field.id] || {} }
                    answers={ nextAnswers }
                    onChange={ (evt, change) => handleChange(change) } />
          ))
        }

        {
          !isPublic && calculationAnswers
            .filter( ([calc, ]) => {
                const calcDisplayState = displayState[wrapCalculationId(calc.id)] || {};

                return !calcDisplayState['hide'];
              })
            .map(([calc, value]) => (
           <BaseField key={ calc.id }
                  fieldType='freeform-short'
                  label={ calc.name }
                  isDisabled={ true }
                  value={ value } />
          ))
        }

        <Fixed>
          <Form.Button  disabled={ false }
                        loading={ submitting }
                        type='button'
                        onClick={ handleSubmit }
                        primary> { submitButtonTitle } </Form.Button>
        </Fixed>
      </Form>
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ResponseForm.defaultProps = {
  answers: {}
};

ResponseForm.propTypes = {
  form: PropTypes.shape({
    id:           PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    calculations: PropTypes.array,
    logic:        PropTypes.array,
    form_fields:  PropTypes.array
  }).isRequired,

  answers: PropTypes.object,
  prePopulate: PropTypes.object,

  onSave:   PropTypes.func.isRequired,
  onChange: PropTypes.func
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ResponseForm;
