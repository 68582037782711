import React from 'react';

import useOrganizationResource  from 'hooks/useOrganizationResource';
import FormResource       from 'resources/organization/FormResource';
import SequenceResource   from 'resources/organization/SequenceResource';
import ClientFormGroupResource   from 'resources/organization/ClientFormGroupResource';

import Display  from './Display';
// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function selectHandler({ clientId, sequences, history }) {
  return async (value) => {
    const [type , nextId] = value.split('-');

    if( type === 'sequence' ){
      const sequence = sequences.find( s => s.id === parseInt(nextId, 10) );
      history.push(`/organization/clients/${clientId}/client_forms/sequence/${sequence.id}/new/${sequence.forms[0].id}`);
    } else {
      history.push(`/organization/clients/${clientId}/client_forms/new/${nextId}`);
    }
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FormsGroupedContainer = ({ loading, history, match }) => {
    // const { history, match: { params: { id } } } = this.props;
  const { params: { id } }  = match;

const forms = useOrganizationResource(FormResource.listShape(), {});
const sequences = useOrganizationResource(SequenceResource.listShape(), {});
const clientFormGroups = useOrganizationResource(ClientFormGroupResource.listShape(), { client_id: id });

const handleSelectNew = selectHandler({ clientId: id, sequences, history });

  return (
    <Display  match={match}
              loading={loading}
              clientFormGroups={ clientFormGroups }
              forms={forms}
              sequences={sequences}
              onSelectNew={ handleSelectNew } />
  );
};

export default FormsGroupedContainer;