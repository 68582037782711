import createResource from '../utils/resource';
import { overrideReducer } from '../utils/reducers';
import { generateReducers } from '../utils/reducers';
import * as types from '../constants/ActionTypes';

const impersonation = createResource({
  name : 'impersonation',
  initialState : null,
  selector : state => state.impersonation,
  basepath : '/admin/impersonations',
  actions : {
    create : {
      method : 'POST',
      response : overrideReducer
    },
    destroy : {
      method : 'DELETE',
      response : () => null
    },
    get : {
      method : 'GET',
      response : (state, action) => action.payload
    }
  },
  reducer: generateReducers({
    [types.DEAUTH_REQUESTED]: () => null,
    [types.AUTH_FAILED]:      () => null
  })
});

export default impersonation;
