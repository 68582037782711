import React, { Component }  from 'react';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function(WrappedComponent, { getScope }={}) {
  return class AnonymousComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        sort:   null,
        scope:  null
      };

      this.handleOnSort       = this.handleOnSort.bind(this);
      this.handleOnSortReset  = this.handleOnSortReset.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
      if(getScope && getScope.call) {
        const nextScope = getScope(props);
        const { scope } = state;

        if(scope !== nextScope) {
          return { scope: nextScope, sort: null };
        } else {
          return null;
        }
      }

      return null;
    }

    render() {
      const { sort } = this.state;

      return (
        <WrappedComponent { ...this.props }
                          sort={ sort }
                          onSort={ this.handleOnSort }
                          onSortReset={ this.handleOnSortReset } />
      );
    }

    handleOnSort({ sort }) {
      this.setState({ sort });
    }

    handleOnSortReset() {
      this.setState({ sort: null });
    }
  };
}
