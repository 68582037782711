import React from 'react';
import { Icon, Card, Header, Container }  from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Confirmation = () => {
  return (
    <Header color='green' size='huge'>
      <Card centered
        color='green'
        style={{ marginTop: '15vh', padding: '4rem' }}>

        <Container textAlign='center'>
          <Icon name='check circle outline' size='huge' />
          <p>Thank You!</p>
        </Container>
      </Card>
    </Header>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Confirmation;
