import React                from 'react';
import PropTypes            from 'prop-types';
import { Table, Statistic } from 'semantic-ui-react';

import formattedDate  from 'utils/formattedDate';

import StateIndicator from '../StateIndicator';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const APPROVED_STATE  = 'approved';
const REJECTED_STATE  = 'rejected';
const PENDING_STATE   = 'pending';

function statusOf(affiliation) {
  const { state, approved_at, rejected_at, created_at } = affiliation;
  const approver = affiliation.approver || {};

  if(state === APPROVED_STATE) {
    return {
      timestampHeader:  'Approved At',
      approverHeader:   'Approved By',
      timestampValue:   formattedDate(approved_at),
      approverValue:    approver.name
    };
  } else if(state === REJECTED_STATE) {
    return {
      timestampHeader:  'Rejected At',
      approverHeader:   'Rejected By',
      timestampValue:   formattedDate(rejected_at),
      approverValue:    approver.name
    };
  } else if(state === PENDING_STATE) {
    return {
      timestampHeader:  'Submitted At',
      timestampValue:   formattedDate(created_at)
    };
  }

  return {};
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const AffiliationStatus = ({ affiliation }) => {
  const { state } = affiliation;
  const status    = statusOf(affiliation);

  const { timestampHeader,
          approverHeader,
          timestampValue,
          approverValue } = status;

  return (
    <Table basic='very'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>Status</Table.HeaderCell>
          <Table.HeaderCell width={4}>{ timestampHeader }</Table.HeaderCell>
          {
            approverHeader &&
            <Table.HeaderCell width={4}>{ approverHeader }</Table.HeaderCell>
          }
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <StateIndicator state={ state } size='small' />
          </Table.Cell>
          <Table.Cell>
            <Statistic size='mini' color='grey'>
              <Statistic.Value>
                { timestampValue }
              </Statistic.Value>
            </Statistic>
          </Table.Cell>

          {
            approverValue &&
            <Table.Cell>
              <Statistic size='mini' color='grey'>
                <Statistic.Value>
                  { approverValue }
                </Statistic.Value>
              </Statistic>
            </Table.Cell>
          }
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

AffiliationStatus.propTypes = {
  affiliation: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AffiliationStatus;
