import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { Switch, Route }        from 'react-router-dom';
import urljoin                  from 'url-join';

import HeaderBar                from 'components/HeaderBar';
import Main                     from 'components/Main';
import ToolBar                  from 'components/ToolBar';
import Privileges               from 'constants/Privileges';
import WithPermission           from 'containers/WithPermission';

import { dashboardWidgets }     from 'resources/organizationResources';
import { getAllById }           from 'utils/connectors';
import connectResource          from 'utils/connectResource';


import EditView                 from './EditView';
import ShowView                 from './ShowView';
import AddButton                from './AddButton';

import 'style/containers/organization/dashboard.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const resourceName        = 'dashboards';
const defaultWidgetLayout = { x: 0, y: 0, w: 2, h: 3 };

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class App extends Component {
  constructor(props) {
    super(props);

    this.handleAddWidgets     = this.handleAddWidgets.bind(this);
    this.handleDeleteWidget   = this.handleDeleteWidget.bind(this);
    this.handleUpdateWidgets  = this.handleUpdateWidgets.bind(this);
  }

  render() {
    const { match: { path }, data } = this.props;
    const dashboardWidgets          = data.dashboardWidgets || [];

    return (
      <WithPermission resource={ resourceName }
                      actions={ Privileges.read }
                      redirect="/organization/permissiondenied">

        <Main>
          <HeaderBar height='40px'>
            <ToolBar  offsetLeft='0px'
                      className='padded-content section-menu'>
              <WithPermission resource={ resourceName } actions={ Privileges.write }>
                <AddButton onSubmit={ this.handleAddWidgets } />
              </WithPermission>
            </ToolBar>
          </HeaderBar>
          <Main className='padded-content' overflow='auto' offsetTop='40px'>
            <Switch>
              <Route  path={ urljoin(path, '/edit') }
                      render={
                        props => (
                          <EditView dashboardWidgets={ dashboardWidgets }
                                    { ...props } />
                        )
                      } />

              <Route  path={ path }
                      render={
                        () => (
                          <ShowView dashboardWidgets={ dashboardWidgets }
                                    onDelete={ this.handleDeleteWidget }
                                    onUpdate={ this.handleUpdateWidgets } />
                        )
                      } />
            </Switch>
          </Main>
        </Main>
      </WithPermission>
    );
  }

  handleAddWidgets({ selectedViews }) {
    const { create } = this.props;

    Object.keys(selectedViews).forEach( (report_definition_id) => {
      selectedViews[report_definition_id].forEach(async (report_view_id) => {
        await create({
          record_type: 'ReportDefinition',
          record_id: report_definition_id,
          view_id: report_view_id,
          ...defaultWidgetLayout });
      });
    });
  }

  async handleDeleteWidget({ id }) {
    const { destroy } = this.props;
    await destroy(null, { id });
  }

  handleUpdateWidgets({ widgets }) {
    const { update } = this.props;

    widgets.forEach(async ({id, ...params }) => {
      await update(params, { id });
    });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

App.propTypes = {
  data: PropTypes.shape({
    dashboardWidgets: PropTypes.array
  }).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(App, {
  connectors: {
    dashboardWidgets: getAllById(dashboardWidgets)
  },
  mapDispatchToProps: {
    ...dashboardWidgets.actions
  }
});
