import React, { Fragment }      from 'react';
import PropTypes  from 'prop-types';
import { List, Label }   from 'semantic-ui-react';

import MasterDetail             from 'components/MasterDetail';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import { internalForms }        from 'resources/organizationResources';
import { getPageAllById }       from 'utils/connectors';
import connectResource          from 'utils/connectResource';
import { sortByAttributes }     from 'utils/sortComparators';
import { textSubstringFilter }  from 'utils/recordFilters';

import DetailView               from './DetailView';
// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const ListItemView = ({ record: { name, active } }) => {
  return (
    <Fragment>
      {
        !active &&
        <List.Content floated='right'>
          <Label size='mini' color='grey'>
            Inactive
          </Label>
        </List.Content>
      }
      <List.Header>{ name }</List.Header>
    </Fragment>
  );
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const App = (props) => {
  const { data: { forms } } = props;

  return (
    <MasterDetail masterItemComponent={ ListItemView }
                  detailComponent={ DetailView }
                  records={ forms || [] }
                  resource='internal_forms'
                  listSort={ sortByAttributes('name') }
                  filterRecord={textSubstringFilter('name')}
                  displayOnly
                  { ...props } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

App.propTypes = {
  data: PropTypes.shape({
    internalForms: PropTypes.array
  }).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    forms: getPageAllById(internalForms)
  },
  mapDispatchToProps: { ...internalForms.actions }
});

export default withSearch(withPagination(connectedComponent));
