import React, { Fragment, Component }   from 'react';
import PropTypes                        from 'prop-types';
import { Button, Popup }                from 'semantic-ui-react';
import FormModal                        from './FormModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Edit form logic.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class EditButton extends Component {
  state = { opened: false }

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    const { opened } = this.state;

    const Btn = <Button icon='pencil'
                        basic
                        color='blue'
                        onClick={ this.handleClick } />;

    return (
      <Fragment>
        <FormModal  title={ title }
                    opened={ opened }
                    onClose={ this.handleClose }
                    { ...this.props } />

        <Popup  trigger={ Btn }
                content={ title }
                position='top center' />
      </Fragment>
    );
  }

  handleClick() {
    this.setState({ opened: true });
  }

  handleClose() {
    this.setState({ opened: false });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditButton.propTypes = {
  index:      PropTypes.number.isRequired,
  condition:  PropTypes.object.isRequired,
  actions:    PropTypes.array.isRequired,
  onSubmit:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditButton;
