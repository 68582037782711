import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ReportDefinitionResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/report_definitions';

  id              = undefined;
  name            = '';

  pk() {
    return this.id && this.id.toString();
  }
}
