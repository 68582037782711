import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Button, Confirm }            from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class DeleteButton extends Component {

  // construction
  constructor(props) {
    super(props);

    this.state = { opened: false };

    this.handleClick    = this.handleClick.bind(this);
    this.handleCancel   = this.handleCancel.bind(this);
    this.handleConfirm  = this.handleConfirm.bind(this);
  }

  // event handlers
  handleClick() {
    this.setState({ opened: true });
  }
  handleCancel() {
    this.setState({ opened: false });
  }
  handleConfirm() {
    const { onConfirm } = this.props;
    onConfirm();
    this.setState({ opened: false });
  }

  // rendering
  render() {
    const { opened }  = this.state;
    const title       = 'Delete notification';

    return (
      <Fragment>
        <Confirm  open={ opened }
                  header={ title }
                  onCancel={ this.handleCancel }
                  onConfirm={ this.handleConfirm } />

        <Button negative
                onClick={ this.handleClick }
          >delete</Button>
      </Fragment>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DeleteButton.propTypes = {
  onConfirm: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DeleteButton;
