import React, { useState }        from 'react';
import PropTypes            from 'prop-types';
import { Segment, Header, Label,
         Modal, Button }  from 'semantic-ui-react';

import ResponseForm               from 'containers/ResponseForm';
import ClientFormResource         from 'resources/organization/ClientFormResource';
import FormResource               from 'resources/organization/FormResource';
import FormValidatorResource      from 'resources/organization/FormValidatorResource';
import FormPrePopulateResource       from 'resources/organization/FormPrePopulateResource';
import SequenceResource           from 'resources/organization/SequenceResource';
import useOrganizationResource    from 'hooks/useOrganizationResource';
import useOrganizationFetcher     from 'hooks/useOrganizationFetcher';
import SequenceModal              from './SequenceModal';
import { formDataRequestMapper }  from 'utils/requestMappers';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function saveHandler({ id, form, create, setSequenceModal, setResponseId, setValidationModal }) {
  return async (answers, done) => {

    const params  = { client_id: id };
    const body    = formDataRequestMapper({ form_id: form.id, answers });

    const response = await create(params, body);

    if(response) {
      if (form.active) {
        setResponseId(response.id);
        setSequenceModal(true);
        done(true);
      } else {
        setValidationModal(true);
      }
    }

    done();
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FormView = ({ match, history }) => {
  const { params: { form_id, sequence_id=null, id } } = match;
  const [openSequenceModal, setSequenceModal] = useState(false);
  const [openValidationModal, setValidationModal] = useState(false);
  const [responseId, setResponseId] = useState(null);

  const form  = useOrganizationResource(FormResource.detailShape(), { id: form_id });
  const sequences = useOrganizationResource(SequenceResource.listShape(), {});
  const sequence = sequence_id ? sequences.find( ({id}) => id === parseInt(sequence_id) ) : null;
  const prePopulate = useOrganizationResource(FormPrePopulateResource.detailShape(), { form_id: form_id, id: id });

  const isFormActive = form.active;
  const createShape =  isFormActive
                      ? ClientFormResource.createShape()
                      : FormValidatorResource.createShape();
  const create      = useOrganizationFetcher(createShape);
  const handleSave  = saveHandler({ id, form, create, setSequenceModal, setResponseId, setValidationModal });

  return (
    <Segment>
      <Header as='h3'>{ sequence_id ? `${sequence.name}: ` : ''} { form.name }
      { sequence_id &&
        <Label size='mini' color='orange' floated='right'>
          Sequence
        </Label>
      }
      { !form.active &&
        <Label size='mini' color='grey' floated='right'>
          Inactive
        </Label>
      }
      </Header>
      <div style={{ marginTop: '2rem' }}>
        {
          form &&
          <ResponseForm form={ form } answers={prePopulate.answers} onSave={ handleSave } submitButtonTitle={ form.active ? 'Save' : 'Validate' } />
        }
      </div>

      <Modal dimmer='inverted' open={openValidationModal} size='small'>
        <Header icon='check circle' content='Form Validation'/>
        <Modal.Content>
          Answers are valid. Record not saved because form is inactive.
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setValidationModal(false) }>
            OK
          </Button>
        </Modal.Actions>
      </Modal>

      <SequenceModal  open={ openSequenceModal }
                      responseId={ responseId }
                      formId={ form.id }
                      sequence={ sequence }
                      clientId={ id }
                      history={ history }/>
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormView.defaultProps = {};

FormView.propTypes = {
  history:  PropTypes.object.isRequired,
  match:    PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormView;
