import React      from 'react';
import PropTypes  from 'prop-types';
import { Form }   from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FreeformLong = ({ name, value, onChange }) => {
  return (
    <Form.TextArea  value={ value || '' }
                    name={ name }
                    onChange={ onChange } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FreeformLong.defaultProps = {
  value:    '',
  metadata: {}
};

FreeformLong.propTypes = {
  value:    PropTypes.string,
  metadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FreeformLong;
