import React from 'react';
import {
  ResponsiveContainer,
  AreaChart, BarChart, ComposedChart, LineChart, ScatterChart,
  PieChart, RadarChart,
  Area, Bar, Line, Scatter, Pie,
  Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, LabelList
} from 'recharts';

import { chartColors } from 'constants/reports/Charts';
import { renderPercentLabel, renderValueLabel } from 'utils/reportViews';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const chartTypeComponents = {
  'cartesian': {
    'areachart':     AreaChart,
    'barchart':      BarChart,
    'composedchart': ComposedChart,
    'linechart':     LineChart,
    'scatterchart':  ScatterChart
  },
  'polar': {
    'piechart':      PieChart,
    'radarchart':    RadarChart,
  }
};

const dataTypeComponents = {
  'areachart':     [Area],
  'barchart':      [Bar],
  'composedchart': [Bar, Line],
  'linechart':     [Line],
  'scatterchart':  [Scatter],
};

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

function cartesianDataRenderer(view_type, key, index, settings=[]) {
  const cartesianComponents = dataTypeComponents[view_type] || [];
  const stacked = settings.includes('stacked');
  const dashed  = settings.includes('dashed');
  const percent = settings.includes('percent');
  const showValueLabel = settings.includes('value_labels');

  return cartesianComponents.map((CartesianComponent) => {
    const fillColor   = chartColors['fill'][index % chartColors['fill'].length];
    const strokeColor = chartColors['stroke'][index % chartColors['stroke'].length];
    let componentProps = {
        key:     CartesianComponent.name + index,
        type:    'monotone',
        dataKey: key,
        fill:    fillColor,
    };

    if (CartesianComponent === Line){
      componentProps.stroke      = strokeColor;
      componentProps.strokeWidth = 3;
    }

    if (stacked || percent) { componentProps.stackId         = '1'; }
    if (dashed)             { componentProps.strokeDasharray = '5 5'; }

    return (
      <CartesianComponent key={componentProps.key} { ...componentProps }  >
        { showValueLabel && <LabelList dataKey={key} position="top" fill={strokeColor} /> }
      </CartesianComponent>
    );
  });
}

function cartesianChartRenderer(data) {
  const {
    view_type,
    records=[],
    xaxis,
    yaxis=null,
    xaxis_label='',
    yaxis_label='',
    series=[],
    settings } = data;

  const ChartComponent    = chartTypeComponents['cartesian'][view_type];

  let chartProps   = {
    data:    records,
    margin:  { top: 20, right: 20, bottom: 30, left: 0 }
  };
  let xAxisProps   = { dataKey: xaxis, label: { value: xaxis_label, offset: -2,  position: 'insideBottom' } };
  let yAxisProps   = { label: { value: yaxis_label, angle: -90, position: 'insideLeft' } };
  let scatterProps = { };

  if (ChartComponent === AreaChart && settings.includes('percent')) {
    chartProps.stackOffset   = 'expand';
    yAxisProps.tickFormatter = toPercent;
  }

  if (ChartComponent === ScatterChart && yaxis) {
    xAxisProps.type    = 'number';
    yAxisProps.dataKey = yaxis;

    if (settings.includes('joint_line')) {
      scatterProps.line = true;
    }
  }

  return (
        <ChartComponent { ...chartProps } >
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <XAxis { ...xAxisProps } />
            <YAxis { ...yAxisProps } />
            { settings.includes('negative_values') && <ReferenceLine y={0} stroke="#666" />}
            { series.length > 0 &&
              series.map((key, index) => {
                return cartesianDataRenderer(view_type, key, index, settings);
              })
            }
            { ChartComponent === ScatterChart &&
              <Scatter data={records} { ...scatterProps } fill={ chartColors['fill'][0] } />
            }
        </ChartComponent>
  );
}

function polarChartRenderer(data) {
  const {
    view_type,
    records=[],
    xaxis,
    series=[],
    settings } = data;

  const ChartComponent    = chartTypeComponents['polar'][view_type];
  const innerRadius       = settings.includes('ring') ? '50%' : 0;
  const label             = settings.includes('value') ? renderValueLabel : renderPercentLabel;

  return (
      <ChartComponent>
        { series.map((key, index) =>
        <Pie
          data={records}
          dataKey={key}
          nameKey={xaxis}
          key={`pie-${index}`}
          innerRadius={innerRadius}
          label={label}
        >
          {
            records.map((entry, index) => <Cell key={`cell-${index}`} fill={chartColors['fill'][index % chartColors['fill'].length]}/>)
          }
        </Pie>
        )}
      </ChartComponent>
  );
}
// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function Chart({ data={} }) {
    const { view_type } = data;

    return (
      <ResponsiveContainer height={480} width="100%">
        {
          Object.keys(chartTypeComponents['cartesian']).includes(view_type)
          ? cartesianChartRenderer(data)
          : polarChartRenderer(data)
        }
      </ResponsiveContainer>
    );
}
