import React, { useState }                                from 'react';
import PropTypes                                          from 'prop-types';
import { Modal, Header, Message, Button, Divider, Icon }  from 'semantic-ui-react';

import DataList from 'components/DataList';

import ClientSearchForm from './ClientSearchForm';
import useClientCreate  from 'caseWorthy/hooks/useClientCreate';
import useClientUpdate  from 'caseWorthy/hooks/useClientUpdate';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const title = 'Link This Person to DTD for Referral';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ClientLinkModal = ({ client, dataList, opened, onClose }) => {
  const [onCommit, setOnCommit] = useState();
  const create    = useClientCreate(client.id);
  const update    = useClientUpdate(client.id);
  const disabled  = onCommit === null || onCommit === undefined;

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if(disabled) {
      return;
    }

    const response = await onCommit();
    setOnCommit();
    onClose(response);
  };

  const handleClose = () => {
    setOnCommit();
    onClose();
  };

  const handleSelect = (client) => {
    setOnCommit(() => async () => await update(client.entity_id));
  };

  const handleCreate = ({ checked: shouldCreate, params }) => {
    if(shouldCreate) {
      setOnCommit(() => async () => await create(params));
    } else {
      setOnCommit();
    }
  };

  return (
    <Modal open={ opened } onClose={ handleClose } dimmer='inverted' closeIcon>
      <Header icon='linkify' content={title} />
      <Modal.Content>
        <Message error icon>
          <Icon name='exclamation' color='red' />
          <Message.Content>
            <p>
              This client has pending DTD referrals, but the referrals cannot be sent
              until the client has been linked to the DTD system.
            </p>
            <p>
              Below, you may select from existing DTD clients, or you may opt to
              create a new DTD client.  The selected/created DTD client will be
              linked to this client record.  No further action will be required for
              subsequent referrals, once a link has been established.
            </p>
          </Message.Content>
        </Message>
        <h4>Client Details</h4>
        <DataList items={ dataList } />
        <Divider horizontal>Search DTD Clients</Divider>
        <ClientSearchForm client={ client }
                          onSelect={ handleSelect }
                          onCreate={ handleCreate }
                          onSubmit={ handleSubmit } />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={ handleClose } negative basic>Cancel</Button>
        <Button type='submit'
                onClick={ handleSubmit }
                disabled={ disabled }
                positive
                basic>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ClientLinkModal.defaultProps = {
  opened: false
};

ClientLinkModal.propTypes = {
  opened:   PropTypes.bool,
  client:   PropTypes.object.isRequired,
  dataList: PropTypes.array.isRequired,
  onClose:  PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ClientLinkModal;
