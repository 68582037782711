import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { Icon, Message, Table } from 'semantic-ui-react';

import _                        from 'lodash';

import MappingsRow              from './MappingsRow';
import MappingsHeader           from '../../MappingsHeader';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class Mappings extends Component {

  //  helpers
  getSortedMappingsArray() {
    const { destinations: pd,
            mappings:     pm } = this.props;
    const fm = _.filter(pm, m => !!pd[m.destination_id]);
    const sm = _.sortBy(fm, m => pd[m.destination_id].position);
    return sm;
  }

  //  rendering
  render() {
    const { destinations, origins } = this.props;
    const mappings                  = this.getSortedMappingsArray();

    if (mappings.length) {
      return (
        <Table basic='very' className='with-top-margin'>
          <MappingsHeader />
          <Table.Body>
          {
            mappings.map(mapping => (
              <MappingsRow  key={ mapping.destination_id }
                            destinations={ destinations }
                            origins={ origins }
                            mapping={ mapping } />
            ))
          }
          </Table.Body>
        </Table>
      );
    }
    else {
      return (
        <Message icon info>
          <Icon name='info circle' />
          This import currently has no column mappings defined.
        </Message>
      );
    }
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

Mappings.propTypes = {
  destinations: PropTypes.object.isRequired,
  origins:      PropTypes.object.isRequired,
  mappings:     PropTypes.array.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default Mappings;
