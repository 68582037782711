import React, { Component } from 'react';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function(WrappedComponent) {
  return class AnonymousComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        category: null
      };

      this.handleOnCategory = this.handleOnCategory.bind(this);
    }

    render() {
      const { category } = this.state;

      return (
        <WrappedComponent { ...this.props }
                          category={ category }
                          onCategory={ this.handleOnCategory } />
      );
    }

    handleOnCategory({ category }) {
      this.setState({ category });
    }
  };
}
