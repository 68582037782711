import React              from 'react';
import { Form, Segment }  from 'semantic-ui-react';
import { Redirect }       from 'react-router-dom';

import connectForm          from 'utils/connectForm';

import FormList from './SequenceFormList';

import Fixed            from 'components/Fixed';

// -----------------------------------------------------
// Helpers & Constants
// -----------------------------------------------------


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------


class FormView extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange         = this.handleChange.bind(this);
  }

  render() {
    const { loading,
            api_errors,
            saving,
            onSave,
            selected,
            data
          } = this.props;

    const activeForms = Object
                      .values(data.forms || {})
                      .filter(({ form_type, active }) => (form_type === 'client' && active));

    const { name='',
            forms={} } = selected || {};

    return (
      <Segment loading={ loading }>
        <Form onSubmit={ onSave } warning={ !forms.length }>
          { api_errors && <Redirect to='./'/> }

          <Form.Input label='Name'
                      name='name'
                      value={ name }
                      onChange={ this.handleChange } />

          <FormList allForms={ activeForms }
                    name='forms'
                    forms={ forms }
                    onChange={ this.handleChange } />

          <Fixed>
            <Form.Button  loading={ saving }
                          primary>Save</Form.Button>
          </Fixed>

        </Form>
      </Segment>
    );
  }

  handleChange(evt, { name, value }) {
    const { onUpdateState } = this.props;
    onUpdateState({ [name]: value });
  }
}

const initialState = {
  id:          null,
  name:        '',
  forms:       [],
};

export default connectForm(FormView, {
  getDefault: ({ data: { selected={} } }) => (
    { ...initialState, ...(selected) }
  ),
  mapOnSave: (selected) => {
    return {
      ...selected,
      forms: selected.forms.filter(o=>!o.new)
    };
  }
});