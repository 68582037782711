export default function resourceHeaders(authToken, transformHeaders=h=>h) {
  const headers = { 'Content-Type': 'application/json' };

  if(authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  if(transformHeaders) {
    transformHeaders(headers);
  }

  return headers;
}
