import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { Button, Popup }    from 'semantic-ui-react';

import FormModal from './FormModal';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const title = 'Create API Credentials';

const CompactButton = ({ onClick }) => {
  const Btn = <Button icon='add'
                      size='mini'
                      basic
                      color='blue'
                      onClick={ onClick } />;

  return (
    <Popup  trigger={ Btn }
            content={ title }
            position='top center' />
  );
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const CreateButton = ({ onCreate, compact }) => {
  const [opened, setOpened]           = useState(false);
  const [credentials, setCredentials] = useState();

  const afterCreate = (credentials) => {
    setCredentials(credentials);
  };
  
  function handleClose() {
    setCredentials(null);
    setOpened(false);
  }

  const handleClick = () => {
    setOpened(true);
  };

  const handleSubmit = (body) => {
    onCreate(body, afterCreate);
  };

  return(
    <>
      <FormModal  title={ title }
                  credentials={ credentials }
                  opened={ opened }
                  onClose={ handleClose }
                  onSubmit={ handleSubmit } />

      {
        compact 
        ? <CompactButton onClick={ handleClick } />
        : <Button primary onClick={ handleClick }>{ title }</Button>
      }
    </>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

CreateButton.defaultProps = {
  compact: false
};

CreateButton.propTypes = {
  onCreate: PropTypes.func.isRequired,
  compact:  PropTypes.bool
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default CreateButton;