import { find, merge }                      from 'lodash';
import { Evaluator as ConditionEvaluator }  from '@coactionnet/conditionaljs';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const typeParserExpr = /^(un)?(.+)$/i;

function typeToState(type) {
  const [ , negation, root] = typeParserExpr.exec(type);
  return { [root]: !negation };
}

function reduceToTargetStates(actions) {
  return actions.reduce((states, { type, target: { id }, payload }) => {
    const state = typeToState(type);
    if(payload) {
      state[`${type}Payload`] = payload;
    }

    states[id] = Object.assign({}, states[id], state);
    return states;
  }, {});
}

function compile(logic) {
  return logic.map(({ condition, actions }) => (
    [
      new ConditionEvaluator(condition),
      reduceToTargetStates(actions)
    ]
  ));
}

function negateTargetStates(targetStates) {
  return Object.keys(targetStates).reduce((nextTargetStates, id) => {
    const state           = targetStates[id];
    nextTargetStates[id]  = Object.keys(state).reduce((nextState, action) => {
      nextState[action]   = !state[action];

      return nextState;
    }, {});

    return nextTargetStates;
  }, {});
}

function updateDynamicData(evaluator, targetStates, data){
  const updatedStates = targetStates;
  Object.keys(targetStates).forEach(key => {
    if(targetStates[key].filter){
      // expecting this exact conditions structure for now. should make it more flexible in the future
      const filterValues = evaluator.conditions.and.map( ({conditions}) => (
                            find(data.answers, ['field_id', conditions.and[0].value]).value
                          ));

      updatedStates[key].filterPayload = filterValues;
    }
  });

  return updatedStates;
}

// -----------------------------------------------------
// Evaluator Definition
// -----------------------------------------------------

class Evaluator {
  constructor(logic) {
    this.logic = compile(logic);
  }

  evaluate(data) {

    // Initialize state with negative cases
    const initialState  = this.logic.reduce((allState, [, targetStates]) => {
      return merge(allState, negateTargetStates(targetStates));
    }, {});

    // 'switch on' states where the evaluator is satisfied by the state's
    // corresponding evaluator
    const finalState  = this.logic.reduce((allState, [evaluator, targetStates]) => {
      if(evaluator.isSatisfiedBy(data)) {
        targetStates = updateDynamicData(evaluator, targetStates, data);
        merge(allState, targetStates);
      }

      return allState;
    }, initialState);

    return finalState;
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Evaluator;
