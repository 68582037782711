import React      from 'react';
import PropTypes  from 'prop-types';
import { Table }  from 'semantic-ui-react';

import Row from './Row';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Body = ({ actionTypes, fields, actions, onChange }) => {
  const actionTypeList = Object.keys(actionTypes);

  return (
    <Table.Body>
      {
        fields.map((field) => (
          <Row  field={ field }
                key={ field.value }
                actions={ actions }
                actionTypeList={ actionTypeList }
                onChange={ onChange } />
        ))
      }
    </Table.Body>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Body.propTypes = {
  actionTypes:  PropTypes.object.isRequired,
  fields:       PropTypes.array.isRequired,
  actions:      PropTypes.array.isRequired,
  onChange:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Body;
