import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Header, Button }   from 'semantic-ui-react';
import { Link }             from 'react-router-dom';
import urljoin              from 'url-join';

import Main                 from 'components/Main';
import SortableTable        from 'components/SortableTable';
import Chart                from 'components/Chart';
import Map                  from 'components/Map';

import {reportResults,
        reportViews,
        reportDefinitions } from 'resources/organizationResources';

import { getById, getPageById }     from 'utils/connectors';
import connectResource              from 'utils/connectResource';
import { refPrefix }                from 'utils/reportDefinitions';
import withPagination               from 'hocs/withPagination';
import withSort                     from 'hocs/withSort';
import ClientFormReportCellRenderer from 'components/organization/reports/ClientFormReportCellRenderer';
import PublicFormReportCellRenderer from 'components/organization/reports/PublicFormReportCellRenderer';
import InternalFormReportCellRenderer from 'components/organization/reports/InternalFormReportCellRenderer';
import RefreshButton                from 'components/organization/reports/RefreshButton';

import DeleteButton from '../DeleteButton';

import 'style/containers/organization/report-results.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
const cellRendererType = {
  'client_forms':   ClientFormReportCellRenderer,
  'public_forms':   PublicFormReportCellRenderer,
  'internal_forms': InternalFormReportCellRenderer
};

const initialScrollResets = {};
const originScrollResets  = { scrollTop: 0, scrollLeft: 0 };

const REFETCH_INTERVAL  = 2000;

const sortOrders = {
  '-1': 'desc',
  '1':  'asc'
};

function reportScope({ reportDefinitionId }) {
  return { report_id: reportDefinitionId };
}

function pageScope({ reportViewId }) {
  return reportViewId;
}

function cellRenderer(reportDefinition, headers) {
  const { report_type } = reportDefinition;

  const Renderer      = cellRendererType[report_type]
                        || PublicFormReportCellRenderer;

  const CellRenderer  = (record, value, columnIndex) => (
    <Renderer reportDefinition={ reportDefinition }
              headers={ headers }
              record={ record }
              value={ value }
              columnIndex={ columnIndex } />
  );

  return CellRenderer;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Report extends Component {

  constructor(props) {
    super(props);

    this.state = {
      scrollResets: initialScrollResets
    };
    this.handleSort     = this.handleSort.bind(this);
    this.handleRefresh  = this.handleRefresh.bind(this);
  }

  render() {
    const { id, data, onDelete, onPageNext, reportDefinitionId, reportViewId } = this.props;
    const { scrollResets }            = this.state;
    const definition                  = data.definition || {};
    const results                     = data.results    || {};
    const { name }                    = definition;
    const { headers=[], records=[], view_type='table' }  = results;

    const cloaker = (index) => ( `${headers[index]}`.startsWith(refPrefix) );

    return (
      <Main className='widget'>
        <Main offsetTop='1.25rem' offsetLeft='1rem' offsetRight='1rem'>
          <Main offsetRight='50%'>
            <Header as='h3'>{ name }</Header>
          </Main>
          <Main offsetLeft='50%'>
            <div className='pull-right'>
              <RefreshButton  size='mini'
                              color='blue'
                              compact
                              refresh={ this.handleRefresh }
                              viewId={ reportViewId }
                              reportDefinition={ definition } />

              <Button as={ Link }
                      to={ urljoin('reports', reportDefinitionId, 'views', reportViewId) }
                      icon='external'
                      color='blue'
                      size='mini'
                      compact
                      basic />
              <DeleteButton onDelete={ onDelete } widgetId={ id } />
            </div>
          </Main>
        </Main>

        <Main offsetTop='4rem'>
          { view_type === 'table'
            ? <SortableTable  { ...scrollResets }
                          tableId={`report_${reportDefinitionId}`}
                          headerLabels={ headers }
                          records={ records }
                          cellRenderer={ cellRenderer(definition, headers) }
                          onPageNext={ onPageNext }
                          onSort={ this.handleSort }
                          columnCloaker={ cloaker }
                          remoteSort={ true }
                          className='report-table' />
            : view_type === 'map'
              ? <Map data={ results } />
              : <Chart data={ results } />
          }
        </Main>
      </Main>
    );
  }

  handleSort({ sort=[] }) {
    const { onSort, onPageReset, data: { definition, results } }  = this.props;

    const { fields=[] }   = definition || {};
    const { headers=[] }  = results || {};
    const offset          = headers.length - fields.length;

    const reportSort = sort.map(({ index, direction }) => (
      { ...fields[index - offset], order: sortOrders[direction] }
    ));

    onPageReset();
    onSort({ sort: reportSort });
  }

  async handleRefresh({ id, viewId }) {
    const { refresh,
            refetch,
            sort,
            onPageReset } = this.props;

    const refreshResponse         = await refresh(null, { id });
    const { refreshing, errors }  = refreshResponse;

    if(!errors) {
      onPageReset();
      this.setState({ scrollResets: originScrollResets });

      const refetcher = setInterval(async () => {
        const refetchResponse
          = await refetch(null, { id: viewId, report_id: id, sort: JSON.stringify(sort) });

        const { refreshing, errors }  = refetchResponse;
        this.setState({ scrollResets: initialScrollResets });

        if(errors || !refreshing) {
          clearInterval(refetcher);
        }
      }, REFETCH_INTERVAL);
    }

    return refreshing;
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Report.defaultProps = {
  pending: false
};

Report.propTypes = {
  id:                 PropTypes.number.isRequired,
  reportDefinitionId: PropTypes.number.isRequired,
  reportViewId:       PropTypes.number.isRequired,
  onDelete:           PropTypes.func.isRequired,
  onPageNext:         PropTypes.func.isRequired,

  data: PropTypes.shape({
    results: PropTypes.shape({
      headers: PropTypes.array,
      records: PropTypes.arrayOf(PropTypes.array)
    }),
    definition: PropTypes.shape({
      name: PropTypes.string
    })
  }),

  pending: PropTypes.bool
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const definitionConnectedComponent = connectResource(Report, {
  connectors: {
    definition: getById(reportDefinitions, ({ reportDefinitionId }) => reportDefinitionId)
  }
});

const resultsConnectedComponent = connectResource(definitionConnectedComponent, {
  key: (({ page, sort, id }) => [id, sort, page]),
  connectors: {
    results: getPageById(reportViews, pageScope, reportScope),
  },

  mapDispatchToProps: {
    refresh: reportResults.actions.refresh,
    refetch: reportViews.actions.refetch
  }
});

const paginatedComponent = withPagination(resultsConnectedComponent, {
  getScope: pageScope
});

const sortedComponent = withSort(paginatedComponent, {
  getScope: pageScope
});

export default sortedComponent;
