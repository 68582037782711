import React, { Fragment }  from 'react';
import { List, Label }      from 'semantic-ui-react';

import MasterDetail                     from 'components/MasterDetail';

import {forms,
        fields,
        calculations,
        cloneRecords }  from 'resources/adminResources';

import scopedToRole                     from 'hocs/scopedToRole';
import withPagination                   from 'hocs/withPagination';
import withSearch                       from 'hocs/withSearch';
import connectResource                  from 'utils/connectResource';
import { getPageAllById, getAllById, getById }  from 'utils/connectors';
import { sortByAttributes }             from 'utils/sortComparators';
import { textSubstringFilter }          from 'utils/recordFilters';

import DetailView from 'components/forms/DetailView';
import FormView   from 'components/forms/FormView';

import ActionsSideCard  from './ActionsSideCard';

// -----------------------------------------------------
// Connected Form View
// -----------------------------------------------------

const ConnectedFormView = connectResource(FormView, {
  connectors: {
    selected: getById(forms, ({ match: { params: { id } } }) => id),
    fields:   getAllById(fields)
  },
  mapDispatchToProps: { ...forms.actions }
});

// -----------------------------------------------------
// Connected Detail View
// -----------------------------------------------------

const { update: formUpdate } = forms.actions;
const {
  create: calculationCreate,
  update: calculationUpdate,
  destroy: calculationDestroy } = calculations.actions;

const detailMapDispatchToProps = {
  formUpdate,
  calculationCreate,
  calculationUpdate,
  calculationDestroy
};

const ScopedDetailView    = scopedToRole(DetailView, { role: 'admin' });
const ConnectedDetailView = connectResource(ScopedDetailView, {
  key: (props) => props.match.params.id,
  connectors: {
    selected:     getById(forms, ({ match: { params: { id } } }) => id),
    fields:       getAllById(fields),
    calculations: getAllById(
      calculations,
      ({ match: { params: { id } } }) => ({ form_id: id }),
      true
    )
  },
  mapDispatchToProps: detailMapDispatchToProps
});

// -----------------------------------------------------
// SideCard
// -----------------------------------------------------

const ConnectedSideCard = connectResource(ActionsSideCard, {
  connectors: {
    form: getById(forms, ({ match }) => match.params.id)
  }
});

// -----------------------------------------------------
// List Item
// -----------------------------------------------------


const ListItem = ({ record: { name, form_type } }) => (
  <Fragment>
    {
      form_type === 'internal' &&
      <List.Content floated='right'>
        <Label size='mini' color='blue'>
          Internal
        </Label>
      </List.Content>
    }
    <List.Header>{ name }</List.Header>
  </Fragment>
);

class App extends React.Component {
  render() {
    const { data, destroy, update, create, clone } = this.props;
    
    return (
    <MasterDetail masterItemComponent={ListItem}
                  detailComponent={ConnectedDetailView}
                  formComponent={ConnectedFormView}
                  sideCardComponent={ ConnectedSideCard }
                  records={data.forms||[]}
                  onDelete={id => destroy(null, {id})}
                  onUpdate={record => update(record, {id: record.id})}
                  onCreate={record => create(record)}
                  onClone={id => clone(null, { cloneable_type: 'forms', cloneable_id: id })}
                  listSort={ sortByAttributes('name') }
                  filterRecord={ textSubstringFilter('name') }
                  {...this.props}/>
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    forms: getPageAllById(forms)
  },
  mapDispatchToProps: {
    ...forms.actions,
    ...cloneRecords.actions
  }
});

export default withSearch(withPagination(connectedComponent));
