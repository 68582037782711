import React        from 'react';
import PropTypes            from 'prop-types';
import { Header, Modal, Button,
         Divider,  Grid, Segment }  from 'semantic-ui-react';
import NewFormSelector  from './shared/NewFormSelector';

import FormResource from 'resources/organization/FormResource';
import useOrganizationResource from 'hooks/useOrganizationResource';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const SequenceModal = ({ open, responseId, formId, sequence, clientId, history }) => {
  const allForms  = useOrganizationResource(FormResource.listShape(), {});

  const formIndex = sequence ? sequence.forms.findIndex( ({id}) => id === formId ) : null;
  const lastInsequence = sequence ? formIndex === sequence.forms.length - 1 : true;
  const nextForm = !lastInsequence ? sequence.forms[formIndex + 1] : {};

  const handleNewForm = (value) => {
    const formId = value.split('-')[1];
    history.push(`/organization/clients/${clientId}/client_forms/new/${formId}`);
  };

  const  handleNextForm = () => {
    history.push(`/organization/clients/${clientId}/client_forms/sequence/${sequence.id}/new/${nextForm.id}`);
  };

  const  handleShowResponse = () => {
    history.push(`/organization/clients/${clientId}/client_forms/${responseId}`);
  };

  return (
      <Modal dimmer='inverted' open={open} size='small'>
        <Header icon='check circle' content='Response saved'/>
        <Modal.Content>
          <Segment placeholder>
            <Grid columns={2} relaxed='very' centered stackable>
              <Grid.Column verticalAlign='middle'>
                <p>Your response was saved successfully.
                { sequence && <><br/> (This action will interrupt the sequence)</> }
                </p>
                <Button onClick={ handleShowResponse } positive basic>
                  Show Response
                </Button>
              </Grid.Column>

              <Grid.Column verticalAlign='middle'>
                { sequence && ! lastInsequence &&
                  <>
                    <p>The next form in the <b>{sequence.name}</b> sequence is: <b>{ nextForm.name }</b>.</p>
                    <p>Click on the Next button to continue the sequence or choose a different form.</p>
                    <Button onClick={ handleNextForm } positive>
                      Next Form
                    </Button>
                  </>
                }
                { (!sequence || lastInsequence) &&
                  <>
                    { sequence && <p>This was the last form in the <b>{sequence.name}</b> sequence.</p> }
                    <p>Choose a new form to add next.</p>
                    <NewFormSelector forms={ allForms } onSelectNew={handleNewForm} />
                  </>
                }
              </Grid.Column>
            </Grid>

            <Divider vertical>Or</Divider>
          </Segment>
        </Modal.Content>
      </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SequenceModal.defaultProps = {};

SequenceModal.propTypes = {
  formId:  PropTypes.number.isRequired,
  sequence:  PropTypes.object,
  clientId:  PropTypes.any.isRequired,
  history:   PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SequenceModal;
