import React, { useState }       from 'react';
import { formAssignments }       from 'resources/adminResources';
import { connect }               from 'react-redux';
import { Modal, Button, Header } from 'semantic-ui-react';

const anchor = '#copy-template';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const AdminContainer = ({ formId, dispatch }) => {
  const [open, setOpen] = useState(false);

  const copyToTemplates = async () => {
    const { create }                = formAssignments.actions;

    const request = create({
      form_id: formId
    });

    const response    = await dispatch(request);
    const didSucceed  = !response.errors;

    if (didSucceed) { setOpen(true); }

    return didSucceed;
  };

  return (
    <React.Fragment>
      <a href={ anchor } onClick={ copyToTemplates }>
        Copy to Templates
      </a>
    <Modal dimmer='inverted' open={open} size='small'>
      <Header icon='copy' content='Form Template'/>
      <Modal.Content>
        Form copied to Templates successfully.
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false) }>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
    </React.Fragment>
  );

};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default connect()(AdminContainer);
