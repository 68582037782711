import { useContext } from 'react';
import get            from 'lodash/get';

import ImpersonationContext from 'contexts/Impersonation';

// --------------------------------------------------
// Component Definition
// --------------------------------------------------

const RoleRestrictedRouteContainer = ({ children, userAccount, permittedRoles }) => {
  const { impersonation } = useContext(ImpersonationContext);
  const role              = get(impersonation, 'user.role') || userAccount.role;

  if(permittedRoles.includes(role)) {
    return children;
  }

  return null;
};

// -----------------------------------------------------
// Public API
// -----------------------------------------------------

export default RoleRestrictedRouteContainer;
