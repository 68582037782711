import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';

import RecipientFieldItem   from './Item';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class RecipientFieldList extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // handlers
  handleChange(evt, { checked, value }) {
    const { checkedIds, onChange } = this.props;
    const ids = (checked)
                  ? _.concat(checkedIds, value)
                  : _.without(checkedIds, value);
    onChange(ids);
  }

  // rendering
  render() {
    const { checkedIds, fields } = this.props;

    if (fields.length) {
      return (
        <div className='recipient__list'>
          <p>
            And/or send to addresses in the following form fields:
          </p>
          {
            fields.map((field) => (
              <RecipientFieldItem key={ field.id }
                                  checked={ _.includes(checkedIds, field.id) }
                                  label={ field.name }
                                  value={ field.id }
                                  onChange={ this.handleChange } />
            ))
          }
        </div>
      );
    }
    else {
      return null;
    }
  }
}

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

RecipientFieldList.propTypes = {
  checkedIds: PropTypes.array,
  fields:     PropTypes.array,
  onChange:   PropTypes.func
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default RecipientFieldList;
