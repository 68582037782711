// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export function toTitleCase(val) {
  return val.split('_').map(v => v[0].toUpperCase() + v.substr(1)).join(' ');
}

export function toSnakeCase(val) {
  return val.replace(/([A-Z])/, '_$1').toLowerCase();
}
