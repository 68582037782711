import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header,
         Table, Button }          from 'semantic-ui-react';
import { Link }                   from 'react-router-dom';

import Privileges                 from 'constants/Privileges';
import WithPermission             from 'containers/WithPermission';
import FieldRecord                from 'containers/organization/clients/Forms/DetailView/FieldRecord';
import CalculationRecord                from 'containers/organization/clients/Forms/DetailView/CalculationRecord';
import connectResource                        from 'utils/connectResource';
import { getById }                            from 'utils/connectors';
import indexOnAttribute                       from 'utils/indexOnAttribute';
import { internalForms, internalFormRecords } from 'resources/organizationResources';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Record extends Component {
  render() {
    const { download=false,
            data={} }  = this.props;
    const form         = data.form   || {};
    const record       = data.record || {};

    const { form_fields=[], name } = form;
    const { answers=[], calculation_results=[] } = record;
    const indexedAnswers = indexOnAttribute(answers, 'field_id');

    const attachmentFields = form_fields.filter( ({field_type}) => field_type === 'attachment' );
    const editHref =`/organization/internal_form_responses/${form.id}/records/${record.id}/edit`;

    if (download && attachmentFields.length === 1) {
      const { id }  = attachmentFields[0] || {};
      const { value } = indexedAnswers[id] || {};
      const { url }   = value || {};

      if (url) {
        window.location.href = url;
      }
    }

    return (
      <Segment>
      <Header as='h3'>{ name }</Header>
       <Button.Group floated='right' size='mini' >

        <WithPermission resource='internal_forms' actions={ Privileges.write }>
            <Button icon='pencil'
                      basic
                      color='blue'
                      as={ Link }
                      to={ editHref } />
        </WithPermission>
      </Button.Group>

      <Table basic='very' style={{ marginTop: '2rem' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={ 7 }>Field</Table.HeaderCell>
            <Table.HeaderCell>Answer</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            form_fields.map((field) => {
              const { id }  = field;
              const answer  = indexedAnswers[id] || {};

              return (
                <FieldRecord key={ id } field={ field } answer={ answer } />
              );
            })
          }
          {
              calculation_results.map((result) => (
                <CalculationRecord  key={ result.id } result={ result } />
              ))
            }
        </Table.Body>
      </Table>
    </Segment>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Record.propTypes = {
  data:     PropTypes.shape({
    form: PropTypes.object,
    record: PropTypes.object
  }).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(Record, {
  connectors: [
    {
      record: getById(
        internalFormRecords,
        ({ match: { params: { record_id } } })  => (record_id),
        ({ match: { params: { id } } })         => ({ form_id: id })
      ),
      form: getById(internalForms, ({ match: { params: { id } } }) => id)
    }
  ]
});
