import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Header, Segment, Table } from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class BasicDetail extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  // handlers
  handleClick(evt) {
    evt.stopPropagation();
  }

  // rendering
  render() {
    const { loading, record } = this.props;

    return (
      <Segment loading={ loading }>
        <Header as='h3'>
          { record.organization_name }
        </Header>
        <Table basic='very' celled collapsing>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as='h4'>Destination</Header>
              </Table.Cell>
              <Table.Cell>
                { record.name }
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4'>Input Data</Header>
              </Table.Cell>
              <Table.Cell>
                <a href={ record.input_url }
                   target='_new'
                   onClick={ this.handleClick }>{ record.input_filename }</a>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

BasicDetail.propTypes = {
  loading:  PropTypes.bool.isRequired,
  record:   PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default BasicDetail;
