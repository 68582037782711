import React, { Component } from 'react';
import { Redirect, Link }   from 'react-router-dom';
import {
  Header,
  Segment,
  Table,
  List
} from 'semantic-ui-react';

import CalculationsContainer      from 'containers/form/CalculationsContainer';
import LogicSettingsContainer     from 'containers/form/LogicSettingsContainer';
import PublishSettingsContainer   from 'containers/organization/forms/PublishSettingsContainer';
import Notifications              from 'containers/form/Notifications';
import UsageInfo                  from 'components/fields/DetailView/UsageInfo';
import OrgUsageInfo               from './OrgUsageInfo';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class DetailView extends Component {

  render() {

    const {
      data,
      match,
      loading,
      api_errors,
      scope,
      formUpdate,
      calculationCreate,
      calculationUpdate,
      calculationDestroy,
      calculationOrderUpdate }  = this.props;

    const form                  = data.selected     || {};
    const calculations          = data.calculations || [];
    const formFields            = form.form_fields  || [];
    const reports               = form.reports  || [];
    const sequences             = form.sequences  || [];

    const contextPrefix         = match.url.split('/')[1];
    const formId                = match.params.id;
    const { clones, form_type } = form;
    const isPublishable         = form_type === 'client';

    return (
      <div style={{ marginBottom: '2rem' }}>
        <Segment loading={ loading }>

          {
            api_errors && <Redirect to='./'/>
          }

          <Header as='h3'>{ form.name }</Header>
          <Table basic='very' celled collapsing>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Header as='h4'>Description</Header>
                </Table.Cell>
                <Table.Cell>{form.description || 'none' }</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell verticalAlign='top'>
                  <Header as='h4'>Fields</Header>
                </Table.Cell>
                <Table.Cell>
                  <List bulleted>
                    {formFields.map(({ id, field_type, name, is_required }) => (
                      <List.Item key={id}>
                        <Link to={`/${contextPrefix}/fields/${id}`}>
                          { name }
                        </Link>

                        { !is_required
                          && field_type !== 'presentation'
                          && ' (optional)'}
                      </List.Item>
                    ))}
                  </List>
                </Table.Cell>
              </Table.Row>
              { form.redirect_url
                ? <Table.Row>
                    <Table.Cell>
                      <Header as='h4'>Custom Redirect Url</Header>
                    </Table.Cell>
                    <Table.Cell>{form.redirect_url}</Table.Cell>
                  </Table.Row>
                : null
              }
            </Table.Body>
          </Table>
        </Segment>

        <CalculationsContainer  loading={ loading }
                                form={ form }
                                fields={ formFields }
                                calculations={ calculations }
                                createAction={ calculationCreate }
                                updateAction={ calculationUpdate }
                                destroyAction={ calculationDestroy }
                                updateOrderAction={ calculationOrderUpdate } />

        {
          scope === 'organization' && isPublishable &&
          <PublishSettingsContainer formId={ formId } />
        }

        <LogicSettingsContainer loading={ loading }
                                form={ form }
                                fields={ formFields }
                                updateAction={ formUpdate } />

        <UsageInfo loading={ loading }
                   sequences={ sequences }
                   reports={ reports } 
                   record_type={ 'form' } scope={ scope } />

        {
          scope === 'admin' &&
          <OrgUsageInfo loading={ loading } forms={ clones } />
        }

        {
          scope === 'organization' &&
          <Notifications  form={ form }
                          resourceId={ +formId }
                          resourceName='forms' />
        }
      </div>
    );
  }
}

export default DetailView;
