import React, { Component }                   from 'react';
import PropTypes                              from 'prop-types';
import { Responsive, WidthProvider }          from 'react-grid-layout';

import Widget from './Widget';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const gridMargins     = [14, 14];
const gridBreakpoints = { lg: 700,  sm: 0 };
const gridColumns     = { lg: 4,    sm: 1 };

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class EditView extends Component {
  render() {
    const { data }  = this.props;
    const widgets   = data.widgets || [];

    return (
      <ResponsiveReactGridLayout className='layout'
                            margin={ gridMargins }
                            breakpoints={ gridBreakpoints }
                            cols={ gridColumns }
                            isResizable={ true }>
        {
          widgets.map(({ id, report_definition_id, layout }) => (
            <Widget key={ id }
                    reportDefinitionId={ report_definition_id }
                    layout={ layout } />
          ))
        }
      </ResponsiveReactGridLayout>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditView.defaultProps = {

};

EditView.propTypes = {
  data: PropTypes.shape({
    widgets: PropTypes.array
  }).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditView;
