import isString from 'lodash/isString';
import get      from 'lodash/get';

// -----------------------------------------------------
// Internal Functions
// -----------------------------------------------------


function normalize(value) {
  if(isString(value)) {
    return value.toUpperCase();
  } else if(Array.isArray(value)) {
    return value.map(v => normalize(v));
  }

  return value;
}

// -----------------------------------------------------
// External Functions
// -----------------------------------------------------

// Just a plain-old comparator.  On it's own, it's likely too naive to be useful.
// This mainly exists to support comparator composition via the higher-order
// functions found in this file.
export function basicComparator(a, b, direction = 1) {
  if(a < b) {
    return -1 * direction;
  } else if(a > b) {
    return 1 * direction;
  }
  return 0;
}

// Higher-order function for applying directionality to a sort comparator. A
// positive direction sorts in ascending order,  a negative in descending order,
// and a zero is a no-sort.
export function sortDirectionally(comparator, direction) {
  return (a, b) => {
    // short circuit to avoid comparison.  For a zero-direction, we do not
    // sort.
    if(direction === 0) {
      return 0;
    }

    return comparator(a, b) * direction;
  };
}

// Higher-order function for generating a sort comparator from an attribute path
// through an object graph.  See Lodash#get for information on path structure.
//
// Note: Due to the use of _.get, this function generates a comparator that is
// slower than comparators generated by #sortByAttributes(...attributes). Only
// use this if you need to sort on an attribute more than 1 layer deep in the
// object graph.
export function sortByAttributePath(path) {
  return (a, b) => {
    const attrA = normalize(get(a, path));
    const attrB = normalize(get(b, path));

    return basicComparator(attrA, attrB);
  };
}

// Higher-order function for generating a sort comparator on a set of object
// attributes.
export function sortByAttributes(...attributes) {
  return (a, b) => {
    const attrsA = attributes.map(attr => normalize(a[attr]));
    const attrsB = attributes.map(attr => normalize(b[attr]));

    return basicComparator(attrsA, attrsB);
  };
}

export function reverseSortByAttributes(...attributes) {
  return (a, b) => {
    const attrsA = attributes.map(attr => normalize(a[attr]));
    const attrsB = attributes.map(attr => normalize(b[attr]));

    return basicComparator(attrsA, attrsB, -1);
  };
}