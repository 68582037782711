import React, { Component }     from 'react';
import { NavLink }              from 'react-router-dom';
import { Menu, Sidebar, Icon }  from 'semantic-ui-react';

import Privileges                 from 'constants/Privileges';
import WithPermission             from 'containers/WithPermission';
import WithCaseWorthyAffiliation  from 'caseWorthy/components/WithCaseWorthyAffiliation';

class Nav extends Component {
  render() {
    return (
      <Sidebar as={ Menu } icon='labeled' visible vertical inverted>
        <WithPermission resource='dashboards'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/dashboard'>
            <Icon name='dashboard' />
            Dashboard
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='permission_groups'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/permissions'>
            <Icon name='lock' />
            Permissions
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='data_groups'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/data_groups'>
            <Icon name='folder open' />
            Data Groups
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='categories'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/categories'>
            <Icon name='folder' />
            Categories
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='users'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/users'>
            <Icon name='users' />
            Users
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='fields'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/fields'>
            <Icon name='terminal' />
            Fields
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='forms'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/forms'>
            <Icon name='file' />
            Forms
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='forms'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/sequences'>
            <Icon name='list ol' />
            Form Sequences
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='report_definitions'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/reports'>
            <Icon name='line chart' />
            Reports
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='clients'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/clients'>
            <Icon name='address card' />
            People
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='forms'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={NavLink} to='/organization/public_responses'>
            <Icon name='tasks' />
            Public Form Responses
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='internal_forms'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={ NavLink } to='/organization/internal_form_responses'>
            <Icon name='table' />
            Resources
          </Menu.Item>
        </WithPermission>
        <WithPermission resource='affiliations'
                        actions={ [Privileges.read, Privileges.write] }
                        anyRecord={ true }>
          <Menu.Item as={ NavLink } to='/organization/affiliations'>
            <Icon name='handshake' />
            Affiliations
          </Menu.Item>
        </WithPermission>
        <WithCaseWorthyAffiliation>
          <Menu.Item as={ NavLink } to='/organzation/caseworthy/referrals'>
            <Icon name='list layout' />
            DTD Referrals
          </Menu.Item>
        </WithCaseWorthyAffiliation>
      </Sidebar>
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default Nav;
