import createResource from '../utils/resource';

const invitations = createResource({
  name : 'invitations',
  selector : state => state.invitations,
  basepath : '/invitations/',
  initialState : null,
  actions : {
    get : {
      method : 'GET',
      path : '/:invite_id',
      response : (state, action) => action.payload
    },
    accept : {
      method : 'PUT',
      path : '/:invite_id',
      response : (state, action) => action.payload
    }
  }
});

export default invitations;
