import React          from 'react';
import PropTypes      from 'prop-types';
import { List, Icon, Label } from 'semantic-ui-react';
import moment         from 'moment';

import If             from 'components/conditionals/If';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function clientName({ first_name, middle_name, last_name }) {
  return [first_name, middle_name, last_name].filter(name => name).join(' ');
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ListItemView = ({ record }) => {
  const { id, gender, has_duplicates, dob } = record || {};

  return (
    <List.Header>
      <div>
        { clientName(record) }
        <If condition={ !!gender } container='span'>
          <div className='pull-right'>
            <small className='text-muted text-uppercase'>{ gender }</small>
          </div>
        </If>

        <If condition={ has_duplicates } container='span'>
          <div className='pull-right'>
            <Icon name='exclamation' color='yellow' />
          </div>
        </If>
      </div>

      <br/>

      <If condition={ !!dob } container='div'>
        <small className='text-muted'>
          <strong>DOB: </strong>
          { moment(dob).format('L') }
        </small>
      </If>
      <small className="text-muted">
        <strong>Client ID: </strong>
        { id }
      </small>

      {
        !record.active &&
        <List.Content floated='right'>
          <Label size='mini' color='grey'>
            Inactive
          </Label>
        </List.Content>
      }
    </List.Header>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ListItemView.defaultProps = {
  record: {}
};

ListItemView.propTypes = {
  record: PropTypes.object
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ListItemView;
