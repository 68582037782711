import React, { useContext }    from 'react';
import PropTypes                from 'prop-types';
import { Redirect }             from 'react-router-dom';

import useApiResource               from 'hooks/useApiResource';
import useOrganizationResource      from 'hooks/useOrganizationResource';
import UserAccountResource          from 'resources/UserAccountResource';
import OrganizationAccountResource  from 'resources/organization/OrganizationAccountResource';
import ImpersonationContext         from 'contexts/Impersonation';
import ApiCallBoundary              from 'components/api/ApiCallBoundary';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const defaultRedirect = '/organization/noaccess';

function intersects(a, b) {
  const normA = [].concat(a);
  const normB = [].concat(b);

  return normA.some(ae => normB.includes(ae));
}

function hasPermission( { entity_privileges={}, record_privileges={} },
                        resource,
                        actions,
                        recordId,
                        anyRecord) {

  // No resource to test, so we'll return true.
  if(resource === undefined || resource === null) {
    return true;
  }

  // If privileges are granted across all instances of an entity, return true.
  let privileges            = entity_privileges[resource] || [];
  const hasEntityPrivilege  = intersects(privileges, actions);

  if(hasEntityPrivilege) {
    return true;
  }

  // If anyRecord is true, we just need one of any of the record privileges,
  // for the resource, to contain the privileges specified by the actions.
  if(anyRecord) {
    const privilegesById        = record_privileges[resource] || {};
    privileges                  = [].concat(...Object.values(privilegesById));
    const hasAnyRecordPrivilege = intersects(privileges, actions);

    if(hasAnyRecordPrivilege) {
      return true;
    }
  }

  // If a recordId was specified, check the record_privileges
  if(recordId !== undefined && recordId !== null) {

    // Because record_privileges is a tree with a height of 3, we have to dig
    // a little deeper.  First we use the resource to get a hash, where keys
    // are record ids, and values are lists of privileges (actions)
    const privilegesById        = record_privileges[resource] || {};
    privileges                  = privilegesById[recordId] || [];
    const hasTheRecordPrivilege = intersects(privileges, actions);

    if(hasTheRecordPrivilege) {
      return true;
    }
  }

  // Default case
  return false;
}

// -----------------------------------------------------
// Sub-Component Definitions
// -----------------------------------------------------

const WithOrgPrivileges = ({  resource,
                              actions,
                              redirect,
                              recordId,
                              anyRecord,
                              children }) => {

  const organizationAccount =
    useOrganizationResource(OrganizationAccountResource.singletonDetailShape());

  const { privileges, owner } = organizationAccount;
  const { entity_privileges={},
          record_privileges={} } = privileges || {};

  // Owners have full access to their organizations functions.
  if(owner) {
    return children;
  }

  // If there are no privileges specified, show nothing.
  if(!privileges) {
    return null;
  }

  const permitted =
    hasPermission(privileges, resource, actions, recordId, anyRecord);

    // If the user has no privileges, redirect to noaccess view
  if( privileges
      && !Object.keys(entity_privileges).length
      && !Object.keys(record_privileges).length) {
    return (<Redirect to={ defaultRedirect }/>);
  }

  // If the user has permissions, show the view; otherwise, redirect to the
  // specified location, or if that does not exist, show nothing.
  return  (permitted && children) ||
          (redirect ? <Redirect to={redirect}/> : null);
};

const WithRoleOrPrivileges = ({ children, superUserOnly, ...forwardProps }) => {
  const userAccount =
    useApiResource(UserAccountResource.singletonDetailShape());

  const { impersonation }   = useContext(ImpersonationContext);
  const impersonatedUser    = impersonation && impersonation.user;

  // If we have no user account, show nothing.
  if(!userAccount) {
    return null;

  // If the user is not a superuser and it's a action for superuser only, show nothing
  } else if( userAccount.role !== 'superuser' && superUserOnly) {
    return null;

  // If the user is a superuser, show everything!
  } else if(userAccount.role === 'superuser' && !impersonatedUser) {
    return children;

  } else {
    return (
      <WithOrgPrivileges { ...forwardProps }>
        { children }
      </WithOrgPrivileges>
    );
  }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const WithPermission = ({ children, ...forwardProps }) => {
  return (
    <ApiCallBoundary>
      <WithRoleOrPrivileges { ...forwardProps }>
        { children }
      </WithRoleOrPrivileges>
    </ApiCallBoundary>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

WithPermission.defaultProps = {
  redirect:   null,
  recordId:   null,
  resource:   null,
  anyRecord:  false
};

WithPermission.propTypes = {
  redirect:   PropTypes.string,
  recordId:   PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resource:   PropTypes.string,
  anyRecord:  PropTypes.bool,
  actions:    PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default WithPermission;
