import React, { useRef }      from 'react';
import PropTypes              from 'prop-types';
import { Form, Button, Icon } from 'semantic-ui-react';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const dummyFileField = {
  click: _=>_,
  files: []
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Attachment = ({ ownLabel, value, name, onChange }) => {
  const fileInput = useRef(dummyFileField);

  const handleValueChange = (evt) => {
    const [ value = '__destroy__' ] = fileInput.current.files;

    onChange(evt, { name, value });
  };

  const handleAddClick = () => {
    fileInput.current.click();
  };

  const handleRemoveClick = (evt) => {
    fileInput.current.value = '';
    handleValueChange(evt);
  };

  const { filename: serverName, name: localName } = value || {};

  const fileName     = localName || serverName;
  const hasFile      = !!fileName;
  const noFileStyle  = (hasFile) ? { display: 'none' } : { display: 'inline-block' };
  const hasFileStyle = (hasFile) ? { display: 'inline-block' } : { display: 'none' };

  return (
    <Form.Field className='attachment-field'>
      { ownLabel && <label>{ ownLabel }</label> }
      <div className='attachment-container'>
        <input  type='file'
                ref={ fileInput }
                style={{ display: 'none' }}
                onChange={ handleValueChange } />

        <div className='attachment-control-group'>
          <Button basic type='button' size='mini' onClick={ handleAddClick }>Choose File</Button>
          <span style={ noFileStyle }>Not Provided</span>
          <span style={ hasFileStyle}>
            { fileName }
            <Icon name='remove' onClick={ handleRemoveClick } />
          </span>
        </div>
      </div>
    </Form.Field>
  );
};

//---------------------------------------------------------
// PropTypes
//---------------------------------------------------------

Attachment.defaultProps = {
  value:      { filename: '' },
  metadata:   {}
};

Attachment.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      url:        PropTypes.string,
      small_url:  PropTypes.string,
      large_url:  PropTypes.string
    }),
    PropTypes.instanceOf(File),
    PropTypes.string
  ]),

  metadata:   PropTypes.object,
  onChange:   PropTypes.func.isRequired
};


//---------------------------------------------------------
// Exports
//---------------------------------------------------------

export default Attachment;
