import AuthenticatedResource from 'resources/AuthenticatedResource';
import resourceUrl           from 'utils/resourceUrl';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ClientResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/clients/';

  // Override listUrl to append search params.
  static listUrl(searchParams) {
    const url = resourceUrl(this.urlRoot, '', searchParams);
    url.search = new URLSearchParams(searchParams);
    return url;
  }

  id                = undefined;
  first_name        = undefined;
  middle_name       = undefined;
  last_name         = undefined;
  dob               = undefined;
  dod               = undefined;
  gender            = undefined;
  ssn_suffix        = undefined;
  active            = undefined;
  photo_url         = null;
  completed_at      = null;
  created_at        = null;
  updated_at        = null;

  pk() {
    return this.id && this.id.toString();
  }
}
