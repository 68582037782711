import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import classNames           from 'classnames';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Item extends Component {
  constructor(props) {
    super(props);

    this.handleClick  = this.handleClick.bind(this);
  }

  render() {
    const { label, isActive } = this.props;
    const classes             = classNames('item', { active: isActive });

    return (
      <a  className={ classes }
          href='#select'
          onClick={ this.handleClick }>{ label }</a>
    );
  }

  handleClick(evt) {
    evt.preventDefault();

    const { value, onSelect } = this.props;
    onSelect({ value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Item.defaultProps = {
  value:    null,
  isActive: false
};

Item.propTypes = {
  value:    PropTypes.any,
  isActive: PropTypes.bool,
  label:    PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Item;
