import React                      from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header, List }  from 'semantic-ui-react';

import Privileges         from 'constants/Privileges';
import WithPermission     from 'containers/WithPermission';
import PrintLink          from 'components/PrintLink';
import PinnedMessages     from 'containers/organization/permissions/PinnedMessages';
import ReportViews        from 'containers/organization/reportDefinitions/ReportViewsContainer';
import ReportSideCard     from 'containers/organization/reportDefinitions/DetailView/ReportSideCard';
import Imports            from 'containers/organization/forms/ImportsContainer';
import AdminContainer            from 'containers/organization/forms/AdminContainer';
import RecordPermissions  from 'containers/organization/RecordPermissions';
import DataGroupRecords   from 'containers/organization/DataGroupRecords';
import CategoryRecords   from 'containers/organization/CategoryRecords';
import Notes from 'containers/Notes';
import Versions from 'containers/Versions';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const SideCardView = (props) => {
  const {
    importable,
    printable,
    groupable,
    categorizable,
    versioned,
    resource,
    resourcename: recordType,
    state,
    match: { params: { id: recordId, nestedId, nestedResource } } } = props;

  const versionId   = nestedResource === 'client_forms' ? nestedId : recordId;
  const versionType = nestedResource === 'client_forms' ? nestedResource : recordType;

  return (
    <div>
      <Segment>
        <Header as='h3' dividing>Actions</Header>
        <List>
          <WithPermission resource='permission_groups' actions={ Privileges.write }>
            <List.Item>
              <List.Icon name='lock' />
              <List.Content>
                <RecordPermissions  recordType={ recordType }
                                    recordId={ recordId } />
              </List.Content>
            </List.Item>
          </WithPermission>

          {
            groupable &&
            <WithPermission resource="data_groups" actions={ Privileges.write }>
              <List.Item>
                <List.Icon name='folder open'/>
                <List.Content>
                  <DataGroupRecords recordType={ recordType }
                                    recordId={ recordId } />
                </List.Content>
              </List.Item>
            </WithPermission>
          }

          {
            categorizable &&
            <WithPermission resource="categories" actions={ Privileges.use }>
              <List.Item>
                <List.Icon name='folder'/>
                <List.Content>
                  <CategoryRecords recordType={ recordType }
                                   recordId={ recordId } />
                </List.Content>
              </List.Item>
            </WithPermission>
          }


          {
            recordType === 'forms' &&
            <WithPermission superUserOnly={ true } actions={ [] } >
              <List.Item>
                <List.Icon name='copy outline' />
                <List.Content>
                  <AdminContainer formId={ recordId } />
                </List.Content>
              </List.Item>
            </WithPermission>
          }

          {
            recordType === 'permission_groups' &&
            <WithPermission resource='permission_groups' actions={ Privileges.write }>
              <List.Item>
                <List.Icon name='pin map' />
                <List.Content>
                  <PinnedMessages recordType={ recordType }
                                  recordId={ recordId } />
                </List.Content>
              </List.Item>
            </WithPermission>
          }

          {
            recordType === 'report_definitions' &&
            <ReportViews recordId={ recordId }
                         history={ props.history } />
          }

          {
            importable &&
            <WithPermission resource={ resource } actions={ Privileges.write }>
              <Imports  recordType={ recordType }
                        recordId={ recordId }
                        state={ state } />
            </WithPermission>
          }

          {
            printable &&
            <List.Item>
              <List.Icon name='print' />
              <List.Content>
                <PrintLink />
              </List.Content>
            </List.Item>
          }
        </List>
      </Segment>

      { recordType === 'report_definitions' && nestedId &&
        <ReportSideCard reportId={ recordId }
                        viewId={ nestedId } />
      }

      <WithPermission resource='notes'
                      actions={ [Privileges.read, Privileges.write] }>
        <Notes notableId={ recordId } notableType={ recordType } />
      </WithPermission>

      {
        versioned &&
        <Versions recordId={ versionId } recordType={ versionType } />
      }
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SideCardView.propTypes = {
  resourcename: PropTypes.string.isRequired,
  match:        PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SideCardView;
