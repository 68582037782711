import 'url-search-params-polyfill';

import React                from 'react';
import ReactDOM             from 'react-dom';
import { Provider }         from 'react-redux';
import { BrowserRouter }    from 'react-router-dom';
import { CacheProvider }    from 'rest-hooks';
import { store }            from './store/configuredStore';

import App from 'components/App';

import 'semantic-ui-css/semantic.min.css';
import './style/index.css';
import './style/print.css';

const ConnectedApp = (
  <Provider store={store}>
    <CacheProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CacheProvider>
  </Provider>
);

ReactDOM.render(ConnectedApp, document.getElementById('root'));
