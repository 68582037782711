import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { List }             from 'semantic-ui-react';

import ScrollPane           from 'components/ScrollPane';

import './ResultsList.css';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const fullAddress = (result) => (
  `${result.address} ${result.address2}, ${result.zip_code}`.trim()
);

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const ResultsList = ({ results, onSelect, onPageNext }) => {
  const [selected, setSelected] = useState();

  const handleSelect = (evt, result) => {
    evt.preventDefault();
    setSelected(result);
    onSelect(result);
  };

  const handleScroll = (evt) => {
    const scroller = evt.target;

    const { clientHeight, scrollTop, scrollHeight } = scroller;
    if (scrollHeight - scrollTop <= clientHeight) {
      onPageNext();
    }
  };

  return (
    <ScrollPane onScrollY={ handleScroll } styleOverrides={{ height: '50vh' }}>

      <List divided className='search-results'>
        {
          results.map(r => (
            <List.Item  key={ `${r.provider_id}-${r.program_id}` }
                        as='a'
                        onClick={ (e) => handleSelect(e, r) }
                        className='result'
                        active={ selected == r }>

              <List.Content>
                <List.Header>
                  { r.title }
                </List.Header>
                <List.Description>
                  <div>{ fullAddress(r) }</div>
                  <div>{ r.description }</div>
                </List.Description>
              </List.Content>
            </List.Item>
          ))
        }
      </List>
    </ScrollPane>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

ResultsList.defaultProps  = {
  results: []
};

ResultsList.propTypes     = {
  results:    PropTypes.array,
  onSelect:   PropTypes.func.isRequired,
  onPageNext: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ResultsList;