import React, { useState }            from 'react';
import PropTypes                      from 'prop-types';
import { Container, Checkbox, Form }  from 'semantic-ui-react';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const style = {
  height:         '100%',
  display:        'flex'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CreateClientCheckbox = ({ client, onChange, onSubmit }) => {
  const [checked, setChecked]     = useState(false);
  const [params, setParams]       = useState({});

  const { first_name, last_name } = client;
  const fullName                  = `${first_name} ${last_name}`;

  const handleCheckboxChange = (evt, { checked }) => {
    evt.preventDefault();
    setChecked(checked);
    onChange(evt, { checked, params });
  };

  const handleChange = (evt, { name, value }) => {
    if(checked) {
      const nextParams = {
        ...params,
        [name]: value
      };

      setParams(nextParams);
      onChange(evt, { checked, params: nextParams });
    }
  };

  return (
    <Container style={ style }>
      <div>
        <h4>
          No record could be found for <u>{fullName}</u> in the DTD system.
        </h4>
        <Checkbox onChange={ handleCheckboxChange }
                  label={{
                    children: <span>
                                Create a DTD Record for <u>{fullName}</u>
                              </span>
                  }} />

        {
          checked
          && (
            <Form style={{ marginTop: '2rem' }} onSubmit={ onSubmit }>
              <Form.Field>
                <label>Email</label>
                <Form.Input name='email' type='email' onChange={ handleChange } />
              </Form.Field>

              <Form.Field>
                <label>Cell Phone</label>
                <Form.Input name='cell_phone' onChange={ handleChange } />
              </Form.Field>

              <Form.Field>
                <label>Home Phone</label>
                <Form.Input name='home_phone' onChange={ handleChange } />
              </Form.Field>

              <Form.Field>
                <label>Work Phone</label>
                <Form.Input name='work_phone' onChange={ handleChange } />
              </Form.Field>
            </Form>
          )
        }
      </div>
    </Container>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CreateClientCheckbox.defaultProps = {

};

CreateClientCheckbox.propTypes = {
  client:   PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CreateClientCheckbox;
