import React, { useEffect }     from 'react';
import PropTypes                from 'prop-types';
import BaseField                from 'components/forms/Field';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const CLEARED_VALUE = null;

function filterOptions({ filters }) {
  return this.filterPayload.every(filterValue => filters.includes(filterValue));
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function deriveOptions(metadata={}, field_type, field_options, internal_options, filterPayload) {
  return showInternalOptions(metadata, field_type) ?
    filteredInternalOptions(internal_options, filterPayload) :
    field_options.map(({ label, value }) => ({ text: label, value }));
}

function showInternalOptions(metadata, field_type){
  return field_type === 'autopopulate' ||
        (!!metadata && Object.keys(metadata).length !== 0 && metadata['options_source'] === 'internal');
}

function filteredInternalOptions(internal_options, filterPayload) {
  return  internal_options
          .filter(filterOptions, { filterPayload })
          .map(({ value }) => value)
          .filter(onlyUnique)
          .map( (value) => ({ text: value, value }));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Field = ({  field={},
                  answer={},
                  answers=[],
                  error,
                  onChange,
                  displayState: {
                    set,
                    setPayload,
                    filterPayload=[],
                    clear,
                    hide=false,
                    require,
                    lock=false
                  } }) => {


    const { id,
            field_type,
            data_type,
            is_required,
            name,
            description,
            metadata,
            parameters_mapping,
            field_options=[],
            internal_options=[],
            settings={} } = field;

    const handleChange = (evt, { value, error }) => {
      const nextAnswer = { ...answer, field_id: id, value };

      onChange(evt, { field, answer: nextAnswer, error });
    };

    const { value=CLEARED_VALUE } = answer;

    useEffect(() => {
      if(!clear && set && setPayload && setPayload !== value) {
        handleChange(null, { value: setPayload, error: false });
      }

      if(clear && !set && value !== CLEARED_VALUE) {
        handleChange(null, { value: CLEARED_VALUE, error: false });
      }
    });

    const options = deriveOptions(metadata, field_type, field_options, internal_options, filterPayload);

    if(settings['hide'] || hide) {
      return <></>;
    }

    // if exists, require by logic has priority over field settings
    const isRequired  = require !== undefined ? require : is_required;

    return (
      <BaseField  label={ name }
                  description={ description }
                  options={ options }
                  fieldType={ field_type }
                  dataType={ data_type }
                  isRequired={ isRequired }
                  isDisabled={ lock }
                  hasError={ error }
                  value={ value }
                  parametersMapping={ parameters_mapping }
                  answers={ answers }
                  fieldId={ id }
                  metadata={ metadata || undefined }
                  settings={ settings }
                  onChange={ handleChange } />
    );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Field.defaultProps = {
  field:        {},
  displayState: {},
  answer:       { value: '' },
  answers:      {},
  error:        false
};

Field.propTypes = {
  field: PropTypes.shape({
    field_type:   PropTypes.string,
    is_required:  PropTypes.bool,
    name:         PropTypes.string,
    description:  PropTypes.string,
    metadata:     PropTypes.object
  }),

  displayState: PropTypes.object,

  answer: PropTypes.shape({
    value:  PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.array
    ])
  }),
  answers:      PropTypes.object,
  error:        PropTypes.bool,
  onChange:     PropTypes.func.isRequired
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default Field;
