import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Form, Select }               from 'semantic-ui-react';

import {  intervals }       from 'constants/Calculations';
import indexVariablesByName from 'utils/calculations/indexVariablesByName';
import fieldOptions         from 'utils/fields/dateFieldOptions';

import {fieldVariableSetter,
        intervalParameterVariableSetter } from 'utils/calculations/variableSetters';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const fieldVariableNameMap  = {
  'record_id':   'a',
  'magnitude':  'b',
  'unit':       'b'
};

const equation = 'a + b';

const variableSetters = {
  a: fieldVariableSetter,
  b: intervalParameterVariableSetter
};

function variableFor(variables, formFieldName) {
  const variablesIndex  = indexVariablesByName(variables);
  const variableName    = fieldVariableNameMap[formFieldName];

  return variablesIndex[variableName] || { name: variableName };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class TimeFromPanel extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { fields, calculation, calculationType } = this.props;
    const { variables }           = calculation || {};

    const variableA = variableFor(variables, 'record_id');
    const variableB = variableFor(variables, 'magnitude');

    const recordId                          = variableA['record_id'];
    const { value: { unit, magnitude }={} } = variableB;

    return (
      <Fragment>
        <p>
          Calculates a new date that is some distance from the selected&nbsp;
          <strong>Date</strong>, where the distance is specified using the&nbsp;
          <strong>Time From</strong> value.
          <br />
          To calculate a date in the past, use a negative value in the&nbsp;
          <strong>Time From</strong> field.  To calculate a date in the future,&nbsp;
          use a positive value.
        </p>

        <Form.Field>
          <label>Date</label>
          <Select options={ fieldOptions(calculationType, fields) }
                  name='record_id'
                  value={ recordId }
                  selectOnBlur={ false }
                  onChange={ this.handleChange } />
        </Form.Field>

        <Form.Field>
          <label>Time From</label>
          <Form.Group>
            <Form.Input type='number'
                        name='magnitude'
                        value={ magnitude }
                        onChange={ this.handleChange } />

            <Select options={ intervals[calculationType] }
                    name='unit'
                    value={ unit }
                    selectOnBlur={ false }
                    onChange={ this.handleChange } />

          </Form.Group>
        </Form.Field>
      </Fragment>
    );
  }

  handleChange(evt, { name, value }) {
    const { calculation, onChange } = this.props;
    const { variables }             = calculation || {};
    const variable                  = variableFor(variables, name);
    const variableName              = fieldVariableNameMap[name];
    const variableSetter            = variableSetters[variableName];
    const nextVariable              = variableSetter(variable, name, value);

    const nextVariables = Object.values({
      ...indexVariablesByName(variables),
      [variableName]: nextVariable
    });

    const nextCalculation = {
      ...calculation,
      equation,
      variables: nextVariables
    };

    onChange({ calculation: nextCalculation });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

TimeFromPanel.defaultProps = {
  loading:      false,
  calculation:  {}
};

TimeFromPanel.propTypes = {
  loading:    PropTypes.bool,
  calculation:  PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    equation:   PropTypes.string,
    variables:  PropTypes.array
  }),

  fields:     PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    data_type:  PropTypes.string
  })).isRequired,
  onChange:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default TimeFromPanel;
