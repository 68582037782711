import React, { useState }             from 'react';
import PropTypes                       from 'prop-types';
import { Modal, Button, Header, Form, Dropdown } from 'semantic-ui-react';

import DateSettings from './AdvancedSettings/Date';
import PrePopulate      from './AdvancedSettings/PrePopulate';
import uniqueId from 'utils/uniqueId';
import { isEqual } from 'lodash';

// -----------------------------------------------------
// Internal Functions
// -----------------------------------------------------

const formId = uniqueId();

const clientFieldOptions = [
  { text: 'First Name', value: 'first_name' },
  { text: 'Middle Name', value: 'middle_name' },
  { text: 'Last Name', value: 'last_name' },
  { text: 'Date of Birth', value: 'dob' },
  { text: 'Gender', value: 'gender' },
  { text: 'SSN', value: 'ssn_suffix' },
];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const SettingsModal = ({  title,
                          opened,
                          onClose,
                          onSubmit,
                          formField,
                          formFields,
                          allFields }) => {

  const { metadata, field_type,
          parameters_mapping, settings }  = formField;

  const [parametersChanges, setParameters] = useState(parameters_mapping || {});
  const [settingsChanges, setSettings]    = useState(settings || {});
  const apiParameters                     = metadata?.api_parameters;

  const fullTitle = `${title}: ${formField.name}`;

  const formFieldOptions  = formFields.map(({ name, id }) => ({
                              text: name === formField.name ? `${name} (self)` : name,
                              value: id
                            }));

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!isEqual(settings, settingsChanges)) {
      onSubmit(evt, { name: 'settings', value: settingsChanges });
    }
    if (apiParameters !== null && apiParameters !== undefined && !isEqual(parameters_mapping, parametersChanges)) {
      onSubmit(evt, { name: 'parameters_mapping', value: parametersChanges });
    }
    onClose();
  };

  const handleCheckboxChange = (evt, { name, checked }) => {
    const nextSettingsChanges = { ...settingsChanges };

    if(checked === false) {
      delete nextSettingsChanges[name];
    } else {
      nextSettingsChanges[name] = checked;
    }

    setSettings(nextSettingsChanges);
  };

  const handleSettingsChange = (nextSettingsChanges) => {
    setSettings(nextSettingsChanges);
  };

  const handleParametersChange = (evt, { name, value }) => {
    const nextChanges = { ...parametersChanges };

    if(value === '' || value === null || value === undefined) {
      delete nextChanges[name];
    } else {
      nextChanges[name] = value;
    }

    setParameters(nextChanges);
  };

  return (
    <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
      <Header icon='setting' content={ fullTitle } />
      <Modal.Content>
        <Form onSubmit={ handleSubmit } id={ formId }>
          <h4>Defaults</h4>
          <Form.Checkbox  label='Hide field by default'
                    name='hide'
                    onChange={ handleCheckboxChange }
                    checked={ settingsChanges.hide || false } />


        <h4>Mappings</h4>
          <PrePopulate settings={ settingsChanges } allFields={ allFields } onChange={ handleSettingsChange } />

          <Form.Field>
            <label>Map to Client field</label>
          <Dropdown placeholder='Select Field'
                    fluid
                    selection
                    clearable
                    options={ clientFieldOptions }
                    value={ settingsChanges.client_field || '' }
                    onChange={ (evt, { value }) => handleSettingsChange({ ...settingsChanges, client_field: value }) }
                    />
          </Form.Field>

          {
            field_type === 'date' &&
            <DateSettings settings={ settingsChanges }
                                  onChange={ handleSettingsChange } />
          }

          {
            ( apiParameters !== null && apiParameters !== undefined) &&
            <>
              <h4>Assign API Parameters</h4>
              <p>
                Each of the following parameters must be mapped to a field in
                this form.  These parameters are sent to the API
                endpoint with values from the selected fields.
              </p>
              {
                apiParameters.map(param => (
                  <Form.Field key={ param }>
                    <label>{param}</label>
                    <Form.Select  value={ parametersChanges[param] }
                                  name={ param }
                                  options={ formFieldOptions }
                                  selectOnBlur={ false }
                                  clearable
                                  search
                                  onChange={ handleParametersChange } />

                  </Form.Field>
                ))
              }
            </>
          }
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={ onClose } negative basic>Cancel</Button>
        <Button type='submit'
                onClick={ handleSubmit }
                form={ formId }
                positive
                basic>Ok</Button>
      </Modal.Actions>
    </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SettingsModal.defaultProps = {

};

SettingsModal.propTypes = {
  title:                  PropTypes.string.isRequired,
  opened:                 PropTypes.bool.isRequired,
  onClose:                PropTypes.func.isRequired,
  formField:              PropTypes.object.isRequired,
  formFields:             PropTypes.array.isRequired,
  allFields:              PropTypes.array.isRequired,
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SettingsModal;
