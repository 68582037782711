import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table }            from 'semantic-ui-react';

import HeaderCell           from './HeaderCell';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Header extends Component {

  render() {
    const { actions, entities, privileges, disabled, onChange } = this.props;

    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          {
            actions.map((action) => {
              return <HeaderCell  key={ action }
                action={ action }
                entities={ entities }
                privileges={ privileges }
                disabled={ disabled }
                onChange={ onChange } />;
            })
          }
        </Table.Row>
      </Table.Header>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Header.propTypes = {
  actions:    PropTypes.arrayOf(PropTypes.string).isRequired,
  entities:   PropTypes.arrayOf(PropTypes.string).isRequired,
  privileges: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired,
  disabled:   PropTypes.bool,
  onChange:   PropTypes.func
};

Header.defaultProps = {
  disabled: true,
  onChange: () => {}
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Header;
