import AuthenticatedResource from 'resources/AuthenticatedResource';
import resourceUrl from 'utils/resourceUrl';

export default class UserResource extends AuthenticatedResource {
  static urlRoot = '/admin/users';

  // Override listUrl to append search params.
  static listUrl(searchParams) {
    const url = resourceUrl(this.urlRoot, '', searchParams);
    url.search = new URLSearchParams(searchParams);
    return url;
  }


  id              = undefined;
  email           = undefined;
  first_name      = undefined;
  last_name       = undefined;
  role            = undefined;
  organization_id = undefined;
  created_at      = undefined;
  updated_at      = undefined;
 
  pk() {
    return this.id && this.id.toString();
  }
}