import React, { Fragment, Component } from 'react';
import { Button, Popup }              from 'semantic-ui-react';
import FormModal                      from './FormModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Create new publish settings.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class CreateButton extends Component {
  state = {
    opened: false
  };

  constructor(props) {
    super(props);

    this.handleOpen   = this.handleOpen.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  render() {
    const { opened } = this.state;

    const Btn = <Button icon='add'
                        size='mini'
                        basic
                        color='blue'
                        onClick={ this.handleOpen } />;

    return (
      <Fragment>
        <FormModal  title={ title }
                    opened={ opened }
                    onClose={ this.handleClose }
                    { ...this.props } />

        <Popup  trigger={ Btn }
                content={ title }
                position='top center' />
      </Fragment>
    );
  }

  handleOpen(evt) {
    evt.preventDefault();
    this.setState({ opened: true });
  }

  handleClose(evt) {
    evt && evt.preventDefault();
    this.setState({ opened: false });
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CreateButton;
