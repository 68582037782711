import React, { Component } from 'react';

import {  Header,
          Segment,
          List,
          Message,
          Icon,
          Divider } from 'semantic-ui-react';

import { Redirect } from 'react-router-dom';

import connectResource  from 'utils/connectResource';
import {  getById,
          getAll,
          getSingleton } from 'utils/connectors';
import Privileges        from 'constants/Privileges';
import WithPermission    from 'containers/WithPermission';
import formattedDate     from 'utils/formattedDate';

import {  users,
          permission_groups,
          organizationAccount } from 'resources/organizationResources';

// import ImpersonationButton  from './ImpersonationButton';
import ArchiveButton        from './ArchiveButton';
import DataList             from 'components/DataList';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const ownerBadgeStyle = {
  display:      'inline-block',
  textAlign:    'center',
  fontSize:     '75%'
};

function OwnerBadge() {
  return (
    <div style={ ownerBadgeStyle }>
      <Icon name='chess queen'
            color='yellow'
            size='big'
            style={{ margin: '0px' }} />

      <div>Owner</div>
    </div>
  );
}

function getUserDetails({ email, created_at, updated_at, last_accessed_at, time_zone }) {

  const emailLink = (
    <a href={`mailto:${email}`} target='blank'>{email}</a>
  );

  const basicDetails = [ { label: 'Email', value: emailLink } ];

  if(created_at) {
    basicDetails.push({ label: 'Created at', value: formattedDate(created_at) });
  }
  if(updated_at) {
    basicDetails.push({ label: 'Updated at', value: formattedDate(updated_at) });
  }
  if(last_accessed_at) {
    basicDetails.push({ label: 'Last accessed at', value: formattedDate(last_accessed_at) });
  }
  if(time_zone) {
    basicDetails.push({ label: 'Time Zone', value: time_zone });
  }

  return basicDetails;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class DetailView extends Component {

  render() {
    const { data, loading, api_errors, history } = this.props;

    const user                          = data.user                 || {};
    const organizationAccount           = data.organizationAccount  || {};
    const permissionGroups              = data.permissionGroups     || {};

    const { permission_group_ids=[],
            first_name,
            last_name,
            id } = user;

    const isOrgOwner = organizationAccount.owner_id === id;
    const dataListItems = getUserDetails(user);
    return (
      <Segment loading={ loading }>
        {
          api_errors && <Redirect to='./'/>
        }

        <div style={{ clear: 'both', display: 'table', width: '100%' }}>
          {/* <ImpersonationButton  impersonatedUserId={ id }
                                floated='right'
                                history={ history } /> */}

          <WithPermission resource='users'
                          recordId={ id }
                          actions={ Privileges.write }
                          anyGroup={ true }>
            <ArchiveButton archivedUserId={ id }
                          history={ history }  />
          </WithPermission>

          <Header as='h2' floated='left'>
            { first_name} { last_name }
          </Header>

          {
            isOrgOwner &&
            <OwnerBadge />
          }
        </div>

        <DataList items={ dataListItems } />

        {
          !isOrgOwner &&
          <div>
            <Divider />
            <Header as='h3'>Permission Groups</Header>

            {
              user && permission_group_ids.length === 0 &&
              <Message warning>
                This user belongs to no permission groups.
              </Message>
            }

            {
              !!permission_group_ids.length &&
              <List bulleted>
                {
                  permission_group_ids.map((groupId) => (
                    <List.Item key={ groupId }>
                      { (permissionGroups[groupId] || {}).name }
                    </List.Item>
                  ))
                }
              </List>
            }
          </div>
        }
      </Segment>
    );
  }
}

export default connectResource(DetailView, {
  connectors: {
    user:                 getById(users, ({ match: { params: { id } } }) => id),
    permissionGroups:     getAll(permission_groups),
    organizationAccount:  getSingleton(organizationAccount),
  }
});
