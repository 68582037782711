import React                      from 'react';
import PropTypes                  from 'prop-types';
import { Form, Segment, Header }  from 'semantic-ui-react';

import useApiResource from 'hooks/useApiResource';
import FormResource   from 'resources/admin/FormResource';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const componentKey = 'referral_form_field_mappings';

const referralParams = [
  'refer_from_provider_id',
  'refer_to_provider_id',
  'refer_to_program_id',
  'service_type_id',
  'referral_reason',
  'note'
];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ReferralFormFieldsMapping = ({ settings, onChange }) => {
  const { template_referral_form_id: formId,
          referral_form_field_mappings: mappings={} } = settings;


  const form = useApiResource(FormResource.detailShape(), { id: formId });
  const formFieldOptions  = form
                            .form_fields
                            .map(({ id, name }) => ({
                              text:   name,
                              value:  id,
                              key:    id
                            }));

  const handleChange = (evt, { name, value }) => {
    onChange(evt, {
      name: componentKey,
      value: {
        ...settings[componentKey],
        [name]: value
      }
    });
  };

  return (
    <Segment>
      <Header as='h3'>Referral Form Field Mappings</Header>

      {
        referralParams.map(param => (
          <Form.Select  key={ param }
                        name={ param }
                        label={ param }
                        value={ mappings[param] }
                        options={ formFieldOptions }
                        selectOnBlur={ false }
                        search
                        onChange={ handleChange } />
        ))
      }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ReferralFormFieldsMapping.defaultProps = {
  settings: {}
};

ReferralFormFieldsMapping.propTypes = {
  settings: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ReferralFormFieldsMapping;
