import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import { Modal, Button, Header, Form }  from 'semantic-ui-react';

import NumericEditor                                from './NumericEditor';
import DateEditor                                   from './DateEditor';
import uniqueId                                     from 'utils/uniqueId';
import { numericType, dateType,
         timeType, calculationTypes }   from 'constants/Calculations';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const initialState = { changes: {} };
const dateTypes = [dateType, timeType];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormModal extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.formId       = uniqueId();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  render() {
    const { opened,
            loading,
            fields,
            calculations,
            title,
            calculation,
            calculationType } = this.props;

    const { changes }             = this.state;
    const calculationWithChanges  = { ...calculation, ...changes };

    return (
      <Modal open={ opened } onClose={ this.handleClose } dimmer='inverted' closeIcon>
        <Header icon='pencil' content={ title } />
        <Modal.Content>
          <Form onSubmit={ this.handleSubmit }
                loading={ loading }
                id={ this.formId }>

            {
              calculationType === numericType &&
              <NumericEditor  loading={ loading }
                              calculation={ calculationWithChanges }
                              fields={ fields }
                              calculations={ calculations }
                              onChange={ this.handleChange } />
            }

            {
              dateTypes.includes(calculationType) &&
              <DateEditor loading={ loading }
                          calculation={ calculationWithChanges }
                          calculationType={ calculationType }
                          fields={ fields }
                          onChange={ this.handleChange } />
            }

          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ this.handleClose } negative basic>Cancel</Button>
          <Button type='submit' form={ this.formId } positive basic>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const { onSubmit, calculation, calculationType }  = this.props;
    const { changes }                                 = this.state;

    const typedCalculation = {
      calculation_type: calculationType,
      ...calculation,
      ...changes
    };

    onSubmit({ calculation: typedCalculation }, this.handleClose);
  }

  handleChange({ calculation }) {
    const { changes } = this.state;
    this.setState({ changes: { ...changes, ...calculation } });
  }

  handleClose() {
    const { onClose } = this.props;
    onClose();
    this.setState(initialState);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormModal.defaultProps = {
  opened:       false,
  loading:      false,
  calculation:  {}
};

FormModal.propTypes = {
  opened:     PropTypes.bool,
  index:      PropTypes.number,
  loading:    PropTypes.bool,

  calculation:  PropTypes.shape({
    id:               PropTypes.number,
    name:             PropTypes.string,
    equation:         PropTypes.string,
    variables:        PropTypes.array,
    calculation_type: PropTypes.string
  }),

  title:      PropTypes.string,
  fields:     PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    data_type:  PropTypes.string
  })).isRequired,

  calculationType:  PropTypes.oneOf(calculationTypes),
  onSubmit:         PropTypes.func.isRequired,
  onClose:          PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormModal;
