import React          from 'react';
import { Button }     from 'semantic-ui-react';

import Privileges     from 'constants/Privileges';
import WithPermission from 'containers/WithPermission';

import RecordPermissions  from 'containers/organization/RecordPermissions';
import EditButton         from './EditButton';

const InstanceControls = ({clientId, clientFormId}) => (
  <Button.Group floated='right' size='mini' >
    <WithPermission resource='client_forms' actions={ Privileges.write }>
      <EditButton clientId={ clientId }
        clientFormId={ clientFormId } />
    </WithPermission>

    <WithPermission resource='permission_groups' actions={ Privileges.write }>
      <RecordPermissions  presentation='button'
                          recordType='client_forms'
                          recordId={ clientFormId } />

    </WithPermission>
  </Button.Group>
);

export default InstanceControls;
