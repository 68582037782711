import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';
import { List }             from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

const Item = (props) => {
  const { record }                  = props;
  const { organization_name, name } = { ...record };
  const styles                      = { marginTop: '0.33em' };
  
  return (
    <Fragment>
      <List.Header>{ organization_name }</List.Header>
      <List.Description style={ styles }>{ name }</List.Description>
    </Fragment>
  );
};

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

Item.propTypes = {
  record: PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default Item;
