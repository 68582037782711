import React                from 'react';
import PropTypes            from 'prop-types';
import { Header, Segment }  from 'semantic-ui-react';

import DataList             from 'components/DataList';
import { contentNamespace } from 'utils/content';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const fieldContent = contentNamespace('fields');

function infoList({ field_type, data_type, metadata, field_options }) {
  const { labels: fieldTypeLabels }         = fieldContent('fieldTypes');
  const { labels: dataTypeLabels }          = fieldContent('dataTypes');

  const list = [
    { label: 'Field Type',  value: fieldTypeLabels[field_type] || field_type },
    { label: 'Data Type',   value: dataTypeLabels[data_type] || data_type }
  ];

  if(metadata !== null && metadata !== undefined) {
    const { type_labels: metadataTypeLabels } = fieldContent('metadata');
    const metadataList = Object.entries(metadata).map(([typeLabel, typeValue]) => (
      {
        label:  metadataTypeLabels[typeLabel] || typeLabel,
        value:  typeValue
      }
    ));

    const options = (field_options !== null && field_options.length) ?
                    {
                      label:  'Field Options',
                      value:  field_options.map( ({label}) => label )
                    } :
                    null;

    return [...list, ...metadataList, options].filter(n => n);
  }

  return list;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FieldInfo = ({ field, loading }) => {
  const { name, description } = field;
  const items                 = infoList(field);

  return (
    <Segment loading={ loading }>
      <Header as='h3'>{ name }</Header>
      <p>{ description }</p>
      <DataList items={ items } />
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FieldInfo.defaultProps = {
  loading: false
};

FieldInfo.propTypes = {
  loading:      PropTypes.bool,
  field:        PropTypes.shape({
    name:         PropTypes.string,
    field_type:   PropTypes.string,
    data_type:    PropTypes.string,
    description:  PropTypes.string,
    mask:         PropTypes.string,
    metadata:     PropTypes.object
  }).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldInfo;
