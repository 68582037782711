import React          from 'react';
import {  Grid,
          Segment,
          Header,
          Divider,
          Form }      from 'semantic-ui-react';
import passwordReset  from '../../resources/passwordResource';
import {connect}      from 'react-redux';

const style = {
  grid: {
    'height': '100%'
  }
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: false,
      submitted: false
    };

    this.handleChange  = this.handleChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
  }

  render() {
    const { submitted, error, email } = this.state;

    return (
      <Grid verticalAlign='middle' centered columns='3' style={ style.grid }>
        <Grid.Row>
          <Grid.Column>
            <Header as='h1' textAlign='center'>CoactionNet</Header>
            <Segment >
              <Header>Forgot Password</Header>
              <Divider />
              {
                !submitted &&
                <Form onSubmit={ this.handleSubmit }>
                  <Form.Input id='forgotPassword-email'
                              error={ error }
                              label='Email'
                              name='email'
                              value={ email }
                              placeholder='e.g. john@example.com'
                              onChange={ this.handleChange } />

                  <Form.Button  type='submit'
                                primary
                                disabled={ error }>Submit</Form.Button>
                </Form>
              }
              {
                submitted &&
                <div>a reset email has been sent to { email }</div>
              }
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  handleChange({ target: { value } }) {
    this.setState({ error: false, email: value });
  }

  async handleSubmit(evt) {
    evt.preventDefault();

    const { create }  = this.props;
    const { email }   = this.state;

    const response = await create({ email });

    if(!response.errors) {
      this.setState({ submitted: true });
    }
  }
}

export default connect(()=>({}), { ...passwordReset.actions })(ForgotPassword);
