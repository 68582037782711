import React, { Component }   from 'react';
import ManageViewsModal       from './ManageViewsModal';
import connectResource        from 'utils/connectResource';
import { getById }            from 'utils/connectors';
import { reportDefinitions,
         reportViews }        from 'resources/organizationResources';

const anchor = '#manage-views-modal';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class ManageViews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened:   false
    };

    this.handleOpenModal  = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleUpdateDefaultView = this.handleUpdateDefaultView.bind(this);
    this.handleDeleteView        = this.handleDeleteView.bind(this);
  }

  render() {
    const { opened }  = this.state;
    const { data, recordId, history }    = this.props;
    const reportDefinition     = data.reportDefinition || {};

    return (
      <React.Fragment>
        <ManageViewsModal opened={ opened }
                  reportId={ recordId }
                  defaultViewId={ reportDefinition.default_view_id }
                  views={ reportDefinition.report_views } 
                  onClose={ this.handleCloseModal }
                  onChange={ this.handleUpdateDefaultView }
                  onDelete={ this.handleDeleteView }
                  history={history} />
        <a onClick={ this.handleOpenModal } href={ anchor } >Manage Views</a>
      </React.Fragment>
      );
  }

  handleOpenModal() {
    this.setState({ opened: true });
  }

  handleCloseModal() {
    this.setState({ opened: false });
  }

  async handleUpdateDefaultView(newDefaultViewId) {
    const { updateReportDefinition, recordId } = this.props;
    await updateReportDefinition({default_view_id: newDefaultViewId}, {id: recordId});
  }

  async handleDeleteView(viewId) {
    const { deleteView, recordId } = this.props;
    await deleteView(null, {id: viewId, report_id: recordId});
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default connectResource(ManageViews, {
  key: ({ recordId }) => recordId,
  connectors: {
    reportDefinition: getById(reportDefinitions, ({ recordId }) => recordId)
  },
  mapDispatchToProps: {
    updateReportDefinition: reportDefinitions.actions.update,
    deleteView: reportViews.actions.destroy
  }
});