import PropTypes            from 'prop-types';

import useApiResource       from 'hooks/useApiResource';
import UserAccountResource  from 'resources/UserAccountResource';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const WithSuperuserRole = ({ children, when }) => {
  const userAccount =
    useApiResource(UserAccountResource.singletonDetailShape());

  if(!when) {
    return children;
  }

  if(userAccount.role === 'superuser') {
    return children;
  } else {
    return null;
  }
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

WithSuperuserRole.defaultProps = {
  when: true
};

WithSuperuserRole.propTypes = {
  when: PropTypes.bool
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default WithSuperuserRole;
