import React      from 'react';
import PropTypes  from 'prop-types';
import { Form }   from 'semantic-ui-react';

import connectResource      from 'utils/connectResource';
import { getAll }           from 'utils/connectors';
import { sortByAttributes } from 'utils/sortComparators';
import { dataGroups }       from 'resources/organizationResources';

function computeOptions(dataGroups={}, selectedDataGroupIds, value) {
  if(dataGroups === null) {
    return [];
  }

  return  Object.values(dataGroups)
                .filter(({ id }) => !selectedDataGroupIds.includes(id) || id === value)
                .sort(sortByAttributes('path_name'))
                .map(({ id, path_name }) => ({ text: path_name, value: id }));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DataGroupSelector = ({ name, value, data, selectedDataGroupIds, onChange }) => {

  const { dataGroups }  = data;
  const options         = computeOptions(dataGroups, selectedDataGroupIds, value);

  return (
    <Form.Select  value={ value }
                  name={ name }
                  onChange={ onChange }
                  selectOnBlur={ false }
                  search
                  options={ options } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DataGroupSelector.defaultProps = {

};

DataGroupSelector.propTypes = {
  name:                 PropTypes.string.isRequired,
  value:                PropTypes.number,
  onChange:             PropTypes.func.isRequired,
  selectedDataGroupIds: PropTypes.arrayOf(PropTypes.number)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(DataGroupSelector, {
  connectors: {
    dataGroups: getAll(dataGroups)
  }
});
