import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ReferralStatusResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/case_worthy/referral_statuses';

  text  = undefined;
  value = undefined;

  pk() {
    return this.value && this.value.toString();
  }
}
