import React                                              from 'react';
import PropTypes                                          from 'prop-types';
import { Segment, Header, Message, List, Icon, Divider }  from 'semantic-ui-react';
import { Link }                                           from 'react-router-dom';

function url(scope, type, id) {
  return `/${scope}/${type}/${id}`;
}

function getStats(forms, sequences, reports) {
  const stats = [];

  if (forms.length) {
    stats.push(`${forms.length} forms`);
  }
  if (sequences.length) {
    stats.push(`${sequences.length} sequences`);
  }
  if (reports.length) {
    stats.push(`${reports.length} reports`);
  }

  return stats;
}

const targetType = {
  'field': 'forms or reports',
  'form':  'sequences or reports'
};

const RecordList = ({records, type, scope}) => {
  return (
     <div style={{ marginBottom: '1.5rem' }}>
      <Divider horizontal>{ type } </Divider>

      <List divided relaxed='very'>
        {
          records.map(({ id, name }) => {
            return (
              <List.Item key={id}>
                <List.Icon name='file outline' />
                <List.Content>
                  <List.Header>
                    <Link to={ url( scope, type, id ) }>{ name }</Link>
                  </List.Header>
                </List.Content>
              </List.Item>
            );
          })
        }
      </List>
    </div>
  );
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const UsageInfo = ({ loading, forms, sequences, reports, record_type='field', scope }) => {
  const stats = getStats(forms, sequences, reports);

  return (
    <Segment loading={ loading }>
      <Header as='h3'>Usage Information</Header>

      { stats.length ?
        <div>
          <Message icon info>
            <Icon name='info circle' />
            This { record_type } is used in: { stats.join(', ') }.
          </Message>

          { forms && forms.length > 0 &&
            <RecordList records={ forms }
                       type= { 'forms' }
                       scope={ scope }
                       />
          }

          { sequences && sequences.length > 0 &&
            <RecordList records={ sequences }
                       type= { 'sequences' }
                       scope={ scope }
                       />
          }

          { reports && reports.length > 0 &&
           <RecordList records={ reports }
                       type= { 'reports' }
                       scope={ scope }
                       />
          }
        </div>
        :
        <Message icon info>
          <Icon name='info circle' />
          This { record_type } does not appear in any { targetType[record_type] }.
        </Message>
      }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

UsageInfo.defaultProps = {
  loading:  false,
  forms:    [],
  sequences:[],
  reports:  [],
};

UsageInfo.propTypes = {
  loading:  PropTypes.bool,
  forms:    PropTypes.array,
  scope:    PropTypes.oneOf(['organization', 'admin']).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default UsageInfo;

