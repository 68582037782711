import React                from 'react';
import PropTypes            from 'prop-types';
import { Segment, Header }  from 'semantic-ui-react';

import ClientFormResource         from 'resources/organization/ClientFormResource';
import FormResource               from 'resources/organization/FormResource';
import useOrganizationResource    from 'hooks/useOrganizationResource';
import useOrganizationFetcher     from 'hooks/useOrganizationFetcher';
import ResponseForm               from 'containers/ResponseForm';
import { formDataRequestMapper }  from 'utils/requestMappers';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function saveHandler({ id, client_form_id, history, form, update }) {
  return async (answers, done) => {

    const params  = { id: client_form_id, client_id: id };
    const body    = formDataRequestMapper({ form_id: form.id, answers });

    const response = await update(params, body);
    if(response) {
      history.push(`/organization/clients/${id}/client_forms/${client_form_id}`);
    }
    done();
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const EditFormView = ({ match, history }) => {
  const { params: { id, client_form_id } } = match;

  const clientForm  = useOrganizationResource(
                        ClientFormResource.detailShape(),
                        { client_id: id, id: client_form_id }
                      );

  const form        = useOrganizationResource(
                        FormResource.detailShape(),
                        { id: clientForm.form_id }
                      );

  const update      = useOrganizationFetcher(ClientFormResource.updateShape());
  const handleSave  = saveHandler({ id, client_form_id, history, form, update });

  if(form === null || form === undefined) {
    return <div/>;
  }

  const clientFormFieldAnswers = clientForm.answers;

  const answers = clientFormFieldAnswers.reduce((index, answer) => {
    index[answer.field_id] = answer;
    return index;
  }, {});

  return (
    <Segment>
      <Header as='h3'>{ form.name }</Header>
      <div style={{ marginTop: '2rem' }}>

        <ResponseForm answers={ answers }
                      form={ form }
                      onSave={ handleSave } />
      </div>
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditFormView.defaultProps = {};

EditFormView.propTypes = {
  history:  PropTypes.object.isRequired,
  match:    PropTypes.object.isRequired
};

export default EditFormView;
