import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form, Grid }       from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Organization extends Component {
  constructor(props) {
    super(props);

    this.handleRemove  = this.handleRemove.bind(this);
  }

  render() {
    const { organization: { name } } = this.props;
    return (
      <Grid.Row>
        <Grid.Column width={ 14 }>
          <h5>{ name }</h5>
        </Grid.Column>
        <Grid.Column width={ 2 }>
          <Form.Button    basic
                          color='red'
                          icon='remove'
                          size='mini'
                          type='button'
                          tabIndex='-1'
                          onClick={ this.handleRemove } />
        </Grid.Column>
      </Grid.Row>
    );
  }

  handleRemove(evt) {
    evt.preventDefault();
    const { index, onRemove } = this.props;
    onRemove(evt, { index });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Organization.defaultProps = {
  organization: {}
};

Organization.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string
  }),

  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Organization;
