import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import SignaturePad         from 'signature_pad';

//---------------------------------------------------------
// Component Definition
//---------------------------------------------------------

function isEmpty(data) {
  return !data || !data.length;
}

class Signature extends Component {

  constructor(props) {
    super(props);
    this.state = {
        signInput: null,
        showSignatureImage: true
    };
    this.canvas = React.createRef();

    this.clearSignature = this.clearSignature.bind(this);
    this.undoSignature = this.undoSignature.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  clearSignature(evt) {
    const { name, onChange } = this.props;
    this.state.signInput.clear();

    const value = '';
    onChange(evt, { name, value });
  }

  undoSignature(evt) {
    const { name, onChange }  = this.props;
    const { signInput }       = this.state;

    let data = signInput.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      signInput.fromData(data);

      let value = '';
      if (!signInput.isEmpty()) {
        value = signInput.toDataURL();
      }
      onChange(evt, { name, value });
    }
  }

  handleChange(evt) {
    const { name, onChange } = this.props;
    const value = this.state.signInput.toDataURL();

    this.setState({ showSignatureImage: false });
    onChange(evt, { name, value });
  }

  componentDidMount() {
    const { value } = this.props;
    if (isEmpty(value)) {
      let canvas = this.canvas.current,
          widget = new SignaturePad(canvas, {
            minWidth: 0,
            maxWidth: 1,
          });
      this.setState({ signInput: widget });
    }
  }

  render() {
    const { value } = this.props;
    const { showSignatureImage } = this.state;

    if (!isEmpty(value) && showSignatureImage) {
      return (
        <img src={ value } alt='Signature' />
      );
    }

    return (
      <div>
        <canvas ref={this.canvas}
                className="ui segment"
                style={{ padding: 0 }}
                onMouseUp={ this.handleChange }
                onTouchEnd={ this.handleChange }></canvas>

        <div>
          <div  className="ui button basic mini"
                onClick={ this.undoSignature }>Undo</div>
          <div  className="ui button basic mini"
                onClick={ this.clearSignature }>Clear</div>
        </div>
      </div>
    );
  }
}

//---------------------------------------------------------
// PropTypes
//---------------------------------------------------------

Signature.defaultProps = {
  value:      '',
  metadata:   {}
};

Signature.propTypes = {
  value:    PropTypes.string,
  metadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

//---------------------------------------------------------
// Exports
//---------------------------------------------------------

export default Signature;
