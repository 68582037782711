import AuthenticatedResource from 'resources/AuthenticatedResource';

const valueLabels = {
  'Client':               'Client',
  'ClientForm':           'Client Form',
  'Field':                'Field',
  'Form':                 'Form',
  'InternalFormInstance': 'Internal Form',
  'Note':                 'Note',
  'Organization':         'Organization', 
  'PermissionGroup':      'Permission Group',
  'PublicFormInstance':   'Public Form',
  'ReportDefinition':     'Report',
  'User':                 'User'
};

export default class ItemTypeResource extends AuthenticatedResource {
  static urlRoot = '/admin/item_types';

  value = undefined;

  get label() {
    return valueLabels[this.value];
  }

  pk() {
    return this.value;
  }
}