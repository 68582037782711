import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { connect }          from 'react-redux';
import { Redirect }         from 'react-router-dom';

import { organizationAccount, impersonation } from 'resources/organizationResources';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Start extends Component {

  async componentDidMount() {
    const { dispatch, match: { params: { userId } } } = this.props;

    // start the impersonation
    let request   = impersonation.actions.create({ user_id: userId });
    let response  = await dispatch(request);

    if(!response.errors) {
      // Reset all org state
      dispatch({ type: 'RESET_ORG' });

      // Reload the organization Account
      request = organizationAccount.actions.get();
      dispatch(request);
    }
  }

  render() {
    return (
      <Redirect to='/' />
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Start.propTypes = {
  match:    PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connect()(Start);
