import resourceUrl from './resourceUrl';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const autocompletePath  = '/address_autocomplete';
const detailsPath       = '/address_detail';

let autocompleteController;
let detailsController;

function getAddressComponents(result) {
  const addressComponents = result
                            .address_components
                            .reduce((components, { short_name, types }) => {
                              const typeNamePairs = types.reduce((kv, type) => (
                                                      { ...kv, [type]: short_name }
                                                    ), {});

                              return { ...components, ...typeNamePairs };
                            }, {});

  const { geometry: { location: { lat, lng } }, formatted_address } = result;
  const { street_number,
          route,
          locality,
          administrative_area_level_1,
          postal_code } = addressComponents;
  
  return {
    text: formatted_address,
    components: {
      streetAddress:  [street_number, route].filter(c => !!c).join(' '),
      city:           locality,
      state:          administrative_area_level_1,
      postalCode:     postal_code
    },
    location: [lat, lng],
    ignoring: []
  };
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export async function getAddressDetails(placeId) {
  detailsController?.abort();

  const url = resourceUrl('/', detailsPath);
  url.searchParams.append('place_id', placeId);
  
  detailsController = new AbortController();
  const { signal }  = detailsController;
  const response    = await fetch(url, { signal });
  const data        = await response.json();
  
  const { result, error_message: error } = data;
  const address = getAddressComponents(result);

  return { error, address };
}

export default async function addressAutocomplete(input) {
  autocompleteController?.abort();

  const url = resourceUrl('/', autocompletePath);
  url.searchParams.append('input', input);

  autocompleteController  = new AbortController();
  const { signal }        = autocompleteController;
  const response          = await fetch(url, { signal });
  const data              = await response.json();

  const { predictions, error_message: error } = data;
  const addresses = predictions.map( prediction => (
    {
      text:     prediction.description,
      placeId:  prediction.place_id
    }
  ));

  return { error, addresses };
}

