import React                                        from 'react';
import PropTypes                                    from 'prop-types';
import { Segment, Header, List, Message, Divider }  from 'semantic-ui-react';
import { Link }                                     from 'react-router-dom';

import ConditionsViz              from 'components/ConditionsViz';
import DataList                   from 'components/DataList';

import DataGroupResource        from 'resources/organization/DataGroupResource';
import UserResource             from 'resources/organization/UserResource';
import PermissionGroupResource  from 'resources/organization/PermissionGroupResource';
import useOrganizationResource  from 'hooks/useOrganizationResource';

import formattedDate            from 'utils/formattedDate';

import CreateSubGroupButton   from './CreateSubGroupButton';
import indexOnAttribute from 'utils/indexOnAttribute';

// -----------------------------------------------------
// Functions
// -----------------------------------------------------

const itemTypes = {
  'Client':     'People',
  'ClientForm': 'Form Response'
};

function getConditionFieldsLookup(users, permissionGroups) {
  return {
    'whodunnit': {
      label:        'Created By',
      formatValue:  (serializedIdAndType) => {
        const { id, type }            = JSON.parse(serializedIdAndType);
        const indexedUsers            = indexOnAttribute(users || [], 'id');
        const indexedPermissionGroups = indexOnAttribute(permissionGroups || [], 'id');

        return  type === 'User'
                ? indexedUsers[id]?.name
                : indexedPermissionGroups[id]?.name;
      }
    },

    'created_at': {
      label:        'Created At',
      formatValue:  dt => formattedDate(dt, 'date', '')
    },

    'item_type': {
      label:        'Record Type',
      formatValue:  itemType => itemTypes[itemType]
    }
  };
}

function dataGroupPath(id) {
  return `/organization/data_groups/${id}`;
}

function PathHeader({ path, name }) {
  return (
    <Header as='h2'>
      {
        path.map(({ id, name }) => (
          <span key={ id }>
            <Link to={ dataGroupPath(id) }>{ name }</Link>
            <span> / </span>
          </span>
       ))
      }
      { name }
    </Header>
  );
}

function DataGroupInfo({ numberOfRecords, subGroups, createdAt, updatedAt }) {
  const items = [
    { label: 'Number of Records',     value: numberOfRecords },
    { label: 'Number of Sub-Groups',  value: subGroups.length },
    { label: 'Created At',            value: formattedDate(createdAt) },
    { label: 'Last Updated At',       value: formattedDate(updatedAt) }
  ];

  return (
    <DataList items={ items } />
  );
}

function SubGroupList({ subGroups }) {
  if(subGroups.length === 0) {
    return <Message>No sub-groups exist for this data group.</Message>;
  }

  return (
    <List divided relaxed>
      {
        subGroups.map(({ id, name, number_of_records }) => (
          <List.Item key={ id }>
            <List.Icon name='folder' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header as={ Link } to={ dataGroupPath(id) }>
                { name }
              </List.Header>
              <List.Description>
                Contains { number_of_records } records
              </List.Description>
            </List.Content>
          </List.Item>
        ))
      }
    </List>
  );
}

function SmartGroupConditions({ smartConditions, users, permissionGroups }) {
  if(smartConditions && Object.keys(smartConditions).length > 0) {
    const lookup = getConditionFieldsLookup(users, permissionGroups);
    console.log({ lookup });
    return (
      <div style={ { marginTop: '2rem' } }>
        <Header as='h3'>SmartGroup Conditions</Header>
        <ConditionsViz conditions={ smartConditions } fieldsLookup={ lookup } />
      </div>
    );
  }

  return null;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = ({ match: { params: { id } }, loading }) => {
  const dataGroup         = useOrganizationResource(DataGroupResource.detailShape(), { id });
  const users             = useOrganizationResource(UserResource.listShape(), {});
  const permissionGroups  = useOrganizationResource(PermissionGroupResource.listShape(), {});

  const { path=[],
          name,
          sub_groups=[],
          number_of_records,
          created_at,
          updated_at,
          smart_conditions }  = dataGroup || {};

  return (
    <Segment loading={ loading }>
      <PathHeader path={ path } name={ name } />
      <DataGroupInfo  numberOfRecords={ number_of_records }
                      subGroups={ sub_groups }
                      createdAt={ created_at }
                      updatedAt={ updated_at } />

      <SmartGroupConditions smartConditions={ smart_conditions }
                            users={ users }
                            permissionGroups={ permissionGroups } />

      <div>
        <div className='pull-right'>
          <CreateSubGroupButton parent={ dataGroup } />
        </div>
        <Header as='h3'>Sub-Groups</Header>
      </div>

      <Divider/>
      <SubGroupList subGroups={ sub_groups } />
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DetailView.propTypes = {
  match: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DetailView;
