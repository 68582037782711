import moment from 'moment';
import {
  numericType,
  dateType,
  timeType,
  intervalParameterType,
  dateRelativeParameterType }  from 'constants/Calculations';
import { timeTypeOptions } from 'containers/organization/reportDefinitions/FormView/WizardPanel/FieldSelection/SelectionTable/EditModal/Formatter';
import { find } from 'lodash';

function hasMissingVarialbles(context, equation) {
  const contextVarsCount  = Object.keys(context).length;
  const equationVarsCount = new Set(equation.replace(/[^a-z]/g, '')).size;

  return contextVarsCount !== equationVarsCount;
}

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

// Evaluates the equation within the variableContext.
function evaluateEquation(equation, variableContext) {
  if (hasMissingVarialbles(variableContext, equation)) { return null; }

  const destructuredArgs  = `{${ Object.keys(variableContext).join(',') }}`;

  // eslint-disable-next-line no-new-func
  const evaluator = new Function(destructuredArgs, `return ${equation}`);
  return evaluator(variableContext);
}

function applyDateCalculation({ equation, variables, display_unit }, answers) {

  // Reduce the variables array to a map of variable names to their computed
  // values.
  const variableContext = variables.reduce((result, variable) => {
    const { name, record_id } = variable;

    // if a record_id exists, we know this variable references a field and
    // its answer.  Further, we know the answer is a date, so we'll use the
    // record id to get the answer, and wrap the answer in moment.
    if(record_id !== null && record_id !== undefined) {
      const { value } = answers[record_id] || {};
      if ( value !== undefined) {
        result[name]    = moment(value);
      }

    // If there is no record id, then the only other thing the variable could be
    // is a parameter.
    } else {
      const { value, parameter_type } = variable;

      // If the parameter represents an interval, we'll wrap it in a moment
      // duration.
      if(parameter_type === intervalParameterType) {
        const { magnitude, unit } = value;
        result[name] = moment.duration(+magnitude, unit);

      // If the parameter isn't an interval, the only other thing it can be
      // is a date, so we'll wrap its value in moment.
      } else if(parameter_type === dateRelativeParameterType) {
        result[name] = moment(value);
      }
    }

    return result;
  }, {});

  const result = evaluateEquation(equation, variableContext);

  if(result !== null && display_unit !== null && display_unit !== undefined) {
    return Math.floor(moment.duration(result).as(display_unit)); // Moment's default is milliseconds, this converts to display_unit
  }

  return result;
}

function applyTimeCalculation({ equation, variables, display_unit }, answers) {

  // Reduce the variables array to a map of variable names to their computed
  // values.
  const variableContext = variables.reduce((result, variable) => {
    const { name, record_id } = variable;
    const { value } = answers[record_id] || {};
    result[name]    = moment.duration(value);

    return result;
  }, {});

  const result = evaluateEquation(equation, variableContext);

  if(result !== null && display_unit !== null && display_unit !== undefined) {
    if (display_unit === 'hours' || display_unit === 'minutes') {
      return moment.duration(result).as(display_unit); // Moment's default is milliseconds, this converts to display_unit
    } else {
      const format = timeTypeOptions.find(({ value }) => value === display_unit).text;
      return moment.utc(result).format(format);
    }
  }

  return result;
}

function applyNumericCalculation({ equation, variables }, answers, calculations) {

    // Given a list of { name: answer } objects, return a map
    // { name: answer.value, ... }
    const variableContext = variables.reduce((result, { name, record_id, record_type }) => {

      if (record_type === 'Field') {
        const { value } = answers[record_id] || {};

        // ensure value is always a number.
        result[name] = +(value || 0);

      } else if (record_type === 'Calculation') {
        const calculation = find(calculations, ['id', record_id]) || {};

        result[name] = applyCalculation(calculation, answers, calculations);
      }

      return result;
    }, {});

    return evaluateEquation(equation, variableContext);
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function applyCalculation({ calculation_type, ...calculation }, answers, calculations) {

  if(calculation_type === numericType) {
    return applyNumericCalculation(calculation, answers, calculations);
  } else if (calculation_type === dateType) {
    return applyDateCalculation(calculation, answers);
  } else if (calculation_type === timeType) {
    return applyTimeCalculation(calculation, answers);
  }
}
