import React           from 'react';
import PropTypes    from 'prop-types';
import { Grid, Header, Form } from 'semantic-ui-react';

const style = {
  marginBottom: '2rem'
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

  const MapConfiguration = ({ currentView, axisOptions, onChange }) => {
    return <>
      <Grid.Row style={style}>
        <Grid.Column>
          <Header as='h3'>Data</Header>
          <Form.Select options={axisOptions}
            label={'Geo locations column'}
            value={currentView.xaxis}
            name='xaxis'
            placeholder={'Select the Location column'}
            onChange={onChange} />

          <Form.Select options={axisOptions}
            label={'Labels column'}
            value={currentView.yaxis}
            name='yaxis'
            placeholder={'Select the Label column'}
            onChange={onChange} />

          <Form.Select options={axisOptions}
            label={'Categorize by column'}
            value={currentView.group_by}
            name='group_by'
            placeholder={'Select a column to group records by'}
            clearable
            onChange={onChange} />

          <Form.Input
            label='Zoom Level'
            placeholder='Default: 10, Min: 1, Max: 20'
            name='zoom'
            value={currentView.zoom}
            onChange={onChange} />



        </Grid.Column>
      </Grid.Row>
    </>;

  };

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

MapConfiguration.propTypes = {
  currentView: PropTypes.object.isRequired,
  axisOptions: PropTypes.array.isRequired,
  seriesOptions: PropTypes.array.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default MapConfiguration;
