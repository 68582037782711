import React, { Component, Link }              from 'react';
import { Modal, Button, Confirm,
         Header, Table, Container }      from 'semantic-ui-react';
import ReportViewItem                    from './ReportViewItem';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function isEditable(viewType) {
  return (viewType !== 'table');
}

function isDeletable(viewId, viewType,  defaultViewId) {
  return (viewType !== 'table' && viewId !== defaultViewId);
}

function rootPath(reportId) {
  return `/organization/reports/${reportId}/views`;
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class ManageViewsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDefaultId: null,
      currentDeleteId:  null,
      confirmOpen:      false
    };

    this.handleNewView           = this.handleNewView.bind(this);
    this.handleDefaultSelection  = this.handleDefaultSelection.bind(this);
    this.handleUpdateDefaultView = this.handleUpdateDefaultView.bind(this);
    this.handleDelete            = this.handleDelete.bind(this);
    this.handleCancelDelete      = this.handleCancelDelete.bind(this);
    this.handleConfirmDelete     = this.handleConfirmDelete.bind(this);
    this.handleClose             = this.handleClose.bind(this);
  }

  render() {
    const { opened, defaultViewId, views = [] } = this.props;
    const { currentDefaultId, confirmOpen }     = this.state;
    const selectedId                            = currentDefaultId || defaultViewId;

    return (
        <Modal open={ opened } onClose={ this.handleClose } dimmer='inverted' closeIcon>
          <Header icon="cog" content="Manage Report Views" />
          <Modal.Content>
            <Container textAlign='right'>
              <Button onClick={ this.handleNewView } content='Add Chart View' labelPosition='left' icon='plus' primary />
            </Container>

            <Table basic style={{ marginTop: '2rem' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={8}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={4}>View Type</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Default</Table.HeaderCell>
                  <Table.HeaderCell width={2}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  Object.entries(views).map(([, view]) => (
                    <ReportViewItem key={`item-${view.id}`}
                                    view={view}
                                    isSelected={view.id === selectedId}
                                    isEditable={isEditable(view.view_type)}
                                    isDeletable={isDeletable(view.id, view.view_type,  defaultViewId)}
                                    onChange={this.handleDefaultSelection}
                                    onDelete={this.handleDelete}
                                    onClose={this.handleClose} />
                  ))
                }
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Confirm
                      as={Link}
                      to={'./'}
                      open={ confirmOpen }
                      dimmer='inverted'
                      onCancel={ this.handleCancelDelete }
                      onConfirm={ this.handleConfirmDelete }
                    />
            <Button basic
                disabled={!currentDefaultId || currentDefaultId === defaultViewId}
                onClick={ this.handleUpdateDefaultView } >Update Default View</Button>
            <Button onClick={ this.handleClose } negative basic>Close</Button>
          </Modal.Actions>
        </Modal>
      );
  }

  handleDefaultSelection(_, { value }) {
    this.setState({
      currentDefaultId: value
    });
  }

  handleUpdateDefaultView() {
    const { onChange }  = this.props;
    const { currentDefaultId }  = this.state;
    onChange(currentDefaultId);
  }

  handleDelete(_, {value}) {
    this.setState({
      currentDeleteId: value,
      confirmOpen: true
    });
  }

  handleCancelDelete() {
    this.setState({
      currentDeleteId: null,
      confirmOpen: false
    });
  }

  handleConfirmDelete() {
    const { onDelete } = this.props;
    const { currentDeleteId } = this.state;

    this.setState({
      currentDeleteId: null,
      confirmOpen: false
    });

    onDelete(currentDeleteId);
  }

  handleNewView() {
    const { reportId, onClose }  = this.props;
    this.props.history.push(`${rootPath(reportId)}/new`);
    onClose();
  }

  handleClose() {
    const { onClose }  = this.props;
    this.setState({ currentDefaultId: null });
    onClose();
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ManageViewsModal;
