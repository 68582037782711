// Preserves insertion order
export function indexOnAttributeWithMap(records, attribute) {
  return records.reduce((index, record) => {
    const key = record[attribute];
    index.set(key, record);
    return index;
  }, new Map());
}

export default function indexOnAttribute(records, attribute) {
  return records.reduce((index, record) => {
    const key   = record[attribute];
    index[key]  = record;
    return index;
  }, {});
}
