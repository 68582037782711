import React        from 'react';
import { connect }  from 'react-redux';
import _            from 'lodash';

// -----------------------------------------------------
// HOC definition
// -----------------------------------------------------

const connectResource = (Component, config) => {

  let { key = () => false,
        connectors,
        mapDispatchToProps = {},
        ignorePending = false } = config;

  if(!connectors) {
    connectors = config; //config can just be connectors
  }

  class ResourceConnect extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        api_errors: null,
        key :       undefined,
        pending:    {}
      };
    }

    componentDidMount() {
      this.update(this.props);
    }

    componentDidUpdate(prevProps) {
      const shouldUpdate = !_.isEqual(prevProps, this.props);

      if(shouldUpdate) {
        this.update(this.props);
      }
    }

    update(props, forceUpdate) {
      const { data }    = props;
      const computedKey = key(props);
      
      if(!_.isEqual(computedKey, this.state.key)) {
        this.setState({ key: computedKey });
        forceUpdate = true;
      }

      for(var i=0; i<connectors.length; i++) {
        const parallelConnections = connectors[i];
        let complete              = true;

        _.each(parallelConnections, ({ fetchData }, connectionName) => {
          const requiresUpdate = !data[connectionName] || forceUpdate;

          if(requiresUpdate) {
            const action    = fetchData(this.props.state, props);
            const canUpdate = !this.state.pending[connectionName]
                              || (forceUpdate && ignorePending);

            if(action && canUpdate) {
              this.setPendingState(connectionName, true);

              props.dispatch(action).then(res => {
                this.setPendingState(connectionName, false);
                this.handleErrors(res, connectionName);
              });
            }
            complete = false;
          }
        });

        if(!complete) break;
      }
    }

    setPendingState(connectionName, isPending) {
      this.setState({
        pending: {
          ...this.state.pending,
          [connectionName]: isPending
        }
      });
    }

    handleErrors(response, connectionName) {
      if(response.error) {
        this.setState({
          api_errors: {
            ...(this.state.api_errors || {}),
            [connectionName]: response
          }
        });
      } else {
        let newErrors = _.omit(this.state.api_errors, connectionName);

        if(!_.some(newErrors)) {
          newErrors = null;
        }

        this.setState({
          api_errors: newErrors
        });
      }
    }

    render() {
      const { api_errors, pending }                                  = this.state;
      const { data, pending: parentPending, loading: parentLoading } = this.props;

      const loading = !_.every(data) && _.some(pending);

      return (
        <Component  { ...this.props }
                      api_errors={ api_errors }
                      pending={ _.some(pending) || parentPending }
                      loading={ loading || parentLoading } />
      );
    }
  }

  function mapStateToProps(state, props) {
    if(!_.isArray(connectors)) {
      connectors = [connectors];
    }

    let data = { ...props.data };

    for(var i=0; i<connectors.length; i++) {
      const parallelConnections = connectors[i];

      const extendedProps = {
        ...props,
        data: {
          ...(props.data || {}),
          ...data
        }
      };

      const newData = _.mapValues(parallelConnections, ({ selectData }) => {
        return selectData(state, extendedProps);
      });

      data = { ...data, ...newData };
      if(!_.every(newData)) {
        break;
      }
    }

    return { data, state };
  }

  const componentConnect =
    connect(mapStateToProps, { ...mapDispatchToProps, dispatch: a=>a });

  return componentConnect(ResourceConnect);
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource;
