import React          from 'react';
import PropTypes      from 'prop-types';
import { Icon }       from 'semantic-ui-react';
import { Link }       from 'react-router-dom';

import AnswerRenderer from 'components/fields/AnswerRenderer';
import SortableTable  from 'components/SortableTable';
import DeleteButton   from './DeleteButton';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const linkStyle                 = { display: 'inline-block' };
const scrollPaneStyleOverrides  = { overflow:   'unset' };
const timeRecordedLabel         = 'Time Recorded';
const sortAttribute             = 'value';
const clientLinkIndicatorIndex  = 1;
const clientLinkIndicatorLabel  = 'Linked to Client?';

const valueRenderer = ({ recordUrl }, value, index) => {
  return (
    <Link to={ recordUrl } style={ linkStyle }>
      {
        index === clientLinkIndicatorIndex
          ? value !== null && value !== undefined
            ? <Icon name='check' />
            : null
          : value
            ? <AnswerRenderer answer={ value } />
            : <em>(no answer)</em>
      }
    </Link>
  );
};

function createControlCellRenderer(publicFormId, onDelete) {
  const CellRenderer = ({ id }) => {
    return (
      <DeleteButton publicFormId={ publicFormId }
                    id={ id }
                    onDelete={ onDelete } />
    );
  };

  return CellRenderer;
}

// Given a list of field objects, returns an array of strings to be used as
// table header labels.
function prepareHeaderLabels(fields) {
  const fieldHeaders = fields.map(({ name }) => name);

  // Append created_at header label to the headers array
  const labels  = [timeRecordedLabel, ...fieldHeaders];
  labels.splice(clientLinkIndicatorIndex, 0, clientLinkIndicatorLabel);

  return labels;
}

// Given a list of field objects, a list of responses and a public form id,
// returns an array of arrays of answer objects.  These are the records for the
// table.
function prepareTableRecords(fields, responses, publicFormId) {
  return responses.map(({ id, answers, created_at, client_form_id }) => {
    const recordUrl     = createRecordUrl(publicFormId, id);
    const answerValues  = fields.map(({ id }) => answers[id]);

    // prepend created_at to the record.
    const record = [
      {
        recordUrl,
        [sortAttribute]:  created_at,
        field_type:       'datetime'
      },
      ...answerValues
    ];

    record.splice(clientLinkIndicatorIndex, 0, client_form_id);

    record.recordUrl  = recordUrl;
    record.id         = id;

    return record;
  });
}

// Template function for generating a link to the edit view of an individual
// response.
function createRecordUrl(publicFormId, responseId) {
  return `/organization/public_responses/${publicFormId}/responses/${responseId}`;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ResponsesTable = ({ publicFormId,
                          fields,
                          responses,
                          onDelete,
                          ...forwardProps }) => {

  const headerLabels        = prepareHeaderLabels(fields);
  const records             = prepareTableRecords(fields, responses, publicFormId);
  const controlCellRenderer = createControlCellRenderer(publicFormId, onDelete);

  return (
    <SortableTable  tableId={`public_responses_${publicFormId}`}
                    headerLabels={ headerLabels }
                    records={ records }
                    sortAttribute={ sortAttribute }
                    cellRenderer={ valueRenderer }
                    controlCellRenderer={ controlCellRenderer }
                    scrollPaneStyleOverrides={ scrollPaneStyleOverrides }
                    { ...forwardProps } />

  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ResponsesTable.defaultTypes = {
  responses:  [],
  fields:     []
};

ResponsesTable.propTypes = {
  publicFormId: PropTypes.number.isRequired,
  responses:    PropTypes.array,
  fields:       PropTypes.array,
  onDelete:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ResponsesTable;
