import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import SystemFieldSelection             from './SystemField';
import HouseholdFieldSelection          from './HouseholdField';
import FormFieldSelection               from './FormField';
import QueryFieldSelection             from './QueryField';
import AnyFieldSelection                from './Field';
import { windowFunctionType,
         aggFunctionType }              from 'constants/reports/FunctionTypes';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const reportTypesWithFormSelection = ['client_forms', 'public_forms'];

const selectionComponent = {
  'client_forms': FormFieldSelection,
  'public_forms': FormFieldSelection,
  'internal_forms': FormFieldSelection,
  'system':       SystemFieldSelection,
  'fields':       AnyFieldSelection,
  'household':    HouseholdFieldSelection,
  'query':        QueryFieldSelection
};

// given a report definition, this function tells if a function has been applied
// to the report and whether the function is a window or aggregate function.
function deriveFunctionType({ window: reportWindow, fields=[] }) {
  if(reportWindow !== null && reportWindow !== undefined) {
    return windowFunctionType;
  } else {
    const hasAggs = fields.some(({ agg }) => !!agg);

    return hasAggs ? aggFunctionType : null;
  }
}

// Omits aggs and filters from the reportDefinition fields
function omitAggs(fields) {
  return fields.map(({ form, field, type, label, formatter }) => ({ form, field, type, label, formatter }));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FieldSelection extends Component {
  constructor(props) {
    super(props);

    const { reportDefinition } = this.props;

    this.state = {
      functionType:       deriveFunctionType(reportDefinition)
    };

    this.handleFunctionTypeChange = this.handleFunctionTypeChange.bind(this);
    this.handleAddField           = this.handleAddField.bind(this);
    this.handleAddAllFields       = this.handleAddAllFields.bind(this);
    this.handleReportTargetChange = this.handleReportTargetChange.bind(this);
    this.handleChangeWindow       = this.handleChangeWindow.bind(this);
    this.handleChange             = this.handleChange.bind(this);
  }

  render() {
    const { reportDefinition }    = this.props;
    const { functionType }        = this.state;
    const FieldSelectionComponent = selectionComponent[reportDefinition.report_type];

    return (
        <FieldSelectionComponent { ...this.props }
                                  functionType={ functionType }
                                  onAddField={ this.handleAddField }
                                  onAddAllFields={ this.handleAddAllFields }
                                  onChangeReportTarget={ this.handleReportTargetChange }
                                  onChangeWindow={ this.handleChangeWindow }
                                  onChange={ this.handleChange }
                                  onFunctionTypeChange={ this.handleFunctionTypeChange } />
    );
  }

  handleFunctionTypeChange(evt, { value }) {
    const { functionType } = this.state;

    if(functionType !== value) {
      this.setState({ functionType: value });

      // If 'remove' is clicked
      if(value === null || value === undefined) {
        const { reportDefinition: { fields }, onChange } = this.props;
        onChange({ window: null, fields: omitAggs(fields) });
      }
    }
  }

  handleAddField(evt, { value: index, options, formid: formId }) {
    const { onChange, reportDefinition }  = this.props;
    const { report_type, fields: reportFields } = reportDefinition;

    const selectedOption = options[index];
    const form = report_type === 'query' ?
                  selectedOption['formid'] :
                  formId || selectedOption['form'];

    const label = report_type === 'query' ? selectedOption['fieldlabel'] : null;
    const queryAgg = report_type === 'query' ? selectedOption['fieldagg'] : null;

    const nextFields = [
      ...reportFields,
      {
        form: form ? form : undefined,
        field: selectedOption.id,
        type: selectedOption.type,
        label: label ? label : undefined,
        agg_query: queryAgg ? queryAgg : undefined
      }
    ];

    onChange({ fields: nextFields });
  }

  handleAddAllFields(newFields) {
    const { onChange, reportDefinition }  = this.props;
    const { fields: reportFields } = reportDefinition;

    const nextFields = [
      ...reportFields,
      ...newFields
    ];

    onChange({ fields: nextFields });
  }

  handleChangeWindow({ windowFunction }) {
    const { onChange } = this.props;

    onChange({
      window: windowFunction
    });
  }

  handleReportTargetChange(value) {
    const { onChange, reportDefinition }  = this.props;
    const { report_type } = reportDefinition;

    if(report_type === 'query') {
      onChange({ report_target_id: value });
    }
  }

  handleChange({ currentFormId, setFunctionType, selectedFields, windowFunction }) {
    const { functionType } = this.state;
    if (!functionType && setFunctionType) {
      this.setState({ functionType: setFunctionType});
    }
    const newFunctionType = functionType || setFunctionType;

    const { onChange, reportDefinition }                = this.props;
    const { fields: reportFields }                      = reportDefinition;

    let fields = [];
    if (reportTypesWithFormSelection.includes(reportDefinition.report_type)) {
      fields = reportFields.flatMap((reportField) => {
        const { form, field, type } = reportField;

        // Scope updates to the current form.  Fields out of this scope remain
        // unchanged.
        if(form === currentFormId) {

          // mark remaining reports field for delete, if the field is not in
          // selectedFields
          if(selectedFields.length === 0) {
            return undefined;

          // replace the report field with the selected field
          } else {
            const selectionIndex =
              selectedFields
              .findIndex(({ type: selectedType, field: selectedField }) => (
                field === selectedField && type === selectedType
              ));

            return selectedFields.splice(selectionIndex, 1);
          }
        } else {
          return reportField;
        }
      }).filter(f => f);
    }

    // any fields remaining in the selected fields should be appended to the
    // fields, as they have been added.
    const nextFields = [...fields, ...selectedFields];

    if(newFunctionType === windowFunctionType) {

      // omit the aggs, if window function
      onChange({
        fields: omitAggs(nextFields),
        window: windowFunction
      });

    } else {
      onChange({ fields: nextFields, window: null });
    }
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FieldSelection.defaultProps = {
  reportDefinition:   {},
  forms:              [],
  fields:             [],
  formCalculations:   {}
};

FieldSelection.propTypes = {
  reportDefinition:   PropTypes.object,
  forms:              PropTypes.array,
  fields:             PropTypes.array,
  formCalculations:   PropTypes.object,
  onChange:           PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldSelection;
