export const chartTypes = {
  'areachart': {
    image:       '/images/charts/areachart.png',
    description: 'Area Chart',
    icon:        'chart area',
    settings:    ['negative_values', 'stacked', 'percent']
  },
  'barchart': {
    image:       '/images/charts/barchart.png',
    description: 'Bar Chart',
    icon:        'chart bar',
    settings:    ['value_labels', 'negative_values', 'stacked']
  },
  'linechart': {
    image:       '/images/charts/linechart.png',
    description: 'Line Chart',
    icon:        'chart line',
    settings:    ['value_labels', 'negative_values', 'dashed']
  },
  'piechart': {
    image:       '/images/charts/piechart.png',
    description: 'Pie Chart',
    icon:         'chart pie',
    settings:    ['ring', 'value']
  },
  'scatterchart': {
    image:       '/images/charts/scatterchart.png',
    description: 'Scatter Chart',
    icon:        'chart line',
    settings:    ['joint_line']
  },
  'composedchart': {
    image:       '/images/charts/composedchart.png',
    description: 'Composed Chart',
    icon:        'chart bar',
    settings:    ['negative_values']
  },
  'map': {
    image:       '/images/charts/map.png',
    description: 'Map',
    icon:        'map outline',
    settings:    []
  }

};

export const chartSettingLabels = {
  'negative_values': 'Show negative values',
  'stacked':         'Stack areas/bars together',
  'percent':         'Show value range as percentage',
  'dashed':          'Show dashed line',
  'joint_line':      'Draw line joining data points',
  'ring':            'Display as ring instead of pie',
  'value':           'Show actual value instead of percentage',
  'value_labels':    'Show value labels on top of each bar/line'
};


export const chartColors = {
  'fill':    [ '#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#067bc2', '#f85a3e' ],
  'stroke':  [ '#706db1', '#6ba681', '#d1a349', '#d16937', '#05659f', '#bc527e' ]
};
// import { formFieldObjectsMapping }  from 'utils/reportDefinitions';
// import { chartTypes, chartSettingLabels } from 'constants/reports/Charts';
// import { find, compact } from 'lodash';
// // --------------------------------------------------------
// // Helpers
// // --------------------------------------------------------
export function axisLabels(chartType) {
  const labels = {
    'default': {
      header: 'Axis',
      label: 'Horizontal Axis',
      placeholder: 'Select a column for the X-Axis',
    },
    'piechart': {
      header: 'Value Label',
      label: '',
      placeholder: 'Select a column for the Label',
    },
    'map': {
      header: '',
      label: 'Locations column',
      placeholder: 'Select a column for the Location',
    }
  };

  return (chartType === 'map') ? labels[chartType] : labels['default'];
}
