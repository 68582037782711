
import createResource             from '../utils/resource';
import * as crudActions           from '../utils/resourceActions';
import { mergeNormalizeReducer }  from '../utils/reducers';
import { formDataRequestMapper }  from 'utils/requestMappers';
import _                          from 'lodash';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const { create: createAction } = crudActions;

// -----------------------------------------------------
// Record Cloning
// -----------------------------------------------------

export const cloneRecords = createResource({
  name:         'adminCloneRecords',
  selector:     (state) => state.admin.adminCloneRecords,
  basepath:     '/admin/:cloneable_type/:cloneable_id/clone',
  actions: {
    clone: crudActions.create
  }
});

// -----------------------------------------------------
// Calculations
// -----------------------------------------------------

export const calculations = createResource({
  name:   'admin_calculations',
  selector: state => state.admin.admin_calculations,
  basepath: '/admin/forms/:form_id/calculations',

  actions: {
    create:   crudActions.create,
    update:   crudActions.update,
    destroy:  crudActions.destroy,
    index: {
      method:   'GET',
      path:     '/',
      response: (state, action) => _.keyBy(action.payload, 'id')
    }
  }
});

// -----------------------------------------------------
// Fields
// -----------------------------------------------------

export const fields = createResource({
  name : 'admin_fields',
  selector : state => state.admin.admin_fields,
  basepath : '/admin/fields',
  actions : {
    ...crudActions
  }
});

// -----------------------------------------------------
// Forms
// -----------------------------------------------------

export const forms = createResource({
  name : 'admin_forms',
  selector : state => state.admin.admin_forms,
  basepath : '/admin/forms',
  actions : {
    ...crudActions
  }
});

// -----------------------------------------------------
// Form Fields
// -----------------------------------------------------

export const formFields = createResource({
  name:     'formFields',
  selector: state => state.admin.formFields,
  basepath: '/admin/forms/:form_id/fields',
  actions: {
    index: crudActions.index
  }
});

// -----------------------------------------------------
// Form Assignments
// -----------------------------------------------------

export const formAssignments = createResource({
  name: 'admin_formAssignments',
  basepath: '/admin/form_assignments',
  actions: {
    create: crudActions.create
  }
});

// -----------------------------------------------------
// Imports
// -----------------------------------------------------

export const imports = createResource({
  name : 'admin_imports',
  selector : state => state.admin.admin_imports,
  basepath : '/admin/imports',
  actions : {
    ...crudActions,
    create: {
      ...createAction,
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper
    },
  }
});

// -----------------------------------------------------
// Organizations
// -----------------------------------------------------

export const organizations = createResource({
  name : 'admin_organizations',
  selector : state => state.admin.admin_organizations,
  basepath : '/admin/organizations',
  actions : {
    ...crudActions
  }
});

// -----------------------------------------------------
// Organizations
// -----------------------------------------------------

export const organization_forms = createResource({
  name : 'admin_organization_forms',
  selector : state => state.admin.admin_organization_forms,
  basepath : '/admin/organizations/:organization_id/forms',
  actions : {
    index: crudActions.index,
    show:  crudActions.show
  }
});

// -----------------------------------------------------
// Org Users
// -----------------------------------------------------

export const org_users = createResource({
  name: 'admin_org_users',
  selector : state => state.admin.admin_org_users,
  basepath : '/admin',
  actions : {
    searchByEmail : {
      path : 'user_search_result/?email=:email',
      response : mergeNormalizeReducer
    }
  }
});

// -----------------------------------------------------
// Pricing Tiers
// -----------------------------------------------------

export const pricing_tiers = createResource({
  name : 'admin_pricing_tiers',
  selector : state => state.admin.admin_pricing_tiers,
  basepath : '/admin/pricing_tiers',
  actions : {
    ...crudActions
  }
});

// -----------------------------------------------------
// Users
// -----------------------------------------------------

export const users = createResource({
  name : 'admin_users',
  selector : state => state.admin.admin_users,
  basepath : '/admin/users',
  actions : {
    ...crudActions
  }
});
