import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Form, Select }               from 'semantic-ui-react';

import { currentDateParameterVariable, intervals } from 'constants/Calculations';

import indexVariablesByName   from 'utils/calculations/indexVariablesByName';
import {fieldVariableSetter } from 'utils/calculations/variableSetters';
import fieldOptions           from 'utils/fields/dateFieldOptions';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const fieldVariableNameMap  = {
  'record_id': 'a'
};

const equation = 'b - a';

const variableSetters = {
  a: fieldVariableSetter
};

// Variable `b` will always reference the current date
const variableB = { ...currentDateParameterVariable, name: 'b' };

function variableFor(variables, formFieldName) {
  const variablesIndex  = indexVariablesByName(variables);
  const variableName    = fieldVariableNameMap[formFieldName];

  return variablesIndex[variableName] || { name: variableName };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class TimeSincePanel extends Component {
  constructor(props) {
    super(props);

    this.handleChange             = this.handleChange.bind(this);
    this.handleDisplayUnitChange  = this.handleDisplayUnitChange.bind(this);
  }

  render() {
    const { fields, calculation, calculationType }     = this.props;
    const { variables, display_unit } = calculation || {};

    const variableA = variableFor(variables, 'record_id');
    const recordId   = variableA['record_id'];

    return (
      <Fragment>
        <p>
          Calculates the amount of time that has passed since the specified <strong>Date</strong>.
          <br />
          The result of the calculation will be displayed using the
          selected <strong>Display Units</strong>.
        </p>

        <Form.Field>
          <label>Date</label>
          <Select options={ fieldOptions(calculationType, fields) }
                  name='record_id'
                  value={ recordId }
                  selectOnBlur={ false }
                  onChange={ this.handleChange }/>
        </Form.Field>

        <Form.Field>
          <label>Display Units</label>
          <Select options={ intervals[calculationType] }
                  name='display_unit'
                  value={ display_unit }
                  selectOnBlur={ false }
                  onChange={ this.handleDisplayUnitChange }/>
        </Form.Field>
      </Fragment>
    );
  }

  handleChange(evt, { name, value }) {
    const { calculation, onChange } = this.props;
    const { variables }             = calculation || {};
    const variable                  = variableFor(variables, name);
    const variableName              = fieldVariableNameMap[name];
    const variableSetter            = variableSetters[variableName];
    const nextVariable              = variableSetter(variable, name, value);

    // indexing the variables by name allows us to easily replace old variables
    // with new variables of the same name (i.e. merge changes)
    const indexedVariables          = indexVariablesByName(variables);
    indexedVariables[variableName]  = nextVariable;

    // We always want 'b' variable to have a standard base, structure, so we'll
    // merge the standard structure into the current 'b' variable. This ensures
    // the structure is correct, while preserving things like the variable id.
    indexedVariables['b']           = { ...indexedVariables['b'], ...variableB };

    const nextVariables = Object.values(indexedVariables);

    const nextCalculation = {
      ...calculation,
      equation,
      variables: nextVariables
    };

    onChange({ calculation: nextCalculation });
  }

  handleDisplayUnitChange(evt, { name, value }) {
    const { calculation={}, onChange }  = this.props;
    const { variables }                 = calculation;

    // see handleChange to get a description of what's happening here.
    const indexedVariables  = indexVariablesByName(variables);
    indexedVariables['b']   = { ...indexedVariables['b'], ...variableB };

    const nextVariables = Object.values(indexedVariables);

    const nextCalculation = {
      ...calculation,
      equation,
      variables: nextVariables,
      [name]: value
    };

    onChange({ calculation: nextCalculation });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

TimeSincePanel.defaultProps = {
  loading:      false,
  calculation:  {}
};

TimeSincePanel.propTypes = {
  loading:    PropTypes.bool,
  calculation:  PropTypes.shape({
    id:           PropTypes.number,
    name:         PropTypes.string,
    equation:     PropTypes.string,
    variables:    PropTypes.array,
    display_unit: PropTypes.string
  }),

  fields:     PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    data_type:  PropTypes.string
  })).isRequired,
  onChange:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default TimeSincePanel;
