import React, { useState, useEffect, useRef } from 'react';
import { Redirect }                           from 'react-router-dom';
import domtoimage                             from 'dom-to-image';
import PropTypes                              from 'prop-types';
import debounce                               from 'lodash/debounce';
import {Header,
        Loader,
        Segment,
        Input,
        Button,
        Dropdown,
        Icon }   from 'semantic-ui-react';

import TableView from './TableView';
import ChartView from './ChartView';
import {reportResults,
        reportViews,
        reportSummary,
        reportDefinitions }     from 'resources/organizationResources';
import connectResource          from 'utils/connectResource';
import withConditions           from 'hocs/withConditions';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import withSort                 from 'hocs/withSort';
import { getById, getPageById } from 'utils/connectors';
import resourceUrl              from 'utils/resourceUrl';
import resourceHeaders          from 'utils/resourceHeaders';
import formattedDate            from 'utils/formattedDate';
import Main                     from 'components/Main';
import RefreshButton            from 'components/organization/reports/RefreshButton';
import { chartTypes }           from 'constants/reports/Charts';

import { find } from 'lodash';

import 'style/containers/organization/report-results.css';
import Map from 'components/Map';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const initialScrollResets   = {};
const originScrollResets    = { scrollTop: 0, scrollLeft: 0 };

function reportScope({ match: { params: { id } } }) {
  return { report_id: id };
}

function pageScope({ match: { params: { viewId } } }) {
  return viewId;
}

function viewOptions({ report_views=[] }) {
  return report_views.map(({ id, name, view_type }) => ({
    key:      `view-${id}`,
    text:     `${name} ▾`,
    value:    id,
    content:  name,
    icon:     (view_type === 'table') ? 'table' : chartTypes[view_type].icon
  }));
}

function csvDownloadHandler(setDownloading, reportDefinition, { orgdomain: organization, auth: { authToken } }) {
  return async () => {
    setDownloading(true);
    const conditions = localStorage.getItem('overridingConditions');

    const { id, name }  = reportDefinition;
    const basePath      = '/organization/:organization/report_results';
    const url           = resourceUrl(basePath, ':id.csv?conditions=:conditions', { organization, id, conditions });

    const headers = resourceHeaders(authToken, (hs) => {
      hs['Content-Type'] = 'text/csv';
    });

    const response      = await fetch(url, { headers, responseType: 'blob' });
    const csv           = await response.blob();

    setDownloading(false);

    const csvUrl        = URL.createObjectURL(csv);
    const downloadLink  = document.createElement('a');

    downloadLink.href   = csvUrl;
    downloadLink.setAttribute('download', `${name}.csv`);
    downloadLink.click();

    URL.revokeObjectURL(csvUrl);
  };
}

function imageDownloadHandler(setDownloading, chartView) {
  return async () => {
    setDownloading(true);
    let chart = chartView;

    domtoimage.toJpeg(chart, { quality: 0.95, bgcolor: '#FFFFFF' })
    .then(dataUrl => {
        const downloadLink  = document.createElement('a');
        downloadLink.href   = dataUrl;
        downloadLink.setAttribute('download', 'chart.jpeg');
        downloadLink.click();
    })
    .catch(error => {
        console.error('oops, something went wrong!', error);
    });

    setDownloading(false);
  };
}

function downloadHandler(shouldDownloadCsv, setDownloading, reportDefinition, chartView, state) {
  return  shouldDownloadCsv
          ? csvDownloadHandler(setDownloading, reportDefinition, state)
          : imageDownloadHandler(setDownloading, chartView);
}

function refreshHandler(refresh, refetch, refetchSummary, sort, onPageReset, setScrollResets, setLastRefreshedAt) {
  return async ({ id, viewId }) => {
    const refreshResponse         = await refresh(null, { id });
    const { refreshing, errors }  = refreshResponse;

    if(!errors) {
      onPageReset();
      setScrollResets(originScrollResets);

      const refetcher = setInterval(async () => {
        const refetchResponse =
          await refetch(null, {
            id:         viewId,
            report_id:  id,
            sort:       JSON.stringify(sort)
          });

        const { refreshing, last_refreshed_at, errors } = refetchResponse;
        setScrollResets(initialScrollResets);

        await refetchSummary(null, { id: viewId, report_id: id });

        if(errors || !refreshing) {
          clearInterval(refetcher);
          setLastRefreshedAt(last_refreshed_at);
        }
      }, REFETCH_INTERVAL);
    }

    return refreshing;
  };
}

const FILTER_DEBOUNCE   = 500;
const REFETCH_INTERVAL  = 2000;

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ReportView = ({ data,
                      refresh,
                      refetch,
                      refetchSummary,
                      onSearch,
                      onPageNext,
                      onPageReset,
                      onSort,
                      sort,
                      pending,
                      match,
                      history,
                      conditions,
                      state }) => {

  const [downloading, setDownloading]         = useState(false);
  const [scrollResets, setScrollResets]       = useState(initialScrollResets);
  const [lastRefreshedAt, setLastRefreshedAt] = useState(null);
  const chartView                             = useRef(null);
  const { params: { id: reportDefinitionId, viewId } } = match;

  const { records=[], id=0, view_type } = data.reportViewResults || {};
  const reportDefinition                = data.reportDefinition || {};

  const { name, last_refreshed_at, default_view_id } = reportDefinition;

  // Keep the lastRefreshedAt state in sync with that of the report definition.
  useEffect(() => {
    setLastRefreshedAt(last_refreshed_at);
  }, [last_refreshed_at]);

  // Reset overridingConditions when switching contexts
  useEffect(() => {
    localStorage.setItem('overridingConditions', '');
  }, [reportDefinitionId]);

  // Reset pagination when new overridingConditions are set
  useEffect(() => {
    onPageReset();
  }, [conditions, onPageReset]);

  const views               = viewOptions(reportDefinition);
  const selectedView        = find(views, { 'value': id }) || {};
  const selectedViewIsTable = (view_type === 'table');

  // Render a redirect to the default view, if we don't have a view for this
  // report.
  if (viewId === undefined && default_view_id) {
    const redirectPath =
      `/organization/reports/${reportDefinitionId}/views/${default_view_id}`;

    return <Redirect to={ redirectPath } />;
  }

  // -----------------------------------------------------
  // Event Handlers
  // -----------------------------------------------------

  const handleChange = debounce(async (evt, { value }) => {
    onPageReset();
    onSearch({ query: value });
  }, FILTER_DEBOUNCE);

  const handleDownload =
    downloadHandler(
      selectedViewIsTable,
      setDownloading,
      reportDefinition,
      chartView.current,
      state
    );

  const handleRefresh =
    refreshHandler(
      refresh,
      refetch,
      refetchSummary,
      sort,
      onPageReset,
      setScrollResets,
      setLastRefreshedAt
    );

  const handleViewChange = (e, { value }) => {
    const { id } = reportDefinition;
    history.push(`/organization/reports/${id}/views/${value}`);
  };

  // -----------------------------------------------------
  // Render Component
  // -----------------------------------------------------

  return (
    <Main>
      <Main offsetTop='1rem'>
        <Header as='h2'>{ name }</Header>
        <Header as='h5' style={{ marginTop: '-0.5rem' }} className='text-muted'>
          Last refreshed at: { formattedDate(lastRefreshedAt) }
        </Header>
      </Main>
      <Main offsetTop='7rem'>
        <Main offsetRight='50%'>
          <Dropdown
            button
            icon={ selectedView.icon }
            className='icon'
            floating
            labeled
            options={views}
            value={ selectedView.value }
            onChange={ handleViewChange }
          >
          </Dropdown>

          {
            selectedViewIsTable
            && <Input placeholder='Filter' onChange={ handleChange } />
          }

        </Main>
        <Main offsetLeft='50%'>
          <div className='pull-right'>
            <RefreshButton  primary
                            refresh={ handleRefresh }
                            reportDefinition={ reportDefinition }
                            viewId={ id } />
          </div>
          <div className='pull-right'>
            <Button primary
                    basic
                    icon
                    loading={ downloading }
                    labelPosition='left'
                    onClick={ handleDownload }>
              <Icon name='cloud download' />
              Download
            </Button>
          </div>
        </Main>
      </Main>
      <Main offsetTop='12rem'>
        {
          records.length === 0
          ? <Segment placeholder textAlign='center'>
              {
                pending
                ? <Header>
                    <Loader active inline content="Loading results." />
                  </Header>
                : <Header>No results found</Header>
              }
            </Segment>

          : (selectedViewIsTable)
            ? <TableView  data={ data }
                          scrollResets={ scrollResets }
                          onPageNext={ onPageNext }
                          onPageReset={ onPageReset }
                          onSort={ onSort } />
            : view_type === 'map'
              ? <Map data={ data.reportViewResults } />
              :
                <div ref={ chartView }>
                  <ChartView reportResults={ data.reportViewResults } />
                </div>

        }
      </Main>
    </Main>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ReportView.propTypes = {
  data: PropTypes.shape({
    reportResults: PropTypes.object,
    reportDefinition: PropTypes.object
  }).isRequired,
  onPageNext:   PropTypes.func.isRequired,
  onPageReset:  PropTypes.func.isRequired,
  onSortReset:  PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

// Note: We do this in multiple steps to ensure the report definition only gets
// loaded once, instead of being loaded for each page.
const definitionConnectedComponent = connectResource(ReportView, {
  connectors: {
    reportDefinition: getById(
      reportDefinitions, ({ match: { params: { id } } }) => id
    ),
  }
});

const resultsConnectedComponent = connectResource(definitionConnectedComponent, {
  key: ({ page, query, sort, match: { params: { viewId } } }) => [viewId, page, query, sort],
  connectors: {
    reportViewResults: getPageById(reportViews, pageScope, reportScope),
  },
  ignorePending: true,
  mapDispatchToProps: {
    refresh: reportResults.actions.refresh,
    refetch: reportViews.actions.refetch,
    refetchSummary: reportSummary.actions.show
  }
});

const overridedConditionsComponent = withConditions(resultsConnectedComponent);

const paginatedComponent = withPagination(overridedConditionsComponent, {
  getScope: pageScope
});

const sortedComponent = withSort(paginatedComponent, {
  getScope: pageScope
});

export default withSearch(sortedComponent);
