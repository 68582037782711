import React          from 'react';
import { Form }       from 'semantic-ui-react';
import MarkdownEditor from 'components/forms/Field/MarkdownEditor';
import DatePicker     from 'react-datepicker';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------


function changeHandler(onChange) {
  return (e, { name, value }) => {
    onChange && onChange({name, value});
  };
}

function changeExpiredAtHandler(onChange) {
  return (date) => {
    const value = date && date.toISOString();
    onChange && onChange({name: 'expired_at', value});
  };
}

const normDate = (dateString) => {
  return dateString ? new window.Date(dateString) : undefined;
};
const timeFormat  = 'h:mm aa';
const dateFormat  = 'MMMM d, yyyy h:mm aa';

const style = { marginBottom: '20px' };
const labelStyle = { fontWeight: 'bold' };
const expiredStyle = { ...labelStyle, display: 'inline-block', marginTop: '20px' };

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const FormView = ({ pinnedMessage, onChange }) => {

  const handleChange  = changeHandler(onChange);
  const handleExpiredAtChange = changeExpiredAtHandler(onChange);
  const headerTitle = pinnedMessage.id ? 'Edit Pinned Message' : 'Create New Pinned Message';
  const expired_at = normDate(pinnedMessage.expired_at);

  return (
    <Form>
      <h2 style={ style }>{ headerTitle }</h2>
      <label style={ labelStyle } >Title</label>
      <Form.Input placeholder='Title'
                  name='subject'
                  value={ pinnedMessage.subject }
                  onChange={ handleChange } />

      <label style={ labelStyle } >Content</label>
      <MarkdownEditor value={ pinnedMessage.body }
                      name='body'
                      onChange={ handleChange } />

      <label style={ expiredStyle } >Expires At</label><br />
      <DatePicker onChange={ handleExpiredAtChange }
                  selected={ expired_at }
                  showTimeSelect
                  timeFormat={ timeFormat }
                  timeIntervals={15}
                  dateFormat={ dateFormat }
                  timeCaption="Time"
                  isClearable={true} />
    </Form>
  );
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default FormView;
