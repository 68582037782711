import React      from 'react';
import { Header } from 'semantic-ui-react';

import ApiCredentialResource    from 'resources/organization/ApiCredentialResource';
import useOrganizationResource  from 'hooks/useOrganizationResource';
import useOrganizationFetcher   from 'hooks/useOrganizationFetcher';

import List       from './List';
import EmptyList  from './EmptyList';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const ApiKeys = () => {
  const apiCredentials  = useOrganizationResource(ApiCredentialResource.listShape());
  const create          = useOrganizationFetcher(ApiCredentialResource.createShape());
  const destroy         = useOrganizationFetcher(ApiCredentialResource.deleteShape()); 

  const handleCreate = async (body, afterCreate) => {
    const response = await create({}, body);

    if(response) {
      afterCreate(response);
    }
  };

  const handleDestroy = (id) => {
    destroy({ id });
  };

  return (
    <>
      <Header as='h1' textAlign='center'>API Credentials</Header>
      {
        apiCredentials.length === 0
        ? <EmptyList onCreate={ handleCreate } />
        : <List apiCredentials={ apiCredentials }
                onCreate={ handleCreate } 
                onDestroy={ handleDestroy } />
      }
    </>
  );
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ApiKeys;