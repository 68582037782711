import React, { Suspense }  from 'react';
import { Loader }           from 'semantic-ui-react';
import ApiErrorHandler      from './ApiErrorHandler';

// -----------------------------------------------------
// Sub-Components
// -----------------------------------------------------

const LoadingIndicator = () => {
  return (
    <Loader active>Loading</Loader>
  );
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

// Provides loading indicator and error handling for network calls made from
// within the sub tree.
const ApiCallBoundary = ({ children }) => {

  // Currently this works, but may not be ideal.  The purpose for this is to
  // force the ApiErrorHandler to refresh its state when the user navigates to
  // a new view.
  const key = window.location.pathname;

  return (
    <Suspense fallback={ <LoadingIndicator /> }>
      <ApiErrorHandler key={ key }>
        { children }
      </ApiErrorHandler>
    </Suspense>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ApiCallBoundary;
