import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import classNames           from 'classnames';

import Item from './Item';

import 'style/components/SelectionPanel.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const componentClassName = 'selection-panel';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class SelectionPanel extends Component {
  constructor(props) {
    super(props);

    this.handleSelection  = this.handleSelection.bind(this);
  }

  render() {
    const { options,
            value,
            className,
            style }   = this.props;

    const selections  = [].concat(value);
    const classes     = classNames(componentClassName, className);

    return (
      <div className={ classes } style={ style }>
        {
          options.map(({ label, value }) => (
            <Item key={ value }
                  label={ label }
                  value={ value }
                  isActive={ selections.includes(value) }
                  onSelect={ this.handleSelection } />
          ))
        }
      </div>
    );
  }

  handleSelection({ value }) {
    const { exclusive,
            onChange,
            name,
            value: selectedValues }  = this.props;
    const selections                 = new Set([].concat(selectedValues));

    if(selections.has(value)) {
      selections.delete(value);
    } else {
      if(exclusive) {
        selections.clear();
      }

      selections.add(value);
    }

    const nextSelections  = [...selections];
    const nextValue       = exclusive ? nextSelections[0] : nextSelections;

    onChange({ value: nextValue, name });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SelectionPanel.defaultProps = {
  exclusive:  true,
  name:       null,
  value:      []
};

SelectionPanel.propTypes = {
  exclusive:  PropTypes.bool,
  name:       PropTypes.string,
  value:      PropTypes.any,

  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  })).isRequired,

  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SelectionPanel;
