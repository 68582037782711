import React, { useState }  from 'react';
import { Button, Popup }    from 'semantic-ui-react';

import SettingsModal        from './SettingsModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Dashboard Settings';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const SettingsButton = () => {
  const [opened, setOpened] = useState(false);

  const handleClick = (evt) => {
    evt.preventDefault();
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const Btn = <Button icon='setting'
                      size='mini'
                      basic
                      color='blue'
                      onClick={ handleClick } />;

  return (
    <>
      <SettingsModal  title={ title }
                      opened={ opened }
                      onClose={ handleClose } />

      <Popup  trigger={ Btn }
              content={ title }
              position='top right' />
    </>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SettingsButton;
