import React, { Component }        from 'react';
import PropTypes                   from 'prop-types';
import { Dropdown, Divider, Grid } from 'semantic-ui-react';

import Organization from './Organization';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const addButtonStyle = {
  margin: '1.5rem 0'
};

function indexOn(list, attr) {
  return list.reduce((result, element) => {
    const key   = element[attr];
    result[key] = element;
    return result;
  }, {});
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class OrganizationList extends Component {
  constructor(props) {
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd    = this.handleAdd.bind(this);
  }

  render() {
    const { user, organizations } = this.props;
    const organizationIds         = user.organization_ids || [];

    const unusedOptions = organizations
                          .filter(({ id }) => (
                            !organizationIds.includes(id)
                          ))
                          .map(({ name, id }) => (
                            { text: name, value: id }
                          ));

    const organizationsIndex = indexOn(organizations, 'id');

    return (
      <div>
        <Divider horizontal style={{ marginBottom: '2rem' }}>Organizations</Divider>

        <Grid columns={ 2 } divided='vertically' verticalAlign='middle'>
          {
            organizationIds.map((id, i) => (
              <Organization key={ id }
                            index={ i }
                            organization={ organizationsIndex[id] }
                            onRemove={ this.handleRemove } />
            ))
          }
        </Grid>

        <Dropdown text='Select an organization to assign user'
                  search
                  fluid
                  labeled
                  basic
                  button
                  className='icon'
                  icon='add'
                  style={ addButtonStyle }
                  selectOnBlur={ false }
                  selectOnNavigation={ false }
                  options={ unusedOptions }
                  onChange={ this.handleAdd } />
      </div>
    );
  }

  handleAdd(evt, { value }) {
    const { user, name, onChange }  = this.props;
    const organizationIds           = user.organization_ids || [];
    organizationIds.push(value);

    onChange(evt, { name, value: organizationIds });
  }

  handleRemove(evt, { index }) {
    const { user, name, onChange }  = this.props;
    const organizationIds           = user.organization_ids || [];
    organizationIds.splice(index, 1);

    onChange(evt, { name, value: organizationIds });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

OrganizationList.propTypes = {
  name:           PropTypes.string.isRequired,
  organizations:  PropTypes.array.isRequired,
  user:           PropTypes.object.isRequired,
  onChange:       PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default OrganizationList;
