import React, { Component }            from 'react';
import { Grid, Header, Form, Card } from 'semantic-ui-react';
import { chartTypes } from 'constants/reports/Charts';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class ChartTypeSelection extends Component {
  render() {
    const { onChartSelection } = this.props;

    return (
        <Form>
        <Header as='h2'>Select a Chart Type</Header>

        <Grid.Row>
        <Grid.Column style={{marginBottom: '1em'}}>
            <Card.Group itemsPerRow={3}>
              { Object.keys(chartTypes).map( (viewType, index) => {
                const chart = chartTypes[viewType];
                return (
                  <Card key={`card-${index}`}
                    value={viewType}
                    image={chart.image}
                    description={chart.description}
                    onClick={onChartSelection}
                  />
                );
            }) }
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
        </Form>
      );
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ChartTypeSelection;
