import React, { Component }  from 'react';
import { Button }            from 'semantic-ui-react';
import PropTypes  from 'prop-types';

import LogicalExpression from './LogicalExpression';

import 'style/components/ConditionsViz.css';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ConditionsViz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentConditions: props.conditions,
      modifiedConditions: false,
      editConditions: false
    };

    this.handleEdit   = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidUpdate() {
    const { conditions }                            = this.props;
    const { currentConditions, modifiedConditions } = this.state;

    if(!modifiedConditions && currentConditions !== conditions) {
      this.setState({currentConditions: conditions});
    }
  }

  render() {
    const { editable, loading, ...forwardProps }  = this.props;
    const { currentConditions, editConditions }   = this.state;

    if(currentConditions === null || currentConditions === undefined) {
      return null;
    }

    return (
      <div className='conditions-viz'>
        <LogicalExpression expression={ currentConditions }
                           onChange={ this.handleChange }
                           editable={ editable && editConditions }
                           { ...forwardProps } />

        { editable && !editConditions &&
          <Button style={ { marginTop: '1em' } }
                  primary
                  loading={ loading }
                  icon='refresh'
                  labelPosition='left'
                  content='Modify Results'
                  onClick={ this.handleEdit } />
        }
        { editable && editConditions &&
        <div style={ { marginTop: '1em' } }>
          <Button primary
                  content='Change'
                  onClick={ this.handleUpdate } />
          <Button negative basic
                  content='Cancel'
                  onClick={ this.handleCancel } />
        </div>
        }
      </div>
    );
  }

  handleEdit() {
    this.setState({ editConditions: true });
  }

  handleChange({ expression }) {
    this.setState({
      currentConditions: expression,
      modifiedConditions: true,
    });
  }

  handleUpdate() {
    const { onUpdate }   = this.props;
    const { currentConditions } = this.state;

    this.setState({ editConditions: false });

    onUpdate({ expression: currentConditions });
  }

  handleCancel() {
    const { conditions } = this.props;

    this.setState({
      editConditions: false,
      modifiedConditions: false,
      currentConditions: conditions
    });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ConditionsViz.propTypes = {
  conditions: PropTypes.oneOfType([
    PropTypes.shape({ and: PropTypes.array }),
    PropTypes.shape({ or: PropTypes.array })
  ]).isRequired,

  editable: PropTypes.bool,

  fieldsLookup: PropTypes.objectOf(PropTypes.shape({
    label:        PropTypes.string,
    formatValue:  PropTypes.func
  }))
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ConditionsViz;
