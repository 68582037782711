import React from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Segment, Header, List } from 'semantic-ui-react';

import clientUrlFor from 'utils/clientUrlFor';

const style = { grid: { height: '100%' } };

const authenticationScopeLoginUrl = authScope => (
  clientUrlFor({subdomain: authScope.key, path: '/login'})
);

const SelectAccount = ({ authenticationScopes }) => {
  if(authenticationScopes.length === 0){
    return <Redirect to='/login' />;
  }

  return (
    <Grid verticalAlign='middle' centered columns='3' style={style.grid}>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' textAlign='center'>CoactionNet</Header>

          <Segment>
            <Header>Select Account to Login</Header>

            <List divided link size='large'>
              {
                authenticationScopes.map( scope => (
                  <List.Item
                    key={ scope.key }
                    as='a'
                    href={ authenticationScopeLoginUrl(scope) }
                  >
                    { scope.description }
                  </List.Item>
                ) )
              }
            </List>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SelectAccount;
