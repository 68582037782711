import React, { Component } from 'react';
import { Modal,
         Header,
         Button }           from 'semantic-ui-react';
import connectResource      from 'utils/connectResource';
import { getChangedAnswer } from 'utils/getChangedAnswer';
import { clients }          from 'resources/organizationResources';
import FormView             from 'containers/organization/clients/FormView';

import 'style/components/Modal.css';

// -----------------------------------------------------
// Helpers / Constants
// -----------------------------------------------------

const anchor = '#create-client-modal';

function getDescription(hasResults) {
  return hasResults ? 'Create a different client as ' : 'No results found. Create';
}

function getInitialStateFromClientMapping(clientFieldMapping, searchValue, answers, changes) {
  if (!clientFieldMapping) {
    const [firstName, ...restNames] = searchValue.split(' ');
    const lastName = restNames.pop();
    const middleName = restNames.join(' ');

    return {
      id: null,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName
    };
  }

  const initialState = { id: null, ...clientFieldMapping };

  Object.keys(initialState).forEach((key) => {
    const answer = getChangedAnswer(initialState[key], changes, answers);
    initialState[key] = key === 'gender' ? answer.toLowerCase() : answer;
  });

  return initialState;
}

function setModalPosition (x, y) {
  if (!x && !y) { return {}; }

  return { position: 'absolute', left: `${x}px`, top: `${y}px` };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class CreateClientModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      x: null,
      y: null
    };

    this.modal = React.createRef();

    this.onMouseDown   = this.onMouseDown.bind(this);
    this.onMouseMove   = this.onMouseMove.bind(this);
    this.onMouseUp     = this.onMouseUp.bind(this);
  }

  render() {
    const { opened,
            value,
            answers,
            changes,
            clientFieldMapping,
            hasResults,
            handleCloseModal,
            handleOpenModal,
            handleCreateClient } = this.props;

    const { x, y } = this.state;
    const modalPosition = setModalPosition(x, y);

    const initialState = getInitialStateFromClientMapping(clientFieldMapping, value, answers, changes);

    return (
        <React.Fragment>
          <Modal open={ opened } onClose={ handleCloseModal } dimmer='inverted' closeIcon
                 ref={ this.modal }
                 style={ modalPosition }>
            <Header icon="address card" content="Create and Link Client" onMouseDown={ this.onMouseDown } />
            <Modal.Content>
              <FormView match={ { params: {} } }
                        hideSubmitButton={ true }
                        initialState={ initialState }
                        onCreate={ record => {
                              return this.props.create(record).then(res => {
                                if(res.id) {
                                  handleCreateClient(res);
                                  handleCloseModal();
                                }
                                return res;
                              });
                            }}
                />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={ handleCloseModal } negative basic>Cancel</Button>
              <Button form='client-form' type='submit' positive basic>Create and link Client</Button>
            </Modal.Actions>
          </Modal>
          <div style={ { 'margin': '1em 0' } }>
            { getDescription(hasResults) } <a onClick={ handleOpenModal } href={ anchor } > { value }</a>?
          </div>
        </React.Fragment>
    );
  }

  onMouseDown = (e) => {
    const {x, y} = this.modal.current.ref.getBoundingClientRect();
    const startX = e.clientX - x;
    const startY = e.clientY - y;

    this.setState({ x, y, startX, startY});

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  };

  onMouseMove = (e) => requestAnimationFrame(() => {
    const { startX, startY } = this.state;
    this.setState({
      x: e.clientX - startX,
      y: e.clientY - startY
    });
  });

  onMouseUp = () => {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  };

}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(CreateClientModal, {
  connectors: {},
  mapDispatchToProps: { ...clients.actions }
});
