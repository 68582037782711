// -----------------------------------------------------
// DEPRECATED!  Use UserAccountResource instead
// -----------------------------------------------------

import createResource       from '../utils/resource';
import { generateReducers } from '../utils/reducers';
import * as types           from '../constants/ActionTypes';

const initialState = null;

const userAccount = createResource({
  name:     'userAccount',
  basepath: 'user_account',
  selector: state => state.userAccount,
  initialState,

  actions: {
    get: {
      method: 'GET',
      path:   '/',
      response : (state, action) => {
        return action.payload;
      }
    },

    update: {
      method: 'PUT',
      path:   '/',
      response: (state, action) => {
        return action.payload;
      }
    }
  },

  reducer: generateReducers({
    [types.DEAUTH_REQUESTED]: () => initialState,
    [types.AUTH_FAILED]: () => initialState
  })
});

export default userAccount;
