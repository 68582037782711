import React from 'react';
import { List, Radio }  from 'semantic-ui-react';
import { Button, Popup } from 'semantic-ui-react';
import uniqueId         from 'utils/uniqueId';

function FilterButton(onFilter, showFilters, handleShowFilters) {
  if (onFilter === null || onFilter === undefined) { return null; }

  const Btn = <Button
    active={showFilters}
    floated='right'
    icon='clone'
    size='small'
    compact
    onClick={handleShowFilters}
    basic />;

    return <Popup trigger={Btn}
      content={'Show filters'}
      position='right center' />;
}

function FilterList(filters, handleFilter) {
  const availableFilters = Object.keys(filters).filter(key => key !== 'text');
  const groupName = uniqueId();

  return <div className="record-list-filters">
    <List horizontal>
      {
        availableFilters.map((filter) => (
          <List.Item key={ filter }>
            <Radio  name={ groupName }
                    value={ filter }
                    checked={ filters[filter] }
                    label={ `Show ${filter}` }
                    onChange={ () => handleFilter(filter) } />
          </List.Item>
        ))
      }
    </List>
  </div>;
}

export { FilterButton, FilterList };
