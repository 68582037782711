import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form, Icon, Grid, Button } from 'semantic-ui-react';

import WithSuperuserRole    from 'containers/WithSuperuserRole';

import ParameterWarning       from 'components/forms/ParameterWarning';
import AdvancedSettingsButton from './AdvancedSettingsButton';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const draggingStyle = {
  userSelect: 'none',
  background: 'lightyellow'
};

const baseStyle = {
  transition: 'background 0.75s'
};

function getDraggableStyle(isDragging, draggableStyle) {
  const style = { ...draggableStyle, ...baseStyle };

  if(isDragging) {
    return { ...style, ...draggingStyle,  };
  }

  return style;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Field extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
    this.handleRemove  = this.handleRemove.bind(this);
  }

  render() {
    const { formField,
            formFields,
            allFields,
            label,
            draggableProvided,
            draggableSnapshot } = this.props;

    const { dragHandleProps, draggableProps } = draggableProvided;
    const { field_type,
            is_required,
            is_quick_view,
            is_locked,
            settings }  = formField;

    const isPresentation = field_type === 'presentation';

    const style = getDraggableStyle(
      draggableSnapshot.isDragging,
      draggableProps.style
    );

    return (
      <Grid.Row { ...draggableProps } style={ style }>
        <Grid.Column width={ 1 }>
          <Icon name='bars'
                size='large'
                { ...dragHandleProps }
                tabIndex='-1' />

        </Grid.Column>

        <Grid.Column width={ 7 }>
          <h5 { ...dragHandleProps } tabIndex='-1'>
            { label }
            <ParameterWarning formField={ formField } />
          </h5>

        </Grid.Column>

        { !isPresentation ?
          <>
            <Grid.Column width={ 2 }>
              <Form.Checkbox  label='Required'
                              name='is_required'
                              checked={ is_required }
                              onChange={ this.handleChange } />
            </Grid.Column>

            <Grid.Column width={ 2 }>
              <Form.Checkbox  label='Preview Field'
                              name='is_quick_view'
                              checked={ is_quick_view }
                              onChange={ this.handleChange } />
            </Grid.Column>

            <Grid.Column width={ 2 }>
              <WithSuperuserRole>
                <Form.Checkbox  label='Locked'
                                name='is_locked'
                                checked={ is_locked }
                                onChange={ this.handleChange } />
              </WithSuperuserRole>
            </Grid.Column>
          </>
          :
          <Grid.Column width={ 6 } />
        }

        <Grid.Column width={ 1 } textAlign='right'>
          <Button.Group size='mini'>
            <AdvancedSettingsButton formField={ formField }
                                    formFields={ formFields }
                                    allFields={ allFields }
                                    fieldType={ field_type }
                                    settings={ settings }
                                    onSubmit={ this.handleChange } />

            <WithSuperuserRole when={ is_locked }>
              <Form.Button    basic
                              color='red'
                              icon='remove'
                              size='mini'
                              type='button'
                              tabIndex='-1'
                              onClick={ this.handleRemove } />
            </WithSuperuserRole>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
    );
  }

  handleChange(evt, { name, value, checked }) {
    const { formField, index, onChange } = this.props;
    const isCheckbox    = ['is_required', 'is_quick_view', 'is_locked'].includes(name);
    const nextValue     = isCheckbox ? checked : value;
    const nextFormField = { ...formField, [name]: nextValue };

    onChange(evt, { formField: nextFormField, index });
  }

  handleRemove(evt) {
    evt.preventDefault();
    const { formField, index, onRemove } = this.props;
    onRemove(evt, { formField, index });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Field.defaultProps = {
  formField: {
    id:           0,
    is_required:  false,
    position:     0
  }
};

Field.propTypes = {
  formField:    PropTypes.shape({
    id:                 PropTypes.number,
    field_type:         PropTypes.string,
    is_required:        PropTypes.bool,
    position:           PropTypes.number,
    metadata:           PropTypes.object,
    parameters_mapping: PropTypes.object
  }),

  formFields: PropTypes.array.isRequired,
  allFields: PropTypes.array.isRequired,

  label:                  PropTypes.string,
  index:                  PropTypes.number.isRequired,
  onChange:               PropTypes.func.isRequired,
  onRemove:               PropTypes.func.isRequired,
  draggableProvided:      PropTypes.object.isRequired,
  draggableSnapshot:      PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Field;
