// This is an adapter that let's the new JSON-based address control work with the
// old string-based address control.

import React from 'react';

import Address from './Address';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const LegacyAddress = ({ value, onChange, ...forwardProps }) => {
  const parsedValue = JSON.parse(value) || {};

  const handleChange = (evt, { name, value }) => {
    const stringValue = JSON.stringify(value);
    onChange(evt, { name, value: stringValue });
  };

  return (
    <Address  value={ parsedValue }
              onChange={ handleChange }
              { ...forwardProps } />
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default LegacyAddress;
