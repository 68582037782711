import React, { Component }             from 'react';
import { Modal, Button, Header, Table } from 'semantic-ui-react';
import LogItem                          from './LogItem';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class LogModal extends Component {

  render() {
    const { opened, imports = {}, onClose } = this.props;

    return (
        <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
          <Header icon="archive" content="Import / Batch Delete Log" />
          <Modal.Content>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Time of Import</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Records</Table.HeaderCell>
                  <Table.HeaderCell>Imported by</Table.HeaderCell>
                  <Table.HeaderCell>Input File</Table.HeaderCell>
                  <Table.HeaderCell>Errors</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  Object.entries(imports).map(([id , {administrator_name, state, type, number_of_records, input_url, error_url, created_at}]) => (
                    <LogItem  key={ id }
                              created_at={ created_at }
                              administrator={ administrator_name }
                              records={ number_of_records }
                              state={ state }
                              type={ type }
                              error_url={ error_url }
                              input_url={ input_url } />
                  ))
                }
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ onClose } negative basic>Close</Button>
          </Modal.Actions>
        </Modal>
      );
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default LogModal;