import React      from 'react';
import PropTypes  from 'prop-types';

import { Modal, Header, Button }  from 'semantic-ui-react';
import History from './History';

import { useHistoryContext } from './HistoryContextProvider';
import VersionHistoryResource   from 'resources/VersionHistoryResource';
import useOrganizationResource  from 'hooks/useOrganizationResource';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const HistoryModal = ({ opened, onClose }) => {
  const { recordId, recordType } = useHistoryContext();

  const versionHistory  = useOrganizationResource(
    VersionHistoryResource.singletonDetailShape(),
    { id: recordId, type: recordType, history: true }
  );

  const { history=[] } = versionHistory;

  return (
    <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
      <Header icon='history' content='History' />
      <Modal.Content>
        <History versions={ history } />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={ onClose } negative basic>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

HistoryModal.defaultProps  = {
  opened:  false,
  onClose: () => {}
};

HistoryModal.propTypes     = {
  opened:  PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default HistoryModal;
