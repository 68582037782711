import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Link }             from 'react-router-dom';
import { Button, Icon }     from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class LinkedView extends Component {
  render() {
    const { clientLink: { client }, onUnlink }  = this.props;
    const { first_name, last_name, id }         = client;

    const name  = `${first_name} ${last_name}`;

    return (
      <div>
        <p>
          This response is linked to the following client.
        </p>
        <p>
          <strong>
            <Link to={ `/organization/clients/${id}`} target='blank'>
              <Icon name='address card' />
              { name }
            </Link>
          </strong>
        </p>
        <p>
          To unlink this client, click the <em>Remove Link</em> button below.
        </p>

        <Button basic
                primary
                onClick={ onUnlink }>Remove Link</Button>
      </div>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

LinkedView.propTypes = {
  clientLink: PropTypes.shape({
    client: PropTypes.object,
    form:   PropTypes.object,
    id:     PropTypes.number
  }).isRequired,
  onUnlink: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default LinkedView;
