import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { Form, Grid, Header } from 'semantic-ui-react';

import { computeSelectedFields }  from 'utils/reportDefinitions';
import ColumnOrderList            from './ColumnOrderList';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class LayoutConfiguration extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { reportDefinition: { default_order } } = this.props;
    const selectedFields = computeSelectedFields(this.props);

    return (
      <Form>
        <Header as='h2'>Configure Layout</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <h4>Set the column order for your report.</h4>
              <p>
                Drag and Drop to set column order.
                Topmost fields will appear as leftmost columns in your report
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <ColumnOrderList  fields={ selectedFields }
                                defaultOrder={ default_order }
                                name='fields'
                                onChange={ this.handleChange } />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  handleChange(evt, { name, value }) {
    const { onChange } = this.props;
    onChange({ [name]: value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

LayoutConfiguration.defaultProps = {
  reportDefinition: {},
  forms:            [],
  fields:           []
};

LayoutConfiguration.propTypes = {
  reportDefinition: PropTypes.object,
  forms:            PropTypes.array,
  fields:           PropTypes.array,
  formCalculations: PropTypes.object,
  onChange:         PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default LayoutConfiguration;
