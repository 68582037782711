import React        from 'react';
import { connect }  from 'react-redux';
import { Link }     from 'react-router-dom';

import {
  Grid,
  Segment,
  Header,
  Divider,
  Form
} from 'semantic-ui-react';

import AuthorizationResource  from 'resources/AuthorizationResource';
import useApiFetcher          from 'hooks/useApiFetcher';
import NetworkError           from 'components/api/NetworkError';
import * as types             from 'constants/ActionTypes';

// -----------------------------------------------------
// Utilities
// -----------------------------------------------------

const style = {
  grid: {
    'height': '100%'
  }
};

function loginHandler(history, login, dispatch) {
  return async (evt) => {
    const response = await login({}, new FormData(evt.target));

    if(response) {
      const { auth_token } = response;

      dispatch({
        type: types.AUTH_REFRESH,
        payload: { authToken: auth_token }
      });

      history.push('/');
    }
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const LoginContainer = ({ invitation, history, dispatch }) => {

  const login       = useApiFetcher(AuthorizationResource.createShape());
  const handleLogin = loginHandler(history, login, dispatch);
  const { email }   = invitation || {};

  return (
    <Grid verticalAlign='middle' centered columns='3' style={ style.grid }>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' textAlign='center'>CoactionNet</Header>
          <Segment>
              <Header>
                { invitation && 'Invitation Complete!' } Login
              </Header>

              <NetworkError local />
              <Divider />

              <Form onSubmit={ handleLogin }>
                <Form.Input
                  id='login-email'
                  label='Email'
                  name='email'
                  readOnly={!!invitation}
                  value={ email }
                  placeholder='e.g. john@example.com'
                  autoComplete='off'
                  autoFocus
                />
                <Form.Input
                  id='login-password'
                  type='password'
                  label='Password'
                  name='password'
                  autoComplete='off'
                />

                <Form.Button type='submit' primary>Login</Form.Button>
                <Link to="/forgotPassword">Forgot Password?</Link>
              </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    invitation: state.invitations
  };
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default connect(mapStateToProps)(LoginContainer);
