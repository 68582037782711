import React, { useState }  from 'react';
import { Button, Popup }    from 'semantic-ui-react';

import FormModal        from './FormModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Add Family Member';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const AddButton = ({clientId}) => {
  const [opened, setOpened] = useState(false);

  const handleClick = (evt) => {
    evt.preventDefault();
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const Btn = <Button icon='add'
                      size='mini'
                      basic
                      color='blue'
                      onClick={ handleClick } />;

  return (
    <>
      <FormModal title={ title }
                opened={ opened }
                clientId={ clientId }
                onClose={ handleClose } />

      <Popup  trigger={ Btn }
              content={ title }
              position='top right' />
    </>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AddButton;
