import React        from 'react';
import { Message }  from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const SuccessView = () => {
  return (
    <Message positive>
      <Message.Header>Success!</Message.Header>
      <p>Version rollback was successful.</p>
    </Message>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

SuccessView.defaultProps  = {};
SuccessView.propTypes     = {};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default SuccessView;