import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Button }           from 'semantic-ui-react';

import ConditionModal       from './Modal';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class ConditionEmpty extends Component {

  // construction
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };

    this.handleClick  = this.handleClick.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  // event handlers
  handleClick() {
    this.setState({ opened: true });
  }
  handleClose() {
    this.setState({ opened: false });
  }

  // rendering
  render() {
    const { conditions, fields, onChange } = this.props;
    const { opened }                       = this.state;

    const label = 'Add a condition to this notification.';
    const title = 'Add conditions';

    return (
      <div className='condition__empty'>
        <ConditionModal opened={ opened }
                        title={ title }
                        conditions={ conditions }
                        fields={ fields }
                        onClose={ this.handleClose }
                        onSubmit={ onChange } />

        <label>
          <Button basic
                  color='blue'
                  icon='add'
                  size='mini'
                  onClick={ this.handleClick } />

          <span className='btn__label'>{ label }</span>
        </label>
      </div>
    );
  }
}

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

ConditionEmpty.propTypes = {
  conditions: PropTypes.object,
  fields:     PropTypes.array.isRequired,
  onChange:   PropTypes.func.isRequired
};


// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ConditionEmpty;
