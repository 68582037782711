import React              from 'react';
import PropTypes          from 'prop-types';
import { Button, Popup }  from 'semantic-ui-react';
import { Link }           from 'react-router-dom';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Edit this form.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const EditButton = ({ clientId, clientFormId }) => {
  const href =
    `/organization/clients/${clientId}/client_forms/${clientFormId}/edit`;

  const Btn = <Button icon='pencil'
                      basic
                      color='blue'
                      as={ Link }
                      to={ href } />;

  return (
    <Popup  trigger={ Btn }
            content={ title }
            position='top center' />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditButton.propTypes = {
  clientId:     PropTypes.number.isRequired,
  clientFormId: PropTypes.number.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditButton;
