
// -----------------------------------------------------
// Class Definition
// -----------------------------------------------------

class SortQueue {
  constructor(size) {
    this.maxSize  = size;
    this.buffer   = [];
  }

  // Deduplicates buffer elements using the supplied attribute value.
  // Deduplication will remove the oldest duplicate elements from the buffer,
  // keeping the newer elements up to the max buffer size.
  compactOn(attribute) {
    const existing = {};

    return  this.buffer
            .reduceRight((compacted, element) => {
              if(compacted.length >= this.maxSize) {
                return compacted;
              }

              const key = element[attribute];

              if(!existing[key]) {
                compacted.unshift(element);
                existing[key] = true;
              }

              return compacted;
            }, [])
            .filter(({ direction }) => direction !== 0);
  }

  // Pushes a new sortItem into the buffer.  This does not mutate the buffer,
  // rather it returns a new buffer.
  push(index, direction) {
    const { buffer, maxSize } = this;

    buffer.push({ index, direction });

    // Create a new, compact buffer instance to return.
    const nextBuffer  = new SortQueue(maxSize);
    nextBuffer.buffer = this.compactOn('index');

    return nextBuffer;
  }

  reduce(...args) {
    return this.buffer.reduce(...args);
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SortQueue;
