import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import { Form }                       from 'semantic-ui-react';

import ReferralFormFieldsMapping from './ReferralFormFieldsMapping';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const changeEventName = 'case_worthy_affiliation';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const OrganizationConfigForm = ({ organization, forms, onChange }) => {
  const affiliation                   = organization.case_worthy_affiliation;
  const hasAffiliation                = affiliation !== null
                                        && affiliation !== undefined;
  const settings                      = affiliation?.settings || {};

  const { template_referral_form_id, 
          case_worthy_organization_id } = settings;

  const [showForm, setShowForm] = useState();

  useEffect(() => {
    setShowForm(hasAffiliation);
  }, [hasAffiliation, setShowForm]);

  const formOptions = forms.map(({ name, id }) => ({
    text:   name,
    value:  id,
    key:    id
  }));

  const handleAffiliateChecked = (evt, { checked }) => {
    setShowForm(checked);

    if(checked) {
      onChange(evt, {
        name:   changeEventName,
        value:  { ...settings }
      });
    } else {
      onChange(evt, {
        name:   changeEventName,
        value:  { _destroy: true }
      });
    }
  };

  const handleChange = (evt, { name, value }) => {
    onChange(evt, {
      name: changeEventName,
      value: {
        settings: {
          ...settings,
          [name]: value
        }
      }
    });
  };

  return (
    <>
      <Form.Checkbox  label='Integrate with DTD/CaseWorthy'
                      onChange={ handleAffiliateChecked }
                      checked={ showForm } />

      {
        showForm
        && (
          <>
            <Form.Input   name='case_worthy_organization_id'
                          value={ case_worthy_organization_id }
                          label='CaseWorthy Organization ID'
                          onChange={ handleChange } />

            <Form.Select  name='template_referral_form_id'
                          value={ template_referral_form_id }
                          label='DTD Referral Form'
                          placeholder='Select to designate a referral form'
                          options={ formOptions }
                          selectOnBlur={ false }
                          search
                          onChange={ handleChange } />

            {
              template_referral_form_id
              && <ReferralFormFieldsMapping settings={ settings }
                                            onChange={ handleChange } />
            }
          </>
        )
      }
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

OrganizationConfigForm.defaultProps = {

};

OrganizationConfigForm.propTypes = {
  organization: PropTypes.object.isRequired,
  forms:        PropTypes.array.isRequired,
  onChange:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default OrganizationConfigForm;
