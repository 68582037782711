import _ from 'lodash';

import createResource             from 'utils/resource';
import * as crudActions           from 'utils/resourceActions';
import * as singletonActions      from 'utils/singletonResourceActions';
import * as types                 from 'constants/ActionTypes';

import {  mergeNormalizeReducer,
          generateReducers,
          overrideReducer,
          reduceWithSubgroup }  from 'utils/reducers';

import { formDataRequestMapper } from 'utils/requestMappers';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

// Resets the current org to an empty record.
const resetOrgReducer = generateReducers({
  'RESET_ORG': () => ({})
});

// Allows us to create state segments on record type.
const polymorphicRecordResponseReducer = (state, action) => {
  const { payload, scope: { record_type, record_id } } = action;
  const norm                = _.keyBy(payload, 'id');
  const recordTypeState     = state[record_type] || {};
  const nextRecordTypeState = { ...recordTypeState, [record_id]: norm };

  return { ...state, [record_type]: nextRecordTypeState };
};

const selectOrgScope = state => ({ organization: state.orgdomain });

const { create:   createAction,
        update:   updateAction,
        destroy:  destroyAction } = crudActions;

// -----------------------------------------------------
// Data Groups
// -----------------------------------------------------

export const dataGroups = createResource({
  name:         'dataGroups',
  selector:     (state) => state.organization.dataGroups,
  basepath:     '/organization/:organization/data_groups',
  actions: {
    ...crudActions
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Data Group Records
// -----------------------------------------------------

export const dataGroupRecords = createResource({
  name:         'dataGroupRecords',
  selector:     (state) => state.organization.dataGroupRecords,
  basepath:     '/organization/:organization/:record_type/:record_id/data_group_records',
  actions:      {
    get: {
      method:   'GET',
      path:     '/',
      response: polymorphicRecordResponseReducer
    },

    update: {
      method:   'PUT',
      path:     '/',
      response: polymorphicRecordResponseReducer
    }
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Record Cloning
// -----------------------------------------------------

export const cloneRecords = createResource({
  name:         'cloneRecords',
  selector:     (state) => state.organization.cloneRecords,
  basepath:     '/organization/:organization/:cloneable_type/:cloneable_id/clone',
  actions: {
    clone: crudActions.create
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Impersonations
// -----------------------------------------------------

export const impersonation = createResource({
  name:         'impersonation',
  selector:     (state) => state.impersonation,
  initialState: null,
  basepath:     '/organization/:organization/impersonations',
  actions:  {
    get: {
      method:   'GET',
      response: (state, action) => action.payload
    },
    create:   {
      method:   'POST',
      response: overrideReducer
    },
    destroy:  {
      method:   'DELETE',
      response: () => null
    }
  },
  selectScope:  selectOrgScope,
  reducer: generateReducers({
    [types.DEAUTH_REQUESTED]: () => null,
    [types.AUTH_FAILED]:      () => null
  })
});

// -----------------------------------------------------
// Peer Organizations
// -----------------------------------------------------

export const peerOrganizations = createResource({
  name:         'peerOrganizations',
  selector:     (state) => state.organization.peerOrganizations,
  basepath:     '/organization/:organization/peer_organizations',
  actions: {
    index: crudActions.index
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Affiliations
// -----------------------------------------------------

export const affiliations = createResource({
  name:         'affiliations',
  selector:     (state) => state.organization.affiliations,
  basepath:     '/organization/:organization/affiliations',
  actions:      {
    ...crudActions
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Public Form Client Link
// -----------------------------------------------------

export const publicFormClientLink = createResource({
  name:     'publicFormClientLink',
  selector: (state) => state.organization.publicFormClientLink,
  basepath: '/organization/:organization/public_form_instances/:public_form_instance_id/public_form_instance_client_form_links',
  actions: {
    create:   {
      method: 'POST',
      path: '/',
      response : (state, action) => {
        return action.payload;
      }
    },
    get:      singletonActions.get,
    destroy:  singletonActions.destroy
  },
  selectScope: selectOrgScope,
  reducer:     resetOrgReducer
});

// -----------------------------------------------------
// Calculations
// -----------------------------------------------------

export const calculations = createResource({
  name:   'calculations',
  selector: state => state.organization.calculations,
  basepath: '/organization/:organization/forms/:form_id/calculations',
  actions: {
    create:   crudActions.create,
    update:   crudActions.update,
    destroy:  crudActions.destroy,
    index: {
      method:   'GET',
      path:     '/',
      response: (state, action) => _.keyBy(action.payload, 'id')
    }
  },
  selectScope: selectOrgScope,
  reducer:     resetOrgReducer
});

export const calculationsOrder = createResource({
  name:   'calculationsOrder',
  selector: state => state.organization.calculations,
  basepath: '/organization/:organization/forms/:form_id/calculations_order',
  actions: {
    update:   crudActions.update,
  },
  selectScope: selectOrgScope,
  reducer:     resetOrgReducer
});

// -----------------------------------------------------
// Public Forms
// -----------------------------------------------------

export const public_forms = createResource({
  name:     'public_forms',
  selector: state => state.organization.public_forms,
  basepath: '/organization/:organization/public/public_forms',
  actions:  {
    show: crudActions.show
  },
  selectScope: selectOrgScope,
  reducer:     resetOrgReducer
});

// -----------------------------------------------------
// Public Form Instances
// -----------------------------------------------------

export const public_form_instances = createResource({
  name:     'public_form_instances',
  basepath: '/organization/:organization/public/public_forms/:access_key/public_form_instances',
  actions: {

    // Override create, to encode form parameters as multipart data.  For this
    // to work, we also have to remove the 'Content-Type' header.
    create: {
      ...createAction,
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper
    }
  },
  selectScope: selectOrgScope,
  reducer:     resetOrgReducer
});

// -----------------------------------------------------
// Public Form Settings
// -----------------------------------------------------

export const public_form_settings = createResource({
  name:     'public_form_settings',
  selector: state => state.organization.public_form_settings,
  basepath: '/organization/:organization/forms/:form_id/public_forms',
  actions: {
    find: {
      path : '/',
      method : 'GET',
      response : mergeNormalizeReducer,
      mapResponse: (res, scope) => res.map(r => ({...scope, ...r}))
    },
    create: {
      method: 'POST',
      response: mergeNormalizeReducer,
      mapResponse: (res, scope) => ({...scope, ...res})
    },
    update: crudActions.update
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Public Form Answers
// -----------------------------------------------------

export const publicFormAnswers = createResource({
  name:     'publicFormAnswers',
  selector: state => state.organization.publicFormAnswers,
  basepath: '/organization/:organization/public_forms',
  actions: {
    index:          crudActions.index,
    show:           crudActions.show,
    hardIndex:      crudActions.hardIndex,
    paginatedIndex: crudActions.paginatedIndex
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Public Form Responses
// -----------------------------------------------------

export const publicFormResponses = createResource({
  name:     'publicFormResponses',
  selector: (state) => state.organization.publicFormResponses,
  basepath: '/organization/:organization/public_forms/:public_form_id/public_form_instances',
  actions: {
    paginatedIndex:  {
      ...crudActions.paginatedIndex,

      // FIXME: This is a hack to work around a deficiency in our resource =>
      // state management lifecycle.  Specifically, there's no smart way to
      // configure reducers to know when to merge state into existing state
      // or when to completely replace it.  In this case, we want to completely
      // replace the public form responses state when its public form scope
      // changes.
      response: (state, action) => {
        const previousRecords = Object.values(state);
        const { scope: { public_form_id: currentScope } } = action;

        const overrideState =
          previousRecords
          .filter(({ public_form_id }) => (public_form_id !== undefined))
          .some(({ public_form_id }) => (currentScope !== public_form_id));

        const nextState = overrideState ? {} : state;

        return crudActions.paginatedIndex.response(nextState, action);
      }
    },
    show:   crudActions.show,

    // Override update, to encode form parameters as multipart data.  For this
    // to work, we also have to remove the 'Content-Type' header.
    update: {
      ...updateAction,
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper
    },

    destroy: destroyAction,
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Public Form Response Detail
// -----------------------------------------------------

export const publicFormResponseDetail = createResource({
  name:     'publicFormResponseDetail',
  selector: state => state.organization.publicFormResponseDetail,
  basepath: '/organization/:organization/public_form_instances',
  actions: {
    show: crudActions.show
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Permission Groups
// -----------------------------------------------------

export const permission_groups = createResource({
  name:     'permission_groups',
  selector: state => state.organization.permission_groups,
  basepath: '/organization/:organization/permission_groups',
  actions: {
    ...crudActions
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Users
// -----------------------------------------------------

export const users = createResource({
  name : 'users',
  selector : state => state.organization.users,
  basepath : '/organization/:organization/users',
  actions : {
    ...crudActions
  },
  selectScope : selectOrgScope,
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Clients
// -----------------------------------------------------

export const clients = createResource({
  name : 'clients',
  selector : state => state.organization.clients,
  basepath : '/organization/:organization/clients',
  actions : {
    ...crudActions
  },
  selectScope : selectOrgScope,
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Fields
// -----------------------------------------------------

export const fields = createResource({
  name : 'fields',
  selector : state => state.organization.fields,
  basepath : '/organization/:organization/fields',
  actions : {
    ...crudActions
  },
  selectScope : selectOrgScope,
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Forms
// -----------------------------------------------------

export const forms = createResource({
  name:     'forms',
  selector: state => state.organization.forms,
  basepath: '/organization/:organization/forms',
  actions: {
    ...crudActions
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Form Fields
// -----------------------------------------------------

export const formFields = createResource({
  name:     'formFields',
  selector: state => state.organization.formFields,
  basepath: '/organization/:organization/forms/:form_id/fields',
  actions: {
    index: {
      method:   'GET',
      path:     '/',
      response: (state, action) => _.keyBy(action.payload, 'id')
    }
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Form Imports
// -----------------------------------------------------

export const formImports = createResource({
  name:     'formImports',
  selector: state => state.organization.formImports,
  basepath: '/organization/:organization/forms/:form_id/imports',
  actions : {
    ...crudActions,
    create: {
      ...createAction,
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper
    },
    get: {
      method: 'GET',
      path:   '/',
      response: (state, action) => action.payload
    },
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Form Sequences
// -----------------------------------------------------

export const sequences = createResource({
  name : 'sequences',
  selector : state => state.organization.sequences,
  basepath : '/organization/:organization/sequences',
  actions : {
    ...crudActions
  },
  selectScope : selectOrgScope,
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Internal Forms
// -----------------------------------------------------

export const internalForms = createResource({
  name:     'internalForms',
  selector: (state) => state.organization.internalForms,
  basepath: '/organization/:organization/internal_forms',
  actions: {
    index:          crudActions.index,
    paginatedIndex: crudActions.paginatedIndex,
    show:           crudActions.show,
    update:         crudActions.update
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Internal Form Records
// -----------------------------------------------------

export const internalFormRecords = createResource({
  name:         'internalFormRecords',
  selector:     (state) => state.organization.internalFormRecords,
  basepath:     '/organization/:organization/internal_forms/:form_id/records',
  actions:      {
    ...crudActions,
    index: {
      method: 'GET',
      path: '/',
      response : (state, action) => _.keyBy(action.payload, 'id')
    },

    // Override create, to encode form parameters as multipart data.  For this
    // to work, we also have to remove the 'Content-Type' header.
    create: {
      ...createAction,
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper
    },

    // Override update, to encode form parameters as multipart data.  For this
    // to work, we also have to remove the 'Content-Type' header.
    update: {
      ...updateAction,
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper
    }
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Client Forms
// -----------------------------------------------------

const groupResponseOnFormIdReducer = reduceWithSubgroup('client_id', 'form_id');

export const clientForms = createResource({
  name: 'clientForms',
  selector: state => state.organization.clientForms,
  basepath: '/organization/:organization/clients/:client_id/client_forms',
  actions: {
    ...crudActions,
    /* override the index so only forms are for last requested client.
    works for now, probably better to make subkeys for each client and their forms */
    index: {
      method : 'GET',
      path : '/',
      response: mergeNormalizeReducer,
    },

    paginatedIndex: {
      ...crudActions.paginatedIndex,
      response: (state, action) => {
        const { scope: { page=1, form_id, client_id }={} } = action;
        const nextState = { ...state };

        if(page === 1) {
          _.setWith(nextState, [client_id, form_id], {}, Object);
        }

        return {
          ...groupResponseOnFormIdReducer(nextState, action),
          _indexed: (new Date()).getTime()
        };
      }
    },

    // Override create, to encode form parameters as multipart data.  For this
    // to work, we also have to remove the 'Content-Type' header.
    create: {
      ...createAction,
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper
    },

    // Override update, to encode form parameters as multipart data.  For this
    // to work, we also have to remove the 'Content-Type' header.
    update: {
      ...updateAction,
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper
    }
  },
  selectScope : selectOrgScope,
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Report Definitions
// -----------------------------------------------------

export const reportDefinitions = createResource({
  name:         'reportDefinitions',
  selector:     state => state.organization.reportDefinitions,
  basepath:     '/organization/:organization/report_definitions',
  actions:      {
    ...crudActions
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Report Results
// -----------------------------------------------------

const mergeResultRecordsReducer = (state, action) => {
  const { payload, scope: { page } }  = action;
  const { id, records }               = payload;
  const currentResults                = state[id] || {};

  const currentRecords  = page === 1
                          ? []
                          : currentResults.records || [];

  const nextState = {
    ...state,
    [id]: {
      ...currentResults,
      ...payload,
      records: [
        ...currentRecords,
        ...(records || [])
      ]
    }
  };

  return nextState;
};

export const reportViews = createResource({
  name:         'reportViews',
  selector:     state => state.organization.reportViews,
  basepath:     '/organization/:organization/report_definitions/:report_id/report_views',
  actions:      {
    show: {
      method:       'GET',
      path:         ':id/page/:page',
      mapResponse:  payload => ({ ...payload, _indexed: true }),
      response:     mergeResultRecordsReducer
    },
    create:  crudActions.create,
    update:  crudActions.update,
    destroy: crudActions.destroy,
    refetch: crudActions.show,
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

export const reportResults = createResource({
  name:         'reportResults',
  selector:     state => state.organization.reportResults,
  basepath:     '/organization/:organization/report_results',
  actions:      {
    show:    crudActions.show,
    refresh: crudActions.update,
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

export const reportSummary = createResource({
  name:         'reportSummary',
  selector:     state => state.organization.reportSummary,
  basepath:     '/organization/:organization/report_definitions/:report_id/report_summary',
  actions:      {
    show:   crudActions.show,
    create:  crudActions.create,
    update:  crudActions.update,
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Notes
// -----------------------------------------------------

export const notes = createResource({
  name:     'notes',
  selector: state => state.organization.notes,
  basepath: '/organization/:organization/:notable_type/:notable_id/notes',

  actions: {
    ...crudActions,

    // We override this so that we're not merging notes from one record into
    // those of another. In this case, we use the overrideReducer to rewrite
    // the current notes context.
    index: {
      path:   '/',
      method: 'GET',
      response: (state, action) => _.keyBy(action.payload, 'id')
    }
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Record Permissions
// -----------------------------------------------------

export const recordPermissions = createResource({
  name: 'recordPermissions',
  selector: state => state.organization.recordPermissions,
  basepath: '/organization/:organization/:record_type/:record_id/record_permissions',
  actions: {
    get: {
      method: 'GET',
      path:   '/',
      response: polymorphicRecordResponseReducer
    },
    update:   {
      method: 'PUT',
      path: '/',
      response: polymorphicRecordResponseReducer
    }
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Organization Account
// -----------------------------------------------------

export const organizationAccount = createResource({
  name:     'organizationAccount',
  selector: state => state.organization.organizationAccount,
  basepath: '/organization/:organization/organization_account',
  actions: {
    get: singletonActions.get,
    update: {
      method: 'PUT',
      path:   '/',
      transformHeaders: (headers) => (delete headers['Content-Type']),
      mapRequest: formDataRequestMapper,
      response: (state, action) => {
        return action.payload;
      }
    }
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

export const dashboardWidgets = createResource({
  name:     'dashboardWidgets',
  selector: state => state.organization.dashboardWidgets,
  basepath: '/organization/:organization/dashboard_widgets',
  actions: {
    index:    crudActions.index,
    create:   crudActions.create,
    update:   crudActions.update,
    destroy:  crudActions.destroy
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Pinned Messages
// -----------------------------------------------------

export const pinnedMessages = createResource({
  name:     'pinnedMessages',
  selector: state => state.organization.pinnedMessages,
  basepath: '/organization/:organization/pinned_messages',
  actions: {
    show:           crudActions.show,
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Permission Schema
// -----------------------------------------------------

export const permissions_schema = createResource({
  name : 'permissions_schema',
  selector : state => state.organization.permissions_schema,
  basepath : '/permissions_schema',
  actions: {
    get: singletonActions.get
  },
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Notification Definitions
// -----------------------------------------------------

export const notification_definitions = createResource({
  name : 'notification_definitions',
  selector : state => state.organization.notification_definitions,
  basepath : '/organization/:organization/:owner_type/:owner_id/notification_definitions',
  actions : {
    ...crudActions,
    find : {
      path : '/',
      method : 'GET',
      response : mergeNormalizeReducer
    }
  },
  selectScope : selectOrgScope,
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Notification Messages
// -----------------------------------------------------

export const notification_messages = createResource({
  name: 'notification_messages',
  selector: state => state.organization.notification_messages,
  basepath: '/organization/:organization/:owner_type/:owner_id/notification_messages',
  actions: {
    paginatedIndex: crudActions.paginatedIndex
  },
  selectScope : selectOrgScope,
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Notifications Metadata
// -----------------------------------------------------

export const notification_metadata = createResource({
  name : 'notification_metadata',
  selector : state => state.organization.notification_metadata,
  basepath : '/organization/:organization/:owner_type/:owner_id/notification_metadata',
  actions: {
    get: singletonActions.get
  },
  selectScope : selectOrgScope,
  reducer : resetOrgReducer
});

// -----------------------------------------------------
// Versions
// -----------------------------------------------------

export const versions = createResource({
  name:     'versions',
  selector: state => state.organization.versions,
  basepath: '/organization/:organization/:record_type/:record_id/versions',
  actions: {
    get: singletonActions.get,
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Categories
// -----------------------------------------------------

export const categories = createResource({
  name:         'categories',
  selector:     (state) => state.organization.categories,
  basepath:     '/organization/:organization/categories/',
  actions: {
    ...crudActions
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

export const categoriesByType = createResource({
  name:         'categoriesByType',
  selector:     (state) => state.organization.categoriesByType,
  basepath:     '/organization/:organization/categories/',
  actions: {
    index: {
      path:   '/',
      method: 'GET',
      response: (state, action) => _.keyBy(action.payload, 'id')
    }
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});

// -----------------------------------------------------
// Category Records
// -----------------------------------------------------

export const categoryRecords = createResource({
  name:         'categoryRecords',
  selector:     (state) => state.organization.categoryRecords,
  basepath:     '/organization/:organization/:record_type/:record_id/category_records',
  actions:      {
    get: {
      method:   'GET',
      path:     '/',
      response: polymorphicRecordResponseReducer
    },

    update: {
      method:   'PUT',
      path:     '/',
      response: polymorphicRecordResponseReducer
    }
  },
  selectScope:  selectOrgScope,
  reducer:      resetOrgReducer
});
