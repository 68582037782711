import React                    from 'react';
import { NavLink }              from 'react-router-dom';
import { Menu, Sidebar, Icon }  from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const Nav = () => {
  return (
    <Sidebar as={ Menu } icon='labeled' visible vertical inverted>
      <Menu.Item as={ NavLink } to='/admin/dashboard'>
        <Icon name='dashboard' />
        Dashboard
      </Menu.Item>
      <Menu.Item as={ NavLink } to='/admin/organizations'>
        <Icon name='building' />
        Organizations
      </Menu.Item>
      <Menu.Item as={ NavLink } to='/admin/users'>
        <Icon name='users' />
        Users
      </Menu.Item>
      <Menu.Item as={ NavLink } to='/admin/pricing-tiers'>
        <Icon name='money' />
        Pricing Tiers
      </Menu.Item>
      <Menu.Item as={ NavLink } to='/admin/fields'>
        <Icon name='terminal' />
        Field Templates
      </Menu.Item>
      <Menu.Item as={ NavLink } to='/admin/forms'>
        <Icon name='file' />
        Form Templates
      </Menu.Item>
      <Menu.Item as={ NavLink } to='/admin/imports'>
        <Icon name='database' />
        Data Imports
      </Menu.Item>
      <Menu.Item as={ NavLink } to='/admin/versions'>
        <Icon name='history' />
        Version History
      </Menu.Item>
    </Sidebar>
  );
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default Nav;
