import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { Modal, Button, Form }  from 'semantic-ui-react';

import ActionCell       from './ActionCell';
import findActionIndex  from 'utils/formLogic/findActionIndex';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const captureFormId = 'form-logic-value-capture';

function extractPayload({ actions, type, target }) {
  const index = findActionIndex(actions, type, target);

  if(index > -1) {
    return actions[index].payload;
  }

  return '';
}

const style = {
  payloadLink: {
    whiteSpace:   'nowrap',
    overflow:     'hidden',
    textOverflow: 'ellipsis',
    maxWidth:     '100px',
    display:      'inline-block',
    marginBottom: '-.33rem'
  }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class PayloadActionCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      capturePayload: false,
      payload:        extractPayload(props)
    };

    this.handleChange         = this.handleChange.bind(this);
    this.handlePayloadChange  = this.handlePayloadChange.bind(this);
    this.handleDismissCapture = this.handleDismissCapture.bind(this);
    this.handleCapture        = this.handleCapture.bind(this);
    this.handlePayloadClick   = this.handlePayloadClick.bind(this);
  }

  render() {
    const { type, target, actions, field }  = this.props;
    const { capturePayload, payload }       = this.state;
    const { label }                         = field;
    const currentPayload                    = extractPayload(this.props);

    return (
      <ActionCell type={ type }
                  target={ target }
                  actions={ actions }
                  onChange={ this.handleChange }>
        {
          currentPayload &&
          <div>
            <small>
              <a  href='#show-value'
                  onClick={ this.handlePayloadClick }
                  style={ style.payloadLink }>
                Value: <strong>{ currentPayload }</strong>
              </a>
            </small>
          </div>
        }
        {
          capturePayload &&
          <Modal dimmer='inverted' size='tiny' open>
            <Modal.Header>Set Value On: { label }</Modal.Header>
            <Modal.Content>
              <Form id={ captureFormId }
                    onSubmit={ this.handleCapture }>
                <Form.Input label={ label }
                            value={ payload }
                            onChange={ this.handlePayloadChange } />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={ this.handleDismissCapture }
                      negative
                      basic>Cancel</Button>

              <Button type='submit'
                      form={ captureFormId }
                      positive
                      basic>Set</Button>
            </Modal.Actions>
          </Modal>
        }
      </ActionCell>
    );
  }

  handleChange(actions, checked) {
    const { onChange } = this.props;

    if(checked) {
      this.setState({ capturePayload: true });
    }

    onChange(actions);
  }

  handlePayloadChange(evt, { value }) {
    this.setState({ payload: value });
  }

  handleDismissCapture() {
    this.setState({
      capturePayload: false,
      payload:        extractPayload(this.props)
    });
  }

  handleCapture(evt) {
    evt.stopPropagation();
    evt.preventDefault();

    const { actions, type, target, onChange } = this.props;
    const { payload } = this.state;

    const index     = findActionIndex(actions, type, target);
    actions[index]  = { type, target, payload };

    this.setState({ capturePayload: false });

    onChange(actions);
  }

  handlePayloadClick(evt) {
    evt.preventDefault();
    this.setState({ capturePayload: true });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

PayloadActionCell.propTypes = {
  type: PropTypes.string.isRequired,
  field:  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    dataType:   PropTypes.string,
    fieldType:  PropTypes.string,
    fieldOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool
        ])
      })
    )
  }).isRequired,

  target: PropTypes.shape({
    type: PropTypes.string,
    id:   PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
  }).isRequired,

  actions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.shape({
      type: PropTypes.string,
      id:   PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
    })
  })).isRequired,

  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default PayloadActionCell;
