import { createStore, applyMiddleware, compose }  from 'redux';
import thunkMiddleware                            from 'redux-thunk';
import throttle                                   from 'lodash/throttle';
import _                                          from 'lodash';
import parseDomain                                from 'parse-domain';

import rootReducer              from 'reducers';
import { loadState, saveState } from 'utils/persistence';
import AuthToken                from 'utils/AuthToken';
import * as actions             from 'constants/ActionTypes';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function impersonationSubdomain(state) {
  const safeState     = state || {};
  const impersonation = safeState.impersonation || {};
  const organization  = impersonation.organization || {};

  return organization.slug;
}

function currentSubdomain() {
  const hostParts = parseDomain(window.location.hostname) || {};
  return hostParts.subdomain;
}

function getOrgDomainManager(store) {
  let orgdomain = null;
  return function() {
    let previousOrgdomain = orgdomain;
    let currentState      = store.getState();
    orgdomain             = impersonationSubdomain(currentState)
                            || currentSubdomain()
                            || '';

    if(previousOrgdomain !== orgdomain) {
      store.dispatch({
        type:     actions.SET_ORGDOMAIN,
        payload:  orgdomain
      });
    }
  };
}

function getAuthTokenManager(store) {
  let authToken = null;

  return function() {
    let previousAuthToken   = authToken;
    let currentState        = store.getState();
    authToken               = currentState.auth.authToken;

    if(previousAuthToken !== authToken) {
      if(authToken === null) {
        AuthToken.remove();
      } else {
        AuthToken.write(authToken);
      }
    }
  };
}

// -----------------------------------------------------
// Public API
// -----------------------------------------------------

export default function configureStore() {
  const persistedState = loadState();

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    _.pick(persistedState, 'auth', 'impersonation'),
    composeEnhancers(
      applyMiddleware(thunkMiddleware)
    )
  );

  // Persist application state to durable storage.
  store.subscribe(throttle(() => {
    saveState(store.getState());
  }, 1000));

  // Keep orgdomain in sync with impersonations
  const orgDomainManager  = getOrgDomainManager(store);

  // Keep AuthToken in sync with Redux State.
  const authTokenManager  = getAuthTokenManager(store);

  // initialize
  orgDomainManager();
  authTokenManager();

  // listen for changes
  store.subscribe(orgDomainManager);
  store.subscribe(authTokenManager);

  return store;
}
