import React, { useState }                from 'react';
import PropTypes                          from 'prop-types';
import { Segment, Header, Icon, Divider } from 'semantic-ui-react';

import DataList                 from 'components/DataList';
import formattedDate            from 'utils/formattedDate';
import VersionHistoryResource   from 'resources/VersionHistoryResource';
import useOrganizationResource  from 'hooks/useOrganizationResource';
import HistoryModal             from './HistoryModal';
import HistoryContextProvider   from './HistoryContextProvider';

function toDatalist({ created_by, created_at, updated_by, updated_at } = {}) {
  const list = [
    { label: 'Created By', value: created_by },
    { label: 'Created At', value: created_at && formattedDate(created_at) },
    { label: 'Updated By', value: updated_by },
    { label: 'Updated At', value: updated_at && formattedDate(updated_at) }
  ];

  return list.filter(({ value }) => value);
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Versions = ({ recordId, recordType }) => {
  const versionHistory  = useOrganizationResource(
                            VersionHistoryResource.singletonDetailShape(),
                            { id: recordId, type: recordType, history: false }
                          );

  const [historyOpened, setHistoryOpened] = useState(false);

  const dataList    = toDatalist(versionHistory);

  const handleHistoryLinkClicked = (e) => {
    e.preventDefault();
    setHistoryOpened(true);
  };

  const handleHistoryModalClosed = () => {
    setHistoryOpened(false);
  };

  const handleRollback = () => {
    setHistoryOpened(false);
    window.location.reload(false);
  };

  return (
    <Segment>
      <Header as="h3" dividing>Record Summary</Header>
      <div style={{ overflow: 'hidden' }}>
        <DataList items={ dataList } />
      </div>

      <Divider />

      <div>
        <Icon name='history' style={{ marginRight: '0.78rem' }} />
        <a href="#version-history" onClick={ handleHistoryLinkClicked }>
          Show full history
        </a>
      </div>

      { historyOpened &&
          <HistoryContextProvider recordId={ recordId }
                                  recordType={ recordType }
                                  onRollback={ handleRollback }>

            <HistoryModal opened={ historyOpened }
                          onClose={ handleHistoryModalClosed } />
          </HistoryContextProvider>
        }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Versions.defaultProps = {
  loading: false
};

Versions.propTypes = {
  recordId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,

  recordType: PropTypes.string.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Versions;
