import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { connect }          from 'react-redux';

import Calculations         from 'components/forms/Calculations';
import { orderBy }          from 'lodash';


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class CalculationsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calculations: orderBy(props.calculations, 'position')
    };

    this.handleSave   = this.handleSave.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { calculations }        = this.props;

    if (this.props.calculations !== prevProps.calculations) {
      this.setState({calculations: orderBy(calculations, 'position')});
    }
  }

  render() {
    const { fields, loading } = this.props;
    const { calculations } = this.state;

    return (
      <Calculations loading={ loading }
                    calculations={ calculations }
                    fields={ fields }
                    onSave={ this.handleSave }
                    onSaveOrder={ this.handleSaveOrder }
                    onDelete={ this.handleDelete }
                    onDragEnd={ this.handleDragEnd } />
    );
  }

  async handleSave({ calculation }, done) {
    const {
      form: { id: form_id },
      createAction: create,
      updateAction: update } = this.props;

    const { id, variables } = calculation;
    const params = {
      ...calculation,
      variables
    };

    let response = null;

    if(id) {
      response = await update(params, { id, form_id });
    } else {
      response = await create(params, { form_id });
    }

    if(!response.errors) {
      done();
    }
  }

  async handleSaveOrder() {
    const {
      form: { id },
      updateOrderAction: update } = this.props;
    const { calculations }        = this.state;

    const response = await update({ calculations }, { id, form_id: id });

    if(!response.errors) {
      return true;
    }
  }

  async handleDelete({ calculation }) {
    const {
      form: { id: form_id },
      destroyAction: destroy
    } = this.props;

    const { id } = calculation;

    await destroy(null, { id, form_id });
  }

  handleDragEnd({ source, destination }) {
    // If the drag does not result in a change, do nothing.
    if(!source || !destination || source.index === destination.index) {
      return;
    }

    const { calculations } = this.state;
    const nextCalcs  = [...calculations ];
    const sourceCalc = nextCalcs[source.index];

    // move the source to the destination
    nextCalcs.splice(source.index, 1);
    nextCalcs.splice(destination.index, 0, sourceCalc);

    // update positions on all fields
    nextCalcs.forEach((option, i) => (option.position = i));

    this.setState( { calculations: nextCalcs });

  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CalculationsContainer.defaultProps = {
  loading:      false,
  calculations: []
};

CalculationsContainer.propTypes = {
  loading:      PropTypes.bool,
  fields:       PropTypes.array.isRequired,

  form:         PropTypes.shape({
    id:         PropTypes.number,
    fields:     PropTypes.array
  }).isRequired,

  calculations: PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    equation:   PropTypes.string,
    variables:  PropTypes.array
  })),

  createAction:   PropTypes.func.isRequired,
  updateAction:   PropTypes.func.isRequired,
  destroyAction:  PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connect()(CalculationsContainer);
