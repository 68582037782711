import React, { Component } from 'react';
import { Segment }          from 'semantic-ui-react';


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Widget extends Component {
  render() {
    const { layout, pending } = this.props;

    return (
      <Segment data-grid={ layout } loading={ pending }>

      </Segment>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Widget.defaultProps = {

};

Widget.propTypes = {

};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Widget;
