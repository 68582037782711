import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class PinnedMessageManagementResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/permission_groups/:permission_group_id/pinned_messages';

  id                = undefined;
  subject           = '';
  body              = '';
  expired_at        = null;
  created_at        = null;
  updated_at        = null;

  pk() {
    return this.id && this.id.toString();
  }
}
