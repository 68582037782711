// NOTE: This is a temporary hook.  We need this as long as we're storing data
// in the Redux store and in the rest-hooks cache.
import { useSelector }  from 'react-redux';
import useApiFetcher    from './useApiFetcher';

// -----------------------------------------------------
// Hook Definition
// -----------------------------------------------------

export default function useOrganizationFetcher(resourceShape, throttle=false) {

  // Get the current organization slug from the Redux state
  const organization  = useSelector(state => state.orgdomain);
  const fetchFn       = useApiFetcher(resourceShape, throttle);

  return (params, body, updateParams) => {
    return fetchFn({ ...params, organization }, body, updateParams);
  };
}
