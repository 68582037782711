import createResource       from '../utils/resource';

import { generateReducers } from '../utils/reducers';
import * as types           from 'constants/ActionTypes';

const initialState = {
  authToken:  null,
  user: {
    first_name: null,
    last_name:  null,
    email:      null,
    role:       null
  }
};

const login = {
  method: 'POST',
  response: (state, action) => {
    const { payload }  = action;
    const { jwt }      = payload;

    return {
      ...state,
      authToken: jwt,
    };
  },
  error : (state) => ({
    authToken: null,
    ...state
  })
};

export function logout() {
  return {
    type: types.DEAUTH_REQUESTED
  };
}

export default createResource({
  name:     'auth',
  selector: state => state.auth,
  basepath: '/auth_token',
  initialState,
  actions: {
    login
  },

  reducer: generateReducers({
    [types.DEAUTH_REQUESTED]: () => {
      return initialState;
    },

    [types.AUTH_FAILED]: () => {
      return initialState;
    },

    [types.AUTH_REFRESH]: (state, action) => {
      return action.payload;
    }
  })
});
