import React                                        from 'react';
import PropTypes                                    from 'prop-types';
import { Segment, Header, Divider, Message, List }  from 'semantic-ui-react';
import { Link }                                     from 'react-router-dom';

import DataList                   from 'components/DataList';

import CategoryResource        from 'resources/organization/CategoryResource';
import useOrganizationResource  from 'hooks/useOrganizationResource';

import formattedDate            from 'utils/formattedDate';

// -----------------------------------------------------
// Functions
// -----------------------------------------------------

function categoryPath(id) {
  return `/organization/categories/${id}`;
}

function PathHeader({ path, name }) {
  return (
    <Header as='h2'>
      {
        path.map(({ id, name }) => (
          <span key={ id }>
            <Link to={ categoryPath(id) }>{ name }</Link>
            <span> / </span>
          </span>
       ))
      }
      { name }
    </Header>
  );
}

function CategoryInfo({ numberOfRecords, createdAt, updatedAt }) {
  const items = [
    { label: 'Number of Records',     value: numberOfRecords },
    { label: 'Created At',            value: formattedDate(createdAt) },
    { label: 'Last Updated At',       value: formattedDate(updatedAt) }
  ];

  return (
    <DataList items={ items } />
  );
}

function typePath(recordType){   
  switch(recordType){   
      case 'ReportDefinition': return 'reports';
      case 'Client': return 'clients';
      default: return 'forms';      
  }
}

function typeName(recordType){   
  switch(recordType){   
      case 'ReportDefinition': return 'Reports';
      case 'Client': return 'Clients';
      default: return 'Forms';      
  }
}

function typeIcon(recordType){   
  switch(recordType){   
      case 'Report': return 'line chart';
      case 'Client': return 'address card';
      default: return 'file';      
  }
}

function categoryRecordPath(recordType, id) {  
  return `/organization/${typePath(recordType)}/${id}`;
}

function RecordsList({ records }) {
  if(records.length === 0) {
    return <Message>No records attached to this category.</Message>;
  }

  const groupedRecords = records.reduce((acc, record) => {
    if (!acc[record.record_type]) {
        acc[record.record_type] = [];
    }
    acc[record.record_type].push(record);
    return acc;
  }, {});

  return (
    Object.entries(groupedRecords).map(([recordsType, typeRecords]) => (
      <List key={ recordsType } relaxed>
        <List.Header as='h3'>{typeName(recordsType)}</List.Header>
        {
          typeRecords.sort((a, b) => a.record_name.localeCompare(b.record_name)).map(({ id, record_id, record_name }) => (
            <List.Item key={ id }>
              <List.Icon name={typeIcon(recordsType)} size='small' verticalAlign='middle' />
              <List.Content>
                <List.Item as={ Link } to={ categoryRecordPath(recordsType, record_id) }>
                  { record_name }
                </List.Item>
              </List.Content>
            </List.Item>
          ))
        }
        <Divider />
      </List>
    ))
  );
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = ({ match: { params: { id } }, loading }) => {
  const category         = useOrganizationResource(CategoryResource.detailShape(), { id });
  const { path=[],
          category_records,
          name,
          created_at,
          updated_at }  = category || {};
  
  const number_of_records = category_records.length;

  return (
    <Segment loading={ loading }>
      <PathHeader path={ path } name={ name } />
      <CategoryInfo  numberOfRecords={ number_of_records }
                      createdAt={ created_at }
                      updatedAt={ updated_at } />
      <Divider/>
      <RecordsList records={ category_records } />
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DetailView.propTypes = {
  match: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DetailView;
