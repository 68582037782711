import React            from 'react';
import PropTypes        from 'prop-types';
import { Icon, Table }  from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

const MappingsRow = (props) => {
  const { destinations,
          mapping,
          origins }   = props;
  const destination   = destinations[mapping.destination_id] || {};
  const origin        = origins[mapping.origin_id]           || {};

  return (
    <Table.Row>
      <Table.Cell>
        { origin.name }
      </Table.Cell>
      <Table.Cell>
        { destination.name }
        {
          destination.is_required &&
          <span className='text-negative'>&nbsp; *</span>
        }
        <br/>
        <small className='text-muted text-uppercase'>
          { destination.data_type }
        </small>
      </Table.Cell>
      <Table.Cell>
        {
          mapping.force &&
          <Icon name='check' />
        }
      </Table.Cell>
    </Table.Row>
  );
};

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

MappingsRow.propTypes = {
  destinations: PropTypes.object.isRequired,
  mapping:      PropTypes.object.isRequired,
  origins:      PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default MappingsRow;
