import React                      from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header, Icon }  from 'semantic-ui-react';

import CreateButton from './CreateButton';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const EmptyList = ({ onCreate }) => {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name="key" size="huge" />
        API Credentials
      </Header>
      <CreateButton onCreate={ onCreate } />
    </Segment>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

EmptyList.propTypes = {
  onCreate: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default EmptyList;