import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table }            from 'semantic-ui-react';

import Header               from './Header';
import Body                 from './Body';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class PermissionTable extends Component {
  render() {
    const { entities, actions, privileges, disabled, onChange } = this.props;

    return (
      <Table className='permission-table' unstackable celled definition striped>
        <Header entities={ entities }
          actions={ actions }
          privileges={ privileges }
          disabled={ disabled }
          onChange={ onChange }/>

        <Body   entities={ entities }
          actions={ actions }
          privileges={ privileges }
          disabled={ disabled }
          onChange={ onChange } />
      </Table>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

PermissionTable.propTypes = {
  entities:   PropTypes.arrayOf(PropTypes.string),
  actions:    PropTypes.arrayOf(PropTypes.string),
  privileges: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ),
  disabled:   PropTypes.bool,
  onChange:   PropTypes.func
};

PermissionTable.defaultProps = {
  entities:   [],
  actions:    [],
  privileges: {},
  disabled:   true,
  onChange:   () => {}
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default PermissionTable;
