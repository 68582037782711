import React      from 'react';
import PropTypes  from 'prop-types';

import connectResource          from 'utils/connectResource';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import { getPageAllById }       from 'utils/connectors';
import { sortByAttributes }     from 'utils/sortComparators';
import { textSubstringFilter }  from 'utils/recordFilters';

import { categories } from 'resources/organizationResources';

import MasterDetail from 'components/MasterDetail';
import ListItemView from './ListItemView';
import DetailView   from './DetailView';
import FormView     from './FormView';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const App = ({ data, destroy, update, create, ...forwardProps }) => {
  const categories = data.categories || [];

  return (
    <MasterDetail masterItemComponent={ ListItemView }
                  detailComponent={ DetailView }
                  formComponent={ FormView }
                  records={ categories }
                  onDelete={ id => destroy(null, { id }) }
                  onUpdate={ record => update(record, { id: record.id }) }
                  onCreate={ create }
                  resource="categories"
                  listSort={ sortByAttributes('path_name') }
                  filterRecord={ textSubstringFilter('name') }
                  data={ data }
                  { ...forwardProps } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

App.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.array
  }).isRequired,

  destroy:  PropTypes.func.isRequired,
  update:   PropTypes.func.isRequired,
  create:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    categories: getPageAllById(categories)
  },
  mapDispatchToProps: { ...categories.actions }
});

export default withSearch(withPagination(connectedComponent));
