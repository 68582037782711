import AuthenticatedResource from 'resources/AuthenticatedResource';

const valueLabels = {
  'update':   'Update',
  'destroy':  'Delete'
};

export default class EventTypeResource extends AuthenticatedResource {
  static urlRoot = '/admin/event_types';

  value = undefined;

  get label() {
    return valueLabels[this.value];
  }

  pk() {
    return this.value;
  }
}