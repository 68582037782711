import React, { Component }    from 'react';
import PropTypes               from 'prop-types';
import { Grid, Form, Header }  from 'semantic-ui-react';
import SelectionTable          from './SelectionTable';
import { systemFields }        from 'constants/reports/SystemFields';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class HouseholdFieldSelection extends Component {
  constructor(props) {
    super(props);

    this.handleAddAllFields       = this.handleAddAllFields.bind(this);
  }

  render() {
    const { reportDefinition,
            users,
            functionType,
            onAddField,
            onChange,
            onFunctionTypeChange }  = this.props;

    return (
      <Form>
        <Header as='h2'>Select Report Fields</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <SelectionTable form={ null }
                              allFields={ null }
                              formCalculations={ {} }
                              functionType={ functionType }
                              reportDefinition={ reportDefinition }
                              onChange={ onChange }
                              onAddField= { onAddField }
                              onAddAllFields= { this.handleAddAllFields }
                              onFunctionTypeChange={ onFunctionTypeChange }
                              forms={ null }
                              users={ users } />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  handleAddAllFields() {
    const { onAddAllFields,
            reportDefinition }  = this.props;

    const newFields = systemFields(reportDefinition).map(({id}) => ({
                        field: id,
                        type: 'field'
                      }));

    onAddAllFields(newFields);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

HouseholdFieldSelection.defaultProps = {
  reportDefinition:   {},
  forms:              [],
  fields:             [],
  formCalculations:   {}
};

HouseholdFieldSelection.propTypes = {
  reportDefinition:   PropTypes.object,
  forms:              PropTypes.array,
  fields:             PropTypes.array,
  formCalculations:   PropTypes.object,
  onChange:           PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default HouseholdFieldSelection;
