import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form }             from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Email extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { value } = this.props;

    return (
      <Form.Input type='email'
                  value={ value || undefined }
                  onChange={ this.handleChange } />
    );
  }

  handleChange(evt, { value }) {
    const { name, onChange } = this.props;
    onChange(evt, { name, value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Email.defaultProps = {
  value:    '',
  metadata: {}
};

Email.propTypes = {
  value:    PropTypes.string,
  metadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Email;
