import React from 'react';

// -----------------------------------------------------
// Helpers / Constants
// -----------------------------------------------------

const ROLES = [ 'admin', 'organization' ];

// -----------------------------------------------------
// HOC Definition
// -----------------------------------------------------

export default function(WrappedComponent, config) {
  const { role } = config;

  if(!ROLES.includes(role)) {
    throw new Error(
      `An invalid role of "${role}" was specified.  Must be one of: ${ROLES}`
    );
  }

  const AnonymousComponent = (props) => {
    return (
      <WrappedComponent { ...props } scope={ role } />
    );
  };

  return AnonymousComponent;
}
