import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { Form, Message }        from 'semantic-ui-react';

import BodyEditor               from './BodyEditor';
import ConditionList            from './Conditions';
import EventSelector            from './EventSelector';
import UpdatedFieldsSelector    from './UpdatedFieldsSelector';
import RecipientFieldList       from './RecipientFields';
import RecipientSelector        from './RecipientSelector';
import SubjectEditor            from './SubjectEditor';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

const EVENT_UPDATED_PATTERN = /.*_updated$/;

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class DefinitionItem extends Component {

  // initialisation
  constructor(props) {
    super(props);

    this.handleBodyChange           = this.handleBodyChange.bind(this);
    this.handleConditionChange      = this.handleConditionChange.bind(this);
    this.handleEventChange          = this.handleEventChange.bind(this);
    this.handleScopedFieldsChange   = this.handleScopedFieldsChange.bind(this);
    this.handleRecipientChange      = this.handleRecipientChange.bind(this);
    this.handleRecipientFieldChange = this.handleRecipientFieldChange.bind(this);
    this.handleSubjectChange        = this.handleSubjectChange.bind(this);
  }

  // event handlers
  handleBodyChange(body_template) {
    const { definition, onChange } = this.props;
    onChange({ ...definition, body_template });
  }
  handleConditionChange(conditions) {
    const { definition, onChange } = this.props;
    onChange({ ...definition, conditions });
  }
  handleEventChange(name) {
    const { definition, onChange } = this.props;
    onChange({ ...definition, name });
  }
  handleScopedFieldsChange(scoped_field) {
    const scoped_fields = scoped_field ? [scoped_field] : [];
    const { definition, onChange } = this.props;
    onChange({ ...definition, scoped_fields });
  }
  handleRecipientChange(recipients) {
    const { definition, onChange } = this.props;
    onChange({ ...definition, recipients });
  }
  handleRecipientFieldChange(recipient_field_ids) {
    const { definition, onChange } = this.props;
    onChange({ ...definition, recipient_field_ids });
  }
  handleSubjectChange(subject_template) {
    const { definition, onChange } = this.props;
    onChange({ ...definition, subject_template });
  }

  // rendering
  render(){
    const { definition, eventMetadata, recipientFields, formFields, users } = this.props;

    const events                    = Object.keys(eventMetadata);
    const fieldMetadata             = eventMetadata[definition.name] || {};
    const fields                    = fieldMetadata.fields || [];
    const recipients                = definition.recipients || [];
    const recipientEmails           = recipients.map(r => r.email);
    const checkedRecipientFieldIds  = definition.recipient_field_ids || [];

    const scoped_field = definition.scoped_fields ? definition.scoped_fields[0] : null;
    
    const hasAddressFields = fields.some(entry => entry.field_type === 'address');

    return !!events && (

      <Form className='definition'>
        <Form.Field>
          <label>
            Send with:
          </label>
          <EventSelector  events={ events }
                          value={ definition.name }
                          onChange={ this.handleEventChange } />
        </Form.Field>
        { definition.name.match(EVENT_UPDATED_PATTERN) &&
           <Form.Field>
            <label>
              Specific field changed?
            </label>
            <UpdatedFieldsSelector  fields={ formFields }
                                    value={ scoped_field }
                                    onChange={ this.handleScopedFieldsChange } />
          </Form.Field>
        }
        <Form.Field>
          <label>
            With Subject:
          </label>
          <SubjectEditor  fields={ fields }
                          value={ definition.subject_template }
                          onChange={ this.handleSubjectChange } />
        </Form.Field>
        <Form.Field>
          <label>
              With Message:
          </label>
          <BodyEditor fields={ fields }
                      value={ definition.body_template }
                      onChange={ this.handleBodyChange } />
          { hasAddressFields ? 
              <Message attached='bottom' size='tiny' info>
                Options available on <b>addresses</b> fields: full, streetAddress, unitNumber, city, state, postalCode
              </Message>
            : null
          }
        </Form.Field>
        <Form.Field className='condition__control'>
          <label>
            Conditions:
          </label>
          <ConditionList conditions={ definition.conditions }
                         fields={ fields }
                         onChange={ this.handleConditionChange } />
        </Form.Field>
        <Form.Field className='recipient__control'>
          <label>
            Recipients:
          </label>
          <RecipientSelector users={ users }
                             value={ recipientEmails }
                             onChange={ this.handleRecipientChange } />
          <RecipientFieldList checkedIds={ checkedRecipientFieldIds }
                              fields={ recipientFields }
                              onChange={ this.handleRecipientFieldChange } />
        </Form.Field>
      </Form>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

DefinitionItem.propTypes = {
  definition:       PropTypes.object.isRequired,
  eventMetadata:    PropTypes.object.isRequired,
  recipientFields:  PropTypes.array.isRequired,
  users:            PropTypes.array.isRequired,
  onChange:         PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default DefinitionItem;
