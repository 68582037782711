import useOrganizationResource      from 'hooks/useOrganizationResource';
import OrganizationAccountResource  from 'resources/organization/OrganizationAccountResource';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const WithCaseWorthyAffiliation = ({ children }) => {
  const organizationAccount =
    useOrganizationResource(OrganizationAccountResource.singletonDetailShape());

  if(organizationAccount.case_worthy_affiliation) {
    return children;
  }

  return null;
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

WithCaseWorthyAffiliation.defaultProps  = {};
WithCaseWorthyAffiliation.propTypes     = {};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default WithCaseWorthyAffiliation;