import { useSelector }  from 'react-redux';

import { useApiUpdate } from 'hooks/useApiFetch';
import resourceUrl      from 'utils/resourceUrl';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const urlRoot = '/organization/:organization/case_worthy/clients/:clientId/client';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function useClientUpdate(clientId) {
  // Get the current organization slug from the Redux state
  const organization      = useSelector(state => state.orgdomain);
  const initUrl           = resourceUrl(urlRoot, '', { organization, clientId });
  const update            = useApiUpdate(initUrl, {});

  return async (caseWorthyClientId) => (
    await update({ case_worthy_client_id: caseWorthyClientId })
  );
}
