import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Button, Popup }              from 'semantic-ui-react';

import ConditionModal                 from './Modal';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class EditButton extends Component {

  // construction
  constructor(props) {
    super(props);

    this.state = { opened: false };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  // event handlers
  handleClick() {
    this.setState({ opened: true });
  }
  handleClose() {
    this.setState({ opened: false });
  }
  handleConfirm() {
    const { onConfirm } = this.props;
    onConfirm();
    this.setState({ opened: false });
  }

  // rendering
  render() {
    const { conditions, fields, onChange }  = this.props;
    const { opened }                        = this.state;

    const title = 'Edit conditions';
    const Btn   = <Button basic
                          color='blue'
                          icon='pencil'
                          onClick={ this.handleClick } />;

    return (
      <Fragment>
        <ConditionModal opened={ opened }
                        title={ title }
                        conditions={ conditions }
                        fields={ fields }
                        onClose={ this.handleClose }
                        onSubmit={ onChange } />

        <Popup  trigger={ Btn }
                content={ title }
                position='top center' />
      </Fragment>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditButton.propTypes = {
  conditions: PropTypes.object,
  fields:     PropTypes.array.isRequired,
  onChange:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditButton;
