import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------------
// Component Style
// --------------------------------------------------------

const style = {
  'position': 'absolute',
  'top':      '0px',
  'bottom':   '0px',
  'left':     '0px',
  'right':    '0px'
};

// --------------------------------------------------------
// Component Def
// --------------------------------------------------------

class FullPage extends React.Component {
  static propTypes = {
    className: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.style = Object.assign({}, style);
  }

  render() {
    return (
      <div  style={ this.style }
            className={`full-page-component ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default FullPage;
