// DEPRECATED:  This should no longer be used to define resources.  We're now
// using the rest-hooks package for this concern.

import 'isomorphic-fetch';
import _ 							from 'lodash';
import reduceReducers from 'reduce-reducers';
import crc32 					from 'crc-32';
import urljoin        from 'url-join';

import AuthToken    from 'utils/AuthToken';

import {AUTH_FAILED,
        DEAUTH_REQUESTED,
        AUTH_REFRESH }  from 'constants/ActionTypes';

import { generateReducers, identityReducer }  from './reducers';
import resourceUrl                            from './resourceUrl';
import resourceHeaders                        from './resourceHeaders';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

// Builds the hash of redux actions, keyed by their role.
function buildActionTypes(resourceName, actionName) {

  // Prefix for all action-types emitted from this resource
  const prefix = `${resourceName}:${actionName}`.toUpperCase();

  // Collection of action-types for this resource
  return {
    request:  `${prefix}_REQUEST`,
    response: `${prefix}_RESPONSE`,
    error:    `${prefix}_ERROR`
  };
}

// Given the store, builds the necessary headers for the API call.
function buildHeaders(getState, transformHeaders) {
  const { auth: { authToken } } = getState();

  // Provides authToken from rest-hook resources
  const token = authToken || AuthToken.read();

  return resourceHeaders(token, transformHeaders);
}

// The server sends a new auth token to the client, on each response.  Update
// the current auth token with it.
function refreshAuthToken(response, dispatch) {
  const authToken = response.headers.get('Authorization');

  if(authToken !== null) {
    dispatch({
      type:     AUTH_REFRESH,
      payload:  { authToken }
    });
  }
}

// Performs a request to the API, returns the response.
async function doRequest(url, method, payload, headers) {
  if(method === 'GET' || method === 'HEAD') {
    payload       = payload && JSON.parse(payload);
    const params  = new URLSearchParams({ ...payload });
    url.search    = params;

    return await fetch(url.toString(), { method, headers });
  } else {
    return await fetch(url.toString(), { method, headers, body: payload });
  }
}

const buildPayload = (payload, payloadScope) => {
  let obj = {};
  if(payload) { obj = Object.assign(obj, payload); }
  if(payloadScope) { obj = Object.assign(obj, payloadScope); }

  return Object.entries(obj).length ? obj : undefined;
};

const pending = {};
const waiting = {};

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------


export default function({
  name: resourceName,
  basepath,
  actions: actionConfigs,
  initialState={},
  reducer=identityReducer,
  selector,
  selectScope=()=>({})
}) {

  const actions     = {};
  const transitions = {};

  _.each(actionConfigs, (actionConfig, actionName) => {

    const {
      path              = '',
      method            = 'GET',
      transformHeaders  = headers => headers,
      mapRequest        = JSON.stringify,
      mapResponse       = response => response,
      ...reducers
    } = actionConfig;

    // Collection of action-types for this resource
    const actionTypes = buildActionTypes(resourceName, actionName);

    // Generate the action functions, for each action
    actions[actionName] = (payload, scope) => {

      // Returns a function that to be used by redux-thunk
      return async (dispatch, getState) => {
        const actionScope = {...selectScope(getState()), ...scope};

        let pathScope = {};
        let payloadScope = {};
        const pathTemplate = urljoin(basepath, path);
        Object.entries(actionScope).forEach( ([key, value]) => {
          if( pathTemplate.includes(`:${key}`) ){
            pathScope[key] = value;
          } else {
            payloadScope[key] = value;
          }
        } );

        payload = buildPayload(payload, payloadScope);
        payload = payload ? mapRequest(payload) : undefined;

        const request = {
          type:  actionTypes.request,
          scope: actionScope,
          payload
        };

        const checksum = crc32.str(JSON.stringify(request));

        if(pending[checksum]) {
          return new Promise((resolve) => {
            waiting[checksum] = waiting[checksum] || [];
            waiting[checksum].push((resJson) => {
              resolve(resJson);
            });
          });
        }

        pending[checksum] = 1;

        dispatch(request);

        /* handles dispatches according to response*/
        function dispatchResponse(response, json={}) {
          (waiting[checksum] || []).forEach(cb => cb(json));

          delete waiting[checksum];
          delete pending[checksum];

          refreshAuthToken(response, dispatch);

          if(response.status < 400) {
            dispatch({
              type:    actionTypes.response,
              payload: json,
              scope:   actionScope
            });
          } else {
            dispatch({
              type:    actionTypes.error,
              payload: json,
              scope:   actionScope
            });
          }

          return Promise.resolve(json);
        }

        const url       = resourceUrl(basepath, path, pathScope);
        const headers   = buildHeaders(getState, transformHeaders);
        const response  = await doRequest(url, method, payload, headers);

        if(response.status === 401) {
          dispatch({ type: AUTH_FAILED });
        }

        // handles EMPTY response try to parse as json, if failed dispatch
        // with an empty payload.
        return response.json().then((json) => {
          return dispatchResponse(response, mapResponse(json, actionScope));
        }).catch(() => {
          return dispatchResponse(response);
        });
      };
    };

    _.each(actionTypes, (actionType, name) => {
      transitions[actionType] = reducers[name] || identityReducer;
    });
  });

  const finalReducer = reduceReducers(
    generateReducers(transitions, initialState),
    reducer,
    (state, action) => {
      if(action.type === DEAUTH_REQUESTED) return initialState;
      else return state;
    }
  );

  return {
    reducer: finalReducer,
    resourceName,
    actions,
    selector
  };
}
