import React, { useState } from 'react';
import PropTypes                      from 'prop-types';
import debounce                       from 'lodash/debounce';
import {Segment,
        Message,
        Icon,
        Divider,
        Input,
        Dimmer,
        Loader,
        Grid } from 'semantic-ui-react';

import InstanceStatus from './InstanceStatus';
import ResponsesTable from './ResponsesTable';
import ResponsesPagination from './ResponsesPagination';
import useOrganizationResource from 'hooks/useOrganizationResource';
import PublicFormResponseResource from 'resources/organization/PublicFormResponseResource';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const SEARCH_DEBOUNCE     = 1000;
const INITIAL_PAGE        = 1;

function queryFilter(query) {
  return function({ answers }) {
    if(query === null || query === undefined || query === '') {
      return true;
    }

    return Object.values(answers).some(({ value }) => {
      const v1 = `${value}`.toLowerCase();
      const v2 = `${query}`.toLowerCase();
      return v1.includes(v2);
    });
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------
    const PublicFormInstance = ({ 
            publishedAt,
            expiredAt,
            isActive,
            dow,
            timeStart,
            timeEnd,
            fields,
            publicFormId,
            pending,
            onDelete }) => {

    const [query, setQuery] = useState('');
    const [page, setPage] = useState(INITIAL_PAGE);
    const responses  = useOrganizationResource(PublicFormResponseResource.listShape(),
      { public_form_id: publicFormId, page: page, query: query }
    );
    const filteredResponses = responses.filter(queryFilter(query));
    const responseCount     = filteredResponses.length;
  
    const handleChange = debounce((evt, { value }) => {
      setPage(INITIAL_PAGE);
      setQuery(value);
    }, SEARCH_DEBOUNCE);
    
    const handleClick = (value) => {
      const nextPage        = Math.max(value, INITIAL_PAGE);
      setPage(nextPage);
    };

    return (
      <>
        <Segment style={ { marginRight: '1rem' } }>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <InstanceStatus isActive={ isActive }
                                publishedAt={ publishedAt }
                                expiredAt={ expiredAt }
                                dow={ dow }
                                timeStart={ timeStart }
                                timeEnd={ timeEnd } />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Input  placeholder='Filter'
                        onChange={ handleChange } />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {
                  pending &&
                  <Dimmer inverted active>
                    <Loader />
                  </Dimmer>
                }
                { responseCount
                  ? <div>
                      <ResponsesTable fields={ fields }
                                      responses={ filteredResponses }
                                      publicFormId={ publicFormId }
                                      onDelete={ onDelete } />

                    </div>
                  : <Message info icon>
                      <Icon name='info circle' />
                      No responses have been collected for this instance of the form.
                    </Message>
                }
                { (responseCount || (!responseCount && page > INITIAL_PAGE)) &&
                  <ResponsesPagination 
                          page={ page }
                          responseCount={ responseCount }
                          onClick={ handleClick } />
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Divider horizontal></Divider>
      </>
    );
  // }

};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

PublicFormInstance.defaultProps = {
  fields:     []
};

PublicFormInstance.propTypes = {
  publicFormId: PropTypes.number.isRequired,
  accessKey:    PropTypes.string,
  publishedAt:  PropTypes.string,
  expiredAt:    PropTypes.string,
  isActive:     PropTypes.bool,
  data:         PropTypes.shape({
    responses:  PropTypes.array
  }),
  fields:       PropTypes.array,
  page:         PropTypes.number,
  query:        PropTypes.string,
  onDelete:     PropTypes.func.isRequired,
  onPageNext:   PropTypes.func.isRequired,
  onSearch:     PropTypes.func.isRequired,
};

export default PublicFormInstance;
