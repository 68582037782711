import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import { pinnedMessages }   from 'resources/organizationResources';
import { getById }          from 'utils/connectors';
import connectResource      from 'utils/connectResource';

import Main                     from 'components/Main';
import { Header, Icon, Label }  from 'semantic-ui-react';
import MarkdownRenderer         from 'components/MarkdownRenderer';
import formattedDate            from 'utils/formattedDate';


function expiredLabel(expired_at) {
  return <Label color='red'>
          { formattedDate(expired_at) }
        </Label>;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class PinnedMessage extends Component {
  render() {

    const { data } = this.props;
    const { body='', subject='', expired=false, expired_at=null, permission_group='' } = data.pinnedMessage || {};

    return (
      <Main className='widget'>
        <Main offsetTop='1.25rem' offsetLeft='1rem' offsetRight='1rem'>
          <Main offsetRight='10%'>
            <Header as='h3'>{ subject }</Header>
          </Main>
          <Main offsetLeft='90%'>
            <Icon name='pin map' color={ expired ? 'red' : 'black' } />
          </Main>
        </Main>


        <Main offsetTop='4rem'  offsetLeft='1rem' offsetRight='1rem'>
          <div className={ 'ui divider' }></div>

          <MarkdownRenderer content={ body } />

          { permission_group &&
            <div style={ {fontSize: '0.8rem'} } >
              <div className={ 'ui divider' }></div>
              <div><b>Permission Group:</b> { permission_group }</div>
              <div>
                <b>{ expired ? 'Expired: ' : 'Expires: '}</b>
                { !expired_at
                  ? 'never'
                  : ( expired ? expiredLabel(expired_at) : formattedDate(expired_at) )
                }
              </div>
            </div>
          }
        </Main>
      </Main>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

PinnedMessage.propTypes = {
  id:              PropTypes.number.isRequired,
  pinnedMessageId: PropTypes.number.isRequired,
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedComponent = connectResource(PinnedMessage, {
  connectors: {
    pinnedMessage: getById(pinnedMessages, ({ pinnedMessageId }) => pinnedMessageId)
  }
});

export default connectedComponent;
