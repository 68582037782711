import React, { Component }    from 'react';
import PropTypes               from 'prop-types';
import { Grid, Form, Header }  from 'semantic-ui-react';
import SelectionTable          from './SelectionTable';
// import { systemFields }        from 'constants/reports/SystemFields';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FieldSelection extends Component {

  render() {
    const { reportDefinition,
            fields,
            formCalculations,
            users,
            functionType,
            onAddField,
            onChange,
            onFunctionTypeChange }  = this.props;

    return (
      <Form>
        <Header as='h2'>Select Fields</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <SelectionTable form={ null }
                              allFields={ fields }
                              formCalculations={ formCalculations }
                              functionType={ functionType }
                              reportDefinition={ reportDefinition }
                              onChange={ onChange }
                              onAddField= { onAddField }
                              onAddAllFields= { () => ({}) }
                              onFunctionTypeChange={ onFunctionTypeChange }
                              forms={ null }
                              users={ users } />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FieldSelection.defaultProps = {
  reportDefinition:   {},
  forms:              [],
  fields:             [],
  formCalculations:   {}
};

FieldSelection.propTypes = {
  reportDefinition:   PropTypes.object,
  forms:              PropTypes.array,
  fields:             PropTypes.array,
  formCalculations:   PropTypes.object,
  onChange:           PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldSelection;
