import React, { createContext, useContext, useState }  from 'react';

// -----------------------------------------------------
// Context Definition
// -----------------------------------------------------

const ApiErrorContext = createContext({
  error:    null,
  setError: () => {},
  local:    false,
  setLocal: () => {}
});

// -----------------------------------------------------
// Context Component
// -----------------------------------------------------

// This component encapsulates the ApiErrorContext.Provider, mapping its value
// to the internal state of this component.
export const ApiErrorContextProvider = ({ children }) => {
  const [error, setError] = useState();
  const [local, setLocal] = useState();

  return (
    <ApiErrorContext.Provider value={ { error, setError, local, setLocal } }>
      { children }
    </ApiErrorContext.Provider>
  );
};

// -----------------------------------------------------
// Hook Definition
// -----------------------------------------------------

// This hook provides access to the current value of the ApiErrorContext.
export const useApiErrorContext = () => {
  return useContext(ApiErrorContext);
};
