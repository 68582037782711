import React, { useEffect } from 'react';
import { useDispatch }      from 'react-redux';
import { Message }          from 'semantic-ui-react';

import { AUTH_FAILED, VALIDATION_ERROR }  from 'constants/ActionTypes';
import { useApiErrorContext }             from './ApiErrorContext';

// -----------------------------------------------------
// Sub-Components
// -----------------------------------------------------

// Component for handling 401 response codes.
const UnauthorizedError = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: AUTH_FAILED });
  }, [dispatch]);

  return null;
};

// Component for handling 422 response codes.
const UnprocessableEntityError = ({ error }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if(error) {
      const doErrorDispatch = async () => {
        const { response }  = error;
        const errors        = await response.json();

        dispatch({
          type:     VALIDATION_ERROR,
          payload:  errors
        });
      };

      doErrorDispatch();
    }
  }, [error, dispatch]);

  return null;
};

// Component for handling 404 response codes.
const NotFoundError = () => {
  return (
    <Message negative>
      <Message.Header>Not Found</Message.Header>
      <p>We could not find the resource you were looking for.</p>
    </Message>
  );
};

const ForbiddenError = () => {
  return (
    <Message negative>
      <Message.Header>Forbidden</Message.Header>
      <p>You do not currently have access to this record.</p>
    </Message>
  );
};

// Fallback component for all other response codes.
const FallbackError = () => {
  return (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>An unknown error has occurred!</p>
    </Message>
  );
};

// Map response codes to their respective handler components.
const statusComponentRegistry = {
  401: UnauthorizedError,
  403: ForbiddenError,
  404: NotFoundError,
  422: UnprocessableEntityError
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const NetworkError = ({ local }) => {
  const { error, setLocal } = useApiErrorContext();

  useEffect(() => {
    setLocal(local !== undefined);
  }, [local, setLocal]);

  const { status }      = error || {};
  const ErrorComponent  = status
                          ? (statusComponentRegistry[status] || FallbackError)
                          : () => null;

  return (
    <ErrorComponent error={ error } />
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default NetworkError;
