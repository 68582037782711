import React, { useEffect } from 'react';
import PropTypes            from 'prop-types';
import { Grid }             from 'semantic-ui-react';

import BrandBox             from 'components/BrandBox';
import HeaderBar            from 'components/HeaderBar';
import Main                 from 'components/Main';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Page = ({ header, translate, children }) => {
  const googleTranslateElementInit = () => {
   new window.google.translate.TranslateElement({ pageLanguage: 'en'}, 'google_translate_element');
  };

  useEffect(() => {
    if(translate) {
      var addScript = document.createElement('script');
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, [translate]);

  return (
    <div>
      <HeaderBar height='3.5rem' className='branded'>
        <Grid centered columns={ 2 }>
          <Grid.Row>
            <Grid.Column>
              <BrandBox width='120px'>CoactionNet</BrandBox>
              <div id="google_translate_element" style={{textAlign: 'right'}}></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </HeaderBar>

      <Main offsetTop='6rem' overflow='scroll-y'>
        <Grid centered columns={ 2 }>
          { header &&
            <Grid.Row>
              <Grid.Column>
                { header }
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column>{ children }</Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Page.propTypes = {
  header: PropTypes.element
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Page;
