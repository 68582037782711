import React              from 'react';
import PropTypes          from 'prop-types';
import { Button, Popup }  from 'semantic-ui-react';
import { Link }           from 'react-router-dom';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Open attachment.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DownloadButton = ({ formId, recordId }) => {
  const href =
    `/organization/internal_form_responses/${formId}/records/${recordId}/download`;

  const Btn = <Button icon='file'
                      basic
                      color='blue'
                      as={ Link }
                      to={ href }
                      target={ '_blank' } />;

  return (
    <Popup  trigger={ Btn }
            content={ title }
            position='top center' />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DownloadButton.propTypes = {
  formId:   PropTypes.number.isRequired,
  recordId: PropTypes.number.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DownloadButton;
