import _ from 'lodash';

/*
Each function in this file is designed to build and return a "connector",
where a connector is an object with two keyed functions:

Instead of each function here being a "connector",
each function here can be thought of as a "connector_creator".

`fetchData`: An action creator, produces an action for redux dispatch.
  See connectResource#update.

`selectData`: A function used within mapStateToProps,
  to get usable data from entire redux state.
  See connectResource#mapStateToProps.
*/

/* omit metadata */
const selectData = (resource, state) => _.omit(resource.selector(state), '_indexed');

const timeout = 120;
const staleIndex = (resource, state) => {
  return !resource.selector(state)._indexed ||
         (new Date()).getTime() - resource.selector(state)._indexed > timeout*1000;
};

function constructParams({query, sort, conditions, filters, category}) {
  if(query || sort || conditions || filters || category) {
    const params = {};

    if(query) {
      params.query = query;
    }

    if(sort) {
      try {
        params.sort = JSON.stringify(sort);
      } catch(e) {
        // eslint-disable-next-line no-console
        console.err('Could not stringify sort:', sort);
      }
    }

    if(conditions) {
      params.conditions = conditions;
    }

    if(filters) {
      params.filters = JSON.stringify(filters);
    }

    if(category) {
      params.category = category;
    }

    return params;
  }

  return null;
}

export function getById(resource, getId=()=>null, getScope=()=>({})) {
  return {
    fetchData: (state, props) => {
      const id    = getId(props);
      const scope = getScope(props);

      if(id) {
        return resource.actions.show(null, {id, ...scope });
      }
    },
    selectData: (state, props) => {
      const id = getId(props);
      const record = selectData(resource, state)[id];
      return (record && record['_indexed']) ? record : null;
    }
  };
}

export function getByFind(
  resource,
  getScope=()=>({}),
  mapArgs=(args)=> args
){
  return {
    fetchData : (state, props) => {
      const scope = getScope(props);
      if(scope) return resource.actions.find(null, scope); //just send as url params
    },
    selectData : (state, props) => {
      const scope = mapArgs(getScope(props)),
        records = selectData(resource, state)||{};
      return _.pickBy(records, record => _.every(scope, (val, key) => record[key] === val));
    }
  };
}

export function getByIds(resource, getIds=()=>[]){
  return {
    fetchData : (state, props) => {
      const needyIds = getIds(props);
      const actions = needyIds.map(id => resource.actions.show(null, {id}));
      if(actions.length) return function(dispatch){
        return Promise.all(actions.map(action => dispatch(action)));
      };
    },
    selectData : (state, props) => {
      const ids = getIds(props);
      const records = _.pickBy(selectData(resource, state), record =>
        ids.indexOf(record.id) > -1 && record['_indexed']
      );
      return _.keys(records).length?records:null;
    }
  };
}

export function getPageById(resource, getId=()=>null, getScope=()=>({})) {
  return {
    fetchData: (state, props) => {
      const { page, query, sort, conditions } = props;
      const params                = constructParams({query, sort, conditions});
      const id                    = getId(props);
      const scope                 = getScope(props);

      if (id === undefined) { return null; }

      return resource.actions.show(params, {id, ...scope, page });
    },
    selectData: (state, props) => {
      const id      = getId(props);
      const record  = selectData(resource, state)[id];
      return (record && record['_indexed']) ? record : null;
    }
  };
}

// Same as getAllById, but with pagination support.
export function getPageAllById(resource, getScope=()=>({}), transformSelection) {
  if(!resource.actions.paginatedIndex) {
    throw new Error(
      `could not find 'paginatedIndex' action on ${resource.name} resource.`
    );
  }

  return {
    fetchData: (state, props) => {
      const { page, query,
              sort, filters, 
              category }          = props;
      const params                = constructParams({query, sort, filters, category});
      const scope                 = getScope(props);

      return resource.actions.paginatedIndex(params, { ...scope, page });
    },
    selectData: (state) => {
      const selected  = selectData(resource, state);
      const results   = transformSelection
                        ? transformSelection(selected)
                        : _.orderBy(_.values(selected), 'id').reverse();
                        
      return results;
    }
  };
}

export function getAllById(resource, getScope=()=>({}), nocache=false) {
  return {
    fetchData : (state, props) => {
      if(nocache || staleIndex(resource, state)) {
        return resource.actions.index(null, getScope(props));
      }
    },
    selectData : (state) => {
      return _.orderBy(_.values(selectData(resource, state)), 'id').reverse();
    }
  };
}

export function getAll(resource) {
  return {
    fetchData : (state) => {
      if(staleIndex(resource, state)) {
        return resource.actions.index();
      }
    },
    selectData: (state) => {
      const res = selectData(resource, state);
      return Object.keys(res).length ? res : null;
    }
  };
}

export function getSingleton(resource, getScope=()=>({})) {
  return {
    fetchData: (state, props) => {
      return resource.actions.get(null, getScope(props));
    },

    selectData: (state) => {
      const selected = resource.selector(state) || {};
      return selected;
    }
  };
}

/* select but make no network requests */
export function selectPassively(resource) {
  return {
    selectData: (state) => {
      return resource.selector(state) || {};
    },
    fetchData: () => {}
  };
}
