import React, { useState }                      from 'react';
import { Grid, Segment, Header, Divider, Form } from 'semantic-ui-react';

import useApiResource       from 'hooks/useApiResource';
import TimeZoneResource     from 'resources/TimeZoneResource';
import UserAccountResource  from 'resources/UserAccountResource';
import useApiFetcher        from 'hooks/useApiFetcher';

const style = {
  grid: {
    'height': '100%'
  }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const MyProfile = ({ history }) => {
  const userAccount       = useApiResource(UserAccountResource.singletonDetailShape());
  const updateUserAccount = useApiFetcher(UserAccountResource.singletonUpdateShape());
  const timeZones         = useApiResource(TimeZoneResource.listShape());
  const timeZoneOptions   = timeZones.map(({ name }) => ({ text: name, value: name }));
  const [form, setForm]   = useState(userAccount);

  const updateState = (evt, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const doProfileSubmit = (evt) => {
    evt.preventDefault();

    const requestParams = Object.entries(form).filter(([, v]) => v !== '');
    updateUserAccount({}, Object.fromEntries(requestParams));
    history.push('/');
  };

  return (
    <Grid verticalAlign='middle' centered columns='3' style={style.grid}>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' textAlign='center'>CoactionNet</Header>
          <Segment >
            <Header>Update Profile</Header>
            <Divider />
            <Form onSubmit={ doProfileSubmit }>
              <Form.Input   id='first_name'
                            label='First Name'
                            name='first_name'
                            value={ form.first_name }
                            placeholder='First Name'
                            onChange={ updateState } />
              <Form.Input   id='last_name'
                            label='Last Name'
                            name='last_name'
                            value={ form.last_name }
                            placeholder='Last Name'
                            onChange={ updateState } />
              <Form.Select  id='time_zone'
                            name='time_zone'
                            label='Time Zone'
                            value={ form.time_zone }
                            options={ timeZoneOptions }
                            onChange={ updateState } />
              <Form.Input   id='email'
                            label='Email'
                            name='email'
                            value={ form.email }
                            placeholder='e.g. bob@foo.com'
                            onChange={ updateState } />
              <Form.Input   id='password'
                            label='New Password'
                            name='password'
                            value={ form.password }
                            type='password'
                            placeholder='new password'
                            onChange={ updateState } />
              <Form.Input   id='password_confirmation'
                            label='Confirm Password'
                            name='password_confirmation'
                            type='password'
                            placeholder='confirm new password'
                            value={form.password_confirmation}
                            onChange={ updateState } />
              <Form.Button type='submit' primary>Submit</Form.Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MyProfile;
