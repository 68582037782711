import React              from 'react';
import PropTypes          from 'prop-types';
import { Button, Popup }  from 'semantic-ui-react';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title             = 'Make this group a Smart Group';
const defaultConditions = { and: [] };

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const SmartGroupButton = ({ onClick }) => {

  const handleClick = () => onClick(defaultConditions);

  const Btn = <Button icon='lightbulb outline'
                      size='mini'
                      basic
                      color='blue'
                      onClick={ handleClick } />;

  return (
    <Popup  trigger={ Btn }
            content={ title }
            position='top center' />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SmartGroupButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SmartGroupButton;
