import React, { Component }   from 'react';
import Modal       from './Modal';

const anchor = '#manage-modal';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class ManagePinnedMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened:   false
    };

    this.handleOpenModal  = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDelete        = this.handleDelete.bind(this);
  }

  render() {
    const { opened }  = this.state;
    const { recordId, history }    = this.props;

    return (
      <React.Fragment>
        <Modal opened={ opened }
                permissionGroupId={ recordId }
                onClose={ this.handleCloseModal }
                onDelete={ this.handleDelete }
                history={history} />
        <a onClick={ this.handleOpenModal } href={ anchor } >Manage Pinned Messages</a>
      </React.Fragment>
      );
  }

  handleOpenModal() {
    this.setState({ opened: true });
  }

  handleCloseModal() {
    this.setState({ opened: false });
  }

  async handleDelete(pinnedMessageId) {
    const { deleteView, recordId } = this.props;
    await deleteView(null, {id: pinnedMessageId, report_id: recordId});
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ManagePinnedMessages;