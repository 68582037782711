import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form, Icon, Grid } from 'semantic-ui-react';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const draggingStyle = {
  userSelect: 'none',
  background: 'lightyellow'
};

const baseStyle = {
  transition: 'background 0.75s'
};

function getDraggableStyle(isDragging, draggableStyle) {
  const style = { ...draggableStyle, ...baseStyle };

  if(isDragging) {
    return { ...style, ...draggingStyle,  };
  }

  return style;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FieldOption extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
    this.handleRemove  = this.handleRemove.bind(this);
  }

  render() {
    const { option,
            draggableProvided,
            draggableSnapshot } = this.props;

    const { dragHandleProps, draggableProps } = draggableProvided;
    const { label, value }                    = option;

    const style = getDraggableStyle(
      draggableSnapshot.isDragging,
      draggableProps.style
    );

    return (
      <Grid.Row { ...draggableProps } style={ style }>
        <Grid.Column width={ 1 }>
          <Icon name='bars'
                size='large'
                { ...dragHandleProps }
                tabIndex='-1' />

        </Grid.Column>
        <Grid.Column width={ 6 }>
          <Form.Input placeholder='Option Label'
                      name='label'
                      value={ label }
                      onChange={ this.handleChange } />
        </Grid.Column>
        <Grid.Column width={ 6 }>
          <Form.Input placeholder='Option Value'
                      name='value'
                      value={ value }
                      onChange={ this.handleChange } />
        </Grid.Column>
        <Grid.Column width={ 3 } textAlign='right'>
          <Form.Button    basic
                          color='red'
                          icon='remove'
                          size='mini'
                          floated='right'
                          type='button'
                          tabIndex='-1'
                          onClick={ this.handleRemove } />
        </Grid.Column>
      </Grid.Row>
    );
  }

  handleChange(evt, { name, value }) {
    const { option, index, onChange } = this.props;
    const nextOption = { ...option, [name]: value };

    onChange(evt, { option: nextOption, index });
  }

  handleRemove(evt) {
    evt.preventDefault();

    const { option, index, onRemove } = this.props;
    onRemove(evt, { option, index });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FieldOption.defaultProps = {

};

FieldOption.propTypes = {
  index:  PropTypes.number.isRequired,
  option: PropTypes.shape({
    id:       PropTypes.number,
    label:    PropTypes.string,
    value:    PropTypes.any,
    position: PropTypes.number
  }).isRequired,

  onChange:           PropTypes.func.isRequired,
  onRemove:           PropTypes.func.isRequired,
  draggableProvided:  PropTypes.object.isRequired,
  draggableSnapshot:  PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldOption;
