import React, { Component }  from 'react';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

// This HOC is meant to be used with the MasterDetail component.  It adds
// pagination calls to the MasterDetail's record list.
export default function(WrappedComponent, { getScope }={}) {
  return class AnonymousComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        page:   1,
        scope:  null
      };

      this.handleOnPageNext   = this.handleOnPageNext.bind(this);
      this.handleOnPageReset  = this.handleOnPageReset.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
      if(getScope && getScope.call) {
        const nextScope = getScope(props);
        const { scope } = state;

        if(scope !== nextScope) {
          return { scope: nextScope, page: 1 };
        } else {
          return null;
        }
      }

      return null;
    }

    render() {
      const { page } = this.state;

      return (
        <WrappedComponent { ...this.props }
                          page={ page }
                          onPageReset={ this.handleOnPageReset }
                          onPageNext={ this.handleOnPageNext } />
      );
    }

    handleOnPageNext() {
      const { page } = this.state;
      this.setState({ page: page + 1 });
    }

    handleOnPageReset() {
      this.setState({ page: 1 });
    }
  };
}
