import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { contentNamespace } from 'utils/content';
import expressionParser     from 'utils/expressionParser';
import Field                from 'components/forms/Field';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const labelFor = contentNamespace('fields.metadata.type_labels');

function fieldOptions(metadata) {
  const { options=[] } = metadata;
  return options.map(({ label, value }) => ({ text: label, value }));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class MetadataFields extends Component {

  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { value, metadata } = this.props;
    const fieldKeys           = Object.keys(metadata);
    const defaultExprContext  = fieldKeys.reduce((o, k) => {
                                  o[k] = null;
                                  return o;
                                }, {});

    return fieldKeys.map((key) => {
      const fieldMetadata                         = metadata[key];
      const { field_type, data_type, show_when }  = fieldMetadata;
      const fieldLabel                            = labelFor(key);
      const fieldValue                            = value[key] || '';
      const options                               = fieldOptions(fieldMetadata);

      if(show_when) {
        const exprContext = { ...defaultExprContext, ...value };
        const showField   = expressionParser(show_when, exprContext);

        if(!showField) {
          return null;
        }
      }

      return (
        <Field  key={ key }
                name={ key }
                label={ fieldLabel }
                value={ fieldValue }
                options={ options }
                internal_options={ options }
                fieldType={ field_type }
                dataType={ data_type }
                onChange={ this.handleChange } />
      );
    });
  }

  handleChange(evt, { name, value: fieldValue }) {
    const { value, onChange } = this.props;
    const nextValue           = { ...value, [name]: fieldValue };

    onChange(evt, { value: nextValue });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

MetadataFields.defaultProps = {
  value:    {},
  metadata: {}
};

MetadataFields.propTypes = {
  value:    PropTypes.object,
  metadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default MetadataFields;
