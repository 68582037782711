import { find, get } from 'lodash';

export function getChangedAnswer(field_id, changes, answers) {
  const changedValue = find(changes, { field_id: field_id });

  if (changedValue) {
    return changedValue.value;
  } else {
    return get(answers, `${field_id}.value`, '');
  }
}
