import React, { Component, Fragment } from 'react';
import { Button, Popup }              from 'semantic-ui-react';
import FormModal                      from './FormModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Add form logic.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class CreateButton extends Component {
  state = {
    opened: false
  }

  constructor(props) {
    super(props);
    this.handleClick  = this.handleClick.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  render() {
    const { opened } = this.state;

    const Btn = <Button icon='add'
                        size='mini'
                        basic
                        color='blue'
                        onClick={ this.handleClick } />;

    return (
      <Fragment>
        <FormModal  title={ title }
                    opened={ opened }
                    onClose={ this.handleClose }
                    { ...this.props } />

        <Popup  trigger={ Btn }
                content={ title }
                position='top center' />
      </Fragment>
    );
  }

  handleClick() {
    this.setState({ opened: true });
  }

  handleClose() {
    this.setState({ opened: false });
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CreateButton;
