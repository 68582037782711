import React from 'react';
import { Segment, Grid, Header, Table, Loader } from 'semantic-ui-react';

import Privileges       from 'constants/Privileges';
import WithPermission   from 'containers/WithPermission';
import SingleCellRow    from 'components/SingleCellRow';
import NewFormSelector  from '../shared/NewFormSelector';
import FormGroup        from './FormGroup';

import indexOnAttribute from 'utils/indexOnAttribute';

// -----------------------------------------------------
// Sub-Component
// -----------------------------------------------------

const Loading = () => (
  <SingleCellRow colSpan='5'>
    <Loader active inline='centered' />
  </SingleCellRow>
);

const Empty = () => (
  <SingleCellRow colSpan='5'>
    This Client Has No Forms
  </SingleCellRow>
);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

// tableLayout: 'fixed' prevents parent table from expanding when child table is
//rendered (see ./FormGroupInstancesDisplay for nested table)
const FormsGroupedDisplay = ({  match,
                                loading,
                                clientFormGroups,
                                forms,
                                sequences,
                                onSelectNew }) => {

  const indexedForms  = indexOnAttribute(forms, 'id');
  const sortedClientFormGroups = clientFormGroups.sort(({ id: a }, { id: b }) => {
    const { name: na } = indexedForms[a] || {};
    const { name: nb } = indexedForms[b] || {};

    return na > nb ? 1 : -1;
  });

  return (
    <Segment loading={ loading }>
      <Grid>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as='h3' content='Forms' />
          </Grid.Column>

          <Grid.Column width={6}>
            <WithPermission resource='client_forms'
                            actions={ Privileges.write } >
              <NewFormSelector forms={forms} sequences={sequences} onSelectNew={onSelectNew} />
            </WithPermission>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table style={{ marginTop: '2rem', tableLayout: 'fixed' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: '2rem' }}></Table.HeaderCell>
            <Table.HeaderCell>Form Name</Table.HeaderCell>
            <Table.HeaderCell>
              Count
            </Table.HeaderCell>
            <Table.HeaderCell>
              Last Administered On
            </Table.HeaderCell>
            <Table.HeaderCell>
              Last Administered By
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            ( loading && <Loading /> ) ||
            ( !clientFormGroups.length && <Empty /> ) ||

            sortedClientFormGroups.map( formGroup => (
              <FormGroup  key={[match.params.id, formGroup.id]}
                          match={match}
                          formGroup={formGroup}
                          forms={indexedForms} />
            ) )
          }
        </Table.Body>
      </Table>
    </Segment>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormsGroupedDisplay;
