import React        from 'react';
import PropTypes    from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import connectResource      from 'utils/connectResource';
import { getAll }           from 'utils/connectors';
import { dataGroups }       from 'resources/organizationResources';
import { sortByAttributes } from 'utils/sortComparators';

// -----------------------------------------------------
// Parent group options
// -----------------------------------------------------

function parentGroupOptions(dataGroups, childGroupId) {
  if(dataGroups === null || dataGroups === undefined) {
    return [];
  }

  return  Object.values(dataGroups)
          .filter(({ id }) => id !== childGroupId)
          .map(({ id, path_name }) => ({ value: id, text: path_name }))
          .sort(sortByAttributes('text'));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ParentGroupSelector = ({ childGroup, data, onChange, name, value }) => {
  const { id }          = childGroup || {};
  const { dataGroups }  = data;
  const options         = parentGroupOptions(dataGroups, id);

  return (
    <Dropdown placeholder='Select a parent group or leave blank'
              fluid
              search
              selection
              clearable
              name={ name }
              value={ value }
              options={ options }
              onChange={ onChange } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ParentGroupSelector.propTypes = {
  data: PropTypes.shape({
    dataGroups: PropTypes.object
  }).isRequired,

  onChange:   PropTypes.func.isRequired,
  childGroup: PropTypes.object
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(ParentGroupSelector, {
  connectors: {
    dataGroups: getAll(dataGroups)
  }
});
