import React, { useState, useCallback } from 'react';
import PropTypes                        from 'prop-types';
import { Label }                        from 'semantic-ui-react';
import get                              from 'lodash/get';

import { getSingleton }     from 'utils/connectors';
import connectResource      from 'utils/connectResource';
import { categoryRecords } from 'resources/organizationResources';

import CategoryRecordsModal  from './CategoryRecordsModal';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const categoriesModal  = '#categories-modal';

function orderedCategoryRecords({ categoryRecords={} }, recordType, recordId) {
  if(categoryRecords === null) {
    return [];
  }

  const scopedCategoryRecords =
    get(categoryRecords, [recordType, recordId], {});

  return  Object
          .values(scopedCategoryRecords)
          .sort(({ path_name: a }, { path_name: b }) => a > b ? 1 : -1);
}

const styles = {
  link:   { display: 'block' },
  label:  { float: 'right', clear: 'right' }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CategoryRecords = ({ data, loading, update, recordType, recordId }) => {
  const [isModalShowing, setModalShowing] = useState(false);
  const [isSaving, setSaving]             = useState(false);

  const categoryRecords  = orderedCategoryRecords(data, recordType, recordId);
  const groupCount        = categoryRecords.length;

  // -----------------------------------------------------
  // Callbacks
  // -----------------------------------------------------

  const handleClick = (evt) => {
    evt.preventDefault();
    setModalShowing(true);
  };

  const handleClose = () => {
    setModalShowing(false);
  };

  const handleSave = useCallback(async (category_records, done) => {

    setSaving(true);
    const response = await update({ category_records }, {
      record_type:  recordType,
      record_id:    recordId
    });
    setSaving(false);

    if(!response.errors) {
      done();
    }
  }, [update, recordId, recordType]);

  return (
    <>
      <a href={ categoriesModal } onClick={ handleClick } style={ styles.link }>
        <span>Manage Categories</span>
        <Label size='mini' color='blue' circular style={ styles.label }>
          { groupCount }
        </Label>
      </a>

      <CategoryRecordsModal  opened={ isModalShowing }
                              saving={ isSaving }
                              loading={ loading }
                              recordType={ recordType }
                              recordId={ recordId }
                              categoryRecords={ categoryRecords }
                              onSave={ handleSave }
                              onClose={ handleClose } />
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CategoryRecords.propTypes = {
  recordType: PropTypes.string.isRequired,
  recordId:   PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
              ]).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(CategoryRecords, {
  key: ({ recordType, recordId }) => [recordType, recordId].join('_'),
  connectors: {
    categoryRecords: getSingleton(
      categoryRecords,
      ({ recordType, recordId }) => ({
        record_type:  recordType,
        record_id:    recordId
      })
    )
  },
  mapDispatchToProps: {
    update: categoryRecords.actions.update
  }
});
