import React                    from 'react';
import { List }                 from 'semantic-ui-react';

import MasterDetail             from 'components/MasterDetail';
import withSearch               from 'hocs/withSearch';
import withPagination           from 'hocs/withPagination';
import { permission_groups,
         cloneRecords }    from 'resources/organizationResources';
import connectResource          from 'utils/connectResource';
import { getPageAllById }       from 'utils/connectors';
import { textSubstringFilter }  from 'utils/recordFilters';
import { sortByAttributes }     from 'utils/sortComparators';

import SideCardView   from '../SideCardView';
import DetailView     from './DetailView';
import FormView       from './FormView';

const ListItem = ({record}) => (
  <List.Header>{ record.name }</List.Header>
);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleClone  = this.handleClone.bind(this);
  }

  render() {
    const { data,
            destroy,
            update,
            create,
            loading } = this.props;

    const permissionGroups = data.permission_groups || [];

    return (
      <MasterDetail masterItemComponent={ ListItem }
                    detailComponent={ DetailView }
                    formComponent={ FormView }
                    sideCardComponent={ SideCardView }
                    records={ permissionGroups }
                    onDelete={id => destroy(null, { id })}
                    onUpdate={record => update(record, { id: record.id })}
                    onCreate={record => create(record)}
                    onClone={ this.handleClone }
                    loading={ loading }
                    listSort={ sortByAttributes('name') }
                    filterRecord={ textSubstringFilter('name') }
                    resource='permission_groups'
                    {...this.props} />
    );
  }

  handleClone(id, cloneData) {
    const { clone } = this.props;
    const scope     = { cloneable_type: 'permission_groups', cloneable_id: id, include_data: cloneData };
    return clone(null, scope);
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    permission_groups: getPageAllById(permission_groups)
  },
  mapDispatchToProps: {
    ...permission_groups.actions,
    ...cloneRecords.actions
  }
});

export default withSearch(withPagination(connectedComponent));
