import AuthenticatedResource  from 'resources/AuthenticatedResource';

export default class VersionRollbackResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/:recordType/:recordId/version_rollbacks';

  record_id   = undefined;
  record_type = undefined;
  created_at  = undefined;
  created_by  = undefined;
  updated_at  = undefined;
  updated_by  = undefined;
  history     = [];
  
  pk() {
    return `${this.record_type}/${this.record_id}`;
  }
}