import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Button, Popup }    from 'semantic-ui-react';


// import 'style/selection-buttons.css';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class WindowFilterSettings extends Component {
  render() {
    const { onChange }  = this.props;

    const SettingsBtn =
      <Button icon='cog'
              basic
              circular
              onClick={ onChange } />;

    return (
        <Popup  trigger={ SettingsBtn }
                content='Set window filters'
                position='top center' />
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

WindowFilterSettings.propTypes = {
  onChange:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default WindowFilterSettings;
