import AuthenticatedResource from 'resources/AuthenticatedResource';

export default class ApiCredentialResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/api_credentials';

  id          = undefined;
  name        = '';
  public_key  = null;
  private_key = null;
  created_at  = null;

  pk() {
    return this.id && this.id.toString();
  }
}