import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';
import { Form }                     from 'semantic-ui-react';

import connectResource              from 'utils/connectResource';
import { getAll }                   from 'utils/connectors';
import { users, permission_groups } from 'resources/organizationResources';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const userType              = 'User';
const permissionGroupType   = 'PermissionGroup';
const valueTypeIdDelimiter  = '_';

function computeOptions(users, permissionGroups) {
  const userOptions =
    Object
    .values(users)
    .map(({ id, first_name, last_name }) => (
      {
        text:   `${first_name} ${last_name}`,
        value:  computeValue(userType, id)
      }
    ))
    .sort(({ text: a }, { text: b }) => a > b ? 1 : -1);

  const permissionGroupOptions =
    Object
    .values(permissionGroups)
    .map(({ id, name }) => (
      {
        text:   name,
        value: computeValue(permissionGroupType, id)
      }
    ))
    .sort(({ text: a }, { text: b }) => a > b ? 1 : -1);

  return [ ...userOptions, ...permissionGroupOptions ];
}

function computeValue(permissionableType, permissionableId) {
  const value = [permissionableType, permissionableId].join(valueTypeIdDelimiter);

  if(value === valueTypeIdDelimiter) {
    return null;
  }

  return value;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class PermissionableSelector extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { data, permissionableType, permissionableId, name } = this.props;

    const users             = data.users || {};
    const permissionGroups  = data.permissionGroups || {};
    const value             = computeValue(permissionableType, permissionableId);

    return (
      <Form.Select  value={ value }
                    name={ name }
                    onChange={ this.handleChange }
                    selectOnBlur={ false }
                    search
                    options={ computeOptions(users, permissionGroups) } />
    );
  }

  handleChange(evt, { value, name }) {
    const [ permissionable_type,
            permissionable_id ] = value.split(valueTypeIdDelimiter);

    const nextValue     = { permissionable_type, permissionable_id };
    const { onChange }  = this.props;

    onChange(evt, { value: nextValue, name });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

PermissionableSelector.defaultProps = {
  permissionableType: null,
  permissionableId:   null
};

PermissionableSelector.propTypes = {
  permissionableType: PropTypes.string,
  permissionableId:   PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),

  data:     PropTypes.shape({
    users:            PropTypes.object,
    permissionGroups: PropTypes.object
  }).isRequired,

  name:     PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(PermissionableSelector, {
  connectors: {
    users:            getAll(users),
    permissionGroups: getAll(permission_groups)
  }
});
