import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import DateSelect           from 'components/DateSelect';
import moment               from 'moment';

// -----------------------------------------------------
// Config
// -----------------------------------------------------

const defaultOutputFormat = 'MM/dd/yyyy';
const defaultInputFormat  = 'YYYY-MM-DD';

function prepopulate() {
  return moment().format(defaultInputFormat);
}

function setDate({type, value=null}) {
  switch (type) {
    case 'today':
      return moment().toDate();
    case 'specific':
      return moment(value).toDate();
    case 'diff':
      return moment().add('days', value).toDate();
  }
}

function setIncludeIntervals(settings={}) {
  const interval = {};

  ['minDate', 'maxDate'].forEach((key) => {
    if (Object.keys(settings).includes(key)) {
      interval[key] = setDate(settings[key]);
    }
  });

  return interval;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Date extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    const { value,
            metadata: { pre_populate } } = this.props;

    // Handles prepopulation.
    if((value === null || value === undefined) && pre_populate) {
      const defaultValue = prepopulate();

      this.update(defaultValue);
    }
  }

  render() {
    const { value, metadata, settings } = this.props;
    const format              = metadata.format || defaultOutputFormat;
    const defaultValue        = metadata.pre_populate ? prepopulate() : null;
    const dateInterval        = setIncludeIntervals(settings);

    return (
      <DateSelect value={ value || defaultValue }
                  format={ format }
                  dateInterval={ dateInterval }
                  onChange={ this.handleChange } />
    );
  }

  handleChange(value) {
    this.update(value);
  }

  update(value) {
    const { name, onChange } = this.props;
    onChange(null, { name, value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Date.defaultProps = {
  metadata: { format: defaultOutputFormat, pre_populate: false }
};

Date.propTypes = {
  value:    PropTypes.string,
  metadata: PropTypes.shape({
    format:       PropTypes.string,
    pre_populate: PropTypes.bool
  }),
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Date;
