import React, { Component }   from 'react';
import LogModal               from './LogModal';
import connectResource        from 'utils/connectResource';
import { getSingleton }       from 'utils/connectors';
import { formImports }        from 'resources/organizationResources';

const anchor = '#import-log-modal';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class Log extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened:   false
    };

    this.handleOpenModal  = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  render() {
    const { opened }  = this.state;
    const { data }    = this.props;
    const imports     = data.imports || {};

    return (
      <React.Fragment>
        <LogModal opened={ opened }
                  onClose={ this.handleCloseModal }
                  imports={ imports } />
        <a onClick={ this.handleOpenModal } href={ anchor } >View Import/Delete Log</a>
      </React.Fragment>
      );
  }

  handleOpenModal() {
    this.setState({ opened: true });
  }

  handleCloseModal() {
    this.setState({ opened: false });
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default connectResource(Log, {
  key: ({ recordId }) => recordId,
  connectors: {
    imports: getSingleton(formImports, ({ recordId }) => ({
      form_id:   recordId
    }))
  },
  mapDispatchToProps: { ...formImports.actions }
});