import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';
import { List, Icon }       from 'semantic-ui-react';

import StateIndicator from './StateIndicator';
// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const OWNER_TYPE = 'owner';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ListItemView = ({ record }) => {
  const { type, name, state } = record;
  const isOwner = type === OWNER_TYPE;

  return (
    <Fragment>
      <List.Content floated='right'>
        {
          isOwner &&
          <Icon name='chess queen' color='yellow' />
        }
        <StateIndicator state={ state } />
      </List.Content>
      
      <List.Header>{ name }</List.Header>
    </Fragment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ListItemView.propTypes = {
  record: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ListItemView;
