import React, { Component }                           from 'react';
import PropTypes                                      from 'prop-types';
import { Form, Divider }  from 'semantic-ui-react';

import { getAllById }  from 'utils/connectors';
import connectResource          from 'utils/connectResource';
import {forms,
        fields } from 'resources/organizationResources';

import { find } from 'lodash';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

// Used to filter out non- internal forms from the form selector
function isInternal({ form_type }) {
  return form_type === 'internal';
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class InternalOptions extends Component {
  constructor(props) {
    super(props);

    this.handleChange   = this.handleChange.bind(this);
  }

  render() {
    const { forms, fields }   = this.props.data;
    const { internal_form_id=null, internal_field_id=null } = this.props.options;

    const internalForms = forms.filter(isInternal);
    const internalFormOptions = internalForms
                                 .map( ({id, name}) => ({ value: id, text: name}))
                                 .sort(({ text: a }, { text: b }) => a > b ? 1 : -1);


    const currentForm = find(internalForms, ['id', internal_form_id]) || {};
    const {field_ids=[]} = currentForm;

    const formFields = fields.filter(({id}) => field_ids.includes(id));
    const formFieldOptions = formFields
                              .map( ({id, name}) => ({ value: id, text: name}))
                              .sort(({ text: a }, { text: b }) => a > b ? 1 : -1);


    return (
      <div>
        <Divider horizontal>Internal Options</Divider>

        <Form.Select  label='Choose Resource table'
                            selectOnBlur={ false }
                            options={ internalFormOptions }
                            value={ internal_form_id }
                            option_name='internal_form_id'
                            onChange={ this.handleChange }
                            clearable
                            search />

        <Form.Select  label='Choose Resource label column'
                            selectOnBlur={ false }
                            options={ formFieldOptions }
                            value={ internal_field_id }
                            option_name='internal_field_id'
                            onChange={ this.handleChange }
                            clearable
                            search />

      </div>
    );
  }

  handleChange(evt, { value, option_name }) {
    const { options, name, onChange } = this.props;
    const nextOptions  = { ...options };
    nextOptions[option_name] = value;

    onChange(evt, { name, value: nextOptions });
  }

}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

InternalOptions.propTypes = {
  options:  PropTypes.arrayOf(PropTypes.shape({
    id:       PropTypes.number,
    label:    PropTypes.string,
    value:    PropTypes.any,
    position: PropTypes.number
  })).isRequired,

  name:     PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(InternalOptions, {
  connectors: {
    forms:        getAllById(forms, ()=>({}), true),
    fields:       getAllById(fields, ()=>({}), true),
  }
});
