import React              from 'react';
import PropTypes          from 'prop-types';
import { Button, Popup }  from 'semantic-ui-react';
import { Link }           from 'react-router-dom';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Create a Sub-Group';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CreateSubGroupButton = ({ parent }) => {
  if(parent === null || parent === undefined) {
    return null;
  }

  const path = '/organization/data_groups/new';

  const Btn = <Button icon='plus'
                      size='mini'
                      basic
                      color='blue'
                      as={ Link }
                      to={ { pathname: path, state: { parent } } } />;

  return (
    <Popup  trigger={ Btn }
            content={ title }
            position='top center' />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CreateSubGroupButton.propTypes = {
  parent: PropTypes.object,
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CreateSubGroupButton;
