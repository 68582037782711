import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import throttle             from 'lodash/throttle';

import ScrollPane from 'components/ScrollPane';
import { sortByAttributePath, sortDirectionally }  from 'utils/sortComparators';

import SortQueue  from './SortQueue';
import ResizableTable  from './ResizableTable';

import 'style/components/SortableTable.css';

// -----------------------------------------------------
// Helpers / Constants
// -----------------------------------------------------

const PAGINATION_DEBOUNCE = 250;

function applySort(records, sortQueue, sortAttribute) {
  return sortQueue.reduce((recs, { index, direction }) => {

    const attributePath =
      (sortAttribute === null || sortAttribute === undefined)
      ? index
      : [index, sortAttribute];

    const comparator            = sortByAttributePath(attributePath);
    const directionalComparator = sortDirectionally(comparator, direction);

    return recs.sort(directionalComparator);
  }, [...records]);
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class SortableTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sortQueue: new SortQueue(3),
    };

    this.handleSort     = this.handleSort.bind(this);
    this.handleScrollY  = this.handleScrollY.bind(this);
  }

  render() {
    const { tableId,
            records,
            controlCellRenderer,
            controlsMinWidth,
            sortAttribute,
            scrollPaneStyleOverrides,
            remoteSort,
            ...forwardProps }  = this.props;

    // We have to do this to keep React from filling our console with warnings.
    delete forwardProps.onPageNext;
    delete forwardProps.onSort;
    delete forwardProps.defaultSort;
    delete forwardProps.headerLabels;
    delete forwardProps.cellRenderer;
    delete forwardProps.columnCloaker;

    const { sortQueue } = this.state;

    const showControls  = controlCellRenderer !== null
                          && controlCellRenderer !== undefined;

    const sortedRecords = remoteSort
                          ? records
                          : applySort(records, sortQueue, sortAttribute);

    return (
      <ScrollPane onScrollY={ this.handleScrollY }
                  styleOverrides={scrollPaneStyleOverrides}
                  { ...forwardProps }>

        <ResizableTable { ...this.props }
                        tableId={ tableId }
                        records={ sortedRecords }
                        showControls={ showControls }
                        controlsMinWidth={ controlsMinWidth }
                        sortQueue={ sortQueue }
                        onSort={ this.handleSort }
                         />
      </ScrollPane>
    );
  }

  handleSort({ columnIndex, sortDirection }) {
    const { onSort }    = this.props;
    const { sortQueue } = this.state;

    const nextSortDirections  = sortQueue.push(columnIndex, sortDirection);

    onSort({ sort: nextSortDirections.buffer });
    this.setState({ sortQueue: nextSortDirections });
  }

  handleScrollY = throttle((evt) => {
    const { onPageNext }  = this.props;

    if(!onPageNext) {
      return;
    }

    this.scroller                                   = this.scroller || evt.target;
    const { clientHeight, scrollTop, scrollHeight } = this.scroller;

    const clientBottomY = scrollTop + clientHeight;
    const distanceFromBottom = scrollHeight - clientBottomY;
    const proximityLimit = 10;

    if(distanceFromBottom < proximityLimit) {
      onPageNext();
    }
  }, PAGINATION_DEBOUNCE)
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SortableTable.defaultProps = {
  onSort:       a => a,
  remoteSort:   false
};

SortableTable.propTypes = {
  tableId:              PropTypes.string,
  headerLabels:         PropTypes.arrayOf(PropTypes.string).isRequired,
  records:              PropTypes.arrayOf(PropTypes.array).isRequired,
  sortAttribute:        PropTypes.string,
  controlsMinWidth:     PropTypes.string,
  remoteSort:           PropTypes.bool,
  cellRenderer:         PropTypes.func,
  controlCellRenderer:  PropTypes.func,
  columnCloaker:        PropTypes.func,
  onPageNext:           PropTypes.func,
  onSort:               PropTypes.func,
  defaultSort:          PropTypes.instanceOf(SortQueue)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export { SortQueue };
export default SortableTable;
