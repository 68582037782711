import React                    from 'react';
import PropTypes                from 'prop-types';
import { Form, Grid, Loader }   from 'semantic-ui-react';

import DateSelect               from 'components/DateSelect';
import useClientSearch          from 'caseWorthy/hooks/useClientSearch';

import ClientSearchResults  from './ClientSearchResults';
import CreateClientCheckbox   from './CreateClientCheckbox';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ClientSearchForm = ({ client, onSelect, onCreate, onSubmit }) => {
  const { first_name, last_name } = client;

  const initialParams = {
    first_name,
    last_name,
    birth_date: ''
  };

  // const [selected, setSelected] = useState();
  const [ { isLoading, /*isError,*/ data },
          params,
          setParams ] = useClientSearch(initialParams);

  const { data: clients } = data;

  const handleChange = (evt, { name, value }) => {
    setParams({
      ...params,
      [name]: value
    });
  };

  const handleChangeCreate = (evt, data) => {
    onCreate(data);
  };

  const handleBirthDateChange = (value) => {
    if(value === undefined) {
      handleChange(null, { value: '', name: 'birth_date' });
    } else {
      handleChange(null, { value, name: 'birth_date' });
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  return (
    <Grid columns={ 2 } divided>
      <Grid.Row>
        <Grid.Column>
          <Form onSubmit={ handleSubmit }>
            <Form.Field>
              <label>First Name</label>
              <Form.Input name='first_name'
                          value={ params.first_name }
                          onChange={ handleChange } />
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <Form.Input name='last_name'
                          value={ params.last_name }
                          onChange={ handleChange } />
            </Form.Field>
            <Form.Field>
              <label>Birth Date</label>
              <DateSelect value={ params.birth_date }
                          onChange={ handleBirthDateChange } />

            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column>
          {
            isLoading
            ? <Loader active>Searching...</Loader>
            : clients?.length > 0
              ? <ClientSearchResults clients={ clients } onSelect={ onSelect } />
              : <CreateClientCheckbox client={ client }
                                      onChange={ handleChangeCreate }
                                      onSubmit={ onSubmit } />
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ClientSearchForm.defaultProps = {
  client:   {}
};

ClientSearchForm.propTypes = {
  client:   PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ClientSearchForm;
