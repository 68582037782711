import AuthenticatedResource from 'resources/AuthenticatedResource';

// --------------------------------------------------------
// Resource Definition
// --------------------------------------------------------

export default class DataGroupResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/data_groups/';

  id                = undefined;
  parent_id         = undefined;
  name              = undefined;
  number_of_records = 0;
  path              = [];
  smart_conditions  = { and: [] };
  sub_groups        = [];
  created_at        = null;
  updated_at        = null;

  pk() {
    return this.id && this.id.toString();
  }
}