import React, { useState, useEffect, useCallback }  from 'react';
import PropTypes                                    from 'prop-types';
import { Button }                                   from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const RefreshButton = ({ reportDefinition, refresh, viewId, ...forwardProps }) => {
  const [ refreshing, setRefreshing ] = useState(false);

  useEffect(() => {
    setRefreshing(reportDefinition.refreshing);
  }, [reportDefinition]);

  const handleClick = useCallback(async (evt) => {
    evt.preventDefault();
    setRefreshing(true);

    const { id }  = reportDefinition;

    const { refreshing: serverRefreshing } = await refresh({ id, viewId });
    setRefreshing(serverRefreshing);

  }, [refresh, reportDefinition, viewId]);

  return (
    <Button basic
            { ...forwardProps }
            icon='refresh'
            disabled={ refreshing }
            loading={ refreshing }
            onClick={ handleClick } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

RefreshButton.propTypes = {
  viewId:           PropTypes.number.isRequired,
  reportDefinition: PropTypes.object.isRequired,
  refresh:          PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default RefreshButton;
