import React      from 'react';
import PropTypes  from 'prop-types';

// -----------------------------------------------------
// Class Definition
// -----------------------------------------------------

export default class If extends React.Component {
  static propTypes = {
    condition:  PropTypes.bool.isRequired,
    container:  PropTypes.string
  };

  static defaultProps = {
    container: 'span'
  };

  render() {
    const Container = this.props.container;

    if(this.props.condition) {
      return <Container>{ this.props.children }</Container>;
    } else {
      return null;
    }
  }
}
