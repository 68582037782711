import React                      from 'react';
import { Link }                   from 'react-router-dom';
import { Button, Confirm }        from 'semantic-ui-react';
import urljoin                    from 'url-join';

import Privileges                 from 'constants/Privileges';
import WithPermission             from 'containers/WithPermission';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ManageRecordHeaderBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmOpen:  false,
      modalOpen:    false,
      reloading:    false
    };

    this.handleDelete         = this.handleDelete.bind(this);
    this.handleCancelDelete   = this.handleCancelDelete.bind(this);
    this.handleConfirmDelete  = this.handleConfirmDelete.bind(this);
    this.handleCloneButton    = this.handleCloneButton.bind(this);
    this.handleCloneModal     = this.handleCloneModal.bind(this);
    this.handleCloseCloneModal= this.handleCloseCloneModal.bind(this);
  }

  render() {
    const {
      cloneModalComponent:  CloneModal,
      match,
      resource,
      onClone,
      disableDelete,
      disableEdit,
      rootPath,
    } = this.props;

    const { confirmOpen, modalOpen } = this.state;

    return (
        <span>
          <WithPermission resource={ resource }
                          recordId={ match.params.id }
                          actions={ Privileges.write }>

            <Button as={ Link }
                    to={ urljoin(rootPath, match.params.id, 'edit') }
                    color='black'
                    icon='write'
                    size='small'
                    disabled={ disableEdit(match.params.id) }
                    circular
                    compact
                    basic />
          </WithPermission>

          {
            !!onClone &&
            <WithPermission resource={ resource }
                            recordId={ match.params.id }
                            actions={ Privileges.write }>

              { CloneModal &&
                <CloneModal id={ match.params.id }
                            opened={ modalOpen }
                            action='clone'
                            onClose={ this.handleCloseCloneModal }
                            onSubmit={ this.handleCloneModal }
                            { ...this.props } />
              }

              <Button onClick={ () => this.handleCloneButton(match.params.id) }
                      color='black'
                      icon='copy'
                      size='small'
                      circular
                      compact
                      basic />
            </WithPermission>
          }

          <WithPermission resource={ resource }
                          recordId={ match.params.id }
                          actions={ Privileges.remove }>
            <Button
              onClick={ this.handleDelete }
              color='black'
              icon='trash'
              size='small'
              disabled={ disableDelete(match.params.id) }
              circular
              compact
              basic />
          </WithPermission>

          <Confirm
            as={Link}
            to={'./'}
            open={ confirmOpen }
            dimmer='inverted'
            onCancel={ this.handleCancelDelete }
            onConfirm={ () => this.handleConfirmDelete(match.params.id) }
          />

        </span>
    );
  }

  handleDelete() {
    this.setState({ confirmOpen: true });
  }

  handleCancelDelete() {
    this.setState({ confirmOpen: false });
  }

  handleConfirmDelete(id) {
    const { onDelete, rootPath } = this.props;

    this.setState({ confirmOpen: false });
    onDelete(id);
    this.props.history.push(urljoin(rootPath, id));
  }

  async handleCloneButton(id) {
    const { cloneModalComponent, onClone, rootPath } = this.props;

    if(cloneModalComponent) {
      this.setState({ modalOpen: true });
    } else {
      const res = await onClone(id);
      if(res.id) {
        this.props.history.push(urljoin(rootPath, res.id, 'edit'));
      }
    }
  }

  async handleCloneModal(id, cloneData) {
    const { onClone, rootPath } = this.props;

    const res = await onClone(id, cloneData);
    if(!cloneData && res.id) {
      this.props.history.push(urljoin(rootPath, res.id, 'edit'));
    }
    this.handleCloseCloneModal();
  }

  handleCloseCloneModal() {
    this.setState({ modalOpen: false });
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default ManageRecordHeaderBar;
