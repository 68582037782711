import AuthenticatedResource from 'resources/AuthenticatedResource';

// --------------------------------------------------------
// Resource Definition
// --------------------------------------------------------

export default class PermissionGroupResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/permission_groups/';

  id          = undefined;
  name        = undefined;
  default     = false;
  user_count  = 0;
  created_at  = null;
  updated_at  = null;

  pk() {
    return this.id && this.id.toString();
  }
}