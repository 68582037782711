import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Header, Segment }  from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class ErrorsCard extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  // handlers
  handleClick(evt) {
    evt.stopPropagation();
  }

  // rendering
  render() {
    const { loading, record } = this.props;

    return (
      <Segment loading={ loading }>
        <Header as='h3'>
          Errors
        </Header>
        <p>
          One or more records in the input file could not be
          processed. Please click the link below to download
          a log of all such exceptions.
        </p>
        <a  href={ record.error_url }
            target='_new'
            onClick={ this.handleClick }>
          Download
        </a>
      </Segment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

ErrorsCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  record:  PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ErrorsCard;
