import React, { useState }          from 'react';
import { Segment, Header, Message } from 'semantic-ui-react';
import { Link }                     from 'react-router-dom';

import SortableTable  from 'components/SortableTable';
import Main           from 'components/Main';

import useOrganizationResource    from 'hooks/useOrganizationResource';
import DtdReferralResource        from 'caseWorthy/resources/DtdReferralResource';
import WithCaseWorthyAffiliation  from 'caseWorthy/components/WithCaseWorthyAffiliation';

import formattedDate  from 'utils/formattedDate';

import Filters from './Filters';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const hrefColumnIndex = 0;

// These columns will have their values formatted.
const dateTimeColumnIndices = [1];

const headerLabels = {
  referral_date:      'Referral Date',
  referral_status:    'Referral State',
  service_type:       'Service Type',
  from_organization:  'Referred From',
  to_organization:    'Referred To',
  referral_reason:    'Referral Reason',
  notes:              'Level of Need/Notes'
};

const orderedColumns = [
  'href',
  'referral_date',
  'referral_status',
  'service_type',
  'from_organization',
  'to_organization',
  'referral_reason',
  'notes'
];

function getTableData(records) {
  const result = { headers: [], records: [] };

  if(!records || records.length === 0) {
    return result;
  }

  result.headers  = orderedColumns.map(key => headerLabels[key]);
  result.records  = records.map(rec => (
    orderedColumns.map(key => (
      key === 'href'
      ? rec.client_id 
        && rec.client_form_id 
        && `/organization/clients/${rec.client_id}/client_forms/${rec.client_form_id}`
      : rec[key]
    ))
  ));

  return result;
}

function cellRenderer([href,], value, columnIndex) {
  const formattedValue  = dateTimeColumnIndices.includes(columnIndex)
                          ? formattedDate(value)
                          : value;

  return (
    href
    ? <Link to={ href }>{ formattedValue }</Link>
    : formattedValue
  );
}

function cloaker(index) {
  return index === hrefColumnIndex;
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const ReferralListing = () => {
  const [queryParams, setQueryParams] = useState([]);
  const referrals = useOrganizationResource(DtdReferralResource.listShape(), queryParams);
  
  const { headers, records } = getTableData(referrals);

  return (
    <WithCaseWorthyAffiliation>
      <Main overflow="scroll" className="padded-content">
        <Segment>
          <Header as='h2'>DTD Referrals</Header>
          <Filters onSubmit={ setQueryParams } />
          {
            referrals.length > 0
            ? <SortableTable  tableId='dtd_referrals'
                              headerLabels={ headers }
                              records={ records }
                              cellRenderer={ cellRenderer }
                              columnCloaker={ cloaker } />

            : <Message info>
                There are currently no referrals to display.
              </Message>
          }
        </Segment>
      </Main>
    </WithCaseWorthyAffiliation>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

ReferralListing.defaultProps  = {};
ReferralListing.propTypes     = {};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ReferralListing;