import React      from 'react';
import PropTypes  from 'prop-types';
import { Link }   from 'react-router-dom';

import DefaultRenderer        from 'components/renderers/DefaultRenderer';
import { hasAggs, recordRef, isHouseholdReport } from 'utils/reportDefinitions';
import AnswerRenderer         from 'components/fields/AnswerRenderer';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const ClientFormIdRenderer = ({ value, clientId, clientFormId }) => {  
  if (typeof value === 'string' && value.startsWith('http')) {
    return <AnswerRenderer answer={{value, field_type: 'hyperlink'}} />;
  } else {
    const url = ['/organization/clients/', clientId];

    if (clientFormId) {
      url.push(`/client_forms/${clientFormId}`);
    }
    
    return ( <Link to={ url.join('') } target='_blank' title={ value }>
              { value }
          </Link> );
  }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ClientFormReportCellRenderer = ({ reportDefinition, headers, record, value, columnIndex }) => {
  const householdReport = isHouseholdReport(reportDefinition);

  if(hasAggs(reportDefinition) && !householdReport) {
    return (
      <DefaultRenderer  record={ record }
                        value={ value }
                        columnIndex={ columnIndex } />
    );
  } else {
    const refJSON = recordRef(reportDefinition, householdReport, headers, record, columnIndex);

    try {
      const { client_id, response_id } = refJSON && JSON.parse(refJSON);

      if(client_id) {
        return (
          <ClientFormIdRenderer value={ value }
                                clientId={ client_id }
                                clientFormId={ response_id } />
        );
      } else {
        return (
          <DefaultRenderer  record={ record }
                            value={ value }
                            columnIndex={ columnIndex } />
        );
      }
    } catch(e) {
      return (
        <DefaultRenderer  record={ record }
                          value={ value }
                          columnIndex={ columnIndex } />
      );
    }
  }
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ClientFormReportCellRenderer.propTypes = {
  value:        PropTypes.any,
  record:       PropTypes.array,
  columnIndex:  PropTypes.number
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ClientFormReportCellRenderer;
