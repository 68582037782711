import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { Form, Button }     from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const NoteForm = ({ onCreate, notableType, notableId }) => {
  const [body, setBody] = useState('');

  const handleSubmit = async () => {
    const resp  = await onCreate({ 
                          notable_type: notableType, 
                          notable_id:   notableId 
                        }, { body });

    if(!resp.errors) {
      setBody('');
      window.location.reload(false);
    }
  };

  const handleChange = (evt, { value }) => {
    setBody(value);
  };

  return (
    <Form onSubmit={ handleSubmit } reply>
      <Form.TextArea value={ body } onChange={ handleChange } rows={ 1 } />
      <Button content='Add Note' labelPosition='left' icon='edit' primary />
    </Form>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

NoteForm.propTypes = {
  notableType:  PropTypes.string.isRequired,
  notableId:    PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  onCreate: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default NoteForm;
