import moment from 'moment';

const defaultFormat = '24-hour';
const twentyfourHourFormat = 'HH:mm';
const twelveHourFormat = 'hh:mm A';

const formattedTime = (time, format, fallback) => {
  if (!time) {
    return fallback;
  }

  return format === defaultFormat
          ? time
          : moment(time, twentyfourHourFormat).format(twelveHourFormat);
};

export default formattedTime;
