import React             from 'react';
import SelectionTable                 from './SelectionTable';
import ReportDefinitionResource from 'resources/organization/ReportDefinitionResource';
import useOrganizationResource    from 'hooks/useOrganizationResource';


const QueryFieldSelection = (props) => {

  const { reportId } = props || {};

  const report = useOrganizationResource(
    ReportDefinitionResource.detailShape(),
    { id: reportId }
  );

  return (
          <SelectionTable reportTarget={ report }
                              { ...props } />
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default QueryFieldSelection;

