import React, { useState } from 'react';
import { useSelector }  from 'react-redux';
import { Button, Segment, SegmentGroup, Header, Confirm, Modal }  from 'semantic-ui-react';

import Main from 'components/Main';
import urljoin  from 'url-join';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const DashboardContainer = (/*props*/) => {
  const [statusMessage, setStatusMessage] = useState('');
  const [showStatusMessage, setShowStatusMessage] = useState(false);
  const [confirmRefreshOpen, setConfirmRefreshOpen] = useState(false);
  const { authToken } = useSelector(state => state.auth);

  const openConfirmRefresh = () => setConfirmRefreshOpen(true);
  const dismiss = () => {
    setConfirmRefreshOpen(false);
    setShowStatusMessage(false);
  };
  const runRefresh = async () => {
    const options = {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer: ${authToken}`
      },
    };
    const url = urljoin(process.env.REACT_APP_API_HOST, '/admin/maintenance/nightly_build_refresh');
    const response = await fetch(url, options);
    setConfirmRefreshOpen(false);
    if (response.ok) {
      const data = await response.json();
      setStatusMessage(data.message);
    } else {
      setStatusMessage('Error: ' + response.statusText);
    }
    setShowStatusMessage(true);
  };

  return (
    <Main className='detail-pane' overflow="scroll" padded>
      <SegmentGroup horizontal>
        <Segment>
          <Header as='h3'>Maintenance Tools</Header>
          <Button primary onClick={openConfirmRefresh}>Refresh Pivot Tables</Button>
          <Confirm
            open={confirmRefreshOpen}
            header='Confirm Refresh Pivot Tables'
            content='Are you sure you want to refresh all the report data? Can take up to ~2 hours'
            onCancel={dismiss}
            onConfirm={runRefresh}
            size='mini'
          />
        </Segment>
      </SegmentGroup>

      <Modal
        open={showStatusMessage}
        header='Status:'
        content={statusMessage}
        actions={[{ key: 'ok', content: 'Ok', primary: true }]}
        onActionClick={dismiss}
        size='mini'
      />
    </Main>
  );
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default DashboardContainer;
