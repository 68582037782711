import React            from 'react';
import PropTypes        from 'prop-types';
import { Header, Grid } from 'semantic-ui-react';

import AffiliationStatus  from './AffiliationStatus';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const OwnerView = ({ affiliation }) => {
  const { name } = affiliation;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as='h2'>Affiliation request to { name }</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <AffiliationStatus affiliation={ affiliation } />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

OwnerView.defaultProps = {

};

OwnerView.propTypes = {
  affiliation: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default OwnerView;
