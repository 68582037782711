import React, { useState } from 'react';
import { Grid, Segment, Header, Container,
         Divider, Form, Button, Image } from 'semantic-ui-react';
         
import FileField                    from 'components/forms/Field/Attachment';
import useOrganizationFetcher       from 'hooks/useOrganizationFetcher';
import useOrganizationResource      from 'hooks/useOrganizationResource';
import OrganizationAccountResource  from 'resources/organization/OrganizationAccountResource';
import { formDataRequestMapper }    from 'utils/requestMappers';

import ApiKeys from './ApiKeys';

const style = {
  grid: {
    'height': '100%',
    'marginTop': '2rem'
  }
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const MyOrganization = ({ history }) => {
  const organizationAccount = useOrganizationResource(
                                OrganizationAccountResource.singletonDetailShape()
                              );
    
  const update              = useOrganizationFetcher(
                                OrganizationAccountResource.singletonUpdateShape()
                              );
      
  const { name, logo: currentLogo, api_access_enabled } = organizationAccount;
  const [logo, setLogo]                                 = useState(currentLogo);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const body      = formDataRequestMapper({ logo });
    const response  = await update(null, body);

    if(response) {
      history.push('/organization/dashboard');
    }
  };

  const handleLogoChange = (evt, { value }) => {
    setLogo(value);
  };

  return (
    <Grid verticalAlign='middle' centered columns='2' style={ style.grid }>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' textAlign='center'>{ name }</Header>
          <Segment >
            { logo &&
              <Container >
                <Image src={ logo } size="medium" centered />
                <Divider />
              </Container>
            }
            <Form onSubmit={ handleSubmit }>
              <FileField  ownLabel='Change Organization Logo'
                          value={ logo }
                          onChange={ handleLogoChange } />

              <Button.Group>
                <Button as='a' href='/organization/dashboard'>Cancel</Button>
                <Button.Or />
                <Button type='submit' positive
                        onSubmit={ handleSubmit }>Save</Button>
              </Button.Group>
            </Form>
          </Segment>

          {
            api_access_enabled
            &&  <ApiKeys />
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default  MyOrganization;
