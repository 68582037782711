import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';

import {
  Segment,
  Header,
  Message,
  Table,
  Icon,
  Divider }         from 'semantic-ui-react';

import Record       from './Record';
import CreateButton from './CreateButton';
import { orderBy }  from 'lodash';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const PublishSettings = ({ records, organization, loading, onSave }) => {
  const { slug } = organization;
  const orderedRecords = orderBy(records, 'published_at', 'desc');

  return (
    <Segment loading={ loading }>
      <Header as='h3'>Publish Settings</Header>
      <div style={{ marginTop: '2rem' }}>
        {
          records && records.length
          ? <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Published At</Table.HeaderCell>
                  <Table.HeaderCell>Expires At</Table.HeaderCell>
                  <Table.HeaderCell>Recurrence</Table.HeaderCell>
                  <Table.HeaderCell>From - To</Table.HeaderCell>
                  <Table.HeaderCell>Translate</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>
                    <CreateButton onSubmit={ onSave } />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  orderedRecords.map((record) => (
                    <Record key={ record.id }
                      slug={ slug }
                      onSave={ onSave }
                      { ...record } />
                  ))
                }
              </Table.Body>
            </Table>
          : <Fragment>

              <Message info icon>
                <Icon name='info circle' />
                This form has not been published for public use.
              </Message>

              <Divider horizontal>Publish</Divider>
              <p>
                <CreateButton onSubmit={ onSave } /> Publish this form.
              </p>
            </Fragment>
        }
      </div>
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

PublishSettings.defaultProps = {
  records:      [],
  organization: {},
  loading:      false
};

PublishSettings.propTypes = {
  records:      PropTypes.arrayOf(PropTypes.object),
  organization: PropTypes.object,
  loading:      PropTypes.bool,
  onSave:       PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default PublishSettings;
