import React, { useState }        from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header, Table } from 'semantic-ui-react';

import formattedDate            from 'utils/formattedDate';
import useOrganizationFetcher   from 'hooks/useOrganizationFetcher';
import ReferralResource         from 'caseWorthy/resources/ReferralResource';

import SelectorLabel from './SelectorLabel';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ReferralStatus = ({ clientForm }) => {
  const { case_worthy_referral }  = clientForm;
  const [referral, setReferral]   = useState(case_worthy_referral);
  const [saving, setSaving]       = useState(false);
  const update                    = useOrganizationFetcher(
                                      ReferralResource.updateShape()
                                    );

  if(!referral) {
    return null;
  }

  const { created_at,
          referral_id,
          status_updated_at,
          note }              = referral;

  const handleSave = async ({ id, referral_status }) => {
    setSaving(true);

    const params    = { id };
    const body      = { referral_status };
    const response  = await update(params, body);

    if(!response.errors) {
      setReferral(response);
    }

    setSaving(false);
  };

  return (
    <Segment loading={ saving }>
      <Header as='h3'>DTD Referral Status</Header>

      <Table basic='very' style={{ marginTop: '2rem' }}>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={ 7 }>Status:</Table.Cell>
            <Table.Cell>
              <SelectorLabel referral={ referral } onSave={ handleSave } />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Referred At:</Table.Cell>
            <Table.Cell>
              { formattedDate(created_at) }
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>DTD Referral ID:</Table.Cell>
            <Table.Cell>
              { referral_id }
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Status Last Updated At:</Table.Cell>
            <Table.Cell>
              {
                formattedDate(status_updated_at)
              }
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Note:</Table.Cell>
            <Table.Cell>
              { note }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ReferralStatus.propTypes = {
  clientForm: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ReferralStatus;
