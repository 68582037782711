import React           from 'react';
import PropTypes    from 'prop-types';
import { Grid, Header, Form, Table, Checkbox } from 'semantic-ui-react';
import { axisLabels } from 'constants/reports/Charts';

const style = {
  marginBottom: '2rem'
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

  const ChartConfiguration = ({ currentView, axisOptions, seriesOptions, onChange }) => {

    const handleSeriesCheck = (evt, { value, checked }) => {
      const nextSeries = currentView.series || [];

      if(checked) {
        nextSeries.push(value);
      } else {
        const index = nextSeries.indexOf(value);
        if (index > -1) {
          nextSeries.splice(index, 1);
        }
      }

      onChange(evt, { name: 'series', value: nextSeries });
    };

    return <>

      <Grid.Row style={style}>
        <Grid.Column>
          <Header as='h3'>{axisLabels(currentView.view_type).header}</Header>
          <Form.Select options={axisOptions}
            label={axisLabels(currentView.view_type).label}
            value={currentView.xaxis}
            name='xaxis'
            placeholder={axisLabels(currentView.view_type).placeholder}
            onChange={onChange} />
          {currentView.view_type !== 'piechart' &&
            <React.Fragment>
              <Form.Input label="Labels column"
                value={currentView.xaxis_label || ''}
                name='xaxis_label'
                onChange={onChange} />

              {currentView.view_type !== 'map' &&
                <Form.Input label="Vertical Axis Label (optional)"
                  value={currentView.yaxis_label || ''}
                  name='yaxis_label'
                  onChange={onChange} />}
            </React.Fragment>}
          {currentView.view_type === 'scatterchart' &&
            <Form.Select options={axisOptions}
              label="Vertical Axis"
              value={currentView.yaxis}
              name='yaxis'
              placeholder='Select a column for the Y-Axis'
              onChange={onChange} />}

        </Grid.Column>
      </Grid.Row>

      {(currentView.view_type !== 'scatterchart' && currentView.view_type !== 'map') &&
        <Grid.Row style={style}>
          <Grid.Column>
            <Header as='h3'>Data Series</Header>
            <Table>
              <Table.Body>
                {seriesOptions.map(({ text, value }) => <Table.Row key={`series-${value}`}>
                  <Table.Cell>
                    <Checkbox label={text}
                      value={value}
                      checked={currentView.series.includes(value)}
                      onChange={handleSeriesCheck} />
                  </Table.Cell>
                </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>}
    </>;

  };

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ChartConfiguration.propTypes = {
  currentView: PropTypes.object.isRequired,
  axisOptions: PropTypes.array.isRequired,
  seriesOptions: PropTypes.array.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ChartConfiguration;
