import React, { Component } from 'react';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function(WrappedComponent) {
  return class AnonymousComponent extends Component {
    render() {
      const conditions = localStorage.getItem('overridingConditions');

      return (
        <WrappedComponent { ...this.props }
                          conditions={ conditions } />
      );
    }
  };
}
