import React from 'react';
import { Grid, Message } from 'semantic-ui-react';

class NoAccess extends React.Component {
  render() {
    return (
      <Grid verticalAlign='middle' centered columns='2' style={{height : '100%'}}>
        <Grid.Row>
          <Grid.Column>
            <Message
              warning
              icon='warning'
              header="Whoops!"
              content="Looks like you've logged in on the wrong subdomain."
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default NoAccess;