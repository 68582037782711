import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';

import 'style/components/DataList.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function displayable(value) {
  if(value === true) {
    return 'True';
  } else if(value === false) {
    return 'False';
  } else if(value instanceof Array) {
    return value.map((v) => <li key={v}>{v}</li>);
  } else {
    return value;
  }
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DataList = ({ items }) => {
  return (
    <dl className='data-list'>
      {
        items.map(({ label, value }) => (
          <Fragment key={ label }>
            <dt>{ label }</dt>
            <dd>{ displayable(value) }</dd>
          </Fragment>
        ))
      }
    </dl>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DataList.defaultProps = {

};

DataList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label:  PropTypes.string,
    value:  PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.node
    ])
  })).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DataList;
