import applyCalculation               from 'utils/calculations/applyCalculation';
import calculationAsAnswer            from 'utils/calculations/calculationAsAnswer';
import { formatRawCalculationResult } from 'utils/calculations/formatCalculationResult';
import FormLogicEvaluator             from './Evaluator';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function calculateDisplayState(logic=[], fields, answers, calculations=[]) {
  const emptyAnswers  = fields.reduce((result, { id }) => {
                          result[id] = { field_id: id, value: undefined };
                          return result;
                        }, {});

  // This may seem like a strange chain of transformations.  We have to convert
  // the field_id's to strings to ensure they match on type, when evaluated
  // against the form logic conditions.  In the conditional evaluator, id's are
  // always treated as strings.
  const allAnswers    = Object
                        .entries(
                          Object.assign({}, emptyAnswers, answers)
                        )
                        .map(([key, { field_id, value }]) => (
                          [key, { field_id: `${field_id}`, value }])
                        )
                        .reduce((result, [key, answer]) => (
                          (result[key] = answer) && result
                        ), {});

  const calculationAnswers  = calculations
                              .map(calculation => (
                                [
                                  calculation,
                                  formatRawCalculationResult(
                                    calculation,
                                    applyCalculation(calculation, allAnswers, calculations)
                                  )
                                ]
                              ))
                              .map(([calculation, result]) => (
                                calculationAsAnswer(calculation, result)
                              ));

  const logicEvaluator  = new FormLogicEvaluator(logic);

  return logicEvaluator.evaluate({
    answers: [
      ...Object.values(allAnswers),
      ...calculationAnswers
    ]
  });
}
