import moment         from 'moment';
import { dateType }   from 'constants/Calculations';
import formattedDate  from 'utils/formattedDate';

function formatRawCalculationResult({ display_unit, calculation_type }, result) {
  // If the calculation has a type of 'date'...
  if(calculation_type === dateType) {

    // ...and a display_unit is specified
    if(display_unit !== null && display_unit !== undefined) {
      return result;
    } else {
      return moment(result).format();
    }
  }

  return result;
}

function formatCalculationResult({ display_unit, calculation_type }, result) {
  if (result === null || result === '') {
    return null;
  }

  // If the calculation has a type of 'date'...
  if(calculation_type === dateType) {

    // ...and a display_unit is specified
    if(display_unit !== null && display_unit !== undefined) {

      if(Number.isInteger(result)) {
        return `${ result } ${ display_unit }`;
      } else {
        return `about ${ result } ${ display_unit }`;
      }
    } else {
      return formattedDate(result, 'date');
    }
  } else if(calculation_type === 'time') {

    if(display_unit !== null && display_unit !== undefined) {
      // hours is stored as a float and && minutes is stored as an integer
      // they should be displayed as is
      if (display_unit === 'hours' || display_unit === 'minutes' || parseFloat(result) !== result ) {
        return result;

      // other formats need to be converted from float (hours)
      } else {
        if (display_unit === 'time') {
          // transform number of hours (float) into hh:mm format
          return moment.utc(result * 3600 * 1000).format('HH:mm');
        } else if (display_unit === 'time_decimal') {
          // transform number of hours (float) into hh:mm format
          return moment.utc(result * 3600 * 1000).format('H.mm');
        }
      }
    }


  }

  return result;
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default formatCalculationResult;
export { formatRawCalculationResult };
