import React, { Component }            from 'react';
import PropTypes                       from 'prop-types';
import { Modal, Button, Header,
         Form, Dropdown, Table }       from 'semantic-ui-react';
import { aggregationOptions }          from 'constants/reports/FunctionTypes';
import { isEmpty }                     from 'lodash';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const DEFAULT_AGG = 'count';

function getfieldOptions(fields) {
  return fields.map( (field, index) => (
      {
        value: index,
        text:  field
      }
    )
  );
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class SummaryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changes: props.settings
    };

    this.handleAdd    = this.handleAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { settings: prevSettings } = prevProps;
    const { settings: currSettings } = this.props;

    // Given the following conditions, setState should only be set once settings
    // are initialized.  If it is called more than that, then we have a problem.
    if(isEmpty(prevSettings) && !isEmpty(currSettings)) {
      this.setState({ changes: currSettings });
    }
  }

  render() {
    const { changes }     = this.state;
    const { opened,
            fields,
            onClose }     = this.props;

    const fieldOptions = getfieldOptions(fields);

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon={ 'cog' } content={ 'Edit Summary Settings' } />
        <Modal.Content>
          <Form >

          <Dropdown text='Choose a field to apply an Aggregate function'
                  className='icon'
                  icon='add'
                  search
                  fluid
                  labeled
                  button
                  basic
                  value={ '' }
                  selectOnBlur={ false }
                  options={ fieldOptions }
                  onChange={ this.handleAdd } />

            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={ 2 }>Function</Table.HeaderCell>
                  <Table.HeaderCell width={ 9 } >Field Name</Table.HeaderCell>
                  <Table.HeaderCell width={ 1 }></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  changes.map( (fieldAggs, fieldIndex) =>
                    fieldAggs?.map( (agg, aggIndex) =>
                      <Table.Row key={`setting-${fieldIndex}-${aggIndex}`}>
                        <Table.Cell>
                          <Form.Select
                                    name={ aggIndex }
                                    value={ agg }
                                    fieldIndex= { fieldIndex }
                                    options={ aggregationOptions }
                                    onChange={ this.handleChange } />
                        </Table.Cell>
                        <Table.Cell>
                          { fields[fieldIndex] }
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                            <Form.Button basic
                                        color='red'
                                        icon='remove'
                                        size='mini'
                                        type='button'
                                        tabIndex='-1'
                                        aggIndex={ aggIndex }
                                        fieldIndex= { fieldIndex }
                                        onClick={ this.handleRemove } />
                        </Table.Cell>
                      </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>

          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ onClose } negative basic>Cancel</Button>
          <Button onClick={ this.handleSubmit } positive basic>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleAdd(e, { value }) {
    const { changes } = this.state;
    const fieldIndex = parseInt(value);

    if (!changes[fieldIndex]) {
      changes[fieldIndex] = [DEFAULT_AGG];
    } else {
      const aggs = changes[fieldIndex];
      aggs.push(DEFAULT_AGG);
      changes[fieldIndex] = aggs;
    }

    this.setState({ changes });
  }

  handleChange(e, { name, value, fieldIndex }){

    const { changes } = this.state;
    const agg = parseInt(name);
    const field = parseInt(fieldIndex);

    changes[field][agg] = value;

    this.setState({ changes });
  }

 handleRemove(e, { aggIndex, fieldIndex }){
    const { changes } = this.state;
    const agg = parseInt(aggIndex);
    const field = parseInt(fieldIndex);

    if (changes[field].length === 1) {
      changes[field] = null;
    } else {
      const aggs = changes[field];
      aggs.splice(agg, 1);
      changes[field] = aggs;
    }

    this.setState({ changes });
  }

  handleSubmit() {
    const { settings }    = this.props;
    const { changes }     = this.state;

    changes.forEach( (value, index) => settings[index] = value );

    this.props.onSubmit(settings);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SummaryModal.propTypes = {
  opened:       PropTypes.bool,
  settings:     PropTypes.array.isRequired,
  fields:       PropTypes.array.isRequired,
  onSubmit:     PropTypes.func.isRequired,
  onClose:      PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SummaryModal;
