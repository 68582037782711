import React	   from 'react';
import showdown  from 'showdown';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------
function getInnerHtml(body) {
  const converter = new showdown.Converter();
  converter.setFlavor('github');
  let content = converter.makeHtml(body);

  // Markdown doesn't support target='_blank' by default. This forces all links to open in a separate tab.
  content = content.replace('<a','<a target=\'_blank\' rel=\'noopener noreferrer\'');

  return { __html: content };
}


// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------
export default class MarkdownRenderer extends React.Component{
  render(){
    const { content='', style={} } = this.props;
    const innerHtml                = getInnerHtml(content);

    return (
      <div style={ style } dangerouslySetInnerHTML={ innerHtml }/>
    );
  }
}
