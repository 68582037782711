import React      from 'react';
import PropTypes  from 'prop-types';

import { Table, Button, Icon }  from 'semantic-ui-react';

import DeleteButton   from './DeleteButton';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const draggingStyle = {
  userSelect: 'none',
  background: 'lightyellow'
};

const baseStyle = {
  transition: 'background 0.75s',
  left: 'auto !important',
  top: 'auto !important'
};

function getDraggableStyle(isDragging, draggableStyle) {
  const style = { ...draggableStyle, ...baseStyle };

  if(isDragging) {
    return { ...style, ...draggingStyle,  };
  }

  return style;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Record = ({ formFieldId, formName, fieldName, onDelete, draggableProvided, draggableSnapshot }) => {

  const { dragHandleProps, draggableProps } = draggableProvided;

  const style = getDraggableStyle(
    draggableSnapshot.isDragging,
    draggableProps.style
  );

  return (
    <Table.Row  { ...draggableProps } style={ style } >
      <Table.Cell>{ formName }</Table.Cell>
      <Table.Cell>{ fieldName }</Table.Cell>
      <Table.Cell textAlign='right'>
        <Icon name='bars'
                size='large'
                { ...dragHandleProps }
                tabIndex='-1' />

        <Button.Group size='mini' { ...dragHandleProps }>
          <DeleteButton formFieldId={ formFieldId } onDelete={ onDelete } />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Record.propTypes = {
  formFieldId:PropTypes.number.isRequired,
  formName:   PropTypes.string.isRequired,
  fieldName:  PropTypes.string.isRequired,
  onDelete:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Record;
