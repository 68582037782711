import { intervalParameterType } from 'constants/Calculations';

// Sets the *name*'d property on the provided *variable* to the supplied *value*
// and returns the resulting variable structure.
export function fieldVariableSetter(variable, name, value) {
  return { ...variable, [name]: value };
}

// Sets the *name*'d property on the provided *variable*'s value, to the supplied
// *value* and returns the resulting variable structure.
export function intervalParameterVariableSetter(variable, name, value) {
  return {
    ...variable,
    parameter_type: intervalParameterType,
    value: {
      ...variable.value,
      [name]: value
    }
  };
}
