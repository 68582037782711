import React  from 'react';

import MasterDetail             from 'components/MasterDetail';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import { pricing_tiers }        from 'resources/adminResources';
import connectResource          from 'utils/connectResource';
import { getPageAllById }       from 'utils/connectors';
import { sortByAttributes }     from 'utils/sortComparators';
import { textSubstringFilter }  from 'utils/recordFilters';


import DetailView from './DetailView';
import FormView   from './FormView';

class App extends React.Component {
  render() {
    return (
      <MasterDetail detailComponent={DetailView}
        formComponent={FormView}
        records={this.props.data.pricing_tiers||[]}
        onDelete={id => this.props.destroy(null, {id})}
        onUpdate={record => this.props.update(record, {id: record.id})}
        onCreate={record => this.props.create(record)}
        listSort={ sortByAttributes('name') }
        filterRecord={ textSubstringFilter('name') }
        {...this.props}/>
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ params, query }) => [params, query],
  connectors : {
    pricing_tiers : getPageAllById(pricing_tiers)
  },
  mapDispatchToProps : {...pricing_tiers.actions}
});

export default withSearch(withPagination(connectedComponent));
