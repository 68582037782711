import React      from 'react';
import PropTypes  from 'prop-types';
import { Table }  from 'semantic-ui-react';


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Body = ({ records, cellRenderer, controlCellRenderer, columnCloaker }) => {

  return (
    <Table.Body>
      {
        records.map((record, rowIndex) => (
          <Table.Row key={ rowIndex }>
            {
              controlCellRenderer &&
              <Table.Cell>
                { controlCellRenderer(record, rowIndex) }
              </Table.Cell>
            }

            {
              record
              .map((value, index) => ([ value, index ])) // preserve cell index
              .filter(([, cellIndex])  => (
                !(columnCloaker && columnCloaker(cellIndex))
              ))
              .map(([value, cellIndex]) => (
                <Table.Cell key={ cellIndex } className={`${cellIndex}_${rowIndex}`}>
                  { cellRenderer(record, value, cellIndex, rowIndex) }
                </Table.Cell>
              ))
            }
          </Table.Row>
        ))
      }
    </Table.Body>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Body.defaultProps = {
  cellRenderer: (record, value) => value && value.toString()
};

Body.propTypes = {
  records:              PropTypes.arrayOf(PropTypes.array).isRequired,
  cellRenderer:         PropTypes.func,
  controlCellRenderer:  PropTypes.func,
  columnCloaker:        PropTypes.func
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Body;
