// eslint-disable-next-line no-useless-escape
const PARSE_EXPR = /^(?:(https?):\/\/)?(?:([a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9]))\.)?([a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9]))\.([a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9]))(?::(\d+))?$/i;

export default function parseHost(host) {
  if(host === null) {
    return {};
  }

  const [
    hostName,
    protocol,
    subdomain,
    domain,
    tld,
    port=80] = PARSE_EXPR.exec(host);

  return {
    host: hostName,
    protocol,
    subdomain,
    domain,
    tld,
    port
  };
}
