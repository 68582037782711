import React, { Component } from 'react';

import Summary              from './Summary';
import Conditions           from './Conditions';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ReportSideCard extends Component {
  render() {

    return (
      <>
        <Summary { ...this.props } />

        <Conditions { ...this.props } />
      </>
    );
  }
}

export default ReportSideCard;
