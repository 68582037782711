import React             from 'react';
import PropTypes         from 'prop-types';
import { Form, Select }  from 'semantic-ui-react';
import DateSelect        from 'components/DateSelect';

// -----------------------------------------------------
// Internal Functions
// -----------------------------------------------------

const intervalOptions = [
  { text: 'Today', value: 'today' },
  { text: 'Specific Date', value: 'specific' },
  { text: 'Relative to Today', value: 'diff' }
];

const valueNames = {
  'specific' : 'Specific Date',
  'diff'     : 'Number of Days'
};

const NumberOfDaysInput = ({interval, settings, onChange}) => (
  <div>
    <Form.Input name='value'
                type='number'
                interval={ interval }
                value={ settings[interval] && settings[interval].value || '' }
                onChange={ onChange } />

    <div>
      <small>Set the number of days to add (+) or subtract (-) to the current date.</small>
    </div>
  </div>
);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DateSettings = ({ settings, onChange }) => {

  const handleDateSettingsChange = (evt, { interval, name, value }) => {
    const nextSettingsChanges = { ...settings };

    if(name === 'type' && value === '') {
      delete nextSettingsChanges[interval];
    } else {

      if (name === 'type') {
        nextSettingsChanges[interval] = {};
      }
      nextSettingsChanges[interval][name] = value;
    }

    onChange(nextSettingsChanges);
  };

  return (
    <>
      <h4>Date Intervals</h4>
      <Form.Group widths='equal'>
        <Form.Field width={ 8 }>
          <label>Start Date</label>
          <Select options={ intervalOptions }
                  interval='minDate'
                  name='type'
                  value={ settings.minDate && settings.minDate.type || null }
                  selectOnBlur={ false }
                  clearable
                  onChange={ handleDateSettingsChange }/>
        </Form.Field>

        <Form.Field width={ 8 }>
          { (settings.minDate && settings.minDate.type !== 'today') &&
            <>
            <label>{ valueNames[settings.minDate.type] }</label>
            { settings.minDate.type === 'specific' &&
              <DateSelect value={ settings.minDate && settings.minDate.value || '' }
                                  onChange={ (value) => handleDateSettingsChange(null, {interval: 'minDate', name:'value', value}) } />
            }
            { settings.minDate.type === 'diff' &&
              <NumberOfDaysInput interval='minDate' settings={ settings } onChange={ handleDateSettingsChange } />
            }
            </>
          }
        </Form.Field>
      </Form.Group>

      <Form.Group widths='equal'>
        <Form.Field width={ 8 }>
          <label>End Date</label>
          <Select options={ intervalOptions }
                  interval='maxDate'
                  name='type'
                  value={ settings.maxDate && settings.maxDate.type || null }
                  selectOnBlur={ false }
                  clearable
                  onChange={ handleDateSettingsChange }/>
        </Form.Field>

        <Form.Field width={ 8 }>
          { (settings.maxDate && settings.maxDate.type !== 'today') &&
            <>
            <label>{ valueNames[settings.maxDate.type] }</label>
            { settings.maxDate.type === 'specific' &&
              <DateSelect value={ settings.maxDate && settings.maxDate.value || '' }
                                  onChange={ (value) => handleDateSettingsChange(null, {interval: 'maxDate', name:'value', value}) } />
            }
            { settings.maxDate.type === 'diff' &&
              <NumberOfDaysInput interval='maxDate' settings={ settings } onChange={ handleDateSettingsChange } />
            }
            </>
          }
        </Form.Field>
      </Form.Group>
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DateSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DateSettings;
