import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table, Checkbox }  from 'semantic-ui-react';

import findActionIndex from 'utils/formLogic/findActionIndex';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function hasAction(actions, type, target) {
  return findActionIndex(actions, type, target) > -1;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ActionCell extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const { children } = this.props;
    return (
      <Table.Cell textAlign='center'>
        <Checkbox checked={ this.isChecked() }
                  onChange={ this.handleChange } />

        { children }
      </Table.Cell>
    );
  }

  isChecked() {
    const { type, target, actions } = this.props;
    return hasAction(actions, type, target);
  }

  handleChange(_, { checked }) {
    const { type, target, actions, onChange } = this.props;

    const nextActions = [...actions];

    if(checked) {
      nextActions.push({ type, target });
    } else {
      const actionIndex = findActionIndex(nextActions, type, target);
      if(actionIndex > -1) {
        nextActions.splice(actionIndex, 1);
      }
    }

    onChange(nextActions, checked);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ActionCell.propTypes = {
  type: PropTypes.string.isRequired,

  target: PropTypes.shape({
    type: PropTypes.string,
    id:   PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
  }).isRequired,

  actions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    target: PropTypes.shape({
      type: PropTypes.string,
      id:   PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
    })
  })).isRequired,

  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ActionCell;
