import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import ConditionEmpty       from './Empty';
import ConditionItem        from './Item';
import FieldShaper          from 'utils/conditions/shapers/FieldShaper';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

function getModalConditions(conds) {
  return  (conds === null || conds === undefined || !Object.keys(conds).length)
            ? { and: [] }
            : conds;
}

function getModalFields(fields) {
  const svc = new FieldShaper(fields);
  return svc.transform();
}

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class ConditionList extends Component {

  // construction
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  // event handling
  handleDelete() {
    const { onChange } = this.props;
    onChange({});
  }
  handleChange(conditions) {
    const { onChange } = this.props;
    onChange(conditions);
  }

  // rendering
  render() {
    const { conditions, fields }  = this.props;
    const mConditions             = getModalConditions(conditions);
    const mFields                 = getModalFields(fields);
    const { and=[], or=[] }       = mConditions;

    if (and.length || or.length) {
      return (
        <ConditionItem conditions={ mConditions }
                       fields={ mFields}
                       onChange={ this.handleChange }
                       onDelete={ this.handleDelete } />
      );
    }
    else {
      return (
        <ConditionEmpty conditions={ { and: [] } }
                        fields={ mFields }
                        onChange={ this.handleChange } />
      );
    }
  }
}

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

ConditionList.propTypes = {
  conditions: PropTypes.object,
  fields:     PropTypes.array.isRequired,
  onChange:   PropTypes.func.isRequired
};


// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ConditionList;
