import React, { useState }             from 'react';
import PropTypes                       from 'prop-types';
import {  Modal, 
          Header,
          Button,
          Form}  from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const EditTitleModal = ({ title,
                    opened,
                    householdTitle,
                    onUpdate,
                    onClose }) => {

  const [hTitle, setHouseholdTitle] = useState(householdTitle);

  const handleCancel = (evt) => {
    evt.preventDefault();
    onClose();
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    setHouseholdTitle(value);
  };

  const handleSumit = (evt) => {
    evt.preventDefault();
    onUpdate(hTitle);
    onClose();
  };

  return (
    <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
      <Header icon='user' content={ title } />
      <Modal.Content>
        <Form>
          <Form.Input label='Household Title'
                      name='title'
                      value={ hTitle }
                      onChange={ handleChange }
                      />

        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={ handleCancel } negative basic>Cancel</Button>
        <Button type='submit'
                onClick={ handleSumit }
                positive
                basic>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditTitleModal.propTypes = {
  title:                  PropTypes.string.isRequired,
  opened:                 PropTypes.bool.isRequired,
  onClose:                PropTypes.func.isRequired,
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditTitleModal;
