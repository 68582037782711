import React, { Component } from 'react';
import { Table }            from 'semantic-ui-react';
import { refPrefix } from 'utils/reportDefinitions';

import Header     from './Header';
import Body       from './Body';

// -----------------------------------------------------
// Helpers / Constants
// -----------------------------------------------------

const MIN = 100;
const DEFAULT_SIZE = `minmax(${MIN}px,1fr)`;

function initColumnsSize(tableId, headerLabels, showControls, controlsMinWidth){
  if (!headerLabels.length) { return []; }
  const storedColumnsSize = localStorage.getItem(tableId)
                            ? localStorage.getItem(tableId).split(' ')
                            : [];

  return currentColumnsSize(storedColumnsSize, headerLabels, showControls, controlsMinWidth);
}

function defaultColumnsSize(headerLabels, showControls, controlsMinWidth){
  const columnsCount = countColumnsFromHeaderLabels(headerLabels, showControls);

  let outputGridStyle = Array(columnsCount).fill().map(() => DEFAULT_SIZE);
  if (controlsMinWidth) {
    outputGridStyle[0] = `minmax(${controlsMinWidth}px,1fr)`;
  }

  return outputGridStyle;
}

function currentColumnsSize(prevColumnsSize, headerLabels, showControls, controlsMinWidth){
  if (!headerLabels.length) { return []; }

  const columnsCount = countColumnsFromHeaderLabels(headerLabels, showControls);

  return prevColumnsSize.length === columnsCount && !controlsMinWidth
         ? prevColumnsSize
         : defaultColumnsSize(headerLabels, showControls, controlsMinWidth);
}

function countColumnsFromHeaderLabels(headerLabels, showControls){
  return headerLabels
         .filter( label => !!label && !label.startsWith(refPrefix))
         .length + (showControls ? 1 : 0);
}

function calcHeaderIndex(headerIndex, headerLabels, showControls){
  const refsOffset = headerLabels
                     .filter( label => !label || label.startsWith(refPrefix))
                     .length;
  const controlsOffset = showControls ? 1 : 0;

  return (headerIndex - refsOffset) + controlsOffset;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ResizableTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnsSize: initColumnsSize(props.tableId, props.headerLabels, props.showControls, props.controlsMinWidth),
      columnBeingResizedIndex: null
    };

    this.columnBeingResized = null;

    this.initResize  = this.initResize.bind(this);
    this.onMouseMove  = this.onMouseMove.bind(this);
    this.onMouseUp  = this.onMouseUp.bind(this);
  }

  render() {
    const { headerLabels,
            sortQueue,
            onSort,
            showControls,
            controlsMinWidth,
            records,
            cellRenderer,
            controlCellRenderer,
            columnCloaker }   = this.props;
    const { columnsSize }         = this.state;

    const gridTemplateColumns = currentColumnsSize(columnsSize, headerLabels, showControls, controlsMinWidth)
                                .join(' ');

    return (
      <Table className={'sortable-table'} style={{ gridTemplateColumns: gridTemplateColumns}}>
        <Header labels={ headerLabels }
                  sortQueue={ sortQueue }
                  onSort={ onSort }
                  showControls={ showControls }
                  columnCloaker={ columnCloaker }
                  onResize={ this.initResize } />

          <Body   records={ records }
                  cellRenderer={ cellRenderer }
                  controlCellRenderer={ controlCellRenderer }
                  columnCloaker={ columnCloaker } />
      </Table>
    );
  }

  onMouseMove = (e) => requestAnimationFrame(() => {
    const { tableId, headerLabels, showControls, controlsMinWidth } = this.props;
    const { columnsSize, columnBeingResizedIndex } = this.state;

    if(this.columnBeingResized ){
      const horizontalScrollOffset = document.documentElement.scrollLeft;
      const width = (horizontalScrollOffset + e.clientX) - this.columnBeingResized.getBoundingClientRect().x + 10;

      const newSizes = currentColumnsSize(columnsSize, headerLabels, showControls, controlsMinWidth);
      const columnIndex = calcHeaderIndex(columnBeingResizedIndex, headerLabels, showControls);
      newSizes[columnIndex] = Math.max(MIN, width) + 'px'; // force minimum


      this.setState({ columnsSize: newSizes });
      localStorage.setItem(tableId, newSizes.join(' '));
    }
  });

  onMouseUp = () => {
    this.setState({ columnBeingResizedIndex: null });
    this.columnBeingResized = null;

    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  };

  initResize = (e, columnBeingResizedIndex) => {
    this.setState({ columnBeingResizedIndex: columnBeingResizedIndex });
    this.columnBeingResized = e.currentTarget.parentNode;

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  };
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

export default ResizableTable;
