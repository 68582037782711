import AuthenticatedResource  from 'resources/AuthenticatedResource';

export default class MergeClientsResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/merge_clients';

  id   = undefined;

  pk() {
    return this.id;
  }
}
