import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const CLIENT_FORM_TYPE = 'client';

const NewFormSelector = ({forms, sequences, onSelectNew}) => {
  const sequenceOptions = [];

  if (sequences){
    sequenceOptions.push({ text: 'SEQUENCES', disabled: true });
    sequences.forEach( ({id, name}) => {
      sequenceOptions.push({ text: name, value: `sequence-${id}` });
    });
    sequenceOptions.push({ text: 'FORMS', disabled: true });
  }

  const formOptions = Object
                      .values(forms)
                      .filter(({ form_type }) => (form_type === CLIENT_FORM_TYPE))
                      .sort(({ name: a }, { name: b }) => a > b ? 1 : -1)
                      .map(({ name, active, id }) => ({ text: name, value: `form-${id}`, description: !active ? 'INACTIVE' : null }));

  const options = [ ...sequenceOptions, ...formOptions ];

  return (
    <Dropdown text='Add a Form'
              size='mini'
              search
              fluid
              labeled
              button
              basic
              className='icon'
              icon='add'
              options={ options }
              selectOnBlur={ false }
              onChange={ (evt, { value }) => onSelectNew(value) } />
  );
};

export default NewFormSelector;
