import React             from 'react';
import { List }          from 'semantic-ui-react';

import DownloadResponses from './Imports/DownloadResponses';
import UploadModal       from './Imports/UploadModal';
import Log               from './Imports/Log';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Imports = (props) => {
  const { recordId, recordType, state } = props;

  return (
        <React.Fragment>
        <List.Item>
          <List.Icon name='cloud download' />
          <List.Content>
            <DownloadResponses recordType={ recordType }
                               recordId={ recordId }
                               state={ state } />
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name='upload' />
          <List.Content>
            <UploadModal recordId={ recordId }
                    uploadType={ 'import' } />
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name='trash alternate' />
          <List.Content>
            <UploadModal recordId={ recordId }
                    uploadType={ 'delete' } />
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name='archive' />
          <List.Content>
            <Log  recordType={ recordType }
                  recordId={ recordId } />
          </List.Content>
        </List.Item>
        </React.Fragment>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Imports;
