import React, { useState }                         from 'react';
import PropTypes                                    from 'prop-types';
import { Modal, Button, Header, Form, Image }  from 'semantic-ui-react';
import ClientResource from 'resources/organization/ClientResource';
import useOrganizationFetcher from 'hooks/useOrganizationFetcher';


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ProfilePhotoModal = ({ opened, id, title, imageUrl, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const update        = useOrganizationFetcher(ClientResource.updateShape());

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const formData = new FormData();
    formData.append('photo', selectedFile);

    const response  = await update({ id }, formData);

    if(response) {
      onClose(response.photo_url);
    }
  };

  const newImageUrl = selectedFile ? URL.createObjectURL(selectedFile) : imageUrl;

  return (
    <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
      <Header icon='cloud upload' content={ title } />
      <Modal.Content >
        <Form id={ id }>
          <Image src={ newImageUrl } size='medium' centered />
          <Form.Input label='Profile Photo'
                      name='photo'
                      type='file' 
                      onChange={ handleFileUpload }
                      />
        </Form>

      </Modal.Content>

      <Modal.Actions>
        <Button onClick={ () => onClose() } negative basic>Cancel</Button>
        <Button type='submit' onClick={ handleSubmit } positive basic>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ProfilePhotoModal.defaultProps = {
  opened:       false
};

ProfilePhotoModal.propTypes = {
  id:           PropTypes.number.isRequired,
  opened:       PropTypes.bool.isRequired,
  imageUrl:     PropTypes.string.isRequired,
  onClose:      PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ProfilePhotoModal;
