import React                      from 'react';
import { Redirect }              from 'react-router-dom';
import { Segment, Header, List, Divider }  from 'semantic-ui-react';
import { Link }                                           from 'react-router-dom';

class DetailView extends React.Component {
  render() {
    const { loading, data, api_errors } = this.props;

    const { selected }       = data;
    const record             = selected || {};
    const { name, forms=[] } = record;

    return (
      <div>
        { api_errors && <Redirect to='./'/> }

        <Segment loading={ loading }>
        <Header as='h3'>{ name }</Header>

        <div style={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
          <Divider horizontal> Forms ({ forms.length }) </Divider>

          <List divided relaxed='very'>
            {
              forms.map(({ id, name }) => {
                return (
                  <List.Item key={id}>
                    <List.Icon name='file outline' />
                    <List.Content>
                      <List.Header>
                        <Link to={ `/organization/forms/${id}` }>{ name }</Link>
                      </List.Header>
                    </List.Content>
                  </List.Item>
                );
              })
            }
          </List>
        </div>

        </Segment>
      </div>
    );  
  }
}

export default DetailView;
