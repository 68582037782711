import React      from 'react';
import PropTypes  from 'prop-types';
import get        from 'lodash/get';

import DefinitionList from 'components/forms/Notifications/Definitions';

import { notification_definitions,
         notification_metadata,
         users }        from 'resources/organizationResources';

import { getAll,
         getByFind,
         getSingleton } from 'utils/connectors';

import connectResource  from 'utils/connectResource';
import { toTitleCase }  from 'utils/strings';

// -----------------------------------------------------
// Helper Functions
// -----------------------------------------------------

function scopedCreate(create, scope) {
  return async function(params, callback) {
    const resp = await create(params, scope);

    if(!resp.errors) {
      callback();
    }

    return resp;
  };
}

function scopedUpdate(update, scope) {
  return async function(params) {
    const { id } = params;
    return await update(params, { id, ...scope });
  };
}

function scopedDestroy(destroy, scope) {
  return async function(id) {
    return await destroy(null, { id, ...scope });
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Definitions = ({ form,
                       data,
                       resourceId,
                       resourceName,
                       create,
                       update,
                       destroy }) => {

  const definitions     = get(data, 'definitions', {});
  const events          = get(data, 'metadata.events', {});
  const users           = get(data, 'users', {});
  const formFields      = get(form, 'form_fields', []);
  const recipientFields = formFields
                          .filter(({ field_type, internal_field_type }) => field_type === 'email' || internal_field_type === 'email');

  const resourceScope = {
    owner_id:   resourceId,
    owner_type: resourceName
  };

  const onCreate = scopedCreate(create, resourceScope);
  const onUpdate = scopedUpdate(update, resourceScope);
  const onDelete = scopedDestroy(destroy, resourceScope);

  return (
    <DefinitionList definitions={ definitions }
                    eventMetadata={ events }
                    recipientFields={ recipientFields }
                    formFields={ formFields }
                    users={ Object.values({ ...users }) }
                    onCreate={ onCreate }
                    onUpdate={ onUpdate }
                    onDelete={ onDelete } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Definitions.propTypes = {
  form:         PropTypes.object.isRequired,
  resourceId:   PropTypes.number.isRequired,
  resourceName: PropTypes.string.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(Definitions, {
  key: ({ resourceId }) => resourceId,

  connectors: {
    definitions: getByFind(
      notification_definitions,
      ({ resourceId, resourceName }) => ({
        owner_id:   resourceId,
        owner_type: resourceName
      }),
      args => ({
        ...args,
        owner_type: toTitleCase(args.owner_type)
                    .substr(0, args.owner_type.length - 1)
      })
    ),

    metadata: getSingleton(
      notification_metadata,
      ({ resourceId, resourceName }) => ({
        owner_id:   resourceId,
        owner_type: resourceName
      }),
    ),

    users: getAll(users)
  },

  mapDispatchToProps: { ...notification_definitions.actions }
});
