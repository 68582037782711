import AuthenticatedResource  from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class UserAccountResource extends AuthenticatedResource {
  static urlRoot = '/user_account'

  pk() {
    return 'userAccount';
  }
}
