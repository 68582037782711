import React            from 'react';
import { Link }         from 'react-router-dom';
import { Loader }       from 'semantic-ui-react';

import SortableTable    from 'components/SortableTable';
import AnswerRenderer   from 'components/fields/AnswerRenderer';

import InstanceControls from '../FormsList/InstanceControls';
import { clientForms }  from 'resources/organizationResources';
import { store }        from 'store/configuredStore';

import FormResource               from 'resources/organization/FormResource';
import useOrganizationResource    from 'hooks/useOrganizationResource';

// -----------------------------------------------------
// Helper Functions / Constants
// -----------------------------------------------------

function recordedAtField(recorded_at=null) {
  return {
    name:       'Recorded At',
    field_type: 'datetime' ,
    value:      recorded_at
  };
}

const onDelete = (clientFormId, clientId) => {
  const { destroy } = clientForms.actions;
  const request     = destroy(null, { id: clientFormId, client_id: clientId });
  store.dispatch(request);
};

function createControlCellRenderer(clientId, formInstances) {
  const ControlCellRenderer = (answers, rowIndex) => {
    const { id } = formInstances[rowIndex] || {};
    return (
      <div className='pull-left'>
        <InstanceControls clientId={ clientId }
                          clientFormId={ id }
                          onDelete={ (id) => onDelete(id, clientId) } />
      </div>
    );
  };

  return ControlCellRenderer;
}

function cellRenderer(record, value) {
  const { client_id, id } = record;

  return (
    <Link to={ `/organization/clients/${client_id}/client_forms/${id}` }>
      <AnswerRenderer answer={ value } />
    </Link>
  );
}

function isQuickView({field_type, is_quick_view}) {
  return field_type !== 'presentation' && is_quick_view;
}

function prepareAnswers(answers, formFields) {
  let missingAnswers = 0;
  return formFields.map((ff, index) => {
          const tentativeAnswer = answers[index - missingAnswers];

          let answer = {};
          if (tentativeAnswer && tentativeAnswer.field_id === ff.id) {
            answer = tentativeAnswer;
          } else {
            missingAnswers++;
          }

          return isQuickView(ff) ? answer : null;
        }).filter( a => a );
}

function prepareRecords(formInstances, formFieldIds) {
  return  formInstances
          .sort(({ created_at: a }, { created_at: b }) => {
            return Date.parse(b) - Date.parse(a);
          })
          .map(({ id, client_id, completed_at, answers }) => {
            const sortedAnswers = prepareAnswers(answers, formFieldIds);
            const record      = [recordedAtField(completed_at),  ...sortedAnswers];
            record.id         = id;
            record.client_id  = client_id;
            return record;
          });
}

function prepareHeaders(formFields) {
  const fieldsLabels = formFields
                       .filter( isQuickView )
                       .map( ({name}) => name );
  return [recordedAtField().name, ...fieldsLabels];
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FormGroupInstancesDisplay = ({ formInstances,
                                     clientId,
                                     formId,
                                     loading,
                                     onPageNext }) => {

  const form                = useOrganizationResource(
                                FormResource.detailShape(),
                                { id: formId }
                              );
  const formFields          = form ? form.form_fields : [];

  const headers             = prepareHeaders(formFields);
  const records             = prepareRecords(formInstances, formFields);
  const controlCellRenderer = createControlCellRenderer(clientId, formInstances);

  return (
    <div className='client-dashboard-form-group-instances'>
      {
        loading
        ? <Loader>Loading</Loader>
        : <SortableTable tableId={`client_form_${formId}`}
                        headerLabels={ headers }
                        records={ records }
                        cellRenderer={ cellRenderer }
                        controlCellRenderer={ controlCellRenderer }
                        sortAttribute='value'
                        scrollPaneStyleOverrides={{ maxHeight: '400px' }}
                        onPageNext={ onPageNext } />
      }
    </div>
  );
};

export default FormGroupInstancesDisplay;
