import React, { Component } from 'react';

// --------------------------------------------------------
// Conponent Definition
// --------------------------------------------------------

class PrintLink extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(evt) {
    evt.preventDefault();
    window.print();
  }

  render() {
    return <a href='#print' onClick={ this.handleClick }>Print</a>;
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default PrintLink;
