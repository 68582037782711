import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Button, Table }    from 'semantic-ui-react';

import DeleteButton         from './DeleteButton';
import EditButton           from './EditButton';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

function getSummary(conditions, fields) {
  const { and, or }   = conditions;
  const logicGroup    = and || or || [];
  const logicOpLabel  = (and && 'All') || (or && 'Any') || 'Unknown';

  const conditionLabels = logicGroup.map((group) => {
    const { conditions, scope } = group;

    if (conditions && scope) {
      const [cond]                  = conditions.and;
      const { field_name: fname,
              op,
              value }               = cond;

      const fieldId = (scope === 'self') ? fname : `${ scope }.${ fname }`;
      const field   = fields.find(({ value }) => (value === fieldId)) || {};
      const label   = field.label || fieldId;

      return `${label} ${op} ${value}`;
    } else {
      return getSummary(group, fields);
    }
  });

  return `${logicOpLabel}: (${conditionLabels.join('; ')})`;
}

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class ConditionItem extends Component {

  // construction
  constructor(props) {
    super(props);

    this.handleClick  = this.handleClick.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  handleClick() {
    this.setState({ opened: true });
  }
  handleClose() {
    this.setState({ opened: false });
  }
  handleSubmit(conditions) {
    const { onSubmit } = this.props;
    onSubmit(conditions);
  }

  // rendering
  render() {
    const { conditions, fields, onChange, onDelete } = this.props;
    const summary = getSummary(conditions, fields);

    return (
      <Table basic='very'>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{ summary }</Table.Cell>
            <Table.Cell textAlign='right'>
              <Button.Group size='mini'>
                <EditButton conditions={ conditions }
                            fields={ fields }
                            onChange={ onChange } />

                <DeleteButton onConfirm={ onDelete } />
              </Button.Group>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

ConditionItem.propTypes = {
  conditions: PropTypes.object,
  fields:     PropTypes.array.isRequired,
  onChange:   PropTypes.func.isRequired,
  onDelete:   PropTypes.func.isRequired
};


// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ConditionItem;
