import React, { Fragment }    from 'react';
import PropTypes              from 'prop-types';
import { Accordion, Header }  from 'semantic-ui-react';
import get                    from 'lodash/get';

import indexOnAttribute           from 'utils/indexOnAttribute';
import { formFieldObjectsMapping }  from 'utils/reportDefinitions';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FormFieldStats = ({ forms, fields, formCalculations, reportDefinition }) => {

  const indexedForms = indexOnAttribute(forms, 'id');

  const mapping =
    formFieldObjectsMapping(forms, fields, formCalculations, reportDefinition);

  return (
    <Fragment>
      <Header as='h4'>Selected Fields</Header>
      <Accordion exclusive={ false }>
        {
          mapping.map(([formId, formFields], i) => (
            <Fragment key={ formId }>
              <Accordion.Title index={ i } active={ true }>
                <strong>{ get(indexedForms, [formId, 'name']) }</strong>
              </Accordion.Title>

              <Accordion.Content active={ true }>
                <ul>
                  {
                    formFields.map((field, index) => (
                      <li key={ index }>{ field.name }</li>
                    ))
                  }
                </ul>
              </Accordion.Content>
            </Fragment>
          ))
        }
      </Accordion>
    </Fragment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormFieldStats.propTypes = {
  reportDefinition: PropTypes.shape({
    form_ids:       PropTypes.array,
    field_ids:      PropTypes.array,
    conditions:     PropTypes.object
  }).isRequired,
  forms:                    PropTypes.array.isRequired,
  fields:                   PropTypes.array.isRequired,
  formCalculations:         PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormFieldStats;
