import React      from 'react';
import PropTypes  from 'prop-types';
import { Form }   from 'semantic-ui-react';

import connectResource      from 'utils/connectResource';
import { getAll }           from 'utils/connectors';
import { sortByAttributes } from 'utils/sortComparators';
import { categories }       from 'resources/organizationResources';

function computeOptions(categories={}, selectedCategoryIds, value) {
  if(categories === null) {
    return [];
  }

  return  Object.values(categories)
                .filter(({ id }) => !selectedCategoryIds.includes(id) || id === value)
                .sort(sortByAttributes('path_name'))
                .map(({ id, path_name }) => ({ text: path_name, value: id }));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CategorySelector = ({ name, value, data, selectedCategoryIds, onChange }) => {

  const { categories }  = data;
  const options         = computeOptions(categories, selectedCategoryIds, value);

  return (
    <Form.Select  value={ value }
                  name={ name }
                  onChange={ onChange }
                  selectOnBlur={ false }
                  search
                  options={ options } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CategorySelector.defaultProps = {

};

CategorySelector.propTypes = {
  name:                 PropTypes.string.isRequired,
  value:                PropTypes.number,
  onChange:             PropTypes.func.isRequired,
  selectedCategoryIds: PropTypes.arrayOf(PropTypes.number)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(CategorySelector, {
  connectors: {
    categories: getAll(categories)
  }
});
