import React, { Component }         from 'react';
import connectResource              from 'utils/connectResource';
import { getByFind, getSingleton }  from 'utils/connectors';

import {
  public_form_settings,
  organizationAccount }   from 'resources/organizationResources';

import PublishSettings    from 'components/forms/PublishSettings';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class PublishSettingsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false
    };

    this.handleSave = this.handleSave.bind(this);
  }

  render() {
    const { data }    = this.props;
    const { saving }  = this.state;
    const { public_form_settings={}, organizationAccount={} } = data;

    return (
      <PublishSettings  records={ Object.values(public_form_settings) }
                        organization={ organizationAccount }
                        loading={ saving }
                        onSave={ this.handleSave } />
    );
  }

  async handleSave(record, done) {
    const { formId, dispatch }  = this.props;
    const { create, update }    = public_form_settings.actions;
    const { id }                = record;

    const scope   = { form_id: formId, id: id };
    const request = id ? update(record, scope) : create(record, scope);

    this.setState({ saving: true });

    await dispatch(request);

    this.setState({ saving: false });
    done();
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(PublishSettingsContainer, {
  key: (props) => props.formId,
  connectors: {
    public_form_settings: getByFind(public_form_settings, (props) => {
      return { form_id: props.formId };
    }),
    organizationAccount: getSingleton(organizationAccount)
  }
});
