import React                  from 'react';
import { Segment, Header, Table } from 'semantic-ui-react';

import Privileges                       from 'constants/Privileges';
import WithPermission                   from 'containers/WithPermission';
import CalculationRecord                from 'containers/organization/clients/Forms/DetailView/CalculationRecord';
import FormResource                     from 'resources/organization/FormResource';
import PublicFormResponseDetailResource from 'resources/organization/PublicFormResponseDetailResource';
import useOrganizationResource          from 'hooks/useOrganizationResource';

import EditButton                 from './EditButton';
import FieldRecord                from './FieldRecord';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Preview = ({ match: { params: { responseId } } }) => {

  const formResponse  = useOrganizationResource(
                          PublicFormResponseDetailResource.detailShape(),
                          { id: responseId }
                        );

  const { form_id, public_form_id, answers, calculation_results } = formResponse;

  const form  = useOrganizationResource(
                  FormResource.detailShape(),
                  { id: form_id }
                );

  const formFields  = form.form_fields;

  const readOnlyFields =
    formFields.filter(({ field_type }) => (field_type !== 'presentation'));

  return (
    <Segment>
      <Header as='h3'>{ form.name }</Header>
      <Table basic='very' style={{ marginTop: '2rem' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={ 7 }>Field</Table.HeaderCell>
            <Table.HeaderCell>Answer</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>
              <WithPermission resource='public_forms'
                              recordId={ responseId }
                              actions={ Privileges.write }>

                <EditButton   publicFormId={public_form_id }
                              responseId={ responseId } />
              </WithPermission>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            readOnlyFields.map((field) => {
              const { id }  = field;
              const answer  = answers[id] || {};

              return (
                <FieldRecord key={ id } field={ field } answer={ answer } />
              );
            })
          }

          {
            calculation_results.map((result) => (
              <CalculationRecord  key={ result.id } result={ result } />
            ))
          }
        </Table.Body>
      </Table>
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Preview.defaultProps = {

};

Preview.propTypes = {

};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Preview;
