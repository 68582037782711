import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import { Modal, Button, Header, Form }  from 'semantic-ui-react';

import ConditionDateSelect    from 'components/ConditionSelect/DateSelect';
import ConditionTimeSelect    from 'components/ConditionSelect/TimeSelect';
import ActionsEditor          from './ActionsEditor';
import ConditionalEditor, {
       ConditionGroup,
       Item,
       Registry,
       GroupProvider,
       ItemProvider,
       ScopedGroupProvider }  from '@coactionnet/conditional-editor';
import AnswerProvider         from 'utils/conditions/providers/AnswerProvider';
import uniqueId               from 'utils/uniqueId';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

function getAnswerAdapter() {
  const p = new AnswerProvider(Item, 'Form Answer Condition');
  return p.getAdapter();
}

function getGroupAdapter() {
  const p = new GroupProvider(ConditionGroup, 'Condition Group');
  return p.getAdapter();
}

function getItemAdapter() {
  const p = new ItemProvider(Item, 'Condition', false);
  return p.getAdapter();
}

function getScopedGroupAdapter() {
  const p = new ScopedGroupProvider(ConditionGroup, 'Scoped Condition Group', false);
  return p.getAdapter();
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------
const excludedDataTypes = ['signature'];
const excludedFieldTypes = ['hyperlink', 'presentation', 'search'];

function isConditionalField(fieldType) {
  return !excludedDataTypes.includes(fieldType)
          && !excludedFieldTypes.includes(fieldType);
}

class FormModal extends Component {
  constructor(props) {
    super(props);

    this.registry = new Registry();
    this.registry.add(getAnswerAdapter());
    this.registry.add(getGroupAdapter());
    this.registry.add(getItemAdapter());
    this.registry.add(getScopedGroupAdapter());

    const { name, condition, actions } = this.props;

    this.state  = { name, condition, actions };
    this.formId = uniqueId();

    this.handleSubmit           = this.handleSubmit.bind(this);
    this.handleNameChange       = this.handleNameChange.bind(this);
    this.handleConditionChange  = this.handleConditionChange.bind(this);
    this.handleActionsChange    = this.handleActionsChange.bind(this);
  }

  render() {
    const { opened, onClose, title, fields }  = this.props;
    const { name, condition, actions }        = this.state;

    // The ConditionalEditor does not know how to handle file, signature and presentation fields
    const conditionalFields = fields.filter(({ fieldType }) => (
      isConditionalField(fieldType)
    ));

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon='pencil' content={ title } />
        <Modal.Content>
          <Form onSubmit={ this.handleSubmit } id={ this.formId }>
            <Header as='h3'>Name</Header>
            <Form.Input value={ name }
                        onChange={ this.handleNameChange } />

            <Header as='h3'>Conditions</Header>
            <div className='conditions-editor'>
              <ConditionalEditor  conditions={ condition }
                                  fields={ conditionalFields }
                                  registry={ this.registry }
                                  valuesDateControl={ ConditionDateSelect }
                                  valuesTimeControl={ ConditionTimeSelect }
                                  onChange={ this.handleConditionChange } />
            </div>

            <Header as='h3'>Actions</Header>
            <div>
              <ActionsEditor  actions={ actions }
                              fields={ fields }
                              onChange={ this.handleActionsChange } />
            </div>
          </Form>

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ onClose } negative basic>Cancel</Button>
          <Button type='submit' form={ this.formId } positive basic>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const { index, onSubmit, onClose }  = this.props;
    const { name, condition, actions }  = this.state;

    onSubmit({ name, condition, actions }, index);
    onClose();
  }

  handleNameChange(evt, { value }) {
    this.setState({ name: value });
  }

  handleConditionChange(condition) {
    this.setState({ condition });
  }

  handleActionsChange(actions) {
    this.setState({ actions });
  }
}

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

FormModal.defaultProps = {
  opened:     false,
  name:       '',
  condition:  { and: [] },
  actions:    []
};

FormModal.propTypes = {
  opened:       PropTypes.bool,
  index:        PropTypes.number,
  condition:    PropTypes.object,
  actions:      PropTypes.array,
  title:        PropTypes.string.isRequired,
  onSubmit:     PropTypes.func.isRequired,
  onClose:      PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default FormModal;
