import React, { Link, useState }          from 'react';
import { Modal, Button, Confirm, Header } from 'semantic-ui-react';
import FormView                           from './FormView';
import RecordList                         from './RecordList';

import PinnedMessageManagementResource  from 'resources/organization/PinnedMessageManagementResource';
import useOrganizationResource          from 'hooks/useOrganizationResource';
import useOrganizationFetcher           from 'hooks/useOrganizationFetcher';

import { find } from 'lodash';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const EMPYT_PINNED_MESSAGE = { subject: '', body: '', expired_at: null };

function deleteHandler({ permissionGroupId, pinnedMessageId, destroy }) {
  const params = { permission_group_id: permissionGroupId, id: pinnedMessageId };
  destroy(params);
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const ManageModal = ({ opened, permissionGroupId, onClose }) => {
  const [ pinnedMessage, setpinnedMessage ] = useState(EMPYT_PINNED_MESSAGE);
  const [ currentView, setCurrentView ]         = useState('list');
  const [ currentDeleteId, setCurrentDeleteId ] = useState(null);
  const [ confirmOpen, setConfirmOpen ]         = useState(false);

  const pinnedMessages = useOrganizationResource(
                            PinnedMessageManagementResource.listShape(),
                            { permission_group_id: permissionGroupId }
                          );

  const create         = useOrganizationFetcher(PinnedMessageManagementResource.createShape());
  const update         = useOrganizationFetcher(PinnedMessageManagementResource.updateShape());
  const destroy        = useOrganizationFetcher(PinnedMessageManagementResource.deleteShape());


  // -----------------------------------------------------
  // Event Handlers
  // -----------------------------------------------------

  const handleDelete = (e, {value}) => {
    setCurrentDeleteId(value);
    setConfirmOpen(true);
  };

  const handleCancelDelete = () => {
    setCurrentDeleteId(null);
    setConfirmOpen(false);
  };

  const handleConfirmDelete = () => {
    const pinnedMessageId = currentDeleteId;

    setCurrentDeleteId(null);
    setConfirmOpen(false);

    deleteHandler({ permissionGroupId, pinnedMessageId, destroy});
  };

  const handleAddNew = () => {
    setCurrentView('form');
  };

  const handleChange = ({name, value}) => {
    setpinnedMessage({
      ...pinnedMessage,
      [name]: value
    });
  };

  const handleEdit = (_, {value}) => {
    const selectedPinnedMessage = find(pinnedMessages, { 'id': value });
    setpinnedMessage(selectedPinnedMessage);
    setCurrentView('form');
  };

  const handleSubmit = async () => {
    const data = { ...pinnedMessage };

    if (pinnedMessage.id){
      const params  = { id: pinnedMessage.id, permission_group_id: permissionGroupId };
      await update(params, data);
    } else {
      const params  = { permission_group_id: permissionGroupId };
      const response = await create(params, data);
      pinnedMessages.push(response);
    }

    setpinnedMessage(EMPYT_PINNED_MESSAGE);
    setCurrentView('list');
  };

  const handleClose = () => {
    setpinnedMessage(EMPYT_PINNED_MESSAGE);
    setCurrentView('list');
    onClose();
  };


  // -----------------------------------------------------
  // Render Component
  // -----------------------------------------------------

  return (
    <Modal open={ opened } onClose={ handleClose } dimmer='inverted' closeIcon>
      <Header icon="cog" content="Manage Pinned Messages" />
      <Modal.Content>
        { currentView === 'list' &&
          <RecordList pinnedMessages={ pinnedMessages }
                      onAddNew={ handleAddNew }
                      onEdit={ handleEdit }
                      onDelete={ handleDelete } />
        }

        { currentView === 'form' &&
          <FormView pinnedMessage={ pinnedMessage }
                    onChange={ handleChange } />
        }
      </Modal.Content>
      <Modal.Actions>
        <Confirm
                  as={Link}
                  to={'./'}
                  open={ confirmOpen }
                  dimmer='inverted'
                  onCancel={ handleCancelDelete }
                  onConfirm={ handleConfirmDelete }
                />
        <Button onClick={ handleClose } negative basic>Close</Button>
        { currentView === 'form' &&
        <Button onClick={ handleSubmit } primary>Submit</Button>
        }
      </Modal.Actions>
    </Modal>
  );
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ManageModal;
