import React, { Component }            from 'react';
import { Modal, Button, Header, Form, Checkbox } from 'semantic-ui-react';
import FileField                       from 'components/forms/Field/Attachment';
import connectResource                 from 'utils/connectResource';
import { formImports }                 from 'resources/organizationResources';

const anchor = '#import-new-modal';

const labels = {
  import: 'Import',
  delete: 'Delete',
  clientTitle: 'Delete Client Responses',
  clientDescription: 'Upload a file with 2 columns: Client ID and Form Response ID',
  publicTitle: 'Delete Public Responses',
  publicDescription: 'Upload a file with 1 column: Form Response ID'
};

function CheckboxLabel({ title, description }) {
  return (
    <span>
      <h4 style={{ marginBottom: '0' }} >{title}</h4>
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </span>
  );
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class UploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened:   false,
      loading:  false,
      state:    'unverified',
      importId: null,
      deleteType: null,
      input:    {}
    };

    this.handleChange     = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit     = this.handleSubmit.bind(this);
    this.handleOpenModal  = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  render() {
    const { uploadType }  = this.props;
    const { opened, loading, input, state, deleteType }  = this.state;

    const hasFile        = (input.constructor === File);
    const buttonDisabled = (!hasFile && state !== 'mapped');
    const buttonLabel    = (state === 'mapped') ? labels[uploadType] : 'Verify';

    return (
      <React.Fragment>
        <Modal open={ opened } onClose={ this.handleCloseModal } dimmer='inverted' closeIcon>
          <Header icon="upload" content={ labels[uploadType] + ' Responses' } />
          <Modal.Content>
            { state !== 'mapped' &&
              <Form style={{ marginBottom: '2rem' }}>
                { uploadType === 'delete' && <>
                <Form.Field>
                  <Checkbox radio name='deleteType'
                    label={{ children: <CheckboxLabel title={ labels.clientTitle }
                                                      description={ labels.clientDescription } /> }}
                    value='delete_client'
                    checked={deleteType === 'delete_client'}
                    onChange={this.handleChange}
                  />
                </Form.Field>

                <Form.Field>
                  <Checkbox radio name='deleteType'
                    label={{ children: <CheckboxLabel title={ labels.publicTitle }
                                                      description={ labels.publicDescription  } /> }}
                    value='delete_public'
                    checked={deleteType === 'delete_public'}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                </> }

                <FileField  ownLabel='Upload CSV file'
                            value={ input }
                            onChange={ this.handleFileChange } />
              </Form>
            }
            { state === 'unverified' && hasFile &&
              <div className="ui message">
                <p>Now, we just need to verify the structure of the uploaded file. Click &quot;Verify&quot; below to begin.</p>
              </div>
            }
            { state === 'failed' &&
              <div className="ui negative message">
                <p>The file is invalid. Please be sure the columns have not been renamed or repositioned, and try again.</p>
              </div>
            }
            { state === 'mapped' &&
              <div className="ui success message">
                <div className="content">
                  <div className="header">Verification Complete!</div>
                  <p>
                    You many now click the &quot;{ labels[uploadType] }&quot;
                    button below to begin the { uploadType } process. This
                    process takes a while, so we’ll email you as soon as it’s
                    finished.
                  </p>
                </div>
              </div>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ this.handleCloseModal } negative basic>Cancel</Button>
            <Button onClick={ this.handleSubmit } disabled={ buttonDisabled } loading={ loading } positive basic>{ buttonLabel }</Button>
          </Modal.Actions>
        </Modal>
        <a onClick={ this.handleOpenModal } href={ anchor } >{  labels[uploadType] + ' Responses'  }</a>
      </React.Fragment>
      );
  }

  handleChange = (e, { value }) => this.setState({ deleteType: value })

  handleFileChange(evt, { value: input }) {
    this.setState({
      state: 'unverified',
      input: input
    });
  }

  async handleSubmit() {
    const { uploadType, recordId, create, update } = this.props;
    const { deleteType, input, state, importId }   = this.state;

    if (uploadType === 'delete' && !deleteType) {
      return null;
    }

    this.setState({ loading: true });

    let response;
    if (state === 'mapped' && importId) {
      response = await update({}, {
        id:       importId,
        form_id:  recordId
      });
    } else {
      response = await create({ input, type: deleteType }, {
        form_id:  recordId
      });
    }

    if(!response.errors) {
      this.setState({
        loading:  false,
        importId: response.id,
        state:    response.state,
        input:    {}
      });

      if(response.state === 'enqueued') {
        this.handleCloseModal();
      }
    } else {
      this.setState({
        loading: false,
        state: 'unverified',
        input: {}
      });
    }
  }

  handleOpenModal() {
    this.setState({ opened: true });
  }

  handleCloseModal() {
    this.setState({ opened: false });
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default connectResource(UploadModal, {
  connectors: {},
  mapDispatchToProps: { ...formImports.actions }
});
