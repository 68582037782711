import React      from 'react';
import PropTypes  from 'prop-types';
import { Table }  from 'semantic-ui-react';

import ScrollPane from 'components/ScrollPane';
import formattedDate from 'utils/formattedDate';

import Changes from './Changes';
import UndoButton from './UndoButton';
import WithPermission from 'containers/WithPermission';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const History = ({ versions }) => {

  return (
    <ScrollPane>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.HeaderCell>Changes</Table.HeaderCell>
            <Table.HeaderCell>Changed By</Table.HeaderCell>
            <Table.HeaderCell>Occurred At</Table.HeaderCell>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            versions.map((version, index) => (
              <Table.Row key={ version.id }>
                <Table.Cell>{ version.event }</Table.Cell>
                <Table.Cell>
                  <Changes attributes={ version.change } action={ version.event } />
                </Table.Cell>
                <Table.Cell>{ version.admin_name }</Table.Cell>
                <Table.Cell>{ formattedDate(version.occurred_at) }</Table.Cell>
                <Table.Cell textAlign='right'>
                  <WithPermission resource="ownerOnly">
                    {
                      version.event === 'update'
                      && index == 0
                      && <UndoButton  versionId={ version.transaction_id } />
                    }
                    </WithPermission>
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </ScrollPane>
  );
};

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

History.defaultProps = {
  versions: PropTypes.array.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default History;
