import React, { Component } from 'react';
import {Form,
        Segment,
        Header,
        Message,
        Table,
        Dropdown,
        Button,
        Divider } from 'semantic-ui-react';

import _            from 'lodash';
import { Redirect } from 'react-router-dom';

import Fixed  from 'components/Fixed';

import connectResource                    from 'utils/connectResource';
import connectForm                        from 'utils/connectForm';
import { getById, getAll, getSingleton }  from 'utils/connectors';

import {users,
        permission_groups,
        organizationAccount } from 'resources/organizationResources';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function permissionGroupName(permissionGroups, id) {
  const group     = permissionGroups[id] || {};
  const { name }  = group;
  return name;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormView extends Component {

  render() {
    const { data,
            selected,
            loading,
            saving,
            api_errors,
            onSave,
            onUpdateValue,
            onUpdateState } = this.props;

    const permissionGroups    = data.permission_groups    || {};
    const organizationAccount = data.organizationAccount  || {};
    const user                = selected                  || {};

    const isOrgOwner = organizationAccount.owner_id === user.id;

    const unusedGroups =
      Object.values(permissionGroups)
            .filter(({ id }) => !user.permission_group_ids.includes(id))
            .sort(({ name: a }, { name: b }) => a > b ? 1 : -1);

    const groupsOptions =
      unusedGroups.map(({ name, id }) => ({ text: name, value: id }));

    return (
      <Segment loading={ loading }>
        { api_errors && <Redirect to='./'/> }

        <Header as='h2'>User Info</Header>

        <Form onSubmit={ onSave } warning={ !user.permission_group_ids.length }>

          <Form.Input label='First Name'
                      name='first_name'
                      value={ user.first_name }
                      placeholder='James'
                      onChange={ onUpdateValue } />

          <Form.Input label='Last Name'
                      name='last_name'
                      value={ user.last_name }
                      placeholder='Brown'
                      onChange={ onUpdateValue } />

          <Form.Input label='Email Address'
                      name='email'
                      value={ user.email }
                      placeholder='e.g. john@example.com'
                      onChange={ onUpdateValue } />

          {
            !isOrgOwner &&
            <div>
              <Header as='h2'>Permission Groups</Header>
              {
                !user.permission_group_ids.length &&
                <Message warning>
                  This user belongs to no permission groups. They will not be able to log in.
                </Message>
              }

              {
                permissionGroups && !!user.permission_group_ids.length &&

                <Table unstackable celled compact basic='very'>
                  <Table.Body>
                    {
                      user.permission_group_ids.map((groupId) => (
                        <Table.Row key={ groupId }>
                          <Table.Cell >
                            <Header as='h4'>
                              { permissionGroupName(permissionGroups, groupId) }
                            </Header>
                          </Table.Cell>

                          <Table.Cell collapsing style={{ borderLeft: 0 }}>
                            <Button basic
                                    color='red'
                                    size='mini'
                                    icon='delete'
                                    onClick={() => onUpdateState({
                                      permission_group_ids: _.without(selected.permission_group_ids, groupId)
                                    })} />
                          </Table.Cell>
                        </Table.Row>
                      ))
                    }
                  </Table.Body>
                </Table>
              }
              <Dropdown text='Add To Group'
                        size='mini'
                        search
                        labeled
                        basic
                        button
                        disabled={!groupsOptions.length}
                        className='icon'
                        icon='add'
                        options={groupsOptions}
                        selectOnBlur={false}
                        onChange={(e, {value}) => {
                          onUpdateState({
                            permission_group_ids : [...selected.permission_group_ids, value]
                          });
                        }} />
              <Divider hidden />
            </div>
          }

          <Fixed>
            <Form.Button  loading={ saving }
                          type='submit'
                          primary>Save</Form.Button>
          </Fixed>
        </Form>
      </Segment>
    );
  }
}

const FormConnected = connectForm(FormView, {
  getDefault: ({ data }) => {
    const { selected, permission_groups } = data;
    let nextState = {};

    if(!selected) {
      nextState = {
        id:                   null,
        first_name:           '',
        last_name:            '',
        email:                '',
        permission_group_ids: []
      };
    } else {
      nextState = selected;
    }

    // add the default permission groups to the user's permission groups
    if(permission_groups && !selected) {
      const defaultGroups =
        Object.values(permission_groups)
              .filter((group) => group.default)
              .map(({ id }) => id);

      nextState = {
        ...nextState,
        permission_group_ids: _.uniq([
          ...nextState.permission_group_ids,
          ...defaultGroups
        ])
      };
    }

    return nextState;
  }
});

export default connectResource(FormConnected, {
  connectors: {
    selected:             getById(users, ({ match: { params: { id } } }) => id),
    permission_groups:    getAll(permission_groups),
    organizationAccount:  getSingleton(organizationAccount)
  },
  mapDispatchToProps: { ...users.actions }
});
