import React      from 'react';
import PropTypes  from 'prop-types';

import { Table }  from 'semantic-ui-react';

import TableHeader  from './Header';
import TableBody    from './Body';

// -----------------------------------------------------
// Settings/Helpers
// -----------------------------------------------------

const actionTypes = {
  'hide':       'Hide',
  'unhide':     'Show',
  'require':    'Require',
  'lock':       'Lock',
  'unlock':     'Unlock',
  'unrequire':  'Optional',
  'clear':      'Clear',
  'set':        'Set',
  'filter':     'Filter'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ActionsEditor = ({ actions, fields, onChange }) => {
  return (
    <Table className='actions-editor' unstackable celled definition striped>
      <TableHeader actionTypes={ actionTypes } />
      <TableBody  actionTypes={ actionTypes }
                  fields={ fields }
                  actions={ actions }
                  onChange={ onChange } />
    </Table>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ActionsEditor.propTypes = {
  actions:  PropTypes.array.isRequired,
  fields:   PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ActionsEditor;
