import React                    from 'react';
import { Switch }               from 'react-router';
import { Route }                from 'react-router-dom';

import FullPage                         from 'components/FullPage';
import ApiCallBoundary                  from 'components/api/ApiCallBoundary';
import AuthenticatedRoute               from 'containers/AuthenticatedRoute';
import RequiresConfidentialityAgreement from 'containers/ConfidentialityAgreement';
import SelectAccountContainer           from 'containers/SelectAccountContainer';
import LoginContainer                   from 'containers/LoginContainer';
import InviteContainer                  from 'containers/InviteContainer';
import OrganizationContainer            from 'containers/OrganizationContainer';
import AdminContainer                   from 'containers/AdminContainer';
import MiscErrorContainer               from 'containers/MiscErrorContainer';
import PublicFormContainer              from 'containers/organization/PublicFormContainer';
import MyProfile                        from 'containers/MyProfile';
import MyOrganization                   from 'containers/MyOrganization';
import ForgotPassword                   from 'containers/password_reset/ForgotPassword';
import PasswordReset                    from 'containers/password_reset/PasswordReset';

class App extends React.Component {
  render() {
    return (
      <FullPage>
        <MiscErrorContainer/>
        <ApiCallBoundary>
          <Switch>
            <Route  path='/select_account'
              component={ SelectAccountContainer } />

            <Route  path='/login'
              component={ LoginContainer } />

            <Route  path='/invitations/:invite_id'
              component={ InviteContainer } />

            <Route  path='/myprofile'
              component={ MyProfile } />

            <Route  path='/myorg'
              component={ MyOrganization } />

            <Route  path='/forgotPassword'
              component={ ForgotPassword } />

            <Route  path='/password_resets/:password_reset_token'
              component={ PasswordReset } />

            <Route  path='/public_forms/:access_key'
              component={ PublicFormContainer } />

            <AuthenticatedRoute>
              <RequiresConfidentialityAgreement>
                <Route path='/' component={ AdminContainer } />
                <Route path='/' component={ OrganizationContainer } />
              </RequiresConfidentialityAgreement>
            </AuthenticatedRoute>
          </Switch>
        </ApiCallBoundary>
      </FullPage>
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default App;
