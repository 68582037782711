import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { Form, Button }     from 'semantic-ui-react';
import DateSelect           from 'components/DateSelect';
import { toSnakeCase }      from 'utils/strings';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const Filters = ({ onSubmit }) => {
  const [beginDate, setBeginDate]               = useState('');
  const [endDate, setEndDate]                   = useState('');
  const [fromOrganization, setFromOrganization] = useState('');
  const [toOrganization, setToOrganization]     = useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const params  = Object
                    .entries({
                      beginDate,
                      endDate,
                      fromOrganization,
                      toOrganization 
                    })
                    .map(([k, v]) => [toSnakeCase(k), v])
                    .filter(([, v]) => v !== null && v !== undefined);
    
    onSubmit(Object.fromEntries(params));
  };

  const handleFromOrganizationChange = (evt, { value }) => {
    setFromOrganization(value);
  };

  const handleToOrganizationChange = (evt, { value }) => {
    setToOrganization(value);
  };

  return (
    <Form onSubmit={ handleSubmit }>
      <Form.Group>
        <Form.Field>
          <label>Begin Date</label>
          <DateSelect onChange={ setBeginDate } name='begin_date' value={ beginDate } />
        </Form.Field>
        <Form.Field>
          <label>End Date</label>
          <DateSelect onChange={ setEndDate } name='end_date' value={ endDate } />
        </Form.Field>
        <Form.Field>
          <label>From Organization</label>
          <Form.Input name="from_organization"
                      value={ fromOrganization }
                      onChange={ handleFromOrganizationChange } />

        </Form.Field>
        <Form.Field>
          <label>To Organization</label>
          <Form.Input name="to_organization"
                      value={ toOrganization }
                      onChange={ handleToOrganizationChange } />

        </Form.Field>
        <Form.Field>
          <label>&nbsp;</label>
          <Button primary basic icon='search' />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

Filters.defaultProps  = {};

Filters.propTypes     = {
  onSubmit: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default Filters;