import React                from 'react';
import PropTypes            from 'prop-types';
import showdown             from 'showdown';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const MarkdownRenderer = ({ value }) => {
  if(value === null || value === undefined) {
    return null;
  }

  const text      = typeof value === 'string' ? value : value.toString();
  const converter = new showdown.Converter();
  let content     = converter.makeHtml(text);
  // Markdown doesn't support target='_blank' by default. This forces all links to open in a separate tab.
  content         = content.replace('<a','<a target=\'_blank\' rel=\'noopener noreferrer\'');

  return (
    <div dangerouslySetInnerHTML={{ __html: content }}/>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

MarkdownRenderer.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default MarkdownRenderer;
