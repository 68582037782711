import React, { Fragment, Component } from 'react';
import PropTypes                      from 'prop-types';

import { Button, Popup }     from 'semantic-ui-react';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const titleSummary = 'Show/Hide conditions and actions';
const titleOrder = 'Change logic settings order';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ViewModeButtons extends Component {
  constructor(props) {
    super(props);
    this.handleClickSummary  = this.handleClickSummary.bind(this);
    this.handleClickOrder    = this.handleClickOrder.bind(this);
  }

  render() {
    const { summaryMode } = this.props;

    const BtnSummary = <Button icon={ summaryMode ? 'eye' : 'eye slash' }
                        size='mini'
                        basic
                        color='black'
                        onClick={ this.handleClickSummary } />;

    const BtnOrder = <Button icon='bars'
                        size='mini'
                        basic
                        color='black'
                        onClick={ this.handleClickOrder } />;

    return (
      <Fragment>
        <Popup  trigger={ BtnSummary }
                content={ titleSummary }
                position='top center' />
        <Popup  trigger={ BtnOrder }
                content={ titleOrder }
                position='top center' />
      </Fragment>
    );
  }

  handleClickSummary() {
    const { onClickSummary } = this.props;
    onClickSummary();
  }

  handleClickOrder() {
    const { onClickOrdering } = this.props;
    onClickOrdering();
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ViewModeButtons.propTypes = {
  onClickSummary:     PropTypes.func.isRequired,
  onClickOrdering:    PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ViewModeButtons;
