// NOTE: This is a temporary hook.  We need this as long as we're storing data
// in the Redux store and in the rest-hooks cache.
import { useSelector }  from 'react-redux';
import useApiResource   from 'hooks/useApiResource';

// -----------------------------------------------------
// Hook Definition
// -----------------------------------------------------

export default function useOrganizationResource(resourceShape, params) {

  // Get the current organization slug from the Redux state
  const organization  = useSelector(state => state.orgdomain);
  return useApiResource(resourceShape, { ...params, organization });
}
