import { useState, useEffect, useReducer }  from 'react';
import { useDispatch }                      from 'react-redux';

import { AUTH_FAILED }                      from 'constants/ActionTypes';
import AuthToken                            from 'utils/AuthToken';
import { generateReducers }                 from 'utils/reducers';

// -----------------------------------------------------
// Internal Functions
// -----------------------------------------------------

const authHeaderKey = 'Authorization';

const withAuthHeaders = async (fn) => {
  const authToken       = AuthToken.read();
  const requestHeaders  = { [authHeaderKey]: `Bearer: ${authToken}` };
  const response        = await fn(requestHeaders);

  const { headers: responseHeaders } = response;
  const nextAuthToken   = responseHeaders.get(authHeaderKey);

  if(nextAuthToken !== null) {
    AuthToken.write(nextAuthToken);
  }

  return response;
};

const initialState = {
  isLoading:  false,
  isError:    false,
  data:       null
};

const actions = {
  INIT:     'init',
  SUCCESS:  'success',
  ERROR:    'error'
};

const fetchReducer = generateReducers({
  [actions.INIT]: (state) => {
    return {
      ...state,
      isLoading:  true,
      isError:    false
    };
  },

  [actions.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isLoading:  false,
      isError:    false,
      data:       payload
    };
  },

  [actions.ERROR]: (state, { payload }) => {
    return {
      ...state,
      isLoading:  false,
      isError:    true,
      data:       payload
    };
  }
});

function createFetcher(localDispatch, globalDispatch) {
  return async (url, { signal, method, body }) => {
    localDispatch({ type: actions.INIT });

    try {
      const response  = await withAuthHeaders(headers => (
                          fetch(url, { headers, signal, method, body })
                        ));

      if(response.status === 401) {
        globalDispatch({ type: AUTH_FAILED });

      } else {
        const type    = response.status >= 400 ? actions.ERROR : actions.SUCCESS;
        const payload = await response.json();

        localDispatch({ type, payload });
        return payload;
      }

    } catch(e) {
      console.error(e);
      localDispatch({ type: actions.ERROR, payload: 'Could not load records.' });
    }
  };
}

function useFetchState(initialUrl, initialData) {
  const [state, dispatch] = useReducer(fetchReducer, {
                              ...initialState,
                              data: initialData
                            });
  const globalDispatch    = useDispatch();
  const doFetch           = createFetcher(dispatch, globalDispatch);

  return [state, doFetch];
}

function bodyAsFormData(body) {
  if(body === null || body === undefined) {
    return null;
  } else {
    return  Object.entries(body).reduce((fd, [k, v]) => {
              fd.append(k, v);
              return fd;
            }, new FormData());
  }
}

function useApiModify(initialUrl, initialData, method) {
  const [, doFetch] = useFetchState(initialUrl, initialData);

  return async (body) => {
    const fdBody = bodyAsFormData(body);
    return await doFetch(initialUrl, { method, body: fdBody });
  };
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export function useApiCreate(initialUrl, initialData) {
  return useApiModify(initialUrl, initialData, 'POST');
}

export function useApiUpdate(initialUrl, initialData) {
  return useApiModify(initialUrl, initialData, 'PUT');
}

export default function useApiFetch(initialUrl, initialData) {
  const [url, setUrl]     = useState(initialUrl);
  const [state, doFetch]  = useFetchState(initialUrl, initialData);

  useEffect(() => {
    const controller  = new AbortController();
    const { signal }  = controller;

    doFetch(url, { signal, method: 'GET' });

    return () => controller.abort();
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps

  return [state, url, setUrl];
}
