// import { useEffect, useState }  from 'react';
import { useSelector }  from 'react-redux';

import { useApiCreate } from 'hooks/useApiFetch';
import resourceUrl      from 'utils/resourceUrl';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const urlRoot = '/organization/:organization/case_worthy/clients/:clientId/client';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function useClientCreate(clientId) {

  // Get the current organization slug from the Redux state
  const organization      = useSelector(state => state.orgdomain);
  const initUrl           = resourceUrl(urlRoot, '', { organization, clientId });

  return useApiCreate(initUrl, {});
}
