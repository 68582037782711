import React      from 'react';
import PropTypes  from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';

import { useHistoryContext } from './HistoryContextProvider';
import useOrganizationFetcher from 'hooks/useOrganizationFetcher';
import VersionRollbackResource from 'resources/VersionRollbackResource';

const title = 'Undo changes.';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const UndoButton = ({ versionId }) => {
  const { recordId, recordType, onRollback } = useHistoryContext();
  const rollback  = useOrganizationFetcher(VersionRollbackResource.singletonCreateShape());

  const handleRollback = async () => {
    const result = await rollback({ recordId, recordType}, { version_id: versionId });
    onRollback(result);
  };

  const Btn = <Button icon='history'
                      basic
                      color='blue'
                      onClick={ handleRollback } />;

  return (
    <Popup  trigger={ Btn }
            content={ title }
            position='top center' />
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

UndoButton.defaultProps  = {};
UndoButton.propTypes     = {
  versionId: PropTypes.number.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default UndoButton;