import applyCalculation        from 'utils/calculations/applyCalculation';
import formatCalculationResult from 'utils/calculations/formatCalculationResult';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function evaluateCalculations(fields=[], answers, calculations=[]) {
  const emptyAnswers  = fields.reduce((result, { id }) => {
                          result[id] = { field_id: id, value: undefined };
                          return result;
                        }, {});

  const allAnswers    = Object
                        .entries(
                          Object.assign({}, emptyAnswers, answers)
                        )
                        .map(([key, { field_id, value }]) => (
                          [key, { field_id: field_id, value }])
                        )
                        .reduce((result, [key, answer]) => (
                          (result[key] = answer) && result
                        ), {});

  return  calculations
          .map(calculation => (
            [
              calculation,
              formatCalculationResult(
                calculation,
                applyCalculation(calculation, allAnswers, calculations)
              )
            ]
          ))
          .filter(([ , value]) => value !== null);

}
