import React, { Component }                       from 'react';
import PropTypes                                  from 'prop-types';
import { Message, Dropdown, Divider, Grid, Ref }  from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable }  from 'react-beautiful-dnd';
import find                                       from 'lodash/find';

import Field from './Field';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const addButtonStyle = {
  margin: '1.5rem 0'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FieldList extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd    = this.handleAdd.bind(this);
    this.handleDragEnd  = this.handleDragEnd.bind(this);
  }

  render() {
    const { fields,
            formFields } = this.props;

    const hasNoFields = formFields.length === 0;

    const fieldLabels = fields.reduce((labels, { name, id }) => {
      labels[id] = name;
      return labels;
    }, {});

    const usedFieldIds  = formFields.map(({ id }) => (id));
    const unusedOptions = fields
                          .map(({ name, id, field_type }) => (
                            { text: name, value: id, field_type: field_type }
                          ))
                          .filter(({ value }) => (
                            !usedFieldIds.includes(value)
                          ));

    return (
      <div>
        <Divider horizontal style={{ marginBottom: '2rem' }}>Fields</Divider>
        <DragDropContext onDragEnd={ this.handleDragEnd }>
          <Droppable droppableId="form-field-list">
            {
              (provided) => (
                <Ref innerRef={ provided.innerRef }>
                  <Grid columns={ 4 } divided='vertically' verticalAlign='middle'>
                    {
                      formFields.map((formField, i) => {
                        const { id }  = formField;
                        const label   = fieldLabels[id];

                        return(
                          <Draggable key={ id } draggableId={ id } index={ i }>
                            {
                              (provided, snapshot) => (
                                <Ref innerRef={ provided.innerRef }>
                                  <Field  index={ i }
                                          formField={ formField }
                                          formFields={ formFields }
                                          allFields={ fields }
                                          label={ label }
                                          onChange={ this.handleChange }
                                          onRemove={ this.handleRemove }
                                          draggableProvided={ provided }
                                          draggableSnapshot={ snapshot } />
                                </Ref>
                              )
                            }
                          </Draggable>
                        );
                      })
                    }
                    { provided.placeholder }
                  </Grid>
                </Ref>
              )
            }
          </Droppable>
        </DragDropContext>

        {
          hasNoFields &&
          <Message info>
            Please add at least one field to this form.
          </Message>
        }

        <Dropdown text='Select a field to add'
                  search
                  fluid
                  labeled
                  basic
                  button
                  className='icon'
                  icon='add'
                  style={ addButtonStyle }
                  selectOnBlur={ false }
                  selectOnNavigation={ false }
                  options={ unusedOptions }
                  onChange={ this.handleAdd } />
      </div>
    );
  }

  handleChange(evt, { formField, index }) {
    const { formFields, name, onChange } = this.props;
    const nextFormFields  = [ ...formFields ];
    nextFormFields[index] = formField;

    onChange(evt, { name, value: nextFormFields });
  }

  handleRemove(evt, { index }) {
    const { formFields, name, onChange } = this.props;
    const nextFormFields  = [ ...formFields ];
    nextFormFields.splice(index, 1);

    onChange(evt, { name, value: nextFormFields });
  }

  handleAdd(evt, { value, options }) {
    const { formFields, name, onChange } = this.props;
    const selectedOption = find(options, ['value', value ]);
    const isRequired     = selectedOption.field_type !== 'presentation';

    const nextFormFields = [
      ...formFields,
      {
        id: value,
        field_type: selectedOption.field_type,
        is_required: isRequired,
        is_quick_view: true,
        is_locked: false,
        position: formFields.length
      }
    ];

    onChange(evt, { name, value: nextFormFields });
  }

  handleDragEnd({ source, destination }) {

    // If the drag does not result in a change, do nothing.
    if(!source || !destination || source.index === destination.index) {
      return;
    }

    const { formFields, name, onChange } = this.props;
    const nextFormFields  = [...formFields ];
    const sourceField     = nextFormFields[source.index];

    // move the source to the destination
    nextFormFields.splice(source.index, 1);
    nextFormFields.splice(destination.index, 0, sourceField);

    // update positions on all fields
    nextFormFields.forEach((formField, i) => (formField.position = i));

    onChange(null, { name, value: nextFormFields });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FieldList.defaultProps = {};
FieldList.propTypes = {
  name:                   PropTypes.string.isRequired,
  fields:                 PropTypes.array.isRequired,
  formFields:             PropTypes.array.isRequired,
  onChange:               PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldList;
