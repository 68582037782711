import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, Button, List, Header } from 'semantic-ui-react';

import { DISMISS_ERRORS } from '../constants/ActionTypes';

class MiscErrorContainer extends React.Component {
  render() {

    return (
      <Modal dimmer='inverted' open={!!this.props.errors} size='small'>
        <Header icon='warning' content='Error'/>
        <Modal.Content>
          <List>
            {_.toPairs(this.props.errors).map(([ername, errs]) => (
              <List.Item key={ername}>
                <Header color='red'>{ername}</Header>
                <List bulleted>
                  {errs.map(err => (
                    <List.Item key={err}>{err}</List.Item>
                  ))}
                </List>
              </List.Item>
            ))}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => this.props.dispatch({type : DISMISS_ERRORS})}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const errors = state.error;
  return {
    errors
  };
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default connect(mapStateToProps)(MiscErrorContainer);
