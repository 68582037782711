import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { Form, Grid, Header } from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ReportConfiguration extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { reportDefinition: { name } } = this.props;

    return (
      <Form>
        <Header as='h2'>Configure Report</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Input label="Name this report"
                          value={ name || '' }
                          name='name'
                          onChange={ this.handleChange } />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  handleChange(evt, { name, value }) {
    const { onChange } = this.props;
    onChange({ [name]: value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ReportConfiguration.defaultProps = {
  reportDefinition: {},
};

ReportConfiguration.propTypes = {
  reportDefinition: PropTypes.object,
  onChange:         PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ReportConfiguration;
