import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';

import connectResource      from 'utils/connectResource';
import { getById }          from 'utils/connectors';
import { imports }          from 'resources/adminResources';

import Advanced             from 'components/admin/imports/Detail/Advanced';
import Basic                from 'components/admin/imports/Detail/Basic';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const DetailContainer = (props) => {
  const { data, loading } = props;
  const { selected }      = { ...data };
  const record            = { ...selected };

  return (
    <Fragment>
      <Basic loading={ loading }
             record={ record } />

      <Advanced loading={ loading }
                record={ record } />
    </Fragment>
  );
};

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

DetailContainer.propTypes = {
  data:       PropTypes.object.isRequired,
  loading:    PropTypes.bool.isRequired,
  match:      PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default connectResource(DetailContainer, {
  connectors: {
    selected: getById(imports, ({ match }) => match.params.id)
  }
});
