import { Resource } from 'rest-hooks';
import resourceUrl  from 'utils/resourceUrl';

// -----------------------------------------------------
// NetworkError Definition
// -----------------------------------------------------

class NetworkError extends Error {
  status    = null;
  response  = null;

  constructor(response) {
    super(response.statusText);

    this.status   = response.status;
    this.response = response;
  }
}

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

// Extends Resource to provide appropriate handling of FormData payloads, and
// for building environment specific URLs for API calls.
class ApiResource extends Resource {
  static url(urlParams) {
    return resourceUrl(this.urlRoot, ':id', urlParams);
  }

  static listUrl(searchParams) {
    return resourceUrl(this.urlRoot, '', searchParams);
  }

  // Override Resource.fetchResponse to properly handle FormData.
  static fetchResponse(method, url, body) {
    // base fetch options
    let options = {
      method:       method.toUpperCase(),
      headers:  {
        'Content-Type': 'application/json'
      }
    };

    if(this.fetchOptionsPlugin) {
      options = this.fetchOptionsPlugin(options);
    }

    if(body) {
      if(body instanceof FormData) {
        options.body  = body;
        delete options.headers['Content-Type'];
      } else {
        options.body  = JSON.stringify(body);
      }
    }

    return  fetch(url, options)
            .then(response => {
              if(!response.ok) {
                throw new NetworkError(response);
              }
              return response;
            })
            .catch(error => {
              if(error instanceof TypeError) {
                error.status = 400;
              }
              throw error;
            });
  }

  static singletonDetailShape() {
    return {
      ...this.detailShape(),

      getFetchKey: (params) => {
        const url = resourceUrl(this.urlRoot, '', params);
        return `GET ${url}`;
      },

      fetch: (params) => {
        const url = resourceUrl(this.urlRoot, '', params);
        return this.fetch('get', url);
      }
    };
  }

  static singletonUpdateShape() {
    return {
      ...this.updateShape(),

      getFetchKey: (params) => {
        const url = resourceUrl(this.urlRoot, '', params);
        return `PUT ${url}`;
      },

      fetch: (params, body) => {
        const url = resourceUrl(this.urlRoot, '', params);
        return this.fetch('put', url, body);
      }
    };
  }

  static singletonCreateShape() {
    return {
      ...this.createShape(),

      getFetchKey: (params) => {
        const url = resourceUrl(this.urlRoot, '', params);
        return `POST ${url}`;
      },

      fetch: (params, body) => {
        const url = resourceUrl(this.urlRoot, '', params);
        return this.fetch('post', url, body);
      }
    };
  }

  static singletonDeleteShape() {
    return {
      ...this.deleteShape(),

      getFetchKey: (params) => {
        const url = resourceUrl(this.urlRoot, '', params);
        return `DELETE ${url}`;
      },

      fetch: (params) => {
        const url = resourceUrl(this.urlRoot, '', params);
        return this.fetch('delete', url);
      }
    };
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ApiResource;
