import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import TimeSelect           from 'components/TimeSelect';

//---------------------------------------------------------
// Component Definition
//---------------------------------------------------------
class Time extends Component {

  // initialisation
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  // rendering
  render() {
    const { value, metadata: { format, pre_populate } } = this.props;

    return (
      <TimeSelect value={ value }
                  format={ format }
                  prepopulate={ pre_populate }
                  onChange={ this.handleChange }  />
    );
  }

  handleChange({ value, error }) {
    const { name, onChange } = this.props;

    onChange(null, { name, value, error });
  }
}

//---------------------------------------------------------
// PropTypes
//---------------------------------------------------------

Time.defaultProps = {
  metadata: { format: '24-hour', pre_populate: false }
};

Time.propTypes = {
  value: PropTypes.string,

  metadata: PropTypes.shape({
    format:       PropTypes.oneOf(['12-hour', '24-hour']),
    pre_populate: PropTypes.bool
  }),

  onChange: PropTypes.func.isRequired
};

//---------------------------------------------------------
// Exports
//---------------------------------------------------------

export default Time;
