import React        from 'react';
import PropTypes    from 'prop-types';
import { Segment }  from 'semantic-ui-react';

import connectResource  from 'utils/connectResource';
import { getById }      from 'utils/connectors';
import { affiliations } from 'resources/organizationResources';

import OwnerView        from './OwnerView';
import ParticipantView  from './ParticipantView';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const OWNER_TYPE        = 'owner';
const PARTICIPANT_TYPE  = 'participant';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = ({ loading, data }) => {
  const affiliation = data.affiliation || {};
  const { type }    = affiliation;

  return (
    <Segment loading={ loading }>
      {
        type === OWNER_TYPE &&
        <OwnerView affiliation={ affiliation } />
      }

      {
        type === PARTICIPANT_TYPE &&
        <ParticipantView affiliation={ affiliation } />
      }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DetailView.defaultProps = {
  loading: false
};

DetailView.propTypes = {
  loading:  PropTypes.bool,
  data:     PropTypes.shape({
    affiliation: PropTypes.object
  }).isRequired,
  match:    PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(DetailView, {
  connectors: {
    affiliation: getById(affiliations, ({ match: { params: { id } } }) => id)
  }
});
