import React, { Component, Fragment } from 'react';
import { Button }                     from 'semantic-ui-react';

import AddModal   from './AddModal';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const title = 'Add reports to your dashboard.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class AddButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };

    this.handleClick  = this.handleClick.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  render() {
    const { opened } = this.state;

    return (
      <Fragment>
        <AddModal opened={ opened }
                  title={ title }
                  onClose={ this.handleClose }
                  { ...this.props } />

        <Button color='black'
                icon='add'
                size='mini'
                circular
                compact
                basic
                onClick={ this.handleClick } />
      </Fragment>
    );
  }

  handleClick() {
    this.setState({ opened: true });
  }

  handleClose() {
    this.setState({ opened: false });
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AddButton;
