import React          from 'react';

import {  Grid,
          Segment,
          Header,
          Divider,
          Form }      from 'semantic-ui-react';

import passwordReset  from '../../resources/passwordResource';
import { connect }    from 'react-redux';
import { bindActionCreators } from 'redux';

const style = {
  grid: {
    'height': '100%'
  }
};

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password:               '',
      password_confirmation:  ''
    };

    this.handleSubmit  = this.handleSubmit.bind(this);
    this.handleChange  = this.handleChange.bind(this);
  }

  render() {

    const { password, password_confirmation } = this.state;

    return (
      <Grid verticalAlign='middle' centered columns='3' style={ style.grid }>
        <Grid.Row>
          <Grid.Column>
            <Header as='h1' textAlign='center'>CoactionNet</Header>
            <Segment >
              <Header>Password Reset</Header>
              <Divider />
              <Form onSubmit={ this.handleSubmit }>

                <Form.Input id='password'
                            label='New Password'
                            name='password'
                            value={ password }
                            placeholder='new password'
                            type='password'
                            onChange={ this.handleChange } />

                <Form.Input id='password_confirmation'
                            label='Confirm Password'
                            name='password_confirmation'
                            value={ password_confirmation }
                            placeholder='confirm password'
                            type='password'
                            onChange={ this.handleChange } />

                <Form.Button type='submit' primary>Submit</Form.Button>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  handleChange(evt, { name, value }) {
    this.setState({ [name]: value });
  }

  async handleSubmit(evt) {
    evt.preventDefault();

    const { match: { params={} }={}, history, update } = this.props;
    const password_reset_token = params.password_reset_token;

    const response = await update(this.state, { password_reset_token });

    if(!response.errors) {
      if(
        response.authentication_scopes &&
        response.authentication_scopes.length > 0
      ){
        history.push('/select_account');
      } else {
        history.push('/login');
      }
    }
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ...bindActionCreators(passwordReset.actions, dispatch),
  ...ownProps // allows history and update to be passed in as props, in test
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
