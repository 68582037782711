import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';
import { Button }           from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const WizardButtons = ({ step, numberOfSteps, onChange, onSave }) => {
  const lastStep = numberOfSteps - 1;

  return (
    <Fragment>
      {
        step > 0 &&
        <Button value={ -1 }
                onClick={ onChange }
                primary
                basic>
          Previous
        </Button>
      }

      {
        step < lastStep &&
        <Button value={ 1 }
                onClick={ onChange }
                primary>
          Next
        </Button>
      }

      {
        step === lastStep &&
        <Button onClick={ onSave }
                primary>
          Save
        </Button>
      }
    </Fragment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

WizardButtons.propTypes = {
  step:           PropTypes.number.isRequired,
  numberOfSteps:  PropTypes.number.isRequired,
  onChange:       PropTypes.func.isRequired,
  onSave:         PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default WizardButtons;
