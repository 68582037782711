import React        from 'react';
import PropTypes    from 'prop-types';
import { Message }  from 'semantic-ui-react';
import classnames   from 'classnames';

import cast           from 'utils/cast';
import formattedDate  from 'utils/formattedDate';

import {intervalParameterType,
        dateRelativeParameterType } from 'constants/Calculations';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function variableName(fields, calculations, recordType, recordId, value, parameterType) {
  // Render field info
  if(recordId !== null && recordId !== undefined) {
    const strFieldId = cast(recordId, 'string');
    const variable = (recordType == 'Field') ?
                       fields.find(({ id }) => (cast(id, 'string') === strFieldId)) :
                       calculations.find(({ id }) => (cast(id, 'string') === strFieldId));

    return variable && variable.name;
  } else {
    if(parameterType === intervalParameterType) {
      const { magnitude, unit } = value || {};
      return `${magnitude} ${unit}`;
    }

    if(parameterType === dateRelativeParameterType) {
      return formattedDate(value, 'date', 'Unknown');
    }
  }

  return '';
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const VariablesInfo = ({ variables, fields, calculations, basic }) => {
  const classNames = classnames({ basic });

  if(variables.length) {
    return (
      <Message info className={ classNames }>
        {
          !basic &&
          <Message.Header>
            Variables used in this equation:
          </Message.Header>
        }

        <code>
          {
            variables.map(({ record_type, record_id, name, value, parameter_type }, index) => (
              <div key={ index }>
                <strong>{ name }</strong> =&nbsp;
                { variableName(fields, calculations, record_type, record_id, value, parameter_type) }
              </div>
            ))
          }
        </code>
      </Message>
    );
  } else {
    return null;
  }
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

VariablesInfo.defaultProps = {
  variables:  [],
  basic:      false
};

VariablesInfo.propTypes = {
  variables:  PropTypes.array,
  basic:      PropTypes.bool,
  fields:     PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    data_type:  PropTypes.string
  })).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default VariablesInfo;
