import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';

import connectResource                from 'utils/connectResource';
import { getById }                    from 'utils/connectors';
import { imports }                    from 'resources/adminResources';

import ActionsCard                    from 'components/admin/imports/Card/Actions';
import ErrorsCard                     from 'components/admin/imports/Card/Errors';
import StatusCard                     from 'components/admin/imports/Card/Status';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class SideCardContainer extends Component {

  // helpers
  getRecord() {
    const { data }     = this.props;
    const { selected } = { ...data };
    return { ...selected };
  }
  showActions() {
    const record    = this.getRecord();
    const { state } = record;
    return state === 'mapped';
  }
  showErrors() {
    const record    = this.getRecord();
    const { state } = record;
    return state === 'failed';
  }

  // rendering
  render() {
    const { loading,
            onUpdate } = this.props;
    const record       = this.getRecord();

    return (
      <Fragment>
        <StatusCard loading={ loading }
                    record={ record } />
        {
          this.showActions() &&
          <ActionsCard loading={ loading }
                       record={ record }
                       onUpdate={ onUpdate } />
        }
        {
          this.showErrors() &&
          <ErrorsCard loading={ loading }
                      record={ record } />
        }
      </Fragment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

SideCardContainer.propTypes = {
  data:     PropTypes.object.isRequired,
  loading:  PropTypes.bool.isRequired,
  match:    PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

// FIXME(?): This feels like the wrong place for this, but
// that's the way the MD component works for right now.
//
export default connectResource(SideCardContainer, {
  connectors: {
    selected: getById(imports, ({ match }) => match.params.id)
  }
});
