import React, { useState }  from 'react';
import { Button, Popup }    from 'semantic-ui-react';

import EditTitleModal        from './EditTitleModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const modalTitle = 'Edit Household Title';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const EditTitleButton = ({householdTitle, onUpdate}) => {
  const [opened, setOpened] = useState(false);

  const handleClick = (evt) => {
    evt.preventDefault();
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const Btn = <Button icon='pencil'
                      size='mini'
                      basic
                      color='blue'
                      onClick={ handleClick } />;

  return (
    <>
      <EditTitleModal title={ modalTitle }
                      opened={ opened }
                      householdTitle={ householdTitle }
                      onUpdate={ onUpdate }
                      onClose={ handleClose } />

      <Popup  trigger={ Btn }
              content={ modalTitle }
              position='top right' />
    </>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditTitleButton;
