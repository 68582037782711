import React      from 'react';
import { Grid }   from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FieldOptionListHeader = () => {
  return (
    <Grid columns={ 4 }>
      <Grid.Row>
        <Grid.Column width={ 1 }></Grid.Column>
        <Grid.Column width={ 6 }>
          <h5>Label</h5>
        </Grid.Column>
        <Grid.Column width={ 6 }>
          <h5>Value</h5>
        </Grid.Column>
        <Grid.Column width={ 3 }></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldOptionListHeader;
