import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header, Grid }  from 'semantic-ui-react';

import ResponseForm                           from 'containers/ResponseForm';
import connectResource                        from 'utils/connectResource';
import { getById }                            from 'utils/connectors';
import { internalForms, internalFormRecords } from 'resources/organizationResources';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class EditRecordForm extends Component {
  constructor(props) {
    super(props);
    this.handleSave  = this.handleSave.bind(this);
  }

  render() {
    const { data={}, loading }  = this.props;
    const form                  = data.form   || {};
    const record                = data.record || {};

    const { name }        = form;
    const { answers=[] }  = record;

    const answers_hash = answers.reduce((index, answer) => (
      (index[answer.field_id] = answer) && index
    ), {});

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment loading={ loading }>
              <Header as='h2'>{ name }</Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment loading={ loading }>
              <Header as='h3'>Edit Record</Header>
              <div style={{ marginTop: '2rem' }}>
                <ResponseForm answers={ answers_hash }
                              form={ form }
                              onSave={ this.handleSave } />
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  async handleSave(answers, done) {
    const { match: { params: { id, record_id } },
            dispatch,
            history }  = this.props;

    const formFieldAnswers = Object.entries(answers).map(([field_id, value]) => {
      return { field_id, ...value };
    });

    const params = {
      answers: formFieldAnswers
    };

    const scope = {
      id:       record_id,
      form_id:  id
    };

    const request   = internalFormRecords.actions.update(params, scope);
    const response  = await dispatch(request);

    if(!response.errors) {
      history.push(`/organization/internal_form_responses/${id}`);
    }

    done();
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditRecordForm.defaultProps = {
  loading: false
};

EditRecordForm.propTypes = {
  loading:  PropTypes.bool,
  match:    PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history:  PropTypes.object.isRequired,
  data:     PropTypes.shape({
    form: PropTypes.object
  }).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(EditRecordForm, {
  connectors: [
    {
      record: getById(
        internalFormRecords,
        ({ match: { params: { record_id } } })  => (record_id),
        ({ match: { params: { id } } })         => ({ form_id: id })
      ),
      form: getById(internalForms, ({ match: { params: { id } } }) => id)
    }
  ]
});
