import React                        from 'react';
import { Route }                    from 'react-router-dom';
import { Grid }                     from 'semantic-ui-react';

import useApiResource               from 'hooks/useApiResource';
import UserAccountResource          from 'resources/UserAccountResource';
import impersonation                from 'resources/impersonation';
import connectResource              from 'utils/connectResource';
import { getSingleton }             from 'utils/connectors';
import RoleRestrictedRouteContainer from './RoleRestrictedRouteContainer';

import {
  DashboardContainer,
  FieldsApp,
  FormsApp,
  ImportsApp,
  OrganizationsApp,
  PricingTiersApp,
  UsersApp,
  VersionsApp
} from './admin';

import ImpersonationContext from 'contexts/Impersonation';

import HeaderBar  from 'components/HeaderBar';
import SideBar    from 'components/SideBar';
import Main       from 'components/Main';
import Nav        from 'components/admin/Nav';
import BrandBox   from 'components/BrandBox';
import ToolBar    from 'components/ToolBar';
import UserMenu   from './admin/UserMenu';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const AdminContainer = ({ data }) => {
  const userAccount   = useApiResource(UserAccountResource.singletonDetailShape());
  const impersonation = data.impersonation;

  return (
    <ImpersonationContext.Provider value={ { impersonation } }>
      <RoleRestrictedRouteContainer userAccount={ userAccount }
                                    permittedRoles={ ['superuser'] }>

        <HeaderBar className='printable-hidden'>
          <BrandBox width='120px'>
            CoactionNet
          </BrandBox>
          <ToolBar offsetLeft='120px' className='padded-content'>
            <Grid columns={2}>
              <Grid.Column >
                <h3>CoactionNet Admin</h3>
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Route
                  path='/'
                  render={props =>
                    <UserMenu user={ userAccount } { ...props  } />
                  }
                />
              </Grid.Column>
            </Grid>
          </ToolBar>
        </HeaderBar>

        <Main offsetTop='50px' className='printable-page'>
          <SideBar className='printable-hidden' width='120px'>
            <Nav />
          </SideBar>
          <Main offsetLeft='120px' className='padded-content stage-content printable-full'>
            <Route  path='/admin/dashboard'
              component={ DashboardContainer } />

            <Route  path='/admin/organizations'
              component={ OrganizationsApp } />

            <Route  path='/admin/users'
              component={ UsersApp } />

            <Route  path='/admin/pricing-tiers'
              component={ PricingTiersApp } />

            <Route  path='/admin/forms'
              component={ FormsApp } />

            <Route  path='/admin/fields'
                    component={ FieldsApp } />

            <Route  path='/admin/imports'
                    component={ ImportsApp } />

            <Route  path='/admin/versions'
                    component={ VersionsApp } />
          </Main>
        </Main>
      </RoleRestrictedRouteContainer>
    </ImpersonationContext.Provider>
  );
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default connectResource(AdminContainer, {
  connectors: {
    impersonation:  getSingleton(impersonation)
  }
});
