import React          from 'react';
import { Button }     from 'semantic-ui-react';

import Privileges     from 'constants/Privileges';
import WithPermission from 'containers/WithPermission';

import ShowButton         from './ShowButton';
import DownloadButton         from './DownloadButton';
import EditButton         from './EditButton';
import DeleteButton       from './DeleteButton';

const InstanceControls = ({formId, recordId, hasAttachments, onDelete}) => (
  <Button.Group floated='right' size='mini' >
    <ShowButton formId={ formId }
                  recordId={ recordId } />

  	{ hasAttachments &&
      <DownloadButton formId={ formId }
                  recordId={ recordId } />
    }

    <WithPermission resource='internal_forms' actions={ Privileges.write }>
      <EditButton formId={ formId }
        recordId={ recordId } />
    </WithPermission>

    <WithPermission resource='internal_forms' actions={ Privileges.remove }>
      <DeleteButton id={ recordId } onDelete={ onDelete } />
    </WithPermission>

  </Button.Group>
);

export default InstanceControls;
