import React                        from 'react';
import PropTypes                    from 'prop-types';
import { Segment, Header, Message } from 'semantic-ui-react';
import { Link }                     from 'react-router-dom';

import SortableTable  from 'components/SortableTable';
import formattedDate  from 'utils/formattedDate';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const hrefColumnIndex = 0;

// These columns will have their values formatted.
const dateTimeColumnIndices = [7, 8, 9, 10];

const headerLabels = {
  email:              'Email',
  role:               'Role',
  time_zone:          'Time Zone',
  first_name:         'First Name',
  last_name:          'Last Name',
  archived:           'Archived?',
  created_at:         'Created At',
  updated_at:         'Updated At',
  last_accessed_at:   'Last Accessed At',
  last_logged_in_at:  'Last Logged-in At',
  last_login_ip:      'Last Login IP Address'
};

const orderedColumns = [
  'href',
  'first_name',
  'last_name',
  'email',
  'role',
  'archived',
  'time_zone',
  'created_at',
  'updated_at',
  'last_accessed_at',
  'last_logged_in_at',
  'last_login_ip'
];

function getTableData(users, archived) {
  const result = { headers: [], records: [] };

  if(!users || users.length === 0) {
    return result;
  }

  result.headers  = orderedColumns.map(key => headerLabels[key]);
  result.records  = users.map(user => (
    orderedColumns.map(key => {
      if (key === 'href') {
        return `/admin/users/${user.id}`;
      } else if (key === 'archived') {
          return archived.includes(user.id) ? 'Yes' : null;
      } else {
          return user[key]; // Returning user[key] in the else block
      }
      })
  ));

  return result;
}

function cellRenderer([href,], value, columnIndex) {
  const formattedValue  = dateTimeColumnIndices.includes(columnIndex)
                          ? formattedDate(value)
                          : value;
  return (
    <Link to={ href }>{ formattedValue }</Link>
  );
}

function cloaker(index) {
  return index === hrefColumnIndex;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const UserList = ({ loading, users, archived }) => {
  const { headers, records } = getTableData(users, archived);
  return (
    <Segment loading={ loading }>
      <Header as='h3'>Organization Members</Header>

      {
        users.length
        ? <SortableTable  tableId={ 'user_list' }
                          headerLabels={ headers }
                          records={ records }
                          cellRenderer={ cellRenderer }
                          columnCloaker={ cloaker } />
        : <Message info>
            There are currently no users in this organization.
          </Message>
      }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

UserList.defaultProps = {
  loading:  false,
  users:    [],
  archived: []
};

UserList.propTypes = {
  loading:  PropTypes.bool,
  users:    PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name:  PropTypes.string
  })),
  archived: PropTypes.array
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default UserList;
