import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table, Checkbox }  from 'semantic-ui-react';
import connectResource          from 'utils/connectResource';
import { getById } from 'utils/connectors';
import { reportDefinitions }     from 'resources/organizationResources';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const fieldName = 'selectedDefinitionIds';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class SelectionTable extends Component {
  constructor(props) {
    super(props);

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { reportDefinitionId, selectedViews, data } = this.props;
    const { report_views=[] } = data.reportDefinition || {};
    const selection = selectedViews[reportDefinitionId] || [];

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={ 1 }>Add</Table.HeaderCell>
            <Table.HeaderCell>Report</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            report_views.map(({ id, name }) => (
              <Table.Row key={ id }>
                <Table.Cell>
                  <Checkbox name={ fieldName }
                            value={ id }
                            checked={ selection.includes(id) }
                            onChange={ this.handleChange } />
                </Table.Cell>
                <Table.Cell>{ name }</Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    );
  }

  handleChange(evt, { value, checked }) {
    const { onChange, reportDefinitionId, selectedViews } = this.props;
    const selection = selectedViews[reportDefinitionId] || [];
    const selectionSet                        = new Set(selection);

    if(checked) {
      selectionSet.add(value);
    } else {
      selectionSet.delete(value);
    }

    onChange({ newSelection: [...selectionSet] });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SelectionTable.propTypes = {
  reportDefinitionId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------
export default connectResource(SelectionTable, {
  connectors: {
    reportDefinition: getById(
      reportDefinitions, ({ reportDefinitionId }) => reportDefinitionId
    ),
  }
});
