import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Button, Dropdown } from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ImpersonationButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick  = this.handleClick.bind(this);
  }

  render() {
    const { orgOptions, floated } = this.props;

    if(orgOptions.length === 1) {
      const [ option ]  = orgOptions;
      const { value }   = option;
      return (
        <Button floated={ floated }
                value={ value }
                onClick={ this.handleClick }>Impersonate</Button>
      );
    } else if(orgOptions.length > 1) {
      return (
        <Dropdown style={{ float: floated }} text='Impersonate' button>
          <Dropdown.Menu direction='left'>
            {
              orgOptions.map(({ value, text }) => (
                <Dropdown.Item  key={ value }
                                value={ value }
                                onClick={ this.handleClick }>

                  { text }
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return null;
    }
  }

  async handleClick(evt, { value: id }) {
    evt.preventDefault();

    const { setOrgDomain,
            user,
            organizations,
            create,
            onSuccess } = this.props;

    const { slug }    = organizations[id];

    setOrgDomain(slug);

    const response = await create({
      user_id:          user.id,
      organization_id:  id
    });

    if(!response.errors) {
      onSuccess(response);
    }
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ImpersonationButton.defaultProps = {
  floated: 'right'
};

ImpersonationButton.propTypes = {
  floated:    PropTypes.oneOf(['right', 'left']),

  orgOptions: PropTypes.arrayOf(PropTypes.shape({
    text:   PropTypes.string,
    value:  PropTypes.string
  })).isRequired,

  user:           PropTypes.object.isRequired,
  organizations:  PropTypes.object.isRequired,
  setOrgDomain:   PropTypes.func.isRequired,
  create:         PropTypes.func.isRequired,
  onSuccess:      PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ImpersonationButton;
