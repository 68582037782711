import React from 'react';
import { Header, Segment, Table, Grid, Button }  from 'semantic-ui-react';
import useOrganizationResource    from 'hooks/useOrganizationResource';
import useOrganizationFetcher     from 'hooks/useOrganizationFetcher';
import HouseholdResource          from 'resources/organization/HouseholdResource';
import HouseholdMemberResource          from 'resources/organization/HouseholdMemberResource';
import { Link }   from 'react-router-dom';
import { contentNamespace }       from 'utils/content';
import { relationships } from 'utils/household';
import formattedDate              from 'utils/formattedDate';

import AddButton from './AddButton';
import EditTitleButton from './EditTitleButton';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';

const genderLabels = contentNamespace('organization.clients.gender');

// -----------------------------------------------------
// Sub Components
// -----------------------------------------------------

const LinkedClient = ({ name, clientId }) => {
  if (!clientId) return name;

  const url = `/organization/clients/${clientId} `;

  const onClick = (evt) => {
    evt.preventDefault();
    window.location.href = url;
  };

  return ( 
    <Link to={ url } title={ name } onClick={ onClick }>
      { name }
    </Link> 
  );
};

const householdTitle = ({household_id, household_title}) => {
  return household_id ? `- ${household_title} (ID: ${household_id})` : '';
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Household = ({ client }) => {

  const household = useOrganizationResource(HouseholdResource.detailShape(), { id: client.id, client_id: client.id });

  const householdMembers = useOrganizationResource(HouseholdMemberResource.listShape(), { id: client.id, client_id: client.id });

  const update = useOrganizationFetcher(HouseholdResource.updateShape());
  const destroy = useOrganizationFetcher(HouseholdMemberResource.deleteShape());

  const handleEditTitle = (title) => {
    const params = { id: client.id, client_id: client.id };
    update(params, { title } );
  };

  const handleDelete = async (memberId) => {
    const params = { client_id: client.id, id: memberId };
    destroy(params);
  };

  return (
    <Segment loading={ !client }>
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <Header as='h3' content={ `Family Members ${ householdTitle(household) }` } />
          </Grid.Column>

          { householdMembers.length === 0 &&
            <Grid.Column width={2}>
              <AddButton clientId={ client.id } />
            </Grid.Column>
          }
        </Grid.Row>
      </Grid>

      { householdMembers.length > 0 &&
      <Table basic='very' style={{ marginTop: '1rem' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>DOB</Table.HeaderCell>
            <Table.HeaderCell>Gender</Table.HeaderCell>
            <Table.HeaderCell>Relationship</Table.HeaderCell>
            <Table.HeaderCell>
              <Button.Group size='mini'>
                { household.id &&
                  <EditTitleButton householdTitle={ household.household_title } onUpdate={ handleEditTitle } />
                }

                <AddButton clientId={ client.id } />
              </Button.Group>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

      { householdMembers.map( member =>
          <Table.Row key={ member.id }>
            <Table.Cell>
              <LinkedClient name={ member.name } clientId={ member.client_id } />
            </Table.Cell>
            <Table.Cell>
              { member.dob && formattedDate(member.dob, 'date') }
            </Table.Cell>
            <Table.Cell> 
              { genderLabels(member.gender) }
            </Table.Cell>
            <Table.Cell> 
              { relationships[member.relationship] }
            </Table.Cell>
            <Table.Cell> 
            <Button.Group size='mini'>
              <EditButton clientId={ client.id } member={ member } />

              <DeleteButton memberId={ member.id } onDelete={ handleDelete } />
            </Button.Group>
            </Table.Cell>
          </Table.Row>
        )        
      }
      </Table>
      }

      
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Household.propTypes = {
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Household;
