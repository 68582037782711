import React, { useState, useCallback } from 'react';
import PropTypes                        from 'prop-types';
import { Button }                       from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const RefreshButton = ({ refresh, formId }) => {
  const [ refreshing, setRefreshing ] = useState(false);

  const handleClick = useCallback(async (evt) => {
    evt.preventDefault();
    setRefreshing(true);

    await refresh({ id: formId });
    setRefreshing(false);

  }, [formId, refresh]);

  return (
    <Button basic
            primary
            icon='refresh'
            disabled={ refreshing }
            loading={ refreshing }
            onClick={ handleClick } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

RefreshButton.propTypes = {
  formId:           PropTypes.number.isRequired,
  refresh:          PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default RefreshButton;
