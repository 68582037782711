import { useFetcher }         from 'rest-hooks';
import { useApiErrorContext } from 'components/api/ApiErrorContext';

// -----------------------------------------------------
// Hook Definition
// -----------------------------------------------------

export default function useApiFetcher(resourceShape, throttle=false) {
  const fetchFn       = useFetcher(resourceShape, throttle);
  const { setError }  = useApiErrorContext();

  return (params, body, updateParams) => {
    return  fetchFn(params, body, updateParams)
            .catch(setError);
  };
}
