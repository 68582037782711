import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import { Segment, Header, Grid, Form }  from 'semantic-ui-react';

import Fixed            from 'components/Fixed';
import connectResource  from 'utils/connectResource';
import { getById }      from 'utils/connectors';
import { affiliations } from 'resources/organizationResources';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const actionStateMapping = {
  approve:  'approved',
  reject:   'rejected'
};

const stateActionMapping = {
  approved: 'approve',
  rejected: 'reject'
};

// Always returns an object, allowing us to safely expand the value into
// the component state.
function deriveAffiliationState(action) {
  const state = actionStateMapping[action];
  return state ? { state } : {};
}

function deriveAffiliationAction(state) {
  const action = stateActionMapping[state];
  return action;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class EditFormView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: null,
      form: {
        affiliation_action: null
      }
    };

    this.handleSave   = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    const { state: currentState } = this.state;
    const { data }                = this.props;
    const { state }               = data.affiliation || {};

    if(currentState === null && state !== null && state !== undefined) {
      const affiliation_action = deriveAffiliationAction(state);

      this.setState({ state, form: { affiliation_action } });
    }
  }

  render() {
    const { loading, data } = this.props;
    const affiliation       = data.affiliation || {};
    const { name }          = affiliation;
    const { state }         = this.state;

    return (
      <Segment loading={ loading }>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as='h2'>
                Affiliation request from { name }
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form onSubmit={ this.handleSave }>
                <Form.Group inline>
                  <Form.Radio label='Approve'
                              value='approve'
                              name='affiliation_action'
                              checked={ state === 'approved' }
                              onChange={ this.handleChange } />

                  <Form.Radio label='Reject'
                              value='reject'
                              name='affiliation_action'
                              checked={ state === 'rejected' }
                              onChange={ this.handleChange } />
                </Form.Group>

                <Fixed>
                  <Form.Button  type='submit'
                                loading={ loading }
                                primary>Save</Form.Button>
                </Fixed>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  handleSave(evt) {
    evt.preventDefault();
    const { onUpdate, match: { params: { id } } }  = this.props;
    const { form }      = this.state;

    return onUpdate({ id, ...form });
  }

  handleChange(evt, { name, value }) {
    const { form }  = this.state;
    const nextForm  = { ...form, [name]: value };
    const nextState = deriveAffiliationState(value);

    this.setState({ form: nextForm, ...nextState });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditFormView.defaultProps = {
  loading: false
};

EditFormView.propTypes = {
  loading:  PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,

  data:     PropTypes.shape({
    affiliation: PropTypes.object
  }).isRequired,

  match:    PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(EditFormView, {
  connectors: {
    affiliation: getById(affiliations, ({ match: { params: { id } } }) => id)
  },

  mapDispatchToProps: {
    update: affiliations.update
  }
});
