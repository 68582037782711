import React, { Component }       from 'react';
import { Segment, Header }        from 'semantic-ui-react';
import { reportSummary,
         reportViews,
         reportDefinitions,
         forms,
         fields }                 from 'resources/organizationResources';
import ConditionsViz              from 'components/ConditionsViz';
import connectResource            from 'utils/connectResource';
import { getById, getAllById }    from 'utils/connectors';
import { defaultFilters }         from 'utils/reportDefinitions';
import { isEqual, isEmpty }       from 'lodash';
import indexOnAttribute           from 'utils/indexOnAttribute';
import { reportFormIds,
         deriveFieldSlug }        from 'utils/reportDefinitions';
import { systemFields }           from 'constants/reports/SystemFields';

// -----------------------------------------------------
// Functions
// -----------------------------------------------------

// Returns a list of fields, used to supply options to the conditions editor.
function getConditionFieldsLookup(forms, fields, reportDefinition) {
  const formIds       = reportFormIds(reportDefinition);
  const indexedFields = indexOnAttribute(fields, 'id');
  const indexedForms  = indexOnAttribute(forms, 'id');

  if (!reportDefinition || isEmpty(indexedFields) || isEmpty(indexedForms)) {
    return {};
  }

  const formFields  = formIds
                      .map(id => indexedForms[id])
                      .flatMap(({ id: formId, field_ids }) => (
                        field_ids
                        .map((fieldId) => ({
                            field_name:   deriveFieldSlug('field', fieldId, formId),
                            label:        indexedFields[fieldId].name,
                            formatValue:  value => value
                          }))
                        ));

  const typeSystemFields = systemFields(reportDefinition, formIds)
                           .map((systemField) => ({
                              field_name:  deriveFieldSlug('field', systemField.id, systemField.form),
                              label:       systemField.name,
                              formatValue: value => value
                            }));

  return indexOnAttribute([...formFields, ...typeSystemFields], 'field_name');
}

function ReportConditions({ conditions, lookup, loading, onUpdate }) {
  if( conditions
      && Object.keys(conditions).length > 0
      && !isEqual(conditions, defaultFilters)) {

    return (
      <div style={ { marginTop: '1rem' } }>
        <ConditionsViz conditions={ conditions }
                       fieldsLookup={ lookup }
                       editable={ true }
                       loading={ loading }
                       onUpdate={ onUpdate }
                       />
      </div>
    );
  }

  return null;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Conditions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updatingConditions: false,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  render() {
    const { reportDefinition,
            forms,
            fields }                    = this.props.data    || {};
    const { updatingConditions }        = this.state;

    const { conditions=[] }             = reportDefinition   || {};

    if (!conditions || conditions.length === 0) {
      return null;
    }

    const lookup  = getConditionFieldsLookup(forms, fields, reportDefinition);

    return (
      <Segment>
        <Header as='h3' dividing>Report Conditions</Header>

        <ReportConditions conditions={ conditions }
                          lookup={ lookup }
                          loading={ updatingConditions }
                          onUpdate={ this.handleUpdate }
                           />
      </Segment>
    );
  }

  async handleUpdate({ expression }) {
    const { reportId, viewId, refetch, recalc } = this.props;
    this.setState({ updatingConditions: true });

    const conditions = JSON.stringify(expression);
    localStorage.setItem('overridingConditions', conditions);

    const refetchResponse =
      await refetch(null, {
        id:         viewId,
        report_id:  reportId,
        conditions: conditions
      });

    await recalc(null, {
      id:         reportId,
      report_id:  reportId,
      conditions: conditions
    });

    const { refreshing } = refetchResponse;
    this.setState({ updatingConditions: false });

    return refreshing;
  }

}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(Conditions, {
  key: ({ reportId }) => reportId,
  connectors: {
    reportDefinition: getById(
      reportDefinitions, ({ reportId: id }) => id
    ),
    forms:        getAllById(forms, ()=>({}), true),
    fields:       getAllById(fields, ()=>({}), true),
  },
  mapDispatchToProps: {
    refetch: reportViews.actions.refetch,
    recalc: reportSummary.actions.show,
  }
});
