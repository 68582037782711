import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import Select               from 'react-select'; // Temporary solution until Semantic UI Dropdown get's fixed (https://github.com/Semantic-Org/Semantic-UI-React/issues/4375)

import cast                 from 'utils/cast';
import Field                from 'components/forms/Field';
import RadioButtons         from './RadioButtons';
import ApiResourceSelect    from './ApiResourceSelect';

import { find }             from 'lodash';

//---------------------------------------------------------
// Helpers
//---------------------------------------------------------

const otherOption = Object.freeze({ text: 'Other', value: '__other__' });

const otherFieldTypeFor = {
  'numeric':        'number',
  'numeric-array':  'number',
  'string':         'freeform-short',
  'string_array':   'freeform-short',
  'boolean':        'checkbox',
  'boolean_array':  'checkbox',
  'date':           'date'
};

function extendOptions(options, allowOther) {
  const extendedOptions = [ ...options ];

  if(allowOther) {
    extendedOptions.push(otherOption);
  }

  return extendedOptions;
}

function shouldSelectOther(props) {
  const { metadata: { allow_other }, dataType, value, options } = props;
  const values    = options.map(({ value }) => cast(value, dataType));
  const hasValue  = value !== null && value !== undefined && value !== '';

  return allow_other && hasValue && !values.includes(value);
}

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: '2',
  })
};

//---------------------------------------------------------
// Component Definition
//---------------------------------------------------------

const SelectOne = (props) => {
  const { isDisabled,
          value,
          name,
          options,
          metadata: { allow_other, display_type, api_endpoint },
          dataType,
          answers,
          parametersMapping,
          onChange } = props;

  const initialSelectOther                    = shouldSelectOther(props);
  const [otherIsSelected, setOtherIsSelected] = useState(initialSelectOther);

  const extendedOptions = extendOptions(options, allow_other);
  const selectedValue   = otherIsSelected ? otherOption.value : value;

  // We have to do this, as all values in a select are strings.  If the
  // selectValue is a number, it will not match its string equivalent.
  const stringValue = (selectedValue === null || selectedValue === undefined)
                      ? ''
                      : selectedValue.toString();

  const handleOtherChange = (evt, { value }) => {
    allow_other && otherIsSelected && onChange(evt, { name, value });
  };

  const handleSelectChange = (evt, { value }) => {
    const isOtherValue    = (allow_other && value === otherOption.value);
    const nextValue       = isOtherValue ? '' : value;

    setOtherIsSelected(isOtherValue);
    onChange(evt, { name, value: nextValue });
  };

  // changes needed to comply with react-select format
  const selectExtendedOptions = extendedOptions.map(({ value, text }) => ( { value: value, label: text } ));
  const selectDefaultValue    = find(selectExtendedOptions, {value: stringValue }) || null;
  const handleRSChange        = (selection) => {
    const newValue =  (selection === null || selection === undefined)
                      ? { value: null }
                      : selection;

    handleSelectChange(null, newValue);
  };

  return(
    <>
      {
        display_type === 'radio'
        ? <RadioButtons value={ stringValue }
                        disabled={ isDisabled }
                        options={ extendedOptions }
                        onChange={ handleSelectChange } />

        : api_endpoint
          ? <ApiResourceSelect  value={ value }
                                disabled={ isDisabled }
                                apiEndpoint={ api_endpoint }
                                parametersMapping={ parametersMapping }
                                answers={ answers }
                                onChange={ handleSelectChange } />

          : <Select
                    value={ selectDefaultValue }
                    isDisabled={ isDisabled }
                    isClearable={ true }
                    isSearchable={ true }
                    options={ selectExtendedOptions }
                    onChange={ handleRSChange }
                    styles={ customStyles }
                    theme={(theme) => ({
                                        ...theme,
                                        borderWidth: '1px',
                                        colors: {
                                          ...theme.colors,
                                          primary50: '#8baeaa',
                                          primary25: '#8baeaa',
                                          primary: '#4a7c77',
                                        },
                    })}
                  />
      }

      {
        allow_other && otherIsSelected &&
        <div style={{ marginTop: '1rem' }}>

          <Field  name={ name }
                  value={ value }
                  disabled={ isDisabled }
                  label='Please specify "Other"'
                  fieldType={ otherFieldTypeFor[dataType] }
                  dataType={ dataType }
                  onChange={ handleOtherChange } />
        </div>
      }
    </>
  );
};

//---------------------------------------------------------
// PropTypes
//---------------------------------------------------------

SelectOne.defaultProps = {
  isDisabled: false,
  value:      '',
  options:    [],
  metadata:   {}
};

SelectOne.propTypes = {
  isDisabled: PropTypes.bool,
  value:    PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),

  name:     PropTypes.string,
  options:  PropTypes.arrayOf(PropTypes.shape({
    text:   PropTypes.string,
    value:  PropTypes.any,
  })),

  metadata: PropTypes.object,
  dataType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

//---------------------------------------------------------
// Exports
//---------------------------------------------------------

export default SelectOne;
