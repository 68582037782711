import React        from 'react';
import PropTypes    from 'prop-types';
import { Segment }  from 'semantic-ui-react';

import FormFieldStats from './FormFieldStats';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const StatusPanel = (props) => {
  return (
    <Segment>
      <FormFieldStats { ...props } />
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

StatusPanel.propTypes = {
  reportDefinition: PropTypes.shape({
    name:         PropTypes.string,
    description:  PropTypes.string,
    fields:       PropTypes.array,
    conditions:   PropTypes.object
  }).isRequired,

  forms:              PropTypes.array.isRequired,
  fields:             PropTypes.array.isRequired,
  formCalculations:   PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default StatusPanel;
