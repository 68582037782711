import urljoin from 'url-join';

export function newPath(match) {
  return urljoin(match.path, 'new');
}

export function newRoute(match) {
  return urljoin(match.path, 'new');
}

export function showPath(match, id) {
  return urljoin(match.path, id);
}

export function showRoute(match) {
  return urljoin(match.path, ':id');
}

export function showNestedRoute(match) {
  return urljoin(match.path, ':id/:nestedResource/:nestedId');
}

export function editPath(match, id) {
  return urljoin(match.path, id, 'edit');
}

export function editRoute(match) {
  return urljoin(match.path, ':id/edit');
}
