import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Grid, Segment }    from 'semantic-ui-react';
import isEqual              from 'lodash/isEqual';

import { getById, getAllById }  from 'utils/connectors';
import connectResource          from 'utils/connectResource';
import {reportDefinitions,
        forms,
        publicFormAnswers,
        fields,
        users,
        calculations } from 'resources/organizationResources';

import { reportFormIds }  from 'utils/reportDefinitions';

import StatusPanel  from './StatusPanel';
import WizardPanel  from './WizardPanel';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const nonPublicReportTypes = ['client_forms', 'internal_forms', 'fields'];

const defaultDefinition = {
  name:           null,
  description:    null,
  fields:         [],
  conditions:     { and: [] },
  default_order:  null
};

function formsByReportType(reportDefinition, forms, publicForms) {
  const { report_type } = reportDefinition || {};
  if (report_type === 'query') {
    return [...new Set([...forms, ...publicForms])];

  } else {
    return nonPublicReportTypes.includes(report_type) ? forms : publicForms;
  }
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changes:          {},
      formCalculations: {}
    };

    // Cache empty calculations to avoid refetch
    this.emptyCalculations  = {};

    this.handleChange       = this.handleChange.bind(this);
    this.handleFormChange   = this.handleFormChange.bind(this);
    this.handleSave         = this.handleSave.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data }    = this.props;
    const { changes } = this.state;

    const { reportDefinition } = data;

    const reportDefinitionChanged =
      !isEqual(reportDefinition, prevProps.reportDefinition);

    const changesChanged =
      !isEqual(changes, prevState.changes);

    if(reportDefinitionChanged || changesChanged) {
      const currentReportDefinition = {
        ...defaultDefinition,
        ...reportDefinition,
        ...changes
      };

      const formIds = (currentReportDefinition.report_type === 'fields')
                      ? data.forms.map(({id}) => id)
                      : reportFormIds(currentReportDefinition);

      await this.updateFormCalculations(formIds);
    }
  }

  render() {
    const { changes, formCalculations } = this.state;
    const { data }                      = this.props;
    const reportDefinition              = data.reportDefinition || {};
    const forms                         = data.forms            || [];
    const publicForms                   = data.publicForms      || [];
    const fields                        = data.fields           || [];
    const users                         = data.users            || [];

    // Apply current changes to reportDefinition
    const currentReportDefinition = {
      ...defaultDefinition,
      ...reportDefinition,
      ...changes
    };

    const selectableForms =
      formsByReportType(currentReportDefinition, forms, publicForms);

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={ 11 }>
            <Segment>
              <WizardPanel  reportDefinition={ currentReportDefinition }
                            forms={ selectableForms }
                            fields={ fields }
                            users={ users }
                            formCalculations={ formCalculations }
                            onChange={ this.handleChange }
                            onFormChange={ this.handleFormChange }
                            onSave={ this.handleSave } />
            </Segment>
          </Grid.Column>

          <Grid.Column width={ 5 }>
            <StatusPanel  reportDefinition={ currentReportDefinition }
                          forms={ forms }
                          fields={ fields }
                          formCalculations={ formCalculations } />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  handleSave() {
    const { data, onCreate, onUpdate } = this.props;

    const reportDefinition  = data.reportDefinition || {};
    const { id }            = reportDefinition;
    const isNew             = id === null || id === undefined;
    const { changes }       = this.state;
    const parameters        = { ...reportDefinition, ...changes };

    if(isNew) {
      onCreate(parameters);
    } else {
      onUpdate(parameters, { id });
    }
  }

  handleChange(changes) {
    const { changes: currentChanges } = this.state;

    this.setState({ changes: { ...currentChanges, ...changes } });
  }

  async handleFormChange(formId) {
    if(formId !== null && formId !== undefined) {
      await this.updateFormCalculations([ formId ]);
    }
  }

  // updates the state with all calculations used by each of the forms referenced
  // in the report definition.
  async updateFormCalculations(formIds) {
    const { fetchCalculations } = this.props;
    const { formCalculations }  = this.state;
    const fetchedCalculations   = {};

    for (const id of formIds) {

      if( !this.emptyCalculations[id]
          && !formCalculations[id]
          && !fetchedCalculations[id]) {

        const calculations = await fetchCalculations(null, { form_id: id });

        if(calculations.length > 0) {
          fetchedCalculations[id] = calculations;
        } else {
          this.emptyCalculations[id] = true;
        }
      }
    }

    if(Object.keys(fetchedCalculations).length > 0) {
      this.setState({
        formCalculations: { ...formCalculations, ...fetchedCalculations }
      });
    }
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormView.propTypes = {
  data: PropTypes.shape({
    reportDefinition: PropTypes.object,
    forms:            PropTypes.array,
    publicForms:      PropTypes.array,
    fields:           PropTypes.array
  }).isRequired,

  onCreate: PropTypes.func,
  onUpdate: PropTypes.func
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(FormView, {
  connectors: {
    reportDefinition: getById(
      reportDefinitions, ({ match: { params: { id } } }) => id
    ),
    forms:        getAllById(forms, ()=>({}), true),
    publicForms:  getAllById(publicFormAnswers, ()=>({}), true),
    fields:       getAllById(fields, ()=>({}), true),
    users:        getAllById(users)
  },

  mapDispatchToProps: { fetchCalculations: calculations.actions.index }
});
