import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Segment, Dropdown,
         Table, Header, Icon }  from 'semantic-ui-react';
import { computeSelectedFields }      from 'utils/reportDefinitions';
import { systemFields }               from 'constants/reports/SystemFields';
import { windowFunctionType }         from 'constants/reports/FunctionTypes';
import indexOnAttribute               from 'utils/indexOnAttribute';

import FunctionSelector from './FunctionSelector';
import WindowFilterSettings from './WindowFilterSettings';
import Record           from './Record';
import EditModal        from './EditModal';
import FiltersModal     from './FiltersModal';
import { find } from 'lodash';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const excludedFieldTypes = ['presentation'];
const reportTypesWithFormSelection = ['client_forms', 'public_forms', 'internal_forms'];

function formFieldsForReportType(reportType, form, report, allFields){
  switch (reportType) {
    case 'system':
      return [];
    case 'fields':
      return fieldsForFieldReport(allFields);
    case 'query':
      return fieldsForFieldQueryOnReport(report, allFields);
    default:
      return formFieldsForCurrentForm(form, allFields);
  }
}

function fieldsForFieldReport(fields) {
  return  fields
          .filter((field) => field && !excludedFieldTypes.includes(field.field_type))
          .sort(({ name: a }, { name: b }) => a > b ? 1 : -1);
}

// Get all non-excluded type fields for the currently selected form.
function formFieldsForCurrentForm(currentForm, fields) {
  if(currentForm === null ||  currentForm === undefined) {
    return [];
  }

  const indexedFields = indexOnAttribute(fields, 'id');

  return  currentForm
          .field_ids
          .map(id => (indexedFields[id]))
          .filter((field) => field && !excludedFieldTypes.includes(field.field_type));
}

function fieldsForFieldQueryOnReport(currentReport, fields) {
  if(currentReport === null ||  currentReport === undefined) {
    return [];
  }

  const indexedFields = indexOnAttribute(fields, 'id');

  return  currentReport
          .fields
          .map(({field}) => (indexedFields[field]))
          .filter((field) => field && !excludedFieldTypes.includes(field.field_type));
}

// Returns all calculations associated with the currently selected form.
function calculatedFieldsForCurrentForm(reportType, currentForm, formCalculations) {
  if (reportType === 'fields') {
    return Object.entries(formCalculations).map(([formId, calculations]) =>
              calculations.map((calculation) =>
                ({...calculation, formId: formId})
              )
            ).flat();
  }

  if(currentForm === null ||  currentForm === undefined) {
    return [];
  }

  const { id } = currentForm;
  return formCalculations[id] || [];
}

function fieldOptionsForReportType(reportDefinition, form, reportTarget, formCalculations, formFields) {
  if (reportDefinition.report_type === 'system') {
    return fieldOptionsForSystemReport(reportDefinition);
  } else if (reportDefinition.report_type === 'household') {
    return fieldOptionsForHouseholdReport(reportDefinition);
  } else if (reportDefinition.report_type === 'query') {
    return fieldOptionsForQueryOnReport(reportTarget, formFields, formCalculations);
  } else {
    return fieldOptionsForCurrentForm(form, reportDefinition, formCalculations, formFields);
  }

}

function fieldOptionsForSystemReport(reportDefinition) {
  const typeSystemFields  = systemFields(reportDefinition);
  const fieldOptions      = [
    fieldOptionsHeader('system'),
    ...typeSystemFields.map( ({id, name}) => ({ text: name, id: id, type: 'field' }))
  ];

  return fieldOptions.map( (option, index) => ({ key: index, value: index, ...option }));
}

function fieldOptionsForHouseholdReport(reportDefinition) {
  const typeSystemFields  = systemFields(reportDefinition);
  const fieldOptions      = [
    fieldOptionsHeader('household'),
    ...typeSystemFields.map( ({id, name}) => ({ text: name, id: id, type: 'field' }))
  ];

  return fieldOptions.map( (option, index) => ({ key: index, value: index, ...option }));
}

function fieldOptionsForQueryOnReport(reportTarget, formFields, formCalculations) {
  const { fields } = reportTarget || {};
  const querySystemFields  = systemFields(reportTarget);

  const fieldOptions = fields
                       .map( ({field, type, form, agg, label}) => (
                        {
                          text: label || getFieldName(field, form, type, formFields, querySystemFields, formCalculations),
                          id: field,
                          type: type,
                          formid: form,
                          fieldlabel: label,
                          fieldagg: agg
                        }
                       ));

  return fieldOptions.map( (option, index) => ({ key: index, value: index, ...option }));
}

function getFieldName(fieldId, formId, type, formFields, systemFields, formCalculations) {

  const field = type === 'field' ?
                  find(formFields, ['id', fieldId]) || find(systemFields, ['id', fieldId]) :
                  find(formCalculations[formId], ['id', fieldId]);

  return field ? field.name : null;
}

function fieldOptionsForCurrentForm(currentForm, reportDefinition, formCalculations, formFields) {
    const typeSystemFields  = systemFields(reportDefinition);
    const calculatedFields  = calculatedFieldsForCurrentForm(reportDefinition.report_type, currentForm, formCalculations);
    const fieldOptions      = [
      fieldOptionsHeader('fields'),
      ...formFields.map( ({id, name}) => ({ text: name, id: id, type: 'field' })),
      fieldOptionsHeader('system'),
      ...typeSystemFields.map( ({id, name}) => ({ text: name, id: id, type: 'field' }))
    ];

    if (calculatedFields.length > 0) {
      fieldOptions.push(
        fieldOptionsHeader('calculations'),
        ...calculatedFields.map( ({id, name, formId}) => ({ text: name, id: id, type: 'calculation', form: formId }))
      );

    }

    return fieldOptions.map( (option, index) => ({ key: index, value: index, ...option }));
}

function fieldOptionsHeader(type) {
  return { text: type.toUpperCase(), disabled: true };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class SelectionTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // references a field record
      currentFieldIndex:  null,
      showEditModal:      false,
      showFiltersModal:   false
    };

    this.handleModalOpen  = this.handleModalOpen.bind(this);
    this.handleFiltersModalOpen  = this.handleFiltersModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleEditField = this.handleEditField.bind(this);
    this.handleEditWindow = this.handleEditWindow.bind(this);
  }

  render() {
    const { reportDefinition,
            form,
            allFields,
            formCalculations,
            functionType,
            reportTarget,
            onChange,
            onAddField,
            onAddAllFields,
            onFunctionTypeChange,
            ...forwardProps } = this.props;

    const { currentFieldIndex,
            showEditModal,
            showFiltersModal }   = this.state;

    const windowFunction  = reportDefinition.window;
    const formFields      = formFieldsForReportType(reportDefinition.report_type, form, reportTarget, allFields);
    const fieldOptions    = fieldOptionsForReportType(reportDefinition, form, reportTarget, formCalculations, formFields);

    const selectedFields = computeSelectedFields({
                              currentFormId: (form ? form.id : null),
                              fields: formFields,
                              reportDefinition,
                              reportTargetDefifinition: reportTarget,
                              formCalculations
                            });

    return (
      <Segment>
        { reportTypesWithFormSelection.includes(reportDefinition.report_type) &&
          <Header> { form.name } </Header>
        }
        <Dropdown text='Add a Field to the report'
                  className='icon'
                  icon='add'
                  search
                  fluid
                  labeled
                  button
                  basic
                  selectOnBlur={ false }
                  options={ fieldOptions }
                  formid={ (form && form.id) || null }
                  onChange={ onAddField } />

        { selectedFields.length > 0 &&
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Field Name</Table.HeaderCell>
                <Table.HeaderCell>Function</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>
                  { functionType === windowFunctionType &&
                    <WindowFilterSettings windowFunction={ windowFunction }
                                          formId={ form.id }
                                          onChange={this.handleFiltersModalOpen }/>
                  }
                  <FunctionSelector functionType={ functionType }
                                    onChange={ onFunctionTypeChange }/>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                selectedFields.map((record, index) => (
                  <Record key={ index }
                          index={ index }
                          functionType={ functionType }
                          selectedFields={ selectedFields }
                          windowFunction={ windowFunction }
                          onEditFunction={ this.handleModalOpen }
                          onDuplicateField={ onAddField }
                          onChange={ onChange } />
                ))
              }
            </Table.Body>
          </Table>
        }

        { selectedFields.length === 0 &&
          <p style={{margin: '60px 0', textAlign: 'center'}}>
            <Icon name='add circle' size='big' /><br /><br />
            <a href="#add-all" onClick={() => onAddAllFields(form ? form.id : null ) }>click here to add all fields at once</a><br />
            or use the dropdown above to add them individually
          </p>
        }

        { showEditModal &&
          <EditModal  opened={ showEditModal }
                      index={ currentFieldIndex }
                      functionType={ functionType }
                      reportDefinition={ reportDefinition }
                      fields={ allFields }
                      selectedFields={ selectedFields }
                      formCalculations={ formCalculations }
                      {...forwardProps}
                      onChange={ this.handleEditField }
                      onClose={ this.handleModalClose }
                      />
        }
        { showFiltersModal &&
          <FiltersModal opened={ showFiltersModal }
                      form={ form.id}
                      reportDefinition={ reportDefinition }
                      fields={ allFields }
                      selectedFields={ selectedFields }
                      formCalculations={ formCalculations }
                      {...forwardProps}
                      onChange={ this.handleEditWindow }
                      onClose={ this.handleModalClose }
                      />
        }
      </Segment>
    );
  }

  handleFiltersModalOpen(){
    this.setState({
      showFiltersModal:     true
    });
  }

  handleModalOpen({currentFieldIndex}){
    this.setState({
      currentFieldIndex: currentFieldIndex,
      showEditModal:     true
    });
  }

  handleModalClose(){
    this.setState({
      currentFieldIndex: null,
      showEditModal:     false,
      showFiltersModal:  false
    });
  }

  handleEditField(nextSelection){
    const { form, onChange } = this.props;

    this.setState({
      currentFieldIndex: null,
      showEditModal:     false
    });

    onChange({
      currentFormId: form && form.id,
      ...nextSelection
    });
  }

  handleEditWindow(window){
    const { onChangeWindow } = this.props;

    onChangeWindow(window);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SelectionTable.propTypes = {
  reportDefinition: PropTypes.object,
  functionType:     PropTypes.string,

  form:   PropTypes.shape({
    id:   PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),

  onChange:             PropTypes.func.isRequired,
  onFunctionTypeChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SelectionTable;
