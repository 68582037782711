import React      from 'react';
import PropTypes  from 'prop-types';
import { Label }  from 'semantic-ui-react';

// -----------------------------------------------------
// Styles
// -----------------------------------------------------

const labelStyle = {
  textAlign:  'center',
  display:    'inline-block',
  width:      '11ch'
};

const stateColorLabelMapping = {
  approved: ['green', 'Approved'],
  rejected: ['red',   'Rejected'],
  pending:  ['blue',  'Pending']
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const StateIndicator = ({ state, size }) => {
  const [color, label] = stateColorLabelMapping[state];

  return (
    <Label size={ size } style={ labelStyle } color={ color } >
      { label }
    </Label>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

StateIndicator.defaultProps = {
  size: 'mini'
};

StateIndicator.propTypes = {
  size:   PropTypes.string,
  state:  PropTypes.oneOf(
    Object.keys(stateColorLabelMapping)
  ).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default StateIndicator;
