import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class HouseholdResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/clients/:client_id/households';

  id                = undefined;
  household_id      = undefined;
  household_title   = '';

  pk() {
    return this.id && this.id.toString();
  }
}
