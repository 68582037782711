import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class FormResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/forms';

  id              = undefined;
  name            = '';
  description     = '';
  redirect_url    = '';
  logic           = [];
  form_fields     = [];
  is_public       = false;
  public_forms    = [];
  affiliation_ids = [];
  form_type       = '';
  updated_at      = null;
  created_at      = null;
  is_owned        = false;
  calculations    = [];

  pk() {
    return this.id && this.id.toString();
  }
}
