import React                        from 'react';
import { Grid, Segment }            from 'semantic-ui-react';

import useApiResource                   from 'hooks/useApiResource';
import useApiFetcher                    from 'hooks/useApiFetcher';
import UserAccountResource              from 'resources/UserAccountResource';
import ConfidentialityFieldsResource    from 'resources/ConfidentialityFieldsResource';

import Form from './Form';

// -----------------------------------------------------
// Config
// -----------------------------------------------------

const style = {
  grid: { height: '100%' }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ConfidentialityAgreement = ({ children }) => {
  const userAccount                 = useApiResource(UserAccountResource.singletonDetailShape());
  const updateUserAccount           = useApiFetcher(UserAccountResource.singletonUpdateShape());
  const fields                      = useApiResource(ConfidentialityFieldsResource.listShape());

  const handleSubmit = (values) => {
    updateUserAccount({}, { confidentiality_agreement: values });
  };

  if(userAccount.executed_confidentiality_agreement) {
    return children;
  } else {
    return (
      <Grid verticalAlign='middle' centered columns='2' style={ style.grid }>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <h2 className='is-caps'>STATEMENT OF CONFIDENTIALITY</h2>
              <p>
                <span className='is-caps'>
                  I AGREE TO MAINTAIN THE STRICT CONFIDENTIALITY OF INFORMATION
                  OBTAINED THROUGH&nbsp;
                </span>
                the CoactionNet system. This information will be used only for
                legitimate client services and administration of the organization
                granting access. Any breach of confidentiality will result in immediate
                termination of participation in CoactionNet.
              </p>

              <h2 className='is-caps'>USER’S RESPONSIBILITY STATEMENTS</h2>
              <p>
                Each user requires a unique username and password, which is to be kept
                private. Use of another user’s username (account) is grounds for
                immediate termination from CoactionNet. Any failure to uphold the
                confidentiality standards set forth below is grounds for immediate
                termination from CoactionNet.
              </p>

              <Form fields={ fields } onSubmit={ handleSubmit } />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ConfidentialityAgreement;
