import urljoin  from 'url-join';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function resourceUrl(basePath, path, pathVariables) {
  const safePathVariables = pathVariables || {};
  const apiPath = Object
                  .entries(safePathVariables)
                  .reduce((path, [name, value]) => (
                    path.replace(`:${name}`, value)
                  ), urljoin(basePath, path));

  return new URL(urljoin(process.env.REACT_APP_API_HOST, apiPath));
}
