import React          from 'react';
import PropTypes      from 'prop-types';


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ResponsesPagination = ({ page, responseCount, onClick}) => {

  return (
    <div className='pagination' style={{ marginTop: '1rem', textAlign: 'center' }}>
      <button onClick={() => onClick(page - 1)} disabled={page === 1}>
        Previous
      </button>
      <span style={{ padding: '0 1rem' }} >Page {page}</span>
      <button onClick={() => onClick(page + 1)} disabled={responseCount === 0}>
        Next
      </button>
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ResponsesPagination.defaultTypes = {
  page:  1,
};

ResponsesPagination.propTypes = {
  page:           PropTypes.number.isRequired,
  responseCount: PropTypes.number.isRequired,
  onClick:        PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ResponsesPagination;
