import React, { useState } from 'react';
import PropTypes           from 'prop-types';
import { Form as SUIForm } from 'semantic-ui-react';

import Field from './Field';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Form = ({ fields, onSubmit }) => {
  const [response, setResponse]       = useState({});
  const [isCompleted, setIsCompleted] = useState(false);

  const handleChange = (evt, { checked, name }) => {
    const nextResponse  = checked
                          ? { ...response, [name]: true }
                          : (delete response[name]) && response;

    const nextIsCompleted = Object.entries(nextResponse).length === fields.length;

    setIsCompleted(nextIsCompleted);
    setResponse(nextResponse);
  };

  const handleSubmit = () => onSubmit(response);

  return (
    <SUIForm onSubmit={ handleSubmit }>
      {
        fields.map(({ name, label }) => (
          <Field  key={ name }
                  name={ name }
                  label={ label }
                  onChange={ handleChange } />
        ))
      }

      <SUIForm.Button type='submit'
                      disabled={ !isCompleted }
                      primary>Agree</SUIForm.Button>
    </SUIForm>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Form.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name:   PropTypes.string,
    label:  PropTypes.string
  })).isRequired,

  onSubmit: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Form;
