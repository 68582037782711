import React                              from 'react';
import PropTypes                          from 'prop-types';
import { Segment, Header, Message, List } from 'semantic-ui-react';
import { Link }                           from 'react-router-dom';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const OrganizationList = ({ loading, organizations, userOrganizationIds, userActiveOrganizationIds }) => {
  const userOrganizations = organizations.filter(({ id }) => (
    userOrganizationIds.includes(id)
  ));

  return (
    <Segment loading={ loading }>
      <Header as='h3'>Organization Memberships</Header>

      {
        organizations.length
        ? <List divided relaxed='very'>
            {
              userOrganizations.map(({ id, name }) => (
                <List.Item key={ id }>
                  <List.Icon name='building outline' />
                  <List.Content>
                    <Link to={ `/admin/organizations/${id}`} >{ name }</Link>
                    { !userActiveOrganizationIds.includes(id) && <span style={{ color: 'green', marginLeft: '10px' }}>(Archived)</span> }
                  </List.Content>
                </List.Item>
              ))
            }
          </List>
        : <Message info>
            This users is associated with no organizations.
          </Message>
      }
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

OrganizationList.defaultProps = {
  loading: false
};

OrganizationList.propTypes = {
  organizations:        PropTypes.array.isRequired,
  userOrganizationIds:  PropTypes.array.isRequired,
  userActiveOrganizationIds:  PropTypes.array.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default OrganizationList;
