import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table }            from 'semantic-ui-react';

import BodyHeaderCell       from './BodyHeaderCell';
import BodyCell             from './BodyCell';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class BodyRow extends Component {
  render() {
    const { entity, actions, privileges, disabled, onChange } = this.props;

    return (
      <Table.Row>
        <BodyHeaderCell entity={ entity }
          actions={ actions }
          privileges={ privileges }
          disabled={ disabled }
          onChange={ onChange } />

        {
          actions.map((action) => {
            return (
              <BodyCell key={ `${entity}-${action}` }
                entity={ entity }
                action={ action }
                privileges={ privileges }
                disabled={ disabled }
                onChange={ onChange } />
            );
          })
        }
      </Table.Row>
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

BodyRow.propTypes = {
  actions:    PropTypes.arrayOf(PropTypes.string).isRequired,
  entity:     PropTypes.string.isRequired,
  privileges: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired,
  disabled:   PropTypes.bool,
  onChange:   PropTypes.func
};

BodyRow.defaultProps = {
  disabled: true,
  onChange: () => {}
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default BodyRow;
