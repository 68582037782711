import { overrideReducer } from 'utils/reducers';

// -----------------------------------------------------
// Get
// -----------------------------------------------------

export const get = {
  method: 'GET',
  path:   '/',
  response: (state, action) => {
    return {
      ...overrideReducer(state, action),
      error:    undefined,
      _indexed: (new Date()).getTime()
    };
  },
  error: () => {
    return {error : true};
  }
};

// -----------------------------------------------------
// Destroy
// -----------------------------------------------------

export const destroy = {
  method:   'DELETE',
  path:     '/',
  response: () => {
    return null;
  }
};
