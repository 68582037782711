export const numericType      = 'numeric';
export const dateType         = 'date';
export const timeType         = 'time';
export const calculationTypes = [numericType, dateType, timeType];

export const intervalParameterType      = 'interval';
export const dateRelativeParameterType  = 'date_relative';

export const currentDateParameterValue  = 'current_date';

export const modalTitleByTypeForCreate = {
  [numericType]:  'Add a numeric calculation.',
  [dateType]:     'Add a date calculation.',
  [timeType]:     'Add a time calculation.'
};

export const modalTitleByTypeForUpdate = {
  [numericType]:  'Edit a numeric calculation.',
  [dateType]:     'Edit a date calculation.',
  [timeType]:     'Edit a time calculation.',
};

export const currentDateParameterVariable = {
  parameter_type: dateRelativeParameterType,
  value:          currentDateParameterValue
};

export const intervals = {
  [dateType]: [
    { text: 'Days',   value: 'days' },
    { text: 'Weeks',  value: 'weeks' },
    { text: 'Months', value: 'months' },
    { text: 'Years',  value: 'years' }
  ],
  [timeType]: [
    { text: 'Hours',   value: 'hours' },
    { text: 'Minutes', value: 'minutes' },
    { text: 'hh:mm',   value: 'time' },
    { text: 'h.mm',    value: 'time_decimal' },
  ],
};
