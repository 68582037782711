import React      from 'react';
import { List }   from 'semantic-ui-react';

import MasterDetail                               from 'components/MasterDetail';
import scopedToRole                               from 'hocs/scopedToRole';
import withPagination                             from 'hocs/withPagination';
import withSearch                                 from 'hocs/withSearch';
import { fields, cloneRecords }                   from 'resources/organizationResources';
import fieldTypes                                 from 'resources/fieldTypes';
import connectResource                            from 'utils/connectResource';
import { getPageAllById, getById, getSingleton }  from 'utils/connectors';
import { sortByAttributes }                       from 'utils/sortComparators';
import { textSubstringFilter }                    from 'utils/recordFilters';

import SideCardView   from '../SideCardView';
import DetailView     from 'components/fields/DetailView';
import FormView       from 'components/fields/FormView';

// -----------------------------------------------------
// Connected Form View Definition
// -----------------------------------------------------

const ConnectedFormView = connectResource(FormView, {
  connectors: {
    fieldTypes: getSingleton(fieldTypes),
    selected:   getById(fields, (props)=>props.match.params.id)
  },
  mapDispatchToProps : { ...fields.actions }
});

// -----------------------------------------------------
// Connected Detail View Definition
// -----------------------------------------------------

const ScopedDetailView = scopedToRole(DetailView, { role: 'organization' });

const ConnectedDetailView = connectResource(ScopedDetailView, {
  fieldTypes: getSingleton(fieldTypes),
  selected:   getById(fields, (props)=>props.match.params.id)
});

// -----------------------------------------------------
// List Item Definition
// -----------------------------------------------------

const ListItem = ({record}) => (
  <List.Header>{ record.name }</List.Header>
);

// -----------------------------------------------------
// App Component Definition
// -----------------------------------------------------

class App extends React.Component {
  render() {

    const { data, destroy, update, create, clone } = this.props;

    return (
      <MasterDetail masterItemComponent={ListItem}
        detailComponent={ConnectedDetailView}
        sideCardComponent={SideCardView}
        formComponent={ConnectedFormView}
        records={ data.fields || [] }
        onDelete={ id => destroy(null, {id}) }
        onUpdate={ record => update(record, {id: record.id}) }
        onCreate={ record => create(record) }
        onClone={ id => clone(null, {cloneable_type: 'fields', cloneable_id: id}) }
        resource='fields'
        versioned={true}
        listSort={ sortByAttributes('name') }
        filterRecord={textSubstringFilter('name')}
        {...this.props}/>
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    fields: getPageAllById(fields)
  },
  mapDispatchToProps: {
    ...fields.actions,
    ...cloneRecords.actions
  }
});

export default withSearch(withPagination(connectedComponent));
