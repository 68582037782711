import React from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import connectResource from '../../../utils/connectResource';
import { getById } from '../../../utils/connectors';
import { pricing_tiers } from '../../../resources/adminResources';

class DetailView extends React.Component {
  render() {
    const record = this.props.data.selected||{};    
    return (
      <Segment loading={this.props.loading}>
        {this.props.api_errors && <Redirect to='./'/>}
        <Header as='h3'>{record.name}</Header>
        <Table basic='very' celled collapsing>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as='h4'>Rate</Header>
              </Table.Cell>
              <Table.Cell>{record.rate_cents}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4'>Billing Period</Header>
              </Table.Cell>
              <Table.Cell>{record.billing_period}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4'>Features</Header>
              </Table.Cell>
              <Table.Cell>{record.features}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default connectResource(DetailView, {
  selected : getById(pricing_tiers, (props)=>props.match.params.id)
});