import React, { Fragment, Component } from 'react';
import PropTypes                      from 'prop-types';

import { Button, Popup }     from 'semantic-ui-react';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Change calculations order';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class OrderButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick    = this.handleClick.bind(this);
  }

  render() {
    const Btn = <Button icon='bars'
                        size='mini'
                        basic
                        color='black'
                        onClick={ this.handleClick } />;

    return (
      <Fragment>
        <Popup  trigger={ Btn }
                content={ title }
                position='top center' />
      </Fragment>
    );
  }

  handleClick() {
    const { onClick } = this.props;
    onClick();
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

OrderButton.propTypes = {
  calculation:  PropTypes.shape({
    id: PropTypes.number
  }),
  onClick:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default OrderButton;
