import React            from 'react';
import PropTypes        from 'prop-types';
import { List, Radio }  from 'semantic-ui-react';
import uniqueId         from 'utils/uniqueId';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const RadioButtons = ({ value: selected, options, disabled, onChange }) => {
  const groupName = uniqueId();

  return (
    <List horizontal>
      {
        options.map(({ text, value }) => (
          <List.Item key={ value }>
            <Radio  name={ groupName }
                    value={ value }
                    disabled={ disabled }
                    checked={ value === selected }
                    label={ text }
                    onChange={ onChange } />
          </List.Item>
        ))
      }
    </List>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

RadioButtons.defaultProps = {
  value:    '',
  options:  [],
  disabled: false
};

RadioButtons.propTypes = {
  disabled: PropTypes.bool,
  value:    PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),

  options:  PropTypes.arrayOf(PropTypes.shape({
    text:   PropTypes.string,
    value:  PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })),
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default RadioButtons;
