import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import RichTextEditor       from 'react-rte';

import FieldInserter        from './FieldInserter';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class SubjectEditor extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // handlers
  handleChange(evt, { value }) {
    const { onChange } = this.props;
    onChange(value);
  }

  // rendering
  render() {
    const { fields, value, onChange } = this.props;

    const Inserter = <FieldInserter key='subjectInserter'
                                    fields={ fields }
                                    scope={ this } />;

    const customControls  = [Inserter];
    const placeholder     = 'Notification subject...';
    const rootStyle       = { background: '#f9f9f9',
                              fontFamily: 'sans-serif' };
    const toolbarConfig   = { display: [] };
    const toolbarStyle    = { background: '#fff',
                              margin: 0,
                              padding: '10px 10px 5px',
                              height: '52px' };

    return (
      <RichTextEditor ref={ (ref) => this.rte = ref }
                      placeholder={ placeholder }
                      rootStyle={ rootStyle }
                      toolbarStyle={ toolbarStyle }
                      toolbarConfig={ toolbarConfig }
                      customControls={ customControls }
                      value={ value }
                      onChange={ onChange } />
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

SubjectEditor.propTypes = {
  fields:     PropTypes.array.isRequired,
  value:      PropTypes.object.isRequired,
  onChange:   PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default SubjectEditor;
