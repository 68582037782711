// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

const conditionalEditorFieldTypeMap = {
  'freeform-short': 'text',
  'freeform-long':  'text',
  'select-one':     'selectOne',
  'select-many':    'selectMany',
  'address':        'text',
  'attachment':     'text',       // models simply render the file name
  'boolean':        'boolean'
};

const conditionalEditorDataTypeMap = {
  'date': 'datetime'
};


// --------------------------------------------------------
// Class Definition
// --------------------------------------------------------

class FieldShaper {
  constructor(fields) {
    this.fields = fields || [];
  }

  transform() {
    return this.fields.map(({ id, label, field_type, data_type, field_options }) => (
      {
        label:        label,
        value:        id,
        fieldType:    conditionalEditorFieldTypeMap[field_type] || field_type,
        dataType:     conditionalEditorDataTypeMap[data_type]   || data_type,
        fieldOptions: field_options
      }
    ));
  }
}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default FieldShaper;
