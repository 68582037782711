import React, { useState }             from 'react';
import PropTypes                       from 'prop-types';
import {  Modal,
          Header,
          Button,
          Form}  from 'semantic-ui-react';
import DateSelect from 'components/DateSelect';
import { relationshipOptions, genderOptions } from 'utils/household';
import ClientResource from 'resources/organization/ClientResource';
import HouseholdMemberResource from 'resources/organization/HouseholdMemberResource';
import useOrganizationFetcher  from 'hooks/useOrganizationFetcher';

const defaultHouseholdMember = {
  type: 'non_client',
  name: null,
  dob: null,
  gender: null,
  relationship: null,
  existing_client_id: null
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FormModal = ({ title,
                    opened,
                    clientId,
                    member=null,
                    onClose }) => {

  const hm = member ?
              {
                ...member,
                existing_client_id: member.client_id,
                type: member.client_id ? 'client' : 'non_client'
              } :
              defaultHouseholdMember;

  const [ householdMember, setHouseholdMember ] = useState(hm);
  const [ clients, setClients ] = useState([]);
  const [ searchString, setSearchString ] = useState('');

  const getClients = useOrganizationFetcher(ClientResource.listShape());
  const create = useOrganizationFetcher(HouseholdMemberResource.createShape());
  const update = useOrganizationFetcher(HouseholdMemberResource.updateShape());


  const clientOptions = clients.map((record) => ({
                          key: record.id,
                          value: record.id,
                          text: record.first_name + ' ' + record.last_name
                        })).sort((a, b) => a.text.localeCompare(b.text));

  const handleSearchChange = async (e, search) => {
    const query = search.searchQuery;

    setSearchString(query);
    if (query === '') {
      setClients([]);
      return;
    }
    // unable to use a debounce here beacause async/await
    // workaround to minimize the number of calls to the server
    if (query.length > 2 && query.length % 2 !== 0 ) {
      const clients = await getClients({ query: query }, {});
      setClients(clients);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (validateForm()){
      const params    = { client_id: clientId, id: member ? member.id : undefined };
      const response  = member ?
                          await update(params, householdMember) :
                          await create(params, householdMember);

      if(!response.errors) {
        window.location.reload(true);
      }
    } else {
      alert('Please fill out all required fields');
    }
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    onClose();
  };

  const validateForm = () => {
    if (householdMember.type === 'non_client'){
      const { name, dob, gender, relationship } = householdMember;
      return name && dob && gender && relationship;
    } else {
      if (!householdMember.existing_client_id){
        alert('Please select a client');
        return false;
      }
    }

    return true;
  };

  const linkClient = (value) => {
    const newFamilyMember = clients.find((client) => client.id === value);

    setHouseholdMember({
      type: 'client',
      name: newFamilyMember.first_name + ' ' + newFamilyMember.last_name,
      dob: newFamilyMember.dob,
      gender: newFamilyMember.gender,
      existing_client_id: value });

    setSearchString('');
  };

  const handleClear = () => {
    setHouseholdMember(defaultHouseholdMember);
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setHouseholdMember({ ...householdMember, [name]: value });
  };

  const handleDobChange = (value) => {
    setHouseholdMember({ ...householdMember, dob: value });
  };

  const handleSelectChange = (evt, { name, value }) => {

    setHouseholdMember({ ...householdMember, [name]: value });
  };

  return (
    <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
      <Header icon='user' content={ title } />
      <Modal.Content>
        <Form>
          <label className={ 'labelStyle' }>Type of contact</label>
          <Form.Group inline>
            <Form.Radio name='memberType'
                        label='New contact'
                        value='non_client'
                        checked={householdMember.type === 'non_client'}
                        onChange={ handleClear }
            />

            <Form.Radio name='memberType'
                        label='Existing client'
                        value='client'
                        checked={householdMember.type === 'client'}
                        onChange={ () => setHouseholdMember({ ...defaultHouseholdMember, type: 'client' }) }
            />

            <Form.Select  placeholder='Search...'
                          icon='search'
                          selectOnBlur={ false }
                          disabled={ householdMember.type === 'non_client' }
                          options={ clientOptions }
                          onSearchChange={handleSearchChange}
                          searchQuery={searchString}
                          onChange={ (_, { value }) => linkClient(value) }
                          search />
          </Form.Group>

          <Form.Input label='Name'
                      name='name'
                      disabled={ householdMember.existing_client_id }
                      value={ householdMember.name || '' }
                      onChange={ handleChange }
                      />

          <Form.Field>
          <label>Date of Birth</label>
          <DateSelect name='dob'
                      disabled={ householdMember.existing_client_id }
                      value={ householdMember.dob || '' }
                      onChange={ handleDobChange }
                      />
          </Form.Field>

          <Form.Select  label='Gender'
                        name='gender'
                        disabled={ householdMember.existing_client_id }
                        value={ householdMember.gender || '' }
                        options={ genderOptions }
                        onChange={ handleSelectChange }
                        selectOnBlur={ false }
                        search
                        />

          <Form.Select label='Relationship'
                      name='relationship'
                      value={ householdMember.relationship || '' }
                      options={ relationshipOptions }
                      onChange={ handleSelectChange }
                      selectOnBlur={ false }
                      search
                      />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={ handleCancel } negative basic>Cancel</Button>
        <Button type='submit'
                onClick={ handleSubmit }
                positive
                basic>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormModal.propTypes = {
  title:                  PropTypes.string.isRequired,
  opened:                 PropTypes.bool.isRequired,
  onClose:                PropTypes.func.isRequired,
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormModal;
