import React, { Component }                           from 'react';
import PropTypes                                      from 'prop-types';
import { Message, Button, Divider, Icon, Grid, Ref }  from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable }      from 'react-beautiful-dnd';

import FieldOption            from './FieldOption';
import FieldOptionListHeader  from './FieldOptionListHeader';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const addButtonStyle = {
  margin: '1.5rem 0'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FieldOptionList extends Component {
  constructor(props) {
    super(props);

    this.handleChange   = this.handleChange.bind(this);
    this.handleRemove   = this.handleRemove.bind(this);
    this.handleAdd      = this.handleAdd.bind(this);
    this.handleDragEnd  = this.handleDragEnd.bind(this);
  }

  render() {
    const { options }   = this.props;
    const hasNoOptions  = options.length === 0;

    return (
      <div>
        <Divider horizontal>Field Options</Divider>

        <FieldOptionListHeader />

        <DragDropContext onDragEnd={ this.handleDragEnd }>
          <Droppable droppableId="field-option-list">
            {
              (provided) => (
                <Ref innerRef={ provided.innerRef }>
                  <Grid columns={ 4 } divided='vertically' verticalAlign='middle'>
                    {
                      options.map((option, i) => {
                        return(
                          <Draggable  key={ i }
                                      draggableId={ i }
                                      index={ i }>
                            {
                              (provided, snapshot) => (
                                <Ref innerRef={ provided.innerRef }>
                                  <FieldOption  index={ i }
                                                option={ option }
                                                onChange={ this.handleChange }
                                                onRemove={ this.handleRemove }
                                                draggableProvided={ provided }
                                                draggableSnapshot={ snapshot } />
                                </Ref>
                              )
                            }
                          </Draggable>
                        );
                      })
                    }

                    { provided.placeholder }
                  </Grid>
                </Ref>
              )
            }
          </Droppable>
        </DragDropContext>

        {
          hasNoOptions &&
          <Message info>
            Please add at least one option to this field.
          </Message>
        }

        <Button icon
                basic
                style={ addButtonStyle }
                labelPosition='left'
                onClick={ this.handleAdd }>
          <Icon name='add' />
          Add an option
        </Button>
      </div>
    );
  }

  handleChange(evt, { option, index }) {
    const { options, name, onChange } = this.props;
    const nextOptions  = [ ...options ];
    nextOptions[index] = option;

    onChange(evt, { name, value: nextOptions });
  }

  handleRemove(evt, { index }) {
    const { options, name, onChange } = this.props;
    const nextOptions = [ ...options ];
    nextOptions.splice(index, 1);

    onChange(evt, { name, value: nextOptions });
  }

  handleAdd(evt) {
    evt.preventDefault();

    const { options, name, onChange } = this.props;
    const nextOptions = [
      ...options,
      { position: options.length }
    ];

    onChange(evt, { name, value: nextOptions });
  }

  handleDragEnd({ source, destination }) {

    // If the drag does not result in a change, do nothing.
    if(!source || !destination || source.index === destination.index) {
      return;
    }

    const { options, name, onChange } = this.props;
    const nextOptions  = [...options ];
    const sourceOption = nextOptions[source.index];

    // move the source to the destination
    nextOptions.splice(source.index, 1);
    nextOptions.splice(destination.index, 0, sourceOption);

    // update positions on all fields
    nextOptions.forEach((option, i) => (option.position = i));

    onChange(null, { name, value: nextOptions });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FieldOptionList.propTypes = {
  options:  PropTypes.arrayOf(PropTypes.shape({
    id:       PropTypes.number,
    label:    PropTypes.string,
    value:    PropTypes.any,
    position: PropTypes.number
  })).isRequired,

  name:     PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldOptionList;
