import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class SequenceResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/sequences';

  id              = undefined;
  name            = '';
  forms           = [];

  pk() {
    return this.id && this.id.toString();
  }
}

