import React      from 'react';
import PropTypes  from 'prop-types';
import { Table }  from 'semantic-ui-react';

import SortQueue  from 'components/SortableTable/SortQueue';
import Cell       from './Cell';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Header = ({ labels,
                  sortQueue,
                  onSort,
                  showControls,
                  columnCloaker,
                  onResize }) => {

  const indexedSortDirections =
    sortQueue.reduce((indexed, { index, direction }) => {
      indexed[index] = direction;
      return indexed;
    }, {});

  return (
    <Table.Header>
      <Table.Row>
        {
          showControls && <Table.HeaderCell>&nbsp;</Table.HeaderCell>
        }

        {
          labels
          .map((label, index) => ([ label, index ])) // preserve column index
          .filter(([, columnIndex]) => (
            !(columnCloaker && columnCloaker(columnIndex))
          ))
          .map(([label, columnIndex]) => (
            <Cell key={ columnIndex }
                  columnIndex={ columnIndex }
                  sortDirection={ indexedSortDirections[columnIndex] }
                  onSort={ onSort }
                  onResize={ onResize }>
              { label }
            </Cell>
          ))
        }
      </Table.Row>
    </Table.Header>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Header.propTypes = {
  labels:         PropTypes.arrayOf(PropTypes.string).isRequired,
  sortQueue:      PropTypes.instanceOf(SortQueue).isRequired,
  onSort:         PropTypes.func.isRequired,
  showControls:   PropTypes.bool.isRequired,
  columnCloaker:  PropTypes.func
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Header;
