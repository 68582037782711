import React, { useState }      from 'react';
import PropTypes  from 'prop-types';

import { Form, List, Radio, Select }  from 'semantic-ui-react';
import FormResource from 'resources/organization/FormResource';
import useOrganizationResource from 'hooks/useOrganizationResource';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function getOptions(records) {
  return records.map((record) => ({
            key: record.id,
            value: record.id,
            text: record.name
          }));
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const PrePopulateSettings = ({ settings, allFields=[], onChange }) => {

  const forms            = useOrganizationResource(FormResource.listShape(), {});
  const { pre_populate } = settings;
  const { form_id,
          form_field_id,
          field_id }     = pre_populate || { form_id: null, form_field_id: null, field_id: null};

  const [ selectedFormId,  setSelectedFormId ] = useState(form_id);
  const [ prepopulateType, setPrepopulateType ] = useState(form_id ? 'specific_form' : 'any_form');

  const allFieldsOptions = getOptions(allFields);

  const formOptions = getOptions(forms);
  const selectedForm = selectedFormId ? forms.find((form) => form.id === selectedFormId) : null;
  const fieldOptions = selectedFormId ? getOptions(selectedForm.ffs) : [];

  const handleChangeType = (_, { value }) => {
    setPrepopulateType(value);

    const nextSettingsChanges = { ...settings };
    delete nextSettingsChanges.pre_populate;
    onChange(nextSettingsChanges);
  };

  const handleChange = (_, { value }) => {
    const nextSettingsChanges = { ...settings };

    if (value === '') {
      delete nextSettingsChanges.pre_populate;

    } else if (prepopulateType === 'any_form') {
      nextSettingsChanges.pre_populate =  { field_id: value};

    } else if (prepopulateType === 'specific_form') {
      nextSettingsChanges.pre_populate =  { form_id: selectedFormId, form_field_id: value};

    }

    onChange(nextSettingsChanges);
  };

  return (
    <>
    <label>Pre-Populate field with previous Client answer: </label>

    <List horizontal>
      <List.Item key={ 'any_form' }>
        <Radio  name={ 'prepopulateType' }
                value={ 'any_form' }
                checked={ prepopulateType === 'any_form' }
                label={ 'Last field answer on any form' }
                onChange={ handleChangeType } />
      </List.Item>
      <List.Item key={ 'specific_form' }>
        <Radio  name={ 'prepopulateType' }
                value={ 'specific_form' }
                checked={ prepopulateType === 'specific_form' }
                label={ 'Last field answer in a specific Form' }
                onChange={ handleChangeType } />
      </List.Item>
    </List>

    <Form.Group widths='equal'>
      { prepopulateType === 'any_form' ?
          <Form.Field width={ 8 }>
            <Select placeholder='Select Field'
                    selectOnBlur={ false }
                    options={ allFieldsOptions }
                    value={ field_id }
                    onChange={ handleChange }
                    fluid
                    clearable
                    search />
          </Form.Field>
        : <>
            <Form.Field width={ 8 }>
              <Select placeholder='Select Form'
                      selectOnBlur={ false }
                      options={ formOptions }
                      value={ selectedFormId }
                      onChange={ (_, { value }) => setSelectedFormId(value) }
                      fluid
                      clearable
                      search />
            </Form.Field>
            <Form.Field width={ 8 }>
              <Select placeholder='Select Field'
                      selectOnBlur={ false }
                      options={ fieldOptions }
                      value={ form_field_id }
                      onChange={ handleChange }
                      fluid
                      clearable
                      search />
            </Form.Field>
          </>
        }
    </Form.Group>
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

PrePopulateSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default PrePopulateSettings;
