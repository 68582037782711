import React, { useState }             from 'react';
import PropTypes                       from 'prop-types';
import {  Modal, 
          Header,
          Table,
          Button,
          Ref }  from 'semantic-ui-react';

import useOrganizationResource from 'hooks/useOrganizationResource';
import useOrganizationFetcher  from 'hooks/useOrganizationFetcher';
import FormResource            from 'resources/organization/FormResource';
import ClientDashboardResource from 'resources/organization/ClientDashboardResource';
import { formDataRequestMapper }  from 'utils/requestMappers';

import AddRecord               from './AddRecord';
import Record                  from './Record';

import { DragDropContext, Droppable, Draggable }      from 'react-beautiful-dnd';
import { remove } from 'lodash';


// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const SettingsModal = ({  title,
                          opened,
                          onClose }) => {

  const create = useOrganizationFetcher(ClientDashboardResource.createShape());

  const forms           = useOrganizationResource(FormResource.listShape(), {});
  const clientDashboard = useOrganizationResource(ClientDashboardResource.detailShape(), { id: 1 });
  const [clientFields, setClientFields] = useState(clientDashboard.fields);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const params       = { id: 1 }; // work around - any id will do
    const formFieldIds = clientFields.map((cf) => cf.id );
    const body         = formDataRequestMapper({ form_field_ids: formFieldIds });
    
    const response  = await create(params, body);

    if(!response.errors) {
      window.location.reload(true);
    }
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    setClientFields(clientDashboard.fields);
    onClose();
  };

  const handleAdd = ({ formId, formFieldId }) => {
    const nextClientFields  = [...clientFields ];
    nextClientFields.push({id: formFieldId, form_id: formId});

    setClientFields(nextClientFields);
  };

  const handleDelete = ({ formFieldId }) => {
    const nextClientFields  = [...clientFields ];
    remove(nextClientFields, (cf) => cf.id === formFieldId);

    setClientFields(nextClientFields);
  };

  const handleDragEnd = ({ source, destination }) => {
    // If the drag does not result in a change, do nothing.
    if(!source || !destination || source.index === destination.index) {
      return;
    }  

    const nextClientFields  = [...clientFields ];
    const sourceCF = nextClientFields[source.index];

    // move the source to the destination
    nextClientFields.splice(source.index, 1);
    nextClientFields.splice(destination.index, 0, sourceCF);

    setClientFields(nextClientFields);
  };


  return (
    <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
      <Header icon='setting' content={ title } />
      <Modal.Content>
        <DragDropContext onDragEnd={ handleDragEnd }>
          <Droppable droppableId="field-option-list">
            {
              (provided) => (
                <Ref innerRef={ provided.innerRef }>
                <Table basic='very'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Form</Table.HeaderCell>
                      <Table.HeaderCell>Field</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>

                    { clientFields.map( (cf, i) => {
                      const form = forms.find( fo => fo.id === cf.form_id );
                      const field = form.ffs.find( fi => fi.id === cf.id );

                      return (
                        <Draggable key={ i }
                                  draggableId={ i }
                                  index={ i }>
                        {
                          (provided, snapshot) => (
                            <Ref innerRef={ provided.innerRef }>
                              <Record key={cf.id}
                                      formFieldId={ cf.id }
                                      formName={ form.name }
                                      fieldName={ field.name }
                                      onDelete={ handleDelete }
                                      draggableProvided={ provided }
                                      draggableSnapshot={ snapshot } />
                          </Ref>
                          )
                        }
                        </Draggable>
                      ); 
                    } ) }

                    <AddRecord forms={forms} onAdd={ handleAdd } />
                  </Table.Body>
                </Table>
                </Ref>
              )
            }
          </Droppable>
        </DragDropContext>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={ handleCancel } negative basic>Cancel</Button>
        <Button type='submit'
                onClick={ handleSubmit }
                positive
                basic>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SettingsModal.defaultProps = {

};

SettingsModal.propTypes = {
  title:                  PropTypes.string.isRequired,
  opened:                 PropTypes.bool.isRequired,
  onClose:                PropTypes.func.isRequired,
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SettingsModal;
