const storage     = localStorage;
const storageKey  = 'state';

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export const loadState = () => {
  try {
    const serializedState = storage.getItem(storageKey);

    if(serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch(err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    storage.setItem(storageKey, serializedState);
  } catch(err) {
    // Do nothing.
  }
};
