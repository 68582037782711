import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { connect }                from 'react-redux';

import LogicSettings                      from 'components/forms/LogicSettings';
import { shapeFields, shapeCalculations } from 'utils/formLogic/conditionEditor';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class LogicSettingsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      logic:  props.form.logic || [],
      saving: false
    };

    this.handleSave   = this.handleSave.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);

  }

  componentDidUpdate(prevProps) {
    const { form }        = this.props;
    const { logic = [] }    = form;

    if (this.props.form.logic !== prevProps.form.logic) {
      this.setState({ logic });
    }
  }

  render() {
    const { form, fields, loading } = this.props;
    const { logic, saving }         = this.state;
    const calculations              = form.calculations || [];

    const fieldsOptions = [
      ...shapeFields(fields),
      ...shapeCalculations(calculations)
    ];

    return (
      <LogicSettings  loading={ loading || saving }
                      logic={ logic }
                      fields={ fieldsOptions }
                      onSave={ this.handleSave }
                      onSaveOrder={ this.handleSaveOrder }
                      onDelete={ this.handleDelete }
                      onDragEnd={ this.handleDragEnd } />
    );
  }

  async handleSave(logicItem, index) {
    const { updateAction: update, form: { id } } = this.props;
    const { logic }                              = this.state;
    const nextLogic = [ ...logic ];

    if(Number.isInteger(index)) {
      nextLogic[index] = logicItem;
    } else {
      nextLogic.push(logicItem);
    }

    this.setState({ saving: true });

    await update({ logic: nextLogic }, { id });
    this.setState({ saving: false });
  }

  async handleSaveOrder() {
    const { updateAction: update, form: { id } } = this.props;
    const { logic }                              = this.state;

    this.setState({ saving: true });
    const response = await update({ logic }, { id });
    this.setState({ saving: false });
    if(!response.errors) {
      return true;
    }
  }

  async handleDelete(index) {
    const { updateAction: update, form: { id } } = this.props;
    const { logic }                              = this.state;

    const nextLogic = [ ...logic ];
    nextLogic.splice(index, 1);

    this.setState({ saving: true });

    await update({ logic: nextLogic }, { id });
    this.setState({ saving: false });

  }

  handleDragEnd({ source, destination }) {
    // If the drag does not result in a change, do nothing.
    if(!source || !destination || source.index === destination.index) {
      return;
    }

    const { logic } = this.state;
    const nextLogic  = [...logic ];
    const sourceLogic = nextLogic[source.index];

    // move the source to the destination
    nextLogic.splice(source.index, 1);
    nextLogic.splice(destination.index, 0, sourceLogic);

    // update positions on all fields
    nextLogic.forEach((option, i) => (option.position = i));

    this.setState( { logic: nextLogic });

  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

LogicSettingsContainer.defaultProps = {
  loading: false
};

LogicSettingsContainer.propTypes = {
  loading:      PropTypes.bool,
  form:         PropTypes.object.isRequired,
  updateAction: PropTypes.func.isRequired,
  fields:       PropTypes.arrayOf(PropTypes.shape({
    id:             PropTypes.number,
    name:           PropTypes.string,
    data_type:      PropTypes.string,
    field_type:     PropTypes.string,
    field_options:  PropTypes.array
  })).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connect()(LogicSettingsContainer);
