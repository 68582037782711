import React              from 'react';
import PropTypes          from 'prop-types';
import { Button, Popup }  from 'semantic-ui-react';
import { Link }           from 'react-router-dom';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Edit this form.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const EditButton = ({ publicFormId, responseId }) => {
  const href = `/organization/public_responses/${publicFormId}/responses/${responseId}/edit`;

  const Btn = <Button icon='pencil'
                      basic
                      color='blue'
                      as={ Link }
                      to={ href } />;

  return (
    <Popup  trigger={ Btn }
            content={ title }
            position='top center' />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

EditButton.propTypes = {
  publicFormId: PropTypes.number.isRequired,
  responseId:   PropTypes.string.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default EditButton;
