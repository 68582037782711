import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table, Checkbox }  from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class BodyCell extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    const { disabled } = this.props;
    return (
      <Table.Cell textAlign='center'>
        <Checkbox disabled={ disabled }
          checked={ this.isChecked() }
          onChange={ this.handleChange } />
      </Table.Cell>
    );
  }

  isChecked() {
    const { privileges, entity, action }  = this.props;
    const privilegeActions                = privileges[entity] || [];
    
    return privilegeActions.indexOf(action) > -1;
  }

  handleChange(_, attrs) {
    const { disabled, entity, action, onChange }  = this.props;
    const { checked }                             = attrs;
    const entities                                = [entity];
    const actions                                 = [action];

    onChange({ entities, actions, checked, disabled });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

BodyCell.propTypes = {
  entity:     PropTypes.string.isRequired,
  action:     PropTypes.string.isRequired,
  privileges: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired,
  disabled:   PropTypes.bool,
  onChange:   PropTypes.func
};

BodyCell.defaultProps = {
  disabled: true,
  onChange: () => {}
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default BodyCell;
