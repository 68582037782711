import React, { Component }       from 'react';
import { Form, Segment, Select }  from 'semantic-ui-react';
import { Redirect }               from 'react-router-dom';

import Fixed                    from 'components/Fixed';
import connectForm              from 'utils/connectForm';
import connectResource          from 'utils/connectResource';
import { getAllById, getById }  from 'utils/connectors';
import { organizations, users } from 'resources/adminResources';

import OrganizationList from './OrganizationList';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const roleOptions = [
  {text: 'Super User',        value: 'superuser'},
  {text: 'Organization User', value: 'orguser'}
];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormView extends Component {
  render() {
    const { data,
            selected: user,
            loading,
            api_errors,
            onSave,
            onUpdateValue,
            saving } = this.props;

    const organizations = data.organizations || [];

    const isUnowned = user.owner_id === null;

    const { first_name, last_name, email, role } = user;

    return (
      <Segment loading={ loading }>
        { api_errors && <Redirect to='./'/> }

        <Form onSubmit={ onSave } warning={ isUnowned }>
          <Form.Input label='First Name'
                      name='first_name'
                      value={ first_name || '' }
                      autoFocus
                      onChange={ onUpdateValue } />

          <Form.Input label='Last Name'
                      name='last_name'
                      value={ last_name || '' }
                      onChange={ onUpdateValue } />

          <Form.Input label='Email'
                      name='email'
                      value={ email }
                      placeholder='test@example.com'
                      onChange={ onUpdateValue } />

          <Form.Field control={ Select }
                      label='Role'
                      options={ roleOptions }
                      placeholder='Select User Role'
                      name='role'
                      value={ role }
                      onChange={ onUpdateValue } />

          {
            role === 'orguser' &&
            <OrganizationList name='organization_ids'
                              organizations={ organizations }
                              user={ user }
                              onChange={ onUpdateValue } />
          }

          <Fixed>
            <Form.Button  loading={ saving }
                          type='submit'
                          primary>Save</Form.Button>
          </Fixed>
        </Form>
      </Segment>
    );
  }
}

const initialState = {
  id:               null,
  first_name:       '',
  last_name:        '',
  role:             '',
  email:            '',
  organization_ids: []
};

export default connectResource(
  connectForm(
    FormView, {
      getDefault: ({ data: { user } }) => ({ ...initialState, ...(user || {}) })
    }
  ), {
  connectors: {
    user:           getById(users, ({ match: { params: { id } } }) => id),
    organizations:  getAllById(organizations)
  },
  mapDispatchToProps: { ...users.actions }
});
