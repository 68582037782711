import React, { Component }                       from 'react';
import PropTypes                                  from 'prop-types';
import { Message, Dropdown, Divider, Grid, Ref }  from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable }  from 'react-beautiful-dnd';

import SequenceForm from './SequenceForm';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const addButtonStyle = {
  margin: '1.5rem 0'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class SequenceFormList extends Component {
  constructor(props) {
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd    = this.handleAdd.bind(this);
    this.handleDragEnd  = this.handleDragEnd.bind(this);
  }

  render() {
    const { allForms,
            forms } = this.props;

    const hasNoForms = forms.length === 0;

    const formLabels = allForms.reduce((labels, { name, id }) => {
      labels[id] = name;
      return labels;
    }, {});

    const usedFormIds  = forms.map(({ id }) => (id));
    const unusedOptions = allForms
                          .map(({ name, id }) => (
                            { text: name, value: id }
                          ))
                          .filter(({ value }) => (
                            !usedFormIds.includes(value)
                          ));

    return (
      <div>
        <Divider horizontal style={{ marginBottom: '2rem' }}>Forms</Divider>
        <DragDropContext onDragEnd={ this.handleDragEnd }>
          <Droppable droppableId="form-field-list">
            {
              (provided) => (
                <Ref innerRef={ provided.innerRef }>
                  <Grid columns={ 4 } divided='vertically' verticalAlign='middle'>
                    {
                      forms.map((sequenceForm, i) => {
                        const { id }  = sequenceForm;
                        const label   = formLabels[id];

                        return(
                          <Draggable key={ id } draggableId={ id } index={ i }>
                            {
                              (provided, snapshot) => (
                                <Ref innerRef={ provided.innerRef }>
                                  <SequenceForm  index={ i }
                                          sequenceForm={ sequenceForm }
                                          sequenceForms={ forms }
                                          label={ label }
                                          onRemove={ this.handleRemove }
                                          draggableProvided={ provided }
                                          draggableSnapshot={ snapshot } />
                                </Ref>
                              )
                            }
                          </Draggable>
                        );
                      })
                    }
                    { provided.placeholder }
                  </Grid>
                </Ref>
              )
            }
          </Droppable>
        </DragDropContext>

        {
          hasNoForms &&
          <Message info>
            Please add at least one form to this sequence.
          </Message>
        }

        <Dropdown text='Select a form to add'
                  search
                  fluid
                  labeled
                  basic
                  button
                  className='icon'
                  icon='add'
                  style={ addButtonStyle }
                  selectOnBlur={ false }
                  selectOnNavigation={ false }
                  options={ unusedOptions }
                  onChange={ this.handleAdd } />
      </div>
    );
  }

  handleRemove(evt, { index }) {
    const { forms, name, onChange } = this.props;
    const nextForms  = [ ...forms ];
    nextForms.splice(index, 1);

    onChange(evt, { name, value: nextForms });
  }

  handleAdd(evt, { value }) {
    const { forms, name, onChange } = this.props;

    const nextForms = [
      ...forms,
      {
        id: value,
        position: forms.length
      }
    ];

    onChange(evt, { name, value: nextForms });
  }

  handleDragEnd({ source, destination }) {

    // If the drag does not result in a change, do nothing.
    if(!source || !destination || source.index === destination.index) {
      return;
    }

    const { forms, name, onChange } = this.props;
    const nextForms  = [...forms ];
    const sourceField     = nextForms[source.index];

    // move the source to the destination
    nextForms.splice(source.index, 1);
    nextForms.splice(destination.index, 0, sourceField);

    // update positions on all fields
    nextForms.forEach((formField, i) => (formField.position = i));

    onChange(null, { name, value: nextForms });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SequenceFormList.defaultProps = {};
SequenceFormList.propTypes = {
  name:                   PropTypes.string.isRequired,
  allForms:               PropTypes.array.isRequired,
  forms:                  PropTypes.array.isRequired,
  onChange:               PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SequenceFormList;
