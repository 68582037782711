import React      from 'react';
// import PropTypes  from 'prop-types';
import { Segment, Header }  from 'semantic-ui-react';
import { Switch, Route }    from 'react-router-dom';

import Main from 'components/Main';

import FormView   from './FormView';
import ListView   from './ListView';
import DetailView from './DetailView';
import SuccessView from './SuccessView';
import ApiErrorHandler from 'components/api/ApiErrorHandler';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const App = (/*props*/) => {
  return (
    <Main className='detail-pane' overflow="scroll" padded>
      <Segment>
        <Header as='h3'>Version Search</Header>
        <ApiErrorHandler>
          <Switch>
            <Route path='/admin/versions/rollback/success' component={ SuccessView } />
            <Route path='/admin/versions/results' component={ ListView } />
            <Route path='/admin/versions/:id' component={ DetailView } />
            <Route path='/admin/versions/' component={ FormView } />
          </Switch>
        </ApiErrorHandler>
      </Segment>
    </Main>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

App.defaultProps  = {};
App.propTypes     = {};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default App;