import React, { useState, useCallback }       from 'react';
import PropTypes                              from 'prop-types';
import { Modal, Header, Form, Button, Table } from 'semantic-ui-react';
import omit                                   from 'lodash/omit';

import uniqueId                     from 'utils/uniqueId';
import { indexOnAttributeWithMap }  from 'utils/indexOnAttribute';

import CategoryField from './CategoryField';

// -----------------------------------------------------
// Helper
// -----------------------------------------------------

const newRecordIdPrefix = 'new';
const initialChanges    = [];
const initialDeletions  = [];

// merges changes with categories, while preserving insertion order.
function mergeChanges(categoryRecords, changes, deletions) {
  const indexedRecords  = indexOnAttributeWithMap(categoryRecords, 'id');
  const indexedChanges  = indexOnAttributeWithMap(changes, 'id');
  const merged          = new Map([ ...indexedRecords, ...indexedChanges ]);

  deletions.forEach(id => merged.delete(id));

  return [ ...merged.values() ];
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CategoryRecordsModal = ({ opened, saving, onClose, onSave, loading, categoryRecords }) => {
  const [changes, setChanges]       = useState(initialChanges);
  const [deletions, setDeletions]   = useState(initialDeletions);

  const categoryRecordsWithChanges = mergeChanges(categoryRecords, changes, deletions);
  const selectedCategoryIds        = categoryRecordsWithChanges
                                      .map(({ category_id }) => category_id);

  const formId = uniqueId();

  // -----------------------------------------------------
  // Callbacks
  // -----------------------------------------------------

  const handleCancel = useCallback(() => {
    setChanges(initialChanges);
    setDeletions(initialDeletions);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    const records = categoryRecordsWithChanges.map(record => {
      const { id } = record;

      if(id.startsWith && id.startsWith(newRecordIdPrefix)) {
        return omit(record, 'id');
      }

      return record;
    });

    onSave(records, () => handleCancel());
  }, [categoryRecordsWithChanges, onSave, handleCancel]);

  const handleAdd = (evt) => {
    evt.preventDefault();
    setChanges([...changes, { id: `${newRecordIdPrefix}.${uniqueId()}` }]);
  };

  const handleChange = (categoryRecord) => {
    setChanges([...changes, categoryRecord]);
  };

  const handleRemove = (id) => {
    setDeletions([...deletions, id]);
  };

  return (
    <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
      <Header icon='folder' content='Manage Categories' />

      <Modal.Content>
        <h4>Add or delete this record to or from a category.</h4>

        <Form onSubmit={ handleSubmit } loading={ loading } id={ formId }>
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>
                  <Button icon='add'
                          size='mini'
                          basic
                          color='blue'
                          onClick={ handleAdd } />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                categoryRecordsWithChanges.map(({ id, category_id }) => (
                  <CategoryField key={ id }
                                  id={ id }
                                  categoryId={ category_id }
                                  selectedCategoryIds={ selectedCategoryIds }
                                  onChange={ handleChange }
                                  onRemove={ handleRemove } />
                ))
              }
            </Table.Body>
          </Table>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={ handleCancel } negative basic>Cancel</Button>
        <Button type='submit'
                loading={ saving }
                form={ formId }
                positive
                basic>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CategoryRecordsModal.defaultProps = {
  opened:   false,
  saving:   false,
  loading:  false
};

CategoryRecordsModal.propTypes = {
  opened:           PropTypes.bool,
  saving:           PropTypes.bool,
  loading:          PropTypes.bool,
  onClose:          PropTypes.func.isRequired,
  onSave:           PropTypes.func.isRequired,
  categoryRecords: PropTypes.array.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CategoryRecordsModal;
