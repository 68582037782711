import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Confirm }                    from 'semantic-ui-react';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const title = 'Delete this response';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class DeleteLink extends Component {
  state = { opened: false };

  constructor(props) {
    super(props);

    this.handleClick    = this.handleClick.bind(this);
    this.handleCancel   = this.handleCancel.bind(this);
    this.handleConfirm  = this.handleConfirm.bind(this);
  }

  render() {
    const { opened } = this.state;

    return (
      <Fragment>
        <Confirm  open={ opened }
                  header={ title }
                  onCancel={ this.handleCancel }
                  onConfirm={ this.handleConfirm } />

        <a  href='#delete'
            style={ { color: '#db2828' } }
            onClick={ this.handleClick }>Delete this response</a>
      </Fragment>
    );
  }

  handleClick(evt) {
    evt.preventDefault();
    this.setState({ opened: true });
  }

  handleCancel() {
    this.setState({ opened: false });
  }

  handleConfirm() {
    const { onDelete } = this.props;
    onDelete();
    this.setState({ opened: false });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DeleteLink.propTypes = {
  onDelete: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DeleteLink;
