import React, { Fragment }  from 'react';
import { Table, Icon }      from 'semantic-ui-react';

import SingleCellRow        from 'components/SingleCellRow';
import FormGroupInstances   from './FormGroupInstances';

import formattedDate        from 'utils/formattedDate';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const Toggler = ({ toggleExpanded, children }) => {
  return (
    <a  href='#toggle-row'
        onClick={ toggleExpanded }
        style={{ display: 'block' }}>{ children }</a>
  );
};

// -----------------------------------------------------
// Component
// -----------------------------------------------------

const FormGroupDisplay = ({
  match,
  formGroup,
  formName,
  lastAdministeredBy,
  expanded,
  toggleExpanded
}) => (

  <Fragment>
    <Table.Row>
      <Table.Cell>
        <Toggler toggleExpanded={ toggleExpanded }>
          {
            expanded
            ? <Icon name='triangle down' />
            : <Icon name='triangle right' />
          }
        </Toggler>
      </Table.Cell>

      <Table.Cell>
        <Toggler toggleExpanded={ toggleExpanded }>
          {formName}
        </Toggler>
      </Table.Cell>

      <Table.Cell>
        <Toggler toggleExpanded={ toggleExpanded }>
          { formGroup.count }
        </Toggler>
      </Table.Cell>

      <Table.Cell>
        <Toggler toggleExpanded={ toggleExpanded }>
          { formattedDate(formGroup.last_administered_at, 'date') }
        </Toggler>
      </Table.Cell>

      <Table.Cell>
        <Toggler toggleExpanded={ toggleExpanded }>
          { lastAdministeredBy }
        </Toggler>
      </Table.Cell>
    </Table.Row>

    {
      expanded &&
      <SingleCellRow colSpan='5'>
        <FormGroupInstances clientId={ Number(match.params.id) }
                            formId={formGroup.id} />
      </SingleCellRow>
    }
  </Fragment>
);

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormGroupDisplay;
