import wrapCalculationId          from 'utils/calculations/wrapId';
import { dateType, numericType }  from 'constants/Calculations';

// field type conversion table for field shaping.  If no entry exists for the
// field_type, the field_type should be used.
const conditionalFieldTypes = {
  'freeform-short': 'text',
  'freeform-long':  'text',
  'select-one':     'selectOne',
  'select-many':    'selectMany',
  'address':        'text',
  'boolean':        'boolean'
};

// data type conversion table for field shaping.  If no entry exists for the
// data_type, the data_type should be used.
const conditionalDataTypes = {
  'date': 'datetime'
};

// A calculation will always result in a numeric value.
const calculationFieldTypes  = {
  'numeric': 'number'
};

const dateOptions = [
  {value: 'today',         label: 'Today',          op: 'range'},
  {value: 'yesterday',     label: 'Yesterday',      op: 'range'},
  {value: '7_days',        label: 'Last 7 Days',    op: 'range'},
  {value: '14_days',       label: 'Last 14 Days',   op: 'range'},
  {value: '30_days',       label: 'Last 30 Days',   op: 'range'},
  {value: '60_days',       label: 'Last 60 Days',   op: 'range'},
  {value: '90_days',       label: 'Last 90 Days',   op: 'range'},
  {value: '180_days',      label: 'Last 180 Days',  op: 'range'},
  {value: '365_days',      label: 'Last 365 Days',  op: 'range'},
  {value: 'next_7_days',   label: 'Next 7 Days',    op: 'range'},
  {value: 'next_14_days',  label: 'Next 14 Days',   op: 'range'},
  {value: 'next_60_days',  label: 'Next 60 Days',   op: 'range'},
  {value: 'next_90_days',  label: 'Next 90 Days',   op: 'range'},
  {value: 'next_180_days', label: 'Next 180 Days',  op: 'range'},
  {value: 'next_365_days', label: 'Next 365 Days',  op: 'range'},
  {value: 'this_week',     label: 'This Week',      op: 'range'},
  {value: 'last_week',     label: 'Last Week',      op: 'range'},
  {value: 'next_week',     label: 'Next Week',      op: 'range'},
  {value: 'this_month',    label: 'This Month',     op: 'range'},
  {value: 'last_month',    label: 'Last Month',     op: 'range'},
  {value: 'next_month',    label: 'Next Month',     op: 'range'},
  {value: 'this_quarter',  label: 'This Quarter',   op: 'range'},
  {value: 'last_quarter',  label: 'Last Quarter',   op: 'range'},
  {value: 'next_quarter',  label: 'Next Quarter',   op: 'range'},
  {value: 'this_year',     label: 'This Year',      op: 'range'},
  {value: 'last_year',     label: 'Last Year',      op: 'range'},
  {value: 'next_year',     label: 'Next Year',      op: 'range'},

  {value: 'sunday',        label: 'Sunday',         op: 'dow'},
  {value: 'monday',        label: 'Monday',         op: 'dow'},
  {value: 'tuesday',       label: 'Tuesday',        op: 'dow'},
  {value: 'wednesday',     label: 'Wednesday',      op: 'dow'},
  {value: 'thursday',      label: 'Thursday',       op: 'dow'},
  {value: 'friday',        label: 'Friday',         op: 'dow'},
  {value: 'saturday',      label: 'Saturday',       op: 'dow'}
];

function calculationDataType({ calculation_type, display_unit }) {
  if(calculation_type === dateType) {
    if(display_unit !== null && display_unit !== undefined) {
      return numericType;
    }
  }

  return conditionalDataTypes[calculation_type] || calculation_type;
}

function calculationFieldType({ calculation_type, display_unit }) {
  if(calculation_type === dateType) {
    if(display_unit !== null && display_unit !== undefined) {
      return calculationFieldTypes[numericType];
    }
  }

  return calculationFieldTypes[calculation_type] || calculation_type;
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export function shapeField(field) {
  const { id, name, field_type, data_type, field_options, ...rest } = field;
  return {
    ...rest,
    label:        name,
    value:        id,
    fieldType:    conditionalFieldTypes[field_type] || field_type,
    dataType:     conditionalDataTypes[data_type]   || data_type,
    fieldOptions: data_type === 'date' ? dateOptions : field_options
  };
}

// Shapes the field list to be compatible with the ConditionalEditor's
// requirements for specifying field options.
export function shapeFields(fields) {
  return fields.map(shapeField);
}

// Shapes the calculation list to be compatible with thte ConditionalEditor's
// requirements for specifying field options.
export function shapeCalculations(calculations, wrapId=true) {
  return calculations.map(({ id, name, ...calculation }) => ({
    label:      name,
    value:      wrapId ? wrapCalculationId(id) : id,
    fieldType:  calculationFieldType(calculation),
    dataType:   calculationDataType(calculation),
    fieldOptions: calculationDataType(calculation) === 'datetime' ? dateOptions : null
  }));
}
