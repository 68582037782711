import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { Button, Popup }    from 'semantic-ui-react';

import SettingsModal        from './SettingsModal';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Advanced Settings';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const AdvancedSettingsButton = (props) => {
  const [opened, setOpened] = useState(false);

  const handleClick = (evt) => {
    evt.preventDefault();
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const Btn = <Button icon='setting'
                      size='mini'
                      basic
                      onClick={ handleClick } />;

  return (
    <>
      <SettingsModal  title={ title }
                      opened={ opened }
                      onClose={ handleClose }
                      { ...props } />

      <Popup  trigger={ Btn }
              content={ title }
              position='top center' />
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

AdvancedSettingsButton.defaultProps = {

};

AdvancedSettingsButton.propTypes = {
  formField:  PropTypes.object.isRequired,
  onSubmit:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AdvancedSettingsButton;
