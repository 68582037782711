import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Label, Button }              from 'semantic-ui-react';
import get                            from 'lodash/get';

import connectResource        from 'utils/connectResource';
import { getSingleton }       from 'utils/connectors';
import { recordPermissions }  from 'resources/organizationResources';
import RecordPermissionsModal from './RecordPermissionsModal';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const buttonPresentation      = 'button';
const linkPresentation        = 'link';

const recordPermissionsModal  = '#record-permissions-modal';
const modalStateKey           = 'currentModal';

function isModalOpen(state) {
  return state[modalStateKey] === recordPermissionsModal;
}

const styles = {
  link:   { display: 'block' },
  label:  { float: 'right', clear: 'right' }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class RecordPermissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving:           false,
      [modalStateKey]:  null
    };

    this.handleClick  = this.handleClick.bind(this);
    this.handleClose  = this.handleClose.bind(this);
    this.handleSave   = this.handleSave.bind(this);
  }

  render() {
    const { data, recordType, recordId, presentation }  = this.props;
    const { saving }                                    = this.state;
    const isOpened = isModalOpen(this.state, recordPermissionsModal);

    const recordPermissions = data.recordPermissions || {};
    const scopedRecordPermissions =
      get(recordPermissions, [recordType, recordId], {});

    const permissionCount = Object.keys(scopedRecordPermissions).length;

    return (
      <Fragment>
        {
          presentation === linkPresentation
          ?  <a href={ recordPermissionsModal }
                onClick={ this.handleClick }
                style={ styles.link }>

                <span>Manage Permissions</span>
                <Label size='mini' color='blue' circular style={ styles.label }>
                  { permissionCount }
                </Label>
              </a>
          :

          presentation === buttonPresentation
          &&  <Button icon='lock'
                      basic
                      color='blue'
                      onClick={ this.handleClick } />
        }

        <RecordPermissionsModal opened={ isOpened }
                                saving={ saving }
                                recordType={ recordType }
                                recordId={ recordId }
                                recordPermissions={ scopedRecordPermissions }
                                onSave={ this.handleSave }
                                onClose={ this.handleClose } />
      </Fragment>
    );
  }

  handleClick(evt) {
    evt.preventDefault();
    this.setState({ [ modalStateKey ]: recordPermissionsModal });
  }

  handleClose() {
    this.setState({ [ modalStateKey ]: null });
  }

  async handleSave(recordPermissions, done) {
    const { recordId, recordType, update } = this.props;

    this.setState({ saving: true });

    const params = {
      record_permissions: Object.values(recordPermissions)
    };

    const response = await update(params, {
      record_type:  recordType,
      record_id:    recordId
    });

    this.setState({ saving: false });

    if(!response.errors) {
      done();
      this.handleClose();
    }
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

RecordPermissions.defaultProps = {
  presentation: 'link'
};

RecordPermissions.propTypes = {
  presentation: PropTypes.oneOf([buttonPresentation, linkPresentation]),
  recordType:   PropTypes.string.isRequired,
  recordId:     PropTypes.oneOfType([
                  PropTypes.string,
                  PropTypes.number
                ]).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(RecordPermissions, {
  key: ({ recordType, recordId }) => [recordType, recordId].join('_'),
  connectors: {
    recordPermissions: getSingleton(
      recordPermissions,
      ({ recordType, recordId }) => ({
        record_type:  recordType,
        record_id:    recordId
      })
    )
  },
  mapDispatchToProps: { ...recordPermissions.actions }
});
