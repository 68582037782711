import React, { Fragment }              from 'react';
import { List, Icon }                   from 'semantic-ui-react';

import MasterDetail                     from 'components/MasterDetail';
import withPagination                   from 'hocs/withPagination';
import withSearch                       from 'hocs/withSearch';
import { users, organizationAccount }   from 'resources/organizationResources';
import connectResource                  from 'utils/connectResource';
import { getPageAllById, getSingleton } from 'utils/connectors';
import { sortByAttributes }             from 'utils/sortComparators';
import { textSubstringFilter }          from 'utils/recordFilters';

import SideCardView   from '../SideCardView';
import DetailView     from './DetailView';
import FormView       from './FormView';

const ListItem = ({ record }) => (
  <Fragment>
    <List.Content floated='right'>
      { record.isOrgOwner && <Icon name='chess queen' color='yellow' /> }
    </List.Content>
    <List.Header>
      { record.first_name } { record.last_name }
    </List.Header>
  </Fragment>
);

class App extends React.Component {
  render() {
    const orgOwner = this.props.data.organizationAccount.owner_id;
    const records = (this.props.data.users||[]).map(user => ({
      ...user,
      isOrgOwner : user.id === orgOwner
    }));
    
    return (
      <MasterDetail masterItemComponent={ ListItem }
                    detailComponent={ DetailView }
                    sideCardComponent={ SideCardView }
                    formComponent={FormView}
                    records={records}
                    onDelete={id => this.props.destroy(null, {id})}
                    onUpdate={record => this.props.update(record, {id: record.id})}
                    onCreate={record => this.props.create(record)}
                    loading={this.props.loading}
                    resource='users'
                    listSort={ sortByAttributes('first_name', 'last_name') }
                    filterRecord={textSubstringFilter('first_name','last_name')}
                    {...this.props }/>
    );
  }
}

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors : {
    users : getPageAllById(users),
    organizationAccount : getSingleton(organizationAccount)
  },
  mapDispatchToProps : {...users.actions}
});

export default withSearch(withPagination(connectedComponent));
