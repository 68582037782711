import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import connectResource            from 'utils/connectResource';
import { clientForms }            from 'resources/organizationResources';
import { getPageAllById }         from 'utils/connectors';
import FormGroupInstancesDisplay  from './FormGroupInstancesDisplay';
import withPagination             from 'hocs/withPagination';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormGroupInstances extends Component {
  render () {
    const { loading, clientId, formId, onPageNext, data } = this.props;

    const formInstancesIndex  = data.formInstances || {};
    const formsByClient       = formInstancesIndex[clientId]  || {};
    const instancesByForm     = formsByClient[formId]         || {};
    const formInstances       = Object
                                .entries(instancesByForm)
                                .sort(([idA, ], [idB, ]) => idA - idB)
                                .map(([ , rec]) => rec);
    formInstances
    .filter(
      clientForm => (
        clientForm.form_id === formId &&
        clientForm.client_id === clientId
      )
    );

    return (
      <FormGroupInstancesDisplay  clientId={ clientId }
                                  formId={ formId }
                                  loading={ loading }
                                  formInstances={ formInstances }
                                  onDelete={ this.handleDelete }
                                  onPageNext={ onPageNext } />
    );
  }
}

// -----------------------------------------------------
// Prop Types
// -----------------------------------------------------

FormGroupInstances.propTypes = {
  loading:    PropTypes.bool,
  state:      PropTypes.object,
  clientId:   PropTypes.number,
  formId:     PropTypes.number,
  onPageNext: PropTypes.func
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedResource = connectResource(FormGroupInstances, {
  key: ({clientId, formId, page}) => [clientId, formId, page],

  connectors: [ {
    formInstances: getPageAllById(
      clientForms,
      ({ clientId, formId }) => ({ client_id: clientId, form_id: formId }),
      results => results
    )
  } ]
});

export default withPagination(connectedResource);
