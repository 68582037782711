import AuthenticatedResource from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class OrganizationUserResource extends AuthenticatedResource {
  static urlRoot = '/admin/organizations/:organization_id/users/';

  id    = undefined;
  type  = '';

  pk() {
    return this.id && this.id.toString();
  }
}
