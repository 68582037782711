import React  from 'react';
import _      from 'lodash';

// -----------------------------------------------------
// Public API
// -----------------------------------------------------

export default function(WrappedComponent, options={}) {
  let { getDefault, initialState, mapOnSave } = options;

  getDefault  = getDefault  || ((props) => (props.data.selected || props.initialState) || initialState);
  mapOnSave   = mapOnSave   || (a => a);

  // -----------------------------------------------------
  // Component Definition
  // -----------------------------------------------------

  class Form extends React.Component {

    // ---------------------------------------------------
    // Instance Properties
    // ---------------------------------------------------

    active = true

    // ---------------------------------------------------
    // Constructor
    // ---------------------------------------------------

    constructor(props) {
      super(props);
      this.state = {
        selected: getDefault(props),
        saving:   false
      };
    }

    // ---------------------------------------------------
    // React Lifecycle Methods
    // ---------------------------------------------------

    UNSAFE_componentWillReceiveProps(nextProps) {
      let nextState       = getDefault(nextProps);
      let currentState    = getDefault(this.props);
      let stateWillChange = !_.isEqual(nextState, currentState);

      if(stateWillChange) {
        this.setState({ selected: nextState });
      }
    }

    componentWillUnmount() {
      this.active = false;
    }

    // ---------------------------------------------------
    // Callbacks
    // ---------------------------------------------------

    changeState = (state) => {
      const nextSelected = {
        ...this.state.selected,
        ...state
      };

      this.setState({
        selected: nextSelected
      });
    }

    updateStateValue = (evt, { name, value }) => {
      this.changeState({ [name]: value });
    }

    doSave = (evt) => {
      evt.preventDefault();

      const { id } = this.props.match.params;
      this.setState({ saving: true });

      // -----------------------
      // Send updates to the API
      // -----------------------

      if(id) {
        this
        .props
        .onUpdate(mapOnSave(this.state.selected))
        .then(() => {
          if(this.active) {
            this.setState({ saving: false });
          }
        });

      // -----------------------
      // Send new record to the API
      // -----------------------

      } else {
        this
        .props
        .onCreate(_.omit(mapOnSave(this.state.selected)), 'id')
        .then(() => {
          if(this.active) {
            this.setState({ saving: false });
          }
        });
      }
    }

    // ---------------------------------------------------
    // Render
    // ---------------------------------------------------

    render() {
      return (
        <WrappedComponent
          selected={this.state.selected}
          saving={this.state.saving}
          onUpdateValue={this.updateStateValue}
          onUpdateState={this.changeState}
          onSave={this.doSave}
          {...this.props}
        />
      );
    }
  }

  return Form;
}
