import React, { useEffect }       from 'react';
import PropTypes                  from 'prop-types';
import { Segment, Header, Form }  from 'semantic-ui-react';
import { Redirect }               from 'react-router-dom';

import Fixed                from 'components/Fixed';
import connectForm          from 'utils/connectForm';
import connectResource      from 'utils/connectResource';
import { getById }          from 'utils/connectors';
import { dataGroups }       from 'resources/organizationResources';

import ParentGroupSelector  from './ParentGroupSelector';
import SmartGroupConditions from './SmartGroupConditions';

// -----------------------------------------------------
// Helper Functions and Constants
// -----------------------------------------------------

const initialState = {
  id:         null,
  parent_id:  null,
  name:       ''
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FormView = ({ selected,
                    loading,
                    api_errors,
                    onSave,
                    onUpdateValue,
                    saving,
                    location }) => {

  const { name, parent_id, smart_conditions }                 = selected;
  const { state: { parent: { id: referredParentId }={} }={} } = location;

  // If a parent exists in the location state, we'll use it to set the parent
  // id on the form parameters.
  useEffect(() => {
    if(referredParentId) {
      onUpdateValue(null, { name: 'parent_id', value: referredParentId });
    }
  }, [onUpdateValue, referredParentId]);

  return (
    <Segment loading={ loading }>
      { api_errors && <Redirect to='./'/> }

      <Header as='h2'>Data Group</Header>
      <Form onSubmit={ onSave }>
        <Form.Input label="Name"
                    name="name"
                    value={ name }
                    onChange={ onUpdateValue }
                    required />

        <Form.Field>
          <label>Parent Data Group</label>
          <ParentGroupSelector  name="parent_id"
                                value={ parent_id || referredParentId }
                                childGroup={ selected }
                                onChange={ onUpdateValue } />
        </Form.Field>

        <Form.Field>
          <label>SmartGroup Settings</label>
          <p>
            SmartGroups automatically gather records based upon the conditions defined
            below.  For instance, we might create a Data Group containing, <em>
            &quot;all form responses created during April 2018&quot;</em>.
          </p>
          <SmartGroupConditions conditions={ smart_conditions }
                                name="smart_conditions"
                                onChange={ onUpdateValue } />
        </Form.Field>

        <Fixed>
          <Form.Button  loading={ saving }
                        type='submit'
                        primary>Save</Form.Button>
        </Fixed>
      </Form>
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormView.defaultProps = {

};

FormView.propTypes = {
  selected: PropTypes.object.isRequired,
  match:    PropTypes.object.isRequired,
  onSave:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedForm = connectForm(FormView, { initialState });
export default connectResource(connectedForm, {
  connectors: {
    selected: getById(dataGroups, ({ match: { params: { id } } }) => id)
  },

  mapDispatchToProps: { ...dataGroups.actions }
});
