import React, { Component, useEffect } from 'react';

import { useApiErrorContext, ApiErrorContextProvider }  from './ApiErrorContext';
import NetworkError                                     from './NetworkError';

// Wrapper component for consolidating errors from the ApiErrorContext and errors
// caught in an error boundary, and rendering them with the appropriate error
// handler component.
const ErrorContextHandler = ({ networkError, children }) => {
  const { error, setError, local } = useApiErrorContext();

  useEffect(() => {
    // If we have a network error, we call setError.  This sets the value that
    // the NetworkError component will read.
    if(networkError && networkError !== error) {
      setError(networkError);
    }
  }, [networkError, error, setError]);

  // No errors? We're done.
  if(!error) {
    return children;
  }

  // if local is true, we know that a NetworkError component is futher down
  // in the render tree.  We'll just return the children, and let the that
  // NetworkError component handle the displaying of the error.
  if(local) {
    return children;
  } else {

    // We only want to show children when the error is a 422.  This is because
    // we currently display 422 errors in an overlay.
    const { status }    = error;
    const showChildren  = status === 422;

    // Order matters here:  If we place the NetworkError above the children,
    // we cause the children to completely re-render.  In the case of a form,
    // this means a loss of form state.  NetworkError must be the last element
    // in the fragment.
    return (
      <>
        { showChildren && children }
        <NetworkError />
      </>
    );
  }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

// Component used to create an error boundary for network calls occuring further
// down the component tree.  This component also provides a context for pushing
// errors up to this handler from code paths that lie outside of the main render
// path.
class ApiErrorHandler extends Component {
  state = {
    networkError: null
  };

  render() {
    const { networkError }  = this.state;
    
    return (
      <ApiErrorContextProvider>
        <ErrorContextHandler networkError={ networkError } { ...this.props } />
      </ApiErrorContextProvider>
    );
  }

  // Make this component behave as an error boundary.
  static getDerivedStateFromError(error) {
    const isNetworkError =
      Object.prototype.hasOwnProperty.call(error, 'status');
    
    if(isNetworkError) {
      return { networkError: error };
    }
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ApiErrorHandler;
