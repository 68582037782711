import _ from 'lodash';
import { combineReducers } from 'redux';

import * as ors       from 'resources/organizationResources';
import * as ars       from 'resources/adminResources';
import auth           from 'resources/auth.js';
import authScopes     from './authenticationScopes';
import error          from './error';
import orgdomain      from './orgdomain';
import userAccount    from 'resources/userAccount';
import impersonation  from 'resources/impersonation';
import invitations    from 'resources/invitations';
import fieldTypes     from 'resources/fieldTypes';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function getReducers(resources) {
  return  Object
          .values(resources)
          .reduce((reducers, { resourceName, reducer }) => {
            reducers[resourceName] = reducer;
            return reducers;
          }, {});
}

// -----------------------------------------------------
// Reducer
// -----------------------------------------------------

const defaultReducers = {
  organization:          combineReducers(_.pickBy(getReducers(ors), i=>i)),
  admin:                 combineReducers(_.pickBy(getReducers(ars), i=>i)),
  auth:                  auth.reducer,
  authenticationScopes:  authScopes,
  userAccount:           userAccount.reducer,
  invitations:           invitations.reducer,
  impersonation:         impersonation.reducer,
  fieldTypes:            fieldTypes.reducer,
  error,
  orgdomain
};

export const buildRootReducer = ({ additionalReducers = {} }) => (
  combineReducers( Object.assign({}, defaultReducers, additionalReducers) )
);

const rootReducer = combineReducers(defaultReducers);
export default rootReducer;
