import React                        from 'react';
import { Button, Table, Container } from 'semantic-ui-react';
import Item                         from './Item';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const RecordList = ({ pinnedMessages, onAddNew, onDelete, onEdit }) => {
  return (
    <>
      <Container textAlign='right'>
        <Button onClick={ onAddNew } content='Create Pinned Message' labelPosition='left' icon='plus' primary />
      </Container>

      <Table basic style={{ marginTop: '2rem' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Title</Table.HeaderCell>
            <Table.HeaderCell width={5}>Content</Table.HeaderCell>
            <Table.HeaderCell width={2}>Updated At</Table.HeaderCell>
            <Table.HeaderCell width={2}>Expires At</Table.HeaderCell>
            <Table.HeaderCell width={2}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            Object.entries(pinnedMessages).map(([, pinnedMessage]) => (
              <Item key={`item-${pinnedMessage.id}`}
                    pinnedMessage={ pinnedMessage }
                    onDelete={ onDelete }
                    onEdit={ onEdit } />
            ))
          }
        </Table.Body>
      </Table>
    </>
  );
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default RecordList;
