import React, { Component }      from 'react';
import FilterSelection           from '../../../FilterSelection';
import { defaultFilters }        from 'utils/reportDefinitions';
import { isEqual }               from 'lodash';

import 'style/containers/organization/reports/wizard.css';

const emptyFilters = { conditions: {} };

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FilterSelector extends Component {
  constructor(props) {
    super(props);

    const { filters } = props;

    this.state = {
        showFiltersSelector: {
          agg:      filters && filters.agg,
          base:     filters && filters.base,
          target:   filters && filters.target,
      }
    };

    this.handleAddFilters    = this.handleAddFilters.bind(this);
    this.handleFiltersChange = this.handleFiltersChange.bind(this);
  }

  render() {
    const { filters,
            windowComparison=false,
            aggType=false,
            ...forwardProps } = this.props;

    const { showFiltersSelector } = this.state;

    const filtersAgg       = filters && filters.agg ? filters.agg : defaultFilters;
    const filtersBase    = filters && filters.base ? filters.base : defaultFilters;
    const filtersTarget   = filters && filters.target ? filters.target : defaultFilters;

    if (aggType) {
      return (
        <div>
          { !showFiltersSelector.agg &&
            <div><a href='#filters' onClick={ () => this.handleAddFilters('agg') }>+ Add a filter</a></div>
          }


          { showFiltersSelector.agg &&
            <FilterSelection  filters={ filtersAgg }
                              filterType={ 'agg' }
                              { ...forwardProps }
                              onChange={this.handleFiltersChange} >
              <label className="labelStyle">Select Filters</label>
            </FilterSelection>
          }
        </div>
      );
    } else {
      return (
        <div>
          { !showFiltersSelector.base &&
            <div><a href='#filters' onClick={ () => this.handleAddFilters('base') }>+ Add a filter { windowComparison ? 'for earliest' : 'for window' }</a></div>
          }

          { showFiltersSelector.base &&
            <FilterSelection  filters={ filtersBase }
                              filterType={ 'base' }
                              { ...forwardProps }
                              onChange={this.handleFiltersChange} >
              <label className="labelStyle">Select Filters { windowComparison ? 'for Ealiest' : 'for window' }</label>
            </FilterSelection>
          }

          { windowComparison && !showFiltersSelector.target &&
            <div><a href='#filters' onClick={ () => this.handleAddFilters('target') }>+ Add a filter for Latest</a></div>
          }

          { windowComparison && showFiltersSelector.target &&
            <FilterSelection  filters={ filtersTarget }
                              filterType={ 'target' }
                              { ...forwardProps }
                              onChange={this.handleFiltersChange} >
              <label className="labelStyle">Select Filters for Latest</label>
            </FilterSelection>
          }
        </div>
      );
    }
  }

  handleAddFilters(filterType) {
    const { showFiltersSelector } = this.state;

    this.setState({
      showFiltersSelector: {
        ...showFiltersSelector,
        [filterType]: true
      }
    });
  }

  handleFiltersChange({ filterType, conditions }) {
    const { showFiltersSelector } = this.state;
    const { filters, onChange } = this.props;

    const nextFilters = { ...filters };

    if (isEqual(conditions, defaultFilters) || isEqual(conditions, emptyFilters)) {
      delete nextFilters[filterType];

      this.setState({
        showFiltersSelector: {
          ...showFiltersSelector,
          [filterType]: false
        }
      });
    } else {
      nextFilters[filterType] = conditions;
    }

    onChange({ filters: nextFilters });
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FilterSelector;
