import React      from 'react';
import PropTypes  from 'prop-types';
import { Form }   from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const AddressComponent = ({ value, onChange, ...forwardProps }) => {
  const handleChange = (evt, { value, name }) => {
    onChange(evt, { [name]: value });
  };

  return (
    <Form.Input { ...forwardProps }
                value={ value }
                onChange={ handleChange } />
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

AddressComponent.defaultProps = {
  value: ''
};

AddressComponent.propTypes = {
  value: PropTypes.string,
  name: PropTypes.oneOf([
    'city',
    'state',
    'streetAddress',
    'unitNumber',
    'postalCode'
  ]).isRequired,

  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AddressComponent;
