import React      from 'react';
import PropTypes  from 'prop-types';

import { Table }  from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Header = ({ actionTypes }) => {
  const labels = Object.values(actionTypes);

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Fields</Table.HeaderCell>
        {
          labels.map((label) => (
            <Table.HeaderCell key={ label }>{ label }</Table.HeaderCell>
          ))
        }
      </Table.Row>
    </Table.Header>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Header.propTypes = {
  actionTypes: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Header;
