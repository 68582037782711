import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import DatePicker                       from 'react-datepicker';
import { Modal, Button,
         Header, Form, Checkbox }       from 'semantic-ui-react';
import uniqueId                         from 'utils/uniqueId';
import { weekdays } from 'moment';

// -----------------------------------------------------
// Helper functions
// -----------------------------------------------------

const normDate = (dateString) => {
  return dateString ? new window.Date(dateString) : undefined;
};

const timeFormat  = 'h:mm aa';
const dateFormat  = 'MMMM d, yyyy h:mm aa';



// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormModal extends Component {
  constructor(props) {
    super(props);

    this.formId = uniqueId();

    this.state = {
      published_at: props.published_at,
      expired_at:   props.expired_at,
      dow:          props.dow,
      time_start:   props.time_start,
      time_end:     props.time_end,
      translate:    props.translate
    };

    this.handleSubmit             = this.handleSubmit.bind(this);
    this.handlePublishedAtChange  = this.handlePublishedAtChange.bind(this);
    this.handleExpiredAtChange    = this.handleExpiredAtChange.bind(this);
    this.handleDowChange          = this.handleDowChange.bind(this);
    this.handleTimeChange         = this.handleTimeChange.bind(this);
    this.handleTranslateChange    = this.handleTranslateChange.bind(this);
  }

  render() {
    const { opened, onClose, title }    = this.props;
    const { published_at, expired_at, dow=[], time_start, time_end, translate }  = this.state;

    const published_at_date = normDate(published_at);
    const expired_at_date   = normDate(expired_at);
    const isEveryDay        = dow.length === 0;

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon='pencil' content={ title } />
        <Modal.Content>
          <Form onSubmit={ this.handleSubmit } id={ this.formId }>
            <Form.Group widths='equal'>
              <Form.Field width={ 8 }>
                <label>Publish Date/Time</label>
                <div className='fluid'>
                  <DatePicker onChange={ this.handlePublishedAtChange }
                              selected={ published_at_date }
                              showTimeSelect
                              timeFormat={ timeFormat }
                              timeIntervals={15}
                              dateFormat={ dateFormat }
                              timeCaption="Time"
                              isClearable={true} />
                </div>
              </Form.Field>

              <Form.Field width={ 8 }>
                <label>Expiration Date/Time</label>
                <div className='fluid'>
                  <DatePicker onChange={ this.handleExpiredAtChange }
                              selected={ expired_at_date }
                              showTimeSelect
                              timeFormat={ timeFormat }
                              timeIntervals={15}
                              dateFormat={ dateFormat }
                              timeCaption="Time"
                              isClearable={true} />
                </div>
              </Form.Field>
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Field width={ 8 }>
                <label>Custom Recurrence</label>
                <div className='fluid'>
                   <Checkbox label='Every Day'
                             value='all'
                             checked={ isEveryDay }
                             onChange={ this.handleDowChange } />

                   { weekdays().map((day, index) =>
                      <div key={day}>
                        <Checkbox label={ day }
                                  value={ index }
                                  checked={ dow.includes(index) }
                                  onChange={ this.handleDowChange } />
                      </div>
                     ) }
                </div>
              </Form.Field>

              <Form.Field width={ 4 }>
                <label>From:</label>
                <Form.Input placeholder='09:00 AM'
                            name='time_start'
                            value={time_start}
                            onChange={ this.handleTimeChange }
                />
                <p>Leave empty if you don&apos;t want to set a custom time interval</p>
              </Form.Field>

              <Form.Field width={ 4 }>
                <label>To:</label>
                <Form.Input placeholder='05:00 PM'
                            name='time_end'
                            value={time_end}
                            onChange={ this.handleTimeChange }
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>Translate</label>
              <Checkbox label='Allow user to translate form to other languages (via Google Translate)'
                        checked={ translate }
                        onChange={ this.handleTranslateChange } />
              </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ onClose } negative basic>Cancel</Button>
          <Button type='submit' form={ this.formId } positive basic>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const data        = { ...this.state, id: this.props.id };
    const { onClose } = this.props;

    this.props.onSubmit(data, onClose);
  }

  handlePublishedAtChange(date) {
    const dateString = date && date.toISOString();
    this.setState({ published_at: dateString });
  }

  handleExpiredAtChange(date) {
    const dateString = date && date.toISOString();
    this.setState({ expired_at: dateString });
  }

  handleDowChange(e, { value, checked }) {
    const { dow=[] }  = this.state;

    const newDow = (value === 'all' && checked) ?
                    [] :
                    checked ?
                      dow.concat(value):
                      dow.filter((day) => day !== value);

    this.setState({ dow: newDow });
  }

  handleTimeChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleTranslateChange(e, { checked }) {
    this.setState({ translate: checked });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormModal.defaultProps = {
  published_at: '',
  expired_at:   '',
  dow:          [],
  translate:    false,
  opened:       false
};

FormModal.propTypes = {
  id:           PropTypes.number,
  published_at: PropTypes.string,
  expired_at:   PropTypes.string,
  translate:    PropTypes.bool,
  opened:       PropTypes.bool,
  title:        PropTypes.string.isRequired,
  onSubmit:     PropTypes.func.isRequired,
  onClose:      PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormModal;
