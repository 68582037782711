import React, { Fragment, useState }  from 'react';
import PropTypes            from 'prop-types';

import {
  Segment,
  Header,
  Message,
  Divider,
  Icon,
  Table,
  Button,
  Ref }  from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable }      from 'react-beautiful-dnd';

import CreateButton from './CreateButton';
import OrderButton  from './OrderButton';
import Record       from './Record';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Calculations = ({ loading, calculations, fields, onSave, onSaveOrder, onDelete, onDragEnd }) => {
  const [orderingMode, setOrderingMode] = useState(false);

  return (
    <Segment loading={ loading }>
      <Header as='h3'>Calculations</Header>
      <div style={{ marginTop: '2rem' }}>
        {
          calculations && calculations.length
          ? <DragDropContext onDragEnd={ onDragEnd }>
              <Droppable droppableId="field-option-list">
                {
                  (provided) => (
                    <Ref innerRef={ provided.innerRef }>
                    <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Equation</Table.HeaderCell>
                  <Table.HeaderCell>Variables</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>
                    { !orderingMode &&
                      <CreateButton onSubmit={ onSave } fields={ fields } calculations={ calculations } />
                    }
                    <OrderButton onClick={ () => setOrderingMode(!orderingMode) } />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  calculations.map((calculation, i) => {
                        return(
                          <Draggable  key={ i }
                                      draggableId={ i }
                                      index={ i }>
                            {
                              (provided, snapshot) => (
                                <Ref innerRef={ provided.innerRef }>
                    <Record key={ calculation.id }
                            fields={ fields }
                            calculations={ calculations }
                            calculation={ calculation }
                            onSave={ onSave }
                            onDelete={ onDelete }
                            isDraggable= { orderingMode }
                            draggableProvided={ provided }
                            draggableSnapshot={ snapshot } />
                                </Ref>
                              )
                            }
                          </Draggable>

                  ); } )
                }
              </Table.Body>
                </Table>
                </Ref>
                  )
                }
              </Droppable>
            </DragDropContext>

          : <Fragment>
              <Message info icon>
                <Icon name='info circle' />
                This form currently has no calculations defined.
              </Message>
              <Divider horizontal>Add a Calculation</Divider>
              <div>
                <CreateButton onSubmit={ onSave }
                              fields={ fields }
                              calculations={ calculations } /> Add a calculation.
              </div>
            </Fragment>
        }

        { orderingMode &&
          <div className='ui right aligned container'>
            <Button style={ { marginTop: '1em' } }
                    primary
                    icon='refresh'
                    labelPosition='left'
                    content='Save order'
                    onClick={ () => { onSaveOrder().then( setOrderingMode(false) ); } } />
          </div>
        }
      </div>
    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Calculations.defaultProps = {
  loading:      false,
  calculations: []
};

Calculations.propTypes = {
  loading:      PropTypes.bool,
  calculations: PropTypes.arrayOf(
    PropTypes.shape({
      id:         PropTypes.number,
      name:       PropTypes.string,
      variables:  PropTypes.array,
      equation:   PropTypes.string
    })
  ),
  fields:   PropTypes.array.isRequired,
  onSave:   PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Calculations;
