import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import { Grid, Form, Header, Confirm }  from 'semantic-ui-react';
import { reportFormIds }              from 'utils/reportDefinitions';
import SelectionTable                 from './SelectionTable';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
const nonClientReportTypes = ['public_forms', 'internal_forms'];

function selectedForms(reportDefinition, forms) {
  const formIds = reportFormIds(reportDefinition);

  return formIds
          .map(formId => forms.find(({ id }) => id === formId))
          .filter(f => f);
}

// Used to filter out internal forms from the form selector
function filterFormsByReportType(report_type) {
  return ({ form_type }) => {
    const isInternal = (form_type === 'internal');
    return (report_type === 'internal_forms') ? isInternal : !isInternal;
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormFieldSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // references a form record
      currentForm:        null,
      showMultiformAlert: false,
    };

    this.handleFormChange         = this.handleFormChange.bind(this);
    this.handleAddAllFields       = this.handleAddAllFields.bind(this);
    this.closeAlert               = this.closeAlert.bind(this);
  }

  render() {
    const { currentForm, showMultiformAlert } = this.state;

    const { forms,
            fields,
            formCalculations,
            reportDefinition,
            users,
            functionType,
            onAddField,
            onChange,
            onChangeWindow,
            onFunctionTypeChange }  = this.props;

    const { report_type }       = reportDefinition;

    const reportForms     = selectedForms(reportDefinition, forms);
    if (currentForm && !reportForms.includes(currentForm)) {
      reportForms.push(currentForm);
    }

    const formOptions     = forms
                            .filter(filterFormsByReportType(report_type))
                            .map(({ id, name }) => ({
                              value:  id,
                              text:   name
                            }))
                            .sort(({ text: a }, { text: b }) => a > b ? 1 : -1);


    return (
      <Form>
        <Header as='h2'>Select Report Fields</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Select  label='Choose a form to select one or more of its fields'
                            selectOnBlur={ false }
                            options={ formOptions }
                            onChange={ this.handleFormChange }
                            clearable
                            search />
            </Grid.Column>
          </Grid.Row>
          {
            reportForms.map( currentForm => (
            <Grid.Row key={ currentForm.id }>
              <Grid.Column>
                <SelectionTable form={ currentForm }
                                allFields={ fields }
                                formCalculations={ formCalculations }
                                functionType={ functionType }
                                reportDefinition={ reportDefinition }
                                onChange={ onChange }
                                onChangeWindow={ onChangeWindow }
                                onAddField= { onAddField }
                                onAddAllFields= { this.handleAddAllFields }
                                onFunctionTypeChange={ onFunctionTypeChange }
                                forms={ forms }
                                users={ users} />
              </Grid.Column>
            </Grid.Row>
            ))
          }

        </Grid>

        <Confirm  open={ showMultiformAlert }
                  dimmer="inverted"
                  content="Multiple forms cannot be used on a Public Form report as there is no client record to connect on."
                  onCancel={ this.closeAlert }
                  onConfirm={ this.closeAlert } />
      </Form>
    );
  }

  handleFormChange(evt, { value }) {
    const { reportDefinition, onFormChange }  = this.props;
    const { report_type }       = reportDefinition;

    const formIds = reportFormIds(reportDefinition);

    // if this is a public form, and there's already a form associated with the
    // reportDefinition and the selected form is different than that form, alert
    // the user this is not a valid operation.  Otherwise, update the current
    // form.
    if( (nonClientReportTypes.includes(report_type))
        && formIds.length > 0
        && !formIds.includes(value)) {
      this.setState({ showMultiformAlert: true });
    } else {
      this.setCurrentForm(value);
      onFormChange(value);
    }
  }

  handleAddAllFields(formId) {
    const { onAddAllFields, forms }  = this.props;

    const currentForm = forms.find(({ id }) => id === formId);
    const newFields = currentForm.field_ids.map((fieldId) => ({
                            form: formId,
                            field: fieldId,
                            type: 'field'
                          }));

    onAddAllFields(newFields);
  }

  closeAlert() {
    this.setState({ showMultiformAlert: false });
  }

  setCurrentForm(formId) {
    const { forms }   = this.props;
    const currentForm = forms.find(({ id }) => id === formId);

    this.setState({ currentForm });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormFieldSelection.defaultProps = {
  reportDefinition:   {},
  forms:              [],
  fields:             [],
  formCalculations:   {}
};

FormFieldSelection.propTypes = {
  reportDefinition:   PropTypes.object,
  forms:              PropTypes.array,
  fields:             PropTypes.array,
  formCalculations:   PropTypes.object,
  onChange:           PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FormFieldSelection;
