import React, { Component } from 'react';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function(WrappedComponent) {
  return class AnonymousComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        query: null
      };

      this.handleOnSearch = this.handleOnSearch.bind(this);
    }

    render() {
      const { query } = this.state;

      return (
        <WrappedComponent { ...this.props }
                          query={ query }
                          onSearch={ this.handleOnSearch } />
      );
    }

    handleOnSearch({ query }) {
      this.setState({ query });
    }
  };
}
