import React            from 'react';
import PropTypes        from 'prop-types';
import { Icon, Popup }  from 'semantic-ui-react';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const initialTitle = 'This field relies upon values from other fields in this form.  Please open advanced settings to configure.';

const parametersAreMissingFrom = (mapping) => (
  p => mapping[p] === undefined || mapping[p] === null
);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ParameterWarning = ({ title, formField: { metadata, parameters_mapping } }) => {
  const apiParameters = metadata?.api_parameters;

  if(!apiParameters?.length) {
    return null;
  }

  const needsParametersMapping  = apiParameters
                                  .some(
                                    parametersAreMissingFrom(parameters_mapping)
                                  );

  if(!needsParametersMapping) {
    return null;
  }

  const Warning = <Icon name='warning sign' color='red' />;

  return (
    <div className='pull-right'>
      <Popup  trigger={ Warning }
              content={ title }
              position='top center' />
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ParameterWarning.defaultProps = {
  title:  initialTitle
};

ParameterWarning.propTypes = {
  formField:  PropTypes.object.isRequired,
  title:      PropTypes.string
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ParameterWarning;
