import React, { Component }            from 'react';
import { Segment, Grid  } from 'semantic-ui-react';
import connectResource                 from 'utils/connectResource';
import { getById, getAllById }  from 'utils/connectors';
import { reportFormIds }  from 'utils/reportDefinitions';
import {
        reportDefinitions,
        forms,
        fields,
        calculations,
        reportViews }                 from 'resources/organizationResources';
import Fixed                from 'components/Fixed';
import WizardButtons        from 'containers/organization/reportDefinitions/FormView/WizardPanel/WizardButtons';

import ChartTypeSelection   from  './ChartTypeSelection';
import Configuration        from  './Configuration';

import { findIndex } from 'lodash';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

function reportScope({ match: { params: { id } } }) {
  return { report_id: id };
}

function fieldIndex(reportDefinition, currentView, prop) {
  if (reportDefinition === null || currentView.data[prop] == null) {
    return -1;
  }
  return findIndex(reportDefinition.fields, ['field', currentView.data[prop].field]);
}

function buildPreviousView(isNew, reportView, reportDefinition) {
  if (isNew || reportView === null || reportDefinition === null) { return {}; }

  const series = reportView.data.series || [];

  return {
    name:      reportView.name,
    view_type: reportView.view_type,
    settings:  reportView.settings,
    xaxis:     fieldIndex(reportDefinition, reportView, 'xaxis'),
    yaxis:     fieldIndex(reportDefinition, reportView, 'yaxis'),
    xaxis_label: reportView.xaxis_label,
    yaxis_label: reportView.yaxis_label,
    series:    series.map((s) => findIndex(reportDefinition.fields, s)),
    group_by:  fieldIndex(reportDefinition, reportView, 'group_by'),
    zoom:      reportView.data.zoom
  };
}

const defaultView = {
  name:      null,
  view_type: null,
  xaxis:     null,
  yaxis:     null,
  xaxis_label: null,
  yaxis_label: null,
  group_by:  null,
  zoom:      null,
  series:    [],
  settings:  [],
};

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class FormView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      loading: false,
      formCalculations: {},
      formCalculationsFetched: false,
      currentViewChanges: {}
    };

    this.handleStepChange  = this.handleStepChange.bind(this);
    this.handleChartSelection  = this.handleChartSelection.bind(this);
    this.handleViewChanges  = this.handleViewChanges.bind(this);
    this.onSave  = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    const { data }    = this.props;
    const { formCalculationsFetched }    = this.state;
    const { reportDefinition={} } = data;

    if (!formCalculationsFetched && reportDefinition) {
      this.setState({ formCalculationsFetched: true });
      const formIds = reportFormIds(reportDefinition);
      await this.updateFormCalculations(formIds);
    }
  }

  render() {
    const { data, match: { params: { viewId } } }                  = this.props;
    const { step, formCalculations, currentViewChanges }           = this.state;
    const { reportDefinition={}, reportView, forms={}, fields={} } = data || [];

    const isNew       = viewId === undefined;
    const prevView    = buildPreviousView(isNew, reportView, reportDefinition);
    const currentView = {
      ...defaultView,
      ...prevView,
      ...currentViewChanges
    };

    return (
      <Segment>
        <Grid>
        <Grid.Row>
        <Grid.Column>
          { (step === 0 && isNew) &&
            <ChartTypeSelection onChartSelection={this.handleChartSelection} />
          }
          { (step === 1 || !isNew) &&
            <Configuration reportDefinition={ reportDefinition }
                          forms={ forms }
                          fields={ fields }
                          formCalculations={ formCalculations }
                          currentView={ currentView }
                          isNew={isNew}
                          onChange={ this.handleViewChanges }
                          />
          }
          <Grid.Row>
            <Grid.Column>
              <Fixed>
                <WizardButtons  step={ step }
                                numberOfSteps={ (isNew) ? 2 : 1 }
                                onChange={ this.handleStepChange }
                                onSave={ this.onSave } />
              </Fixed>
            </Grid.Column>
          </Grid.Row>

        </Grid.Column>
        </Grid.Row>
        </Grid>
      </Segment>
      );
  }

  handleStepChange(evt, { value }) {
    const { step }  = this.state;
    const nextStep  = step + value;

    this.setState({ step: nextStep });
  }

  handleChartSelection(evt, { value }) {
    const { step, currentViewChanges }  = this.state;

    this.setState({
      currentViewChanges: {
        ...currentViewChanges,
        view_type: value
      },
      step: step + 1
    });
  }

  handleViewChanges(evt, { name, value }) {
    const { currentViewChanges } = this.state;
    const newView = {
      ...currentViewChanges,
      [name]: value
    };

    this.setState({ currentViewChanges: newView });
  }

  async onSave() {
    const { data, create, update } = this.props;
    const { currentViewChanges }   = this.state;

    const reportDefinition  = data.reportDefinition || {};
    const { fields=[] }     = reportDefinition;
    const reportView        = data.reportView || {};
    const isNew             = reportView.id === null || reportView.id === undefined;

    const chartData = {
      ...reportView.data
    };

    if (Object.prototype.hasOwnProperty.call(currentViewChanges, 'xaxis')) {
      chartData['xaxis']  = fields[currentViewChanges.xaxis];
    }

    if (Object.prototype.hasOwnProperty.call(currentViewChanges, 'yaxis')) {
      chartData['yaxis']  = fields[currentViewChanges.yaxis];
    }

    if (Object.prototype.hasOwnProperty.call(currentViewChanges, 'xaxis_label')) {
      chartData['xaxis_label']  = currentViewChanges.xaxis_label;
    }

    if (Object.prototype.hasOwnProperty.call(currentViewChanges, 'yaxis_label')) {
      chartData['yaxis_label']  = currentViewChanges.yaxis_label;
    }

    if (Object.prototype.hasOwnProperty.call(currentViewChanges, 'series')) {
      chartData['series'] = currentViewChanges.series.map((index) => fields[index]);
    }

    if (Object.prototype.hasOwnProperty.call(currentViewChanges, 'group_by')) {
      chartData['group_by']  = fields[currentViewChanges.group_by];
    }

    if (Object.prototype.hasOwnProperty.call(currentViewChanges, 'zoom')) {
      chartData['zoom']  = currentViewChanges.zoom;
    }

    const newReportView = {
      ...currentViewChanges,
      data: chartData
    };

    const response = isNew
      ? await create(newReportView, { report_id: reportDefinition.id })
      : await update(newReportView, { id: reportView.id, report_id: reportDefinition.id });

    if(!response.errors) {
      this.props.history.push(`/organization/reports/${reportDefinition.id}/views/${response.id}`);
    }
  }

  // updates the state with all calculations used by each of the forms referenced
  // in the report definition.
  async updateFormCalculations(formIds) {
    const { fetchCalculations } = this.props;
    const fetchedCalculations   = {};

    for (const id of formIds) {
      const calculations = await fetchCalculations(null, { form_id: id });

      if(calculations.length > 0) {
        fetchedCalculations[id] = calculations;
      }
    }

    this.setState({ formCalculations: fetchedCalculations });
  }

}

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default connectResource(FormView, {
  connectors: {
    reportDefinition: getById(
      reportDefinitions, ({ match: { params: { id } } }) => id
    ),
    reportView: getById(
      reportViews, ({ match: { params: { viewId } } }) => viewId, reportScope
    ),
    forms:        getAllById(forms, ()=>({}), true),
    fields:       getAllById(fields, ()=>({}), true),
  },
  mapDispatchToProps: {
    create: reportViews.actions.create,
    update: reportViews.actions.update,
    fetchCalculations: calculations.actions.index
  }
});
