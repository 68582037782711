import React, { createContext, useContext } from 'react';

const HistoryContext = createContext({
  recordType: null,
  recordId:   null,
  onRollback: () => {}
});

const HistoryContextProvider = ({ children, recordType, recordId, onRollback }) => {
  return (
    <HistoryContext.Provider value={ { recordType, recordId, onRollback } }>
      { children }
    </HistoryContext.Provider>
  );
};


const useHistoryContext = () => useContext(HistoryContext);

export { useHistoryContext };
export default HistoryContextProvider;

