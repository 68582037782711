import { rawContent } from 'utils/content';
import { isEmpty }    from 'lodash';

const genderOptions = Object.entries(rawContent('organization.clients.gender'))
                      .map(([value, label]) => ({ value, label }));


const clientFormsType = 'client_forms';
const publicFormsType = 'public_forms';
const internalFormsType = 'internal_forms';
const systemType      = 'system';
const fieldsType      = 'fields';
const queryType       = 'query';
const householdType   = 'household';
const actorExpr       = /(?:created_by|updated_by)$/;
const responsePrefixExpr = 'response_';
const responsePrefixText = 'Response';

const clientFields = [
  {
    id:             'client_id',
    name:           'Client ID',
    field_type:     'number',
    data_type:      'numeric'
  }, {
    id:             'client_dob',
    name:           'Client DOB',
    field_type:     'date',
    data_type:      'date'
  }, {
    id:             'client_age',
    name:           'Client Age',
    field_type:     'number',
    data_type:      'numeric'
  }, {
    id:             'client_dod',
    name:           'Client Date Deceased',
    field_type:     'date',
    data_type:      'date'
  }, {
    id:             'client_active',
    name:           'Client Active?',
    field_type:     'checkbox',
    data_type:      'boolean'
  }, {
    id:             'client_gender',
    name:           'Client Gender',
    field_type:     'select-one',
    data_type:      'string',
    field_options:  genderOptions
  }, {
    id:             'client_name',
    name:           'Client Full Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'client_first_name',
    name:           'Client First Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'client_middle_name',
    name:           'Client Middle Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'client_last_name',
    name:           'Client Last Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'client_ssn_suffix',
    name:           'Client Last 4 of SSN',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'client_created_at',
    name:           'Client Created At',
    field_type:     'date',
    data_type:      'date'
  }, {
    id:             'client_updated_at',
    name:           'Client Updated At',
    field_type:     'date',
    data_type:      'date'
  }, {
    id:             'household_id',
    name:           'Household ID',
    field_type:     'number',
    data_type:      'numeric'
  }, {
    id:             'household_title',
    name:           'Household Title',
    field_type:     'freeform-short',
    data_type:      'string'
  }
];

const clientAdminFields = [
  {
    id:             'client_created_by',
    name:           'Client Created By',
    field_type:     'select-one',
    data_type:      'string'
  }, {
    id:             'client_updated_by',
    name:           'Client Updated By',
    field_type:     'select-one',
    data_type:      'string'
  }
];

const householdFields = [
  {
    id:             'household_id',
    name:           'Household ID',
    field_type:     'number',
    data_type:      'numeric'
  },{
    id:             'household_title',
    name:           'Household Title',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'household_member_name',
    name:           'Household Member Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'household_member_dob',
    name:           'Household Member DOB',
    field_type:     'date',
    data_type:      'date'
  }, {
    id:             'household_member_gender',
    name:           'Household Member Gender',
    field_type:     'select-one',
    data_type:      'string',
    field_options:  genderOptions
  }, {
    id:             'household_member_relationship',
    name:           'Household Member Relationship',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'household_member_status',
    name:           'Client Status',
    field_type:     'checkbox',
    data_type:      'boolean'
  }, {
    id:             'client_id',
    name:           'Client ID',
    field_type:     'number',
    data_type:      'numeric'
  }
];

const formFields = [
  {
    id:             'form_id',
    name:           'Form ID',
    field_type:     'number',
    data_type:      'numeric'
  }, {
    id:             'form_name',
    name:           'Form Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }
];

const userFields = [
  {
    id:             'user_id',
    name:           'User ID',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'user_first_name',
    name:           'User First Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'user_last_name',
    name:           'User Last Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'user_full_name',
    name:           'User Full Name',
    field_type:     'freeform-short',
    data_type:      'string'
  }, {
    id:             'user_permission_groups',
    name:           'User Permission Groups',
    field_type:     'freeform-short',
    data_type:      'string'
  }
];

const responseFields = {
  [clientFormsType]: [
    {
      id:             'response_id',
      name:           'Client Response ID',
      field_type:     'number',
      data_type:      'numeric'
    }, {
      id:             'created_by',
      name:           'Created By',
      field_type:     'select-one',
      data_type:      'string'
    }, {
      id:             'created_at',
      name:           'Created At',
      field_type:     'date',
      data_type:      'date'
    }, {
      id:             'updated_by',
      name:           'Updated By',
      field_type:     'select-one',
      data_type:      'string'
    }, {
      id:             'updated_at',
      name:           'Updated At',
      field_type:     'date',
      data_type:      'date'
    }, {
      id:             'completed_at',
      name:           'Recorded At',
      field_type:     'date',
      data_type:      'date'
    }
  ],

  [publicFormsType]: [
    {
      id:             'response_id',
      name:           'Public Response ID',
      field_type:     'number',
      data_type:      'numeric'
    }, {
      id:             'client_link',
      name:           'Linked to Client?',
      field_type:     'checkbox',
      data_type:      'boolean'
    }, {
      id:             'created_at',
      name:           'Created At',
      field_type:     'date',
      data_type:      'date'
    }, {
      id:             'updated_at',
      name:           'Updated At',
      field_type:     'date',
      data_type:      'date'
    }, {
      id:             'updated_by',
      name:           'Updated By',
      field_type:     'select-one',
      data_type:      'string'
    }
  ],

  [internalFormsType]: [
    {
      id:             'response_id',
      name:           'Record ID',
      field_type:     'number',
      data_type:      'numeric'
    }, {
      id:             'created_at',
      name:           'Created At',
      field_type:     'date',
      data_type:      'date'
    }, {
      id:             'created_by',
      name:           'Created By',
      field_type:     'select-one',
      data_type:      'string'
    }, {
      id:             'updated_at',
      name:           'Updated At',
      field_type:     'date',
      data_type:      'date'
    }, {
      id:             'updated_by',
      name:           'Updated By',
      field_type:     'select-one',
      data_type:      'string'
    }
  ]
};

const responseTypeField = {
      id:             'response_type',
      name:           'Response Type',
      field_type:     'freeform-short',
      data_type:      'string'
    };

function systemTypeResponseFields() {
  return responseFields[clientFormsType]
         .filter(({ id }) => !actorExpr.test(id) )
         .map( field => {
            if (field.id.startsWith(responsePrefixExpr)) {
                return field;
              } else {
                return {
                  ...field,
                  name: responsePrefixText + ' ' + field.name,
                  id: responsePrefixExpr + field.id
                };
              }
          });
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export function systemFields(reportDefinition, formIds) {
  const { report_type }   = reportDefinition;

  if (report_type === householdType) {
    return [ ...householdFields];
  }

  let responseTypeFields  = (report_type === systemType)
                            ? systemTypeResponseFields()
                            : (report_type === fieldsType || report_type === queryType)
                              ? responseFields[clientFormsType]
                              : responseFields[report_type];

  if(!responseTypeFields) {
    return [];
  }

  if(!isEmpty(formIds) && report_type !== fieldsType) {
    responseTypeFields = formIds.flatMap(form => (
      responseTypeFields.map(field => ({ ...field, form }))
    ));
  }

  if(report_type === systemType) {
    return [...formFields, ...userFields, ...clientFields, ...responseTypeFields, responseTypeField];
  } else if(report_type === fieldsType) {
    return [...formFields, ...clientFields, ...responseTypeFields];
  } else if(report_type === clientFormsType || report_type === queryType) {
    return [...clientFields, ...clientAdminFields, ...responseTypeFields];
  } else {
    return responseTypeFields;
  }
}

export function systemFieldsWithUserOptions(reportDefinition, users, formIds) {
  const nextSystemFields  = [ ...systemFields(reportDefinition, formIds) ];
  const actorFields       = nextSystemFields
                            .filter(({ id }) => actorExpr.test(id) );

  if(actorFields.length > 0) {
    const userOptions = users.map(({ first_name, last_name }) => {
      const name = `${first_name} ${last_name}`;
      return { value: name, label: name };
    });

    actorFields.forEach(field => field.field_options = userOptions);
  }

  return nextSystemFields;
}
