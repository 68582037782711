import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Grid }             from 'semantic-ui-react';

import Fixed                from 'components/Fixed';
import ReportTypeSelection  from './ReportTypeSelection';
import FieldSelection       from './FieldSelection';
import FilterSelection      from './FilterSelection';
import LayoutConfiguration  from './LayoutConfiguration';
import ReportConfiguration  from './ReportConfiguration';
import WizardButtons        from './WizardButtons';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const updateSteps = [
  FieldSelection,
  LayoutConfiguration,
  FilterSelection,
  ReportConfiguration
];

const createSteps = [
  ReportTypeSelection,
  ...updateSteps
];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class WizardPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0
    };

    this.handleStepChange  = this.handleStepChange.bind(this);
  }

  render() {
    const { onSave,
            onChange,
            reportDefinition,
            ...forwardProps } = this.props;

    const { id }              = reportDefinition;
    const { step }            = this.state;
    const steps               = id ? updateSteps : createSteps;
    const Step                = steps[step];

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Step onChange={ onChange }
                  reportDefinition={ reportDefinition }
                  { ...forwardProps } />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Fixed>
              <WizardButtons  step={ step }
                              numberOfSteps={ steps.length }
                              onChange={ this.handleStepChange }
                              onSave={ onSave } />
            </Fixed>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  handleStepChange(evt, { value }) {
    const { step }  = this.state;
    const nextStep  = step + value;

    this.setState({ step: nextStep });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

WizardPanel.propTypes = {
  reportDefinition:   PropTypes.object.isRequired,
  forms:              PropTypes.array.isRequired,
  fields:             PropTypes.array.isRequired,
  formCalculations:   PropTypes.object.isRequired,
  onChange:           PropTypes.func.isRequired,
  onSave:             PropTypes.func.isRequired,
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default WizardPanel;
