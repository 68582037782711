import React              from 'react';
import { Button, Popup, Icon }  from 'semantic-ui-react';
import { Link }           from 'react-router-dom';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Add a record.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CreateButton = ({ to }) => {
  const Btn = <Button as={ Link }
                      to={ to }
                      primary
                      basic
                      icon
                      labelPosition='left' >
                <Icon name='add' />
                { title }
              </Button>;

  return (
    <Popup  trigger={ Btn }
            content={ title }
            position='top center' />
  );
};




// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CreateButton;
