import React      from 'react';
import PropTypes  from 'prop-types';
import { Label }  from 'semantic-ui-react';

// -----------------------------------------------------
// Styles
// -----------------------------------------------------

const labelStyle = {
  textAlign:  'center'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ActiveStateBadge = ({ isActive, size }) => {
  const [color, label] = isActive ? ['green', 'Active'] : ['red', 'Inactive'];

  return (
    <Label size={ size } color={ color } style={ labelStyle }>
      { label }
    </Label>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ActiveStateBadge.defaultProps = {
  size: 'mini'
};

ActiveStateBadge.propTypes = {
  isActive: PropTypes.bool.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ActiveStateBadge;
