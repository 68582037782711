import React              from 'react';
import PropTypes          from 'prop-types';
import { Route, Switch }  from 'react-router-dom';
import urljoin            from 'url-join';

import RecordList     from './RecordList';
import NewRecordForm  from './NewRecordForm';
import ShowRecord     from './ShowRecord';
import DownloadAttachment from './ShowRecord/download.js';
import EditRecordForm from './EditRecordForm';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = ({ match: { path } }) => {

  return (
    <Switch>
      <Route  path={ urljoin(path, '/records/new') }
              component={ NewRecordForm } />

      <Route  path={ urljoin(path, '/records/:record_id/edit') }
              component={ EditRecordForm } />

      <Route  path={ urljoin(path, '/records/:record_id/download') }
              component={ DownloadAttachment } />

      <Route  path={ urljoin(path, '/records/:record_id') }
              component={ ShowRecord } />

      <Route  path={ path }
              component={ RecordList } />
    </Switch>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DetailView.propTypes = {
  match:  PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DetailView;
