import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table, Checkbox }  from 'semantic-ui-react';

import { contentNamespace } from '../../../../utils/content';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class HeaderCell extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  content = contentNamespace('organization.permissions.actions')

  render() {
    const { action, disabled } = this.props;
    return (
      <Table.HeaderCell className='header-cell'
                        verticalAlign='middle'
                        textAlign='center'>

        <Checkbox disabled={ disabled }
                  checked={ this.isChecked() }
                  label={ this.content(action) }
                  onChange={ this.handleChange } />
      </Table.HeaderCell>
    );
  }

  isChecked() {
    const { privileges, action, entities }  = this.props;
    const entityActions                     = Object.values(privileges);
    const justActions                       = [].concat(...entityActions);

    const actionCount = justActions.reduce((count, a) => {
      if(a === action) {
        count++;
      }
      return count;
    }, 0);

    return  actionCount === entities.length;
  }

  handleChange(_, attrs) {
    const { disabled, entities, action, onChange }  = this.props;
    const { checked }                               = attrs;
    const actions                                   = [action];

    onChange({ entities, actions, checked, disabled });
  }
}

// -----------------------------------------------------
// Prop Types
// -----------------------------------------------------

HeaderCell.propTypes = {
  action:     PropTypes.string.isRequired,
  entities:   PropTypes.arrayOf(PropTypes.string).isRequired,
  privileges: PropTypes.objectOf(
                PropTypes.arrayOf(PropTypes.string)
              ).isRequired,
  disabled:   PropTypes.bool,
  onChange:   PropTypes.func
};

HeaderCell.defaultProps = {
  disabled:   true,
  onChange:   () => {}
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default HeaderCell;
