import React, { Component, Fragment } from 'react';
import { Button, Dropdown }           from 'semantic-ui-react';
import FormModal                      from './FormModal';

import { modalTitleByTypeForCreate }  from 'constants/Calculations';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const dropdownOptions = [
  {
    value:  'numeric',
    text:   'Numeric Calculation',
    icon:   'calculator'
  }, {
    value:  'date',
    text:   'Date Calculation',
    icon:   'calendar alternate outline'
  }, {
    value:  'time',
    text:   'Time Calculation',
    icon:   'stopwatch'
  }
];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class CreateButton extends Component {
  state = {
    opened:           false,
    calculationType:  'numeric'
  }

  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  render() {
    const { opened, calculationType } = this.state;
    const modalTitle                  = modalTitleByTypeForCreate[calculationType];

    const Btn = <Button icon='add'
                        size='mini'
                        basic
                        color='blue' />;

    const ModeSelector = () => (
      <Dropdown trigger={ Btn }
                options={ dropdownOptions }
                pointing='top left'
                onChange={ this.handleSelect }
                selectOnBlur={ false }
                icon={ null } />
    );

    return (
      <Fragment>
        <FormModal  title={ modalTitle }
                    calculation={ {} }
                    calculationType={ calculationType }
                    opened={ opened }
                    onClose={ this.handleClose }
                    { ...this.props } />

        <ModeSelector />
      </Fragment>
    );
  }

  handleSelect(evt, { value }) {
    this.setState({ opened: true, calculationType: value });
  }

  handleClose() {
    this.setState({ opened: false });
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CreateButton;
