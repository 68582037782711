import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import Chart            from 'components/Chart';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ChartView extends Component {
  render() {
    const { reportResults } = this.props;

    return (
      <Chart data={ reportResults } />
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ChartView.propTypes = {
  reportResults: PropTypes.shape({
    view_type: PropTypes.string,
    options: PropTypes.object,
    xaxis: PropTypes.string,
    yaxis: PropTypes.string,
    series: PropTypes.arrayOf(PropTypes.string),
    records:  PropTypes.arrayOf(PropTypes.object),
  })
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ChartView;
