import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Form }                       from 'semantic-ui-react';

import cast       from 'utils/cast';
import Field      from 'components/forms/Field';
import Checkboxes from './Checkboxes';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const otherOption = Object.freeze({ text: 'Other', value: '__other__' });

const otherFieldTypeFor = {
  'numeric':        'number',
  'numeric_array':  'number',
  'string':         'freeform-short',
  'string_array':   'freeform-short',
  'boolean':        'checkbox',
  'boolean_array':  'checkbox',
  'date':           'date'
};

function extendOptions(options, allowOther, dataType) {
  const extendedOptions = options.map(({ text, value }) => (
    { text, value: cast(value, dataType, { simplify: true }) }
  ));

  if(allowOther) {
    extendedOptions.push(otherOption);
  }

  return extendedOptions;
}

function cleanArrayValue(value, includeOther = false) {
  const values  = []
                  .concat(value)
                  .filter((v) => (v !== null && v !== undefined));

  if(includeOther) {
    values.push(otherOption.value);
  }

  return values;
}

function arrayDiff(a, b) {
  return a.filter((x) => !b.includes(x));
}

function arrayIntersect(a, b) {
  return a.filter((x) => b.includes(x));
}

function shouldShowOther(props) {
  const { metadata: { allow_other }, dataType, value, options } = props;
  const values      = options.map(({ value }) => value);

  const cleanValue  = cleanArrayValue(value);
  const showOther   = allow_other
                      && value
                      && arrayDiff(
                          cast(cleanValue, dataType),
                          cast(values, dataType)
                        ).length > 0;

  return !!showOther;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class SelectMany extends Component {

  constructor(props) {
    super(props);

    this.state = {
      otherIsSelected: shouldShowOther(props)
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleOtherChange  = this.handleOtherChange.bind(this);
  }

  render() {
    const { value,
            options,
            dataType,
            name,
            isDisabled,
            metadata: { allow_other, display_type } } = this.props;

    const { otherIsSelected } = this.state;
    const extendedOptions     = extendOptions(options, allow_other, dataType);
    const cleanValues         = cleanArrayValue(value, otherIsSelected);

    // Other value will be an array.  We need a single (non-array) value
    // for the 'other' field, so we'll always reference the first element
    // (otherValue[0]) when setting the value of the field.  See below.
    const otherValue =
      arrayDiff(cleanValues, extendedOptions.map(({ value }) => value));

    return (
      <Fragment>
        {
          display_type === 'checkbox'
          ? <Checkboxes   value={ cleanValues }
                          disabled={ isDisabled }
                          options={ extendedOptions }
                          onChange={ this.handleSelectChange } />
          : <Form.Select  value={ cleanValues }
                          disabled={ isDisabled }
                          options={ extendedOptions }
                          onChange={ this.handleSelectChange }
                          selectOnBlur={ false }
                          autoComplete='off'
                          clearable
                          multiple />
        }

        {
          allow_other && otherIsSelected &&
          <div style={{ marginTop: '1rem' }}>
            <Field  name={ name }
                    value={ otherValue[0] }
                    disabled={ isDisabled }
                    label='Please specify "Other"'
                    fieldType={ otherFieldTypeFor[dataType] }
                    dataType={ dataType }
                    onChange={ this.handleOtherChange } />
          </div>
        }
      </Fragment>
    );
  }

  handleSelectChange(evt, { value }) {
    const { name, onChange, metadata: { allow_other } } = this.props;
    const otherOptionIndex  = value.indexOf(otherOption.value);
    let otherIsSelected     = false;

    if(allow_other && otherOptionIndex > -1) {
      otherIsSelected = true;
      value.splice(otherOptionIndex, 1);
    }

    this.setState({ otherIsSelected });
    onChange(evt, { name, value });
  }

  handleOtherChange(evt, { value }) {
    const { name,
            value: currentValue,
            options,
            dataType,
            onChange,
            metadata: { allow_other } } = this.props;

    // values that were selected from the standard options
    const selectedValues =
      arrayIntersect( currentValue,
                      cast(options.map(({ value }) => value), dataType));

    allow_other && onChange(evt, { name, value: [ ...selectedValues, value ] });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

SelectMany.defaultProps = {
  value:      [],
  options:    [],
  metadata:   {},
  isDisabled: false
};

SelectMany.propTypes = {
  isDisabled: PropTypes.bool,
  value:      PropTypes.array,
  options:    PropTypes.arrayOf(PropTypes.shape({
    text:     PropTypes.string,
    value:    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })),
  metadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default SelectMany;
