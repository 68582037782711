import React      from 'react';
import PropTypes  from 'prop-types';
import moment     from 'moment';
import MarkdownRenderer from 'components/MarkdownRenderer';

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const MessageItem = (props) => {
  const { body, created_at, notifiable } = props;
  const { email }                        = notifiable;

  const timestamp = moment(created_at).fromNow();

  return (
    <div className='message'>
      <div className='message__header'>
        <div>
          Notified:
          <strong>{ email }</strong>
        </div>
        <div className='message__timestamp'>{ timestamp }</div>
      </div>
      <MarkdownRenderer content={ body } />
    </div>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

MessageItem.propTypes = {
  body:       PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  notifiable: PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default MessageItem;
