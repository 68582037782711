import React from 'react';
import PropTypes from 'prop-types';

const initialStyle = {
  'position': 'absolute',
  'top':      '0px',
  'bottom':   '0px',
  'right':    '0px',
  lineHeight: '22px'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ToolBar = ({ offsetLeft, className, style, children }) => {
  const finalStyle = { ...initialStyle, ...style, left: offsetLeft };

  return (
    <div  style={ finalStyle}
          className={ `tool-bar-component ${className}` }>
      { children }
    </div>
  );
};

ToolBar.defaultProps = {
  className:  '',
  style:      {}
};

ToolBar.propTypes = {
  offsetLeft: PropTypes.string.isRequired,
  className:  PropTypes.string,
  style:      PropTypes.object
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default ToolBar;
