import _ from 'lodash';

function filterTextToTerms(filterText) {
  if(filterText === null || filterText === undefined) {
    return [];
  }

  return filterText.toString().toLowerCase().trim().split(/\s+/);
}

function propValue(record, propName) {
  return (_.get(record, propName) || '').toLowerCase();
}

// -----------------------------------------------------
// External Functions
// -----------------------------------------------------

export function textSubstringFilter(...propNames) {
  return (filterText, record) => {
    const terms = filterTextToTerms(filterText);

    if(terms.length === 0) {
      return true;
    }

    return _.some(propNames, propName => {
      const value = propValue(record, propName);
      return terms.some(term => value.includes(term));
    });
  };
}

export function numericFilter(...propNames) {
  return (filterText, record) => {
    const numericFilter = Number(filterText);

    return _.some(propNames, propName => {
      const value = record[propName];
      return Number(value) === numericFilter;
    });
  };
}

export function dateFilter(operandParser, ...propNames) {
  return (filterText, record) => {
    const dateFilter  = operandParser(filterText);

    return _.some(propNames, propName => {
      const value = operandParser(record[propName]);
      return value === dateFilter;
    });
  };
}

// Provides filtering functionality for human names.
export function humanNamesFilter(firstNameProp, middleNameProp, lastNameProp) {
  return (filterText, record) => {
    const terms = filterTextToTerms(filterText);

    if(terms.length === 0) {
      return true;
    }

    const firstName   = propValue(record, firstNameProp);
    const middleName  = propValue(record, middleNameProp);
    const lastName    = propValue(record, lastNameProp);

    if(terms.length === 2) {
      const [firstNameTerm, lastNameTerm] = terms;

      return  firstName.startsWith(firstNameTerm)
              && lastName.startsWith(lastNameTerm);

    } else if(terms.length === 3) {
      const [firstNameTerm, middleNameTerm, lastNameTerm] = terms;

      return  firstName.startsWith(firstNameTerm)
              && middleName.startsWith(middleNameTerm)
              && lastName.startsWith(lastNameTerm);
    } else {
      return terms.some((term) => {
        return  firstName.startsWith(term)
                || middleName.startsWith(term)
                || lastName.startsWith(term);
      });
    }
  };
}

export function combineFilters(...filters) {
  return (filterText, record) => {
    return _.some(filters, filter => (filter(filterText, record)));
  };
}
