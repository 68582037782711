import React, { Fragment, Component } from 'react';
import PropTypes                      from 'prop-types';

import { Button, Popup, Confirm }     from 'semantic-ui-react';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Delete this record.';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class DeleteButton extends Component {
  state = { opened: false };

  constructor(props) {
    super(props);
    this.handleClick    = this.handleClick.bind(this);
    this.handleCancel   = this.handleCancel.bind(this);
    this.handleConfirm  = this.handleConfirm.bind(this);
  }

  render() {
    const { opened } = this.state;

    const Btn = <Button icon='remove'
                        basic
                        color='red'
                        onClick={ this.handleClick } />;

    return (
      <Fragment>
        <Confirm  open={ opened }
                  header={ title }
                  onCancel={ this.handleCancel }
                  onConfirm={ this.handleConfirm } />

        <Popup  trigger={ Btn }
                content={ title }
                position='top center' />
      </Fragment>
    );
  }

  handleClick() {
    this.setState({ opened: true });
  }

  handleCancel() {
    this.setState({ opened: false });
  }

  handleConfirm() {
    const { id, publicFormId, onDelete } = this.props;
    onDelete(publicFormId, id);
    this.setState({ opened: false });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DeleteButton.propTypes = {
  id:           PropTypes.number.isRequired,
  publicFormId: PropTypes.number.isRequired,
  onDelete:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DeleteButton;
