import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Header, Label, Segment } from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class StatusCard extends Component {

  // helpers
  getBodyText(state) {
    const map = {
      unmapped:   'The import cannot be executed until the spreadsheet columns are mapped to database columns. Please edit the record to complete its configuration.',
      mapped:     'The import is properly configured.',
      enqueued:   'Please check back in a few moments.',
      failed:     'The import was processed with errors.',
      succeeded:  'The import was processed successfully.'
    };
    return map[state];
  }
  getLabelColor(state) {
    const map = {
      unmapped:   'yellow',
      mapped:     'blue',
      enqueued:   'blue',
      failed:     'red',
      succeeded:  'green'
    };
    return map[state];
  }
  getLabelText(state) {
    const map = {
      unmapped:   'Invalid',
      mapped:     'Ready',
      enqueued:   'Running',
      failed:     'Failed',
      succeeded:  'Succeeded'
    };
    return map[state];
  }

  // rendering
  render() {
    const { loading,
            record } = this.props;
    const { state }  = { ...record };
    const labelColor = this.getLabelColor(state);
    const labelText  = this.getLabelText(state);
    const bodyText   = this.getBodyText(state);

    return (
      <Segment loading={ loading }>
        <Header floated='left' as='h3'>
          Status
        </Header>
        <Header floated='right'>
          <Label  color={ labelColor }
                  size='small'>{ labelText }</Label>
        </Header>
        <p style={{ clear: 'both' }}>{ bodyText }</p>
      </Segment>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

StatusCard.propTypes = {
  loading:  PropTypes.bool.isRequired,
  record:   PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default StatusCard;
