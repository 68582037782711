import difference from 'lodash/difference';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const VARIABLE_NAMES  = 'abcdefghijklmnopqrstuvwxyz'.split('');

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function nextVariable(variables) {
  const safeVariables       = []
                              .concat(variables)
                              .filter((v) => v !== null && v !== undefined);

  const usedVariableNames   = safeVariables.map(({ name }) => name);
  const unusedVariableNames = difference(VARIABLE_NAMES, usedVariableNames);
  const [nextVariableName]  = unusedVariableNames;

  if(nextVariableName !== undefined) {
    return nextVariableName;
  } else {
    const position        = usedVariableNames.length;
    const variableCount   = VARIABLE_NAMES.length;
    const variableChar    = VARIABLE_NAMES[position % variableCount];
    const charRepeatCount = Math.floor(position / variableCount) + 1;
    return variableChar.repeat(charRepeatCount);
  }
}
