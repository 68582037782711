import Ajv from 'ajv';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const matcher = (new Ajv()).compile({
  type:     'object',
  required: [ 'scope', 'conditions'],
  properties: {
    scope: {
      type:     'string',
      pattern:  '^answers$'
    },
    conditions: {
      type: 'object',
      required: [ 'and' ],
      properties: {
        and: {
          type:             'array',
          uniqueItems:      true,
          additionalItems:  false,
          minItems:         2,
          items: {
            oneOf: [
              {
                type: 'object',
                required: ['field_name', 'op', 'value'],
                properties: {
                  field_name: { type: 'string', pattern: '^field_id$' },
                  op:         { type: 'string' },
                  value:      { type: ['integer', 'string'] }
                }
              }, {
                oneOf: [
                  {
                    type: 'object',
                    required: ['field_name', 'op', 'value'],
                    properties: {
                      field_name: { type: 'string', pattern: '^value$' },
                      op: {
                        type: 'string',
                        enum: [
                          'EQ',
                          'NEQ',
                          'IN',
                          'NIN',
                          'LT',
                          'LTE',
                          'GT',
                          'GTE',
                          'BWITH',
                          'EWITH',
                          'CONTAINS',
                          'NCONTAIN',
                          'RANGE',
                          'NRANGE',
                          'DOW',
                          'NDOW'
                        ]
                      },
                      value: {
                        type: ['array', 'number', 'string', 'boolean', 'null']
                      }
                    }
                  }, {
                    type: 'object',
                    required: ['field_name', 'op'],
                    properties: {
                      field_name: { type: 'string', pattern: '^value$' },
                      op:         { type: 'string', enum: ['EMPTY', 'NEMPTY', 'TRUE', 'FALSE'] }
                    }
                  }
                ]
              }
            ]
          }
        }
      }
    }
  }
});

function transformIn(data) {
  const { conditions: { and } } = data;
  const fieldIdCond = and.find(cond => cond.field_name === 'field_id');
  const valueCond   = and.find(cond => cond.field_name === 'value');

  return {
    field_name: fieldIdCond.value,
    op:         valueCond.op,
    value:      valueCond.value
  };
}

function transformOut(data) {
  const { field_name: fieldId, op, value } = data;

  // Below, we convert fieldId to string.  We want to use strings as an id may
  // be a number, but it also could be a UUID.
  return {
    scope: 'answers',
    conditions: {
      and: [
        { field_name: 'field_id', op: 'EQ', value: `${fieldId}` },
        { field_name: 'value', op, value }
      ]
    }
  };
}

function create() {
  return transformOut({ field_name: '0', op: 'EQ', value: '' });
}

// -----------------------------------------------------
// Class Definition
// -----------------------------------------------------

class AnswerProvider {
  constructor(node, name, providesControl=true) {
    this.adapter = {
      matcher,
      transformIn,
      transformOut,
      create,
      node,
      name,
      providesControl
    };
  }

  getAdapter() {
    return this.adapter;
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AnswerProvider;
