import { rawContent }   from 'utils/content';

export const relationships = {
  aunt: 'Aunt',
  boyfriend: 'Boyfriend',
  brother: 'Brother',
  brother_in_law: 'Brother-in-law',
  cousin: 'Cousin',
  daughter: 'Daughter',
  stepdaughter: 'Stepdaughter',
  daughter_in_law: 'Daughter-in-law',
  father: 'Father',
  stepfather: 'Stepfather',
  father_in_law: 'Father-in-law',
  foster_father: 'Foster father',
  girlfriend: 'Girlfriend',
  granddaughter: 'Granddaughter',
  grandson: 'Grandson',
  grandmother: 'Grandmother',
  grandfather: 'Grandfather',
  husband: 'Husband',
  mother: 'Mother',
  stepmother: 'Stepmother',
  mother_in_law: 'Mother-in-law',
  foster_mother: 'Foster mother',
  other_female: 'Other female relative',
  other_male: 'Other male relative',
  sister: 'Sister',
  stepsister: 'Stepsister',
  sister_in_law: 'Sister-in-law',
  son: 'Son',
  stepson: 'Stepson',
  son_in_law: 'Son-in-law',
  uncle: 'Uncle',
  wife: 'Wife',
  unknown: 'Unknown'
};

export const relationshipOptions = Object.entries(relationships)
                                   .map(([value, text]) => ({ value, text }));
                                  //  .map(key => ({ text: relationships[key], value: key }));

export const genderOptions = Object.entries(rawContent('organization.clients.gender'))
                             .map(([value, text]) => ({ value, text }));
