import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Table, Icon }      from 'semantic-ui-react';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
const icons = [
  'arrow alternate circle down',
  null,
  'arrow alternate circle up'
];

function nextSortDirection(d) {
  return ((d + 3) % 3) - 1;
}

function renderSortIcon(d) {
  const   iconName = icons[d + 1];
  return  iconName
          ? <Icon name={ iconName } />
          : '';
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Cell extends Component {
  constructor(props) {
    super(props);

    this.handleClick  = this.handleClick.bind(this);
  }

  render() {
    const { columnIndex, children, sortDirection, onResize } = this.props;
    return (
      <Table.HeaderCell className='sortable-table-header-cell'>
        <a href="#sort" onClick={ this.handleClick }>
          <span className='header-cell-label'>{ children }</span>
          <span className='header-cell-direction-icon'>
            { renderSortIcon(sortDirection) }
          </span>
        </a>

        <span className="resize-handle" onMouseDown={ e => onResize( e, columnIndex ) }></span>
      </Table.HeaderCell>
    );
  }

  handleClick(evt) {
    evt.preventDefault();

    const { sortDirection, columnIndex, onSort } = this.props;

    onSort({
      columnIndex,
      sortDirection: nextSortDirection(sortDirection)
    });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Cell.defaultProps = {
  sortDirection: 0
};

Cell.propTypes = {
  sortDirection:  PropTypes.number,
  columnIndex:    PropTypes.number.isRequired,
  onSort:         PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Cell;
