import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Dropdown }         from 'semantic-ui-react';

import _                    from 'lodash';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

// Start with users supplied by parent then add selected
// values in case any were typed in; then de-dup the
// combined list.
function getOptions(users, value) {
  const uOpts  = users.map(u => ({ text: u.email, value: u.email }));
  const vOpts  = value.map(v => ({ text: v, value: v }));
  const opts   = uOpts.concat(vOpts);

  return _.uniqWith(opts, 'value');
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class RecipientSelector extends Component {

  // construction
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // handlers
  handleChange(evt, { value }) {
    const { users, onChange } = this.props;
    const emails     = value || [];
    const recipients = emails.map(email => {
      const user  = _.find(users, u => u.email === email);
      const name  = (user)
                      ? user.first_name + ' ' + user.last_name
                      : email;
      return { name, email, phone: null, delivery_types: ['email'] };
    });
    onChange(recipients);
  }

  // rendering
  render() {
    const { users, value }  = this.props;
    const placeholder       = 'Select or enter recipients';
    const options           = getOptions(users, value);

    return (
      <Dropdown allowAdditions
                fluid
                multiple
                search
                selection
                placeholder={ placeholder }
                options={ options }
                value={ value }
                onChange={ this.handleChange } />
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

RecipientSelector.propTypes = {
  users:      PropTypes.array.isRequired,
  value:      PropTypes.array.isRequired,
  onChange:   PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default RecipientSelector;
