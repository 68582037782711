import content from '../content/en.json';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function subContext(namespace, context) {
  const path = namespace.split('.');
  return path.reduce((ctx, node) => ctx[node], context);
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export function contentNamespace(namespace, context=content) {
  const subCtx = subContext(namespace, context);
  return (key, fallback) => (subCtx[key] || fallback || key);
}

export function rawContent(namespace, context=content) {
  return subContext(namespace, context);
}
