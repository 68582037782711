import React, { Component }               from 'react';
import PropTypes                          from 'prop-types';
import { Checkbox, Form, Select, Table }  from 'semantic-ui-react';

// --------------------------------------------------------
// Component Definitions
// --------------------------------------------------------

class MappingsRow extends Component {

  // construction
  constructor(props) {
    super(props);

    this.handleChangeToForce  = this.handleChangeToForce.bind(this);
    this.handleChangeToOrigin = this.handleChangeToOrigin.bind(this);
  }

  // handlers
  handleChangeToForce(evt, { checked }) {
    evt.stopPropagation();
    const { mapping, onChange } = this.props;
    onChange({
      ...mapping,
      force: checked
    });
  }
  handleChangeToOrigin(evt, { value }) {
    evt.stopPropagation();
    const { mapping, onChange } = this.props;
    onChange({
      ...mapping,
      origin_id: value
    });
  }

  // helpers
  getIsForced() {
    const { mapping } = this.props;
    const { force }   = { ...mapping };
    return !!force;
  }
  getOriginOptions() {
    const { origins } = this.props;
    const values      = Object.values(origins);
    let   options     = values.map(o => (
                          { text: o.name, value: o.id }
                        ));
    options.unshift({ key: 'empty', text: 'Empty' });
    return options;
  }
  getOriginId() {
    const { mapping }   = this.props;
    const { origin_id } = { ...mapping };
    return origin_id;
  }

  // rendering
  render() {
    const { destination } = this.props;
    const originOptions   = this.getOriginOptions();
    const originId        = this.getOriginId();
    const isForced        = this.getIsForced();

    return (
      <Table.Row>
        <Table.Cell>
          <Form.Field className='clearable'
                      placeholder='Select a column'
                      control={ Select }
                      options={ originOptions }
                      value={ originId }
                      onChange={ this.handleChangeToOrigin } />
        </Table.Cell>
        <Table.Cell>
          { destination.name }
          {
            destination.is_required &&
            <span className='text-negative'>&nbsp; *</span>
          }
          <br/>
          <small className='text-muted text-uppercase'>
            { destination.data_type }
          </small>
        </Table.Cell>
        <Table.Cell>
          <Checkbox checked={ isForced }
                    onChange={ this.handleChangeToForce } />
        </Table.Cell>
      </Table.Row>
    );
  }
}

// --------------------------------------------------------
// Prop Types
// --------------------------------------------------------

MappingsRow.propTypes = {
  destination: PropTypes.object.isRequired,
  mapping:     PropTypes.object.isRequired,
  origins:     PropTypes.object.isRequired,
  onChange:    PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default MappingsRow;
