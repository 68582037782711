import React      from 'react';
import PropTypes  from 'prop-types';
import { Table }  from 'semantic-ui-react';

import formatCalculationResult  from 'utils/calculations/formatCalculationResult';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CalculationRecord = ({ result }) => {
  const { name }        = result;
  const formattedResult = formatCalculationResult(result, result.value);

  return (
    <Table.Row>
      <Table.Cell>{ name }</Table.Cell>
      <Table.Cell colSpan={ 2 }>
        { formattedResult }
      </Table.Cell>
    </Table.Row>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CalculationRecord.propTypes = {
  result: PropTypes.shape({
    name:             PropTypes.string.isRequired,
    value:            PropTypes.any,
    display_unit:     PropTypes.string,
    calculation_type: PropTypes.string
  }).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CalculationRecord;
