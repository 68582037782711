import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import { Image, Icon }  from 'semantic-ui-react';

import ProfilePhotoModal from './ProfilePhotoModal';
import './styles.css';

// -----------------------------------------------------
// Settings
// -----------------------------------------------------

const title = 'Upload a new Profile Photo';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ProfilePhoto = ({id, photoUrl}) => {
  const [opened, setOpened] = useState(false);
  const [imageUrl, setImageUrl] = useState(photoUrl ? photoUrl : '/images/profile.jpg');
  
  useEffect(() => {
    if(photoUrl) {
      setImageUrl(photoUrl);
    }
  }, [photoUrl]);
  
  const handleClose = (newImageUrl=null) => {
    if(newImageUrl){
      setImageUrl(newImageUrl);
    }
    setOpened(false);
  };

  const handleClick = (evt) => {
    evt.preventDefault();
    setOpened(true);
  };

  //
  return (
    <>
      <ProfilePhotoModal 
                  id={ id }
                  title={ title }
                  imageUrl={ imageUrl }
                  opened={ opened }
                  onClose={ handleClose }
                  />

      <a href='#profile-photo'
         className='profilePhotoUploader'
         title={ title }
         onClick={ handleClick }>

        <Image src={ imageUrl } />
        <Icon className='downloadIcon' circular inverted name='cloud upload' size='large' />
      </a>

    </>
  );
};


// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ProfilePhoto.propTypes = {
  id:        PropTypes.number.isRequired,
  photo_url: PropTypes.string
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ProfilePhoto;
