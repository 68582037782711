import React          from 'react';
import PropTypes      from 'prop-types';
import { Table }      from 'semantic-ui-react';

import AnswerRenderer from 'components/fields/AnswerRenderer';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const cellStyle = {
  whiteSpace: 'normal'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const FieldRecord = ({ field, answer }) => {
  const { name, data_type }  = field;

  if (data_type === 'signature') {
    answer['showSignature'] = true;
  }

  return (
    <Table.Row>
      <Table.Cell style={ cellStyle }>{ name }</Table.Cell>
      <Table.Cell style={ cellStyle } colSpan={ 2 }>
        <AnswerRenderer answer={ answer } />
      </Table.Cell>
    </Table.Row>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FieldRecord.propTypes = {
  field:  PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FieldRecord;
