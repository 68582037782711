import AuthenticatedResource from 'resources/AuthenticatedResource';

// --------------------------------------------------------
// Resource Definition
// --------------------------------------------------------

export default class UserResource extends AuthenticatedResource {
  static urlRoot = '/organization/:organization/users/';

  id                    = undefined;
  email                 = undefined;
  first_name            = undefined;
  last_name             = undefined;
  name                  = undefined;
  permission_group_ids  = [];
  role                  = undefined;

  pk() {
    return this.id && this.id.toString();
  }
}