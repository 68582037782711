import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import MasterDetail             from 'components/MasterDetail';
import withPagination           from 'hocs/withPagination';
import withSearch               from 'hocs/withSearch';
import { sortByAttributes }     from 'utils/sortComparators';
import { textSubstringFilter }  from 'utils/recordFilters';
import connectResource          from 'utils/connectResource';
import { getPageAllById }       from 'utils/connectors';

import { affiliations } from 'resources/organizationResources';

import SideCardView from '../SideCardView';
import ListItemView from './ListItemView';
import DetailView   from './DetailView';
import FormView     from './FormView';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const OWNER_TYPE        = 'owner';
const PARTICIPANT_TYPE  = 'participant';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class App extends Component {
  constructor(props) {
    super(props);

    this.handleDelete   = this.handleDelete.bind(this);
    this.handleUpdate   = this.handleUpdate.bind(this);
    this.handleCreate   = this.handleCreate.bind(this);
    this.disableEdit    = this.disableEdit.bind(this);
    this.disableDelete  = this.disableDelete.bind(this);
  }

  render() {
    const { data }      = this.props;
    const affiliations  = data.affiliations || {};
    const records       = Object.values(affiliations);

    return (
      <MasterDetail masterItemComponent={ ListItemView }
                    detailComponent={ DetailView }
                    formComponent={ FormView }
                    sideCardComponent={ SideCardView }
                    records={ records }
                    onDelete={ this.handleDelete }
                    onUpdate={ this.handleUpdate }
                    onCreate={ this.handleCreate }
                    disableEdit={ this.disableEdit }
                    disableDelete={ this.disableDelete }
                    resource='affiliations'
                    listSort={ sortByAttributes('name') }
                    filterRecord={ textSubstringFilter('name') }
                    { ...this.props } />


    );
  }

  handleDelete(id) {
    const { destroy } = this.props;

    return destroy(null, { id });
  }

  handleUpdate(record) {
    const { update }  = this.props;
    const { id }      = record;

    return update(record, { id });
  }

  handleCreate(record) {
    const { create } = this.props;
    return create(record);
  }

  disableEdit(id) {
    const { data }      = this.props;
    const affiliations  = data.affiliations || {};
    const record        = affiliations[id]  || {};

    return record.type === OWNER_TYPE;
  }

  disableDelete(id) {
    const { data }      = this.props;
    const affiliations  = data.affiliations || {};
    const record        = affiliations[id]  || {};

    return record.type === PARTICIPANT_TYPE;
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

App.propTypes = {
  data: PropTypes.shape({
    affiliations: PropTypes.object
  }).isRequired,

  destroy:  PropTypes.func.isRequired,
  create:   PropTypes.func.isRequired,
  update:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedComponent = connectResource(App, {
  key: ({ page, query }) => [page, query],
  connectors: {
    affiliations: getPageAllById(affiliations),
  },

  mapDispatchToProps: {
    ...affiliations.actions
  }
});

export default withSearch(withPagination(connectedComponent));
