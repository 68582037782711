import urljoin from 'url-join';

const domainExpr  = /((?:[a-z0-9]+(?:-[a-z0-9]+)*)\.[a-z]{2,}(?::\d+)?)$/i;

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function({ subdomain='', path='' }) {
  const { origin }  = window.location;
  const match       = origin.match(domainExpr);
  const fqdn        = `//${subdomain}.${match[1]}`;

  return urljoin(fqdn, path);
}
