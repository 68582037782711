import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Form, Menu }                 from 'semantic-ui-react';

import TimeBetweenPanel   from './TimeBetweenPanel';
import TimeFromPanel      from './TimeFromPanel';
import TimeSincePanel     from './TimeSincePanel';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const tabs = {
  timeSince:    'timeSince',
  timeBetween:  'timeBetween',
  timeFrom:     'timeFrom'
};

const tabViews = {
  timeSince:    TimeSincePanel,
  timeBetween:  TimeBetweenPanel,
  timeFrom:     TimeFromPanel
};

const subtypeTabs = {
  time_since:   'timeSince',
  time_between: 'timeBetween',
  time_from:    'timeFrom'
};

const defaultDateTab = tabs.timeSince;
const defaultTimeTab = tabs.timeBetween;

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class DateEditor extends Component {
  constructor(props) {
    super(props);

    const { calculation: { calculation_subtype: subtype },
            calculationType: type } = props;

    const selected = subtype ?
                      subtypeTabs[subtype] :
                      type === 'time' ? defaultTimeTab : defaultDateTab;

    this.state = { selected };

    this.handleChange     = this.handleChange.bind(this);
    this.handleMenuClick  = this.handleMenuClick.bind(this);
  }

  render() {
    const { selected }                      = this.state;
    const { fields, calculation,
            calculationType, onChange }     = this.props;
    const { name='' }                       = calculation;
    const SelectedView                      = tabViews[selected];

    return (
      <Fragment>
        <Form.Field>
          <label>Name</label>
          <Form.Input name='name'
                      value={ name }
                      onChange={ this.handleChange } />
        </Form.Field>

        { calculationType === 'date' &&
        <Menu pointing secondary>
          <Menu.Item  name={ tabs.timeSince }
                      active={ selected === tabs.timeSince }
                      onClick={ this.handleMenuClick }>
            Time Since...
          </Menu.Item>
          <Menu.Item  name={ tabs.timeBetween }
                      active={ selected === tabs.timeBetween }
                      onClick={ this.handleMenuClick }>
            Time Between...
          </Menu.Item>
          <Menu.Item  name={ tabs.timeFrom }
                      active={ selected === tabs.timeFrom }
                      onClick={ this.handleMenuClick }>
            Time From...
          </Menu.Item>
        </Menu>
        }

        <SelectedView fields={ fields }
                      calculation={ calculation }
                      calculationType={ calculationType }
                      onChange={ onChange } />
      </Fragment>
    );
  }

  handleMenuClick(evt, { name }) {
    this.setState({ selected: name });
  }

  handleChange(evt, { name, value }) {
    const { calculation, onChange } = this.props;
    const nextCalculation = {
      ...calculation,
      [name]: value
    };

    onChange({ calculation: nextCalculation });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DateEditor.defaultProps = {
  loading:      false,
  calculation:  {}
};

DateEditor.propTypes = {
  loading:    PropTypes.bool,
  calculation:  PropTypes.shape({
    id:                   PropTypes.number,
    name:                 PropTypes.string,
    equation:             PropTypes.string,
    variables:            PropTypes.array,
    calculation_subtype:  PropTypes.string
  }),

  fields:     PropTypes.arrayOf(PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    data_type:  PropTypes.string
  })).isRequired,

  onChange:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DateEditor;
