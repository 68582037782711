import React, { Component, Fragment }       from 'react';
import { Header, Segment, Table, Message }  from 'semantic-ui-react';
import { Redirect, Link }                   from 'react-router-dom';

import connectResource                          from 'utils/connectResource';
import { getById }                              from 'utils/connectors';
import { organizations, pricing_tiers, users }  from 'resources/adminResources';
import parseHost                                from 'utils/parseHost';

import UserList from './UserList';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class DetailView extends Component {

  render() {
    const { data, loading, api_errors } = this.props;

    const organization  = data.organization || {};
    const pricingTier   = data.pricing_tier || {};
    const owner         = data.org_owner    || {};
    const hasOwner      = organization && organization.owner_id !== null;
    const { users, archived } = organization;

    const host          = parseHost(window.location.host);
    const fqdn          = `${organization.slug}.${host.domain}.${host.tld}`;

    return (
      <Fragment>
        <Segment loading={ loading }>
          { api_errors && <Redirect to='./'/> }

          <Header as='h2'>{organization.name}</Header>
          <Table basic='very' collapsing>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>
                  <Header as='h4'>Domain</Header>
                </Table.Cell>
                <Table.Cell>
                  <a href={ `//${fqdn}:${host.port}` } target='blank'>{ fqdn }</a>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>
                  <Header as='h4'>Pricing Tier</Header>
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/admin/pricing-tiers/${pricingTier.id}` }>
                    { pricingTier.name }
                  </Link>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>
                  <Header as='h4'>Time Zone</Header>
                </Table.Cell>
                <Table.Cell>
                  { organization.time_zone }
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header as='h3'>Owner</Header>

          {
            !hasOwner &&
            <Message info>
              Owner has not accepted invitation
            </Message>
          }

          {
            hasOwner &&
            <Table basic='very' collapsing>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>
                    <Header as='h4'>Name</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={ `/admin/users/${ owner.id }`}>
                      { owner.first_name } { owner.last_name }
                    </Link>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell collapsing>
                    <Header as='h4'>Email</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <a href={ `mailto:${ owner.email }` }>{ owner.email }</a>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          }
        </Segment>

        <UserList loading={ loading } users={ users } archived={ archived } />
      </Fragment>
    );
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(DetailView, [
  {
    organization: getById(organizations, ({ match: { params: { id } } }) => id)
  }, {
    org_owner:  getById(users, ({ data: { organization } }) => organization.owner_id),
    pricing_tier: {
      fetchData: (state, { data: { organization } }) => {
        const ptid = organization && organization.pricing_tier_id;
        if(ptid) {
          return pricing_tiers.actions.show(null, {id: ptid });
        }
      },
      selectData: (state, { data: { organization } }) => {
        const ptid = organization && organization.pricing_tier_id;
        return pricing_tiers.selector(state)[ptid];
      }
    }
  }
]);
