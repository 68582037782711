import React                        from 'react';
import PropTypes                    from 'prop-types';
import { Segment, Comment, Header } from 'semantic-ui-react';

import useOrganizationResource  from 'hooks/useOrganizationResource';
import useOrganizationFetcher   from 'hooks/useOrganizationFetcher';
import NoteResource             from 'resources/organization/NoteResource';

import Item from './Item';
import Form from './Form';

const style = {
  textAlign: 'center',
  marginTop: '1em',
  cursor: 'pointer'
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Notes = ({ notableType, notableId }) => {
  const [showMore, setShowMore] = React.useState(false);

  const notes   = useOrganizationResource(NoteResource.listShape(), { notable_type: notableType, notable_id: notableId });
  const create  = useOrganizationFetcher(NoteResource.createShape());
  const update  = useOrganizationFetcher(NoteResource.updateShape());
  const destroy = useOrganizationFetcher(NoteResource.deleteShape());

  const nVisible = showMore ? notes.length : 3;
  const visibleNotes = notes.
                        sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).
                        slice(0, nVisible);

  return (
    <Segment>
      <Comment.Group>
        <Header as="h3" dividing>Notes</Header>
        {
          visibleNotes.map(note => (
            <Item key={ note.id } 
                  note={ note }
                  notableType={ notableType }
                  onDestroy={ destroy } 
                  onUpdate={ update } />
                  ))
        }
        {
          notes.length > 3 &&
            <p style={ style }>
              { showMore ? 
                <a onClick={ () => setShowMore(false) }>Show Less</a> : 
                <a onClick={ () => setShowMore(true) }>Show { notes.length - 3 } more notes...</a> 
              }
            </p>
        }
      </Comment.Group>

      <Form onCreate={ create }
            notableType={ notableType }
            notableId={ notableId } />

    </Segment>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Notes.defaultProps = {};

Notes.propTypes = {
  notableId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,

  notableType: PropTypes.string.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Notes;
