import React, { useCallback } from 'react';
import PropTypes              from 'prop-types';
import { Table, Button }      from 'semantic-ui-react';

import CategorySelector  from './CategorySelector';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const categoryFieldName = 'category_id';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const CategoryField = ({ id, categoryId, selectedCategoryIds, onChange, onRemove }) => {
  const handleChange  = useCallback((evt, { name, value }) => {
    onChange({ id, [name]: value });
  }, [id, onChange]);

  const handleRemove  = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  return (
    <Table.Row>
      <Table.Cell>
        <CategorySelector  name={ categoryFieldName }
                            value={ categoryId }
                            selectedCategoryIds={ selectedCategoryIds }
                            onChange={ handleChange } />
      </Table.Cell>
      <Table.Cell textAlign='right'>
        <Button icon='remove'
                size='mini'
                basic
                color='red'
                onClick={ handleRemove } />
      </Table.Cell>
    </Table.Row>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

CategoryField.defaultProps = {

};

CategoryField.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,

  categoryId:          PropTypes.number,
  onRemove:             PropTypes.func.isRequired,
  onChange:             PropTypes.func.isRequired,
  selectedCategoryIds: PropTypes.arrayOf(PropTypes.number)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CategoryField;
