import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { connect }          from 'react-redux';
import { Redirect }         from 'react-router-dom';

import { organizationAccount, impersonation } from 'resources/organizationResources';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class Stop extends Component {

  constructor(props) {
    super(props);
    this.state = {
      impersonationStopped: false
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    // stop the impersonation
    let request   = impersonation.actions.destroy();
    let response  = await dispatch(request);

    if(!response.errors) {

      // Reset all org state
      dispatch({ type: 'RESET_ORG' });

      // Reload the organization Account
      request = organizationAccount.actions.get();
      dispatch(request);

      this.setState({ impersonationStopped: true });
    }
  }

  render() {
    const { impersonationStopped } = this.state;

    return (
      impersonationStopped && <Redirect to='/' />
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Stop.propTypes = {
  dispatch: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connect()(Stop);
