import React, { Component }                         from 'react';
import PropTypes                                    from 'prop-types';
import { Form, Segment, Header, Button, Checkbox }  from 'semantic-ui-react';
import { Redirect }                                 from 'react-router-dom';

import connectForm                        from 'utils/connectForm';
import connectResource                    from 'utils/connectResource';
import { getAll, getById, getSingleton }  from 'utils/connectors';
import { users, permission_groups, permissions_schema } from 'resources/organizationResources';

import Fixed                from 'components/Fixed';
import PermissionTable      from 'components/organization/permissions/PermissionTable';
import ExtendedPermissions  from 'components/organization/permissions/ExtendedPermissions';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

function calculateNextPrivileges({ entities, actions, checked }, privileges) {
  const setFnName = checked ? 'add' : 'delete';

  return entities.reduce((nextPrivileges, entity) => {
    const currentActionSet = new Set(nextPrivileges[entity]);

    actions.forEach((action) => {
      currentActionSet[setFnName](action);
    });

    nextPrivileges[entity] = [...currentActionSet];

    return nextPrivileges;
  }, { ...privileges });
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FormView extends Component {
  constructor(props) {
    super(props);
    this.handlePrivilegesChange         = this.handlePrivilegesChange.bind(this);
    this.handleExtendedPrivilegesChange = this.handleExtendedPrivilegesChange.bind(this);
  }

  render() {
    const { data,
            onSave,
            onUpdateValue,
            onUpdateState,
            loading,
            saving,
            api_errors,
            selected }  = this.props;

    const { schema }                        = data;
    const { permission_actions, entities }  = schema || {};

    return (
      <Segment loading={ loading }>
        <Header as='h2'>Permissions</Header>
        { api_errors && <Redirect to='./'/> }
        <Form onSubmit={ onSave }>
          <Form.Input label='Group Name'
                      name='name'
                      value={ selected.name }
                      placeholder='Name This Group...'
                      onChange={ onUpdateValue } />

          <Checkbox   label='Make this group default for new users.'
                      checked={ !!selected.default }
                      onChange={ (e, { checked }) => onUpdateState({ default: checked }) } />


          <PermissionTable  entities={ entities }
                            actions={ permission_actions }
                            privileges={ selected.privileges }
                            disabled={ false }
                            onChange={ this.handlePrivilegesChange } />

          <ExtendedPermissions  entities={ entities }
                                actions={ permission_actions }
                                privileges={ selected.record_permission_assignments }
                                onChange={ this.handleExtendedPrivilegesChange } />

          <Fixed>
            <Button loading={ saving }
                    primary>Save</Button>
          </Fixed>
        </Form>
      </Segment>
    );
  }

  handlePrivilegesChange(value) {
    const { onUpdateState, selected } = this.props;
    const { privileges }              = selected;

    const nextPrivileges = calculateNextPrivileges(value, privileges);
    onUpdateState({ privileges: nextPrivileges });
  }

  handleExtendedPrivilegesChange(value) {
    const { onUpdateState, selected }       = this.props;
    const { record_permission_assignments } = selected;

    const nextPrivileges =
      calculateNextPrivileges(value, record_permission_assignments);
      
    onUpdateState({ record_permission_assignments: nextPrivileges });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FormView.defaultProps = {
  loading:    false,
  api_errors: false,
  saving:     false
};

FormView.propTypes = {
  loading:    PropTypes.bool,
  api_errors: PropTypes.bool,
  saving:     PropTypes.bool,

  selected:   PropTypes.shape({
    id:         PropTypes.number,
    name:       PropTypes.string,
    default:    PropTypes.bool,
    privileges: PropTypes.object
  }).isRequired,

  onUpdateValue:  PropTypes.func.isRequired,
  onUpdateState:  PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const initialState = {
  id:         null,
  name:       '',
  default:    false,
  privileges: {}
};

export default connectResource(connectForm(FormView, { initialState }), {
  connectors: {
    schema:   getSingleton(permissions_schema),
    users:    getAll(users),
    selected: getById(permission_groups, ({ match: { params: { id } } }) => id)
  },
  mapDispatchToProps: { ...users.actions }
});
