import React  from 'react';
import PropTypes            from 'prop-types';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DefaultRenderer = ({ value }) => {
  if(value === null || value === undefined) {
    return null;
  }

  return (
    <div title={ value }>
      { value }
    </div>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

DefaultRenderer.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DefaultRenderer;
