import React, { Component, Fragment }                      from 'react';
import PropTypes                                 from 'prop-types';
import { Link }                                  from 'react-router-dom';
import { Modal, Button, Header, Form, Message }  from 'semantic-ui-react';

import { reportDefinitions }  from 'resources/organizationResources';
import connectResource        from 'utils/connectResource';
import { getAllById }         from 'utils/connectors';
import uniqueId               from 'utils/uniqueId';
import { sortBy }             from 'lodash';

import SelectionTable from './SelectionTable';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const initialState = {
  selectedViews: {},
  currentReportDefinitionId: '',
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class AddModal extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.formId       = uniqueId();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleViewSelection = this.handleViewSelection.bind(this);
    this.handleReportDefinitionChange = this.handleReportDefinitionChange.bind(this);
    this.handleClose  = this.handleClose.bind(this);
  }

  render() {
    const { opened, title, data }   = this.props;
    const { selectedViews,
            currentReportDefinitionId } = this.state;
    const reportDefinitions         = data.reportDefinitions || [];

    const reportDefinitionsOptions = sortBy(reportDefinitions, 'name').map(({id, name}) => {
      return {
              value: id,
              key: id,
              text: name,
            };
    });

    return (
      <Modal  open={ opened } onClose={ this.handleClose } dimmer='inverted' closeIcon>
        <Header icon='add' content={ title } />
        <Modal.Content>
          <Form onSubmit={ this.handleSubmit } id={ this.formId }>

            {
              reportDefinitions.length === 0

              ? <Message>
                  <Message.Content>
                    <Message.Header>There are currently no reports defined.</Message.Header>
                    Visit the <Link to='/organization/reports'>Reports</Link> tab to add a report.
                  </Message.Content>
                </Message>

              : <Fragment>
                  <strong>Select a report</strong>
                  <Form.Select  options={reportDefinitionsOptions}
                                value={ currentReportDefinitionId }
                                name='report_definition'
                                placeholder='Select a report'
                                clearable
                                search
                                onChange={ this.handleReportDefinitionChange } />

                  { currentReportDefinitionId !== '' &&
                    <Fragment>
                    <strong>Select views to add to your dashboard</strong>

                    <SelectionTable reportDefinitionId={ currentReportDefinitionId }
                                  selectedViews={ selectedViews }
                                  onChange={ this.handleViewSelection } />
                    </Fragment>
                  }
                </Fragment>
            }
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ this.handleClose } negative basic>Cancel</Button>
          <Button type='submit' form={ this.formId } positive basic>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleSubmit() {
    const { onSubmit }              = this.props;
    const { selectedViews } = this.state;

    onSubmit({ selectedViews });
    this.handleClose();
  }

  handleClose() {
    const { onClose } = this.props;

    onClose();
    this.setState({ ...initialState });
  }

  handleReportDefinitionChange(_, { value }) {
    this.setState({ currentReportDefinitionId: value });
  }

  handleViewSelection({ newSelection }) {
    const {selectedViews, currentReportDefinitionId } = this.state;
    const newSelectedViews = {
      ...selectedViews,
      [currentReportDefinitionId]: newSelection
    };

    this.setState({ selectedViews: newSelectedViews });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

AddModal.defaultProps = {
  opened: false
};

AddModal.propTypes = {
  opened:   PropTypes.bool,
  title:    PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose:  PropTypes.func.isRequired,
  data:     PropTypes.shape({
    reportDefinitions: PropTypes.array
  }).isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default connectResource(AddModal, {
  connectors: {
    reportDefinitions: getAllById(reportDefinitions)
  }
});
