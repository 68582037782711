import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import MarkdownRenderer     from 'components/MarkdownRenderer';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------
const style = { margin: '20px 0' };

class Presentation extends Component {

  render() {
    const { metadata: { presentation_content='' } } = this.props;

    return (
      <MarkdownRenderer style={ style }
                        content={ presentation_content } />
    );
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Presentation.propTypes = {
  value:    PropTypes.string,
  metadata: PropTypes.object,
  loading:  PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Presentation;
