import React, { useState }       from 'react';
import PropTypes   from 'prop-types';
import { Button, Confirm }  from 'semantic-ui-react';

import ArchiveResource            from 'resources/organization/ArchiveResource';
import useOrganizationFetcher     from 'hooks/useOrganizationFetcher';


// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

async function archiveHandler({ archivedUserId, history, archive }) {
  const params  = { archivable_type: 'users', archivable_id: archivedUserId };

  const response = await archive(params, {});

  if(response.id) {
    history.push('/organization/users');
    window.location.reload(true);
  }
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ArchiveButton = ({ archivedUserId, history }) => {
  const [ confirmOpen, setConfirmOpen ] = useState(false);

  const archive         = useOrganizationFetcher(ArchiveResource.createShape());

  const handleArchive = () => {
    setConfirmOpen(true);
  };

  const handleCancelArchive = () => {
    setConfirmOpen(false);
  };

  const handleConfirmArchive = () => {
    setConfirmOpen(false);

    archiveHandler({ archivedUserId, history, archive });
  };

  return (
    <>
      <Confirm open={ confirmOpen }
                onCancel={ handleCancelArchive }
                onConfirm={ handleConfirmArchive }
                content={ 'Are you sure you want to archive this user?' }
                confirmButton={ 'Archive' }
                cancelButton={ 'Cancel' }
                />
      <Button floated={ 'right' }
              onClick={ handleArchive }
              >Archive</Button>
    </>
  );

};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ArchiveButton.propTypes = {
  archivedUserId: PropTypes.number,
  history: PropTypes.object.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ArchiveButton;
