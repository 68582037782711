import React, { Component } from 'react';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default function(WrappedComponent) {
  return class AnonymousComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        filters: null
      };

      this.handleOnFilter = this.handleOnFilter.bind(this);
    }

    render() {
      const { filters } = this.state;

      return (
        <WrappedComponent { ...this.props }
                          filters={ filters }
                          onFilter={ this.handleOnFilter } />
      );
    }

    handleOnFilter({ filters }) {
      this.setState({ filters });
    }
  };
}
