import React                    from 'react';
import { organizationAccount }  from 'resources/organizationResources';
import connectResource          from 'utils/connectResource';

class OrganizationName extends React.Component {
  render() {
    const { data }      = this.props;
    const organization  = data.organization || {};
    const { name }      = organization;

    if(name) {
      return (
        <h3>{ name }</h3>
      );
    }

    return null;
  }
}

export default connectResource(OrganizationName, [
  {
    organization: {
      fetchData: () => {
        return organizationAccount.actions.get();
      },
      selectData: (state) => {
        return organizationAccount.selector(state);
      }
    }
  }
]);
