import createResource from '../utils/resource';

const passwordReset = createResource({
  name: 'passwordReset',
  basepath: '/password_resets/',
  actions: {
    create: {
      method: 'POST',
      path: '/'
    },
    update: {
      method: 'PUT',
      path: '/:password_reset_token'
    }
  }
});

export default passwordReset;
