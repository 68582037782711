import React, { useEffect } from 'react';
import PropTypes            from 'prop-types';
import get                  from 'lodash/get';
import throttle             from 'lodash/throttle';

import withPagination             from 'hocs/withPagination';
import { notification_messages }  from 'resources/organizationResources';
import { getPageAllById }         from 'utils/connectors';
import connectResource            from 'utils/connectResource';

import ScrollPane                 from 'components/ScrollPane';
import MessageList                from 'components/forms/Notifications/Messages';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const PAGER_THROTTLE = 250;

function scrollHandler(onPageNext) {
  let scroller;

  return function(evt) {
    scroller  = scroller || evt.target;

    const { clientHeight, scrollTop, scrollHeight } = scroller;
    if(scrollHeight - scrollTop <= clientHeight) {
      onPageNext();
    }
  };
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Messages = ({ data, onPageNext, onPageReset, resourceName, resourceId }) => {

  // Rest the page to 1, when resourceName and/or resourceId changes
  useEffect(() => {
    onPageReset();
  }, [resourceName, resourceId, onPageReset]);

  const messages      = get(data, 'messages', {});
  const messagesDesc  = Object
                        .values(messages)
                        .sort(({ created_at: a }, { created_at: b }) => (
                          b > a ? 1 : -1
                        ));

  const handleScroll  = throttle(scrollHandler(onPageNext), PAGER_THROTTLE);

  return (
    <ScrollPane onScrollY={ handleScroll }
                styleOverrides={ { height: '50vh' } }>

      <MessageList messages={ messagesDesc } />
    </ScrollPane>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Messages.propTypes = {
  resourceId:   PropTypes.number.isRequired,
  resourceName: PropTypes.string.isRequired,
  onPageNext:   PropTypes.func.isRequired,
  onPageReset:  PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const ConnectedMessages = connectResource(Messages, {
  key: ({ page, resourceName, resourceId }) => [page, resourceName, resourceId],
  ignorePending: true,
  connectors: {
    messages: getPageAllById(
                notification_messages,
                ({ resourceId, resourceName }) => ({
                  owner_id:   resourceId,
                  owner_type: resourceName
                })
              )
  }
});

export default withPagination(ConnectedMessages);
