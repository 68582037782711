import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------------
// Component Style
// --------------------------------------------------------

const initialStyle = {
  'display':  'block',
  'position': 'absolute',
  'top':      '0px',
  'bottom':   '0px',
  'left':     '0px',
  'overflow': 'auto'
};

// --------------------------------------------------------
// Component Def
// --------------------------------------------------------

const SideBar = ({ width, className, children }) => {
  const style = { ...initialStyle, width };

  return (
    <aside  style={ style }
            className={ `sidebar-component ${className}` }>
      { children }
    </aside>
  );
};

SideBar.defaultProps = {
  className:    ''
};

SideBar.propTypes = {
  width:      PropTypes.string.isRequired,
  className:  PropTypes.string
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default SideBar;
