import React, { useState }                      from 'react';
import PropTypes                                from 'prop-types';
import { Button, Comment, Header, Modal, Form } from 'semantic-ui-react';

import formattedDate  from 'utils/formattedDate';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Item = ({ note, notableType, onUpdate, onDestroy }) => {
  const { id,
          body, 
          created_at, 
          notable_id,
          user: { first_name, last_name } = {} }  = note;

  const [confirmDestroy, setConfirmDestroy] = useState(false);
  const [isEditing, setIsEditing]           = useState(false);
  const [editBody, setEditBody]             = useState(body);

  const handleChange = (_evt, { value }) => {
    setEditBody(value);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const dismissEdit = () => {
    setIsEditing(false);
    setEditBody(body);
  };

  const updateComment = async () => {
    const resp = await onUpdate({ notable_type: notableType, notable_id, id }, { body: editBody });

    if(!resp.errors) {
      setIsEditing(false);
    }
  };

  const handleDelete = () => {
    setConfirmDestroy(true);
  };

  const dismissDelete = () => {
    setConfirmDestroy(false);
  };

  const finalizeDeletion = async () => {
    const resp = await onDestroy({ notable_type: notableType, notable_id, id });

    if(!resp.errors) {
      setConfirmDestroy(false);
    }
  };

  return (
    <>
      <Comment>
        <Comment.Content>
          <Comment.Author as='span'>{ first_name } { last_name }</Comment.Author>
          <Comment.Metadata>
            { formattedDate(created_at) }
          </Comment.Metadata>
          <Comment.Text>
            { body }
          </Comment.Text>
          <Comment.Actions>
            <Comment.Action onClick={ handleEdit }>Edit</Comment.Action>
            <Comment.Action onClick={ handleDelete }>Delete</Comment.Action>
          </Comment.Actions>
        </Comment.Content>
      </Comment>

      
      <Modal open={ confirmDestroy } onClose={ dismissDelete } dimmer='inverted' closeIcon>
        <Header content="Are you sure you want to delete this note?" />
        <Modal.Content>
          <Header as="h4">Note content</Header>
          <blockquote>
            { body }
          </blockquote>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ dismissDelete } basic>Cancel</Button>
          <Button type='submit'
                  onClick={ finalizeDeletion }
                  negative
                  basic>Delete</Button>
        </Modal.Actions>
      </Modal>
 
      <Modal open={ isEditing } onClose={ dismissEdit } dimmer='inverted' closeIcon>
        <Header content="Edit Comment" />
        <Modal.Content>
          <Form onSubmit={updateComment} reply>
            <Form.TextArea  value={ editBody } 
                            onChange={ handleChange } 
                            rows={ 3 } />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ dismissEdit } basic>Cancel</Button>
          <Button type='submit'
                  onClick={ updateComment }
                  positive
                  basic>Update</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Item.propTypes = {
  note: PropTypes.shape({
    id:           PropTypes.number,
    notable_type: PropTypes.string,
    notable_id:   PropTypes.number,
    body:         PropTypes.string,
    created_at:   PropTypes.string,
    updated_at:   PropTypes.string,

    user:       PropTypes.shape({
      first_name: PropTypes.string,
      last_name:  PropTypes.string
    })
  }).isRequired,

  onDestroy:  PropTypes.func.isRequired,
  onUpdate:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Item;
