import ApiResource  from 'resources/ApiResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ConfidentialityFieldsResource extends ApiResource {
  static urlRoot = '/confidentiality_agreement_fields'

  pk() {
    return this.name;
  }
}
