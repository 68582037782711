import React, { Component }       from 'react';
import { Link }                   from 'react-router-dom';
import { Grid, Message, Button }  from 'semantic-ui-react';

import connectResource                from 'utils/connectResource';
import { getById, getSingleton }      from 'utils/connectors';
import { users, organizationAccount } from 'resources/organizationResources';

class NoPermission extends Component {
  render() {
    const owner_email = (this.props.data.owner||{}).email || '';
    return (
      <Grid verticalAlign='middle' centered columns='2' style={{height : '100%'}}>
        <Grid.Row>
          <Grid.Column>
            <Message
              warning
              icon='lock'
              header="You don't have permission to view this."
              content={`Contact your organization owner at ${owner_email} if you believe this is an error.`}
            />
            <Link to='/'>
            <Button
              basic
              >Take Me Back</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connectResource(NoPermission, [
  {
    orgAccount : getSingleton(organizationAccount)
  },
  {
    owner : getById(users, props => props.data.orgAccount.owner_id)
  }
]);
