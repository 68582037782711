import React      from 'react';
import PropTypes  from 'prop-types';

import { Table, Button, Popup }  from 'semantic-ui-react';

import EditButton       from './EditButton';
import ActiveStateBadge from 'components/ActiveStateBadge';
import clientUrlFor     from 'utils/clientUrlFor';
import formattedDate    from 'utils/formattedDate';
import formattedDow     from 'utils/recurrence';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const dateFormatName = 'datetime_long';

const formUrl = (slug, access_key) => {
  return clientUrlFor({
    subdomain:  slug,
    path:       `public_forms/${access_key}`
  });
};

const VisitButton = ({ slug, access_key }) => {
  const Btn = <Button icon='external'
    as='a'
    href={formUrl(slug, access_key) }
    target='_blank'
    basic
    color='blue' />;

  return <Popup trigger={ Btn }
    content='Visit the published form.'
    position='top center' />;
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Record =
    ({ id, slug, access_key, is_active, published_at, expired_at, dow, time_start, time_end, translate, onSave }) => {
      return (
        <Table.Row>
          <Table.Cell><ActiveStateBadge isActive={ is_active } /></Table.Cell>
          <Table.Cell>
            { formattedDate(published_at, dateFormatName, 'Not Published') }
          </Table.Cell>
          <Table.Cell>{ formattedDate(expired_at) }</Table.Cell>
          <Table.Cell>{ formattedDow(dow) }</Table.Cell>
          <Table.Cell>
            { !!time_start &&
              <>
                {time_start} - {time_end}
              </>
            }
          </Table.Cell>
          <Table.Cell>{ translate ? 'Yes' : 'No' }</Table.Cell>
          <Table.Cell textAlign='right'>
            <Button.Group size='mini'>
              <EditButton id={ id }
                published_at={ published_at }
                expired_at={ expired_at }
                translate={ translate }
                dow={ dow }
                time_start={ time_start }
                time_end={ time_end }
                onSubmit={ onSave }  />
              <VisitButton slug={ slug } access_key={ access_key } />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      );
    };

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Record.propTypes = {
  id:           PropTypes.number,
  slug:         PropTypes.string,
  access_key:   PropTypes.string,
  is_active:    PropTypes.bool,
  published_at: PropTypes.string,
  expired_at:   PropTypes.string,
  translate:    PropTypes.bool,
  onSave:       PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Record;
