import React  from 'react';

const RADIAN = Math.PI / 180;

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export const renderPercentLabel = ( { cx, cy, midAngle, innerRadius, outerRadius, name, percent, fill }) => {
  const value = `${ (percent * 100).toFixed(1) }%`;

  return renderLabel({ cx, cy, midAngle, innerRadius, outerRadius, name, value, fill });
};

export const renderValueLabel = ( { cx, cy, midAngle, innerRadius, outerRadius, name, value, fill }) => {
  return renderLabel({ cx, cy, midAngle, innerRadius, outerRadius, name, value, fill });
};

function renderLabel({ cx, cy, midAngle, innerRadius, outerRadius, name, value, fill }){
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
            <text
              x={x}
              y={y}
              fill={fill}
              textAnchor={x > cx ? 'start' : 'end'}
              dominantBaseline="central"
            >
               {name} ({value})
            </text>
          );
}