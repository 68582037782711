import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form }             from 'semantic-ui-react';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class AutoPopulate extends Component {
  componentDidUpdate(prevProps) {
    const { name, options, onChange } = this.props;

    if(!options.length && prevProps.value !== null){
      onChange(null, { name, value: null });
    } else if (options.length === 1 && prevProps.value !== options[0].value) {
      onChange(null, { name, value: options[0].value });
    }
  }

  render() {
    const { value } = this.props;

    const isShortText = (!value || value.length <= 80);

    return (
      <>
      { isShortText &&
        <Form.Input readOnly value={ value || '' } />
      }
      { !isShortText &&
        <Form.TextArea readOnly value={ value || '' } />
      }
      </>
    );
  }

  handleChange(evt) {
    const { name, onChange }  = this.props;
    const { value }           = evt.target;
    onChange(evt, { name, value });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

AutoPopulate.defaultProps = {
  value:    '',
  options: []
};

AutoPopulate.propTypes = {
  value:    PropTypes.string,
  options:  PropTypes.array,
  onChange: PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AutoPopulate;
